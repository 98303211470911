import project from 'constants/project'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  deleteUser: `${project.environment[project.environmentName].api}/user`,
}