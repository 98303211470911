import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
    catchError,
    exhaustMap,
    takeUntil,
    filter,
    map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
    fetchGetNews,
    fetchPostNews,
    fetchPutNews,
    fetchDeleteNews,
} from './services'
import actions from './actions'

const newsEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getNewsAction)),
        exhaustMap(_ =>
            from(fetchGetNews())
                .pipe(
                    map(actions.getNewsSuccessAction),
                    catchError(error => of(actions.getNewsFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getNewsCancelAction))))
                ),
        )
    )

const newsPostEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.postNewsAction)),
        exhaustMap(action =>
            from(fetchPostNews(action.payload))
                .pipe(
                    map(actions.postNewsSuccessAction),
                    catchError(error => of(actions.postNewsFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.postNewsCancelAction))))
                ),
        )
    )

const newsPutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.putNewsAction)),
        exhaustMap(action =>
            from(fetchPutNews(action.payload))
                .pipe(
                    map(actions.putNewsSuccessAction),
                    catchError(error => of(actions.putNewsFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.putNewsCancelAction))))
                ),
        )
    )

const newsDeleteEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.deleteNewsAction)),
        exhaustMap(action =>
            from(fetchDeleteNews(action.payload))
                .pipe(
                    map(actions.deleteNewsSuccessAction),
                    catchError(error => of(actions.deleteNewsFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.deleteNewsCancelAction))))
                ),
        )
    )

export default [
    newsEpic,
    newsPostEpic,
    newsPutEpic,
    newsDeleteEpic,
]
