import { createAction } from 'typesafe-actions'
import {
  ON_CHANGE_MENU_REQUEST,
  ON_CHANGE_MENU_SHOW,
  CLEAR_MENU,
} from './constants'

const onChangeMenuAction = createAction(ON_CHANGE_MENU_REQUEST,
  resolve => (data: IMenuActiveControl) => resolve(data))

const onChangeMenuShow = createAction(ON_CHANGE_MENU_SHOW)

const clearMenu = createAction(CLEAR_MENU)

export default {
  onChangeMenuAction,
  onChangeMenuShow,
  clearMenu,
}