import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchPutLottoStatus } from './services'
import actions from './actions'

const putLottoStatusEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.putLottoStatusAction)),
    exhaustMap(action =>
      from(fetchPutLottoStatus(action.payload))
        .pipe(
          map(actions.putLottoStatusSuccessAction),
          catchError(error => of(actions.putLottoStatusFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.putLottoStatusCancelAction))))
        ),
    )
  )

export default [
  putLottoStatusEpic,
]
