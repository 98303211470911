import React, { SFC } from 'react'
import { get, noop } from 'lodash'
import { Button } from 'components'
import { number, transformer } from 'utils'
import './success.style.scss'
import {
  Grid
} from '@material-ui/core'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const constants = {
  title: 'เรียบร้อย',
  description: 'กรุณารอการตรวจสอบสักครู่',
  approval: 'อนุมัติ',
  cancel: 'ยกเลิก',
  bank: 'ธนาคาร: ',
  accountNo: 'หมายเลขบัญชี: ',
  amount: 'จำนวนเงิน: ',
  baht: ' บาท',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDepositSuccessModal = {
  title: constants.title,
  description: constants.description,
  cancel: constants.cancel,
  approval: constants.approval,
  txStatus: 'SUCCESS',
  txId: 0,
  action(status: 'SUCCESS' | 'FAIL', txId: number) { noop() },
  cancelAction() { noop() },
}

const SuccessModal: SFC<IDepositSuccessModal & DefaultProps> = (props: IDepositSuccessModal) => {

  const handleSubmit = () => {
    props.action!(
      props.txStatus,
      props.txId,
    )
  }

  return (
    <div className="success-modal-container">
      <div className="title-success-modal">
        <h4>{props.title}</h4>
      </div>
      <div className="sub-title-success-modal">
        <h6>{props.subTitle}</h6>
      </div>
      <div className="description-success-modal body-2">
      <SimpleBar style={{minHeight:'100%', maxHeight: '100vh'}}>
        <div className="margin-bottom">
          {props.name}
        </div>
        <div className="description-setings">
          <div className="secondary-text">{constants.bank}</div>{props.bank}
        </div>
        <div className="description-setings">
          <div className="secondary-text">{constants.accountNo}</div>{number.formatBankNumber(get(props,'accountNo',''),get(props,'bank',''))}
        </div>
        <div className="amount-settings">
          <div className="secondary-text">{constants.amount}</div>
          <div className="primary-green-text">
            {transformer.typeFormat(props.money!)}
          </div>
          <div>{constants.baht}</div>
        </div>
      </SimpleBar>
      </div>

      <Grid container justify="center">
        <Grid item xs={4}>
          {}
        </Grid>

        <Grid item xs={8}>
          <div className="buttton-container">
            <div className="footer-wrapper-success-modal">
              <Button
                onClick={props.cancelAction}
                text={props.cancel!}
                theme="outline"
              />
            </div>
            <div className="footer-wrapper-success-modal">
              <Button
                onClick={handleSubmit}
                text={props.approval!}
                color="green"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SuccessModal.defaultProps = defaultProps

export default SuccessModal