import { string, object, ObjectSchema, boolean } from 'yup'
import { COMMACASE } from 'constants/regex'

const constants = {
  onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
  number: 'กรุณากรอกตัวเลข',
  twoLength: 'กรุณากรอกไม่เกิน 2 ตัว',
  twoMinLength:'กรุณากรอกให้ครบ 2 ตัว',
  threeLength: 'กรุณากรอกไม่เกิน 3 ตัว',
  threeMinLength:'กรุณากรอกให้ครบ 3 ตัว',
  fourLength: 'กรุณากรอกไม่เกิน 4 ตัว',
  fourMinLength:'กรุณากรอกให้ครบ 4 ตัว',
  rate: 'กรุณากรอกอัตราจ่าย',
  level: 'กรุณากรอกระดับ',
  volumeLimit: 'กรุณากรอกยอดรวมปรับระดับ',
}

const scheme: ObjectSchema<ILottoRate> = object().shape({
  level: string(),
  rate: string()
  .matches(COMMACASE, constants.onlyNumber)
  .required(constants.rate),
  volumeLimit: string()
  .matches(COMMACASE, constants.onlyNumber)
  .required(constants.volumeLimit),
  isSecondButton: boolean(),
})
export default scheme