import { createAction } from 'typesafe-actions'
import {
  GET_WEBBANK_SUMMARY_REQUEST,
  GET_WEBBANK_SUMMARY_CANCEL,
  GET_WEBBANK_SUMMARY_SUCCESS,
  GET_WEBBANK_SUMMARY_FAILURE,

  GET_BET_SUMMARY_REQUEST,
  GET_BET_SUMMARY_CANCEL,
  GET_BET_SUMMARY_SUCCESS,
  GET_BET_SUMMARY_FAILURE,

  GET_MEMBER_SUMMARY_REQUEST,
  GET_MEMBER_SUMMARY_CANCEL,
  GET_MEMBER_SUMMARY_SUCCESS,
  GET_MEMBER_SUMMARY_FAILURE,

  GET_FINANCE_SUMMARY_REQUEST,
  GET_FINANCE_SUMMARY_CANCEL,
  GET_FINANCE_SUMMARY_SUCCESS,
  GET_FINANCE_SUMMARY_FAILURE,

  GET_BET_CHART_SUMMARY_REQUEST,
  GET_BET_CHART_SUMMARY_CANCEL,
  GET_BET_CHART_SUMMARY_SUCCESS,
  GET_BET_CHART_SUMMARY_FAILURE,

  GET_MEMBER_CHART_SUMMARY_REQUEST,
  GET_MEMBER_CHART_SUMMARY_CANCEL,
  GET_MEMBER_CHART_SUMMARY_SUCCESS,
  GET_MEMBER_CHART_SUMMARY_FAILURE,

  GET_FINANCE_CHART_SUMMARY_REQUEST,
  GET_FINANCE_CHART_SUMMARY_CANCEL,
  GET_FINANCE_CHART_SUMMARY_SUCCESS,
  GET_FINANCE_CHART_SUMMARY_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getWebbankSummaryAction = createAction(GET_WEBBANK_SUMMARY_REQUEST)
const getWebbankSummarySuccessAction = createAction(
  GET_WEBBANK_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWebbankSummaryRes>>) => resolve(data))
const getWebbankSummaryFailureAction = createAction(
  GET_WEBBANK_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getWebbankSummaryCancelAction = createAction(GET_WEBBANK_SUMMARY_CANCEL)
// ----------------------------------------------------------------------------------------
const getBetSummaryAction = createAction(GET_BET_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getBetSummarySuccessAction = createAction(
  GET_BET_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetSummaryRes>>) => resolve(data))
const getBetSummaryFailureAction = createAction(
  GET_BET_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getBetSummaryCancelAction = createAction(GET_BET_SUMMARY_CANCEL)
// ----------------------------------------------------------------------------------------
const getMemberSummaryAction = createAction(GET_MEMBER_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getMemberSummarySuccessAction = createAction(
  GET_MEMBER_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IMemberSummaryRes>>) => resolve(data))
const getMemberSummaryFailureAction = createAction(
  GET_MEMBER_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getMemberSummaryCancelAction = createAction(GET_MEMBER_SUMMARY_CANCEL)
// ----------------------------------------------------------------------------------------
const getFinanceSummaryAction = createAction(GET_FINANCE_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getFinanceSummarySuccessAction = createAction(
  GET_FINANCE_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IFinanceSummaryRes>>) => resolve(data))
const getFinanceSummaryFailureAction = createAction(
  GET_FINANCE_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getFinanceSummaryCancelAction = createAction(GET_FINANCE_SUMMARY_CANCEL)

// ----------------------------------------------------------------------------------------
const getBetChartSummaryAction = createAction(GET_BET_CHART_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getBetChartSummarySuccessAction = createAction(
  GET_BET_CHART_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetChartSummaryRes[]>>) => resolve(data))
const getBetChartSummaryFailureAction = createAction(
  GET_BET_CHART_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getBetChartSummaryCancelAction = createAction(GET_BET_CHART_SUMMARY_CANCEL)
// ----------------------------------------------------------------------------------------
const getMemberChartSummaryAction = createAction(GET_MEMBER_CHART_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getMemberChartSummarySuccessAction = createAction(
  GET_MEMBER_CHART_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IMemberChartSummaryRes>>) => resolve(data))
const getMemberChartSummaryFailureAction = createAction(
  GET_MEMBER_CHART_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getMemberChartSummaryCancelAction = createAction(GET_MEMBER_CHART_SUMMARY_CANCEL)
// ----------------------------------------------------------------------------------------
const getFinanceChartSummaryAction = createAction(GET_FINANCE_CHART_SUMMARY_REQUEST, resolve => (data: IQueryReportSummary) => resolve(data))
const getFinanceChartSummarySuccessAction = createAction(
  GET_FINANCE_CHART_SUMMARY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IFinanceChartSummaryRes>>) => resolve(data))
const getFinanceChartSummaryFailureAction = createAction(
  GET_FINANCE_CHART_SUMMARY_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getFinanceChartSummaryCancelAction = createAction(GET_FINANCE_CHART_SUMMARY_CANCEL)


export default {
  getWebbankSummaryAction,
  getWebbankSummarySuccessAction,
  getWebbankSummaryFailureAction,
  getWebbankSummaryCancelAction,

  getBetSummaryAction,
  getBetSummarySuccessAction,
  getBetSummaryFailureAction,
  getBetSummaryCancelAction,

  getMemberSummaryAction,
  getMemberSummarySuccessAction,
  getMemberSummaryFailureAction,
  getMemberSummaryCancelAction,

  getFinanceSummaryAction,
  getFinanceSummarySuccessAction,
  getFinanceSummaryFailureAction,
  getFinanceSummaryCancelAction,

  getBetChartSummaryAction,
  getBetChartSummarySuccessAction,
  getBetChartSummaryFailureAction,
  getBetChartSummaryCancelAction,

  getMemberChartSummaryAction,
  getMemberChartSummarySuccessAction,
  getMemberChartSummaryFailureAction,
  getMemberChartSummaryCancelAction,

  getFinanceChartSummaryAction,
  getFinanceChartSummarySuccessAction,
  getFinanceChartSummaryFailureAction,
  getFinanceChartSummaryCancelAction
}