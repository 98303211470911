import React, { Component } from 'react'
import { isEqual, noop } from 'lodash'
import {
  Snackbar,
} from 'components'
import { AppBar, MenuBar } from './components'
import {
  BrowserRouter as Router,
  RouteComponentProps,
  Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom'
import routes from 'configs/routes'
import { Modal } from 'components'
import { JWT } from 'constants/jwt'
import { USER } from 'constants/user'
import project from 'constants/project'
import jwt from 'jsonwebtoken'
import { Loader } from '../Loader'
import { THEME_MODE } from 'constants/variables'
import './Root.container.scss'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
type DefaultProps = Readonly<typeof defaultProps>

const constants = {
  sessionExpireTitle: 'เซสชั่นหมดอายุ',
  sessionExpireDescription: 'โปรดเข้าสู่ระบบใหม่อีกครั้ง',
}

const defaultProps: IRootProps & IRootActionProps = {
  isPersist: false,
  token: '',
  userMeResult: [],
  userMeCode: 0,
  userMeError: '',
  userMeIsFetching: false,
  loginIsFetching: false,
  loginResult: [],
  loginCode: 0,
  loginError: '',
  getUserMe() { noop() },
  clearUser() { noop() },
  logout() { noop() },
  clearMenu() { noop() },
  loader() { noop() },
  getNotificationTransactionAll() { noop() },
  listenNotificationTransactionAllSocket() { noop() },
  unlistenNotificationTransactionAllSocket() { noop() },
  clearReportBenefit() { noop() },
  clearReportBenefitLottery() { noop() },
  clearReportBenefitExternalCasino() { noop() },
  clearReportBenefitExternalSlot() { noop() },
  getNotificationTransactionAllCode: 0,
  getNotificationTransactionAllError: '',
  getNotificationTransactionAllIsFetching: false,
  getNotificationTransactionAllResult: {
    depositTxWaitTotal: 0,
    withdrawTxWaitTotal: 0,
    depositTxReserveTotal: 0,
    withdrawTxReserveTotal: 0,
  },
}

class RootContainer extends Component<IRootProps & DefaultProps, IRootStates> {

  static defaultProps = defaultProps

  state: IRootStates = {
    themeMode: THEME_MODE.DARK,
    consoleClassName: 'content-container open',
    permission: '',
  }


  componentDidUpdate(prevProps: IRootProps) {
    if (prevProps.userMeIsFetching !== this.props.userMeIsFetching && !this.props.userMeIsFetching) {
      this.props.loader(false)
    }
  }

  componentWillUnmount() {
    this.props.unlistenNotificationTransactionAllSocket()
  }


  changeThemeMode = (mode: string) => this.setState({
    themeMode: mode,
  })

  onPressLogo = () => {
    return <Link to="/main" />
  }

  isCanAccess = (): boolean => {
    let decodedResult: ITokenData
    try {
      decodedResult = jwt.verify(this.props.token, project.environment[project.environmentName].jwtSecretKey) as ITokenData
      if (decodedResult.permission === USER.PERMISSON.STAFF
        || decodedResult.permission === USER.PERMISSON.ADMIN
        || decodedResult.permission === USER.PERMISSON.SUPER_ADMIN
        || decodedResult.permission === USER.PERMISSON.LOTTER_MASTER
        || decodedResult.permission === USER.PERMISSON.MARKETING
      ) {
        return true
      }
    } catch (err) {
      if (err.name === JWT.ERROR.JWT_ERROR) {
        switch (err.message) {
          case JWT.ERROR.TOKEN_EXPIRE:
            Modal.error.show({
              action: Modal.error.hide,
              description: constants.sessionExpireDescription,
              actionText: constants.sessionExpireTitle,
            })
            break;
          case JWT.ERROR.INVALID_SIGNATURE:
          case JWT.ERROR.JWT_NOT_PROVIDED:
        }
      }
    }
    return false
  }


  renderGuardRoute = ({ component: RouteComponent, name, path, exact }: IRoutes) => {

    const renderRoute = (routeProps: RouteComponentProps) => {
      if (!this.isCanAccess()) {
        this.props.loader(false)
        return (<Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />)
      }
      return (
        <>
          {this.renderOptionsBar()}
          <div className={this.state.consoleClassName}>
            <RouteComponent {...routeProps} />
          </div>
        </>
      )
    }
    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    )
  }

  renderRedirectRoute = ({ component: RouteComponent, name, path, exact }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (this.isCanAccess()) {
        const decodedResult = jwt.verify(this.props.token, project.environment[project.environmentName].jwtSecretKey) as ITokenData
        if (decodedResult.permission === USER.PERMISSON.SUPER_ADMIN) {
          return (<Redirect to={{ pathname: '/summary-dashboard', state: { from: routeProps.location } }} />)
        } else if (decodedResult.permission === USER.PERMISSON.STAFF
          || decodedResult.permission === USER.PERMISSON.ADMIN) {
          return (<Redirect to={{ pathname: '/transaction/deposit', state: { from: routeProps.location } }} />)
        }
        else if (decodedResult.permission === USER.PERMISSON.MARKETING) {
          return (<Redirect to={{ pathname: '/broadcast', state: { from: routeProps.location } }} />)
        }
        else {
          return (<Redirect to={{ pathname: '/lotto', state: { from: routeProps.location } }} />)
        }
      }
      return (
        <div className={this.state.consoleClassName}>
          <RouteComponent {...routeProps} />
        </div>
      )
    }

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    )
  }


  renderPageElement = () => (
    <Switch>
      {routes.map(route =>
        isEqual(route.name, '404')
          ? (<Route key={`${route.name}-page`} component={route.component} />)
          : (route.private)
            ? this.renderGuardRoute(route)
            : this.renderRedirectRoute(route)
      )}
    </Switch>
  )

  handleLogout = () => {
    this.props.clearUser()
    this.props.logout()
    this.props.clearMenu()
    this.props.clearReportBenefit()
    this.props.clearReportBenefitLottery()
    this.props.clearReportBenefitExternalCasino()
    this.props.clearReportBenefitExternalSlot()
  }

  renderOptionsBar = (): JSX.Element => {

    return (
      <>
        <MenuBar notification={this.props.getNotificationTransactionAllResult} />
        <AppBar
          userMeResult={this.props.userMeResult}
          logout={this.handleLogout}
        />
      </>
    )
  }
  // }

  render() {
    const PageElement = this.renderPageElement

    return (
      <>
      <SimpleBar className="simple-scroll-bar">
        <Router>
          <PageElement />
        </Router>
        <Modal.Core />
        <Snackbar.Core />
        <Loader />
         </SimpleBar>
      </>
    )
  }
}

export default RootContainer