import { Grid } from "@material-ui/core";
import { BenefitCard, Breadcrumb, TitlePage } from "components";
import React, { Component } from "react";
import { History } from "history";
import "./ReportBenefit.style.scss";
import { format } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { noop } from "lodash";

const constants = {
  reportLabel: "รายงาน",
  benefitLabel: "กำไร / ขาดทุน",
  summary: "สรุปผล",
  lotto: "หวย",
  externalCasino: "คาสิโนนอก",
  externalSlot: "สล็อตนอก",
  dateFormat: `yyyy-MM-dd'T'HH:mm:ss`,
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IReportBenefitProps & IReportBenefitActionProps = {
  getReportBenefitCode: 0,
  getReportBenefitError: "",
  getReportBenefitIsFetching: false,
  getReportBenefitResult: {
    loss: 0,
    profit: 0,
    total: 0,
  },
  getReportBenefitLotteryCode: 0,
  getReportBenefitLotteryError: "",
  getReportBenefitLotteryIsFetching: false,
  getReportBenefitLotteryResult: {
    loss: 0,
    profit: 0,
    total: 0,
  },
  getReportBenefitExternalCasinoCode: 0,
  getReportBenefitExternalCasinoError: "",
  getReportBenefitExternalCasinoIsFetching: false,
  getReportBenefitExternalCasinoResult: {
    loss: 0,
    profit: 0,
    total: 0,
  },
  getReportBenefitExternalSlotCode: 0,
  getReportBenefitExternalSlotError: "",
  getReportBenefitExternalSlotIsFetching: false,
  getReportBenefitExternalSlotResult: {
    loss: 0,
    profit: 0,
    total: 0,
  },

  getReportBenefit() {
    noop();
  },
  getReportBenefitLottery() {
    noop();
  },
  getReportExternalCasino() {
    noop();
  },
  getReportExternalSlot() {
    noop();
  },
};

class ReportBenefitContainer extends Component<
  DefaultProps &
    IReportBenefitProps &
    IReportBenefitActionProps & { history: History },
  IReportBenefitState
> {
  static defaultProps = defaultProps;

  state: IReportBenefitState = {
    summaryReport: {
      startDate: "",
      endDate: "",
    },
    lottoReport: {
      lottery: "ALL",
      startDate: "",
      endDate: "",
    },
    externalCasinoReport: {
      company: "ALL",
      startDate: "",
      endDate: "",
    },
    externalSlotReport: {
      company: "ALL",
      startDate: "",
      endDate: "",
    },
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.push(path);
  };

  handleReportSummary = (
    _: undefined,
    selectedStartDate: MaterialUiPickersDate,
    selectedEndDate: MaterialUiPickersDate
  ) => {
    if (selectedStartDate && selectedEndDate) {
      this.setState(
        {
          summaryReport: {
            startDate: format(selectedStartDate, constants.dateFormat),
            endDate: format(selectedEndDate.setHours(23, 59,59), constants.dateFormat),
          },
        },
        () => {
          this.props.getReportBenefit(this.state.summaryReport);
        }
      );
    }
  };

  handleLottoReport = (
    selectedLotto: TSlugLotto,
    selectedStartDate: MaterialUiPickersDate,
    selectedEndDate: MaterialUiPickersDate
  ) => {
    if (selectedStartDate && selectedEndDate) {
      this.setState(
        {
          lottoReport: {
            startDate: format(selectedStartDate, constants.dateFormat),
            endDate: format(selectedEndDate.setHours(23, 59,59), constants.dateFormat),
            lottery: selectedLotto,
          },
        },
        () => {
          this.props.getReportBenefitLottery(this.state.lottoReport!);
        }
      );
    }
  };

  handleExternalCasinoReport = (
    selectedExternalCasino: TSlugExternalCasino,
    selectedStartDate: MaterialUiPickersDate,
    selectedEndDate: MaterialUiPickersDate
  ) => {
    if (selectedStartDate && selectedEndDate) {
      this.setState(
        {
          externalCasinoReport: {
            startDate: format(selectedStartDate, constants.dateFormat),
            endDate: format(selectedEndDate.setHours(23, 59,59), constants.dateFormat),
            company: selectedExternalCasino,
          },
        },
        () => {
          this.props.getReportExternalCasino(this.state.externalCasinoReport!);
        }
      );
    }
  };

  handleExternalSlotReport = (
    selectedExternalSlot: TSlugExternalSlot,
    selectedStartDate: MaterialUiPickersDate,
    selectedEndDate: MaterialUiPickersDate
  ) => {
    if (selectedStartDate && selectedEndDate) {
      this.setState(
        {
          externalSlotReport: {
            startDate: format(selectedStartDate, constants.dateFormat),
            endDate: format(selectedEndDate.setHours(23, 59,59), constants.dateFormat),
            company: selectedExternalSlot,
          },
        },
        () => {
          this.props.getReportExternalSlot(this.state.externalSlotReport!);
        }
      );
    }
  };

  renderTitle = (): JSX.Element => {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.reportLabel, active: false },
      { label: constants.benefitLabel, active: true },
    ];
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadcrumb}
            />
            <TitlePage title={constants.benefitLabel} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const RenderTitle = this.renderTitle;
    return (
      <div className="report-benefit-container">
        <Grid container spacing={2}>
          <RenderTitle />
          <Grid item xs={5}>
            <BenefitCard
              title={constants.summary}
              handleReport={this.handleReportSummary}
              profit={this.props.getReportBenefitResult.profit}
              loss={this.props.getReportBenefitResult.loss}
              total={this.props.getReportBenefitResult.total}
            />
          </Grid>
          <Grid item xs={5}>
            <BenefitCard
              title={constants.lotto}
              handleReport={this.handleLottoReport}
              profit={this.props.getReportBenefitLotteryResult.profit}
              loss={this.props.getReportBenefitLotteryResult.loss}
              total={this.props.getReportBenefitLotteryResult.total}
            />
          </Grid>
          <Grid item xs={2}>
            <></>
          </Grid>
          <Grid item xs={5}>
            <BenefitCard
              title={constants.externalCasino}
              handleReport={this.handleExternalCasinoReport}
              profit={this.props.getReportBenefitExternalCasinoResult.profit}
              loss={this.props.getReportBenefitExternalCasinoResult.loss}
              total={this.props.getReportBenefitExternalCasinoResult.total}
            />
          </Grid>
          <Grid item xs={5}>
            <BenefitCard
              title={constants.externalSlot}
              handleReport={this.handleExternalSlotReport}
              profit={this.props.getReportBenefitExternalSlotResult.profit}
              loss={this.props.getReportBenefitExternalSlotResult.loss}
              total={this.props.getReportBenefitExternalSlotResult.total}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ReportBenefitContainer;
