import { createAction } from "typesafe-actions";
import {
  PUT_LOTTO_RATE_NUMBER_REQUEST,
  PUT_LOTTO_RATE_NUMBER_SUCCESS,
  PUT_LOTTO_RATE_NUMBER_FAILURE,
  PUT_LOTTO_RATE_NUMBER_CANCEL,
} from "./constants";
import { AxiosResponse, AxiosError } from "axios";

const putLottoRateNumberAction = createAction(
  PUT_LOTTO_RATE_NUMBER_REQUEST,
  (reslove) => (data: ILottoCreateRateNumberData[]) => reslove(data)
);

const putLottoRateNumberSuccessAction = createAction(
  PUT_LOTTO_RATE_NUMBER_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<object>>) => resolve(data)
);

const putLottoRateNumberFailureAction = createAction(
  PUT_LOTTO_RATE_NUMBER_FAILURE,
  (resolve) => (error: AxiosError<APIResponse>) => resolve(error)
);

const putLottoRateNumberCancelAction = createAction(
  PUT_LOTTO_RATE_NUMBER_CANCEL
);

export default {
  putLottoRateNumberAction,
  putLottoRateNumberSuccessAction,
  putLottoRateNumberFailureAction,
  putLottoRateNumberCancelAction,
};
