import { Button } from 'components';
import { isEmpty,noop } from 'lodash';
import React, { FC, useEffect, useState } from 'react'
import './Tap.style.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITap = {
  id: '',
  listTap:[],
  onClick() { noop() },
  classChildren:''
}

const Tap: FC<ITap & DefaultProps> = (props) => {

  const {
    id,
    listTap,
    onClick,
    children,
    classChildren
  } = props

  const [current, setCurrent] = useState(0)

  const changeTap = (index:number)=>{
    setCurrent(index)
    onClick!(index)
  }

  useEffect(() => {},[])

  let loop = ()=>{
    if (!isEmpty(listTap)) {
      return listTap!.map((item,index)=>(
        <Button 
          id={`tap`}
          key={id+index}
          text={item}
          color={current===index?"gray":"gray-aplha"}
          onClick={()=>{changeTap(index)}}
        />
      ))
    }
    return (<></>)
  }
  

  return (
    <div className="tap-container">
      {loop()}
      <div className={`${classChildren} children-tap secondary-dark`}>
        {children}
      </div>
    </div>
  )
}

Tap.defaultProps = defaultProps

export default Tap