import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
    catchError,
    exhaustMap,
    takeUntil,
    filter,
    map,
    switchMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
    fetchReportOverview,
    fetchReportYegee,
    fetchReportLotterNumberSession,
    fetchpostReportDepositWithdraw,
    fetchGetReportBenefit,
    fetchGetReportBenefitLottery,
    fetchGetReportBenefitExternalCasino,
    fetchGetReportBenefitExternalSlot,
} from './services'
import actions from './actions'

const reportOverviewEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportOverviewAction)),
        exhaustMap(action =>
            from(fetchReportOverview(action.payload))
                .pipe(
                    map(actions.getReportOverviewSuccessAction),
                    catchError(error => of(actions.getReportOverviewFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getReportOverviewCancelAction))))
                ),
        )
    )

const reportYegeeEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportYegeeAction)),
        exhaustMap(action =>
            from(fetchReportYegee(action.payload))
                .pipe(
                    map(actions.getReportYegeeSuccessAction),
                    catchError(error => of(actions.getReportYegeeFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getReportYegeeCancelAction))))
                ),
        )
    )

const reportLotterNumberSessionEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportLotterNumberSessionAction)),
        exhaustMap(action =>
            from(fetchReportLotterNumberSession(action.payload))
                .pipe(
                    map(actions.getReportLotterNumberSessionSuccessAction),
                    catchError(error => of(actions.getReportLotterNumberSessionFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getReportLotterNumberSessionCancelAction))))
                ),
        )
    )

const reportDepositWithdrawEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.postReportDepositWithdrawAction)),
        exhaustMap(action =>
            from(fetchpostReportDepositWithdraw(action.payload))
                .pipe(
                    map(actions.postReportDepositWithdrawSuccessAction),
                    catchError(error => of(actions.postReportDepositWithdrawFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.postReportDepositWithdrawCancelAction))))
                ),
        )
    )

const reportBenefitEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportBenefitAction)),
        switchMap(action => from(fetchGetReportBenefit(action.payload))
            .pipe(
                map(actions.getReportBenefitSuccessAction),
                catchError(error => of(actions.getReportBenefitFailureAction(error))),
                takeUntil(action$.pipe(filter(isActionOf(actions.getReportBenefitCancelAction))))
            )
        )
    )

    const reportBenefitLotteryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportBenefitLotteryAction)),
        switchMap(action => from(fetchGetReportBenefitLottery(action.payload))
            .pipe(
                map(actions.getReportBenefitLotterySuccessAction),
                catchError(error => of(actions.getReportBenefitLotteryFailureAction(error))),
                takeUntil(action$.pipe(filter(isActionOf(actions.getReportBenefitLotteryCancelAction))))
            )
        )
    )

    const reportBenefitExternalCasinoEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportBenefitExternalCasinoAction)),
        switchMap(action => from(fetchGetReportBenefitExternalCasino(action.payload))
            .pipe(
                map(actions.getReportBenefitExternalCasinoSuccessAction),
                catchError(error => of(actions.getReportBenefitExternalCasinoFailureAction(error))),
                takeUntil(action$.pipe(filter(isActionOf(actions.getReportBenefitExternalCasinoCancelAction))))
            )
        )
    )

    const reportBenefitExternalSlotEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getReportBenefitExternalSlotAction)),
        switchMap(action => from(fetchGetReportBenefitExternalSlot(action.payload))
            .pipe(
                map(actions.getReportBenefitExternalSlotSuccessAction),
                catchError(error => of(actions.getReportBenefitExternalSlotFailureAction(error))),
                takeUntil(action$.pipe(filter(isActionOf(actions.getReportBenefitExternalSlotCancelAction))))
            )
        )
    )

export default [
    reportOverviewEpic,
    reportYegeeEpic,
    reportLotterNumberSessionEpic,
    reportDepositWithdrawEpic,
    reportBenefitEpic,
    reportBenefitLotteryEpic,
    reportBenefitExternalCasinoEpic,
    reportBenefitExternalSlotEpic
]
