import React, { SFC } from 'react'
import { noop } from 'lodash'
import './error.style.scss'
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from 'components'
const constants = {
  title: 'เกิดข้อผิดพลาด',
  description: 'กรุณาลองใหม่อีกครั้ง',
  disApproval: 'ไม่อนุมัติ',
  cancel: 'ยกเลิก',
  bank: 'ธนาคาร: ',
  accountNo: 'หมายเลขบัญชี: ',
  amount: 'จำนวนเงิน: ',
  baht: ' บาท',
  actionText: 'กลับสู่หน้าหลัก',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IErrorModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
}

const ErrorModal: SFC<IErrorModal & DefaultProps> = (props: IErrorModal) => {

  return (
    <div className="error-modal-container">
      <div className="description-error-modal">
        <CancelIcon
          color="secondary"
        />
        <h5>{props.actionText}</h5>
        <h5 className="subtitle-2">{props.description}</h5>
        <div className="mg-button">
        <Button
          text="OK"
          onClick={props.action}
        />
         </div>
      </div>
    </div>
  )
}

ErrorModal.defaultProps = defaultProps

export default ErrorModal