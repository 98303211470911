import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import broadcastAction from 'reduxs/settings/actions'
import loaderAction from 'reduxs/loader/actions'
import BroadcastContainer from './Broadcast.container'

const mapStateToProps = (state: RootReducers): IBroadcastContainerProps => ({
    saveBroadcastIsFetching: state.mantra.settings.saveBroadcast.isFetching!,
    saveBroadcastError: state.mantra.settings.saveBroadcast.error!,
    saveBroadcastCode: state.mantra.settings.saveBroadcast.code!,
    saveBroadcastResult: state.mantra.settings.saveBroadcast.data!,

    postBroadcastIsFetching: state.mantra.settings.postBroadcast.isFetching!,
    postBroadcastError: state.mantra.settings.postBroadcast.error!,
    postBroadcastCode: state.mantra.settings.postBroadcast.code!,
    postBroadcastResult: state.mantra.settings.postBroadcast.data!,

    getBroadcastListIsFetching: state.mantra.settings.allBroadcast.isFetching!,
    getBroadcastListError: state.mantra.settings.allBroadcast.error!,
    getBroadcastListCode: state.mantra.settings.allBroadcast.code!,
    getBroadcastListResult: state.mantra.settings.allBroadcast.data!,

    deleteBroadcastListIsFetching: state.mantra.settings.deleteBroadcast.isFetching!,
    deleteBroadcastListError: state.mantra.settings.allBroadcast.error!,
    deleteBroadcastListCode: state.mantra.settings.allBroadcast.code!,
    deleteBroadcastListResult: state.mantra.settings.allBroadcast.data!,

    summaryBroadcastListIsFetching: state.mantra.settings.summaryBroadcast.isFetching!,
    summaryBroadcastListError: state.mantra.settings.summaryBroadcast.error!,
    summaryBroadcastListCode: state.mantra.settings.summaryBroadcast.code!,
    summaryBroadcastListResult: state.mantra.settings.summaryBroadcast.data!,

    logBroadcastListIsFetching: state.mantra.settings.broadcastLog.isFetching!,
    logBroadcastListError: state.mantra.settings.broadcastLog.error!,
    logBroadcastListCode: state.mantra.settings.broadcastLog.code!,
    logBroadcastListResult: state.mantra.settings.broadcastLog.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IBroadcastContainerActionProps => bindActionCreators({
    saveBroadcast: broadcastAction.saveBroadcastLineAction,
    postBroadcast: broadcastAction.postBroadcastLineAction,
    getListBroadcast: broadcastAction.getBroadcastLineListAction,
    summaryBroadcast: broadcastAction.summaryBroadcastLineListAction,
    deleteBroadcast: broadcastAction.deleteBroadcastLineAction,
    logBroadcast: broadcastAction.logBroadcastLineAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(BroadcastContainer)