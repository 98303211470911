import axios from 'axios'
import { get, omit } from 'lodash'
import { endpoint } from './constants'

export const fetchReportOverview = (data: IRequestReportOverview) => axios.post(`${endpoint.getReportOverview}`, data)
export const fetchReportYegee = (data: IRequestReportYegee) => axios.post(`${endpoint.getReportYegee}`, data)
export const fetchReportLotterNumberSession = (data: IRequestReportLotterNumberSession) => axios.post(`${endpoint.getReportLotterNumberSession}`, data)
export const fetchpostReportDepositWithdraw = (data: IRequestReportDepositWithdraw) => axios.post(`${endpoint.postReportDepositWithdraw}`, data)
export const fetchGetReportBenefit = (data: IQueryReportBenefit) => {
    const queryParams = new URLSearchParams({
        startDate: get(data, 'startDate', ''),
        endDate: get(data, 'endDate', ''),
    })
    return axios.get(endpoint.getReportBenefit, {
        params: queryParams
    })
}
export const fetchGetReportBenefitLottery = (data: IQueryReportBenefitLottery) => {
    let queryParams: IQueryReportBenefitLottery = {
        startDate: get(data, 'startDate', ''),
        endDate: get(data, 'endDate', ''),
        lottery: get(data, 'lottery', 'ALL')
    }
    if (queryParams.lottery === "ALL") {
        queryParams = omit(queryParams, ['lottery'])
    }
    return axios.get(endpoint.getReportBenefitLottery, {
        params: queryParams
    })
}
export const fetchGetReportBenefitExternalCasino = (data: IQueryReportBenefitExternalCasino) => {
    let queryParams: IQueryReportBenefitExternalCasino = {
        startDate: get(data, 'startDate', ''),
        endDate: get(data, 'endDate', ''),
        company: get(data, 'company', 'ALL')
    }
    if (queryParams.company === "ALL") {
        queryParams = omit(queryParams, ['company'])
    }
    return axios.get(endpoint.getReportBenefitExternalCasino, {
        params: queryParams
    })
}
export const fetchGetReportBenefitExternalSlot = (data: IQueryReportBenefitExternalSlot) => {
    let queryParams: IQueryReportBenefitExternalSlot = {
        startDate: get(data, 'startDate', ''),
        endDate: get(data, 'endDate', ''),
        company: get(data, 'company', 'ALL')
    }
    if (queryParams.company === "ALL") {
        queryParams = omit(queryParams, ['company'])
    }
    return axios.get(endpoint.getReportBenefitExternalSlot, {
        params: queryParams
    })
}