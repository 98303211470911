import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchPutLottoMode } from './services'
import actions from './actions'

const putLottoModeEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.putLottoModeAction)),
    exhaustMap(action =>
      from(fetchPutLottoMode(action.payload))
        .pipe(
          map(actions.putLottoModeSuccessAction),
          catchError(error => of(actions.putLottoModeFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.putLottoModeCancelAction))))
        ),
    )
  )

export default [
  putLottoModeEpic,
]
