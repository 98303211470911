import React, { Component } from 'react'
import { noop } from 'lodash'
import moment from 'moment'
import './ReportSummary.style.scss'
import { RouteComponentProps } from 'react-router-dom'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  TablePagination,
  Collapse,
  Box,
  Typography
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import {
  Chipper,
  Button,
  ModalDeposit,
  TextField,
  Select,
  TitlePage,
  Breadcrumb,
} from 'components'

const constants = {
  confirmDeposit: 'ยืนยันอนุมัติรายการฝาก',
  notDeposit: 'ไม่อนุมัติรายการฝาก',
  approveSureDeposit: 'คุณแน่ใจใช่หรือไม่? อนุมัติรายการฝาก',
  disApprovalSure: 'คุณแน่ใจใช่หรือไม่? ไม่อนุมัติรายการฝาก',
  approval: 'อนุมัติ',
  disApproval: 'ไม่อนุมัติ',
  reserve: 'กำลังดำเนินการอยู่...',
  image: 'รูปภาพ',
  font: 'ตัวอักษร',
  yegeeLabel: 'รายงานผลยี่กี',
  systemLabel: 'จัดการระบบ',
}

enum ESTATUS {
  SUCCESS = 'SUCCESS',
  WAIT = 'WAIT',
  REJECT = 'REJECT',
  FAIL = 'FAIL',
  RESERVE = 'RESERVE',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IContentProps & IContentActionProps = {
  loader() { noop() },
}

const data = [{
  id: 'หน้าลงทะเบียน',
  betNumber: 'รูปโฆษณาหน้ากรอกเบอร์โทร',
  statusBet: 'รูปภาพ',
  moneyIncome: 'BASE64',
  moneyOutcome: '22',
  history: [
    { date: '2020-01-05', customerId: '11091700' },
    { date: '2020-01-02', customerId: 'Anonymous' },
  ],
}]

const columns: ITransactionColumn<'id' |
  'betNumber' | 'statusBet' | 'moneyIncome' |
  'moneyOutcome'>[] = [
    { id: 'id', label: 'รายการ', minWidth: 50, align: 'left' },
    // { id: 'betNumber', label: 'รายการแทง', minWidth: 50, align: 'right' },
    // { id: 'statusBet', label: 'สถานะการแทง', minWidth: 50, align: 'left' },
    // { id: 'moneyIncome', label: 'จำนวนเงินเข้าระบบ', minWidth: 50, align: 'left' },
    // { id: 'moneyOutcome', label: 'จำนวนเงินออกระบบ', minWidth: 50, align: 'left' },
  ];

class DepositTransactionContainer extends
  Component<ITransactionProps & ITransactionActionProps & DefaultProps & RouteComponentProps,
  IReportLottoContainerState> {

  static defaultProps = defaultProps

  constructor(props: ITransactionProps & ITransactionActionProps & RouteComponentProps & DefaultProps) {
    super(props)
    this.state = {
      tableRows: [],
      dataType: 'DEPOSIT',
      dataStatus: 'WAIT',
      limit: 10,
      page: 0,
      total: 0,
      search: '',
      date: '',
      selectedDate: null,
      queryTimeout: setTimeout(() => { noop() }, 0),
      dateTimeout: setTimeout(() => { noop() }, 0),
      isShowSnack: false,
      open: true,
    }
  }

  componentDidMount() {
    this.setState({ tableRows: data.map((row) => this.createData(row)) })
    // this.setState({ tableRows: this.createData([{ set: '22', userBank: '2', bankName: '2', userTxTime: '2' }]) })
  }

  createData(txData: IReportLottoPopuate): IReportLottoRow {
    return {
      id: txData.id,
      betNumber: '',
      statusBet: '',
      moneyIncome: '',
      moneyOutcome: '',
    };
  }

  transformTime(time: string) {
    return moment(time).add('years', 543).format('Do MMM YYYY - h:mm')
  }

  transformType2Th(type: string) {
    switch (type) {
      case 'DEPOSIT':
        return 'ฝากเงิน'
      case 'WITHDRAW':
        return 'ถอนเงิน'
      default:
        return 'ผิดพลาด'
    }
  }

  setting(txData: any): JSX.Element {
    return (
      <IconButton className="primary-purple-text">
        <EditIcon />
      </IconButton>
    )
  }

  createActionElement(txData: IWalletTransactionPopuate): JSX.Element {
    switch (txData.status) {
      case ESTATUS.FAIL:
        return (
          <Chipper label={constants.disApproval} color="red" />
        )
      case ESTATUS.SUCCESS:
        return (
          <Chipper label={constants.approval} color="green" />
        )
      case ESTATUS.RESERVE:
        return (
          <div className="button-container">
            <Button
              text={`${txData.approveUserId.username} ${constants.reserve}`}
              theme="outline"
              onClick={() => this.handleforceCancelReserveWithDraw(txData)}
            />
          </div>
        )
      case ESTATUS.WAIT:
        return (
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className="button-container">
                  <Button
                    type="submit"
                    text={constants.disApproval}
                    theme="outline"
                    color="red"
                    onClick={() => this.handleOnUpdateTx(txData, ESTATUS.FAIL)}
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="button-container">
                  <Button
                    type="submit"
                    text={constants.approval}
                    color="green"
                    onClick={() => this.handleOnUpdateTx(txData, ESTATUS.SUCCESS)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        )
      default:
        return <></>
    }
  }

  handleApproveDeposit = (status: 'SUCCESS' | 'FAIL', txId: number) => {
    const reqData = {
      status: status,
      wallet_tx_id: txId,
      user_tx_time: new Date().toDateString(),
    }
    this.props.loader(true)
    this.props.putTransaction(reqData)
    ModalDeposit.success.hide()
  }

  handleCancelDeposit = (description: string, status: 'SUCCESS' | 'FAIL', txId: number) => {
    const reqData = {
      status: status,
      wallet_tx_id: txId,
      user_tx_time: new Date().toDateString(),
      description: description,
      web_bank_id: null,
    }
    this.props.loader(true)
    this.props.putTransaction(reqData)
    ModalDeposit.error.hide()
  }

  handleReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.postReserveTransaction({ wallet_tx_id: walletTxData.id })
  }
  handleCancelReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.cancelReserveTransaction({ wallet_tx_id: walletTxData.id })
  }
  handleforceCancelReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.forceCancelReserveTransaction({ wallet_tx_id: walletTxData.id })
  }


  handleOnUpdateTx(txData: IWalletTransactionPopuate, status: ESTATUS) {
    let reqData: ITransactionPutData
    switch (txData.type) {
      case 'WITHDRAW':
        this.props.loader(true)
        reqData = {
          status: status,
          wallet_tx_id: txData.id,
        }
        this.props.putTransaction(reqData)
        break
      case 'DEPOSIT':
        reqData = {
          status: status,
          wallet_tx_id: txData.id,
          web_bank_id: 1,
          pay_slip_image: 'image',
          user_tx_time: new Date().toDateString(),
        }
        if (status === 'SUCCESS') {
          this.handleReserveWithDraw(txData)
          ModalDeposit.success.show({
            action: this.handleApproveDeposit,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(txData)
              ModalDeposit.success.hide()
            },
            txId: txData.id,
            txStatus: status,
            title: constants.confirmDeposit,
            subTitle: constants.approveSureDeposit,
            name: txData.userId.username,
            bank: txData.userBankId.type,
            accountNo: txData.userBankId.number,
            money: txData.money,
          })
        }
        else {
          this.handleReserveWithDraw(txData)
          ModalDeposit.error.show({
            action: this.handleCancelDeposit,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(txData)
              ModalDeposit.error.hide()
            },
            txId: txData.id,
            txStatus: status,
            title: constants.notDeposit,
            subTitle: constants.disApprovalSure,
            name: txData.userId.username,
            bank: txData.userBankId.type,
            accountNo: txData.userBankId.number,
            money: txData.money,
          })
        }
        break
    }
  }

  handleTextAlign = (align: any) => {
    let alignType
    switch (align) {
      case 'center':
        alignType = 'MuiTableCell-alignCenter'
        break
      case 'right':
        alignType = 'MuiTableCell-alignRight'
        break
      case 'left':
        alignType = 'MuiTableCell-alignLeft'
        break
    }
    return alignType
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }
  render() {
    const { tableRows } = this.state
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel },
      { label: constants.yegeeLabel, active: true },
    ]
    return (
      <div className="content-management-container">
        <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
        <TitlePage title={constants.yegeeLabel} />
        <Paper className="secondary-dark" elevation={3}>
          <TableContainer >
            <Grid container className="table-title">
              <Grid item xs={9} >
                <div >
                  <h5>
                    รายงานผล
                  </h5>
                </div>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="ค้นหา"
                      startElement={<SearchIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      label="ประเภทข้อมูล"
                      itemList={[
                        {
                          value: 'รูปภาพ',
                          element: <h6>{constants.image}</h6>,
                        },
                        {
                          value: 'ตัวอักษร',
                          element: <h6>{constants.font}</h6>,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>







            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                {}
              </TableHead>



              <TableBody>
                <TableRow>
                  <TableCell>
                  <div className="aa">
                    <IconButton
                      color="primary"
                      aria-label="expand row"
                      size="small"
                      onClick={() => this.setState({ open: !this.state.open })}
                    >
                   
                      Hello
                      {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            
                    </IconButton>
                    </div>
                  </TableCell>

                  {/* <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
                <TableRow>
                  {
                    columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                              History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Customer</TableCell>
                                  {/* <TableCell align="right">Amount</TableCell> */}
                                  {/* <TableCell align="right">Total price ($)</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data[0].history.map((historyRow) => (
                                  <TableRow key={historyRow.date}>
                                    <TableCell component="th" scope="row">
                                      {historyRow.date}
                                    </TableCell>
                                    <TableCell>{historyRow.customerId}</TableCell>
                                    {/* <TableCell align="right">{historyRow.amount}</TableCell> */}
                                    {/* <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                        {column.label}
                      </TableCell>
                    ))
                  }
                </TableRow>


                {
                  tableRows.length === 0 && (
                    <TableRow hover role="checkbox">
                      <TableCell key={'no data'} >
                        ไม่มีข้อมูล...
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>















          </TableContainer>
          <TablePagination
            className="primary-text"
            component="div"
            count={1}
            page={1}
            onChangePage={() => '1'}
            rowsPerPage={1}
            // onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="รายการ/หน้า:"
            labelDisplayedRows={(
              { from, to, count }) => `${from}-${to} ของ ${count !== -1 ? count : `more than ${to}`}`}
          />
        </Paper>
      </div>
    )
  }
}

export default DepositTransactionContainer