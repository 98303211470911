import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const broadcastPostReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.postBroadcastLineAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postBroadcastLineSuccessAction):
      return {
        isFetching: false,
        data: [],
        code: 200,
      }

    case getType(actions.postBroadcastLineFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const saveBroadcastReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.saveBroadcastLineAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.saveBroadcastLineSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: 200,
      }

    case getType(actions.saveBroadcastLineFailureAction):
      return {
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.data.code,
      }
    default:
      return state
  }
}

const broadcastListReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.getBroadcastLineListAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getBroadcastLineListSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: 200,
      }

    case getType(actions.getBroadcastLineListFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}
const broadcastDeleteReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.deleteBroadcastLineAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.deleteBroadcastLineSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: 200,
      }

    case getType(actions.deleteBroadcastLineFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}
const broadcastSummaryReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.summaryBroadcastLineListAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.summaryBroadcastLineListSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: 200,
      }

    case getType(actions.summaryBroadcastLineListFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const broadcastLogReducer = (state: IBroadcastAllState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.logBroadcastLineAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.logBroadcastLineSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: 200,
      }

    case getType(actions.logBroadcastLineFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

export {
  broadcastPostReducer,
  broadcastListReducer,
  broadcastDeleteReducer,
  saveBroadcastReducer,
  broadcastSummaryReducer,
  broadcastLogReducer,
}