import { createAction } from 'typesafe-actions'
import {
  LOTTO_CANCEL_REQUEST,
  LOTTO_CANCEL_SUCCESS,
  LOTTO_CANCEL_FAILURE,
  LOTTO_CANCEL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const LottoCancelAction = createAction(
  LOTTO_CANCEL_REQUEST,
  reslove => (data: ILottoCancelData) => reslove(data)
)

const LottoCancelSuccessAction = createAction(
  LOTTO_CANCEL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<object>>) => resolve(data))

const LottoCancelFailureAction = createAction(
  LOTTO_CANCEL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const LottoCancelCancelAction = createAction(LOTTO_CANCEL_CANCEL)

export default {
  LottoCancelAction,
  LottoCancelSuccessAction,
  LottoCancelFailureAction,
  LottoCancelCancelAction,
}