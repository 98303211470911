import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchPostReserveTransaction, fetchDeleteReserveTransaction, fetchForceDeleteReserveTransaction } from './services'
import actions from './actions'

const postReserveEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postReserveTransactionAction)),
    exhaustMap(action =>
      from(fetchPostReserveTransaction(action.payload))
        .pipe(
          map(actions.postReserveTransactionSuccessAction),
          catchError(error => of(actions.postReserveTransactionFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.postReserveTransactionCancelAction))))
        ),
    )
  )

  const deleteReserveEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.cancelReserveTransactionAction)),
    exhaustMap(action =>
      from(fetchDeleteReserveTransaction(action.payload))
        .pipe(
          map(actions.cancelReserveTransactionSuccessAction),
          catchError(error => of(actions.cancelReserveTransactionFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.cancleReserveTransactionCancelAction))))
        ),
    )
  )

const forceDeleteReserveEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.forceCancelReserveTransactionAction)),
    exhaustMap(action =>
      from(fetchForceDeleteReserveTransaction(action.payload))
        .pipe(
          map(actions.forceCancelReserveTransactionSuccessAction),
          catchError(error => of(actions.forceCancelReserveTransactionFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.forceCancelReserveTransactionCancelAction))))
        ),
    )
  )

export default [
  postReserveEpic,
  deleteReserveEpic,
  forceDeleteReserveEpic,
]
