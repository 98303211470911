import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootAction } from "typings/reduxs/Actions";
import loaderAction from "reduxs/loader/actions";
import configAction from "reduxs/config/actions";
import NumberRateContainer from "./NumberRate.container";

const mapStateToProps = (state: RootReducers): INumberRateProps => {
  return {
    getLottoScheduleIsFetching: state.mantra.config.lotto.schedule.isFetching!,
    getLottoScheduleCode: state.mantra.config.lotto.schedule.code!,
    getLottoScheduleError: state.mantra.config.lotto.schedule.error!,
    lottoSchedule: state.mantra.config.lotto.schedule.data!,

    getLottoRateNumberIsFetching:
      state.mantra.config.numberRate.list.isFetching!,
    getLottoRateNumberCode: state.mantra.config.numberRate.list.code!,
    getLottoRateNumberError: state.mantra.config.numberRate.list.error!,
    getLottoRateNumberResult: state.mantra.config.numberRate.list,

    putLottoRateNumberIsFetching:
      state.mantra.config.numberRate.create.isFetching!,
    putLottoRateNumberCode: state.mantra.config.numberRate.create.code!,
    putLottoRateNumberError: state.mantra.config.numberRate.create.error!,
    putLottoRateNumberResult: state.mantra.config.numberRate.create.data!,
  };
};

const mapDispatchToProos = (
  dispatch: Dispatch<RootAction>
): INumberRateActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getLottoSchedule: configAction.getLottoScheduleAction,
      getLottoRateNumber: configAction.getLottoRateNumberAction,
      clearLottoRateNumber: configAction.getLottoRateNumberCancelAction,
      putLottoRateNumber: configAction.putLottoRateNumberAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProos
)(NumberRateContainer);
