import { createAction } from 'typesafe-actions'
import {
  GET_BET_RATE_REQUEST,
  GET_BET_RATE_SUCCESS,
  GET_BET_RATE_FAILURE,
  GET_BET_RATE_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getBetRateAction = createAction(GET_BET_RATE_REQUEST,
  reslove => (data: IGetBetRateParams) => reslove(data)
)

const getBetRateSuccessAction = createAction(
  GET_BET_RATE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetRate[]>>) => resolve(data))

const getBetRateFailureAction = createAction(
  GET_BET_RATE_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getBetRateCancelAction = createAction(GET_BET_RATE_CANCEL)


export default {
  getBetRateAction,
  getBetRateSuccessAction,
  getBetRateFailureAction,
  getBetRateCancelAction,
}