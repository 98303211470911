import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  exhaustMap,
  filter,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const setDateEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf([actions.setDateAction, actions.clearDateAction])),
    exhaustMap((action) => of(
      actions.persistedDateAction(action.payload),
    ))
  )

export default [
  setDateEpic,
]
