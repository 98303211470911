import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Grid } from '@material-ui/core'
import { number } from 'utils'

import './DepositWithdrawSummary.component.scss'

const keys = ['ยอดรวมฝากเงิน', 'ยอดรวมถอนเงิน']
const commonProps = {
margin: { top: 60, right: 80, bottom: 60, left: 80 },
indexBy: 'type',
keys,
padding: 0.5,
labelTextColor: 'inherit:darker(1.4)',
labelSkipWidth: 16,
labelSkipHeight: 16,
}


class YegeeSummary extends Component<IResponseReportDepositWithdraw, {}> {
    render() {
        return (
            <div className="deposit-withdraw-summary-container">
                <ResponsiveBar
                    {...commonProps}
                    data={this.props.dataList}
                    borderWidth={.5}
                    borderColor="#fff"
                    layout={this.props.graphType}
                    colors={{ scheme: 'purpleRed_green' }}
                    defs={[
                        {
                            id: 'gradientB',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'red' },
                                { offset: 100, color: 'rgb(244, 117, 96)' },
                            ],
                        },
                        {
                            id: 'gradientC',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'rgb(167, 295, 187)' },
                                { offset: 100, color: 'rgb(97, 205, 187)' },
                            ],
                        },
                    ]}
                    fill={[
                        { match: {  id: 'ยอดรวมถอนเงิน' }, id:  'gradientB'},
                        { match: {  id: 'ยอดรวมฝากเงิน' }, id:  'gradientC'},
                    ]}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 50,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 200,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
                <div className="detail-container">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4>รายละเอียด</h4>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>เงินคงค้างระบบ</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${this.props.balance >= 0 ? 'green' : 'red'}-text`}>
                            {number.numberFormat(String(this.props.balance))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                </div >
            </div >
        )
    }
}

export default YegeeSummary