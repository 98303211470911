import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
  fetchSaveBroadcast,
  fetchPostBroadcast,
  fetchListBroadcast,
  fetchDeleteBroadcast,
  fetchSummaryBroadcast,
  fetchLogBroadcast,
} from './services'
import actions from './actions'

const broadcastSaveEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.saveBroadcastLineAction)),
    exhaustMap(action =>
      from(fetchSaveBroadcast(action.payload))
        .pipe(
          map(actions.saveBroadcastLineSuccessAction),
          catchError(error => of(actions.saveBroadcastLineFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.saveBroadcastLineCancelAction))))
        ),
    )
  )

const broadcastPostEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postBroadcastLineAction)),
    exhaustMap(action =>
      from(fetchPostBroadcast(action.payload))
        .pipe(
          map(actions.postBroadcastLineSuccessAction),
          catchError(error => of(actions.postBroadcastLineFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.postBroadcastLineCancelAction))))
        ),
    )
  )

const broadcastListEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getBroadcastLineListAction)),
    exhaustMap(_ =>
      from(fetchListBroadcast())
        .pipe(
          map(actions.getBroadcastLineListSuccessAction),
          catchError(error => of(actions.getBroadcastLineListFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getBroadcastLineListCancelAction))))
        ),
    )
  )

const broadcastDeleteEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.deleteBroadcastLineAction)),
    exhaustMap(action =>
      from(fetchDeleteBroadcast(action.payload))
        .pipe(
          map(actions.deleteBroadcastLineSuccessAction),
          catchError(error => of(actions.deleteBroadcastLineFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.deleteBroadcastLineCancelAction))))
        ),
    )
  )

const broadcastSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.summaryBroadcastLineListAction)),
    exhaustMap(_ =>
      from(fetchSummaryBroadcast())
        .pipe(
          map(actions.summaryBroadcastLineListSuccessAction),
          catchError(error => of(actions.summaryBroadcastLineListFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.summaryBroadcastLineListCancelAction))))
        ),
    )
  )

const broadcastLogEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.logBroadcastLineAction)),
    exhaustMap(_ =>
      from(fetchLogBroadcast())
        .pipe(
          map(actions.logBroadcastLineSuccessAction),
          catchError(error => of(actions.logBroadcastLineFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.logBroadcastLineCancelAction))))
        ),
    )
  )

export {
  broadcastSaveEpic,
  broadcastPostEpic,
  broadcastListEpic,
  broadcastDeleteEpic,
  broadcastSummaryEpic,
  broadcastLogEpic,
}
