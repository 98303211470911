import project from 'constants/project'

export const LOTTO_CANCEL_REQUEST = ' LOTTO_CANCEL_REQUEST'
export const LOTTO_CANCEL_SUCCESS = ' LOTTO_CANCEL_SUCCESS'
export const LOTTO_CANCEL_FAILURE = ' LOTTO_CANCEL_FAILURE'
export const LOTTO_CANCEL_CANCEL = ' LOTTO_CANCEL_CANCEL'

export const initialState: ReducerState<object> = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}

export const endpoint = {
  deleteLottoRateLevel: `${project.environment[project.environmentName].api}/lotter/bet/cancel`,
}