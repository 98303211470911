import React, { SFC } from 'react'
import { noop } from 'lodash'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import './DatePicker.component.scss'
import EventRoundedIcon from '@material-ui/icons/EventRounded';

import { IconButton, InputAdornment } from "@material-ui/core";
import { th } from 'date-fns/locale';
type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDateTimeProps = {
    id: '',
    label: '',
    value: null,
    disableToolbar: true,
    variant: 'inline',
    margin: 'normal',
    onClick() { noop() },
    onOpen() { noop() },
    onClose() { noop()},
    onChange(date: Date | null) { noop() },
    fullWidth: false,
    disabled: false,
    disablePast: false,
}

const DateTimeComponent: SFC<IDateTimeProps & DefaultProps> = (props) => {
    const {
        id,
        label,
        disableToolbar,
        variant,
        onOpen,
        // onClick,
        onChange,
        onClose,
        value,
        fullWidth,
        disabled,
        disablePast,
    } = props

    return (
        <div className="text-field-container">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={th}>
                <DatePicker
                    autoOk
                    views={['year','month']}
                    disablePast={disablePast}
                    disableFuture={true}
                    disabled={disabled}
                    id={id}
                    label={label}
                    disableToolbar={disableToolbar}
                    variant={variant}
                    inputVariant="outlined"
                    format="yyyy/MM"
                    value={value}
                    onOpen={onOpen}
                    onClose={onClose}
                    onChange={onChange} 
                    fullWidth={fullWidth}
                    placeholder={`${label}`}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                         <EventRoundedIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}
DateTimeComponent.defaultProps = defaultProps
export default DateTimeComponent