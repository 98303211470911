import project from 'constants/project'

export const GET_TRANSACTION_ALL_REQUEST = 'GET_TRANSACTION_ALL_REQUEST'
export const GET_TRANSACTION_ALL_SUCCESS = 'GET_TRANSACTION_ALL_SUCCESS'
export const GET_TRANSACTION_ALL_FAILURE = 'GET_TRANSACTION_ALL_FAILURE'
export const GET_TRANSACTION_ALL_CANCEL = 'GET_TRANSACTION_ALL_CANCEL'

export const UPDATE_TRANSACTION_ALL_REQUEST = 'UPDATE_TRANSACTION_ALL_REQUEST'
export const UPDATE_TRANSACTION_ALL_SUCCESS = 'UPDATE_TRANSACTION_ALL_SUCCESS'
export const UPDATE_TRANSACTION_ALL_FAILURE= 'UPDATE_TRANSACTION_ALL_FAILURE'

export const UPDATE_TRANSACTION_WITHDRAW_ALL_REQUEST = 'UPDATE_TRANSACTION_WITHDRAW_ALL_REQUEST'
export const UPDATE_TRANSACTION_WITHDRAW_ALL_SUCCESS = 'UPDATE_TRANSACTION_WITHDRAW_ALL_SUCCESS'
export const UPDATE_TRANSACTION_WITHDRAW_ALL_FAILURE= 'UPDATE_TRANSACTION_WITHDRAW_ALL_FAILURE'

export const UNLISTEN_TRANSACTION_ALL_SOCKET = 'UNLISTEN_TRANSACTION_ALL_SOCKET'
export const CLEAR_TRANSACTION_ALL = 'CLEAR_TRANSACTION_ALL'

export const initialState: ITransactionAllState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getTransactionAll: `${project.environment[project.environmentName].api}/finance/all`,
}