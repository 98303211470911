import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb,
} from 'components'
import {
    Grid,
    Paper,
} from '@material-ui/core'
import { responseCode } from 'constants/response'
import { transformer } from 'utils'
import { noop } from 'lodash'
import './Affilate.container.scss'

const constants = {
    settingLabel: 'ตั้งค่าระบบ',
    lottoLabel: 'แนะนำสมาชิก',
  }

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAffilateContainerProps & IAffilateContainerActionProps = {
    getAffilateAll() { noop() },
    getAffilateAllCode: 0,
    getAffilateAllError: '',
    getAffilateAllIsFetching: false,
    getAffilateAllResult: {
        lotterRate: '',
        gameRate: '',
        ballRate: '',
        systemRate: '',
    },
    putAffilate() { noop() },
    putAffilateCode: 0,
    putAffilateError: '',
    putAffilateIsFetching: false,
    putAffilateResult: [],
    loader() { noop() },
}

class AffilateContainer extends
    Component<IAffilateContainerProps & IAffilateContainerActionProps & DefaultProps & RouteComponentProps,
    IAffilateContainerState> {

    static defaultProps = defaultProps

    constructor(props: IAffilateContainerProps & IAffilateContainerActionProps & DefaultProps & RouteComponentProps) {
        super(props)
        this.state = {
            affLotterRate: '0',
            affGameRate: '0',
            affBallRate: '0',
            affSystemRate: '0',
        }
    }

    componentDidMount() {
        this.props.getAffilateAll()
        this.props.loader(true)
    }

    componentDidUpdate(prevProps: IAffilateContainerProps) {
        if (prevProps.getAffilateAllIsFetching !== this.props.getAffilateAllIsFetching
            && !this.props.getAffilateAllIsFetching) {
            if (this.props.getAffilateAllCode === responseCode.OK) {
                this.props.loader(false)
                const calData = this.transformObjectNum2Percentage(this.props.getAffilateAllResult)
                this.setState({
                    affLotterRate: calData.lotterRate,
                    affGameRate: calData.gameRate,
                    affBallRate: calData.ballRate,
                    affSystemRate: calData.systemRate,
                })
            }
            else {
                // TODO
            }
        }
        if (prevProps.putAffilateIsFetching !== this.props.putAffilateIsFetching
            && !this.props.putAffilateIsFetching) {
            if (this.props.putAffilateCode === responseCode.OK) {
                this.props.loader(false)
            }
            else {
                // TODO
            }
        }
    }


    transformObjectNum2Percentage(data: any) {
        return transformer.calPercentage(data)
    }

    handleaffRate = (affRate: string) => {
        affRate = affRate.replace(/\+/g, '')
        if (Number(affRate) > 100) {
            return '100'
        }
        else if (Number(affRate) < 0 || affRate === '') {
            return ''
        }
        else {
            return affRate
        }
    }


    onUpdateAffRate = () => {
        const {
            affLotterRate,
            affBallRate,
            affGameRate,
            affSystemRate,
        } = this.state
        const reqData = {
            lotter_rate: String(Number(affLotterRate) / 100),
            ball_rate: String(Number(affBallRate) / 100),
            game_rate: String(Number(affGameRate) / 100),
            system_rate: String(Number(affSystemRate) / 100),
        }
        this.props.loader(true)
        this.props.putAffilate(reqData)
    }

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
      }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.settingLabel },
            { label: constants.lottoLabel, active: true },
          ]
        const {
            affLotterRate,
            affBallRate,
            affGameRate,
            affSystemRate,
        } = this.state
        return (
            <div className="news-container">
                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title="แนะนำสมาชิก" />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">ตั้งค่าสมาชิก</div>
                            <div className="paper-body">
                                <TextField
                                    label="เรทหวยของสมาชิก (ร้อยละ) *"
                                    fullWidth={true}
                                    type="number"
                                    value={affLotterRate}
                                    onChange={(e) => this.setState(
                                        { affLotterRate: this.handleaffRate(e.target.value) }
                                    )}
                                    placeholder="เรทหวยของสมาชิก  (ร้อยละ) *"

                                />
                                <TextField
                                    label="เรทเกมของสมาชิก (ร้อยละ) *"
                                    fullWidth={true}
                                    type="number"
                                    value={affGameRate}
                                    onChange={(e) => this.setState(
                                        { affGameRate: this.handleaffRate(e.target.value) }
                                    )}
                                    placeholder="เรทเกมของสมาชิก  (ร้อยละ) *"

                                />
                                <TextField
                                    label="เรทบอลของสมาชิก (ร้อยละ) *"
                                    fullWidth={true}
                                    type="number"
                                    value={affBallRate}
                                    onChange={(e) => this.setState(
                                        { affBallRate: this.handleaffRate(e.target.value) }
                                    )}
                                    placeholder="เรทบอลของสมาชิก  (ร้อยละ) *"

                                />
                                <TextField
                                    label="เรทค่าธรรมเนียมของระบบ (ร้อยละ) *"
                                    fullWidth={true}
                                    type="number"
                                    value={affSystemRate}
                                    onChange={(e) => this.setState(
                                        { affSystemRate: this.handleaffRate(e.target.value) }
                                    )}
                                    placeholder="เรทค่าธรรมเนียมของระบบ  (ร้อยละ) *"

                                />
                            </div>
                            <div className="paper-bottom">
                                <Button
                                    text="บันทึก"
                                    fullWidth={true}
                                    onClick={this.onUpdateAffRate}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        {}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default AffilateContainer