import { createAction } from 'typesafe-actions'
import {
  GET_YEGEE_GAME_CONFIG_REQUEST,
  GET_YEGEE_GAME_CONFIG_SUCCESS,
  GET_YEGEE_GAME_CONFIG_FAILURE,
  GET_YEGEE_GAME_CONFIG_CANCEL,

  PUT_YEGEE_GAME_CONFIG_REQUEST,
  PUT_YEGEE_GAME_CONFIG_SUCCESS,
  PUT_YEGEE_GAME_CONFIG_FAILURE,
  PUT_YEGEE_GAME_CONFIG_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getYegeeGameConfigAllAction = createAction(GET_YEGEE_GAME_CONFIG_REQUEST)

const getYegeeGameConfigAllSuccessAction = createAction(
  GET_YEGEE_GAME_CONFIG_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IYegeeConfigAll>>) => resolve(data))

const getYegeeGameConfigAllFailureAction = createAction(
  GET_YEGEE_GAME_CONFIG_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getYegeeGameConfigAllCancelAction = createAction(GET_YEGEE_GAME_CONFIG_CANCEL)

const putYegeeGameConfigAction = createAction(
  PUT_YEGEE_GAME_CONFIG_REQUEST,
  reslove => (data: IPutYegeeConfigState) => reslove(data)
)

const putYegeeGameConfigSuccessAction = createAction(
  PUT_YEGEE_GAME_CONFIG_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IYegeeConfigAll>>) => resolve(data))

const putYegeeGameConfigFailureAction = createAction(
  PUT_YEGEE_GAME_CONFIG_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const putYegeeGameConfigCancelAction = createAction(PUT_YEGEE_GAME_CONFIG_CANCEL)

export default {
  getYegeeGameConfigAllAction,
  getYegeeGameConfigAllSuccessAction,
  getYegeeGameConfigAllFailureAction,
  getYegeeGameConfigAllCancelAction,

  putYegeeGameConfigAction,
  putYegeeGameConfigSuccessAction,
  putYegeeGameConfigFailureAction,
  putYegeeGameConfigCancelAction,
}