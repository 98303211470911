import { createAction } from 'typesafe-actions'
import {
  SAVE_BROADCAST_LINE_REQUEST,
  SAVE_BROADCAST_LINE_SUCCESS,
  SAVE_BROADCAST_LINE_FAILURE,
  SAVE_BROADCAST_LINE_CANCEL,

  POST_BROADCAST_LINE_REQUEST,
  POST_BROADCAST_LINE_SUCCESS,
  POST_BROADCAST_LINE_FAILURE,
  POST_BROADCAST_LINE_CANCEL,

  GET_BROADCAST_LINE_LIST_REQUEST,
  GET_BROADCAST_LINE_LIST_SUCCESS,
  GET_BROADCAST_LINE_LIST_FAILURE,
  GET_BROADCAST_LINE_LIST_CANCEL,

  DELETE_BROADCAST_LINE_LIST_REQUEST,
  DELETE_BROADCAST_LINE_LIST_SUCCESS,
  DELETE_BROADCAST_LINE_LIST_FAILURE,
  DELETE_BROADCAST_LINE_LIST_CANCEL,

  SUMMARY_BROADCAST_LINE_LIST_REQUEST,
  SUMMARY_BROADCAST_LINE_LIST_SUCCESS,
  SUMMARY_BROADCAST_LINE_LIST_FAILURE,
  SUMMARY_BROADCAST_LINE_LIST_CANCEL,

  LOG_BROADCAST_LINE_REQUEST,
  LOG_BROADCAST_LINE_SUCCESS,
  LOG_BROADCAST_LINE_FAILURE,
  LOG_BROADCAST_LINE_CANCEL,

} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const saveBroadcastLineAction = createAction(
  SAVE_BROADCAST_LINE_REQUEST,
  reslove => (data: IBroadcastSaveData) => reslove(data)
)

const saveBroadcastLineSuccessAction = createAction(
  SAVE_BROADCAST_LINE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const saveBroadcastLineFailureAction = createAction(
  SAVE_BROADCAST_LINE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const saveBroadcastLineCancelAction = createAction(SAVE_BROADCAST_LINE_CANCEL)

const postBroadcastLineAction = createAction(
  POST_BROADCAST_LINE_REQUEST,
  reslove => (data: IBroadcastPostData) => reslove(data)
)

const postBroadcastLineSuccessAction = createAction(
  POST_BROADCAST_LINE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const postBroadcastLineFailureAction = createAction(
  POST_BROADCAST_LINE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const postBroadcastLineCancelAction = createAction(POST_BROADCAST_LINE_CANCEL)

const getBroadcastLineListAction = createAction(GET_BROADCAST_LINE_LIST_REQUEST)

const getBroadcastLineListSuccessAction = createAction(
  GET_BROADCAST_LINE_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const getBroadcastLineListFailureAction = createAction(
  GET_BROADCAST_LINE_LIST_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getBroadcastLineListCancelAction = createAction(GET_BROADCAST_LINE_LIST_CANCEL)

const deleteBroadcastLineAction = createAction(
  DELETE_BROADCAST_LINE_LIST_REQUEST,
  reslove => (data: IBroadcastDeleteData) => reslove(data)
)

const deleteBroadcastLineSuccessAction = createAction(
  DELETE_BROADCAST_LINE_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const deleteBroadcastLineFailureAction = createAction(
  DELETE_BROADCAST_LINE_LIST_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const deleteBroadcastLineCancelAction = createAction(DELETE_BROADCAST_LINE_LIST_CANCEL)

const summaryBroadcastLineListAction = createAction(SUMMARY_BROADCAST_LINE_LIST_REQUEST)

const summaryBroadcastLineListSuccessAction = createAction(
  SUMMARY_BROADCAST_LINE_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const summaryBroadcastLineListFailureAction = createAction(
  SUMMARY_BROADCAST_LINE_LIST_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const summaryBroadcastLineListCancelAction = createAction(SUMMARY_BROADCAST_LINE_LIST_CANCEL)


const logBroadcastLineAction = createAction(LOG_BROADCAST_LINE_REQUEST)

const logBroadcastLineSuccessAction = createAction(
  LOG_BROADCAST_LINE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBroadcast>>) => resolve(data))

const logBroadcastLineFailureAction = createAction(
  LOG_BROADCAST_LINE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const logBroadcastLineCancelAction = createAction(LOG_BROADCAST_LINE_CANCEL)

export default {
  saveBroadcastLineAction,
  saveBroadcastLineSuccessAction,
  saveBroadcastLineFailureAction,
  saveBroadcastLineCancelAction,
  postBroadcastLineAction,
  postBroadcastLineSuccessAction,
  postBroadcastLineFailureAction,
  postBroadcastLineCancelAction,
  getBroadcastLineListAction,
  getBroadcastLineListSuccessAction,
  getBroadcastLineListFailureAction,
  getBroadcastLineListCancelAction,
  deleteBroadcastLineAction,
  deleteBroadcastLineSuccessAction,
  deleteBroadcastLineFailureAction,
  deleteBroadcastLineCancelAction,
  summaryBroadcastLineListAction,
  summaryBroadcastLineListSuccessAction,
  summaryBroadcastLineListFailureAction,
  summaryBroadcastLineListCancelAction,
  logBroadcastLineAction,
  logBroadcastLineSuccessAction,
  logBroadcastLineFailureAction,
  logBroadcastLineCancelAction,
}