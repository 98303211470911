import moment, { Moment } from 'moment-timezone'
import React from 'react'
import { ESCAPED_TIMEZONE } from 'constants/regex'
import { isEmpty, get } from 'lodash'

const convertTimeToMoment = (hours: string = '00', minutes: string = '00'): Moment => {
  const momentTime = moment(`${hours}:${minutes}`, 'HH:mm')
  return momentTime
}

const calibratingTime = (timeasString?: string): Moment => {
  const localTime = moment().local()
  if (isEmpty(timeasString)) { return localTime }

  const localTimezoneArray = localTime.format().match(ESCAPED_TIMEZONE)
  const escapedTimezoneArray = timeasString!.match(ESCAPED_TIMEZONE)

  const servertime = get(escapedTimezoneArray, '0', '')
  const localTimezone = get(localTimezoneArray, '1', '')
  if (isEmpty(servertime)) { return localTime }

  const calibratedTimeString = `${servertime}${localTimezone}`
  const calibratedTime = moment(calibratedTimeString).local()

  return calibratedTime
}

const buildDaysInMonthSelect = (): { value: string; element: JSX.Element }[] => {
  const numberDays = moment().daysInMonth()
  const dayInMonthList = []
  for (let i = 0; i < numberDays; i++) {
      dayInMonthList.push({
          value: String(i).length === 1 ? `0${String(i)}` : String(i),
          element: (<h6>{String(i).length === 1 ? `0${String(i)}` : String(i)}</h6>),
      })
  }
  return dayInMonthList
}

const FormatDate = (time : string, format: string) => {  
  return moment(time).add(543, 'years').format(format)
}

export default {
  convertTimeToMoment,
  calibratingTime,
  buildDaysInMonthSelect,
  FormatDate
}