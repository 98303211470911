import tokenEpic from './token/epics'
import userMeEpic from './me/epics'
import userAllEpic from './all/epics'
import userCreateEpic from './create/epics'
import userEditEpic from './edit/epics'
import userDeleteEpic from './delete/epics'
import userLineEpic from './line/epics'
import dateOverviweEpic from './dateOverviwe/epics'
import criminalEpic from './criminal/epics'
import criminalLogEpicAction from './criminalLog/epics'
export default [
    ...tokenEpic,
    ...userMeEpic,
    ...userAllEpic,
    ...userCreateEpic,
    ...userEditEpic,
    ...userDeleteEpic,
    ...userLineEpic,
    ...dateOverviweEpic,
    ...criminalEpic,
    ...criminalLogEpicAction,
]