import { createAction } from 'typesafe-actions'
import {
  POST_RESERVE_TRANSACTION_REQUEST,
  POST_RESERVE_TRANSACTION_SUCCESS,
  POST_RESERVE_TRANSACTION_FAILURE,
  POST_RESERVE_TRANSACTION_CANCEL,

  CANCEL_RESERVE_TRANSACTION_REQUEST,
  CANCEL_RESERVE_TRANSACTION_SUCCESS,
  CANCEL_RESERVE_TRANSACTION_FAILURE,
  CANCEL_RESERVE_TRANSACTION_CANCEL,

  FORCE_CANCEL_RESERVE_TRANSACTION_REQUEST,
  FORCE_CANCEL_RESERVE_TRANSACTION_SUCCESS,
  FORCE_CANCEL_RESERVE_TRANSACTION_FAILURE,
  FORCE_CANCEL_RESERVE_TRANSACTION_CANCEL,

} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postReserveTransactionAction = createAction(
  POST_RESERVE_TRANSACTION_REQUEST,
  reslove => (data: IWalletTransactionReserve) => reslove(data)
)
const postReserveTransactionSuccessAction = createAction(
  POST_RESERVE_TRANSACTION_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWalletTransactionPopuate>>) => resolve(data))
const postReserveTransactionFailureAction = createAction(
  POST_RESERVE_TRANSACTION_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const postReserveTransactionCancelAction = createAction(POST_RESERVE_TRANSACTION_CANCEL)


const cancelReserveTransactionAction = createAction(
  CANCEL_RESERVE_TRANSACTION_REQUEST,
  reslove => (data: IWalletTransactionCancelReserve) => reslove(data)
)
const cancelReserveTransactionSuccessAction = createAction(
  CANCEL_RESERVE_TRANSACTION_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWalletTransactionPopuate>>) => resolve(data))
const cancelReserveTransactionFailureAction = createAction(
  CANCEL_RESERVE_TRANSACTION_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const cancleReserveTransactionCancelAction = createAction(CANCEL_RESERVE_TRANSACTION_CANCEL)


const forceCancelReserveTransactionAction = createAction(
  FORCE_CANCEL_RESERVE_TRANSACTION_REQUEST,
  reslove => (data: IWalletTransactionCancelReserve) => reslove(data)
)
const forceCancelReserveTransactionSuccessAction = createAction(
  FORCE_CANCEL_RESERVE_TRANSACTION_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWalletTransactionPopuate>>) => resolve(data))
const forceCancelReserveTransactionFailureAction = createAction(
  FORCE_CANCEL_RESERVE_TRANSACTION_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const forceCancelReserveTransactionCancelAction = createAction(FORCE_CANCEL_RESERVE_TRANSACTION_CANCEL)

export default {
  postReserveTransactionAction,
  postReserveTransactionSuccessAction,
  postReserveTransactionFailureAction,
  postReserveTransactionCancelAction,
  cancelReserveTransactionAction,
  cancelReserveTransactionSuccessAction,
  cancelReserveTransactionFailureAction,
  cancleReserveTransactionCancelAction,
  forceCancelReserveTransactionAction,
  forceCancelReserveTransactionSuccessAction,
  forceCancelReserveTransactionFailureAction,
  forceCancelReserveTransactionCancelAction,
}