import React, { SFC, useEffect, useState } from "react";
import { noop, cloneDeep, filter, get } from "lodash";
import "./confirm.style.scss";
import { Grid } from "@material-ui/core";
import { Button, ResponsiveIcon } from "components";
import ErrorIcon from "assets/images/modal/error/error.png";
import ErrorIcon2x from "assets/images/modal/error/error@2x.png";
import ErrorIcon3x from "assets/images/modal/error/error@3x.png";
import AlertIcon from "assets/images/modal/alert/Alert.png";
import number from "utils/number";

const constants = {
  description: "ยืนยันการทำรายการ",
  errorMaxrate: "อัตราจ่ายเกินสูงสุด",
  accepts: "ยืนยัน",
  cancel: "ยกเลิก",
  edit: "กลับไปแก้ไข",
  twoDown: "2 ตัวล่าง",
  threeUp: "3 ตัวบน",
  fourSuit: "เลขชุด 4 ตัว",
  modeBet: [
    { key: "THREE_UP", label: "3 บน", rate: "threeUpRate" },
    { key: "THREE_TOAST", label: "3 โต๊ด", rate: "threeToastRate" },
    { key: "TWO_UP", label: "2 บน", rate: "twoUpRate" },
    { key: "TWO_DOWN", label: "2 ล่าง", rate: "twoDownRate" },
    { key: "RUN_UP", label: "วิ่งบน", rate: "runUpRate" },
    { key: "RUN_DOWN", label: "วิ่งล่าง", rate: "runDownRate" },
  ],
  colorWhite: "#FFFFFF",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IAcceptsModal = {
  description: constants.description,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },

  fourSuit: "",
  twoDown: "",
  threeUp: "",
  child: <></>,
  height: "",
  typeIcon: "Error",
};

const ConfirmModal: SFC<IconfirmNumberRateModal & DefaultProps> = (
  props: IconfirmNumberRateModal
) => {
  const containerClass = `accepts-number-rate-modal-container`;

  const [lottoList, setLottoList] = useState<IObjectLottoRate[]>(
    props.lottoList!
  );

  const [dataListFail] = useState(props.dataListFail!);

  useEffect(() => {
    detailModal(lottoList);
    // eslint-disable-next-line
  }, [lottoList]);
  const handleClick = (data: IObjectLottoRate[], index: number) => {
    if (!props.isResponseFailure) {
      let newListNumber = cloneDeep(data);
      newListNumber.splice(index, 1);
      props.handleCallback!(newListNumber);
      setLottoList(newListNumber);
    }
  };
  const detailModal = (data: IObjectLottoRate[]) => {
    if (data.length === 0) {
      props.cancelAction!()
    } else {
      return (
        <>
          <div className="box-list-bet">
            {constants.modeBet.map((element, index) => {
              const dataFilter = filter(data, ["type", element.key]);

              const indexFailStatus: boolean = dataListFail?.some(
                (e) => e.type === dataFilter[0]?.type
              );

              return dataFilter.length ? (
                <div className="box-items" key={index}>
                  <div className="d-flex">
                    <span style={{ color: constants.colorWhite }}>{`${
                      element.label
                    } ${number.decimalsFormatCommaWithOutZero(
                      get(dataFilter, "[0].rate", "0")
                    )} บ.`}</span>

                    {props.isResponseFailure && indexFailStatus && (
                      <span className="errorText">
                        {constants.errorMaxrate}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col list-bet">
                      {data!.map((item, index) => {
                        return element.key === item.type ? (
                          <div
                            className={`number-bet length-${
                              item.number.length
                            } ${
                              indexFailStatus && props.isResponseFailure
                                ? "fail"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              handleClick(data, index);
                            }}
                          >
                            {item.number}
                          </div>
                        ) : (
                          <React.Fragment key={index}></React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              );
            })}
          </div>
        </>
      );
    }
  };

  return (
    <div className={containerClass}>
      <div className="description-error-modal">
        <ResponsiveIcon
          alt="error-icon"
          className={
            props.typeIcon === "Error"
              ? "error-checked-icon"
              : "alert-checked-icon"
          }
          icon={
            props.typeIcon === "Error"
              ? { x1: ErrorIcon, x2: ErrorIcon2x, x3: ErrorIcon3x }
              : { x1: AlertIcon, x2: AlertIcon, x3: AlertIcon }
          }
        />
        <div className="description">
          <h5>{`${props.description}`}</h5>
          {props.isResponseFailure && <h5>{`${props.description2}`}</h5>}
        </div>

        {detailModal(lottoList)}

        <div className="m1-t">
          <Grid container justify="center" spacing={2}>
            {props.isResponseFailure ? (
              <>
                <Grid item xs={12}>
                  <div className="footer-wrapper-success-modal">
                    <Button
                      onClick={props.editAction}
                      text={constants.edit}
                      color="purple"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <div className="footer-wrapper-success-modal">
                    <Button
                      onClick={props.cancelAction}
                      text={constants.cancel}
                      color="red"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="footer-wrapper-success-modal">
                    <Button
                      onClick={props.action}
                      text={constants.accepts}
                      color="green"
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.defaultProps = defaultProps;

export default ConfirmModal;
