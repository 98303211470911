import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import {
    Paper,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
} from '@material-ui/core'
import './DeleteUser.scss'
import { History } from 'history';
import { responseCode } from 'constants/response'
import { noop, get } from 'lodash'
import {
    Button,
    Breadcrumb,
    TitlePage,
} from 'components'

const constants = {
    detail: 'รายละเอียด',
    placeholderUsername: 'ชื่อผู้ใช้ *',
    placeholderPassword: 'รหัสผ่าน *',
    placeholderConfirmPassword: 'ยืนยันรหัสผ่าน *',
    placeholderAffilate: 'รหัสคนชวน ',
    placeholderPhoneNumber: 'ระบุหมายเลขโทรศัพท์ 10 หลัก',
    placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),
    selectBankText: 'เลือกธนาคาร *',
    placeholderBankNumber: 'หมายเลขบัญชีธนาคาร *',
    placeholderOwnerName: 'ชื่อ - นามสกุลบัญชีธนาคาร *',
    buttonConfirm: 'สมัครสมาชิก',
    userLabel: 'ผู้ใช้',
    systemLabel: 'จัดการระบบ',
    deleteUserLabel: 'ลบผู้ใช้',
}

type DefaultProps = Readonly<typeof defaultProps>


const defaultProps: IUserDeleteManagementProps & IDeleteManagementActionProps = {
    deleteUser() { noop() },
    loader() { noop() },
    deleteUserCode: 0,
    deleteUserError: '',
    deleteUserIsFetching: false,
    deleteUserResult: [{
        id: 0,
        username: '',
        password: '',
        phoneNumber: '',
        walletId: 0,
        userBankId: 0,
        affilateMeUuid: '',
        permission: '',
        isFake: false,
        createdAt: '',
        updatedAt: '',
        bank: {
            id: 0,
            type: '',
            name: '',
            number: '',
            isFake: false,
            createdAt: '',
            updatedAt: '',
        },
        wallet: '',
        action: '',
    }],
}
class DeleteManagement
    extends Component<IDeleteManagementActionProps & IUserDeleteManagementProps & { history: History },
    & IUserManagementCreateContainerState> {

    static defaultProps = defaultProps
    constructor(props: IDeleteManagementActionProps & IUserDeleteManagementProps & { history: History }
        & DefaultProps) {
        super(props)
        this.state = {
            bank: '',
        }
    }

    componentDidUpdate(prevProps: IUserDeleteManagementProps) {
        if ((prevProps.deleteUserIsFetching !== this.props.deleteUserIsFetching
            && !this.props.deleteUserIsFetching)) {
            if (this.props.deleteUserCode === responseCode.OK) {
                this.props.loader(false)
                this.props.history.push('/user-management')
            }
            else {
                this.props.loader(false)
                // TODO
            }
        }
    }
    onDeleteWebBank = () => {
        this.props.loader(true)
        this.props.deleteUser({
            id: Number(get(this.props.history.location.state, 'deleteUser', 0)),
        })
    }

    handleWebBankChange = (bankName: string) => {
        this.setState({ bank: bankName })
    }
    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
    }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.userLabel, path: '/user-management', active: false },
            { label: constants.deleteUserLabel, active: true },
        ]
        return (
            <div>
                <div className="delete-user-container">
                    <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                    <TitlePage title={constants.deleteUserLabel} />
                    <Paper className="paper-container">
                        <div className="pd-title">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <h5>
                                                {constants.detail}
                                            </h5>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <></>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="line" />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableBody>
                                    <TableRow key="name">
                                        <TableCell align="left">
                                            <div className="primary-text subtitle-1">ชื่อบัญชี</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="secondary-text body-2">
                                                {get(this.props.history.location.state, 'username', '')}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <div className="secondary-text body-2  pd-announce">
                            {/* {constants.announce} */}
                            <div className="mg-button">
                                <Button
                                    text="ลบบัญชีผู้ใช้"
                                    theme="outline"
                                    color="red"
                                    onClick={this.onDeleteWebBank}
                                />
                            </div>
                        </div>
                    </Paper>
                </div >
            </div >
        )
    }
}

export default DeleteManagement