import { createAction } from 'typesafe-actions'
import {
  POST_LOTTO_RATE_LEVEL_REQUEST,
  POST_LOTTO_RATE_LEVEL_SUCCESS,
  POST_LOTTO_RATE_LEVEL_FAILURE,
  POST_LOTTO_RATE_LEVEL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postLottoRateLevelAction = createAction(
  POST_LOTTO_RATE_LEVEL_REQUEST,
  reslove => (data: ILottoCreateRateLevelData) => reslove(data)
  )

const postLottoRateLevelSuccessAction = createAction(
  POST_LOTTO_RATE_LEVEL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoRateLevel[]>>) => resolve(data))

const postLottoRateLevelFailureAction = createAction(
  POST_LOTTO_RATE_LEVEL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const postLottoRateLevelCancelAction = createAction(POST_LOTTO_RATE_LEVEL_CANCEL)

export default {
  postLottoRateLevelAction,
  postLottoRateLevelSuccessAction,
  postLottoRateLevelFailureAction,
  postLottoRateLevelCancelAction,
}