
import React, { FC } from 'react'
import { TextField } from 'components'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

const InputNumberComponent: FC<ITextField & NumberFormatProps> = (props) => {

  const {
    placeholder,
    ...inputProps
  } = props
  return (
    <>
    {/* <input ref={getInputRef}></input> */}
    <NumberFormat
      {...inputProps}
      hintText={placeholder}
      placeholder={placeholder}
      customInput={TextField}
    />
    </>
  )
}

export default InputNumberComponent