import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import criminalAction from 'reduxs/user/criminal/actions'
import criminalLogAction from 'reduxs/user/criminalLog/actions'
import CriminalView from './CriminalView.container'

const mapStateToProps = (state: RootReducers): ICriminalViewProps => ({
    getCriminalViewFetching: state.mantra.user.criminalView.isFetching!,
    getCriminalViewError: state.mantra.user.criminalView.error!,
    getCriminalViewCode: state.mantra.user.criminalView.code!,
    getCriminalViewResult: state.mantra.user.criminalView.data!,
    getCriminalViewPagination: state.mantra.user.criminalView.pagination!,

    getCriminalViewLogFetching: state.mantra.user.criminalViewLog.isFetching!,
    getCriminalViewLogError: state.mantra.user.criminalViewLog.error!,
    getCriminalViewLogCode: state.mantra.user.criminalViewLog.code!,
    getCriminalViewLogResult: state.mantra.user.criminalViewLog.data!,
    getCriminalViewLogPagination: state.mantra.user.criminalViewLog.pagination!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ICriminalViewActionProps => bindActionCreators({
    getCriminalView: criminalAction.getCriminalViewAction,
    getCriminalViewLog: criminalLogAction.getCriminalViewLogAction,
    loader: loaderAction.loadingAction,
    clearCriminalViewLog: criminalLogAction.getCriminalViewLogClear,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(CriminalView)