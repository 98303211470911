import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import reportAction from 'reduxs/report/actions'
import DashboardContainer from './Dashboard.container'

const mapStateToProps = (state: RootReducers): IDashboardProps => ({
  getReportOverviewCode: state.mantra.report.overview.code!,
  getReportOverviewError: state.mantra.report.overview.error!,
  getReportOverviewIsFetching: state.mantra.report.overview.isFetching!,
  getReportOverviewResult: state.mantra.report.overview.data!,

  getReportYegeeCode: state.mantra.report.yegee.code!,
  getReportYegeeError: state.mantra.report.yegee.error!,
  getReportYegeeIsFetching: state.mantra.report.yegee.isFetching!,
  getReportYegeeResult: state.mantra.report.yegee.data!,

  getReportLotterNumberSessionCode: state.mantra.report.lotterNumberSession.code!,
  getReportLotterNumberSessionError: state.mantra.report.lotterNumberSession.error!,
  getReportLotterNumberSessionIsFetching: state.mantra.report.lotterNumberSession.isFetching!,
  getReportLotterNumberSessionResult: state.mantra.report.lotterNumberSession.data!,

  postReportDepositWithdrawCode: state.mantra.report.lotterDepositWithdraw.code!,
  postReportDepositWithdrawError: state.mantra.report.lotterDepositWithdraw.error!,
  postReportDepositWithdrawIsFetching: state.mantra.report.lotterDepositWithdraw.isFetching!,
  postReportDepositWithdrawResult: state.mantra.report.lotterDepositWithdraw.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IDashboardActionProps => bindActionCreators({
  getReportOverview: reportAction.getReportOverviewAction,
  getReportYegee: reportAction.getReportYegeeAction,
  postReportLotterDepositWithdraw : reportAction.postReportDepositWithdrawAction,
  getReportLotterNumberSession: reportAction.getReportLotterNumberSessionAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(DashboardContainer)