import project from 'constants/project'

export const GET_ALL_WEBBANK_REQUEST = 'GET_ALL_WEBBANK_REQUEST'
export const GET_ALL_WEBBANK_SUCCESS = 'GET_ALL_WEBBANK_SUCCESS'
export const GET_ALL_WEBBANK_FAILURE = 'GET_ALL_WEBBANK_FAILURE'
export const GET_ALL_WEBBANK_CANCEL = 'GET_ALL_WEBBANK_CANCEL'

export const initialState: IRateNumberListStoreState = {
  isFetching: false,
  data: [],
  code: 0,
  page: 0,
  limit: 0,
  total: 0
}

export const endpoint = {
  getWebbank: `${project.environment[project.environmentName].api}/webbank/all`,
}