import allActions from './all/actions'
import putActions from './check/actions'
import reserveActions from './reserve/actions'
import notificationsActions from './notifications/actions'

export default {
  ...allActions,
  ...putActions,
  ...reserveActions,
  ...notificationsActions,
}