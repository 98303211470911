import project from 'constants/project'

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST'
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS'
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE'
export const GET_NEWS_CANCEL = 'GET_NEWS_CANCEL'

export const POST_NEWS_REQUEST = 'POST_NEWS_REQUEST'
export const POST_NEWS_SUCCESS = 'POST_NEWS_SUCCESS'
export const POST_NEWS_FAILURE = 'POST_NEWS_FAILURE'
export const POST_NEWS_CANCEL = 'POST_NEWS_CANCEL'

export const PUT_NEWS_REQUEST = 'PUT_NEWS_REQUEST'
export const PUT_NEWS_SUCCESS = 'PUT_NEWS_SUCCESS'
export const PUT_NEWS_FAILURE = 'PUT_NEWS_FAILURE'
export const PUT_NEWS_CANCEL = 'PUT_NEWS_CANCEL'

export const DELETE_NEWS_REQUEST = 'DELETE_NEWS_REQUEST'
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS'
export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE'
export const DELETE_NEWS_CANCEL = 'DELETE_NEWS_CANCEL'

export const initialState: INewsAllState = {
    isFetching: false,
    code: 0,
    data: {},
    error: '',
}

export const endpoint = {
    getNews: `${project.environment[project.environmentName].api}/news/all`,
    postNews: `${project.environment[project.environmentName].api}/news`,
    putNews: `${project.environment[project.environmentName].api}/news`,
    deleteNews: `${project.environment[project.environmentName].api}/news`,
}