import { createAction } from 'typesafe-actions'
import {
  PUT_LOTTO_STATUS_REQUEST,
  PUT_LOTTO_STATUS_SUCCESS,
  PUT_LOTTO_STATUS_FAILURE,
  PUT_LOTTO_STATUS_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const putLottoStatusAction = createAction(
  PUT_LOTTO_STATUS_REQUEST,
  reslove => (data: ILottoStatusPutData) => reslove(data)
)

const putLottoStatusSuccessAction = createAction(
  PUT_LOTTO_STATUS_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoSchedule[]>>) => resolve(data))

const putLottoStatusFailureAction = createAction(
  PUT_LOTTO_STATUS_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const putLottoStatusCancelAction = createAction(PUT_LOTTO_STATUS_CANCEL)

export default {
  putLottoStatusAction,
  putLottoStatusSuccessAction,
  putLottoStatusFailureAction,
  putLottoStatusCancelAction,
}