import project from 'constants/project'


export const GET_WEBBANK_SUMMARY_REQUEST = 'GET_WEBBANK_SUMMARY_REQUEST'
export const GET_WEBBANK_SUMMARY_CANCEL = 'GET_WEBBANK_SUMMARY_CANCEL'
export const GET_WEBBANK_SUMMARY_SUCCESS = 'GET_WEBBANK_SUMMARY_SUCCESS'
export const GET_WEBBANK_SUMMARY_FAILURE = 'GET_WEBBANK_SUMMARY_FAILURE'

export const GET_BET_SUMMARY_REQUEST = 'GET_BET_SUMMARY_REQUEST'
export const GET_BET_SUMMARY_CANCEL = 'GET_BET_SUMMARY_CANCEL'
export const GET_BET_SUMMARY_SUCCESS = 'GET_BET_SUMMARY_SUCCESS'
export const GET_BET_SUMMARY_FAILURE = 'GET_BET_SUMMARY_FAILURE'

export const GET_MEMBER_SUMMARY_REQUEST = 'GET_MEMBER_SUMMARY_REQUEST'
export const GET_MEMBER_SUMMARY_CANCEL = 'GET_MEMBER_SUMMARY_CANCEL'
export const GET_MEMBER_SUMMARY_SUCCESS = 'GET_MEMBER_SUMMARY_SUCCESS'
export const GET_MEMBER_SUMMARY_FAILURE = 'GET_MEMBER_SUMMARY_FAILURE'

export const GET_FINANCE_SUMMARY_REQUEST = 'GET_FINANCE_SUMMARY_REQUEST'
export const GET_FINANCE_SUMMARY_CANCEL = 'GET_FINANCE_SUMMARY_CANCEL'
export const GET_FINANCE_SUMMARY_SUCCESS = 'GET_FINANCE_SUMMARY_SUCCESS'
export const GET_FINANCE_SUMMARY_FAILURE = 'GET_FINANCE_SUMMARY_FAILURE'

export const GET_BET_CHART_SUMMARY_REQUEST = 'GET_BET_CHART_SUMMARY_REQUEST'
export const GET_BET_CHART_SUMMARY_CANCEL = 'GET_BET_CHART_SUMMARY_CANCEL'
export const GET_BET_CHART_SUMMARY_SUCCESS = 'GET_BET_CHART_SUMMARY_SUCCESS'
export const GET_BET_CHART_SUMMARY_FAILURE = 'GET_BET_CHART_SUMMARY_FAILURE'

export const GET_MEMBER_CHART_SUMMARY_REQUEST = 'GET_MEMBER_CHART_SUMMARY_REQUEST'
export const GET_MEMBER_CHART_SUMMARY_CANCEL = 'GET_MEMBER_CHART_SUMMARY_CANCEL'
export const GET_MEMBER_CHART_SUMMARY_SUCCESS = 'GET_MEMBER_CHART_SUMMARY_SUCCESS'
export const GET_MEMBER_CHART_SUMMARY_FAILURE = 'GET_MEMBER_CHART_SUMMARY_FAILURE'

export const GET_FINANCE_CHART_SUMMARY_REQUEST = 'GET_FINANCE_CHART_SUMMARY_REQUEST'
export const GET_FINANCE_CHART_SUMMARY_CANCEL = 'GET_FINANCE_CHART_SUMMARY_CANCEL'
export const GET_FINANCE_CHART_SUMMARY_SUCCESS = 'GET_FINANCE_CHART_SUMMARY_SUCCESS'
export const GET_FINANCE_CHART_SUMMARY_FAILURE = 'GET_FINANCE_CHART_SUMMARY_FAILURE'

export const initialWebbankSummaryState: IWebbankSummaryState = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}

export const initialChartSummaryState: IWebbankSummaryState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  webBankSummary: `${project.environment[project.environmentName].api}/report/web_bank/summary`,

  betSummary: `${project.environment[project.environmentName].api}/report/bet/summary`,
  betSummaryChart: `${project.environment[project.environmentName].api}/report/bet/summary/chart/line`,

  memberSummary: `${project.environment[project.environmentName].api}/report/member/registered/summary`,
  memberSummaryChart: `${project.environment[project.environmentName].api}/report/member/registered/summary/chart/line`,

  financeSummary: `${project.environment[project.environmentName].api}/report/finance/summary`,
  financeSummaryChart: `${project.environment[project.environmentName].api}/report/finance/summary/chart/line`,
}