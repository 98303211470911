import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import webConfigAction from 'reduxs/webConfig/actions'
import loaderAction from 'reduxs/loader/actions'
import WebConfigContainer from './WebConfig.container'

const mapStateToProps = (state: RootReducers): IWebConfigContainerProps => ({
    getWebConfigIsFetching: state.mantra.webConfig.get.isFetching!,
    getWebConfigError: state.mantra.webConfig.get.error!,
    getWebConfigCode: state.mantra.webConfig.get.code!,
    getWebConfigResult: state.mantra.webConfig.get.data!,
    putWebConfigCode: state.mantra.webConfig.put.code!,
    putWebConfigError: state.mantra.webConfig.put.error!,
    putWebConfigIsFetching: state.mantra.webConfig.put.isFetching!,
    putWebConfigResult: state.mantra.webConfig.put.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IWebConfigContainerActionProps => bindActionCreators({
    getWebConfig: webConfigAction.getWebConfigAction,
    putWebConfig: webConfigAction.putWebConfigAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(WebConfigContainer)