import { AxiosError, AxiosResponse } from 'axios';
import { get, isEmpty } from 'lodash';
import { responseMessage } from '../constants/response';

const constants = {
  defaultError: 'กรุณาลองใหม่อีกครั้ง',
};

const getMessage = (
  response: AxiosError<APIResponse> | AxiosResponse<APIResponse>
): IResponseConverter => {
  if (isEmpty(get(response, 'response', {}))) {
    // Succes case
    const axiosResponse = response as AxiosResponse<APIResponse>;
    const messageCode: number = get(
      axiosResponse,
      'data.code',
      get(response, 'status', 0)
    );
    const code: number = get(response, 'data.code', get(response, 'status', 0));
    return {
      code,
      message: get(responseMessage, messageCode, constants.defaultError),
    };
  } else {
    // Error case
    const axiosResponse = response as AxiosError<APIResponse>;
    const messageCode: number = get(
      axiosResponse,
      'response.data.code',
      get(response, 'response.status', Number(get(response, 'code', 0)))
    );
    const code: number = get(
      response,
      'response.status',
      Number(get(response, 'code', 0))
    );
    return {
      code,
      message: get(responseMessage, messageCode, constants.defaultError),
    };
  }
};

const responseConverter = { getMessage };
export default responseConverter;
