import { string, object, ObjectSchema } from 'yup'
import { NUMBER, ENG, LOWERCASE, ENG_TH } from 'constants/regex'

const constants = {
  phoneNumber: 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก',
  onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
  startZero: 'เริ่มต้นด้วย 0 เท่านั้น',
  otp: 'กรุณากรอก OTP',
  otpLenght: 'กรุณากรอกไม่เกิน 6 ตัว',
  username: 'กรุณากรอกชื่อผู้ใช้',
  lowercase: 'กรุณากรอกเฉพาะตัวหนังสือตัวเล็ก',
  bankMinLenght: 'กรุณากรอกเลขบัญชีธนาคาร 10 ตัวขึ้นไป',
  englishRequire: 'กรุณากรอกเฉพาะตัวหนังสือภาษาอังกฤษและตัวเลข',
  englishTHRequire: 'กรุณากรอกเฉพาะตัวหนังสือภาษาอังกฤษหรือภาษาไทย',
  usernameMinLength: 'กรุณากรอกชื่อผู้ใช้ 6 ตัวขึ้นไป',
  password: 'กรุณากรอกรหัสผ่าน',
  passwordMinLength: 'กรุณากรอกรหัสผ่าน 6 ตัวขึ้นไป',
  confirmPassword: 'กรุณากรอกรหัสผ่าน',
  passwordMustEqual: 'กรุณากรอกรหัสผ่านให้เหมือนกัน',
  bankType: 'กรุญาเลือกธนาคาร',
  bankNumber: 'กรุณากรอกหมายเลขบัญชีธนาคาร',
  ownerName: 'กรุณากรอกชื่อเจ้าของบัญชี',
}

const schemeEdit: ObjectSchema<IEditUser> = object().shape({
  password: string()
    .matches(ENG, constants.englishRequire)
    .min(6, constants.passwordMinLength),
  phoneNumber: string()
    .length(10, constants.phoneNumber)
    .matches(NUMBER, constants.startZero),
  bankCode: string(),
  name: string()
    .matches(ENG_TH, constants.englishTHRequire),
  number: string()
    .min(10, constants.bankMinLenght),
})

const scheme: ObjectSchema<IRegister> = object().shape({
  phoneNumber: string()
    .length(10, constants.phoneNumber)
    .required(constants.phoneNumber)
    .matches(NUMBER, constants.startZero),
  username: string()
    .min(6, constants.usernameMinLength)
    .matches(ENG, constants.englishRequire)
    .matches(LOWERCASE, constants.lowercase)
    .required(constants.username),
  password: string()
    .matches(ENG, constants.englishRequire)
    .min(6, constants.passwordMinLength)
    .required(constants.password),
  confirmPassword: string()
    .required(constants.confirmPassword)
    .test(
      'samePasswordRequire',
      constants.passwordMustEqual,
      function (value: string) {
        return (value === this.parent.password)
      }),
  affilateNumber: string(),
  bankNumber: string().required(constants.bankType),
  name: string().required(constants.ownerName),
  number: string().required(constants.bankNumber)
    .min(10, constants.bankMinLenght),
})
export {
  scheme,
  schemeEdit,
}