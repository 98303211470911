import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const postReserveReducer = (state: IReserveTransactionPostState = initialState, action: RootAction)
: IReserveTransactionPostState => {
  switch (action.type) {
    case getType(actions.postReserveTransactionAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postReserveTransactionSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.postReserveTransactionFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const deleteReserveReducer = (state: IReserveTransactionDeleteState = initialState, action: RootAction)
: IReserveTransactionDeleteState => {
  switch (action.type) {
    case getType(actions.cancelReserveTransactionAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.cancelReserveTransactionSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.cancelReserveTransactionFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const forceDeletereserveReducer = (state: IReserveTransactionDeleteState = initialState, action: RootAction)
: IReserveTransactionDeleteState => {
  switch (action.type) {
    case getType(actions.cancelReserveTransactionAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.cancelReserveTransactionSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.cancelReserveTransactionFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

export default combineReducers({
  post: postReserveReducer,
  delete: deleteReserveReducer,
  forceDetete: forceDeletereserveReducer,
})