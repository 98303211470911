import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetLottoRateLevel = (data: IGetLottoRateLevelParams) =>  {
    const queryParams = new URLSearchParams({
        type: get(data,'type',''),
        code: get(data,'code',''),
    })
    return axios.get(endpoint.getAllLottoRate, {
        params: queryParams,
    })
}