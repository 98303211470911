import React, { FC, useEffect, useState, useRef } from "react";
import { noop } from "lodash";
// import DateFnsUtils from '@date-io/date-fns';
import {
  // DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import "./DateTime.component.scss";
import { th } from "date-fns/locale";
import OverwriteBEERADate from "utils/OverwriteBEEraDate";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IDateTimeProps = {
  id: "",
  label: "",
  value: null,
  disableToolbar: false,
  placeHolder: "",
  variant: "inline",
  margin: "normal",
  onClick() {
    noop();
  },
  onClose() {
    noop();
  },
  onChange(date: Date | null) {
    noop();
  },
  fullWidth: false,
  disabled: false,
  disablePast: false,
  position: "start",
};

const DateTimeComponent: FC<IDateTimeProps & DefaultProps> = React.forwardRef(
  (props) => {
    const {
      id,
      label,
      disableToolbar,
      variant,
      onClick,
      onChange,
      onClose,
      value,
      fullWidth,
      disabled,
      disablePast,
      position,
      placeHolder,
    } = props;
    const inputRef: any = useRef(null);
    const [length, setLength] = useState(0);
    const [effCursor, setEffCursor] = useState(value);
    // const [keydown, setKeyDown] = useState('')
    const onSetLength = (val: number) => {
      setLength(val);
    };
    useEffect(() => {
      const input: any = document.getElementById(id!);
      // if (input.selectionEnd < length && !(input.value.length === 5 || input.value.length === 7)) {
      //     input.setSelectionRange(input.selectionEnd, input.selectionEnd)
      // }
      if (input.selectionEnd < length) {
        input.setSelectionRange(input.selectionEnd, input.selectionEnd);
      } else {
        input.setSelectionRange(length, length);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effCursor]);

    return (
      <div className="text-field-container">
        <MuiPickersUtilsProvider utils={OverwriteBEERADate} locale={th}>
          <KeyboardDateTimePicker
            disablePast={disablePast}
            disabled={disabled}
            onKeyDown={(e) => {
              // setKeyDown(e.key)
            }}
            id={id}
            ref={inputRef.birth_date}
            label={label}
            disableToolbar={disableToolbar}
            variant={variant}
            inputVariant="outlined"
            format="dd MMM yyyy HH:mm"
            invalidDateMessage="ตัวอย่าง (02 มิ.ย. 2565 12.30)"
            rifmFormatter={(val) => {
              // eslint-disable-next-line
              let stDate = val.replace(
                // eslint-disable-next-line
                /\`|\~|\!|\@|\#|\-|\_|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/g,
                ""
              );

              let offSet =
                stDate[2] === "เ" ||
                (stDate[2] === "ม" && (stDate[3] === "ิ" || stDate[3] === "ี"))
                  ? 1
                  : 0;

              const newSring = stDate.slice(0, 2).concat(
                stDate.length > 2 ? " " : "", // " "
                stDate.slice(2, 3 + offSet), //พ
                stDate.length >= 3 ? "." : "", //.
                stDate.slice(3 + offSet, 4 + offSet), //ค
                stDate.length >= 4 + offSet ? ". " : "", //.
                stDate.slice(4 + offSet, 8 + offSet), //2565
                stDate.length >= 5 + offSet ? " " : "", // " "
                stDate.slice(8 + offSet, 10 + offSet), //12
                stDate.length >= 9 + offSet ? ":" : "", // :
                stDate.slice(10 + offSet, 12 + offSet) // 30
              );
              onSetLength(newSring.length);
              return newSring;
            }}
            // eslint-disable-next-line
            refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
            value={value}
            onClick={onClick}
            minDateMessage=""
            maxDateMessage=""
            onClose={onClose}
            onChange={(e) => {
              onChange(e);
              setEffCursor(e);
            }}
            fullWidth={fullWidth}
            placeholder={`${placeHolder}`}
            InputAdornmentProps={{ position: position }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
);
DateTimeComponent.defaultProps = defaultProps;
export default DateTimeComponent;
