import PGIcon from './pg.png';
import AllIcon from './thailandbet.png'
import DTIcon from './dream-tech.png'
import JokerIcon from './joker.png'
import SlotXOIcon from './slot-xo.png'
import W88Icon from './w88-circle.png'
import JiliIcon from './jili_logo.png'
import PPIcon from './pg.png'
const externalSlotImage: IImageExternalSlot = {
    ALL: {
        name: "All",
        Icon: AllIcon
    },
    DT: {
        name: "All",
        Icon: DTIcon
    },
    JOKER: {
        name: "Joker",
        Icon: JokerIcon
    },
    PGGAME: {
        name: "PG",
        Icon: PGIcon
    },
    SLOT_XO: {
        name: "Slot XO",
        Icon: SlotXOIcon,
    },
    W88: {
        name: "W88",
        Icon: W88Icon,
    },
    JILIGAME:{
        name: "JILIGAME",
        Icon: JiliIcon
    },
    PPGAME:{
        name: "PPGAME",
        Icon: PPIcon
    },
}

export default externalSlotImage