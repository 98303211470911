import project from 'constants/project'
import { transformer } from 'utils'
export const GET_USER_CRIMINAL_VIEW_LOG_REQUEST = 'GET_USER_CRIMINAL_VIEW_LOG_REQUEST'
export const GET_USER_CRIMINAL_VIEW_LOG_SUCCESS = 'GET_USER_CRIMINAL_VIEW_LOG_SUCCESS'
export const GET_USER_CRIMINAL_VIEW_LOG_FAILURE = 'GET_USER_CRIMINAL_VIEW_LOG_FAILURE'
export const GET_USER_CRIMINAL_VIEW_LOG_CLEAR = 'GET_USER_CRIMINAL_VIEW_LOG_CLEAR'
export const initialState: ICriminalViewLogState  = {
  isFetching: false,
  code: 0,
  data: [],
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const endpoint = {
  getCriminalViewLog: (query?: IQueryCriminalViewLog) => `${project.environment[project.environmentName].api}/user/criminal/view/log${transformer.urlSearchParams(query)}`,
}
