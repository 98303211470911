import axios, { AxiosResponse } from "axios"
import project from 'constants/project'
import { transformer } from 'utils'

//ST1.GET: api/v1/stats/user/read
const fetchStatUserRead = (query: IV1ReadStatsUser): Promise<AxiosResponse<APIResponse<IV1RespReadStatsUser>>> =>  {
  const endpoint = `${project.environment[project.environmentName].api}/v1/stats/user/read`
  return axios.get(`${endpoint}${transformer.urlSearchParamsSnakecase(query)}`)
}

export {
  fetchStatUserRead,
}


