import bank from 'assets/images/global/bank';
// import { Button } from 'components';
import React, { FC, useEffect } from 'react'
import { transformer,number } from 'utils';
import { noop } from 'lodash'
import './BankCard.style.scss'

const constants = {
  titleDetail: 'รายละเอียด',
  titleEdit: 'เปลี่ยนงานบัญชี',
  titleListBank: 'ใช้ร่วมกับ'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IBankCard = {
  id: '',
  onClickDetail(){noop()},
  dataWebBank:{
    id: -1,
    type: '',
    name: '',
    number: '',
    currentMoney: 0,
    status: '',
    bankType:'',
    createdAt: '',
    updated_at: '',
  }
}

const BankCard: FC<IBankCard & DefaultProps> = (props) => {

  const {
    id,
    dataWebBank,
    onClickDetail
  } = props

  useEffect(() => {},[])

  return (
    <div
      className={`bank-card-container mb-2`}
      id={id}
      key={id}
    >
      <div className="container">
        <div className="row">
          <div className="col-2 p0 d-flex justify-content-center">
            <img
              src={bank[dataWebBank!.type].Icon}
              className='align-self-center justify-content-center'
              style={{ width: '45px', height: 'inherit',maxWidth:'45px',minWidth:'45px'}}
              alt={`${dataWebBank!.type}-logo`}
            />
          </div>
          <div className="col p0">
            <h6 className="subtitle-1 primary-text">{bank[dataWebBank!.type].name}</h6>
            <h6 className="subtitle-2 secondary-text">{number.formatBankNumber(dataWebBank!.number,dataWebBank!.type)}</h6>
            <h6 className="subtitle-1 primary-text">{dataWebBank?.name}</h6>
          </div>
          <div className="p0">
              <h5 className="primary-green-text text-right">
                {transformer.typeFormat(String(dataWebBank!.currentMoney))}
              </h5>
              {/* <Button
                id={"changeBank"}
                text={constants.titleEdit}
                onClick={() => {}}
                color="gray"
              /> */}
          </div>
        </div>
        <div className="row">
          <div className="p0 offset-2">
            <h6 
              className="link title-detail"
              onClick={onClickDetail}
            >
              {constants.titleDetail}
            </h6>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end list-bank">
        <h6 className="primary-text mr-2">
          {constants.titleListBank}
        </h6>
        <div className="list-logo-bank d-flex justify-content-center">
          {["KTB","KTB","KTB","SCB","TCD"].map((nameBank,index)=>
            (<img
                key={index}
                src={bank[nameBank].Icon}
                className={`logo-bank ${index===0?'first':''}`}
                alt={`thailand-flat-${index}`}
              />))
          }
          <div className="box-close d-flex justify-content-center">
            <h6 className="primary-text">ปิดปรับปรุง ชั่วคราว</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

BankCard.defaultProps = defaultProps

export default BankCard