import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  // mergeMap,
  filter,
  flatMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchLogin } from './services'
import actions from './actions'
import userActions from 'reduxs/user/actions'
import notificationsActions from 'reduxs/transaction/notifications/actions'
import authActions from 'reduxs/auth/actions'
import { AxiosResponse } from 'axios'
import socketActions from 'reduxs/socket/actions'
// import actionMenu from 'reduxs/menu/actions'
const loginEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.loginAction)),
    exhaustMap(action =>
      from(fetchLogin(action.payload))
        .pipe(
          flatMap((response: AxiosResponse<APIResponse<{ token: string }>>) => of(
            userActions.persistedUserAction({ token: response.data.data.token }),
            socketActions.connectSocketAction(),
            userActions.getUserMeAction(),
            notificationsActions.getNotificationTransactionAllAction(),
            notificationsActions.listenNotificationTransactionAllSocketAction(),
            actions.loginSuccessAction(response),
          )),
          catchError(error => of(
            actions.loginFailureAction(error),
            userActions.clearUserAction()
          )),
          takeUntil(action$.pipe(filter(isActionOf(actions.loginCancelAction))))
        ),
    )
  )

const logoutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.logoutAction)),
    exhaustMap((_) => of(
      userActions.clearUserAction(),
      authActions.logoutSuccessAction(),
      // actionMenu.clearMenu(),
    ))
  )
export default [
  loginEpic,
  logoutEpic,
]
