import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  mergeMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchDepositRequest } from './services'
import actions from './actions'

const meDetailEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.postCreditMeDeTailRequestAction)),
    mergeMap(action =>
      from(fetchDepositRequest(action.payload,action.payload.userId))
        .pipe(
          map(actions.postCreditMeDetailRequestSuccessAction),
          catchError(error => of(actions.postCreditMeDetailFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.postCreditMeDetailCancelAction))))
        ),
    )
  )

export default [
  meDetailEpic,
]
