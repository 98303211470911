import project from 'constants/project'

export const POST_LOTTO_REFUND_REQUEST = 'POST_LOTTO_REFUND_REQUEST'
export const POST_LOTTO_REFUND_SUCCESS = 'POST_LOTTO_REFUND_SUCCESS'
export const POST_LOTTO_REFUND_FAILURE = 'POST_LOTTO_REFUND_FAILURE'
export const POST_LOTTO_REFUND_CANCEL = 'POST_LOTTO_REFUND_CANCEL'

export const initialState: ILottoRefundState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postLottoRefund: `${project.environment[project.environmentName].api}/lotter/refund`,
}