import { createAction } from 'typesafe-actions'
import {
  PUT_LOTTO_MODE_REQUEST,
  PUT_LOTTO_MODE_SUCCESS,
  PUT_LOTTO_MODE_FAILURE,
  PUT_LOTTO_MODE_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const putLottoModeAction = createAction(
  PUT_LOTTO_MODE_REQUEST,
  reslove => (data: ILottoModePutData) => reslove(data)
)

const putLottoModeSuccessAction = createAction(
  PUT_LOTTO_MODE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoSchedule[]>>) => resolve(data))

const putLottoModeFailureAction = createAction(
  PUT_LOTTO_MODE_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const putLottoModeCancelAction = createAction(PUT_LOTTO_MODE_CANCEL)

export default {
  putLottoModeAction,
  putLottoModeSuccessAction,
  putLottoModeFailureAction,
  putLottoModeCancelAction,
}