import axios from 'axios'
import { endpoint } from './constants'
import { isUndefined, get } from 'lodash'

export const fetchPostAccountUser = (data: IPostWebbankStoreState) => axios.post(`${endpoint.postWebbank}`, data)
export const fetchPostMapBank = (data: IPostMapWebbankStoreState) => axios.post(`${endpoint.postMapBank}`, data)
export const fetchGetAccountUser = (data: IGetWebBankParams) => {
    const queryParams = new URLSearchParams({
        status: isUndefined(data.queryStatus) ? '' : data.queryStatus,
        page: String(get(data, 'page', 0) + 1),
        limit: String(get(data, 'limit', '10')),
        search: get(data, 'search', ''),
        operate_type: get(data, 'operate_type', 'WITHDRAW'),
    })
    return axios.get(endpoint.getWebbank, {
        params: queryParams,
    })
}

export const fetchGetActiveWebBank = (data: IGetWebBankParams) => {
    const queryParams = new URLSearchParams({
        status: 'ACTIVE',
        page: String(get(data, 'page', 0) + 1),
        limit: '',
        search: get(data, 'search', ''),
        operate_type: get(data, 'operate_type', 'WITHDRAW'),
    })
    return axios.get(endpoint.getWebbank, {
        params: queryParams,
    })
}

export const fetchGetInActiveWebBank = (data: IGetWebBankParams) => {
    const queryParams = new URLSearchParams({
        status: String(get(data, 'queryStatus', 'INACTIVE')),
        page: String(get(data, 'page', 0) + 1),
        limit: String(get(data, 'limit', '10')),
        search: get(data, 'search', ''),
        operate_type: get(data, 'operate_type', 'WITHDRAW'),
    })
    return axios.get(endpoint.getWebbank, {
        params: queryParams,
    })
}
export const fetchGetWebBankActive = (data?: IGetWebBankActiveParams) => {
    const queryParams = new URLSearchParams({
        page: String(get(data, 'page', 0) + 1),
        // limit: String(get(data,'limit','10')),
        limit: String(get(data, 'limit', '10')),
        status: 'ACTIVE',
        operate_type: String(get(data, 'operateType', ''))
    })
    return axios.get(endpoint.getWebbank, {
        params: queryParams,
    })
}

export const fetchPutAccountUser = (data: IPutWebbankStoreState) => axios.put(`${endpoint.putWebbank}`, data)
export const fetchDeleteAccountUser = (data: IDeleteWebbankStoreState) =>
    axios.delete(`${endpoint.deleteWebbank}`, { data: data })
