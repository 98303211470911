import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import webbankAction from 'reduxs/webbank/webbanks/actions'
import loaderAction from 'reduxs/loader/actions'
import CreateWebBankContainer from './CreateWebBank'

const mapStateToProps = (state: RootReducers): any => {
  return {
    postWebBankIsFetching: state.mantra.webbank.webbanks.postWebbank.isFetching!,
    postWebBankError: state.mantra.webbank.webbanks.postWebbank.error!,
    postWebBankCode: state.mantra.webbank.webbanks.postWebbank.code!,
    postWebBankResult: state.mantra.webbank.webbanks.postWebbank.data!,

  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ICreateWebBankActionProps => bindActionCreators({
  postWebBank: webbankAction.postWebbankAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(CreateWebBankContainer)