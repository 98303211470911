import { betRatesAllEpic, betRatesPutEpic } from './betRates/epics'
import { yegeeGameConfigAllEpic, yegeeGameConfigPutEpic } from './yegeeConfig/epics'
import {
  broadcastPostEpic,
  broadcastListEpic,
  broadcastDeleteEpic,
  broadcastSaveEpic,
  broadcastSummaryEpic,
  broadcastLogEpic,
} from './broadcastLine/epics'

export default [
  ...[betRatesAllEpic],
  ...[betRatesPutEpic],
  ...[yegeeGameConfigAllEpic],
  ...[yegeeGameConfigPutEpic],
  ...[broadcastPostEpic],
  ...[broadcastListEpic],
  ...[broadcastDeleteEpic],
  ...[broadcastSaveEpic],
  ...[broadcastSummaryEpic],
  ...[broadcastLogEpic],
]
