export const NUMBER = /^0\d[0-9]*$/g
export const DECIMAL_DITGITS = /(\.\d+)+/g
export const COMMA = /[,]/g
export const ENG = /^[a-zA-Z0-9]+$/g
export const LOWERCASE = /^[a-z0-9]+$/g
export const NUMBER_NO = /^[0-9]*$/g
export const NUM_DECIMAL = /^[1-9]\d*(\.\d+)?$/g
export const ESCAPED_TIMEZONE = /\b\S{1,19}\b/g
export const COMMACASE = /^[0-9,.]*$/
export const ZERO = /^[1-9][0-9]*$/
export const test = /^\d*\.?\d*$/
export const ENG_TH = /^[\u0E00-\u0E7Fa-zA-Z ]+$/

// eslint-disable-next-line no-useless-escape
export const SPECIAL = /\`|\~|\!|\@|\#|\-|\_|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/g