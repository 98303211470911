import project from 'constants/project'


export const POST_WEBBANK_REQUEST = 'POST_WEBBANK_REQUEST'
export const POST_WEBBANK_SUCCESS = 'POST_WEBBANK_SUCCESS'
export const POST_WEBBANK_FAILURE = 'POST_WEBBANK_FAILURE'
export const POST_WEBBANK_CANCEL = 'POST_WEBBANK_CANCEL'

export const POST_MAP_WEBBANK_REQUEST = 'POST_MAP_WEBBANK_REQUEST'
export const POST_MAP_WEBBANK_SUCCESS = 'POST_MAP_WEBBANK_SUCCESS'
export const POST_MAP_WEBBANK_FAILURE = 'POST_MAP_WEBBANK_FAILURE'
export const POST_MAP_WEBBANK_CANCEL = 'POST_MAP_WEBBANK_CANCEL'

export const GET_WEBBANK_REQUEST = 'GET_WEBBANK_REQUEST'
export const GET_WEBBANK_SUCCESS = 'GET_WEBBANK_SUCCESS'
export const GET_WEBBANK_FAILURE = 'GET_WEBBANK_FAILURE'
export const GET_WEBBANK_CANCEL = 'GET_WEBBANK_CANCEL'

export const GET_ACTIVE_WEBBANK_REQUEST = 'GET_ACTIVE_WEBBANK_REQUEST'
export const GET_ACTIVE_WEBBANK_SUCCESS = 'GET_ACTIVE_WEBBANK_SUCCESS'
export const GET_ACTIVE_WEBBANK_FAILURE = 'GET_ACTIVE_WEBBANK_FAILURE'
export const GET_ACTIVE_WEBBANK_CANCEL = 'GET_ACTIVE_WEBBANK_CANCEL'

export const GET_INACTIVE_WEBBANK_REQUEST = 'GET_INACTIVE_WEBBANK_REQUEST'
export const GET_INACTIVE_WEBBANK_SUCCESS = 'GET_INACTIVE_WEBBANK_SUCCESS'
export const GET_INACTIVE_WEBBANK_FAILURE = 'GET_INACTIVE_WEBBANK_FAILURE'
export const GET_INACTIVE_WEBBANK_CANCEL = 'GET_INACTIVE_WEBBANK_CANCEL'

export const GET_WEBBANK_ACTIVE_REQUEST = 'GET_WEBBANK_ACTIVE_REQUEST'
export const GET_WEBBANK_ACTIVE_SUCCESS = 'GET_WEBBANK_ACTIVE_SUCCESS'
export const GET_WEBBANK_ACTIVE_FAILURE = 'GET_WEBBANK_ACTIVE_FAILURE'
export const GET_WEBBANK_ACTIVE_CANCEL = 'GET_WEBBANK_ACTIVE_CANCEL'

export const PUT_WEBBANK_REQUEST = 'PUT_WEBBANK_REQUEST'
export const PUT_WEBBANK_SUCCESS = 'PUT_WEBBANK_SUCCESS'
export const PUT_WEBBANK_FAILURE = 'PUT_WEBBANK_FAILURE'
export const PUT_WEBBANK_CANCEL = 'PUT_WEBBANK_CANCEL'

export const DELETE_WEBBANK_REQUEST = 'DELETE_WEBBANK_REQUEST'
export const DELETE_WEBBANK_SUCCESS = 'DELETE_WEBBANK_SUCCESS'
export const DELETE_WEBBANK_FAILURE = 'DELETE_WEBBANK_FAILURE'
export const DELETE_WEBBANK_CANCEL = 'DELETE_WEBBANK_CANCEL'

export const UPDATE_WEBBANK_REQUEST = 'UPDATE_WEBBANK_REQUEST'
export const UPDATE_WEBBANK_SUCCESS = 'UPDATE_WEBBANK_SUCCESS'
export const UPDATE_WEBBANK_FAILURE = 'UPDATE_WEBBANK_FAILURE'
export const LISTEN_WEBBANK_SOCKET = 'LISTEN_WEBBANK_SOCKET'

export const UNLISTEN_WEBBANK_SOCKET = 'UNLISTEN_WEBBANK_SOCKET'
export const CLEAR_WEBBANK = 'CLEAR_WEBBANK'


export const initialState: IWebbankStoreState = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}

export const endpoint = {
  postWebbank: `${project.environment[project.environmentName].api}/webbank/`,
  postMapBank: `${project.environment[project.environmentName].api}/webbank/mapbank`,
  getWebbank: `${project.environment[project.environmentName].api}/webbank/all`,
  putWebbank: `${project.environment[project.environmentName].api}/webbank/`,
  deleteWebbank: `${project.environment[project.environmentName].api}/webbank/`,
}