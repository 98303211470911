import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchGetBetRatesAll, fetchPutBetRates } from './services'
import actions from './actions'

const betRatesAllEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getBetRatesAllAction)),
    exhaustMap(_ =>
      from(fetchGetBetRatesAll())
        .pipe(
          map(actions.getBetRatesAllSuccessAction),
          catchError(error => of(actions.getBetRatesAllFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getBetRatesAllCancelAction))))
        ),
    )
  )

const betRatesPutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.putBetRatesAction)),
    exhaustMap(action =>
      from(fetchPutBetRates(action.payload))
        .pipe(
          map(actions.putBetRatesSuccessAction),
          catchError(error => of(actions.putBetRatesFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.putBetRatesCancelAction))))
        ),
    )
  )

// export default [
//   betRatesAllEpic,
//   betRatesPutEpic,
// ]

export {
  betRatesAllEpic,
  betRatesPutEpic
}
