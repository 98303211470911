import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { ListSubheader, Paper } from '@material-ui/core'
import './CreateWebBank.scss'
import { responseCode } from 'constants/response'
import { History } from 'history';
import { Formik, Form } from 'formik'
import { initialValue } from './models/initialValues'
import scheme from './models/scheme'
import bank from 'assets/images/global/bank'
import {
  Button,
  TitlePage,
  Breadcrumb,
  TextField,
  Modal,
  Select,
  InputNumber,
  Switches,
  Snackbar
} from 'components'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty } from 'lodash';

const constants = {
  placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),
  placeholderAccName: 'ชื่อบัญชี *',
  placeholderAccNumber: 'เลขบัญชี *',
  systemLabel: 'จัดการระบบ',
  webBankLabel: 'บัญชีระบบ',
  addWebBankLabel: 'เพิ่มบัญชีระบบ',
  wrongCreateBank: 'สร้างบัญชีระบบผิดพลาด',
  wrongCreateBankDescription: 'ไม่สามารถสร้างบัญชีระบบ เนื่องจากมีอยู่ในระบบแล้ว',
  auto:'ระบบอัตโนมัติ : ',

  bankDeposit:'บัญชีฝาก',
  bankWithdraw:'บัญชีถอน',
  selectBank:'เลือกธนาคาร',
  nameBank:'ชื่อเจ้าของบัญชี',
  numberBank:'เลขบัญชี',
  addBank:'เพิ่มบัญชีระบบ',
  multiBank:'ธนาคารร่วม',
  addBankSuccess:'เพิ่มบัญชีธนาคารสำเร็จ!'
}

class CreateWebBank extends Component<ICreateWebBankActionProps & IWebBankProps & { history: History },
  & ICreateWebBankState> {

  constructor(props: ICreateWebBankActionProps & IWebBankProps & { history: History }) {
    super(props)
    this.state = {
      bank: '',
      name: '',
      number: '',
      listBankCheck: [],
      isAuto: false,
      bankType: 'DEPOSIT'
    }
  }

  componentDidUpdate(prevProps: IWebBankProps) {
    if (prevProps.postWebBankIsFetching !== this.props.postWebBankIsFetching
      &&!this.props.postWebBankIsFetching) {
        if (this.props.postWebBankCode === responseCode.OK) {
          this.props.loader(false)
          this.props.history.push('/webbank')
          Snackbar.success.show({
            message:constants.addBankSuccess
          })
        }
        else {
          Modal.error.show({
            action: Modal.error.hide,
            description: constants.wrongCreateBankDescription,
            actionText: constants.wrongCreateBank,
          })
          this.props.loader(false)
        }
    }
  }

  handleWebBankChange = (bankName: string) => {
    this.setState({ bank: bankName })
  }

  handleOnClickBreadcrumb = (path: string) => {
    if (!isEmpty(path)) {
        this.props.history.replace(path)
    }
  }

  formatBankNumber = (type:string)=>{
    return type === 'GSB' || type ==='BAAC'
    ? '# ### #### ####'
    : '### ###### #'
  }

  onSubmitCreate = (values: ICreateWebBank) => {
    let data = {
      type: this.state.bank,
      name: values.name,
      number: values.number,
      isAuto: this.state.isAuto,
      listBankCheck: this.state.listBankCheck,
      operate_type: this.state.bankType
    }
    this.props.loader(true)
    this.props.postWebBank(data)
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.webBankLabel, path: '/webbank', active: false },
      { label: constants.addWebBankLabel, active: true },
    ]
    return (
      <div>
        <div className="createwebbank-container">
        <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
        <TitlePage title={constants.addWebBankLabel}/>
          <Grid container>
            <Grid item xs={10}>
              <Paper className="paper-container secondary-dark">
                {/* formik */}
                <Formik
                  initialValues={initialValue}
                  validationSchema={scheme(this.state.bank)}
                  enableReinitialize
                  onSubmit={this.onSubmitCreate}
                >
                  {
                    ({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                       // @ts-ignore
                        <Form>
                          <div className="paper-body">
                          <Grid container>
                              <Grid item xs={6}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <h5>{constants.addWebBankLabel}</h5>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div className="control-button">
                                      <h6 className="primary-text">
                                        {constants.auto}
                                      </h6>
                                      <Switches
                                        // onChange={(e) => {this.setState({isAuto:!this.state.isAuto})}}
                                        checked={this.state.isAuto}
                                        color="default"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <Select
                                  label="ประเภท"
                                  fullWidth={true}
                                  value={this.state.bankType}
                                  onChange={(e) => {
                                    this.setState({bankType:e.target.value})
                                  }}
                                  itemList={[
                                    {value: 'DEPOSIT',element: <h6 className="primary-green-text">{constants.bankDeposit}</h6>},
                                    {value: 'WITHDRAW',element: <h6 className="primary-red-text">{constants.bankWithdraw}</h6>}
                                  ]}
                                />
                                <Select
                                  name="bank"
                                  label={constants.selectBank}
                                  fullWidth={true}
                                  value={this.state.bank}
                                  onChange={(event) => {
                                    this.handleWebBankChange(event.target.value)
                                    setFieldValue(event.target.name, event.target.value)
                                  }}

                                  error={!!errors.bank && touched.bank}
                                  helperText={!!errors.bank && touched.bank ? `${errors.bank}` : ''}
                                  itemList={Object.keys(bank).filter(item => item!=='TMB'&&item!=='TBANK').map((item) => {
                                    return {
                                      // @ts-ignore
                                      value: bank[item].key,
                                      element: (
                                        <div>
                                          <div style={{ display: 'flex' }}>
                                            <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                              <img
                                                src={bank[item].Icon}
                                                style={{
                                                  width: 'inherit',
                                                  height: 'inherit',
                                                }}
                                                alt="thailand-flat"
                                              />
                                            </div>
                                            <div style={{ display: 'grid' }}>
                                              <h6>{bank[item].name}</h6>
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    }
                                  })

                                  }
                                />
                                <TextField
                                  name="name"
                                  label={constants.nameBank}
                                  fullWidth={true}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name.toLowerCase()}
                                  error={!!errors.name && touched.name}
                                  helperText={!!errors.name && touched.name ? `${errors.name}` : ''}
                                />
                                <InputNumber
                                  onBlur={handleBlur}
                                  disabled={this.state.bank === '' ? true : false}
                                  name="number"
                                  label={constants.numberBank}
                                  fullWidth={true}
                                  onValueChange={({ value }) => setFieldValue('number', value)}
                                  decimalScale={0}
                                  format={this.formatBankNumber(this.state.bank)}
                                  allowNegative={false}
                                  value={values.number.toLowerCase()}
                                  errorMessage={errors.number}
                                  error={!!errors.number && touched.number}
                                  helperText={!!errors.number && touched.number ? `${errors.number}` : ''}
                                />
                                <Button
                                  type="submit"
                                  text={constants.addBank}
                                  fullWidth={true}
                                />
                              </Grid>
                              <Grid item xs={6} className="list-bank">
                                <div className="box-close d-flex align-items-center justify-content-center">
                                  <h6>ปิดปรับปรุง ชั่วคราว</h6>
                                </div>
                                <List className={`custom-scrollbar`} subheader={<ListSubheader><h5>{constants.multiBank}</h5></ListSubheader>}>
                                  {Object.keys(bank).map((item) => {
                                    const labelId = `checkbox-list-label-${item}`;
                                    let checkIndex = this.state.listBankCheck.indexOf(item)
                                    return (
                                      <ListItem key={item} role={undefined} dense button 
                                      className={`${checkIndex!==-1?"checked":""}`}
                                        onClick={
                                          ()=>{
                                            if (checkIndex === -1) {
                                              this.setState({listBankCheck:this.state.listBankCheck.concat([item])})
                                            }else{
                                              this.state.listBankCheck.splice(checkIndex,1)
                                              this.setState({listBankCheck:this.state.listBankCheck})
                                            }
                                          }
                                        }>
                                        <ListItemIcon>
                                          <Checkbox
                                            checked={checkIndex !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                          />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={(
                                          <div style={{ display: 'flex' }}>
                                            <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                              <img
                                                src={bank[item].Icon}
                                                style={{
                                                  width: 'inherit',
                                                  height: 'inherit',
                                                }}
                                                alt="thailand-flat"
                                              />
                                            </div>
                                            <div style={{ display: 'grid' }}>
                                              <h6>{bank[item].name}</h6>
                                            </div>
                                          </div>
                                          )}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </Grid>
                            </Grid>
                          </div>
                        </Form>
                      )
                  }
                </Formik>
              </Paper>
            </Grid>
          </Grid>
        </div >
      </div >
    )
  }
}

export default CreateWebBank