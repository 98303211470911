import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import configAction from 'reduxs/config/actions'
import lottoAction from 'reduxs/lotto/actions'
import LottoCancelContainer from './LottoCancel.container'

const mapStateToProps = (state: RootReducers): INumberRateCancelProps => {
    return ({
        getLottoScheduleIsFetching: state.mantra.config.lotto.schedule.isFetching!,
        getLottoScheduleCode: state.mantra.config.lotto.schedule.code!,
        getLottoScheduleError: state.mantra.config.lotto.schedule.error!,
        lottoSchedule: state.mantra.config.lotto.schedule.data!,

        postLottoCancelIsFetching: state.mantra.lotto.remove.isFetching!,
        postLottoCancelCode: state.mantra.lotto.remove.code!,
        postLottoCancelError: state.mantra.lotto.remove.error!,
        postLottoCancelResult: state.mantra.lotto.remove.data!,
    })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): INumberRateCancelActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    getLottoSchedule: configAction.getLottoScheduleAction,
    postLottoCancel: lottoAction.LottoCancelAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoCancelContainer)