import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  fetchGetUserMe,
} from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const userMeEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getUserMeAction)),
    exhaustMap(_ =>
      from(fetchGetUserMe())
        .pipe(
          map(actions.getUserMeSuccessAction),
          catchError(error => of(actions.getUserMeFailureAction(error))),
        ),
    )
  )

export default [
  userMeEpic,
]
