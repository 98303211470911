import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetAllWebbank = (data: IGetWebBankParams) => {
    const queryParams = new URLSearchParams({
        queryStatus: '',
        page: String(get(data, 'page', 0) + 1),
        limit: String(get(data, 'limit', '10')),
        search: get(data, 'search', ''),
        operate_type: get(data, 'operate_type', 'WITHDRAW'),
    })
    return axios.get(endpoint.getWebbank, {
        params: queryParams,
    })
}