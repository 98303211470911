import React from 'react'
import './homeButton.style.scss'

function homeButton(){
  return (
    <div className="radius">
    <div className="outline">
        <div className="white">
            {}
        </div>
    </div>
    </div>
  )
}
export default homeButton