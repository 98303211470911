import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import ReportSummaryContainer from './ReportSummary.container'


const mapStateToProps = (state: RootReducers): any => ({
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): any => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(ReportSummaryContainer)