import { createAction } from 'typesafe-actions'
import {
  POST_LOTTO_RESULT_REQUEST,
  POST_LOTTO_RESULT_SUCCESS,
  POST_LOTTO_RESULT_FAILURE,
  POST_LOTTO_RESULT_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postLottoResultAction = createAction(
  POST_LOTTO_RESULT_REQUEST,
  reslove => (data: ILottoResultPostData) => reslove(data)
  )

const postLottoResultSuccessAction = createAction(
  POST_LOTTO_RESULT_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoResult[]>>) => resolve(data))

const postLottoResultFailureAction = createAction(
  POST_LOTTO_RESULT_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const postLottoResultCancelAction = createAction(POST_LOTTO_RESULT_CANCEL)

export default {
  postLottoResultAction,
  postLottoResultSuccessAction,
  postLottoResultFailureAction,
  postLottoResultCancelAction,
}