import { string, object } from "yup";
import { isEmpty } from "lodash";

const constants = {
  onlyNumber: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
  number: "กรุณากรอกตัวเลข",
  twoLength: "กรุณากรอกไม่เกิน 2 ตัว",
  twoMinLength: "กรุณากรอกให้ครบ 2 ตัว",
  threeLength: "กรุณากรอกไม่เกิน 3 ตัว",
  threeMinLength: "กรุณากรอกให้ครบ 3 ตัว",
  fourLength: "กรุณากรอกไม่เกิน 4 ตัว",
  fourMinLength: "กรุณากรอกให้ครบ 4 ตัว",
  rate: "กรุณากรอกอัตราจ่าย",
  numberReq: "กรุณากรอกเลข",
};

const textError = (typeLotto: TLottoGameType) => {
  if (typeLotto === "FOUR_SUITE") {
    return constants.fourMinLength;
  } else if (typeLotto === "THREE_TOAST" || typeLotto === "THREE_UP") {
    return constants.threeMinLength;
  } else if (typeLotto === "TWO_DOWN" || typeLotto === "TWO_UP") {
    return constants.twoMinLength;
  } else {
    return constants.number;
  }
};

const scheme = (typeLotto: TLottoGameType) => {
    return object().shape({
      number: string()
        .required(constants.number)
        .test("check length", textError(typeLotto), function (value: string) {
          if(!isEmpty(value)) {
            if (typeLotto === "FOUR_SUITE" && isEmpty(value)) {
              return value.length === 4;
            } else if (typeLotto === "THREE_TOAST" || typeLotto === "THREE_UP") {
              return value.length === 3;
            } else if (typeLotto === "TWO_DOWN" || typeLotto === "TWO_UP") {
              return value.length === 2;
            } else {
              return value.length === 1;
            }
          }
          else{
            return true
          }
        }),
    
    });
  
};
export default scheme;
