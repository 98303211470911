import { createAction } from 'typesafe-actions'
import {
  PUT_TRANSACTION_REQUEST,
  PUT_TRANSACTION_SUCCESS,
  PUT_TRANSACTION_FAILURE,
  PUT_TRANSACTION_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const putTransactionAction = createAction(
  PUT_TRANSACTION_REQUEST,
  reslove => (data: ITransactionPutData) => reslove(data)
)

const putTransactionSuccessAction = createAction(
  PUT_TRANSACTION_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ITransactionData>>) => resolve(data))

const putTransactionFailureAction = createAction(
  PUT_TRANSACTION_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const putTransactionCancelAction = createAction(PUT_TRANSACTION_CANCEL)

export default {
  putTransactionAction,
  putTransactionSuccessAction,
  putTransactionFailureAction,
  putTransactionCancelAction,
}