import React from 'react'
import { isEqual } from 'lodash'
import { Chip } from '@material-ui/core'
import './Chip.component.scss'

function ChipComponent(props: IChipProps) {
    const {
        label = 0,
        size = 'small',
        color = 'primary-purple',
    } = props

    return (
        <div className="chip-container">
            {
                !isEqual(label, 0) && (
                    <Chip
                        label={(
                            <h6 className={`${color}`}>{label}</h6>
                        )}
                        className={`${color} ${size}`}
                    />
                )
            }
        </div>
    )
}

export default ChipComponent