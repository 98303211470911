import React, { SFC, useState } from 'react'
import { get, noop } from 'lodash'
import { Button, TextField } from 'components'
import { number, transformer } from 'utils'
import {
  Grid
} from '@material-ui/core'

import './error.style.scss'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const constants = {
  title: 'เกิดข้อผิดพลาด',
  description: 'กรุณาลองใหม่อีกครั้ง',
  disApproval: 'ไม่อนุมัติ',
  cancel: 'ยกเลิก',
  bank: 'ธนาคาร: ',
  accountNo: 'หมายเลขบัญชี: ',
  amount: 'จำนวนเงิน: ',
  baht: ' บาท',
  note: 'หมายเหตุ *',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDepositErrorModal = {
  title: constants.title,
  description: constants.description,
  cancel: constants.cancel,
  disApproval: constants.disApproval,
  txStatus: 'SUCCESS',
  txId: 0,
  action(description: string, status: 'SUCCESS' | 'FAIL', txId: number) { noop() },
  cancelAction() { noop() },
}

const ErrorModal: SFC<IDepositErrorModal & DefaultProps> = (props: IDepositErrorModal) => {

  const [description, setDescription] = useState('')

  const handleSubmit = () => {
    props.action!(
      description,
      props.txStatus,
      props.txId,
    )
  }

  return (
    <div className="error-modal-deposit-container">
      <div className="title-error-modal-deposit">
        <h4>{props.title}</h4>
      </div>
      <div className="sub-title-error-modal-deposit">
        <h6>{props.subTitle}</h6>
      </div>
      <div className="description-error-modal-deposit body-2">
      <SimpleBar style={{minHeight:'100%', maxHeight: '100vh'}}>
        <div className="margin-bottom">
          {props.name}
        </div>
        <div className="description-setings">
          <div className="secondary-text">{constants.bank}</div>{props.bank}
        </div>
        <div className="description-setings">
          <div className="secondary-text">{constants.accountNo}</div>{number.formatBankNumber(get(props,'accountNo',''),get(props,'bank',''))}
        </div>
        <div className="amount-settings">
          <div className="secondary-text">{constants.amount}</div>
          <div className="primary-green-text">
            {transformer.typeFormat(props.money!)}
          </div>
          <div>{constants.baht}</div>
        </div>
      </SimpleBar>
      </div>

      <div>
        <TextField
          placeholder={constants.note}
          fullWidth={true}
          label={constants.note}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <Grid container justify="center">
        <Grid item xs={4}>
          {}
        </Grid>

        <Grid item xs={8}>
          <div className="buttton-container">
            <div className="footer-wrapper-success-modal-deposit">
              <Button
                onClick={props.cancelAction}
                text={props.cancel!}
                theme="outline"
              />
            </div>
            <div className="footer-wrapper-success-modal-deposit">
              <Button
                onClick={handleSubmit}
                text={props.disApproval!}
                color="red"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ErrorModal.defaultProps = defaultProps

export default ErrorModal