import React, { Component } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { TitlePage, Select } from 'components'
import {
    // DepositWithdrawSubscriber,
    // RegisterSubscriber,
    DepositWithdrawSummary,
    YegeeSummary,
    IncomeSummary,
    LotterNumberSession,
} from './components'
import { LOTTO_SESSION_TYPE, LOTTO_SESSION_CODE } from 'constants/variables'
import { date } from 'utils'
import { noop, get } from 'lodash'
import moment from 'moment'
import './Dashboard.container.scss'
import { responseCode } from 'constants/response'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const monthList = [
    { value: '01', element: <h6>มกราคม</h6> },
    { value: '02', element: <h6>กุมภาพันธ์</h6> },
    { value: '03', element: <h6>มีนาคม</h6> },
    { value: '04', element: <h6>เมษายน</h6> },
    { value: '05', element: <h6>พฤษภาคม</h6> },
    { value: '06', element: <h6>มิถุนายน</h6> },
    { value: '07', element: <h6>กรกฎาคม</h6> },
    { value: '08', element: <h6>สิงหาคม</h6> },
    { value: '09', element: <h6>กันยายน</h6> },
    { value: '10', element: <h6>ตุลาคม</h6> },
    { value: '11', element: <h6>พฤศจิกายน</h6> },
    { value: '12', element: <h6>ธันวาคม</h6> },
]

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IDashboardProps & IDashboardActionProps = {
    getReportOverview() { noop() },
    getReportOverviewCode: 0,
    getReportOverviewError: '',
    getReportOverviewIsFetching: false,
    getReportOverviewResult: {
        allIncome: 0,
        monthIncome: 0,
        monthBetTxMaxIncome: 0,
        monthBetTxMinIncome: 0,
        dayBetTxMaxIncome: 0,
        dayOfBetTxMaxIncome: '',
        dayBetTxMinIncome: 0,
        dayOfBetTxMinIncome: '',
        incomeLine: [{
            x: '',
            y: 0,
        }],
        moneyLoseLine: [{
            x: '',
            y: 0,
        }],
    },
    getReportYegee() { noop() },
    getReportYegeeCode: 0,
    getReportYegeeError: '',
    getReportYegeeIsFetching: false,
    getReportYegeeResult: {
        allIncome: 0,
        averageIncome: 0,
        totalUser: 0,
        averageUser: 0,
        dataList: [{ 'ได้': 0, 'เสีย': 0, round: 0 }],
    },
    getReportLotterNumberSession() { noop() },
    getReportLotterNumberSessionCode: 0,
    getReportLotterNumberSessionError: '',
    getReportLotterNumberSessionIsFetching: false,
    getReportLotterNumberSessionResult: {
        sumBetTotal: 0,
        dataList: [{
            number: '',
            totalBet: 0,
            result: 0,
            rateLevel: {
                id: 0,
                level: 0,
                volumeLimit: 0,
                rate: 0,
                code: '',
                type: '',
                createdAt: '',
                updatedAt: '',
            },
        }],
    },
    postReportLotterDepositWithdraw() { noop() },
    postReportDepositWithdrawCode: 0,
    postReportDepositWithdrawError: '',
    postReportDepositWithdrawIsFetching: false,
    postReportDepositWithdrawResult: {
        dataList: [{
            type: '',
            ยอดรวมฝากเงิน: 0,
            ยอดรวมถอนเงิน: 0,
        }],
        balance: 0,
        graphType: 'horizontal',
    },
}

class DashboardContainer extends Component<DefaultProps & IDashboardProps & IDashboardActionProps, IDashboardState> {
    static defaultProps = defaultProps

    state: IDashboardState = {
        queryIncomeQueryMonth: moment().tz('Asia/Bangkok').format('MM'),
        queryLotterDepositWithdraw: moment().tz('Asia/Bangkok').format('MM'),
        queryYegeeDate: moment().tz('Asia/Bangkok').format('DD'),
        incomeSystem: {
            allIncome: 0,
            monthIncome: 0,
            monthBetTxMaxIncome: 0,
            monthBetTxMinIncome: 0,
            dayBetTxMaxIncome: 0,
            dayOfBetTxMaxIncome: '',
            dayBetTxMinIncome: 0,
            dayOfBetTxMinIncome: '',
            incomeLine: [{
                x: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
                y: 0,
            }],
            moneyLoseLine: [{
                x: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
                y: 0,
            }],
        },
        yegeeDaily: {
            allIncome: 0,
            averageIncome: 0,
            totalUser: 0,
            averageUser: 0,
            dataList: [{ 'ได้': 0, 'เสีย': 0, round: 0 }],
        },
        daysInMonth: date.buildDaysInMonthSelect(),
        queryLotterNumberSessionType: Object.keys(LOTTO_SESSION_TYPE)[0],
        queryLotterNumberSessionCode: Object.keys(LOTTO_SESSION_CODE)[0],
        DepositWithdrawSummary: {
            dataList: [{
                type: '',
                ยอดรวมฝากเงิน: 0,
                ยอดรวมถอนเงิน: 0,
            }],
            balance: 0,
            graphType: 'horizontal',

        },
    }
    componentDidMount() {
        this.props.getReportOverview({ type: 'month', date: this.dateMonthTransform(this.state.queryIncomeQueryMonth) })
        this.props.getReportYegee({ date: this.dateDayTransform(this.state.queryYegeeDate) })
        this.props.getReportLotterNumberSession(
            {
                code: this.state.queryLotterNumberSessionCode,
                type: this.state.queryLotterNumberSessionType,
            })
        this.props.postReportLotterDepositWithdraw(
            {
                type: 'month',
                date: this.dateMonthTransform(this.state.queryLotterDepositWithdraw),
            })
    }

    componentDidUpdate(prevProps: IDashboardProps) {
        if ((prevProps.getReportOverviewIsFetching !== this.props.getReportOverviewIsFetching
            && !this.props.getReportOverviewIsFetching)) {
            if (this.props.getReportOverviewCode === responseCode.OK) {
                this.setState({ incomeSystem: this.props.getReportOverviewResult })
            }
        }
        if ((prevProps.getReportYegeeIsFetching !== this.props.getReportYegeeIsFetching
            && !this.props.getReportYegeeIsFetching)) {
            if (this.props.getReportYegeeCode === responseCode.OK) {
                this.setState({ yegeeDaily: this.props.getReportYegeeResult })
            }
        }
        if ((prevProps.postReportDepositWithdrawIsFetching !== this.props.postReportDepositWithdrawIsFetching
            && !this.props.postReportDepositWithdrawIsFetching)) {
            if (this.props.postReportDepositWithdrawCode === responseCode.OK) {
                this.setState({ DepositWithdrawSummary: this.props.postReportDepositWithdrawResult })
            }
        }
    }

    transformSelectLotterCode = () => Object.keys(LOTTO_SESSION_CODE).map((lottoCode) => {
        return {
            value: lottoCode,
            element: <h6>{LOTTO_SESSION_CODE[lottoCode as TLottoSessionCode]}</h6>,
        }
    })

    transformSelectLotterType = () => Object.keys(LOTTO_SESSION_TYPE).map((lottoType) => {
        return {
            value: lottoType,
            element: <h6>{LOTTO_SESSION_TYPE[lottoType as TLottoSessionType]}</h6>,
        }
    })

    dateDayTransform = (day: string): string => {
        return moment(day, 'DD').format('DDMMYYYY')
    }

    dateMonthTransform = (month: string): string => {
        return moment(month, 'MM').format('DDMMYYYY')
    }

    handleSelectMonthIncome = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ queryIncomeQueryMonth: e.target.value },
            () => this.props.getReportOverview({
                type: 'month',
                date: this.dateMonthTransform(this.state.queryIncomeQueryMonth),
            }))
    }

    handleSelectDayYegee = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ queryYegeeDate: e.target.value },
            () => this.props.getReportYegee({
                date: this.dateDayTransform(this.state.queryYegeeDate),
            }))
    }

    handleSelectLotterNumberSessionType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ queryLotterNumberSessionType: e.target.value },
            () => this.props.getReportLotterNumberSession(
                {
                    code: this.state.queryLotterNumberSessionCode,
                    type: this.state.queryLotterNumberSessionType,
                })
        )
    }

    handleSelectLotterNumberSessionCode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ queryLotterNumberSessionCode: e.target.value },
            () => this.props.getReportLotterNumberSession(
                {
                    code: this.state.queryLotterNumberSessionCode,
                    type: this.state.queryLotterNumberSessionType,
                })
        )
    }

    render() {
        return (
            <div className="dashboard-container">
                <TitlePage title="รายงาน" />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TitlePage
                                            title="กำไรระบบ"
                                            className="primary-purple-text"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="select-month-container">
                                            <Select
                                                fullWidth={true}
                                                value={this.state.queryIncomeQueryMonth}
                                                itemList={monthList}
                                                onChange={this.handleSelectMonthIncome}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="paper-body">
                                <IncomeSummary
                                    allIncome={this.state.incomeSystem.allIncome}
                                    monthIncome={this.state.incomeSystem.monthIncome}
                                    monthBetTxMaxIncome={this.state.incomeSystem.monthBetTxMaxIncome}
                                    monthBetTxMinIncome={this.state.incomeSystem.monthBetTxMinIncome}
                                    dayBetTxMaxIncome={this.state.incomeSystem.dayBetTxMaxIncome}
                                    dayOfBetTxMaxIncome={this.state.incomeSystem.dayOfBetTxMaxIncome}
                                    dayBetTxMinIncome={this.state.incomeSystem.dayBetTxMinIncome}
                                    dayOfBetTxMinIncome={this.state.incomeSystem.dayOfBetTxMinIncome}
                                    incomeLine={this.state.incomeSystem.incomeLine}
                                    moneyLoseLine={this.state.incomeSystem.moneyLoseLine}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">ฝาก - ถอน ปัจจุบัน</div>
                            <div className="paper-body" style={{ height: '60vh' }}>
                                <DepositWithdrawSubscriber />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">สมัครสมาชิก ปัจจุบัน</div>
                            <div className="paper-body" style={{ height: '60vh' }}>
                                <RegisterSubscriber />
                            </div>
                        </Paper>
                    </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TitlePage
                                            title="ผลรวม ฝาก - ถอน"
                                            className="primary-purple-text"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="paper-body" style={{ height: '79vh' }}>
                                <DepositWithdrawSummary
                                    dataList={this.state.DepositWithdrawSummary.dataList}
                                    graphType={this.state.DepositWithdrawSummary.graphType}
                                    balance={this.state.DepositWithdrawSummary.balance}
                                />
                            </div>
                        </Paper>
                        <Grid item xs={6}>
                            {}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TitlePage
                                            title="ยี่กีรายวัน"
                                            className="primary-purple-text"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="select-month-container">
                                            <Select
                                                fullWidth={true}
                                                value={this.state.queryYegeeDate}
                                                itemList={this.state.daysInMonth}
                                                onChange={this.handleSelectDayYegee}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="paper-body" style={{ height: '92vh' }}>
                                <YegeeSummary
                                    allIncome={this.state.yegeeDaily.allIncome}
                                    totalUser={this.state.yegeeDaily.totalUser}
                                    dataList={this.state.yegeeDaily.dataList}
                                    averageUser={this.state.yegeeDaily.averageUser}
                                    averageIncome={this.state.yegeeDaily.averageIncome}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <TitlePage
                                            title="ยอดแทงหวย"
                                            className="primary-purple-text"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="summary-total">
                                            <h5>
                                                ยอดเดิมพัน: {
                                                    get(this.props,
                                                        'getReportLotterNumberSessionResult.sumBetTotal', '0')
                                                        .toLocaleString('en-US')} บาท
                                            </h5>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="select-month-container">
                                            <Select
                                                label="ประเภทหวย"
                                                fullWidth={true}
                                                value={this.state.queryLotterNumberSessionCode}
                                                itemList={this.transformSelectLotterCode()}
                                                onChange={this.handleSelectLotterNumberSessionCode}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="select-month-container">
                                            <Select
                                                label="ประเภทการเดิมพัน"
                                                fullWidth={true}
                                                value={this.state.queryLotterNumberSessionType}
                                                itemList={this.transformSelectLotterType()}
                                                onChange={this.handleSelectLotterNumberSessionType}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="paper-body" style={{ height: '50vh' }}>
                            <SimpleBar style={{minHeight:'100%', maxHeight: '100vh'}}>
                                <LotterNumberSession
                                    lotterNumberSessionList={this.props.getReportLotterNumberSessionResult.dataList}
                                />
                            </SimpleBar>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default DashboardContainer