import { combineReducers } from 'redux'
import { betRatesAllReducer, betRatesPutReducer } from './betRates/reducers'
import { yegeeGameConfigReducer, putYegeeGameConfigReducer } from './yegeeConfig/reducers'
import {
  broadcastPostReducer,
  broadcastListReducer,
  broadcastDeleteReducer,
  saveBroadcastReducer,
  broadcastSummaryReducer,
  broadcastLogReducer,
}
  from './broadcastLine/reducers'

export default combineReducers({
  allBetRates: betRatesAllReducer,
  putBetRate: betRatesPutReducer,
  getYegeeConfig: yegeeGameConfigReducer,
  putYegeeConfig: putYegeeGameConfigReducer,
  postBroadcast: broadcastPostReducer,
  allBroadcast: broadcastListReducer,
  deleteBroadcast: broadcastDeleteReducer,
  saveBroadcast: saveBroadcastReducer,
  summaryBroadcast :broadcastSummaryReducer,
  broadcastLog : broadcastLogReducer,
})