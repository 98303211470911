import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core'
import './CreateUser.scss'
import { History } from 'history';
import { responseCode, responseMessage } from 'constants/response'
import { noop, trim } from 'lodash'
import bank from 'assets/images/global/bank'
import {
  Button,
  TextField,
  Select,
  Modal,
  TitlePage,
  Breadcrumb,
  InputNumber,
} from 'components'
import { Formik, Form } from 'formik'
import { initialValue } from '../models/initialValues'
import { scheme } from '../models/scheme'

const constants = {
  placeholderUsername: 'ชื่อผู้ใช้ *',
  placeholderPassword: 'รหัสผ่าน *',
  placeholderConfirmPassword: 'ยืนยันรหัสผ่าน *',
  placeholderAffilate: 'รหัสคนชวน ',
  placeholderPhoneNumber: 'ระบุหมายเลขโทรศัพท์ 10 หลัก',
  placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),
  selectBankText: 'เลือกธนาคาร *',
  placeholderBankNumber: 'หมายเลขบัญชีธนาคาร *',
  placeholderOwnerName: 'ชื่อ - นามสกุลบัญชีธนาคาร *',
  buttonConfirm: 'สมัครสมาชิก',
  wrongCreateUserTitle: 'สร้างผู้ใช้ผิดพลาด',
  wrongCreateUserDescription: 'ไม่สามารถสร้างผู้ใช้ เนื่องจากมีอยู่ในระบบแล้ว',
  wrongUserDescription: 'ไม่สามารถสร้างผู้ใช้ เนื่องจากมีชื่อผู้ใช้อยู่ในระบบแล้ว',
  wrongBankDescription: 'ไม่สามารถสร้างผู้ใช้ เนื่องจากมีเลขบัญชีนี้อยู่ในระบบแล้ว',
  wrongPhoneDescription: 'ไม่สามารถสร้างผู้ใช้ เนื่องจากมีเบอร์มือถือนี้อยู่ในระบบแล้ว',
  wrongUuidDescription: 'ไม่สามารถสร้างผู้ใช้  เนื่องจากไม่มีรหัสคนชวนนี้ในระบบ',
  wrongInvalidUuidDescription: 'ไม่สามารถสร้างผู้ใช้ เนื่องจากไม่ถูกรูปแบบ',
  errorPhone: 'duplicate phone number',
  errorBank: 'duplicate bank number',
  errorUser: 'duplicate user',
  errorUuid : 'uuid not relation',
  invalidUuid: 'invalid type uuid',
  userLabel: 'ผู้ใช้',
  systemLabel: 'จัดการระบบ',
  addUserLabel: 'เพิ่มผู้ใช้',
}

type DefaultProps = Readonly<typeof defaultProps>


const defaultProps: IUserCreateManagementProps & ICreateManagementActionProps = {
  postUser() { noop() },
  loader() { noop() },
  postUserCode: 0,
  postUserError: '',
  postUserIsFetching: false,
  postUserdevMessage: '',
  postUserResult: {
    id: 0,
    username: '',
    password: '',
    phoneNumber: '',
    walletId: 0,
    userBankId: 0,
    affilateMeUuid: '',
    permission: '',
    isFake: false,
    createdAt: '',
    updatedAt: '',
    bank: {
      id: 0,
      type: '',
      name: '',
      number: '',
      isFake: false,
      createdAt: '',
      updatedAt: '',
    },
    wallet: '',
    action: '',
  },
}
class CreateUser
  extends Component<ICreateManagementActionProps & IUserCreateManagementProps & { history: History },
  & IUserManagementCreateContainerState> {

  static defaultProps = defaultProps
  constructor(props: ICreateManagementActionProps & IUserCreateManagementProps & { history: History }
    & DefaultProps) {
    super(props)
    this.state = {
      bank: '',
    }
  }

  componentDidUpdate(prevProps: IUserCreateManagementProps) {
    if ((prevProps.postUserIsFetching !== this.props.postUserIsFetching
      && !this.props.postUserIsFetching)) {
      if (this.props.postUserCode === responseCode.OK) {
        this.props.history.push('/user-management')
      }
      else if(constants.errorPhone === this.props.postUserdevMessage) {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongPhoneDescription,
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
      }
      else if(constants.errorBank === this.props.postUserdevMessage) {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongBankDescription,
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
      }
      else if(constants.errorUser === this.props.postUserdevMessage) {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongUserDescription,
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
      }
      else if(constants.errorUuid === this.props.postUserdevMessage) {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongUuidDescription,
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
      }
      else if(constants.invalidUuid === this.props.postUserdevMessage) {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongInvalidUuidDescription,
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: responseMessage[this.props.postUserCode],
          actionText: constants.wrongCreateUserTitle,
        })
        this.props.loader(false)
        // TODO
      }

    }
  }

  onSubmitLogin = (values: IRegister) => {
    const data = {
      username: trim(values.username),
      password: trim(values.password),
      password_confirm: values.confirmPassword,
      phone_number: values.phoneNumber,
      permission: 'USER',
      bank: {
        type: values.bankNumber,
        name: values.name,
        number: values.number,
      },
      affilate_uuid: values.affilateNumber !== '' ? values.affilateNumber : null,
    }
    this.props.loader(true)
    this.props.postUser(data)
  }

  handleWebBankChange = (bankName: string) => {
    this.setState({ bank: bankName })
  }
  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }
  formatBankNumber = (type:string)=>{
    return type === 'GSB' || type ==='BAAC'
    ? '# ### #### ####'
    : '### ###### #'
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.userLabel, path: '/user-management', active: false },
      { label: constants.addUserLabel, active: true },
    ]
    return (
      <div>
        <div className="crate-user-container">
          <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
          <TitlePage title={constants.addUserLabel} />
          <Grid container>
            <Grid item xs={6}>
              <Paper className="paper-container secondary-dark">
                <Formik
                  initialValues={initialValue}
                  validationSchema={scheme}
                  enableReinitialize
                  onSubmit={this.onSubmitLogin}
                >
                  {
                    ({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                       // @ts-ignore
                        <Form>
                          <div className="paper-body">
                            <TextField
                              onBlur={handleBlur}
                              name="username"
                              label="ชื่อผู้ใช้"
                              fullWidth={true}
                              placeholder={constants.placeholderInput(constants.placeholderUsername)}
                              onChange={handleChange}
                              value={values.username.toLowerCase()}
                              error={!!errors.username && touched.username}
                              helperText={!!errors.username && touched.username ? `${errors.username}` : ''}
                            />
                            <TextField
                              onBlur={handleBlur}
                              name="password"
                              label="รหัสผ่าน"
                              fullWidth={true}
                              placeholder={constants.placeholderInput(constants.placeholderPassword)}
                              onChange={handleChange}
                              value={values.password.toLowerCase()}
                              error={!!errors.password && touched.password}
                              helperText={!!errors.password && touched.password ? `${errors.password}` : ''}
                            />
                            <TextField
                              name="confirmPassword"
                              label="ยืนยันรหัสผ่าน"
                              fullWidth={true}
                              placeholder={constants.placeholderInput(constants.placeholderConfirmPassword)}
                              onChange={handleChange}
                              value={values.confirmPassword.toLowerCase()}
                              error={!!errors.confirmPassword && touched.confirmPassword}
                              helperText={!!errors.confirmPassword && touched.confirmPassword ? `${errors.confirmPassword}` : ''}
                            />
                            <TextField
                              name="phoneNumber"
                              label="หมายเลขโทรศัพท์"
                              fullWidth={true}
                              placeholder={constants.placeholderPhoneNumber}
                              onChange={handleChange}
                              value={values.phoneNumber.toLowerCase()}
                              error={!!errors.phoneNumber && touched.phoneNumber}
                              helperText={!!errors.phoneNumber && touched.phoneNumber ? `${errors.phoneNumber}` : ''}
                            />
                            <TextField
                              name="affilateNumber"
                              label="รหัสคนชวน"
                              fullWidth={true}
                              onChange={handleChange}
                              value={values.affilateNumber.toLowerCase()}
                              error={!!errors.affilateNumber && touched.affilateNumber}
                              helperText={!!errors.affilateNumber && touched.affilateNumber ? `${errors.affilateNumber}` : ''}
                            />
                            <Select
                              name="bankNumber"
                              label="เลือกธนาคาร"
                              // maxWidth="700px"
                              fullWidth={true}
                              value={this.state.bank}
                              onChange={(event) => {
                                this.handleWebBankChange(event.target.value)
                                setFieldValue(event.target.name, event.target.value)
                              }}

                              error={!!errors.bankNumber && touched.bankNumber}
                              helperText={!!errors.bankNumber && touched.bankNumber ? `${errors.bankNumber}` : ''}
                              itemList={Object.keys(bank).map((item) => {
                                return {
                                  // @ts-ignore
                                  value: bank[item].key,
                                  element: (
                                    <div>
                                      <div style={{ display: 'flex' }}>
                                        <div style={{ width: '40px', height: '40px', marginRight: '16px' }}>
                                          <img
                                            src={bank[item].Icon}
                                            style={{
                                              width: 'inherit',
                                              height: 'inherit',
                                            }}
                                            alt="thailand-flat"
                                          />
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                          <h6>{bank[item].name}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                }
                              })

                              }
                            />
                            <TextField
                              name="name"
                              label="ชื่อเจ้าของบัญชี"
                              fullWidth={true}
                              onChange={handleChange}
                              value={values.name.toLowerCase()}
                              error={!!errors.name && touched.name}
                              helperText={!!errors.name && touched.name ? `${errors.name}` : ''}
                            />
                            <InputNumber
                              onBlur={handleBlur}
                              disabled={this.state.bank === '' ? true : false}
                              name="number"
                              label="เลขบัญชี"
                              fullWidth={true}
                              onValueChange={({ value }) => setFieldValue('number', value)}
                              decimalScale={0}
                              format={this.formatBankNumber(this.state.bank)}
                              allowNegative={false}
                              value={values.number.toLowerCase()}
                              errorMessage={errors.number}
                              error={!!errors.number && touched.number}
                              helperText={!!errors.number && touched.number ? `${errors.number}` : ''}
                            />
                          </div>
                          <div className="paper-bottom">
                            <Button
                              type="submit"
                              text="สมัครสมาชิก"
                              fullWidth={true}
                            />
                          </div>
                        </Form>
                      )
                  }
                </Formik>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <></>
            </Grid>
          </Grid>
        </div >
      </div >
    )
  }
}

export default CreateUser