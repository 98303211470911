import { getType } from 'typesafe-actions'
import { get } from 'lodash'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const userMeReducer = (state: IGetMeState = initialState, action: RootAction):
  IGetMeState => {
  switch (action.type) {
    case getType(actions.getUserMeAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getUserMeSuccessAction):
      return {
        isFetching: false,
        data:{
          id: get(action, 'payload.data.data.id', 0),
          username: get(action, 'payload.data.data.username', ''),
          password: get(action, 'payload.data.data.password', ''),
          phone_number: get(action, 'payload.data.data.phone_number', ''),
          wallet_id: get(action, 'payload.data.data.wallet_id', 0),
          user_bank_id: get(action, 'payload.data.data.user_bank_id', 0),
          affilate_me_uuid: get(action, 'payload.data.data.affilate_me_uuid', ''),
          permission: get(action, 'payload.data.data.permission', ''),
          is_fake: get(action, 'payload.data.data.is_fake', false),
          created_at: get(action, 'payload.data.data.created_at', new Date()),
          updated_at: get(action, 'payload.data.data.updated_at', new Date()),
          bank: get(action, 'payload.data.data.bank', ''),
          wallet: get(action, 'payload.data.data.wallet', ''),
        },
        code: action.payload.data.code,
      }
    case getType(actions.getUserMeFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}
export default userMeReducer