import { string, object } from 'yup'
import { NUMBER_NO } from 'constants/regex'

const constants = {
  onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
  bankType: 'กรุญาเลือกธนาคาร',
  bankNumber: 'กรุณากรอกหมายเลขบัญชีธนาคาร',
  ownerName: 'กรุณากรอกชื่อเจ้าของบัญชี',
}

const bankLenght = (bankType: string) => {
  return `กรุณากรอกเลขบัญชีธนาคารให้ครบ ${bankType === 'GSB' || bankType === 'BAAC' ? '12' : '10'} ตัว`
}

const schema = (bankType: string) => {
  return object().shape({
    bank: string().required(constants.bankType),
    number: string()
      .required(constants.bankNumber)
      .matches(NUMBER_NO, constants.onlyNumber)
      .test(
        'check length',
        bankLenght(bankType),
        function (value: string) {
          if (this.parent.bank === 'GSB' || this.parent.bank === 'BAAC') {
            return (value.length === 12)
          } else {
            return (value.length === 10)
          }
        }),
    name: string().required(constants.ownerName),
  })
}

export default schema;