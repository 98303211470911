import project from 'constants/project'

export const GET_BET_RATES_ALL_REQUEST = 'GET_BET_RATES_ALL_REQUEST'
export const GET_BET_RATES_ALL_SUCCESS = 'GET_BET_RATES_ALL_SUCCESS'
export const GET_BET_RATES_ALL_FAILURE = 'GET_BET_RATES_ALL_FAILURE'
export const GET_BET_RATES_ALL_CANCEL = 'GET_BET_RATES_ALL_CANCEL'

export const PUT_BET_RATES_REQUEST = 'PUT_BET_RATES_REQUEST'
export const PUT_BET_RATES_SUCCESS = 'PUT_BET_RATES_SUCCESS'
export const PUT_BET_RATES_FAILURE = 'PUT_BET_RATES_FAILURE'
export const PUT_BET_RATES_CANCEL = 'PUT_BET_RATES_CANCEL'

export const initialState: IBetRatesAllState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getBetRatesAll: `${project.environment[project.environmentName].api}/lotter/bet/rate`,
  putBetRates: `${project.environment[project.environmentName].api}/lotter/bet/rate`,
  yegeeGameConfig: `${project.environment[project.environmentName].api}/config/yegee/game`,
}