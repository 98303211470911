import { createAction } from 'typesafe-actions'
import {
  GET_NOTIFICATION_TRANSACTION_ALL_REQUEST,
  GET_NOTIFICATION_TRANSACTION_ALL_SUCCESS,
  GET_NOTIFICATION_TRANSACTION_ALL_FAILURE,
  GET_NOTIFICATION_TRANSACTION_ALL_CANCEL,
  UPDATE_NOTIFICATION_TRANSACTION_ALL_REQUEST,
  UPDATE_NOTIFICATION_TRANSACTION_ALL_SUCCESS,
  UPDATE_NOTIFICATION_TRANSACTION_ALL_FAILURE,
  LISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET,
  UNLISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET,
  CLEAR_NOTIFICATION_TRANSACTION_ALL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getNotificationTransactionAllAction = createAction(GET_NOTIFICATION_TRANSACTION_ALL_REQUEST,
  reslove => () => reslove())

const getNotificationTransactionAllSuccessAction = createAction(
  GET_NOTIFICATION_TRANSACTION_ALL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<INotification>>) => resolve(data))

const getNotificationTransactionAllFailureAction = createAction(
  GET_NOTIFICATION_TRANSACTION_ALL_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getNotificationTransactionAllCancelAction = createAction(GET_NOTIFICATION_TRANSACTION_ALL_CANCEL)

const updateNotificationTransactionAllAction = createAction(
  UPDATE_NOTIFICATION_TRANSACTION_ALL_REQUEST,
  resolve => (data: APIResponse<INotification>) => resolve(data))

const updateNotificationTransactionAllActionSuccessAction = createAction(
  UPDATE_NOTIFICATION_TRANSACTION_ALL_SUCCESS,
  resolve => (data: APIResponse<INotification>) => resolve(data))

const updateNotificationTransactionAllActionFailureAction = createAction(
  UPDATE_NOTIFICATION_TRANSACTION_ALL_FAILURE,
  resolve => (error: any) => resolve(error))

const listenNotificationTransactionAllSocketAction = createAction(
  LISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET,
  resolve => () => resolve())
const unlistenNotificationTransactionAllSocketAction = createAction(
  UNLISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET,
  resolve => () => resolve())

const clearNotificationTransactionAll = createAction(CLEAR_NOTIFICATION_TRANSACTION_ALL)

export default {
  getNotificationTransactionAllAction,
  getNotificationTransactionAllSuccessAction,
  getNotificationTransactionAllFailureAction,
  getNotificationTransactionAllCancelAction,
  updateNotificationTransactionAllAction,
  updateNotificationTransactionAllActionSuccessAction,
  updateNotificationTransactionAllActionFailureAction,
  listenNotificationTransactionAllSocketAction,
  unlistenNotificationTransactionAllSocketAction,
  clearNotificationTransactionAll,
}