import { createAction } from 'typesafe-actions'
import {
  POST_USER_LINE_REQUEST,
  POST_USER_LINE_SUCCESS,
  POST_USER_LINE_FAILURE,
  DELETE_USER_LINE_REQUEST,
  DELETE_USER_LINE_SUCCESS,
  DELETE_USER_LINE_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const postUserLineAction = createAction(
  POST_USER_LINE_REQUEST,
  reslove => (data: IPostUserLineData) => reslove(data)
)
const postUserLineSuccessAction = createAction(
  POST_USER_LINE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IGetMeState>>>) => resolve(data)
)
const postUserLineFailureAction = createAction(
  POST_USER_LINE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const deleteUserLineAction = createAction(
  DELETE_USER_LINE_REQUEST,
  reslove => (data: IDeleteUserLineData) => reslove(data)
)
const deleteUserLineSuccessAction = createAction(
  DELETE_USER_LINE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IGetMeState>>) => resolve(data)
)
const deleteUserLineFailureAction = createAction(
  DELETE_USER_LINE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))


export default {
  postUserLineAction,
  postUserLineSuccessAction,
  postUserLineFailureAction,
  deleteUserLineAction,
  deleteUserLineSuccessAction,
  deleteUserLineFailureAction,
}