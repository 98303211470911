import { createAction } from 'typesafe-actions'
import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const deleteUserAction = createAction(
  DELETE_USER_REQUEST,
  reslove => (data: IManagementDeleteData) => reslove(data)
)
const deleteUserSuccessAction = createAction(
  DELETE_USER_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IUser>>) => resolve(data)
)
const deleteUserFailureAction = createAction(
  DELETE_USER_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

export default {
  deleteUserAction,
  deleteUserSuccessAction,
  deleteUserFailureAction,
}