import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
  fetchGetAccountUser,
  fetchGetWebBankActive,
  fetchPutAccountUser,
  fetchDeleteAccountUser,
  fetchPostAccountUser,
  fetchGetActiveWebBank,
  fetchGetInActiveWebBank,
  fetchPostMapBank,
} from './services'
import actions from './actions'

const webBankEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getWebbankAction)),
    exhaustMap(action =>
      from(fetchGetAccountUser(action.payload))
        .pipe(
          map(actions.getWebbankSuccessAction),
          catchError(error => of(actions.getWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getWebbankCancelAction))))
        ),
    )
  )

const ActivewebBankEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getActiveWebbankAction)),
    exhaustMap(action =>
      from(fetchGetActiveWebBank(action.payload))
        .pipe(
          map(actions.getActiveWebbankSuccessAction),
          catchError(error => of(actions.getActiveWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getActiveWebbankCancelAction))))
        ),
    )
  )

const InActivewebBankEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getInActiveWebbankAction)),
    exhaustMap(action =>
      from(fetchGetInActiveWebBank(action.payload))
        .pipe(
          map(actions.getInActiveWebbankSuccessAction),
          catchError(error => of(actions.getInActiveWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getInActiveWebbankCancelAction))))
        ),
    )
  )

const webBankActiveEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getWebbankActiveAction)),
    exhaustMap(action =>
      from(fetchGetWebBankActive(action.payload))
        .pipe(
          map(actions.getWebbankActiveSuccessAction),
          catchError(error => of(actions.getWebbankActiveFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getWebbankActiveCancelAction))))
        ),
    )
  )

const webBankPutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.putWebbankAction)),
    exhaustMap(action =>
      from(fetchPutAccountUser(action.payload))
        .pipe(
          map(actions.putWebbankSuccessAction),
          catchError(error => of(actions.getWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getWebbankCancelAction))))
        ),
    )
  )

const webBankDeleteEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.deleteWebbankAction)),
    exhaustMap(action =>
      from(fetchDeleteAccountUser(action.payload))
        .pipe(
          map(actions.deleteWebbankSuccessAction),
          catchError(error => of(actions.deleteWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.deleteWebbankCancelAction))))
        ),
    )
  )

const webBankPostEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postWebbankAction)),
    exhaustMap(action =>
      from(fetchPostAccountUser(action.payload))
        .pipe(
          map(actions.postWebbankSuccessAction),
          catchError(error => of(actions.postWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.postWebbankCancelAction))))
        ),
    )
  )

const mapWebBankPostEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postMapWebbankAction)),
    exhaustMap(action =>
      from(fetchPostMapBank(action.payload))
        .pipe(
          map(actions.postMapWebbankSuccessAction),
          catchError(error => of(actions.postMapWebbankFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.postMapWebbankCancelAction))))
        ),
    )
  )

const updateWebbankEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updateWebbankAction)),
    map((action) => actions.updateWebbankSuccessAction(action.payload))
  )
export default [
  webBankEpic,
  webBankActiveEpic,
  webBankPutEpic,
  webBankDeleteEpic,
  webBankPostEpic,
  mapWebBankPostEpic,
  updateWebbankEpic,
  ActivewebBankEpic,
  InActivewebBankEpic
]
