import project from 'constants/project'

export const GET_NOTIFICATION_TRANSACTION_ALL_REQUEST = 'GET_NOTIFICATION_TRANSACTION_ALL_REQUEST'
export const GET_NOTIFICATION_TRANSACTION_ALL_SUCCESS = 'GET_NOTIFICATION_TRANSACTION_ALL_SUCCESS'
export const GET_NOTIFICATION_TRANSACTION_ALL_FAILURE = 'GET_NOTIFICATION_TRANSACTION_ALL_FAILURE'
export const GET_NOTIFICATION_TRANSACTION_ALL_CANCEL = 'GET_NOTIFICATION_TRANSACTION_ALL_CANCEL'

export const UPDATE_NOTIFICATION_TRANSACTION_ALL_REQUEST = 'UPDATE_NOTIFICATION_TRANSACTION_ALL_REQUEST'
export const UPDATE_NOTIFICATION_TRANSACTION_ALL_SUCCESS = 'UPDATE_NOTIFICATION_TRANSACTION_ALL_SUCCESS'
export const UPDATE_NOTIFICATION_TRANSACTION_ALL_FAILURE= 'UPDATE_NOTIFICATION_TRANSACTION_ALL_FAILURE'

export const LISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET =  'LISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET'
export const UNLISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET = 'UNLISTEN_NOTIFICATION_TRANSACTION_ALL_SOCKET'
export const CLEAR_NOTIFICATION_TRANSACTION_ALL = 'CLEAR_TRANSACTION_ALL'

export const initialState: ITransactionAllState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getNotificationTransactionAll: `${project.environment[project.environmentName].api}/finance/notifications`,
}