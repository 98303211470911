import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import configAction from 'reduxs/config/actions'
import LottoListContainer from './LottoList.container'

const mapStateToProps = (state: RootReducers): ILottoListProps => ({
    getLottoScheduleIsFetching: state.mantra.config.lotto.schedule.isFetching!,
    getLottoScheduleCode: state.mantra.config.lotto.schedule.code!,
    getLottoScheduleError: state.mantra.config.lotto.schedule.error!,
    lottoSchedule: state.mantra.config.lotto.schedule.data!,
    getLottoIsFetching: state.mantra.config.lotto.list.isFetching!,
    getLottoError: state.mantra.config.lotto.list.error!,
    getLottoCode: state.mantra.config.lotto.list.code!,
    lottoList: state.mantra.config.lotto.list.data!,
    putLottoModeIsFetching: state.mantra.config.lotto.mode.isFetching!,
    putLottoModeCode: state.mantra.config.lotto.mode.code!,
    putLottoModeError: state.mantra.config.lotto.mode.error!,
    putlottoModeResult: state.mantra.config.lotto.mode.data!,
    putLottoStatusIsFetching: state.mantra.config.lotto.status.isFetching!,
    putLottoStatusCode: state.mantra.config.lotto.status.code!,
    putLottoStatusError: state.mantra.config.lotto.status.error!,
    putlottoStatusResult: state.mantra.config.lotto.status.data!,
    putlottoStatusResCode: state.mantra.config.lotto.status.resCode!,
    postLottoRefundIsFetching: state.mantra.config.lotto.refund.isFetching!,
    postLottoRefundCode: state.mantra.config.lotto.refund.code!,
    postLottoRefundError: state.mantra.config.lotto.refund.error!,
    postlottoRefundResult: state.mantra.config.lotto.refund.data!,
    postLottoResultIsFetching: state.mantra.config.lotto.result.isFetching!,
    postLottoResultCode: state.mantra.config.lotto.result.code!,
    postLottoResultError: state.mantra.config.lotto.result.error!,
    postlottoResultResult: state.mantra.config.lotto.result.data!,
    postLottoCalculateIsFetching: state.mantra.config.lotto.calculate.isFetching!,
    postLottoCalculateCode: state.mantra.config.lotto.calculate.code!,
    postLottoCalculateError: state.mantra.config.lotto.calculate.error!,
    postlottoCalculateResult: state.mantra.config.lotto.calculate.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ILottoListActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    getLottoList: configAction.getLottoListAction,
    getLottoSchedule: configAction.getLottoScheduleAction,
    putLottoMode:configAction.putLottoModeAction,
    putLottoStatus: configAction.putLottoStatusAction,
    postLottoRefund: configAction.postLottoRefundAction,
    postLottoResult: configAction.postLottoResultAction,
    postLottoCalculate: configAction.postLottoCalculateAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoListContainer)