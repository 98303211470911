import { createAction } from 'typesafe-actions'
import {
    GET_WEB_CONFIG_REQUEST,
    GET_WEB_CONFIG_CANCEL,
    GET_WEB_CONFIG_SUCCESS,
    GET_WEB_CONFIG_FAILURE,

    PUT_WEB_CONFIG_REQUEST,
    PUT_WEB_CONFIG_CANCEL,
    PUT_WEB_CONFIG_SUCCESS,
    PUT_WEB_CONFIG_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getWebConfigAction = createAction(GET_WEB_CONFIG_REQUEST,
)

const getWebConfigSuccessAction = createAction(
    GET_WEB_CONFIG_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IWebConfig>>) => resolve(data))

const getWebConfigFailureAction = createAction(
    GET_WEB_CONFIG_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const getWebConfigCancelAction = createAction(GET_WEB_CONFIG_CANCEL)

const putWebConfigAction = createAction(PUT_WEB_CONFIG_REQUEST,
    reslove => (data: IWebConfigPutData) => reslove(data)
)

const putWebConfigSuccessAction = createAction(
    PUT_WEB_CONFIG_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IWebConfig>>) => resolve(data))

const putWebConfigFailureAction = createAction(
    PUT_WEB_CONFIG_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const putWebConfigCancelAction = createAction(PUT_WEB_CONFIG_CANCEL)

export default {
    getWebConfigAction,
    getWebConfigSuccessAction,
    getWebConfigFailureAction,
    getWebConfigCancelAction,

    putWebConfigAction,
    putWebConfigSuccessAction,
    putWebConfigFailureAction,
    putWebConfigCancelAction,
}