import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import webbankAction from 'reduxs/webbank/webbanks/actions'
import loaderAction from 'reduxs/loader/actions'
import DeleteBankContainer from './DeleteWebBank'

const mapStateToProps = (state: RootReducers): any => {
  return {
    deleteWebBankIsFetching: state.mantra.webbank.webbanks.deleteWebbank.isFetching!,
    deleteWebBankError: state.mantra.webbank.webbanks.deleteWebbank.error!,
    deleteWebBankCode: state.mantra.webbank.webbanks.deleteWebbank.code!,
    deleteWebBankResult: state.mantra.webbank.webbanks.deleteWebbank.data!,

  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IDeleteWebBankActionProps => bindActionCreators({
  deleteWebBank: webbankAction.deleteWebbankAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(DeleteBankContainer)