import React, { Component } from 'react'
// @ts-ignore
import { ResponsiveBar } from '@nivo/bar'
import { Grid } from '@material-ui/core'
import { number } from 'utils'

import './YegeeSummary.component.scss'

const keys = ['ได้', 'เสีย']
const commonProps = {
    // width: 900,
    // height: 500,
    margin: { top: 60, right: 80, bottom: 60, left: 80 },
    indexBy: 'round',
    keys,
    padding: 0.5,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
}

class YegeeSummary extends Component<IResponseReportYegee, {}> {
    render() {
        return (
            <div className="yegee-summary-container">
                <ResponsiveBar
                    {...commonProps}
                    data={this.props.dataList}
                    borderWidth={.5}
                    borderColor="#fff"
                    colors={{ scheme: 'purpleRed_green' }}
                    defs={[
                        {
                            id: 'gradientB',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'red' },
                                { offset: 100, color: 'rgb(244, 117, 96)' },
                            ],
                        },
                        {
                            id: 'gradientC',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'rgb(167, 295, 187)' },
                                { offset: 100, color: 'rgb(97, 205, 187)' },
                            ],
                        },
                    ]}
                    fill={[
                        // match using object query
                        { match: { id: 'ได้' }, id: 'gradientC' },
                        { match: { id: 'เสีย' }, id: 'gradientB' },
                    ]}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 50,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 200,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
                <div className="detail-container">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4>รายละเอียด</h4>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ยอดรวม</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${this.props.allIncome >= 0 ? 'green' : 'red'}-text`}>
                            {number.numberFormat(String(this.props.allIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ยอดเฉลี่ยต่อรอบ</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${this.props.averageIncome >= 0 ? 'green' : 'red'}-text`}>
                            {number.numberFormat(String(this.props.averageIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>จำนวนผู้เล่นทั้งหมด</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5>
                            {number.numberFormat(String(this.props.totalUser))} คน
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>จำนวนผู้เล่นเฉลี่ยต่อรอบ</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5>
                            {number.numberFormat(String(this.props.averageUser))} คน
                            </h5>
                        </Grid>
                    </Grid>
                </div >
            </div >
        )
    }
}

export default YegeeSummary