import React, { SFC } from 'react'
import {
    MenuItem,
    TextField,
    InputAdornment,
} from '@material-ui/core'
import { noop } from 'lodash'

import './Select.component.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ISelect = {
    id: '',
    label: '',
    error: false,
    helperText: '',
    name: '',
    type: '',
    placeholder: '',
    value: '',
    fullWidth: false,
    isMultiline: false,
    itemList: [{ value: '', element: <h6>please select</h6> }],
    startElement: undefined,
    endElement: undefined,
    onBlur() { noop() },
    onClick() { noop() },
    onChange() { noop() },
    size: 'medium',
}

const SelectComponent: SFC<ISelect & DefaultProps> = (props) => {
    const {
        id,
        label,
        error,
        helperText,
        name,
        type,
        placeholder,
        value,
        itemList = [{ value: '', element: <h6>please select</h6> }],
        fullWidth,
        isMultiline,
        startElement,
        endElement,
        onClick,
        onBlur,
        onChange,
        size,
    } = props


    return (
        <div className={`select-container ${isMultiline ? 'multi-line' : ''}`}>
            <TextField
                select
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                label={label}
                variant="outlined"
                error={error}
                fullWidth={fullWidth}
                helperText={helperText}
                onClick={onClick}
                onBlur={onBlur}
                onChange={onChange}
                InputProps={{
                    startAdornment: startElement ? (
                        <InputAdornment position="start">
                            {startElement}
                        </InputAdornment>
                    ) : undefined,
                    endAdornment: endElement ? (
                        <InputAdornment position="end">
                            {endElement}
                        </InputAdornment>
                    ) : undefined
                }}
                size={size}
            >
                {
                    itemList.map((item, index) => (
                        <MenuItem key={`menu-item ${index}`} value={item.value}>{item.label ? item.label : item.element}</MenuItem>
                    ))
                }
            </TextField>
        </div>
    )
}

SelectComponent.defaultProps = defaultProps

export default SelectComponent