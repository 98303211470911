// import React from 'react'
import emiter from 'configs/emiter'
import event from 'constants/event'
import Snackbar from './Snackbar.component'

const info = {
    show: ({ message }: ISnackbarShowProps) => {
        return emiter.emit(event.SNACKBAR, {
            variant: 'info',
            message,
        })
    },
}

const success = {
    show: ({ message }: ISnackbarShowProps) => {
        return emiter.emit(event.SNACKBAR, {
            variant: 'success',
            message,
        })
    },
}

const warning = {
    show: ({ message }: ISnackbarShowProps) => {
        return emiter.emit(event.SNACKBAR, {
            variant: 'warning',
            message,
        })
    },
}

const error = {
    show: ({ message }: ISnackbarShowProps) => {
        return emiter.emit(event.SNACKBAR, {
            variant: 'error',
            message,
        })
    },
}

export default {
    Core: Snackbar,
    success,
    info,
    warning,
    error,
}