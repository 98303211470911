import axios from 'axios'
import { endpoint } from './constants'

export const fetchPostReserveTransaction =(
    data: IWalletTransactionReserve) => axios.post(endpoint.postReserveTransaction, data)

export const fetchDeleteReserveTransaction =(
    data: IWalletTransactionCancelReserve) => axios.delete(endpoint.deleteReserveTransaction, {data: data})

export const fetchForceDeleteReserveTransaction =(
    data: IWalletTransactionForceCancelReserve) => axios.delete(endpoint.deleteForceReserveTransaction, {data: data})