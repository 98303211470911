import project from 'constants/project'

export const GET_WEBBANK_RATE_REQUEST = 'GET_WEBBANK_RATE_REQUEST'
export const GET_WEBBANK_RATE_SUCCESS = 'GET_WEBBANK_RATE_SUCCESS'
export const GET_WEBBANK_RATE_FAILURE = 'GET_WEBBANK_RATE_FAILURE'
export const GET_WEBBANK_RATE_CANCEL = 'GET_WEBBANK_RATE_CANCEL'

export const initialState: ReducerState<IRate> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    limitMoney: 0,
  },
  error: '',
}


export const endpoint = {
  getRateWebbank: `${project.environment[project.environmentName].api}/config/web/webbank`,
}