import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import webbankAction from 'reduxs/webbank/webbanks/actions'
import webbankRateAction from 'reduxs/webbank/rate/actions'
import loaderAction from 'reduxs/loader/actions'
import WebBankContainer from './Webbank.container'

const mapStateToProps = (state: RootReducers): any => {
  return {
    getWebBankIsFetching: state.mantra.webbank.webbanks.getWebbank.isFetching!,
    getWebBankError: state.mantra.webbank.webbanks.getWebbank.error!,
    getWebBankCode: state.mantra.webbank.webbanks.getWebbank.code!,
    getWebBankResult: state.mantra.webbank.webbanks.getWebbank.data!,
    getWebBankLimit: state.mantra.webbank.webbanks.getWebbank.limit!,
    getWebBankPage: state.mantra.webbank.webbanks.getWebbank.page!,
    getWebBankTotal: state.mantra.webbank.webbanks.getWebbank.total!,

    getInActiveWebBankIsFetching: state.mantra.webbank.webbanks.getInActiveWebbank.isFetching!,
    getInActiveWebBankError: state.mantra.webbank.webbanks.getInActiveWebbank.error!,
    getInActiveWebBankCode: state.mantra.webbank.webbanks.getInActiveWebbank.code!,
    getInActiveWebBankResult: state.mantra.webbank.webbanks.getInActiveWebbank.data!,
    getInActiveWebBankLimit: state.mantra.webbank.webbanks.getInActiveWebbank.limit!,
    getInActiveWebBankPage: state.mantra.webbank.webbanks.getInActiveWebbank.page!,
    getInActiveWebBankTotal: state.mantra.webbank.webbanks.getInActiveWebbank.total!,

    getActiveWebBankIsFetching: state.mantra.webbank.webbanks.getActiveWebbank.isFetching!,
    getActiveWebBankError: state.mantra.webbank.webbanks.getActiveWebbank.error!,
    getActiveWebBankCode: state.mantra.webbank.webbanks.getActiveWebbank.code!,
    getActiveWebBankResult: state.mantra.webbank.webbanks.getActiveWebbank.data!,
    getActiveWebBankLimit: state.mantra.webbank.webbanks.getActiveWebbank.limit!,
    getActiveWebBankPage: state.mantra.webbank.webbanks.getActiveWebbank.page!,
    getActiveWebBankTotal: state.mantra.webbank.webbanks.getActiveWebbank.total!,

    getWebBankActiveIsFetching: state.mantra.webbank.webbanks.getWebbankActive.isFetching!,
    getWebBankActiveError: state.mantra.webbank.webbanks.getWebbankActive.error!,
    getWebBankActiveCode: state.mantra.webbank.webbanks.getWebbankActive.code!,
    getWebBankActiveResult: state.mantra.webbank.webbanks.getWebbankActive.data!,

    putWebBankIsFetching: state.mantra.webbank.webbanks.putWebbank.isFetching!,
    putWebBankError: state.mantra.webbank.webbanks.putWebbank.error!,
    putWebBankCode: state.mantra.webbank.webbanks.putWebbank.code!,
    putWebBankResult: state.mantra.webbank.webbanks.putWebbank.data!,

    deleteWebBankIsFetching: state.mantra.webbank.webbanks.deleteWebbank.isFetching!,
    deleteWebBankError: state.mantra.webbank.webbanks.deleteWebbank.error!,
    deleteWebBankCode: state.mantra.webbank.webbanks.deleteWebbank.code!,
    deleteWebBankResult: state.mantra.webbank.webbanks.deleteWebbank.data!,

    getWebBankRateIsFetching: state.mantra.webbank.rate.get.isFetching!,
    getWebBankRateIsError: state.mantra.webbank.rate.get.error!,
    getWebBankRateCode: state.mantra.webbank.rate.get.code!,
    getWebBankRateResult: state.mantra.webbank.rate.get.data!,
  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IWebBankActionProps => bindActionCreators({
  getWebBank: webbankAction.getWebbankAction,
  getActiveWebBank: webbankAction.getActiveWebbankAction,
  getInActiveWebBank: webbankAction.getInActiveWebbankAction,
  getWebBankRate: webbankRateAction.getWebbankRateAction,
  getWebBankActive: webbankAction.getWebbankActiveAction,
  putWebBank: webbankAction.putWebbankAction,
  deleteWebBank: webbankAction.deleteWebbankAction,
  listenWebbankSocket: webbankAction.listenWebbankSocketAction,
  unlistenWebbankSocket: webbankAction.unlistenWebbankSocketAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(WebBankContainer)