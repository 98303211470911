import React, { SFC } from "react";
import { Button } from "@material-ui/core";
import { noop } from "lodash"; // Temporary
import "./Button.component.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IButton = {
  id: "",
  text: "",
  onClick() {
    noop();
  },
  type: "button",
  size: "medium",
  disabled: false,
  theme: "default",
  fullWidth: false,
  color: undefined,
  buttonClass: ''
}


const ButtonComponent: SFC<IButton & DefaultProps> = (props) => {

  const {
    id,
    text,
    onClick,
    type,
    size,
    disabled,
    theme,
    fullWidth,
    color,
    buttonClass
  } = props


  let containerClass = `button-container`
  containerClass = `${containerClass} ${buttonClass} ${type}`
  containerClass = `${containerClass} ${size}`
  containerClass = `${containerClass} ${disabled ? 'disabled' : ''}`
  containerClass = `${containerClass} ${theme}`
  containerClass = `${containerClass} ${fullWidth ? 'full-width' : ''}`
  containerClass = `${containerClass} ${color ? color : ''}`

  return (
    <div className={containerClass} id={"div-" + id}>
      <Button
        id={"button-" + id}
        type={type}
        className={containerClass}
        onClick={disabled ? noop : onClick}
        disabled={disabled}
      >
        <h6 className="link">{text}</h6>
      </Button>
    </div>
  );
};

ButtonComponent.defaultProps = defaultProps;

export default ButtonComponent;
