import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const webBankReducer = (state: IWebbankStoreState = initialState, action: RootAction):
  IWebbankStoreState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.getWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        code: action.payload.data.code,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.limit * action.payload.data.data.total),
      }

    case getType(actions.getWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    case getType(actions.updateWebbankAction):
      return {
        isFetching: true,
        ...state,
      }
    case getType(actions.updateWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.dataList,
        page: (action.payload.data.page - 1),
        limit: action.payload.data.limit,
        total: (action.payload.data.limit * action.payload.data.total),
      }
    case getType(actions.updateWebbankFailureAction):
      return {
        ...state,
      }
    default:
      return state
  }
}

const activeWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction):
  IWebbankStoreState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.getActiveWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getActiveWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        code: action.payload.data.code,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.limit * action.payload.data.data.total),
      }

    case getType(actions.getActiveWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const inActiveWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction):
  IWebbankStoreState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.getInActiveWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getInActiveWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        code: action.payload.data.code,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.limit * action.payload.data.data.total),
      }

    case getType(actions.getInActiveWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}



const webBankActiveReducer = (state: IWebbankStoreState = initialState, action: RootAction): IWebbankStoreState => {
  switch (action.type) {
    case getType(actions.getWebbankActiveAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getWebbankActiveSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.getWebbankActiveFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}


const putWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction): IWebbankStoreState => {
  switch (action.type) {
    case getType(actions.putWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.putWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.putWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}


const deleteWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction): IWebbankStoreState => {
  switch (action.type) {
    case getType(actions.deleteWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.deleteWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.deleteWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}


const postWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction): IWebbankStoreState => {
  switch (action.type) {
    case getType(actions.postWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.postWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const postMapWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction): IWebbankStoreState => {
  switch (action.type) {
    case getType(actions.postMapWebbankAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postMapWebbankSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.postMapWebbankFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.response?.data.code,
      }
    default:
      return state
  }
}

// const updateWebBankReducer = (state: IWebbankStoreState = initialState, action: RootAction):
// IWebbankStoreState & IPaginationStore => {
//   switch (action.type) {
//     case getType(actions.updateWebbankAction):
//       return {
//         isFetching: true,
//         ...state,
//       }
//     case getType(actions.updateWebbankSuccessAction):
//       console.log('reducers',action.payload)
//       return {
//         isFetching: false,
//         data: action.payload.data.dataList,
//         page: (action.payload.data.page - 1),
//         limit: action.payload.data.limit,
//         total: (action.payload.data.limit * action.payload.data.total),
//       }
//     case getType(actions.updateWebbankFailureAction):
//       return {
//         ...state,
//       }
//     default:
//       return state
//   }
// }



export default combineReducers({
  getWebbank: webBankReducer,
  getWebbankActive: webBankActiveReducer,
  putWebbank: putWebBankReducer,
  deleteWebbank: deleteWebBankReducer,
  postWebbank: postWebBankReducer,
  getActiveWebbank: activeWebBankReducer,
  getInActiveWebbank: inActiveWebBankReducer,
  postMapWebbank: postMapWebBankReducer,
  // updateWebBankReducer,
})