import React from 'react'
import './RollerLoading.style.scss'

const homeButton = () => {
  return (
    <div className="col d-flex lds-roller">
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
      <div>{}</div>
    </div>
  )
}
export default homeButton