import { Home } from './Home'
import { News } from './News'
import { CriminalView } from './CriminalView'
import { Settings } from './Settings'
import { Withdraw } from './Withdraw'
import { DailyReport } from './Overview'
import { Affilate } from './Affilate'
import { WebConfig } from './WebConfig'
import { Dashboard } from './Dashboard'
import { LottoList } from './LottoList'
import { LottoRate } from './LottoRate'
import { NumberRate } from './NumberRate'
import { LottoCancel } from './LottoCancel'
import { Broadcast } from './Broadcast'
import { ComingSoon } from './ComingSoon'
import { Content } from './ContentManagement'
import { ReportSummary } from './ReportSummary'
import { LineManagement } from './LineManagement'
import { DepositTransaction } from './DepositTransaction'
import { UserManagement, CreateUser, DeleteUser, EditUser } from './UserManagement'
import { AdminManagement, CreateAdmin, DeleteAdmin, EditAdmin } from './AdminManagement'
import { StaffManagement, CreateStaff, DeleteStaff, EditStaff } from './StaffManagement'
import { LottoMasterManagement, CreateMaster, DeleteMaster, EditMaster } from './LottoMasterManagement'
import {
  Webbank,
  CreateWebBank,
  EditWebBank,
  DeleteWebBank
} from './Webbank'
import { ReportBenefit } from './ReportBenefit'
import Verify from './Verify'
export default {
  Home,
  Broadcast,
  ComingSoon,
  CriminalView,
  DepositTransaction,
  Settings,
  Webbank,
  CreateWebBank,
  Withdraw,
  EditWebBank,
  News,
  DeleteWebBank,
  WebConfig,
  Affilate,
  Dashboard,
  UserManagement,
  AdminManagement,
  CreateAdmin,
  DeleteAdmin,
  EditAdmin,
  StaffManagement,
  LineManagement,
  CreateStaff,
  DeleteStaff,
  EditStaff,
  CreateUser,
  DeleteUser,
  EditUser,
  LottoList,
  LottoRate,
  Content,
  LottoMasterManagement,
  CreateMaster,
  DeleteMaster,
  EditMaster,
  ReportSummary,
  DailyReport,
  NumberRate,
  LottoCancel,
  ReportBenefit,
  Verify
}