const initialValue: ILottoRateNumber = {
  rate: "",
  number: "",
  threeUpRate: "",
  threeToastRate: "",
  twoUpRate: "",
  twoDownRate: "",
  runUpRate: "",
  runDownRate: "",
};
export default initialValue;
