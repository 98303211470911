import React from 'react'

import './Chipper.component.scss'

function ChipperComponent(props: IChipperProps) {
    const {
        label = '',
        color = 'default',
    } = props

    return (
        <div className={`chipper-container ${color}`}>
            <h6 className="subtitle-2">{label}</h6>
        </div>
    )
}

export default ChipperComponent