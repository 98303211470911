import project from 'constants/project'

export const POST_USER_REQUEST = 'POST_USER_REQUEST'
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS'
export const POST_USER_FAILURE = 'POST_USER_FAILURE'
export const POST_USER_CANCEL = 'POST_USER_CANCEL'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postUser: `${project.environment[project.environmentName].api}/register`,
}