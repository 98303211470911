import { combineEpics } from 'redux-observable'
import loader from './loader/epics'
import auth from './auth/epics'
import user from './user/epics'
import transaction from './transaction/epics'
import betRates from './settings/epics'
import webBank from './webbank/epics'
import onChangeEpic from './menu/epics'
import news from './news/epics'
import webConfig from './webConfig/epics'
import socket from './socket/epics'
import affilate from './affilate/epics'
import config from './config/epics'
import lotto from './lotto/epics'
import check from './check/epics'
import report from './report/epics'
import credit from './credit/epics'
import overview from './overview/epics'
export default combineEpics(
  ...user,
  ...loader,
  ...auth,
  ...transaction,
  ...betRates,
  ...webBank,
  ...onChangeEpic,
  ...news,
  ...webConfig,
  ...socket,
  ...affilate,
  ...config,
  ...lotto,
  ...check,
  ...report,
  ...credit,
  ...overview
)

