import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import newsAction from 'reduxs/news/actions'
import loaderAction from 'reduxs/loader/actions'
import NewsContainer from './News.container'

const mapStateToProps = (state: RootReducers): INewsContainerProps => ({
    getNewsAllIsFetching: state.mantra.news.all.isFetching!,
    getNewsAllError: state.mantra.news.all.error!,
    getNewsAllCode: state.mantra.news.all.code!,
    getNewsAllResult: state.mantra.news.all.data!,
    postNewsCode: state.mantra.news.post.code!,
    postNewsError: state.mantra.news.post.error!,
    postNewsIsFetching: state.mantra.news.post.isFetching!,
    postNewsResult: state.mantra.news.post.data!,
    putNewsCode: state.mantra.news.put.code!,
    putNewsError: state.mantra.news.put.error!,
    putNewsIsFetching: state.mantra.news.put.isFetching!,
    putNewsResult: state.mantra.news.put.data!,
    deleteNewsCode: state.mantra.news.delete.code!,
    deleteNewsError: state.mantra.news.delete.error!,
    deleteNewsIsFetching: state.mantra.news.delete.isFetching!,
    deleteNewsResult: state.mantra.news.delete.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): INewsContainerActionProps => bindActionCreators({
    getNewsAll: newsAction.getNewsAction,
    postNews: newsAction.postNewsAction,
    putNews: newsAction.putNewsAction,
    deleteNews: newsAction.deleteNewsAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(NewsContainer)