import project from 'constants/project'

export const GET_AFFILATE_REQUEST = 'GET_AFFILATE_REQUEST'
export const GET_AFFILATE_SUCCESS = 'GET_AFFILATE_SUCCESS'
export const GET_AFFILATE_FAILURE = 'GET_AFFILATE_FAILURE'
export const GET_AFFILATE_CANCEL = 'GET_AFFILATE_CANCEL'

export const PUT_AFFILATE_REQUEST = 'PUT_AFFILATE_REQUEST'
export const PUT_AFFILATE_SUCCESS = 'PUT_AFFILATE_SUCCESS'
export const PUT_AFFILATE_FAILURE = 'PUT_AFFILATE_FAILURE'
export const PUT_AFFILATE_CANCEL = 'PUT_AFFILATE_CANCEL'

export const initialState: IAffilateAllState = {
    isFetching: false,
    code: 0,
    data: {},
    error: '',
}

export const endpoint = {
    getAffilate: `${project.environment[project.environmentName].api}/affilate/control`,
    putAffilate: `${project.environment[project.environmentName].api}/affilate/control`,
}