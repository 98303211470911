import project from "constants/project";

export const PUT_LOTTO_RATE_NUMBER_REQUEST = "PUT_LOTTO_RATE_NUMBER_REQUEST";
export const PUT_LOTTO_RATE_NUMBER_SUCCESS = "PUT_LOTTO_RATE_NUMBER_SUCCESS";
export const PUT_LOTTO_RATE_NUMBER_FAILURE = "PUT_LOTTO_RATE_NUMBER_FAILURE";
export const PUT_LOTTO_RATE_NUMBER_CANCEL = "PUT_LOTTO_RATE_NUMBER_CANCEL";

export const initialState: ReducerState<object> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
};

export const endpoint = {
  putLottoRateNumber: `${
    project.environment[project.environmentName].api
  }/v1/lotter/rate/reduce `,
};
