import { string, object, ObjectSchema } from 'yup'

const constants = {
  advert: 'กรุณากรอกหัวข้อโฆษณา',
}

const scheme: ObjectSchema<IBroadcastScheme> = object().shape({
  title: string().required(constants.advert),
})

export default scheme