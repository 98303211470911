import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import VerifyContainer from './Verify.container'
import userAction from 'reduxs/user/all/actions'
const mapStateToProps = (state: RootReducers): IVerifyContainerProps => ({
    getDetailUserIsFetching: state.mantra.user.userAll.isFetching!,
    getDetailUserError: state.mantra.user.userAll.error!,
    getDetailUserCode: state.mantra.user.userAll.code!,
    getDetailUserResult: state.mantra.user.userAll.data!,
    userMeResult: state.mantra.user.userMe.data
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IVerifyContainerActionProps => bindActionCreators({
    getUserDetail: userAction.getUserAllAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(VerifyContainer)