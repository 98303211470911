import { createAction } from 'typesafe-actions'
import {
  PUT_USER_REQUEST,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const putUserAction = createAction(
  PUT_USER_REQUEST,
  reslove => (data: IManagementPutData) => reslove(data)
)
const putUserSuccessAction = createAction(
  PUT_USER_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IUser>>) => resolve(data)
)
const putUserFailureAction = createAction(
  PUT_USER_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

export default {
  putUserAction,
  putUserSuccessAction,
  putUserFailureAction,
}