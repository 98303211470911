import { createAction } from 'typesafe-actions'
import {
  GET_USER_ME_REQUEST,
  GET_USER_ME_SUCCESS,
  GET_USER_ME_FAILURE,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getUserMeAction = createAction(
  GET_USER_ME_REQUEST,
)
const getUserMeSuccessAction = createAction(
  GET_USER_ME_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IUserState>>) => resolve(data)
)

const getUserMeFailureAction = createAction(
  GET_USER_ME_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

export default {
  getUserMeAction,
  getUserMeSuccessAction,
  getUserMeFailureAction,
}