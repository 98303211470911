import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  fetchDeleteUser,
} from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const userDeleteEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.deleteUserAction)),
    exhaustMap(action =>
      from(fetchDeleteUser(action.payload))
        .pipe(
          map(actions.deleteUserSuccessAction),
          catchError(error => of(actions.deleteUserFailureAction(error))),
        ),
    )
  )
export default [
  userDeleteEpic,
]
