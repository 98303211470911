import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const newsReducer = (state: INewsAllState = initialState, action: RootAction): INewsAllState => {
    switch (action.type) {
        case getType(actions.getNewsAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getNewsSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getNewsFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const postNewsReducer = (state: INewsAllState = initialState, action: RootAction): INewsAllState => {
    switch (action.type) {
        case getType(actions.postNewsAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.postNewsSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.postNewsFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const putNewsReducer = (state: INewsAllState = initialState, action: RootAction): INewsAllState => {
    switch (action.type) {
        case getType(actions.putNewsAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.putNewsSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.putNewsFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}


const deleteNewsReducer = (state: INewsAllState = initialState, action: RootAction): INewsAllState => {
    switch (action.type) {
        case getType(actions.deleteNewsAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.deleteNewsSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.deleteNewsFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}


export default combineReducers({
    all: newsReducer,
    post: postNewsReducer,
    put: putNewsReducer,
    delete: deleteNewsReducer,
})