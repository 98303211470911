import { Epic } from 'redux-observable'
import {
  exhaustMap,
  filter,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import actions from './actions'
import { of } from 'rxjs'


const onChangeEpic: Epic<RootAction, RootAction, RootReducers> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.onChangeMenuAction)),
    exhaustMap((action) => {
      return of(actions.onChangeMenuShow())
    })
  )

export default [
  onChangeEpic,
]
