import { createAction } from 'typesafe-actions'
import {
  GET_ALL_WEBBANK_REQUEST,
  GET_ALL_WEBBANK_SUCCESS,
  GET_ALL_WEBBANK_FAILURE,
  GET_ALL_WEBBANK_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getAllWebbankAction = createAction(GET_ALL_WEBBANK_REQUEST,
  reslove => (data: IGetWebBankParams, isFirst?: boolean) =>
    reslove({ ...data, isFirst }))

const getAllWebbankSuccessAction = createAction(
  GET_ALL_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IWebBank>>>, isFirst?: boolean) =>
    resolve({ ...data, isFirst }))

const getAllWebbankFailureAction = createAction(
  GET_ALL_WEBBANK_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getAllWebbankCancelAction = createAction(GET_ALL_WEBBANK_CANCEL)

export default {
  getAllWebbankAction,
  getAllWebbankSuccessAction,
  getAllWebbankFailureAction,
  getAllWebbankCancelAction,
}