import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import userCreateAction from 'reduxs/user/create/actions'
import loaderAction from 'reduxs/loader/actions'
import CreateStaff from './CreateStaff'

const mapStateToProps = (state: RootReducers): IUserCreateManagementProps => {
  return {
    postUserIsFetching: state.mantra.user.userCreate.isFetching!,
    postUserError: state.mantra.user.userCreate.error!,
    postUserCode: state.mantra.user.userCreate.code!,
    postUserResult: state.mantra.user.userCreate.data!,
    postUserdevMessage: state.mantra.user.userCreate.devMessage!,
  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ICreateManagementActionProps => bindActionCreators({
  postUser: userCreateAction.postUserAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(CreateStaff)