import project from 'constants/project'
import { transformer } from 'utils'
export const GET_USER_CRIMINAL_VIEW_REQUEST = 'GET_USER_CRIMINAL_VIEW_REQUEST'
export const GET_USER_CRIMINAL_VIEW_SUCCESS = 'GET_USER_CRIMINAL_VIEW_SUCCESS'
export const GET_USER_CRIMINAL_VIEW_FAILURE = 'GET_USER_CRIMINAL_VIEW_FAILURE'

export const initialState: ICriminalViewState  = {
  isFetching: false,
  code: 0,
  data: [],
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
    pageTotal: 0,
  },
  error: '',
}

export const endpoint = {
  getCriminalView: (query?: IQueryCriminalView) => `${project.environment[project.environmentName].api}/user/criminal/view${transformer.urlSearchParams(query)}`,
}
