import project from 'constants/project'

export const PUT_USER_REQUEST = 'PUT_USER_REQUEST'
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS'
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  putUser: `${project.environment[project.environmentName].api}/user`,
}