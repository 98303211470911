import axios, {
  AxiosRequestConfig,
  AxiosTransformer,
  AxiosResponse,
  AxiosError,
} from 'axios'
import { Store } from 'redux'
import { transformer, apiResponse } from 'utils'
import loginAction from 'reduxs/auth/login/actions'
import { Modal } from 'components'
const transformResponse: AxiosTransformer = transformer.camelcaseTransform

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
    transformResponse,
    url: config.url?.replace(/([^:])(\/\/)/g, '$1/'),
  }
}

const responseInterceptor = (response: AxiosResponse<APIResponse>): AxiosResponse<APIResponse> =>  {
  const data = apiResponse.converter(response)
  return { ...response, data }
}
const errorResponseHandler = (getstore: Store<RootReducers>) => (error: AxiosError) => {
  if(error.response?.status === 403) {
    Modal.error.show({
      action: () => {
        Modal.error.hide()
      },
      description: 'โปรดเข้าสู่ระบบอีกครั้งเพื่อต่ออายุเซสชัน',
      actionText: 'เซสชันหมดอายุ',
    })
    getstore.dispatch(loginAction.logoutAction())
 }
  return Promise.reject(error)
}

const errorRequestHandler = (error: any) => Promise.reject(error)

const initService = (config: any, store: Store<RootReducers>) => {
  // Axios globals configuration
  // axios.defaults.baseURL = config.baseURL || ''
  axios.defaults.responseType = 'json'
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.interceptors.request.use(requestInterceptor, errorRequestHandler)
  axios.interceptors.response.use(responseInterceptor, errorResponseHandler(store))
  axios.defaults.timeout = 60000

}

export default initService