import { combineReducers } from 'redux'
import token from './token/reducers'
import userMe from './me/reducers'
import userAll from './all/reducers'
import userCreate from './create/reducers'
import userEdit from './edit/reducers'
import userDelete from './delete/reducers'
import userLine from './line/reducers'
import dateOverviwe from './dateOverviwe/reducers'
import criminalView from './criminal/reducers'
import criminalViewLog from './criminalLog/reducers'
export default combineReducers({
    token,
    userMe,
    userAll,
    userCreate,
    userEdit,
    userDelete,
    userLine,
    dateOverviwe,
    criminalView,
    criminalViewLog
})