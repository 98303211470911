import React, { SFC } from 'react'
import { noop } from 'lodash'
import './confirm.style.scss'
import {
  Grid
} from '@material-ui/core'
// import CancelIcon from '@material-ui/icons/Cancel';
import { Button, ResponsiveIcon } from 'components'
import ErrorIcon from 'assets/images/modal/error/error.png'
import ErrorIcon2x from 'assets/images/modal/error/error@2x.png'
import ErrorIcon3x from 'assets/images/modal/error/error@3x.png'
import AlertIcon from 'assets/images/modal/alert/Alert.png'

const constants = {
  description: 'ยืนยันการทำรายการ',
  accepts: 'ยืนยัน',
  cancel: 'ยกเลิก',
  twoDown: '2 ตัวล่าง',
  threeUp: '3 ตัวบน',
  fourSuit: 'เลขชุด 4 ตัว',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAcceptsModal = {
  description: constants.description,
  action() { noop() },
  cancelAction() { noop() },
  fourSuit: '',
  twoDown: '',
  threeUp: '',
  child:<></>,
  height:'',
  isCancelButton:true,
  typeIcon:'Error'
}

const ConfirmModal: SFC<IAcceptsModal & DefaultProps> = (props: IconfirmModal) => {

  const containerClass = `accepts-modal-container`
  
  return (
    <div className={containerClass} style={{height:props.height}}>
      <div className="description-error-modal">
        <ResponsiveIcon
          alt="error-icon"
          className={props.typeIcon==='Error'?'error-checked-icon':'alert-checked-icon'}
          icon={props.typeIcon==='Error'?{ x1: ErrorIcon, x2: ErrorIcon2x, x3: ErrorIcon3x }:{ x1: AlertIcon, x2: AlertIcon, x3: AlertIcon }}
        />
        <h5 className="subtitle-2 m1-b">{`${props.description}`}</h5>
        {props.child}
        <div className="m1-t">
          <Grid container justify="center" spacing={2}>
            {props.isCancelButton&&<Grid item xs={6}>
              <div className="footer-wrapper-success-modal">
                <Button
                  onClick={props.cancelAction}
                  text={constants.cancel}
                  color="red"
                />
              </div>
            </Grid>}
            <Grid item xs={6}>
              <div className="footer-wrapper-success-modal">
                <Button
                  onClick={props.action}
                  text={constants.accepts}
                  color="green"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

ConfirmModal.defaultProps = defaultProps

export default ConfirmModal