import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchGetTransactionAll } from './services'
import actions from './actions'

const transactionEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getTransactionAllAction)),
    exhaustMap(action =>
      from(fetchGetTransactionAll(action.payload))
        .pipe(
          map(actions.getTransactionAllSuccessAction),
          catchError(error => of(actions.getTransactionAllFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getTransactionAllCancelAction))))
        ),
    )
  )

const updateTransactionAllEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updateTransactionAllAction)),
    map((action) => actions.updateTransactionAllActionSuccessAction(action.payload))
  )

const updateTransactionWithdrawAllEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updateTransactionWithdrawAllAction)),
    map((action) => actions.updateTransactionWithdrawlActionSuccessAction(action.payload))
  )

export default [
  transactionEpic,
  updateTransactionAllEpic,
  updateTransactionWithdrawAllEpic,
]
