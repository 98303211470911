import axios from 'axios'
import { get } from 'lodash'
import { endpoint } from './constants'

export const fetchGetWebBankSummary = () => axios.get(endpoint.webBankSummary)
export const fetchGetBetSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.betSummary}`, {
        params: queryParams,
    })
}
export const fetchGetFinanceSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.financeSummary}`, {
        params: queryParams,
    })
}
export const fetchGetMemberSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.memberSummary}`, {
        params: queryParams,
    })
}

export const fetchGetBetChartSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.betSummaryChart}`, {
        params: queryParams,
    })
}
export const fetchGetFinanceChartSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.financeSummaryChart}`, {
        params: queryParams,
    })
}
export const fetchGetMemberChartSummary = (data: IQueryReportSummary) => {
    const queryParams = new URLSearchParams({
        startDate: String(get(data, 'startDate', '')),
        endDate: String(get(data, 'endDate', '')),
    })
    return axios.get(`${endpoint.memberSummaryChart}`, {
        params: queryParams,
    })
}