export const THEME_MODE = {
  DARK: 'dark-mode',
  LIGHT: 'light-mode',
};

export const LOTTO_SLUG_NAME: { [type in TLottoSlug]: string } = {
  LOTTER_YEGEE: 'หวยยี่กี',
  LOTTER_GOVN: 'หวยรัฐบาล',
  LOTTER_BAAC: 'หวยธกส',
  LOTTER_GSB: 'หวยออมสิน',
  LOTTER_NAT_SHARE_LAO: 'หวยลาว',
  LOTTER_LAO_SUITE: 'หวยลาว (เลขชุด)',
  LOTTER_TH_SHARE_MORNING: 'หุ้นไทยเช้า',
  LOTTER_TH_SHARE_MIDDAY: 'หุ้นไทยเที่ยง',
  LOTTER_TH_SHARE_AFTERNOON: 'หุ้นไทยบ่าย',
  LOTTER_TH_SHARE_EVENING: 'หุ้นไทยเย็น',
  LOTTER_NAT_SHARE_DOWNJON: 'หุ้นดาวโจนส์',
  LOTTER_NAT_SHARE_EGYPT: 'หุ้นต่างประเทศอียิปต์',
  LOTTER_NAT_SHARE_GERMANY: 'หุ้นต่างประเทศเยอรมัน',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'หุ้นนิเคอิรอบเช้า',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'หุ้นนิเคอิรอบบ่าย',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'หุ้นจีนรอบเช้า',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'หุ้นจีนรอบบ่าย',
  LOTTER_NAT_SHARE_TAIWAN: 'หุ้นไต้หวัน',
  LOTTER_NAT_SHARE_KOREA: 'หุ้นเกาหลี',
  LOTTER_NAT_SHARE_SINGAPORE: 'หุ้นสิงคโปร์',
  LOTTER_NAT_SHARE_INDIA: 'หุ้นอินเดีย',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'หุ้นฮานอยพิเศษ',
  LOTTER_NAT_SHARE_MALAY: 'หุ้นมาเลย์',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'หุ้นฮานอย',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'หุ้นฮานอย VIP',
  LOTTER_NAT_SHARE_HANOI_4D: 'หุ้นฮานอย (4D)',
  LOTTER_NAT_SHARE_RUSSIA: 'หุ้นรัสเซีย',
  LOTTER_NAT_SHARE_ENGLISH: 'หุ้นอังกฤษ',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'หุ้นฮั่งเส็งรอบเช้า',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'หุ้นฮั่งเส็งรอบบ่าย',
  LOTTER_LAO_HD: 'หวยลาว HD',
  LOTTER_HANOI_TV: 'หวยฮานอย TV',
  LOTTER_LAO_STAR: 'หวยลาวสตาร์',
  LOTTER_HANOI_RED_CROSS: 'หวยฮานอย กาชาด',
  LOTTER_HANOI_SAMAKKEE: 'หวยฮานอยสามัคคี',
  LOTTER_HANOI_PHATTANA: 'หวยฮานอยพัฒนา',
  LOTTER_LAO_SAMAKKEE: 'หวยลาวสามัคคี',
  LOTTER_LAO_ASEAN: 'หวยลาวอาเซียน',
  LOTTER_LAO_STAR_VIP: 'หวยลาวสตาร์ VIP',
  LOTTER_ENGLISH_VIP: 'หวยอังกฤษ VIP',
  LOTTER_GERMANY_VIP: 'หวยเยอรมัน VIP',
  LOTTER_LAO_RED_CROSS: 'หวยลาว กาชาด',
  LOTTER_RUSSIA_VIP: 'หวยรัสเซีย VIP',
  LOTTER_DOWNJON_VIP: 'หวยดาวโจนส์ VIP',
  LOTTER_HANOI_ASEAN: 'หวยฮานอยอาเซียน',
  LOTTER_LAO_TV: 'หวยลาว TV',
  LOTTER_HANOI_HD: 'หวยฮานอย HD',
  LOTTER_HANOI_STAR: 'หวยฮานอยสตาร์',
  LOTTER_MALAY: 'หวยมาเลเซีย',
  LOTTER_HANOI_EXTRA: 'หวยฮานอยเอ็กซ์ตร้า',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: 'หุ้นฮั่งเส็งรอบเช้า VIP',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'หุ้นฮั่งเส็งรอบบ่าย VIP',
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: 'หุ้นจีนรอบเช้า VIP',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: 'หุ้นจีนรอบบ่าย VIP',
  LOTTER_NAT_SHARE_SINGAPORE_VIP: 'หุ้นสิงคโปร์ VIP',
  LOTTER_NAT_SHARE_KOREA_VIP: 'หุ้นเกาหลี VIP',
  LOTTER_NAT_SHARE_TAIWAN_VIP: 'หุ้นไต้หวัน VIP',
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: 'หุ้นนิเคอิรอบเช้า VIP',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: 'หุ้นนิเคอิรอบบ่าย VIP',
  LOTTER_LAO_VIP: 'หวยลาว VIP',
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์'
};

export const LOTTO_SESSION_CODE: { [type in TLottoSessionCode]: string } = {
  LOTTER_GOVN: 'หวยรัฐบาล',
  LOTTER_BAAC: 'หวยธกส',
  LOTTER_GSB: 'หวยออมสิน',
  LOTTER_NAT_SHARE_LAO: 'หวยลาว',
  LOTTER_LAO_SUITE: 'หวยลาว (เลขชุด)',
  LOTTER_TH_SHARE_MORNING: 'หุ้นไทยเช้า',
  LOTTER_TH_SHARE_MIDDAY: 'หุ้นไทยเที่ยง',
  LOTTER_TH_SHARE_AFTERNOON: 'หุ้นไทยบ่าย',
  LOTTER_TH_SHARE_EVENING: 'หุ้นไทยเย็น',
  LOTTER_NAT_SHARE_DOWNJON: 'หุ้นดาวโจนส์',
  LOTTER_NAT_SHARE_EGYPT: 'หุ้นต่างประเทศอียิปต์',
  LOTTER_NAT_SHARE_GERMANY: 'หุ้นต่างประเทศเยอรมัน',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'หุ้นนิเคอิรอบเช้า',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'หุ้นนิเคอิรอบบ่าย',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'หุ้นจีนรอบเช้า',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'หุ้นจีนรอบบ่าย',
  LOTTER_NAT_SHARE_TAIWAN: 'หุ้นไต้หวัน',
  LOTTER_NAT_SHARE_KOREA: 'หุ้นเกาหลี',
  LOTTER_NAT_SHARE_SINGAPORE: 'หุ้นสิงคโปร์',
  LOTTER_NAT_SHARE_INDIA: 'หุ้นอินเดีย',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'หุ้นฮานอยพิเศษ',
  LOTTER_NAT_SHARE_MALAY: 'หุ้นมาเลย์',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'หุ้นฮานอย',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'หุ้นฮานอย VIP',
  LOTTER_NAT_SHARE_HANOI_4D: 'หุ้นฮานอย (4D)',
  LOTTER_NAT_SHARE_RUSSIA: 'หุ้นรัสเซีย',
  LOTTER_NAT_SHARE_ENGLISH: 'หุ้นอังกฤษ',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'หุ้นฮั่งเส็งรอบเช้า',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'หุ้นฮั่งเส็งรอบบ่าย',
};

export const LOTTO_FLAG_ALPHA: { [type in TLottoType]: TFlag } = {
  GOVN: 'THA',
  BAAC: 'BAAC',
  GSB: 'GSB',
  LAO: 'LAO',
  TH_SHARE_MORNING: 'THA',
  TH_SHARE_MIDDAY: 'THA',
  TH_SHARE_AFTERNOON: 'THA',
  TH_SHARE_EVENING: 'THA',
  NAT_SHARE_DOWNJON: 'USA',
  NAT_SHARE_EGYPT: 'EGY',
  NAT_SHARE_GERMANY: 'DEU',
  NAT_SHARE_NIKAII_MORNING: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON: 'JPN',
  NAT_SHARE_CHINA_MORNING: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON: 'CHN',
  NAT_SHARE_TAIWAN: 'TWN',
  NAT_SHARE_KOREA: 'KOR',
  NAT_SHARE_SINGAPORE: 'SGP',
  NAT_SHARE_INDIA: 'IND',
  NAT_SHARE_HANOI_SPECIAL: 'VNM',
  NAT_SHARE_MALAY: 'MYS',
  NAT_SHARE_VIETNAM_HANOI: 'VNM',
  NAT_SHARE_VIETNAM_HANOI_VIP: 'VNM',
  NAT_SHARE_HANOI_4D: 'VNM',
  NAT_SHARE_RUSSIA: 'RUS',
  NAT_SHARE_ENGLISH: 'GBR',
  NAT_SHARE_HUNGSENG_MORNING: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON: 'HKG',
  NAT_SHARE_LAO: 'LAO',
  LAO_SUITE: 'LAO',
  YEGEE: 'THA',
  LAO_HD: 'LAO',
  HANOI_TV: 'VNM',
  LAO_STAR: 'LAO',
  HANOI_RED_CROSS: 'VNM',
  HANOI_SAMAKKEE: 'VNM',
  HANOI_PHATTANA: 'VNM',
  LAO_SAMAKKEE: 'LAO',
  LAO_ASEAN: 'LAO',
  LAO_STAR_VIP: 'LAO',
  ENGLISH_VIP: 'GBR',
  GERMANY_VIP: 'DEU',
  LAO_RED_CROSS: 'LAO',
  RUSSIA_VIP: 'RUS',
  DOWNJON_VIP: 'USA',
  HANOI_ASEAN: 'VNM',
  LAO_TV: 'LAO',
  HANOI_HD: 'VNM',
  HANOI_STAR: 'VNM',
  HANOI_EXTRA: 'VNM',
  MALAY: 'MYS',
  NAT_SHARE_HUNGSENG_MORNING_VIP: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'HKG',
  NAT_SHARE_CHINA_MORNING_VIP: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON_VIP: 'CHN',
  NAT_SHARE_SINGAPORE_VIP: 'SGP',
  NAT_SHARE_KOREA_VIP: 'KOR',
  NAT_SHARE_TAIWAN_VIP: 'TWN',
  NAT_SHARE_NIKAII_MORNING_VIP: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON_VIP: 'JPN',
  LAO_VIP: 'LAO',
  HANOI_CHINESE_NEW_YEAR: 'VNM',
  NAT_SHARE_DOWNJON_STAR: 'USA',
};

export const LOTTO_SLUG_TO_TYPE: { [type in TLottoSlug]: TLottoType } = {
  LOTTER_YEGEE: 'YEGEE',
  LOTTER_GOVN: 'GOVN',
  LOTTER_BAAC: 'BAAC',
  LOTTER_GSB: 'GSB',
  LOTTER_NAT_SHARE_LAO: 'NAT_SHARE_LAO',
  LOTTER_LAO_SUITE: 'LAO_SUITE',
  LOTTER_TH_SHARE_MORNING: 'TH_SHARE_MORNING',
  LOTTER_TH_SHARE_MIDDAY: 'TH_SHARE_MIDDAY',
  LOTTER_TH_SHARE_AFTERNOON: 'TH_SHARE_AFTERNOON',
  LOTTER_TH_SHARE_EVENING: 'TH_SHARE_EVENING',
  LOTTER_NAT_SHARE_DOWNJON: 'NAT_SHARE_DOWNJON',
  LOTTER_NAT_SHARE_EGYPT: 'NAT_SHARE_EGYPT',
  LOTTER_NAT_SHARE_GERMANY: 'NAT_SHARE_GERMANY',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'NAT_SHARE_NIKAII_MORNING',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'NAT_SHARE_NIKAII_AFTERNOON',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'NAT_SHARE_CHINA_MORNING',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'NAT_SHARE_CHINA_AFTERNOON',
  LOTTER_NAT_SHARE_TAIWAN: 'NAT_SHARE_TAIWAN',
  LOTTER_NAT_SHARE_KOREA: 'NAT_SHARE_KOREA',
  LOTTER_NAT_SHARE_SINGAPORE: 'NAT_SHARE_SINGAPORE',
  LOTTER_NAT_SHARE_INDIA: 'NAT_SHARE_INDIA',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'NAT_SHARE_HANOI_SPECIAL',
  LOTTER_NAT_SHARE_MALAY: 'NAT_SHARE_MALAY',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'NAT_SHARE_VIETNAM_HANOI',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'NAT_SHARE_VIETNAM_HANOI_VIP',
  LOTTER_NAT_SHARE_HANOI_4D: 'NAT_SHARE_HANOI_4D',
  LOTTER_NAT_SHARE_RUSSIA: 'NAT_SHARE_RUSSIA',
  LOTTER_NAT_SHARE_ENGLISH: 'NAT_SHARE_ENGLISH',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'NAT_SHARE_HUNGSENG_MORNING',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'NAT_SHARE_HUNGSENG_AFTERNOON',
  LOTTER_LAO_HD: 'LAO_HD',
  LOTTER_HANOI_TV: 'HANOI_TV',
  LOTTER_LAO_STAR: 'LAO_STAR',
  LOTTER_HANOI_RED_CROSS: 'HANOI_RED_CROSS',
  LOTTER_HANOI_SAMAKKEE: 'HANOI_SAMAKKEE',
  LOTTER_HANOI_PHATTANA: 'HANOI_PHATTANA',
  LOTTER_LAO_SAMAKKEE: 'LAO_SAMAKKEE',
  LOTTER_LAO_ASEAN: 'LAO_ASEAN',
  LOTTER_LAO_STAR_VIP: 'LAO_STAR_VIP',
  LOTTER_ENGLISH_VIP: 'ENGLISH_VIP',
  LOTTER_GERMANY_VIP: 'GERMANY_VIP',
  LOTTER_LAO_RED_CROSS: 'LAO_RED_CROSS',
  LOTTER_RUSSIA_VIP: 'RUSSIA_VIP',
  LOTTER_DOWNJON_VIP: 'DOWNJON_VIP',
  LOTTER_HANOI_ASEAN: 'HANOI_ASEAN',
  LOTTER_LAO_TV: 'LAO_TV',
  LOTTER_HANOI_HD: 'HANOI_HD',
  LOTTER_HANOI_STAR: 'HANOI_STAR',
  LOTTER_MALAY: 'MALAY',
  LOTTER_HANOI_EXTRA: 'HANOI_EXTRA',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: 'NAT_SHARE_HUNGSENG_MORNING_VIP',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'NAT_SHARE_HUNGSENG_AFTERNOON_VIP',
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: 'NAT_SHARE_CHINA_MORNING_VIP',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: 'NAT_SHARE_CHINA_AFTERNOON_VIP',
  LOTTER_NAT_SHARE_SINGAPORE_VIP: 'NAT_SHARE_SINGAPORE_VIP',
  LOTTER_NAT_SHARE_KOREA_VIP: 'NAT_SHARE_KOREA_VIP',
  LOTTER_NAT_SHARE_TAIWAN_VIP: 'NAT_SHARE_TAIWAN_VIP',
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: 'NAT_SHARE_NIKAII_MORNING_VIP',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: 'NAT_SHARE_NIKAII_AFTERNOON_VIP',
  LOTTER_LAO_VIP: 'LAO_VIP',
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'HANOI_CHINESE_NEW_YEAR',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'NAT_SHARE_DOWNJON_STAR'
};

export const LOTTO_GAME_TYPE_NAME: { [type in TLottoGameType]: string } = {
  TWO_UP: 'สองตัวบน',
  TWO_DOWN: 'สองตัวล่าง',
  THREE_UP: 'สามตัวบน',
  THREE_FRONT: 'สามตัวหน้า',
  THREE_BACK: 'สามตัวหลัง',
  THREE_TOAST: 'สามตัวโต๊ด',
  FOUR_SUITE: 'เลขชุดสี่ตัว',
  RUN_UP: 'วิ่งบน',
  RUN_DOWN: 'วิ่งล่าง',
  ONE_AWARD: 'รางวัลที่ 1',
  YEGEE_PLAY_AWARD: 'รางวัลยิงเลขลำดับที่',
};

export const LOTTO_SESSION_TYPE: { [type in TLottoSessionType]: string } = {
  TWO_UP: 'สองตัวบน',
  TWO_DOWN: 'สองตัวล่าง',
  THREE_UP: 'สามตัวบน',
  THREE_FRONT: 'สามตัวหน้า',
  THREE_BACK: 'สามตัวหลัง',
  THREE_TOAST: 'สามตัวโต๊ด',
  FOUR_SUITE: 'เลขชุดสี่ตัว',
  RUN_UP: 'วิ่งบน',
  RUN_DOWN: 'วิ่งล่าง',
  ONE_AWARD: 'รางวัลที่ 1',
};

export const TRANSACTION_TYPE: { [type in TTransactionType]: string } = {
  DEPOSIT: 'ฝากเครดิต',
  WITHDRAW: 'ถอนเครดิต',
  DEPOSIT_GAME_FISHING: 'ฝากเครดิตจากเกมยิงปลา',
  WITHDRAW_GAME_FISHING: 'ถอนเครดิตเข้าเกมยิงปลา',
  BET_GAME_CASINO: 'เกมคาสิโน',
  AFFILATE_WITHDRAW: 'ย้ายเงินจากกระเป๋าระบบแนะนำสมาชิก',
  SYSTEM_INCOME: 'รายได้ระบบ',
  SYSTEM_MONEY_LOSS: 'จ่ายผู้เล่น',
  DEPOSIT_SYSTEM_INCOME: 'ลูกค้าฝากเข้าระบบ',
  WITHDRAW_SYSTEM_INCOME: 'ลูกค้าแจ้งถอนเงินออกจากระบบ',
  WITHDRAW_SYSTEM_MONEY_LOSS: 'ลูกค้าถอนเงินออกจากระบบ',
  DEPOSIT_W88_P2P: 'ฝากเครดิตจากเกม Casino W88 ผู้เล่นปะทะผู้เล่น',
  WITHDRAW_W88_P2P: 'ถอนเครดิตเข้าเกม Casino W88 ผู้เล่นปะทะผู้เล่น',
};

export const GAME_TYPE_NAME: { [type in TGameType]: string } = {
  BINGO: 'เล่นบิงโก',
  ECASINO: 'พนันคาสิโน',
  FISHING: 'ยิงปลา',
  SLOT: 'หมุนสล๊อต',
  CASINO: 'คาสิโนออนไลน์',
};

export const LOTTO_CANCEL_TYPE_NAME: { [type in TLottoTypeCancel]: string } = {
  TWO_UP: '2 ตัวบน',
  TWO_DOWN: '2 ตัวล่าง',
  THREE_UP: '3 ตัวบน',
  THREE_TOAST: '3 ตัวโต๊ด',
  FOUR_SUITE: 'เลขชุดสี่ตัว',
  RUN_UP: 'วิ่งบน',
  RUN_DOWN: 'วิ่งล่าง',
  '': '',
};

export const SLUG_EXTERNAL_CASINO: { [type in TSlugExternalCasino]: string } = {
  ALL: 'รวม',
  AESEXY: 'AE Sexy',
  SAGAME: 'SA Gaming',
  ALLBET: 'Allbet',
  DG: 'Dream Gaming',
  W88: 'W88',
  WMGAME: 'WM Casino',
  EVOLUTION: 'EVOLUTION'
};

export const SLUG_EXTERNAL_SLOT: { [type in TSlugExternalSlot]: string } = {
  ALL: 'รวม',
  JOKER: 'Joker',
  DT: 'Dream Tech',
  PGGAME: 'PG',
  SLOT_XO: 'Slot XO',
  W88: 'W88',
  JILIGAME: 'JILIGAME',
  PPGAME: 'PPGAME',

};

export const SLUG_LOTTO_NAME: { [type in TSlugLotto]: string } = {
  ALL: 'รวม',
  GOVN: 'หวยรัฐบาล',
  BAAC: 'หวยธกส',
  GSB: 'หวยออมสิน',
  LAO_SUITE: 'หวยลาว ( เลขชุด )',
  LAO_HD: 'หวยลาว HD',
  LAO_STAR: 'หวยลาว สตาร์',
  LAO_SAMAKKEE: 'หวยลาว สามัคคี',
  LAO_ASEAN: 'หวยลาว อาเซียน',
  LAO_STAR_VIP: 'หวยลาว สตาร์ VIP',
  LAO_RED_CROSS: 'หวยลาว กาชาด',
  LAO_TV: 'หวยลาว TV',
  LAO_VIP: 'หวยลาว VIP',
  NAT_SHARE_LAO: 'หวยลาว',
  TH_SHARE_MORNING: 'หุ้นไทยเช้า',
  TH_SHARE_MIDDAY: 'หุ้นไทยเที่ยง',
  TH_SHARE_AFTERNOON: 'หุ้นไทยบ่าย',
  TH_SHARE_EVENING: 'หุ้นไทยเย็น',
  NAT_SHARE_DOWNJON: 'หุ้นดาวน์โจน',
  DOWNJON_VIP: 'หุ้นดาวน์โจน VIP',
  NAT_SHARE_EGYPT: 'หุ้นต่างประเทศอียิปต์',
  NAT_SHARE_GERMANY: 'หุ้นต่างประเทศเยอรมัน',
  GERMANY_VIP: 'หุ้นต่างประเทศเยอรมัน VIP',
  MALAY: 'หวยมาเลเซีย',
  NAT_SHARE_NIKAII_MORNING: 'หุ้นนิเคอิรอบเช้า',
  NAT_SHARE_NIKAII_MORNING_VIP: 'หุ้นนิเคอิรอบเช้า VIP',
  NAT_SHARE_NIKAII_AFTERNOON: 'หุ้นนิเคอิรอบบ่าย',
  NAT_SHARE_NIKAII_AFTERNOON_VIP: 'หุ้นนิเคอิรอบบ่าย VIP',
  NAT_SHARE_CHINA_MORNING: 'หุ้นจีนรอบเช้า',
  NAT_SHARE_CHINA_MORNING_VIP: 'หุ้นจีนรอบเช้า VIP',
  NAT_SHARE_CHINA_AFTERNOON: 'หุ้นจีนรอบบ่าย',
  NAT_SHARE_CHINA_AFTERNOON_VIP: 'หุ้นจีนรอบบ่าย VIP',
  NAT_SHARE_TAIWAN: 'หุ้นไต้หวัน',
  NAT_SHARE_TAIWAN_VIP: 'หุ้นไต้หวัน VIP',
  NAT_SHARE_KOREA: 'หุ้นเกาหลี',
  NAT_SHARE_KOREA_VIP: 'หุ้นเกาหลี VIP',
  NAT_SHARE_SINGAPORE: 'หุ้นสิงค์โปร',
  NAT_SHARE_SINGAPORE_VIP: 'หุ้นสิงค์โปร VIP',
  NAT_SHARE_INDIA: 'หวยอินเดีย',
  NAT_SHARE_HANOI_SPECIAL: 'หวยฮานอยพิเศษ',
  NAT_SHARE_MALAY: 'หวยมาเลย์',
  NAT_SHARE_VIETNAM_HANOI: 'หุ้นฮานอย',
  NAT_SHARE_VIETNAM_HANOI_VIP: 'หุ้นฮานอย VIP',
  HANOI_RED_CROSS: 'หวยฮานอย กาชาด',
  HANOI_SAMAKKEE: 'หวยฮานอย สามัคคี',
  HANOI_PHATTANA: 'หวยฮานอย พัฒนา',
  HANOI_ASEAN: 'หวยฮานอย อาเซียน',
  HANOI_HD: 'หวยฮานอย HD',
  HANOI_STAR: 'หวยฮานอย สตาร์',
  HANOI_EXTRA: 'หวยฮานอย เอกสตาร์',
  HANOI_TV: 'หวยฮานอย TV',
  NAT_SHARE_HANOI_4D: 'หุ้นฮานอย (4D)',
  NAT_SHARE_RUSSIA: 'หุ้นรัสเซีย',
  RUSSIA_VIP: 'หุ้นรัสเซีย VIP',
  NAT_SHARE_ENGLISH: 'หุ้นอังกฤษ',
  ENGLISH_VIP: 'หุ้นอังกฤษ VIP',
  NAT_SHARE_HUNGSENG_MORNING: 'หุ้นฮั่งเส็งรอบเช้า',
  NAT_SHARE_HUNGSENG_MORNING_VIP: 'หุ้นฮั่งเส็งรอบเช้า VIP',
  NAT_SHARE_HUNGSENG_AFTERNOON: 'หุ้นฮั่งเส็งรอบบ่าย',
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'หุ้นฮั่งเส็งรอบบ่าย VIP',
  HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์',
  YEGEE_001: 'หวยยี่กีรอบที่ 1',
  YEGEE_002: 'หวยยี่กีรอบที่ 2',
  YEGEE_003: 'หวยยี่กีรอบที่ 3',
  YEGEE_004: 'หวยยี่กีรอบที่ 4',
  YEGEE_005: 'หวยยี่กีรอบที่ 5',
  YEGEE_006: 'หวยยี่กีรอบที่ 6',
  YEGEE_007: 'หวยยี่กีรอบที่ 7',
  YEGEE_008: 'หวยยี่กีรอบที่ 8',
  YEGEE_009: 'หวยยี่กีรอบที่ 9',
  YEGEE_010: 'หวยยี่กีรอบที่ 10',
  YEGEE_011: 'หวยยี่กีรอบที่ 11',
  YEGEE_012: 'หวยยี่กีรอบที่ 12',
  YEGEE_013: 'หวยยี่กีรอบที่ 13',
  YEGEE_014: 'หวยยี่กีรอบที่ 14',
  YEGEE_015: 'หวยยี่กีรอบที่ 15',
  YEGEE_016: 'หวยยี่กีรอบที่ 16',
  YEGEE_017: 'หวยยี่กีรอบที่ 17',
  YEGEE_018: 'หวยยี่กีรอบที่ 18',
  YEGEE_019: 'หวยยี่กีรอบที่ 19',
  YEGEE_020: 'หวยยี่กีรอบที่ 20',
  YEGEE_021: 'หวยยี่กีรอบที่ 21',
  YEGEE_022: 'หวยยี่กีรอบที่ 22',
  YEGEE_023: 'หวยยี่กีรอบที่ 23',
  YEGEE_024: 'หวยยี่กีรอบที่ 24',
  YEGEE_025: 'หวยยี่กีรอบที่ 25',
  YEGEE_026: 'หวยยี่กีรอบที่ 26',
  YEGEE_027: 'หวยยี่กีรอบที่ 27',
  YEGEE_028: 'หวยยี่กีรอบที่ 28',
  YEGEE_029: 'หวยยี่กีรอบที่ 29',
  YEGEE_030: 'หวยยี่กีรอบที่ 30',
  YEGEE_031: 'หวยยี่กีรอบที่ 31',
  YEGEE_032: 'หวยยี่กีรอบที่ 32',
  YEGEE_033: 'หวยยี่กีรอบที่ 33',
  YEGEE_034: 'หวยยี่กีรอบที่ 34',
  YEGEE_035: 'หวยยี่กีรอบที่ 35',
  YEGEE_036: 'หวยยี่กีรอบที่ 36',
  YEGEE_037: 'หวยยี่กีรอบที่ 37',
  YEGEE_038: 'หวยยี่กีรอบที่ 38',
  YEGEE_039: 'หวยยี่กีรอบที่ 39',
  YEGEE_040: 'หวยยี่กีรอบที่ 40',
  YEGEE_041: 'หวยยี่กีรอบที่ 41',
  YEGEE_042: 'หวยยี่กีรอบที่ 42',
  YEGEE_043: 'หวยยี่กีรอบที่ 43',
  YEGEE_044: 'หวยยี่กีรอบที่ 44',
  YEGEE_045: 'หวยยี่กีรอบที่ 45',
  YEGEE_046: 'หวยยี่กีรอบที่ 46',
  YEGEE_047: 'หวยยี่กีรอบที่ 47',
  YEGEE_048: 'หวยยี่กีรอบที่ 48',
  YEGEE_049: 'หวยยี่กีรอบที่ 49',
  YEGEE_050: 'หวยยี่กีรอบที่ 50',
  YEGEE_051: 'หวยยี่กีรอบที่ 51',
  YEGEE_052: 'หวยยี่กีรอบที่ 52',
  YEGEE_053: 'หวยยี่กีรอบที่ 53',
  YEGEE_054: 'หวยยี่กีรอบที่ 54',
  YEGEE_055: 'หวยยี่กีรอบที่ 55',
  YEGEE_056: 'หวยยี่กีรอบที่ 56',
  YEGEE_057: 'หวยยี่กีรอบที่ 57',
  YEGEE_058: 'หวยยี่กีรอบที่ 58',
  YEGEE_059: 'หวยยี่กีรอบที่ 59',
  YEGEE_060: 'หวยยี่กีรอบที่ 60',
  YEGEE_061: 'หวยยี่กีรอบที่ 61',
  YEGEE_062: 'หวยยี่กีรอบที่ 62',
  YEGEE_063: 'หวยยี่กีรอบที่ 63',
  YEGEE_064: 'หวยยี่กีรอบที่ 64',
  YEGEE_065: 'หวยยี่กีรอบที่ 65',
  YEGEE_066: 'หวยยี่กีรอบที่ 66',
  YEGEE_067: 'หวยยี่กีรอบที่ 67',
  YEGEE_068: 'หวยยี่กีรอบที่ 68',
  YEGEE_069: 'หวยยี่กีรอบที่ 69',
  YEGEE_070: 'หวยยี่กีรอบที่ 70',
  YEGEE_071: 'หวยยี่กีรอบที่ 71',
  YEGEE_072: 'หวยยี่กีรอบที่ 72',
  YEGEE_073: 'หวยยี่กีรอบที่ 73',
  YEGEE_074: 'หวยยี่กีรอบที่ 74',
  YEGEE_075: 'หวยยี่กีรอบที่ 75',
  YEGEE_076: 'หวยยี่กีรอบที่ 76',
  YEGEE_077: 'หวยยี่กีรอบที่ 77',
  YEGEE_078: 'หวยยี่กีรอบที่ 78',
  YEGEE_079: 'หวยยี่กีรอบที่ 79',
  YEGEE_080: 'หวยยี่กีรอบที่ 80',
  YEGEE_081: 'หวยยี่กีรอบที่ 81',
  YEGEE_082: 'หวยยี่กีรอบที่ 82',
  YEGEE_083: 'หวยยี่กีรอบที่ 83',
  YEGEE_084: 'หวยยี่กีรอบที่ 84',
  YEGEE_085: 'หวยยี่กีรอบที่ 85',
  YEGEE_086: 'หวยยี่กีรอบที่ 86',
  YEGEE_087: 'หวยยี่กีรอบที่ 87',
  YEGEE_088: 'หวยยี่กีรอบที่ 88',
};

export const LOTTO_FLAG_REPORT_BENEFIT: { [type in TSlugLotto]: TFlag } = {
  ALL: 'ALL',
  GOVN: 'THA',
  BAAC: 'BAAC',
  GSB: 'GSB',
  TH_SHARE_MORNING: 'THA',
  TH_SHARE_MIDDAY: 'THA',
  TH_SHARE_AFTERNOON: 'THA',
  TH_SHARE_EVENING: 'THA',
  NAT_SHARE_DOWNJON: 'USA',
  NAT_SHARE_EGYPT: 'EGY',
  NAT_SHARE_GERMANY: 'DEU',
  NAT_SHARE_NIKAII_MORNING: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON: 'JPN',
  NAT_SHARE_CHINA_MORNING: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON: 'CHN',
  NAT_SHARE_TAIWAN: 'TWN',
  NAT_SHARE_KOREA: 'KOR',
  NAT_SHARE_SINGAPORE: 'SGP',
  NAT_SHARE_INDIA: 'IND',
  NAT_SHARE_HANOI_SPECIAL: 'VNM',
  NAT_SHARE_MALAY: 'MYS',
  NAT_SHARE_VIETNAM_HANOI: 'VNM',
  NAT_SHARE_VIETNAM_HANOI_VIP: 'VNM',
  NAT_SHARE_HANOI_4D: 'VNM',
  NAT_SHARE_RUSSIA: 'RUS',
  NAT_SHARE_ENGLISH: 'GBR',
  NAT_SHARE_HUNGSENG_MORNING: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON: 'HKG',
  NAT_SHARE_LAO: 'LAO',
  LAO_SUITE: 'LAO',
  LAO_HD: 'LAO',
  HANOI_TV: 'VNM',
  LAO_STAR: 'LAO',
  HANOI_RED_CROSS: 'VNM',
  HANOI_SAMAKKEE: 'VNM',
  HANOI_PHATTANA: 'VNM',
  LAO_SAMAKKEE: 'LAO',
  LAO_ASEAN: 'LAO',
  LAO_VIP: 'LAO',
  LAO_STAR_VIP: 'LAO',
  ENGLISH_VIP: 'GBR',
  GERMANY_VIP: 'DEU',
  LAO_RED_CROSS: 'LAO',
  RUSSIA_VIP: 'RUS',
  DOWNJON_VIP: 'USA',
  HANOI_ASEAN: 'VNM',
  LAO_TV: 'LAO',
  HANOI_HD: 'VNM',
  HANOI_STAR: 'VNM',
  HANOI_EXTRA: 'VNM',
  MALAY: 'MYS',
  NAT_SHARE_HUNGSENG_MORNING_VIP: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'HKG',
  NAT_SHARE_CHINA_MORNING_VIP: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON_VIP: 'CHN',
  NAT_SHARE_SINGAPORE_VIP: 'SGP',
  NAT_SHARE_KOREA_VIP: 'KOR',
  NAT_SHARE_TAIWAN_VIP: 'TWN',
  NAT_SHARE_NIKAII_MORNING_VIP: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON_VIP: 'JPN',
  HANOI_CHINESE_NEW_YEAR: 'VNM',
  NAT_SHARE_DOWNJON_STAR: 'USA',
  YEGEE_001: 'THA',
  YEGEE_002: 'THA',
  YEGEE_003: 'THA',
  YEGEE_004: 'THA',
  YEGEE_005: 'THA',
  YEGEE_006: 'THA',
  YEGEE_007: 'THA',
  YEGEE_008: 'THA',
  YEGEE_009: 'THA',
  YEGEE_010: 'THA',
  YEGEE_011: 'THA',
  YEGEE_012: 'THA',
  YEGEE_013: 'THA',
  YEGEE_014: 'THA',
  YEGEE_015: 'THA',
  YEGEE_016: 'THA',
  YEGEE_017: 'THA',
  YEGEE_018: 'THA',
  YEGEE_019: 'THA',
  YEGEE_020: 'THA',
  YEGEE_021: 'THA',
  YEGEE_022: 'THA',
  YEGEE_023: 'THA',
  YEGEE_024: 'THA',
  YEGEE_025: 'THA',
  YEGEE_026: 'THA',
  YEGEE_027: 'THA',
  YEGEE_028: 'THA',
  YEGEE_029: 'THA',
  YEGEE_030: 'THA',
  YEGEE_031: 'THA',
  YEGEE_032: 'THA',
  YEGEE_033: 'THA',
  YEGEE_034: 'THA',
  YEGEE_035: 'THA',
  YEGEE_036: 'THA',
  YEGEE_037: 'THA',
  YEGEE_038: 'THA',
  YEGEE_039: 'THA',
  YEGEE_040: 'THA',
  YEGEE_041: 'THA',
  YEGEE_042: 'THA',
  YEGEE_043: 'THA',
  YEGEE_044: 'THA',
  YEGEE_045: 'THA',
  YEGEE_046: 'THA',
  YEGEE_047: 'THA',
  YEGEE_048: 'THA',
  YEGEE_049: 'THA',
  YEGEE_050: 'THA',
  YEGEE_051: 'THA',
  YEGEE_052: 'THA',
  YEGEE_053: 'THA',
  YEGEE_054: 'THA',
  YEGEE_055: 'THA',
  YEGEE_056: 'THA',
  YEGEE_057: 'THA',
  YEGEE_058: 'THA',
  YEGEE_059: 'THA',
  YEGEE_060: 'THA',
  YEGEE_061: 'THA',
  YEGEE_062: 'THA',
  YEGEE_063: 'THA',
  YEGEE_064: 'THA',
  YEGEE_065: 'THA',
  YEGEE_066: 'THA',
  YEGEE_067: 'THA',
  YEGEE_068: 'THA',
  YEGEE_069: 'THA',
  YEGEE_070: 'THA',
  YEGEE_071: 'THA',
  YEGEE_072: 'THA',
  YEGEE_073: 'THA',
  YEGEE_074: 'THA',
  YEGEE_075: 'THA',
  YEGEE_076: 'THA',
  YEGEE_077: 'THA',
  YEGEE_078: 'THA',
  YEGEE_079: 'THA',
  YEGEE_080: 'THA',
  YEGEE_081: 'THA',
  YEGEE_082: 'THA',
  YEGEE_083: 'THA',
  YEGEE_084: 'THA',
  YEGEE_085: 'THA',
  YEGEE_086: 'THA',
  YEGEE_087: 'THA',
  YEGEE_088: 'THA',
};
