import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import transactionAction from 'reduxs/transaction/actions'
import webBankAction from 'reduxs/webbank/webbanks/actions'
import loaderAction from 'reduxs/loader/actions'
import WithdrawContainer from './Withdraw.container'
import socketAction from 'reduxs/socket/actions'
import creditAction from 'reduxs/credit/actions';
import creditInfoSelector from 'reduxs/credit/selector'
const mapStateToProps = (state: RootReducers): ITransactionProps => {
  const creditMeDatail = creditInfoSelector.creditMeDetail(state)
  return ({
    getTransactionIsFetching: state.mantra.transaction.all.isFetching!,
    getTransactionError: state.mantra.transaction.all.error!,
    getTransactionCode: state.mantra.transaction.all.code!,
    getTransactionResult: state.mantra.transaction.all.data!,
    getTransactionLimit: state.mantra.transaction.all.limit!,
    getTransactionPage: state.mantra.transaction.all.page!,
    getTransactionTotal: state.mantra.transaction.all.total!,
    getTransactionPageTotal: state.mantra.transaction.all.pageTotal!,
    putTransactionCode: state.mantra.transaction.put.code!,
    putTransactionError: state.mantra.transaction.put.error!,
    putTransactionIsFetching: state.mantra.transaction.put.isFetching!,
    putTransactionResult: state.mantra.transaction.put.data!,
    getWebBankActiveIsFetching: state.mantra.webbank.webbanks.getWebbankActive.isFetching!,
    getWebBankActiveError: state.mantra.webbank.webbanks.getWebbankActive.error!,
    getWebBankActiveCode: state.mantra.webbank.webbanks.getWebbankActive.code!,
    getWebBankActiveResult: state.mantra.webbank.webbanks.getWebbankActive.data!,
    postMapWebBankCode: state.mantra.webbank.webbanks.postMapWebbank.code!,
    postMapWebBankError: state.mantra.webbank.webbanks.postMapWebbank.error!,
    postMapWebBankIsFetching: state.mantra.webbank.webbanks.postMapWebbank.isFetching!,
    postMapWebBankResult: state.mantra.webbank.webbanks.postMapWebbank.data!,
    postReserveTransactionCode: state.mantra.transaction.reserve.code!,
    postReserveTransactionError: state.mantra.transaction.reserve.error!,
    postReserveTransactionIsFetching: state.mantra.transaction.reserve.isFetching!,
    postReserveTransactionResult: state.mantra.transaction.reserve.data!,
    getCreditInfoListCode: state.mantra.credit.list.code!,
    getCreditInfoListError: state.mantra.credit.list.error!,
    getCreditInfoListIsFetching: state.mantra.credit.list.isFetching!,
    creditInfoPagination: state.mantra.credit.list.pagination!,
    creditInfo: state.mantra.credit.list.data!,
    storeCreditMetail: creditMeDatail.storeData!,
    userMeResult: state.mantra.user.userMe.data!,
  })
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ITransactionActionProps => bindActionCreators({
  getTransaction: transactionAction.getTransactionAllAction,
  putTransaction: transactionAction.putTransactionAction,
  getWebBankActive: webBankAction.getWebbankActiveAction,
  postMapWebBank: webBankAction.postMapWebbankAction,
  clearTransactionAll: transactionAction.clearTransactionAll,
  postReserveTransaction: transactionAction.postReserveTransactionAction,
  cancelReserveTransaction: transactionAction.cancelReserveTransactionAction,
  forceCancelReserveTransaction: transactionAction.forceCancelReserveTransactionAction,
  loader: loaderAction.loadingAction,
  connectFinanceDepositSocket: socketAction.connectFinanceDepositSocketAction,
  connectFinanceWithdrawSocket: socketAction.connectFinanceWithDrawSocketAction,
  unlistenTransactionAllSocket: transactionAction.unlistenTransactionAllSocket,
  getCreditInfoList: creditAction.getCreditInfoListAction,
  clearCreditInfoList: creditAction.clearCreditInfoList,
  meDetailRequest: creditAction.postCreditMeDeTailRequestAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(WithdrawContainer)