import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import userMeAction from 'reduxs/user/me/actions'
import transactionAction from 'reduxs/transaction/notifications/actions'
import loaderAction from 'reduxs/loader/actions'
import loginAction from 'reduxs/auth/login/actions'
import reportAction from 'reduxs/report/actions'
import userTokenAction from 'reduxs/user/token/actions'
import menuAction from 'reduxs/menu/actions'
import RootContainer from './Root.container'

const mapStateToProps = (state: RootReducers): IRootProps => ({
    isPersist: state.mantra.user.token.isPersist!,
    token: state.mantra.auth.login!.data!.token!,
    loginError: state.mantra.auth.login.error!,
    loginCode: state.mantra.auth.login.code!,
    loginResult: state.mantra.auth.login.data!,
    loginIsFetching: state.mantra.auth.login.isFetching!,
    userMeIsFetching: state.mantra.user.userMe.isFetching!,
    userMeError: state.mantra.user.userMe.error!,
    userMeCode: state.mantra.user.userMe.code!,
    userMeResult: state.mantra.user.userMe.data!,
    getNotificationTransactionAllIsFetching: state.mantra.transaction.notification.isFetching!,
    getNotificationTransactionAllError: state.mantra.transaction.notification.error!,
    getNotificationTransactionAllCode: state.mantra.transaction.notification.code!,
    getNotificationTransactionAllResult: state.mantra.transaction.notification.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IRootActionProps => bindActionCreators({
  getUserMe: userMeAction.getUserMeAction,
  loader: loaderAction.loadingAction,
  getNotificationTransactionAll : transactionAction.getNotificationTransactionAllAction,
  listenNotificationTransactionAllSocket: transactionAction.listenNotificationTransactionAllSocketAction,
  unlistenNotificationTransactionAllSocket:transactionAction.unlistenNotificationTransactionAllSocketAction,
  clearUser: userTokenAction.clearUserAction,
  logout: loginAction.logoutAction,
  clearMenu: menuAction.clearMenu,
  clearReportBenefit: reportAction.clearReportBenefitAction,
  clearReportBenefitLottery: reportAction.clearReportBenefitLotteryAction,
  clearReportBenefitExternalCasino: reportAction.clearReportBenefitExternalCasinoAction,
  clearReportBenefitExternalSlot: reportAction.clearReportBenefitExternalSlotAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(RootContainer)