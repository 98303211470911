import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState, initialStateLotterNumberSession, initialStateDepositWithdraw, initialStateReportBenefit } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const reportOverviewReducer = (state: IReportOverviewReduxState = initialState, action: RootAction)
    : IReportOverviewReduxState => {
    switch (action.type) {
        case getType(actions.getReportOverviewAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportOverviewSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportOverviewFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const reportYegeeReducer = (state: IReportYegeeReduxState = initialState, action: RootAction)
    : IReportYegeeReduxState => {
    switch (action.type) {
        case getType(actions.getReportYegeeAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportYegeeSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportYegeeFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const reportLotterNumberSessionReducer = (
    state: IReportLotterNumberSessionReduxState = initialStateLotterNumberSession,
    action: RootAction)
    : IReportLotterNumberSessionReduxState => {
    switch (action.type) {
        case getType(actions.getReportLotterNumberSessionAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportLotterNumberSessionSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportLotterNumberSessionFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const reportLotterDepositWithdrawReducer = (
    state: IReportDepositWithdrawReduxState = initialStateDepositWithdraw,
    action: RootAction)
    : IReportDepositWithdrawReduxState => {
    switch (action.type) {
        case getType(actions.postReportDepositWithdrawAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.postReportDepositWithdrawSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.postReportDepositWithdrawFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const reportBenefitReducer = (
    state: IReportBenefitReduxState<IResponseReportBenefit> = initialStateReportBenefit,
    action: RootAction)
    : IReportBenefitReduxState<IResponseReportBenefit> => {
    switch (action.type) {
        case getType(actions.getReportBenefitAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportBenefitSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportBenefitFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        case getType(actions.clearReportBenefitAction):
            return initialStateReportBenefit
        default:
            return state
    }
}

const reportBenefitLotteryReducer = (
    state: IReportBenefitLotteryReduxState = initialStateReportBenefit,
    action: RootAction)
    : IReportBenefitLotteryReduxState => {
    switch (action.type) {
        case getType(actions.getReportBenefitLotteryAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportBenefitLotterySuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportBenefitLotteryFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        case getType(actions.clearReportBenefitLotteryAction):
            return initialStateReportBenefit
        default:
            return state
    }
}

const reportBenefitExternalCasinoReducer = (
    state: IReportBenefitExternalCasinoReduxState = initialStateReportBenefit,
    action: RootAction)
    : IReportBenefitExternalCasinoReduxState => {
    switch (action.type) {
        case getType(actions.getReportBenefitExternalCasinoAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportBenefitExternalCasinoSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportBenefitExternalCasinoFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        case getType(actions.clearReportBenefitExternalCasinoAction):
            return initialStateReportBenefit
        default:
            return state
    }
}

const reportBenefitExternalSlotReducer = (
    state: IReportBenefitExternalSlotReduxState = initialStateReportBenefit,
    action: RootAction)
    : IReportBenefitExternalSlotReduxState => {
    switch (action.type) {
        case getType(actions.getReportBenefitExternalSlotAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getReportBenefitExternalSlotSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getReportBenefitExternalSlotFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        case getType(actions.clearReportBenefitExternalSlotAction):
            return initialStateReportBenefit
        default:
            return state
    }
}

export default combineReducers({
    overview: reportOverviewReducer,
    yegee: reportYegeeReducer,
    lotterNumberSession: reportLotterNumberSessionReducer,
    lotterDepositWithdraw: reportLotterDepositWithdrawReducer,
    reportBenefit: reportBenefitReducer,
    reportBenefitLottery: reportBenefitLotteryReducer,
    reportBenefitExternalCasino: reportBenefitExternalCasinoReducer,
    reportBenefitExternalSlot: reportBenefitExternalSlotReducer,
})