import React, { Component } from 'react'
import { noop, isEqual } from 'lodash'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb,
    Modal,
} from 'components'
import { date } from 'utils'
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TablePagination,
} from '@material-ui/core'
import './LineManagement.container.scss'
import { History } from 'history'
import SearchIcon from '@material-ui/icons/Search'


const constants = {
    title: 'รายการไลน์ผู้ใช้',
    lottoMasterLabel: 'จัดการไลน์ผู้ใช้',
    systemLabel: 'จัดการระบบ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IUserLineManagementProps & IUserLineManagementActionProps = {
    postUserLine() { noop() },
    deleteUserLine() { noop() },
    postUserLineCode: 0,
    postUserLineError: '',
    postUserLineIsFetching: false,
    postUserLineResult: [],
    postUserLineLimit: 10,
    postUserLinePage: 0,
    postUserLineTotal: 0,
    deleteUserLineIsFetching: false,
    loader() { noop() },
}
type IColumnId = 'userId' | 'username' | 'phoneNumber' | 'createdAt' | 'delete'
const columns: ITransactionColumn<IColumnId>[] = [
    { id: 'userId', label: 'เลขที่', minWidth: 50, align: 'center' },
    { id: 'username', label: 'ชื่อผู้ใช้งาน', minWidth: 50, align: 'left' },
    { id: 'phoneNumber', label: 'เบอร์โทร', minWidth: 50, align: 'left' },
    { id: 'createdAt', label: 'วันที่/เวลา', minWidth: 50, align: 'left' },
    { id: 'delete', label: 'จัดการบัญชี', minWidth: 50, align: 'center' },
];

class
    LineManagementContainer
    extends Component<IUserLineManagementProps & IUserLineManagementActionProps & DefaultProps
    & { history: History }, IUserManagementContainerState> {

    static defaultProps = defaultProps
    state: IUserManagementContainerState = {
        limit: 10,
        page: 0,
        total: 0,
        search: '',
        permission: 'LOTTER_MASTER',
        queryTimer: setTimeout(() => { noop() }, 0),
    }

    postUserLine() {
        const data = {
            page: this.state.page+1,
            limit: this.state.limit,
            search: this.state.search,
        }
        this.props.postUserLine(data)
    }

    componentDidMount() {
        this.props.loader(true)
        this.postUserLine()
    }

    componentDidUpdate(prevProps: IUserLineManagementProps) {
        if (prevProps.postUserLineIsFetching !== this.props.postUserLineIsFetching
            && !this.props.postUserLineIsFetching) {
            this.props.loader(false)
        }
        if (!isEqual(this.props.postUserLineResult, prevProps.postUserLineResult)) {
            if (this.props.postUserLineResult.length === 0 && (this.props.postUserLinePage > 0)) {
              this.setState({
                limit: this.props.postUserLineLimit,
                total: this.props.postUserLineTotal,
              })
              this.handleChangePage(null, this.props.postUserLinePage - 1)
            } else {
              this.setState({
                limit: this.props.postUserLineLimit,
                page: this.props.postUserLinePage,
                total: this.props.postUserLineTotal,
              })
            }
            this.props.loader(false)
          }
        if (prevProps.deleteUserLineIsFetching !== this.props.deleteUserLineIsFetching
            && !this.props.postUserLineIsFetching) {
            this.props.loader(false)
            this.props.loader(true)
            this.postUserLine()
        }
    }

    handleApproveDeposit = (userId: number) => {
        const reqData:IDeleteUserLineData = {
            user_id: userId,
        }
        this.props.loader(true)
        this.props.deleteUserLine(reqData)
        Modal.confirm.hide()
    }

    handleOnDelete = (userId: number) => {
        Modal.confirm.show({
            action: () => {
                this.handleApproveDeposit(userId)
            },
            cancelAction: () => {
                Modal.confirm.hide()
            },
        })
    }

    getQueryParams = (): IQueryUserLineParams => {
        return {
            search: this.state.search,
            page: this.state.page+1,
            limit: this.state.limit,
        }
    }

    handleQuerySearch = (value: string) => {
        this.setState({ search: value }, () => {
            clearTimeout(this.state.queryTimer)
            this.setState({
                queryTimer: setTimeout(() => {
                    this.props.postUserLine(this.getQueryParams())
                }, 2000),
            })
        })
    }

    createData = (txDataList: IUserLinePopulate[]): IUserLineTableRow[] => {
        return txDataList.map((txData) => {
            return {
                userId: String(txData.userId),
                username: txData.username,
                phoneNumber: txData.phoneNumber,
                createdAt: date.FormatDate(txData.createdAt, 'Do MMM YY | k:mm น.'),
                delete: <Button text="ลบ" onClick={() => this.handleOnDelete(txData.userId)} />,
            }
        })
    }


    handleTextAlign = (align: any) => {
        let alignType
        switch (align) {
            case 'center':
                alignType = 'MuiTableCell-alignCenter'
                break
            case 'right':
                alignType = 'MuiTableCell-alignRight'
                break
            case 'left':
                alignType = 'MuiTableCell-alignLeft'
                break
        }
        return alignType
    }

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.setState({
            page: newPage,
        }, () => {
            this.props.postUserLine(this.getQueryParams())
        })
    };

    handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        this.setState({
            page: 0,
            limit: Number(event.target.value),
        }, () => {
            this.props.postUserLine(this.getQueryParams())
        })
    };

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
    }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.lottoMasterLabel, active: true },
        ]
        return (
            <div className="lotto-master-management-container">
                <Grid container spacing={3}>
                    <Grid item xs={12} className="title-webbank">
                        <Grid container>
                            <Grid item xs={12}>
                                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                                <TitlePage title="จัดการไลน์ผู้ใช้" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Paper className="secondary-dark" elevation={3}>
                    <TableContainer >
                        <Grid container className="table-title">
                            <Grid item xs={7} >
                                <div >
                                    <h5>
                                        {constants.title}
                                    </h5>
                                </div>
                            </Grid>

                            <Grid item xs={5} className="Xs4">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <></>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="th-container">
                                            <TextField
                                                label="ค้นหา"
                                                startElement={<SearchIcon />}
                                                value={this.state.search}
                                                onChange={(e) => this.handleQuerySearch(e.target.value)}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.createData(this.props.postUserLineResult).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.userId}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            className={this.handleTextAlign(column.align)}
                                                        >
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value) : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    this.props.postUserLineResult.length === 0 && (
                                        <TableRow hover role="checkbox">
                                            <TableCell key={'no data'} >
                                                ไม่มีข้อมูล...
                                        </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="primary-text"
                        component="div"
                        count={this.props.postUserLineTotal}
                        page={this.props.postUserLinePage}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.props.postUserLineLimit}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="รายการ/หน้า:"
                        labelDisplayedRows={(
                            { from, to, count }) => `${from}-${to} ของ ${count !== -1 ? count : `more than ${to}`}`}
                    />
                </Paper>
            </div>
        )
    }
}

export default LineManagementContainer