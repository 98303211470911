import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchGetNotificationTransactionAll } from './services'
import actions from './actions'

const notificationGetEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getNotificationTransactionAllAction)),
    exhaustMap(_ =>
      from(fetchGetNotificationTransactionAll())
        .pipe(
          map(actions.getNotificationTransactionAllSuccessAction),
          catchError(error => of(actions.getNotificationTransactionAllFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getNotificationTransactionAllCancelAction))))
        ),
    )
  )

const updatenotificationlEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updateNotificationTransactionAllAction)),
    map((action) => actions.updateNotificationTransactionAllActionSuccessAction(action.payload))
  )

export default [
  notificationGetEpic,
  updatenotificationlEpic,
]
