import { createAction } from 'typesafe-actions'
import {
  GET_USER_CRIMINAL_VIEW_LOG_REQUEST,
  GET_USER_CRIMINAL_VIEW_LOG_SUCCESS,
  GET_USER_CRIMINAL_VIEW_LOG_FAILURE,
  GET_USER_CRIMINAL_VIEW_LOG_CLEAR
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getCriminalViewLogAction = createAction(
  GET_USER_CRIMINAL_VIEW_LOG_REQUEST,
  reslove => (data: IQueryCriminalViewLog, isFirst?: boolean) => reslove({...data,isFirst})
)
const getCriminalViewLogSuccessAction = createAction(
  GET_USER_CRIMINAL_VIEW_LOG_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IResCriminalViewLog>>>,isFirst: boolean) => 
  resolve({...data, isFirst})
)
const getCriminalViewFailureLogAction = createAction(
  GET_USER_CRIMINAL_VIEW_LOG_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getCriminalViewLogClear = createAction(GET_USER_CRIMINAL_VIEW_LOG_CLEAR)
export default {
  getCriminalViewLogAction,
  getCriminalViewLogSuccessAction,
  getCriminalViewFailureLogAction,
  getCriminalViewLogClear,
}