import React, { Component } from 'react'
import { Grid, Paper, ListSubheader } from '@material-ui/core'
import './EditWebBank.scss'
import { History } from 'history';
import {
  TextField,
  Button,
  Select,
  Breadcrumb,
  TitlePage,
  Switches,
  InputNumber,
  Modal,
  Snackbar
} from 'components'
import bank from 'assets/images/global/bank'
import { Formik, Form } from 'formik'
import scheme from './models/scheme'
import { get, isEmpty } from 'lodash';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { responseCode } from 'constants/response';

const constants = {
  detail: 'รายละเอียด',
  placeholderOwnerName: 'ชื่อเจ้าของบัญชี',
  placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),

  systemLabel: 'จัดการระบบ',
  webBankLabel: 'บัญชีระบบ',
  editWebBankLabel: 'แก้ไข',

  wrongEditBank: 'แก้ไขบัญชีระบบผิดพลาด',
  wrongEditBankDescription: 'ไม่สามารถแก้ไขบัญชีระบบ',
  auto:'ระบบอัตโนมัติ : ',
  selectBank:'เลือกธนาคาร',
  numberBank:'เลขบัญชี',
  save:'บันทึก',
  multiBank:'ธนาคารร่วม',
  editBankSuccess:'แก้ไขบัญชีธนาคารสำเร็จ'
}
class EditWebBank extends Component<IPutWebBankActionProps & IWebBankProps & { history: History },
  & IEditWebBankState> {

  constructor(props: IPutWebBankActionProps & IWebBankProps & { history: History }) {
    super(props)
    this.state = {
      type: '',
      name: '',
      accountNo: '',
      bank: '',
      listBankCheck: [],
      isAuto:false
    }
  }

  componentDidMount(){
      this.setState({
        bank:get(this.props.history.location.state, 'webBank.type', ''),
        isAuto:get(this.props.history.location.state, 'webBank.isAuto', false),
        listBankCheck:get(this.props.history.location.state, 'webBank.listBankCheck', []),
      })
  }

  componentDidUpdate(prevProps: IWebBankProps) {

    if(this.props.putWebBankIsFetching !== prevProps.putWebBankIsFetching && !this.props.putWebBankIsFetching){
      this.props.loader(false)
      if (this.props.putWebBankCode === responseCode.OK) {
        this.props.loader(false)
        Snackbar.success.show({
          message:constants.editBankSuccess
        })
        this.props.history.push('/webbank')
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongEditBankDescription,
          actionText: constants.wrongEditBank,
        })
        this.props.loader(false)
      }
    }
  }

  handleWebBankChange = (bankName: string) => {
    this.setState({ bank: bankName })
  }

  formatBankNumber = (type:string)=>{
    return type === 'GSB' || type ==='BAAC'
    ? '# ### #### ####'
    : '### ###### #'
  }

  onSubmitEdit = (values: ICreateWebBank) => {
    this.props.loader(true)
    this.props.putWebBank(
      {
        id: get(this.props.history.location.state, 'webBank.id', ''),
        name: values.name,
        number: values.number,
        type: this.state.bank,
        isAuto: this.state.isAuto,
        listBankCheck:this.state.listBankCheck,
        operate_type:get(this.props.history.location.state, 'webBank.operateType', '')
      }
    )
  }

  handleOnClickBreadcrumb = (path: string) => {
    if (!isEmpty(path)) {
        this.props.history.replace(path)
    }
}

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.webBankLabel, path: '/webbank', active: false },
      { label: constants.editWebBankLabel, active: true },
    ]
    let typeBank = get(this.props.history.location.state, 'webBank.type', '')
    let titleEditWebbank = get(bank,`[${typeBank}].name`,'')+' ('+ get(this.props.history.location.state, 'webBank.name', '')+')'

    return (
      <div>
        <div className="edit-webbank-container">
        <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
        <TitlePage title={constants.editWebBankLabel}/>
        <h5 className="body-2 mb-4">{titleEditWebbank}</h5>
        <Grid container>
            <Grid item xs={10}>
              <Paper className="paper-container secondary-dark">
                <Formik
                  initialValues={{
                    bank: get(this.props.history.location.state, 'webBank.type', ''),
                    name: get(this.props.history.location.state, 'webBank.name', ''),
                    number: get(this.props.history.location.state, 'webBank.number', ''),
                    amount: get(this.props.history.location.state, 'webBank.amount', ''),
                  }}
                  validationSchema={scheme(this.state.bank)}
                  enableReinitialize
                  onSubmit={this.onSubmitEdit}
                >
                  {
                    ({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                       // @ts-ignore
                        <Form>
                          <div className="paper-body">
                          <Grid container>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <h5>{constants.detail}</h5>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="control-button">
                                    <h6 className="primary-text">
                                      {constants.auto}
                                    </h6>
                                    <Switches
                                      // onChange={(e) => {this.setState({isAuto:!this.state.isAuto})}}
                                      checked={this.state.isAuto}
                                      color="default"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Select
                              name="bank"
                              label={constants.selectBank}
                              fullWidth={true}
                              value={this.state.bank}
                              onChange={(event) => {
                                this.handleWebBankChange(event.target.value)
                                setFieldValue(event.target.name, event.target.value)
                              }}

                              error={!!errors.bank && touched.bank}
                              helperText={!!errors.bank && touched.bank ? `${errors.bank}` : ''}
                              itemList={Object.keys(bank).map((item) => {
                                return {
                                  // @ts-ignore
                                  value: bank[item].key,
                                  element: (
                                    <div>
                                      <div style={{ display: 'flex' }}>
                                        <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                          <img
                                            src={bank[item].Icon}
                                            style={{
                                              width: 'inherit',
                                              height: 'inherit',
                                            }}
                                            alt="thailand-flat"
                                          />
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                          <h6>{bank[item].name}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                }
                              })

                              }
                            />
                            <TextField
                              name="name"
                              label={constants.placeholderOwnerName}
                              fullWidth={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name.toLowerCase()}
                              error={!!errors.name && touched.name}
                              helperText={!!errors.name && touched.name ? `${errors.name}` : ''}
                            />
                            <InputNumber
                              onBlur={handleBlur}
                              disabled={this.state.bank === '' ? true : false}
                              name="number"
                              label={constants.numberBank}
                              fullWidth={true}
                              onValueChange={({ value }) => setFieldValue('number', value)}
                              decimalScale={0}
                              format={this.formatBankNumber(this.state.bank)}
                              allowNegative={false}
                              value={values.number.toLowerCase()}
                              errorMessage={errors.number}
                              error={!!errors.number && touched.number}
                              helperText={!!errors.number && touched.number ? `${errors.number}` : ''}
                            />
                            <div className="paper-bottom">
                              <Button
                                type="submit"
                                text={constants.save}
                                fullWidth={true}
                              />
                            </div>
                            </Grid>
                            <Grid item xs={6} className="list-bank">
                              <div className="box-close d-flex align-items-center justify-content-center">
                                <h6>ปิดปรับปรุง ชั่วคราว</h6>
                              </div>
                              <List className={`custom-scrollbar`} subheader={<ListSubheader><h5>{constants.multiBank}</h5></ListSubheader>}>
                                {Object.keys(bank).map((item) => {
                                  const labelId = `checkbox-list-label-${item}`;
                                  let checkIndex = this.state.listBankCheck.indexOf(item)
                                  return (
                                    <ListItem key={item} role={undefined} dense button 
                                    className={`${checkIndex!==-1?"checked":""}`}
                                      onClick={
                                        ()=>{
                                          if (checkIndex === -1) {
                                            this.setState({listBankCheck:this.state.listBankCheck.concat([item])})
                                          }else{
                                            this.state.listBankCheck.splice(checkIndex,1)
                                            this.setState({listBankCheck:this.state.listBankCheck})
                                          }
                                        }
                                      }>
                                      <ListItemIcon>
                                        <Checkbox
                                          checked={checkIndex !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} primary={(
                                        <div style={{ display: 'flex' }}>
                                          <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                            <img
                                              src={bank[item].Icon}
                                              style={{
                                                width: 'inherit',
                                                height: 'inherit',
                                              }}
                                              alt="thailand-flat"
                                            />
                                          </div>
                                          <div style={{ display: 'grid' }}>
                                            <h6>{bank[item].name}</h6>
                                          </div>
                                        </div>
                                        )}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Grid>
                          </Grid>
                          </div>
                        </Form>
                        )
                  }
                </Formik>
              </Paper>
            </Grid>
        </Grid>
        </div >
      </div >
    )
  }
}

export default EditWebBank