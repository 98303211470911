import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchGetCriminalViewLog } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const criminalViewLogEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getCriminalViewLogAction)),
    exhaustMap(action =>
      from(fetchGetCriminalViewLog(action.payload))
        .pipe(
          mergeMap(response => of(actions.getCriminalViewLogSuccessAction(response, action.payload.isFirst || false))),
          catchError(error => of(actions.getCriminalViewFailureLogAction(error))),
        ),
    )
  )
export default [
  criminalViewLogEpic,
]
