import project from 'constants/project'

export const POST_CREDIT_ME_DETAIL_REQUEST = 'POST_CREDIT_ME_DETAIL_REQUEST'
export const POST_CREDIT_ME_DETAIL_SUCCESS = 'POST_CREDIT_ME_DETAIL_SUCCESS'
export const POST_CREDIT_ME_DETAIL_FAILURE = 'POST_CREDIT_ME_DETAIL_FAILURE'
export const POST_CREDIT_ME_DETAIL_CANCEL = 'POST_CREDIT_ME_DETAIL_CANCEL'
export const CLEAR_CREDIT_ME_DETAIL = 'CLEAR_CREDIT_ME_DETAIL'


export const initialState: ReducerState<ICreditDetail> = {
  isFetching: false,
  code: 0,
  storeData: {},
  data: {
    createDate: '',
    money: '',
    numbers: '',
    numbersBetResult: '',
    rate: '',
    slug: '',
    status: 'WAIT',
    type: 'WITHDRAW',
    updatedAt: ''
  },
  error: '',
}

export const endpoint = {
  meDetailRequest: `${project.environment[project.environmentName].api}/credit/history/list`,
}