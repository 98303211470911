import axios, { AxiosResponse } from "axios"
import project from 'constants/project'
import { transformer } from 'utils'


//FN8.GET: api/v2/finance/user/wallet
const fetchFinanceUserWallet = (query: IV2ReadFinanceUserWallet): Promise<AxiosResponse<APIResponse<IV2RespReadFinanceUserWallet>>> => {
  const endpoint = `${project.environment[project.environmentName].api}/v2/finance/user/wallet`
  return axios.get(`${endpoint}${transformer.urlSearchParamsSnakecase(query)}`)
}
//FN9.GET: api/v2/finance/user/records
const fetchUserRecords= (query: IQueryPagination & IV2QueryFinanceUserRecords): Promise<AxiosResponse<APIResponse<IPagination<IV2RespFinanceUserRecords>>>> => {
  const endpoint = `${project.environment[project.environmentName].api}/v2/finance/user/records`
  return axios.get(`${endpoint}${transformer.urlSearchParamsSnakecase(query)}`)
}


export {
  fetchFinanceUserWallet,
  fetchUserRecords
}