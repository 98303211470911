import project from 'constants/project'

export const POST_LOTTO_RESULT_REQUEST = 'POST_LOTTO_RESULT_REQUEST'
export const POST_LOTTO_RESULT_SUCCESS = 'POST_LOTTO_RESULT_SUCCESS'
export const POST_LOTTO_RESULT_FAILURE = 'POST_LOTTO_RESULT_FAILURE'
export const POST_LOTTO_RESULT_CANCEL = 'POST_LOTTO_RESULT_CANCEL'

export const initialState: ReducerState<ILottoResult[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postLottoResult: `${project.environment[project.environmentName].api}/lotter`,
}