import React, { Component } from 'react'
import './verify.style.scss'
import { debounce, get, isEmpty, isNull, noop } from 'lodash'
// import { responseCode } from 'constants/response'
import { date, number, transformer } from 'utils'
import { RouteComponentProps } from 'react-router-dom'
import {
    TitlePage,
    Breadcrumb,
    Button,
    InputNumber,
    Modal,
} from 'components'
import {
    Checkbox,
    Grid,
    Paper,
} from '@material-ui/core'
import { TabMenuComponent } from "./TabMenu"
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchFinanceUserWallet, fetchUserRecords } from 'reduxs/finance/services'
import { fetchStatUserRead } from 'reduxs/stats/services'
import { fetchPromoCondition, postPromoUpdate } from 'reduxs/promotion/services'
import ContentLoader from 'react-content-loader'
import { responseCode } from 'constants/response'
const constants = {
    withdrawLabel: 'รายการถอน',
    systemLabel: 'จัดการระบบ',
    verify: 'ตรวจสอบ',
    tabReport: 'รายงาน',
    tabDepitWithdraw: 'ฝาก/ถอน',
    tabBet: 'เดิมพัน',
    tabData: 'ข้อมูล',
    tabAff: 'ชวนเพื่อน',
    tabNoti: 'แจ้งเตือน',
    save: 'บันทึก',
}
const items = [
    { label: 'เทริน์ x 1', turn: 1 },
    { label: 'เทริน์ x 3', turn: 3 },
    { label: 'เทริน์ x 5', turn: 5 },
    { label: 'เทริน์ x 10', turn: 10 },
]

const tabs = ([
    { name: constants.tabReport },
    { name: constants.tabDepitWithdraw },
    { name: constants.tabBet },
    { name: constants.tabData },
    { name: constants.tabAff },
    { name: constants.tabNoti },
])

const navigates: IBreadcrumbItem[] = [
    { label: constants.systemLabel, active: false },
    { label: constants.withdrawLabel, path: '/transaction/withdraw', active: false },
    { label: constants.verify, active: true },
]

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IVerifyContainerProps & IVerifyContainerActionProps = {
    getDetailUserIsFetching: false,
    getDetailUserError: '',
    getDetailUserCode: 0,
    getDetailUserResult: [],
    getUserDetail() { noop() },
    userMeResult: {},
}

class VerifyContainer extends
    Component<IVerifyContainerProps & IVerifyContainerActionProps & DefaultProps & RouteComponentProps & { history: History },
        IVerifyContainerState> {

    static defaultProps = defaultProps

    constructor(props: IVerifyContainerProps & IVerifyContainerActionProps & DefaultProps & RouteComponentProps & { history: History }) {
        super(props)
        this.state = {
            disableMode: false,
            walletUser: {},
            statUser: {
                uUserId: 0,
                uUserName: '',
                benefit: 0,
                deposit: 0,
                withdraw: 0,
                betCountTotal: 0,
                lotterTotal: 0,
                slotTotal: 0,
                casinoTotal: 0,
            },
            promoConditionData: {
                bonusCurrent: 0,
                bonusCurrentAt: '',
                bonusTotal: 0,
                turnBalance: 0,
                turnCurrent: 0,
                turnCurrentAt: '',
                turnTotal: 0
            },
            hasMoreUserRecords: false,
            userRecordsDatas: [],
            pageRecords: 0,
            totalRecords: 0,
            bonusCurrent: '',
            loadingUserDetail: false,
            loadingPromoCondition: false,
            loading: false,
            checkedIndex: 0,
            turn: 1
        }
    }

    componentDidMount() {
        const queryObj = transformer.queryToObject()
        if (isEmpty(queryObj) || queryObj.phoneNo.length !== 10) {
            this.goback()
        }
        else {
            //STAFF ONLY CONDITION
            if(this.props.userMeResult.permission === 'STAFF') {
                this.setState({ disableMode: true})
            }
            this.setState({ loadingUserDetail: true })
            this.props.getUserDetail({ permission: 'USER', phone_number: String(queryObj.phoneNo), limit: 1, page: 0 })
        }
    }

    
    componentDidUpdate(prevProps: IVerifyContainerProps, prevState: IVerifyContainerState) {

        if (prevProps.getDetailUserIsFetching !==
            this.props.getDetailUserIsFetching &&
            !this.props.getDetailUserIsFetching) {
                if(this.props.getDetailUserCode === responseCode.OK &&  !isEmpty(this.props.getDetailUserResult)){
                    this.setState({ loadingUserDetail: false, loadingPromoCondition: true })
                    this.getUserWallet(get(this.props.getDetailUserResult, '[0].id', -1))
                    this.getStatUser(get(this.props.getDetailUserResult, '[0].id', -1))
                    this.getPromoCondition(get(this.props.getDetailUserResult, '[0].id', -1))
                    this.getUserRecords(get(this.props.getDetailUserResult, '[0].id', -1))
                }
                else{
                    this.goback()
                }
        }
        // if(prevState.loading !== this.state.loading){
        //     this.setState({loading: false})
        // }
    }

    goback = ()=>{
         this.props.history.goBack()
    }

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
    }

    handleOnChangeTab = (tabName: string) => {
        //TODO New tab
        // this.setState({currentTab: tabName})
    }

    getPromoCondition = (id: number) => {
        fetchPromoCondition({ userId: id })
            .then((response) => {
                const promoConditionData = response.data.data
                if (promoConditionData) {
                    this.setState({ promoConditionData, loadingPromoCondition: false })
                } else {
                    this.props.history.replace('/transaction/withdraw')
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
            })
    }
    checkCurrentDeposit = ()=>{
        let latestDeposit = null
        for (const record of this.state.userRecordsDatas) {
            if (record.type === 'DEPOSIT') {
                if (!latestDeposit || new Date(record.updatedAt) > new Date(latestDeposit.updatedAt)) {
                    latestDeposit = record;
                }
            }
        }
        return latestDeposit ? Number(latestDeposit.money) : 0
    }

    getUserRecords = (id: number) => {
        fetchUserRecords({ userId: id, page: this.state.pageRecords +1, limit: 10, status: ['SUCCESS'], type: ['DEPOSIT', 'WITHDRAW'] })
            .then((response) => {
                const userRecordsDatas: IV2RespFinanceUserRecords[] = get(response, 'data.data.dataList', [])
                const totalRecords = get(response, 'data.data.total', 0)
                const pageRecords = get(response, 'data.data.page', 0)
                if (userRecordsDatas) {
                    const updatedRecords = this.state.userRecordsDatas.concat(userRecordsDatas)
                    this.setState({ userRecordsDatas: updatedRecords, totalRecords: this.state.totalRecords+totalRecords, hasMoreUserRecords: pageRecords < totalRecords, pageRecords:  pageRecords})
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
            })
    }

    getUserWallet = (id: number) => {
        fetchFinanceUserWallet({ userId: id })
            .then((response) => {
                const walletUser = response.data.data
                if (walletUser) {
                    this.setState({ walletUser })
                } else {
                    this.goback()
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
            })
    }

    promoUpdate = () => {
        postPromoUpdate({ userId: get(this.props.getDetailUserResult, '[0].id', -1), bonusCurrent: Number(this.state.bonusCurrent), turnCurrent: (Number(this.state.bonusCurrent) + this.checkCurrentDeposit()) * this.state.turn})
            .then((response) => {
                const resp = response.data.data
                if (resp) {
                    Modal.success.show({
                        action: () => {
                            Modal.success.hide()
                            this.getPromoCondition(resp.userId)
                            this.getUserWallet(get(this.props.getDetailUserResult, '[0].id', -1))
                        },
                        description: 'อัพเดทเทริน์โบนัส',
                    })
                } else {
                    Modal.error.show({
                        action: () => {
                            Modal.error.hide()
                        },
                        actionText: 'เกิดข้อผิดพลาด',
                        description: 'อัพเดทเทริน์โบนัส',
                    })
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
            })
    }

    getStatUser = (id: number) => {
        fetchStatUserRead({ u_user_id: id })
            .then((response) => {
                const statUser = get(response, 'data.data.dataList[0]', [])
                if (statUser) {
                    this.setState({ statUser })
                } else {
                    this.goback()
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
            })
    }

    debouncedCallback = debounce((status: string) => {
        this.setState({ bonusCurrent: status, loading: true })
        // setTimeout(() => {
        //     this.setState({ loading: false })
        // }, 800)

    }, 800)

    handleCheckboxChange = (index: number, turn: number) => {
        this.setState({ checkedIndex: index, turn: turn })
    };

    render() {
        const deposit = parseFloat(get(this.state, 'statUser.deposit', 0))
        const withdraw = parseFloat(get(this.state, 'statUser.withdraw', 0))
        const totalCredit = deposit - withdraw

        return (
            <div className="verify-container">
                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title={constants.verify} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper className="paper-container secondary-dark">
                            <div className=" body-1 m1-b d-flex flex-column">
                                {
                                    this.state.loadingUserDetail ?
                                        <ContentLoader height={53} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                            <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
                                            <rect x="0" y="30" rx="3" ry="3" width="75" height="10" />
                                            <rect x="80" y="30" rx="3" ry="3" width="70" height="10" />
                                            <rect x="155" y="30" rx="3" ry="3" width="110" height="10" />
                                            <rect x="270" y="30" rx="3" ry="3" width="90" height="10" />
                                        </ContentLoader>
                                        : <>
                                            {get(this.props.getDetailUserResult, '[0].username', '')}
                                            <span className="body-2">
                                                เงินในกระเป๋า{' '}
                                                <span className='primary-purple-text'>
                                                    {number.decimalsFormatCommaWithOutZero(String(this.state.walletUser.wallet))}
                                                </span>
                                                {' • กระเป๋า Aff '}
                                                {number.decimalsFormatCommaWithOutZero(String(this.state.walletUser.affWallet))}
                                                {' • รวม '}
                                                {number.decimalsFormatCommaWithOutZero(String(this.state.walletUser.walletTotal))}

                                            </span>
                                            <div className='divider p2-b'></div>
                                        </>
                                }

                            </div>
                            <div className="menu-list">
                                <TabMenuComponent tabs={tabs} onChangeTab={this.handleOnChangeTab} />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} lg={4} xl={4}>
                        <Paper className="paper-container secondary-dark">
                            <div className=" body-1 d-flex flex-column">
                                {'การเงิน'}
                            </div>
                            <div className='divider p2-b'></div>
                            <div className='finance-container primary-dark m1-t'>
                                <InfiniteScroll
                                    dataLength={this.state.totalRecords}
                                    next={() => {
                                        this.getUserRecords(get(this.props.getDetailUserResult, '[0].id', -1))
                                    }}
                                    hasMore={this.state.hasMoreUserRecords}
                                    height={300}
                                    style={{ maxHeight: "300px", minHeight: "150px" }}
                                    loader={false}
                                >
                                    {
                                        this.state.userRecordsDatas.map((row: IV2RespFinanceUserRecords, index: number) => {
                                            return (
                                                <div key={`${index}`} className="finance-item-wrapper subtitle-1 primary-text m1-y">
                                                    {row.type === 'DEPOSIT' ? 'ฝาก' : 'ถอน'}
                                                    <span className='d-flex m1-r'>
                                                        <div className={`${new Date(row.updatedAt).getDate().toString().length === 2 ? 'm2-r' : 'md-r'}`}>
                                                            {`${number.decimalsFormatCommaWithOutZero(String(row.money))} บาท`}
                                                        </div>
                                                        {date.FormatDate(row.updatedAt, "Do MMM YY k:mm")}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-y">
                                {'ฝากทั้งหมด'}
                                <span className='pd-item'>{`${number.decimalsFormatCommaWithOutZero(String(get(this.state, 'statUser.deposit', 0)))} บาท`}</span>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text">
                                {'ถอนทั้งหมด'}
                                <span className='pd-item'>{`${number.decimalsFormatCommaWithOutZero(String(get(this.state, 'statUser.withdraw', 0)))} บาท`}</span>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-y">
                                {'สรุป ฝาก/ถอน'}
                                <span className={`pd-item ${totalCredit < 0 ? 'primary-red-text' : 'primary-green-text'}`}>
                                    {number.decimalsFormatCommaWithOutZero(
                                        String(
                                            parseFloat(get(this.state, 'statUser.deposit', 0)) +
                                            parseFloat(get(this.state, 'statUser.withdraw', 0))
                                        )
                                    )}
                                    <span className='primary-text' style={{ marginLeft: '5px' }}>บาท</span>
                                </span>
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} lg={4} xl={4}>
                        <Paper className="paper-container secondary-dark" style={{ minHeight: 523 }}>
                            <div className="bonus-control-header body-1">
                                {'เงื่อนไข'}
                                <div>
                                    <Button
                                        disabled={this.state.disableMode || this.state.bonusCurrent === ''}
                                        buttonClass={'btn-width'}
                                        size="small"
                                        text={constants.save}
                                        onClick={() => this.promoUpdate()}
                                    />
                                </div>
                            </div>
                            <div className='divider p1-b'></div>
                            <div className='bonus-control'>
                                <div className='d-flex align-items-center m2-b'>
                                    <div className="d-flex flex subtitle-1 primary-text">
                                        {'กำหนดโบนัส'}
                                    </div>
                                    <div className="subtitle-1 primary-text">{'ให้'}</div>
                                    <InputNumber
                                        disabled={this.state.disableMode}
                                        thousandSeparator
                                        inputClassName='input-style'
                                        sizeInput={'small'}
                                        style={{ height: 20 }}
                                        name="bonus"
                                        label={'ใส่เลข'}
                                        onValueChange={({ value }) => {
                                            this.debouncedCallback(value)
                                        }}
                                        allowNegative={false}
                                        decimalScale={0}
                                        allowLeadingZeros
                                        value={this.state.bonusCurrent}
                                    />
                                    <div className="subtitle-1 primary-text m1-l">{'บาท'}</div>
                                </div>
                                <div className='d-flex m2-b'>
                                    <div className="d-flex flex subtitle-1 primary-text">
                                        {'โบนัสล่าสุด'}
                                    </div>
                                    <div className="subtitle-1 primary-text ">{
                                        this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={20} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="6" rx="3" ry="3" width="20" height="10" />
                                            </ContentLoader>
                                            : number.decimalsFormatCommaWithOutZero(String(this.state.promoConditionData.bonusCurrent))
                                    }</div>
                                    <div className="subtitle-1 primary-text m1-x">
                                        {this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={20} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="6" rx="3" ry="3" width="20" height="10" />
                                            </ContentLoader>
                                            : 'บาท'
                                        }
                                    </div>
                                    <div className="subtitle-1 primary-text">
                                        {this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={60} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="6" rx="3" ry="3" width="60" height="10" />
                                            </ContentLoader>                                            
                                            : date.FormatDate(isNull(get(this.state, 'promoConditionData.bonusCurrentAt')) ? new Date() : get(this.state, 'promoConditionData.bonusCurrentAt'), "Do MMM YY k:mm")
                                        }
                                    </div>
                                </div>
                                <div className='d-flex m2-b'>
                                    <div className="d-flex flex subtitle-1 primary-text">
                                        {'โบนัสทั้งหมด'}
                                    </div>
                                    <div className="subtitle-1 primary-text">
                                        {this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={40} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="6" rx="3" ry="3" width="40" height="10" />
                                            </ContentLoader>
                                            : `${number.decimalsFormatCommaWithOutZero(String(this.state.promoConditionData.bonusTotal))} บาท`
                                        }
                                    </div>
                                </div>
                                {items.map((item, index) => (
                                    <div key={index} className='d-flex m2-b align-items-center'>
                                        <div className="d-flex flex subtitle-1 primary-text align-items-center">
                                            <Checkbox
                                                disabled={this.state.disableMode}
                                                style={{ padding: 0, marginLeft: -2 }}
                                                size='small'
                                                onChange={() => this.handleCheckboxChange(index, item.turn)}
                                                checked={this.state.checkedIndex === index && !this.state.disableMode}
                                                tabIndex={-1}
                                            />
                                            <div className="subtitle-1 primary-text m1-l">{`${item.label}`}</div>
                                        </div>
                                        <div className="subtitle-1 primary-text">{'ต้องเดิมพัน'}</div>
                                        {/* {index === items.length - 1 ? (
                                            <InputNumber
                                                thousandSeparator
                                                inputClassName='input-style'
                                                sizeInput={'small'}
                                                style={{ height: 20 }}
                                                name="bonus"
                                                label={'ใส่เลข'}
                                                // onValueChange={({ value }) => {
                                                //     setFieldValue("number", value);
                                                // }}
                                                allowNegative={false}
                                                allowLeadingZeros
                                            // value={values.number}
                                            />
                                        ) : ( */}
                                        <div className="subtitle-1 primary-text m1-l">
                                            {this.state.loadingPromoCondition ?
                                                <ContentLoader rtl width={20} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                    <rect x="0" y="9" rx="3" ry="3" width="20" height="10" />
                                                </ContentLoader>
                                                : number.decimalsFormatCommaWithOutZero(String((Number(this.state.bonusCurrent) + this.checkCurrentDeposit()) * item.turn))
                                            }
                                        </div>
                                        {/* )} */}
                                        <div className="subtitle-1 primary-text m1-l">{'บาท'}</div>
                                    </div>
                                ))}
                                <div className="bonus-item-wrapper m1-l subtitle-1 primary-text m2-b">
                                    {'ต้องเดิมพัน'}
                                    <span className='pd-item'>
                                        {this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={40} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="9" rx="3" ry="3" width="40" height="10" />
                                            </ContentLoader>
                                            : `${number.decimalsFormatCommaWithOutZero(String(this.state.promoConditionData.turnCurrent))} บาท`
                                        }
                                    </span>
                                </div>
                                <div className="bonus-item-wrapper m1-l subtitle-1 primary-text">
                                    {'ต้องเดิมพันคงเหลือ'}
                                    <span className='pd-item'>
                                        {this.state.loadingPromoCondition ?
                                            <ContentLoader rtl width={40} height={20} speed={2} foregroundColor={'#8A85FF'} backgroundColor='#ecebeb'>
                                                <rect x="0" y="9" rx="3" ry="3" width="40" height="10" />
                                            </ContentLoader>
                                            : `${number.decimalsFormatCommaWithOutZero(String(this.state.promoConditionData.turnBalance))} บาท`
                                        }
                                    </span>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} lg={4} xl={4} >
                        <Paper className="paper-container secondary-dark" style={{ minHeight: 523 }}>
                            <div className=" body-1 d-flex flex-column">
                                {'เดิมพัน'}
                            </div>
                            <div className='divider p2-b'></div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-y">
                                {'หวย'}
                                <span style={{ paddingRight: '50%' }}>{`${number.decimalsFormatCommaWithOutZero(String(get(this.state,'statUser.lotterTotal',0)))} บาท`}</span>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-b">
                                {'คาสิโน'}
                                <span style={{ paddingRight: '50%' }}>{`${number.decimalsFormatCommaWithOutZero(String(get(this.state,'statUser.casinoTotal',0)))} บาท`}</span>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-b">
                                {'สล๊อต'}
                                <span style={{ paddingRight: '50%' }}>{`${number.decimalsFormatCommaWithOutZero(String(get(this.state,'statUser.slotTotal',0)))} บาท`}</span>
                            </div>
                            <div className="finance-item-wrapper subtitle-1 primary-text m2-b">
                                {'ทั้งหมด'}
                                <span style={{ paddingRight: '50%' }}>
                                    {number.decimalsFormatCommaWithOutZero(
                                        String(
                                            parseFloat(get(this.state, 'statUser.lotterTotal', 0)) +
                                            parseFloat(get(this.state, 'statUser.casinoTotal', 0)) +
                                            parseFloat(get(this.state, 'statUser.slotTotal', 0))
                                        )
                                    )} บาท
                                </span>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default VerifyContainer