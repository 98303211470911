import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetBetRate = (data: IGetBetRateParams) => {
    const queryParams = new URLSearchParams({
        type: get(data, 'type', ''),
    })
    return axios.get(endpoint.getBetRate, {
        params: queryParams,
    })
}