import React from "react";
import emiter from "configs/emiter";
import event from "constants/event";
import { ModalProps } from "react-bootstrap/Modal";
import Modal from "./Modal.component";

import { Confirm } from "./components/Confirm";

const confirm = {
  show: ({
    action,
    cancelAction,
    editAction,
    value,
    description,
    description2,
    child,
    height,
    dataListFail,
    isResponseFailure,
    is19DoorOrAllRelevantActivated,
    typeIcon,
    lottoList,
    handleCallback,
  }: IconfirmNumberRateModal) => {
    const modalProps: ModalProps = {
      size: "sm",
    };
    return emiter.emit(event.MODAL, {
      state: "show",
      extraProps: modalProps,
      component: (
        <Confirm
          action={action}
          cancelAction={cancelAction}
          editAction={editAction}
          description={description}
          description2={description2}
          child={child}
          height={height}
          dataListFail={dataListFail}
          isResponseFailure={isResponseFailure}
          typeIcon={typeIcon}
          is19DoorOrAllRelevantActivated={is19DoorOrAllRelevantActivated}
          lottoList={lottoList}
          handleCallback={handleCallback}
        />
      ),
    });
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: "hide" });
  },
};
export default {
  Core: Modal,
  confirm,
};
