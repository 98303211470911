import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import momentTime from 'moment-timezone'
import {
  Grid,
  Paper,
  TextareaAutosize,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Table,
  TableContainer,
  IconButton,
} from '@material-ui/core'
import {
  TitlePage,
  Button,
  TextField,
  Modal
} from 'components'
import HelpIcon from '@material-ui/icons/Help'
import DeleteIcon from '@material-ui/icons/Delete'
import { Formik, Form, FormikProps } from 'formik'
import initialValue from './models/initialValues'
import scheme from './models/scheme'
import { noop, isEmpty } from 'lodash'
import './Broadcast.container.scss'
import { responseCode } from 'constants/response'
import {transformer} from 'utils'


const constants = {
  lineLabel: 'โฆษณาไลน์',
  announceLabel: 'บันทึกรายการและประกาศโฆษณา',
  editLabel: 'แก้ไขโดย',
  boardcastList: 'ประวัติโฆษณาที่ประกาศ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IBroadcastContainerProps & IBroadcastContainerActionProps = {
  saveBroadcast() { noop() },
  postBroadcast() { noop() },
  getListBroadcast() { noop() },
  deleteBroadcast() { noop() },
  summaryBroadcast() { noop() },
  logBroadcast() { noop() },
  saveBroadcastCode: 0,
  saveBroadcastError: '',
  saveBroadcastIsFetching: false,
  saveBroadcastResult: {},
  postBroadcastCode: 0,
  postBroadcastError: '',
  postBroadcastIsFetching: false,
  postBroadcastResult: {},
  getBroadcastListCode: 0,
  getBroadcastListError: '',
  getBroadcastListIsFetching: false,
  getBroadcastListResult: [],
  deleteBroadcastListCode: 0,
  deleteBroadcastListError: '',
  deleteBroadcastListIsFetching: false,
  deleteBroadcastListResult: [],
  summaryBroadcastListCode: 0,
  summaryBroadcastListError: '',
  summaryBroadcastListIsFetching: false,
  summaryBroadcastListResult: [],
  logBroadcastListCode: 0,
  logBroadcastListError: '',
  logBroadcastListIsFetching: false,
  logBroadcastListResult: [],
  loader() { noop() },
}
const columns: IBroadcastSummaryColumn<'countGroup' | 'countRoom' | 'countUser'>[] = [
  { id: 'countGroup', label: 'จำนวนกลุ่ม', align: 'center' },
  { id: 'countRoom', label: 'จำนวนห้อง', align: 'center' },
  { id: 'countUser', label: 'จำนวนคน', align: 'center' },
];

const columnsLog: IBroadcastSummaryColumn<'title' | 'groupUserCount' | 'roomUserCount'
  | 'userCount' | 'createdAt'>[] = [
    { id: 'title', label: 'หัวข้อ', align: 'center' },
    { id: 'groupUserCount', label: 'จำนวนกลุ่ม', align: 'center' },
    { id: 'roomUserCount', label: 'จำนวนห้อง', align: 'center' },
    { id: 'userCount', label: 'จำนวนคน', align: 'center' },
    { id: 'createdAt', label: 'วันที่', align: 'center' },
  ];

class BroadcastContainer extends Component<IBroadcastContainerProps
  & IBroadcastContainerActionProps & DefaultProps & RouteComponentProps, IBroadcastContainerState> {

  static defaultProps = defaultProps

  constructor(props: IBroadcastContainerProps & IBroadcastContainerActionProps & DefaultProps & RouteComponentProps) {
    super(props)
    this.state = {
      textRunner: '',
      contactUrl: '',
      contactLine: '',
      contactPhoneNumber: '',
      updatedAt: '',
      boardcastLine: '',
      initialFormValue: initialValue,
      tableRows: [],
      boardcastList: [],
      boardcastId: null,
      isSave: false,
      countGroup: 0,
      countRoom: 0,
      countUser: 0,
      title: '',
    }
  }

  componentDidMount() {
    this.props.getListBroadcast()
    this.props.logBroadcast()
    this.props.summaryBroadcast()
    this.props.loader(true)
  }

  componentDidUpdate(prevProps: IBroadcastContainerProps) {
    if (prevProps.saveBroadcastIsFetching !== this.props.saveBroadcastIsFetching
      && !this.props.saveBroadcastIsFetching) {
      this.props.loader(false)
      if (this.props.saveBroadcastCode === responseCode.OK) {
        this.props.postBroadcast({ id: this.props.saveBroadcastResult.id })
        this.props.getListBroadcast()
      }
      else {
        Modal.error.show({
          action:  Modal.error.hide,
          description: 'หัวข้อโฆษณามีอยู่ในระบบแล้ว',
        })
      }
    }
    if (prevProps.postBroadcastIsFetching !== this.props.postBroadcastIsFetching
      && !this.props.postBroadcastIsFetching) {

      if (this.props.postBroadcastCode === responseCode.OK) {
        this.props.logBroadcast()
        this.props.loader(false)
        Modal.success.show({
          action: Modal.success.hide,
          description: 'ประกาศโฆษณาสำเร็จ',
        });
        this.setState({title: '', boardcastLine:'', isSave: false,boardcastId: null})
      }
    }
    if (prevProps.getBroadcastListIsFetching !== this.props.getBroadcastListIsFetching
      && !this.props.getBroadcastListIsFetching) {
      this.props.loader(false)
      this.setState({ boardcastList: this.addUIParameter(this.props.getBroadcastListResult) })
    }
    if (prevProps.deleteBroadcastListIsFetching !== this.props.deleteBroadcastListIsFetching
      && !this.props.deleteBroadcastListIsFetching) {
      this.props.loader(false)
      if (this.props.deleteBroadcastListCode === responseCode.OK) {
        this.setState({ boardcastId: null, boardcastLine: '', isSave: false })
        this.props.getListBroadcast()
      }
    }
    if (prevProps.logBroadcastListIsFetching !== this.props.logBroadcastListIsFetching
      && !this.props.logBroadcastListIsFetching) {
      this.props.loader(false)
      if (this.props.logBroadcastListCode === responseCode.OK) {
        this.setState({
          tableRows: this.props.logBroadcastListResult.map((logResult) => this.createData(logResult)),
        })
      }
    }
  }

  createData(logResult: IBroadcast): IBroadcastRow {
    return {
      title: String(logResult.title),
      groupUserCount: logResult.groupUserCount,
      roomUserCount: logResult.roomUserCount,
      userCount: logResult.userCount,
      createdAt: momentTime(moment(logResult.createdAt).format('YYYYMMDDHHmmss'), 'YYYYMMDDHHmmss').format('lll'),
    };
  }

  addUIParameter = (list: any[]) => {
    return list.map((item) => { return { ...item, isEdit: false } })
  }

  onInsertBoardcast = (props: IBroadcastScheme) => {
    this.props.loader(true)
    if (this.state.boardcastId === null) {
      if(transformer.IsJsonString(this.state.boardcastLine)) {
        this.props.saveBroadcast({
          title: props.title,
          contents: JSON.parse(this.state.boardcastLine),
        })
      }
      else {
        this.props.loader(false)
        Modal.error.show({
          action:  Modal.error.hide,
          description: 'ข้อความประกาศไม่ถูกต้อง',
        })
      }
    }
    else {
      this.props.postBroadcast({
        id: this.state.boardcastId,
      })
    }
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }
  onDeleteBoardcast = (id: number) => {
    this.props.loader(true)
    this.props.deleteBroadcast({
      id: id,
    })
  }

  handleTextAlign = (align: any) => {
    let alignType
    switch (align) {
      case 'center':
        alignType = 'MuiTableCell-alignCenter'
        break
      case 'right':
        alignType = 'MuiTableCell-alignRight'
        break
      case 'left':
        alignType = 'MuiTableCell-alignLeft'
        break
    }
    return alignType
  }
  renderNewsList = (props: FormikProps<IBroadcastScheme>): JSX.Element => (
    <>
      {
        this.state.boardcastList
          .map((news, index) => {
            return (
              <div className="broadcast-item-container" key={`news${index}`}>
                <div
                  className="subtitle"
                  onClick={() => {
                    props.setFieldValue('title', news.title)
                    this.setState({title: news.title})
                    this.setState({
                      isSave: true,
                      boardcastId: news.id,
                      boardcastLine: JSON.stringify(news.contents, null, 1),
                    })
                  }}
                >
                  <div className="AAA">
                  <h5 className="primary-text">
                      {news.title}
                    </h5>
                    <h6 className="secondary-text subtitle-2">
                      {
                        news.isEdit
                          ? (<></>)
                          : `${moment(news.createdAt).add('years', 543).format('Do MMM YYYY - k:mm')}
                             : ${constants.editLabel} ${news.username}`
                      }
                    </h6>
                  </div>
                </div>
                <div className="delete-broadcast">
                  <h6 className="secondary-text">
                    <IconButton
                      className="primary-purple-text"
                      onClick={() => {
                        props.setFieldValue('title', '')
                        this.onDeleteBoardcast(news.id)
                      }
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </h6>
                </div>
              </div>
            )
          })
      }
    </>
  )

  render() {
    const { tableRows } = this.state
    const RenderNewsList = this.renderNewsList
    return (
      <Formik
        initialValues={this.state.initialFormValue}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={values => {
          if (!isEmpty(values) && !isEmpty(this.state.boardcastLine)) {
            this.onInsertBoardcast(values)
          }
        }}
      >
        {
          (formikProps: FormikProps<IBroadcastScheme>) => {
            return (
              // @ts-ignore
              <Form>
                <div className="broadcast-container">
                  <TitlePage title={constants.lineLabel} />
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} xl={6}>
                      <TableContainer component={Paper} className="m2-b">
                        <Table aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {
                                columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow component="th" scope="row">
                              <TableCell align="center">{this.props.summaryBroadcastListResult.countGroup}</TableCell>
                              <TableCell align="center">{this.props.summaryBroadcastListResult.countRoom}</TableCell>
                              <TableCell align="center">{this.props.summaryBroadcastListResult.countUser}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Paper className="paper-container secondary-dark">
                        <div className="paper-title">
                          <div className="title">
                            <div >{`จัดการประชาสัมพันธ์`}</div>
                            <div className="help-icon">
                            <HelpIcon
                              onClick={() =>
                                window.open(`https://medium.com/linedevth/%E0%B8%A5%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B8%A3%E0%B8%B6%E0%B8%A2%E0%B8%B1%E0%B8%87-flex-message-simulator-%CE%B2-afb595a828bd`, '_blank')}
                            />
                          </div>
                          </div>
                        </div>
                        <div className="button-broadcast p2-b">
                          <Button
                            size="small"
                            text="ข้อมูลที่ต้องการประกาศ"
                            color="green"
                            onClick={() => window.open('https://developers.line.biz/flex-simulator/', '_blank')}
                          />
                        </div>
                        <div className="paper-body primary-text">
                          <TextField
                            disabled={this.state.isSave}
                            name="title"
                            onBlur={formikProps.handleBlur}
                            placeholder="กรุณากรอกหัวข้อโฆษณา *"
                            fullWidth={false}
                            label="หัวข้อโฆษณา"
                            value={this.state.title}
                            onChange={(event) => {
                              this.setState({title: event.target.value})
                              formikProps.setFieldValue('title', event.target.value)
                            }}
                            // onChange={formikProps.handleChange}
                            error={!!formikProps.errors.title && formikProps.touched.title}
                            helperText={!!formikProps.errors.title && formikProps.touched.title ? `${formikProps.errors.title}` : ''}
                          />

                          <div className="text-area-container">
                            <TextareaAutosize
                              color="red"
                              disabled={this.state.isSave}
                              className="text-area-auto-size"
                              aria-label="minimum height"
                              rowsMin={21}
                              rowsMax={22}
                              placeholder="ข้อความ...."
                              value={this.state.boardcastLine}
                              onChange={(e) => this.setState({ boardcastLine: e.target.value })}
                            />
                          </div>
                        </div>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <div className="paper-bottom">
                              <Button
                                text="ยกเลิก"
                                fullWidth={true}
                                onClick={() => {
                                  this.setState({ title: '',boardcastLine: '', boardcastId: null, isSave: false })
                                  formikProps.setFieldValue('title', '')
                                }}
                                disabled={this.state.boardcastLine === '' ? true : false}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="paper-bottom">
                              <Button
                                type="submit"
                                text={constants.announceLabel}
                                disabled={this.state.boardcastLine === '' ? true : false}
                                fullWidth={true}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                      <Paper className="paper-container secondary-dark">
                        <div className="paper-title">รายการโฆษณา</div>
                        <div className="paper-body">
                          <div className="broadcast-list-container">
                            <RenderNewsList {...formikProps} />
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className="paper-container secondary-dark">
                        <div className="paper-title">{constants.boardcastList}</div>
                        <div className="broadcast-log-container">
                          <TableContainer component={Paper} className="m2-b">
                            <Table aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {
                                    columnsLog.map((column) => (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))
                                  }
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {tableRows.map((row, index) => {
                                  return (
                                    <TableRow component="th" scope="row" key={index}>
                                      {columnsLog.map((column) => {
                                        const value = row[column.id];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={this.handleTextAlign(column.align)}
                                          >
                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )
          }
        }
      </Formik>
    )
  }
}

export default BroadcastContainer