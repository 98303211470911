import React, { SFC } from 'react'
import { noop } from 'lodash'
import { Button } from 'components';
import './success.style.scss'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const constants = {
  title: 'เรียบร้อย',
  description: 'กรุณารอการตรวจสอบสักครู่',
  approval: 'อนุมัติ',
  cancel: 'ยกเลิก',
  bank: 'ธนาคาร: ',
  accountNo: 'หมายเลขบัญชี: ',
  amount: 'จำนวนเงิน: ',
  baht: ' บาท',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ISuccessModal = {
  title: constants.title,
  description: constants.description,
  action() { noop() },
  actionText: 'สำเร็จ',
}

const SuccessModal: SFC<ISuccessModal & DefaultProps> = (props: ISuccessModal) => {

  return (
    <div className="modal-success-container">
    <div className="description-success-modal">
      <CheckCircleIcon/>
      <h5>{props.actionText}</h5>
      <h5 className="subtitle-2">{props.description}</h5>
      <div className="margin-bottom">
      <Button
        text="OK"
        onClick={props.action}
      />
       </div>
    </div>
  </div>
  )
}

SuccessModal.defaultProps = defaultProps

export default SuccessModal