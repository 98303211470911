import React, { SFC } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { noop } from "lodash";

import "./TextField.component.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ITextField = {
  id: "",
  label: "",
  error: false,
  helperText: "",
  name: "",
  type: "",
  placeholder: "",
  value: "",
  fullWidth: false,
  multiline: false,
  rows: 1,
  startElement: undefined,
  endElement: undefined,
  onBlur() {
    noop();
  },
  onClick() {
    noop();
  },
  onChange() {
    noop();
  },
  onFocus() {
    noop();
  },
  InputLabelProps: undefined,
  disabled: false,
  betRate: "",
  maxLength: undefined,
  variant: "outlined",
  inputRef: undefined,
  inputClassName: '',
  sizeInput: 'medium',
};

const TextFieldComponent: SFC<ITextField & DefaultProps> = (props) => {
  const {
    id,
    label,
    error,
    helperText,
    name,
    type,
    placeholder,
    value,
    fullWidth,
    multiline,
    rows,
    startElement,
    endElement,
    onClick,
    onBlur,
    onChange,
    onFocus,
    disabled,
    betRate,
    maxLength,
    variant,
    inputRef,
    inputClassName,
    sizeInput,
  } = props;
  const betRateValue = betRate !== "" ? `อัตราจ่ายลูกค้า: ` : "";

  return (
    <div className={`text-field-container ${variant}`}>
      <TextField
        className={inputClassName}
        size={sizeInput}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        label={label}
        variant={variant}
        error={error}
        disabled={disabled}
        fullWidth={fullWidth}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        inputRef={inputRef}
        InputLabelProps={undefined}
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          startAdornment: startElement ? (
            <InputAdornment position="start">{startElement}</InputAdornment>
          ) : undefined,
          endAdornment: endElement ? (
            <InputAdornment position="end">{endElement}</InputAdornment>
          ) : undefined,
        }}
      />
      <div className="subtitle-1 secondary-text">
        {betRateValue}
        <span className="primary-text">{betRate}</span>
      </div>
    </div>
  );
};

TextFieldComponent.defaultProps = defaultProps;

export default TextFieldComponent;
