import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import settingsRatesAction from 'reduxs/settings/actions'
import lottoAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import SettingsContainer from './Settings.container'

const mapStateToProps = (state: RootReducers): IBetRatesProps => ({
  getBetRatesAllIsFetching: state.mantra.settings.allBetRates.isFetching!,
  getBetRatesAllError: state.mantra.settings.allBetRates.error!,
  getBetRatesAllCode: state.mantra.settings.allBetRates.code!,
  getBetRatesAllResult: state.mantra.settings.allBetRates.data!,
  putBetRatesCode: state.mantra.settings.putBetRate.code!,
  putBetRatesError: state.mantra.settings.putBetRate.error!,
  putBetRatesIsFetching: state.mantra.settings.putBetRate.isFetching!,
  putBetRatesResult: state.mantra.settings.putBetRate.data!,
  getYegeeGameConfigAllIsFetching: state.mantra.settings.getYegeeConfig.isFetching!,
  getYegeeGameConfigAllError: state.mantra.settings.getYegeeConfig.error!,
  getYegeeGameConfigAllCode: state.mantra.settings.getYegeeConfig.code!,
  getYegeeGameConfigAllResult: state.mantra.settings.getYegeeConfig.data!,
  putYegeeGameConfigIsFetching: state.mantra.settings.putYegeeConfig.isFetching!,
  putYegeeGameConfigError: state.mantra.settings.putYegeeConfig.error!,
  putYegeeGameConfigCode: state.mantra.settings.putYegeeConfig.code!,
  putYegeeGameConfigResult: state.mantra.settings.putYegeeConfig.data!,
  getBetRatesIsFetching: state.mantra.lotto.bet.rate.isFetching!,
  getBetRatesError: state.mantra.lotto.bet.rate.error!,
  getBetRatesCode: state.mantra.lotto.bet.rate.code!,
  getBetRatesResult: state.mantra.lotto.bet.rate.data!,

})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ISettingsActionProps => bindActionCreators({
  getBetRatesAll: settingsRatesAction.getBetRatesAllAction,
  putBetRates: settingsRatesAction.putBetRatesAction,
  getYegeeGameConfigAll: settingsRatesAction.getYegeeGameConfigAllAction,
  putYegeeGameConfig: settingsRatesAction.putYegeeGameConfigAction,
  loader: loaderAction.loadingAction,
  getBetRate: lottoAction.getBetRateAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(SettingsContainer)