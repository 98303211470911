import React, { Component } from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import './LotterNumberSession.component.scss'


type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: ILotterNumberSessionComponentProps = {
    lotterNumberSessionList: [{
        number: '',
        totalBet: 0,
        result: 0,
        rateLevel: {
            id: 0,
            level: 0,
            volumeLimit: 0,
            rate: 0,
            code: '',
            type: '',
            createdAt: '',
            updatedAt: '',
        },
    }],
}
type IColumnLotter = 'number' | 'totalBet' | 'result' | 'rateLevel' | 'rate'
const columns: ITransactionColumn<IColumnLotter>[] = [
    { id: 'number', label: 'เลข', minWidth: 50, align: 'center' },
    {
        id: 'totalBet', label: 'ยอดเดิมพัน', minWidth: 50, align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'result', label: 'ยอดจ่าย', minWidth: 50, align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    { id: 'rateLevel', label: 'ระดับอัตราจ่าย', minWidth: 50, align: 'right' },
    {
        id: 'rate', label: 'อัตราจ่ายปัจจุบัน', minWidth: 50, align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
];

class LotterNumberSessionComponent extends Component<ILotterNumberSessionComponentProps & DefaultProps, {}> {
    static defaultProps = defaultProps

    createData(txData: ILotterNumberSession): ILotterNumberSessionTransactionRow {
        return {
            number: String(txData.number),
            totalBet: String(txData.totalBet),
            result: String(txData.result),
            rateLevel: String(txData.rateLevel.level),
            rate: String(txData.rateLevel.rate),
        };
    }

    render() {
        return (
            <TableContainer >
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <h5>{column.label}</h5>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.lotterNumberSessionList.map((row) => {
                            const extractData = this.createData(row)
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={extractData.number}>
                                    {columns.map((column) => {
                                        const value = extractData[column.id as IColumnLotter];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                <h5 className="primary-purple-text">
                                                    {column.format ? column.format(Number(value)) : value}
                                                </h5>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })
                        }
                        {
                            this.props.lotterNumberSessionList.length === 0 && (
                                <TableRow hover role="checkbox">
                                    <TableCell key={'no data'} >
                                        ไม่มีข้อมูล...
                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer >
        )
    }
}

export default LotterNumberSessionComponent