import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Formik, Form, FormikProps } from 'formik'
import initialValue from './models/initialValues'
import scheme from './models/scheme'
import { responseCode, responseMessage } from 'constants/response'
import moment from 'moment'
import { noop, isEmpty, map, find, get } from 'lodash'
import colors from 'constants/colors'
import { LOTTO_SLUG_NAME, LOTTO_FLAG_ALPHA, LOTTO_SLUG_TO_TYPE } from 'constants/variables'
import LottoFlags from 'assets/images/global/flags'
import './LottoList.container.scss'
import { Grid, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  TitlePage,
  TextField,
  Button,
  DateTime,
  LottoActionCard,
  ResponsiveIcon,
  Switches,
  Modal,
  Breadcrumb,
  ButtonCountDown,
} from 'components'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import AddAlarm from '@material-ui/icons/AddAlarm'
import AlarmOff from '@material-ui/icons/AlarmOff'
import PaymentIcon from '@material-ui/icons/Payment'
import GavelIcon from '@material-ui/icons/Gavel';
enum ESTATUS {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  WAIT = 'WAIT',
}

const constants = {
  defaultError: 'กรุณาลองใหม่อีกครั้ง',
  back: 'กลับ',
  betLabel: 'แทงหวย',
  closedLabel: 'เวลาที่ปิดรับ: ',
  openedYeegeLabel: 'เปิดรับแทง: ',
  openBet: 'เปิดรับแทง',
  calculate: 'จ่ายผู้ชนะ',
  closedStatusLabel: 'ปิดรับแทง',
  submit: 'ออกผล',
  placeholderInput: (type: string) => `กรอก ${type}`,
  waitingStatusLabel: 'รอเปิดรับแทง',
  openedYeegeStatusLabel: '24 ชม.',
  refund: 'คืนเงินลูกค้า',
  round: 'รอบ',
  twoDown: '2 ตัวล่าง',
  threeUp: '3 ตัวบน',
  fourSuit: 'เลขชุด  4 ตัว',
  wrongUsernameTitle: 'เวลาเปิด-ปิด แทงไม่ถูกต้อง',
  wrongUsernameDescription: 'โปรดกรอกเวลาเปิดห้ามน้อยกว่า 5 นาที่ ของเวลาปัจจุบัน และเวลาปิดต้องมากกว่าเวลาเปิด',
  wrongCalculateTitle: 'จ่ายเงินผู้ชนะผิดพลาด',
  wrongCalculateDescription: 'ขณะนี้ไม่มีรายการแทง ไม่สามารถจ่ายเงินผู้ชนะได้',
  lottoLabel: 'ควบคุมหวย',
  systemLabel: 'จัดการระบบ',
  start: 'เวลาที่เปิดรับ',
  end: 'เวลาที่ปิดรับ',
  refundSuccess: 'คืนเงินลูกค้าสำเร็จ',
  calculateSuccess: 'จ่ายผู้ชนะสำเร็จ'

}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoListProps & ILottoListActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: '',
  lottoSchedule: [],
  lottoList: [],
  getLottoCode: 0,
  getLottoError: '',
  getLottoIsFetching: false,
  putLottoModeIsFetching: false,
  putLottoModeCode: 0,
  putLottoModeError: '',
  putlottoModeResult: [],
  putLottoStatusIsFetching: false,
  putLottoStatusCode: 0,
  putLottoStatusError: '',
  putlottoStatusResult: [],
  putlottoStatusResCode: 0,
  postLottoRefundIsFetching: false,
  postLottoRefundCode: 0,
  postLottoRefundError: '',
  postlottoRefundResult: {},
  postLottoResultIsFetching: false,
  postLottoResultCode: 0,
  postLottoResultError: '',
  postlottoResultResult: [],
  postLottoCalculateIsFetching: false,
  postLottoCalculateCode: 0,
  postLottoCalculateError: '',
  postlottoCalculateResult: {},
  loader() { noop() },
  getLottoList() { noop() },
  getLottoSchedule() { noop() },
  putLottoMode() { noop() },
  putLottoStatus() { noop() },
  postLottoRefund() { noop() },
  postLottoResult() { noop() },
  postLottoCalculate() { noop() },
}

class LottoListContainer extends Component<
  ILottoListProps & ILottoListActionProps & DefaultProps & RouteComponentProps> {

  static defaultProps = defaultProps

  state: ILottoContainerState = {
    id: 2,
    dateStartWithMoment: moment().utcOffset(0).format(),
    dateStart: new Date(),
    dateEnd: new Date(),
    dateEndWithMoment: moment().utcOffset(0).format(),
    search: '',
    onSettingLotto: 'LOTTER_GOVN',
    title: 'หวยรัฐบาล',
    startTime: '',
    endTime: '',
    buttonControl: 'AUTOMATIC',
    checked: false,
    dateTimeout: setTimeout(() => { noop() }, 0),
    status: 'OPEN',
    initialFormValue: initialValue,
    onSubmit: false,
    onRefund: false,
    onCalculate: false,
    icon: LottoFlags.THA.Icon,
    endDateConf: '',
    updateAtResult: '',
    isCountDown: false
  }

  componentDidMount() {
    this.props.loader(true)
    this.props.getLottoList()
    this.props.getLottoSchedule()
  }

  componentDidUpdate(prevProps: ILottoListProps) {
    if ((prevProps.getLottoScheduleIsFetching !== this.props.getLottoScheduleIsFetching || prevProps.getLottoIsFetching !== this.props.getLottoIsFetching)
      && (!this.props.getLottoScheduleIsFetching && !this.props.getLottoIsFetching)
    ) {
      this.renderBetDetail(
        find(this.props.lottoSchedule, (lotto) => { return lotto.code === this.state.onSettingLotto })!
      )
      this.props.loader(false)
    }

    if (prevProps.putLottoModeIsFetching !== this.props.putLottoModeIsFetching
      && !this.props.putLottoModeIsFetching) {
      this.props.getLottoSchedule()
      this.props.loader(false)
      // TODO FAIL
    }

    if (prevProps.putLottoStatusIsFetching !== this.props.putLottoStatusIsFetching
      && !this.props.putLottoStatusIsFetching) {
      if (this.props.putLottoStatusCode === responseCode.OK) {
        this.setState({ status: (find(this.props.putlottoStatusResult, 'status')?.status) })
        this.props.getLottoSchedule()
        this.props.getLottoList()
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: get(responseMessage, this.props.putlottoStatusResCode, constants.defaultError),
          actionText: constants.wrongUsernameTitle,
        })
      }
      this.props.loader(false)
    }

    if (prevProps.postLottoResultIsFetching !== this.props.postLottoResultIsFetching
      && !this.props.postLottoResultIsFetching) {
      if (this.props.postLottoResultCode === responseCode.OK) {
        this.props.getLottoList()
        this.setState(
          {
            onSubmit: true,
            onRefund: true,
            onCalculate: true,
          })
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: get(
            responseMessage,
            this.props.postLottoResultCode,
            responseMessage[0]
          ),
        })
      }
      this.props.loader(false)
    }

    if (prevProps.postLottoRefundIsFetching !== this.props.postLottoRefundIsFetching
      && !this.props.postLottoRefundIsFetching) {
      if (this.props.postLottoRefundCode === responseCode.OK ||
        this.props.postLottoRefundCode === responseCode.ACCEPTED) {
        this.setState({onRefund: false,})
        Modal.success.show({
          action: Modal.success.hide,
          description: constants.refundSuccess,
        });
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongUsernameDescription,
          actionText: constants.wrongUsernameTitle,
        })
      }
      this.props.loader(false)
    }

    if (prevProps.postLottoCalculateIsFetching !== this.props.postLottoCalculateIsFetching
      && !this.props.postLottoCalculateIsFetching) {
      if (this.props.postLottoCalculateCode === responseCode.OK) {
        this.setState({onCalculate: false})
        Modal.success.show({
          action: Modal.success.hide,
          description: constants.calculateSuccess,
        });
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongCalculateDescription,
          actionText: constants.wrongCalculateTitle,
        })
      }
      this.props.loader(false)
    }
  }

  renderLottoList = () => map(this.props.lottoSchedule, (lotto, index) => {
    const subtitle = (lotto.code === 'LOTTER_YEGEE') ? constants.openedYeegeLabel : constants.closedLabel
    const backgroundColor = (lotto.code === this.state.onSettingLotto) ? colors.SECONDARY_PURPLE : colors.SECONDARY_DARK
    const description = (lotto.code === 'LOTTER_YEGEE')
      ? `88 ${constants.round}`
      : isEmpty(lotto.endTime)
        ? '-'
        : moment(lotto.endTime).add(-7, 'hour').add(543,'years').format('DD MMM YY')
    const isCountingDown = (lotto.code === 'LOTTER_YEGEE')
      ? false
      : (lotto.status === 'OPEN')
    const FlagIcon = LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon

    return (
      <div className="lotto-body-item" key={`lotto-${lotto.code}-${index}`}>
        <LottoActionCard
          id={`lotto-${lotto.code}`}
          onClick={() => this.renderBetDetail(lotto)}
          title={LOTTO_SLUG_NAME[lotto.code]}
          subTitle={subtitle}
          icon={FlagIcon}
          backgroundColor={backgroundColor}
          status={lotto.status}
          isCountingdown={isCountingDown}
          closedStatusText={constants.closedStatusLabel}
          waitingStatusText={constants.waitingStatusLabel}
          openedStatusText={constants.openedYeegeStatusLabel}
          description={description}
          expire={lotto.endTime}
        />
      </div>
    )
  })

  renderBetDetail = (lotto: ILottoSchedule) => {
    const lottoList = find(this.props.lottoList, (lotter, index) => {
      return lotter.code === LOTTO_SLUG_TO_TYPE[lotto.code]
    })
    const dataForm: ILottoDigits =
    {
      twoDown: String(find(lottoList?.lotto, (lotto) => { return lotto.type === 'TWO_DOWN' })?.numbers),
      threeUp: String(find(lottoList?.lotto, (lotto) => { return lotto.type === 'THREE_UP' })?.numbers),
      fourSuit: lottoList?.code === 'LAO_SUITE'
        ? String(find(lottoList?.lotto, (lotto) => { return lotto.type === 'FOUR_SUITE' })?.numbers)
        : '',
      timeStamp: moment().unix(),
    }
    moment.locale('th')
    this.setState({
      isCountDown: false,
      endDateConf: lotto.endTime,
      updateAtResult: lottoList?.updatedAt,
      onSubmit: false,
      onRefund: false,
      onCalculate: false,
      id: lotto.id,
      status: lotto.status,
      onSettingLotto: lotto.code,
      title: LOTTO_SLUG_NAME[lotto.code],
      startTime: lotto.startTime !== null ? moment(lotto.startTime).add(-7, 'hour').add(543,'years').format('Do MMM YY k:mm') : '-',
      endTime: lotto.endTime !== null ? moment(lotto.endTime).add(-7, 'hour').add(543,'years').format('Do MMM YY k:mm') : '-',
      dateStartWithMoment: moment(lotto.startTime).utcOffset(0).format('YYYY-MM-DD HH:mm'),
      dateEndWithMoment: moment(lotto.endTime).utcOffset(0).format('YYYY-MM-DD HH:mm'),
      checked: lotto.mode === 'MANUAL' ? true : false,
      buttonControl: lotto.mode,
      initialFormValue: dataForm,
      icon: LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon,
    })
  }

  handleDateChangeStart = (date: Date) => {
    // console.log(date)
    this.setState({ dateStart: date, dateStartWithMoment: moment(date).format('YYYY-MM-DD HH:mm') }
    )
  }

  onChangeDateStart = () => {
    if (!isNaN(this.state.dateStart.getDate())) {
      this.props.loader(true)
      this.props.putLottoStatus({
        id: this.state.id,
        status: this.state.status,
        start_time: moment(this.state.dateStart).format('YYYY-MM-DD HH:mm:ssZ'),
        end_time: moment(this.state.dateEndWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
      })
    }
  }


  handleDateChangeEnd = (date: Date) => {
    this.setState({ dateEnd: date, dateEndWithMoment: moment(date).format('YYYY-MM-DD HH:mm') }
    )
  }

  onChangeDateEnd = () => {
    if (!isNaN(this.state.dateEnd.getDate())) {
      this.props.loader(true)
      this.props.putLottoStatus({
        id: this.state.id,
        status: this.state.status,
        start_time: moment(this.state.dateStartWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
        end_time: moment(this.state.dateEnd).format('YYYY-MM-DD HH:mm:ssZ'),
      })
    }
  }



  onSubmit = (values: ILottoDigits) => {
    // this.props.postUser(data)
  }

  updateSearch(value: any) {
    this.setState({ search: value.substr(0, 20) })
  }

  controlLotto = (event: any) => {
    if (event) {
      this.props.loader(true)
      this.props.putLottoMode({ id: this.state.id, mode: ESTATUS.MANUAL })
      this.setState({ checked: event })
      this.setState({ buttonControl: ESTATUS.MANUAL })
    }
    else {
      this.props.loader(true)
      this.props.putLottoMode({ id: this.state.id, mode: ESTATUS.AUTOMATIC })
      this.setState({ checked: event })
      this.setState({ buttonControl: ESTATUS.AUTOMATIC })
    }
  }



  onActionLottoResult = (field: ILottoListActionButton, props: FormikProps<ILottoDigits>) => {
    const arr: any = []
    if (props.values.twoDown && props.values.threeUp && field.onSettingLotto !== 'LOTTER_LAO_SUITE') {
      arr.push({ value: props.values.twoDown, value_type: 'TWO_DOWN', slug: field.onSettingLotto })
      arr.push({ value: props.values.threeUp, value_type: 'THREE_UP', slug: field.onSettingLotto })
    }
    if (props.values.fourSuit && field.onSettingLotto === 'LOTTER_LAO_SUITE') {
      arr.push({ value: props.values.fourSuit, value_type: 'FOUR_SUITE', slug: field.onSettingLotto })
    }
    Modal.accepts.show({
      action: () => {
        this.props.postLottoResult(arr)
        Modal.accepts.hide()
      },
      lottoCode: field.onSettingLotto,
      title: constants.submit,
      threeUp: props.values.threeUp,
      twoDown: props.values.twoDown,
      fourSuit: props.values.fourSuit,
      isconfirm: true,
      cancelAction: () => {
        Modal.accepts.hide()
      },
    })
  }

  ActionOpenBet = (value: any) => {
    Modal.accepts.show({
      action: () => {
        this.props.putLottoStatus({
          id: this.state.id,
          status: ESTATUS.OPEN,
          start_time: moment(this.state.dateStartWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
          end_time: moment(this.state.dateEndWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
        })
        Modal.accepts.hide()
      },
      title: constants.openBet,
      isconfirm: false,
      cancelAction: () => {
        Modal.accepts.hide()
      },
    })
  }

  ActionCloseBet = (value: any) => {
    Modal.accepts.show({
      action: () => {
        this.props.putLottoStatus({
          id: this.state.id,
          status: ESTATUS.CLOSE,
          start_time: moment(this.state.dateStartWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
          end_time: moment(this.state.dateEndWithMoment).format('YYYY-MM-DD HH:mm:ssZ'),
        })
        Modal.accepts.hide()
      },
      title: constants.closedStatusLabel,
      isconfirm: false,
      cancelAction: () => {
        Modal.accepts.hide()
      },
    })
  }

  ActionCalculate = (value: TLottoSlug, props: FormikProps<ILottoDigits>) => {
    Modal.accepts.show({
      action: () => {
        this.props.postLottoCalculate({
          slug: value,
        })
        Modal.accepts.hide()
      },
      lottoCode: value,
      title: constants.calculate,
      threeUp: props.values.threeUp,
      twoDown: props.values.twoDown,
      fourSuit: props.values.fourSuit,
      isconfirm: true,
      cancelAction: () => {
        Modal.accepts.hide()
      },
    })
  }

  actionRefund = (value: any) => {
    Modal.accepts.show({
      action: () => {
        this.props.postLottoRefund({ slug: value })
        Modal.accepts.hide()
      },
      title: constants.refund,
      isconfirm: false,
      cancelAction: () => {
        Modal.accepts.hide()
      },
    })
  }


  handleActionButtonRefund = (props: ILottoListActionButton) => {
    const onActionClick = () => {
      if (props.text === constants.refund) {
        return this.actionRefund(props.onSettingLotto)
      }
    }

    if (props.buttonControl === ESTATUS.AUTOMATIC || props.onSettingLotto === 'LOTTER_YEGEE' || this.state.status === ESTATUS.OPEN) {
      return (
        <div>
          <ButtonCountDown
            startIcon={props.icon}
            text={props.text}
            fullWidth={true}
            disabled={true}
          />
        </div>
      )
    }
    else {
      const isCurrentTimeResult =  moment(this.state.endDateConf).isBefore(moment(moment(this.state.updateAtResult).format('YYYY-MM-DDTHH:mm:ss[Z]'))) 
      const isBefor = isEmpty( this.state.updateAtResult) ? 0 :(moment(this.state.updateAtResult).add(3, 'm').diff(moment()))
      const minute = isBefor > 0 ? moment(this.state.updateAtResult).add({ ms: 10, m: 3 }).valueOf() : 0

      if(isBefor > 0 && !this.state.isCountDown){
        this.setState({isCountDown: true})
      }
      return (
        <div>
          <ButtonCountDown
            allowColor={true}
            fontColor={colors.PRIMARY_DARK}
            color={'yellow'}
            time={minute}
            onClick={onActionClick}
            startIcon={props.icon}
            text={isBefor > 0 ? `${props.text}ได้ในอีก` : props.text}
            fullWidth={true}
            disabled={isCurrentTimeResult && this.state.isCountDown ? true : isCurrentTimeResult ? false: true}
            onCountdownComplete={() => {
              this.setState({ isCountDown: false })
            }}
          />
        </div>
      )
    }
  }

  handleActionButtonCalculate = (props: ILottoListActionButton, FormikProps: FormikProps<ILottoDigits>) => {
    if (props.buttonControl === ESTATUS.AUTOMATIC || props.onSettingLotto === 'LOTTER_YEGEE'  ||  this.state.status === ESTATUS.OPEN ) {
      return (
        <div>
          <ButtonCountDown
            startIcon={props.icon}
            text={props.text}
            fullWidth={true}
            disabled={true}
          />
        </div>
      )
    }
    else {
      const isCurrentTimeResult =  moment(this.state.endDateConf).isBefore(moment(moment(this.state.updateAtResult).format('YYYY-MM-DDTHH:mm:ss[Z]'))) 
      const isBefor = isEmpty( this.state.updateAtResult) ? 0 :(moment(this.state.updateAtResult).add(3, 'm').diff(moment()))
      const minute = isBefor > 0 ? moment(this.state.updateAtResult).add({ ms: 10, m: 3 }).valueOf() : 0
      const onActionClick = () => {
            if (props.text === constants.calculate) {
              return this.ActionCalculate(props.onSettingLotto as TLottoSlug, FormikProps)
            }
          }
          if(isBefor > 0 && !this.state.isCountDown){
            this.setState({isCountDown: true})
          }
        return (
          <div>
           <ButtonCountDown
            allowColor={true}
            fontColor={colors.PRIMARY_TEXT}
            color={props.color}
            time={minute}
            onClick={onActionClick}
            startIcon={props.icon}
            text={isBefor > 0 ? `${props.text}ได้ในอีก` : props.text}
            fullWidth={true}
            disabled={isCurrentTimeResult && this.state.isCountDown ? true : isCurrentTimeResult ? false: true}
            onCountdownComplete={() => {
              this.setState({ isCountDown: false })
            }}
          />
          </div>
        )
    }
  }

  handleActionButton = (props: ILottoListActionButton) => {
    if (props.buttonControl === ESTATUS.AUTOMATIC || props.onSettingLotto === 'LOTTER_YEGEE') {
      return (
        <div>
          <ButtonCountDown
            startIcon={props.icon}
            text={props.text}
            fullWidth={true}
            disabled={true}
          />
        </div>
      )
    }
    else if (props.buttonControl === ESTATUS.MANUAL) {
      if ((
        (props.status === ESTATUS.CLOSE || props.status === ESTATUS.WAIT)
        && (props.text !== constants.openBet)
      )
        || ((props.status === ESTATUS.OPEN)
          && props.text !== constants.closedStatusLabel)
      ) {
        return (
          <div>
            <ButtonCountDown
              startIcon={props.icon}
              text={props.text}
              fullWidth={true}
              disabled={true}
            />
          </div>
        )
      }
      else {
        const onActionClick = () => {
          if (props.text === constants.refund) {
            return this.actionRefund(props.onSettingLotto)
          }
          else if (props.text === constants.openBet) {
            return this.ActionOpenBet(props.onSettingLotto)
          }
          else if (props.text === constants.closedStatusLabel) {
            return this.ActionCloseBet(props.onSettingLotto)
          }
        }
        return (
          <div>
            <ButtonCountDown
              startIcon={props.icon}
              text={props.text}
              fullWidth={true}
              color={props.color}
              onClick={onActionClick}
            />
          </div>
        )
      }
    }
    else {
      return <></>
    }
  }

  handleOnSubmitButton = (field: ILottoListActionButton, props: FormikProps<ILottoDigits>) => {
    if ((props.values.threeUp && props.values.twoDown)
      && (props.errors.twoDown === undefined && props.errors.threeUp === undefined)
      && (ESTATUS.MANUAL === field.buttonControl) && (field.onSettingLotto !== 'LOTTER_LAO_SUITE')
      && (this.state.onSettingLotto !== 'LOTTER_YEGEE')
      && (!this.state.onSubmit && (this.state.status !== ESTATUS.OPEN))
      ) {
      return (
        <div>
          <ButtonCountDown
            iconStyles={{ position: "absolute", bottom: 10 }}
            startIcon={GavelIcon}
            text={field.text}
            fullWidth={true}
            color="green"
            type="submit"
            onClick={() => this.onActionLottoResult(field, props)}
          />
        </div>
      )
    }
    else if ((props.values.fourSuit && props.errors.fourSuit === undefined
      && field.onSettingLotto === 'LOTTER_LAO_SUITE')
      && (this.state.status !== ESTATUS.OPEN)) {
      return (
        <div>
          <ButtonCountDown
            iconStyles={{ position: "absolute", bottom: 10 }}
            startIcon={GavelIcon}
            text={field.text}
            fullWidth={true}
            color="green"
            type="submit"
            onClick={() => this.onActionLottoResult(field, props)}
          />
        </div>
      )
    }
    else {
      return (
        <div>
          <ButtonCountDown
            iconStyles={{ position: "absolute", bottom: 10 }}
            startIcon={GavelIcon}
            text={field.text}
            fullWidth={true}
            disabled={true}
          />
        </div>
      )
    }
  }

  handleActionClear = (props: FormikProps<ILottoDigits>) => {
    props.setFieldValue('threeUp', '')
    props.setFieldValue('twoDown', '')
    props.setFieldValue('fourSuit', '')
    props.setFieldTouched('twoDown', false)
    props.setFieldTouched('threeUp', false)
    props.setFieldTouched('fourSuit', false)
  }

  handleButtonActionClear = (props: FormikProps<ILottoDigits>) => {
    if ((props.values.threeUp || props.values.twoDown) && (this.state.onSettingLotto !== 'LOTTER_YEGEE')) {
      return (
        <Button
          text="ล้างข้อมูล"
          fullWidth={true}
          onClick={() => this.handleActionClear(props)}
        />
      )
    }
    else if (props.values.fourSuit && this.state.onSettingLotto !== 'LOTTER_YEGEE') {
      return (
        <Button
          text="ล้างข้อมูล"
          fullWidth={true}
          onClick={() => this.handleActionClear(props)}
        />
      )
    }
    else {
      return (
        <Button
          text="ล้างข้อมูล"
          fullWidth={true}
          disabled={true}
        />
      )
    }
  }
  createActionElement = (props: FormikProps<ILottoDigits>) => {
    const lottoList = find(this.props.lottoList, (lotto, index) => {
      return lotto.code === LOTTO_SLUG_TO_TYPE[this.state.onSettingLotto]
    })
    switch (get(lottoList, 'code')) {
      case 'GOVN':
      case 'GSB':
      case 'BAAC':
      case 'TH_SHARE_MORNING':
      case 'TH_SHARE_MIDDAY':
      case 'TH_SHARE_AFTERNOON':
      case 'TH_SHARE_EVENING':
      case 'NAT_SHARE_DOWNJON':
      case 'NAT_SHARE_EGYPT':
      case 'NAT_SHARE_GERMANY':
      case 'NAT_SHARE_NIKAII_MORNING':
      case 'NAT_SHARE_NIKAII_AFTERNOON':
      case 'NAT_SHARE_CHINA_MORNING':
      case 'NAT_SHARE_CHINA_AFTERNOON':
      case 'NAT_SHARE_TAIWAN':
      case 'NAT_SHARE_KOREA':
      case 'NAT_SHARE_SINGAPORE':
      case 'NAT_SHARE_INDIA':
      case 'NAT_SHARE_HANOI_SPECIAL':
      case 'NAT_SHARE_MALAY':
      case 'NAT_SHARE_VIETNAM_HANOI':
      case 'NAT_SHARE_VIETNAM_HANOI_VIP':
      case 'NAT_SHARE_HANOI_4D':
      case 'NAT_SHARE_RUSSIA':
      case 'NAT_SHARE_ENGLISH':
      case 'NAT_SHARE_HUNGSENG_MORNING':
      case 'NAT_SHARE_HUNGSENG_AFTERNOON':
      case 'NAT_SHARE_LAO':
      case 'LAO_HD':
      case 'LAO_VIP':
      case 'HANOI_TV':
      case 'LAO_STAR':
      case 'HANOI_RED_CROSS':
      case 'HANOI_SAMAKKEE':
      case 'HANOI_PHATTANA':
      case 'LAO_SAMAKKEE':
      case 'LAO_ASEAN':
      case 'LAO_STAR_VIP':
      case 'ENGLISH_VIP':
      case 'GERMANY_VIP':
      case 'LAO_RED_CROSS':
      case 'RUSSIA_VIP':
      case 'DOWNJON_VIP':
      case 'HANOI_ASEAN':
      case 'LAO_TV':
      case 'HANOI_HD':
      case 'HANOI_STAR':
      case 'HANOI_EXTRA':
      case 'MALAY':
      case 'NAT_SHARE_HUNGSENG_MORNING_VIP':
      case 'NAT_SHARE_HUNGSENG_AFTERNOON_VIP':
      case 'NAT_SHARE_CHINA_MORNING_VIP':
      case 'NAT_SHARE_CHINA_AFTERNOON_VIP':
      case 'NAT_SHARE_SINGAPORE_VIP':
      case 'NAT_SHARE_KOREA_VIP':
      case 'NAT_SHARE_TAIWAN_VIP':
      case 'NAT_SHARE_NIKAII_MORNING_VIP':
      case 'NAT_SHARE_NIKAII_AFTERNOON_VIP':
      case 'HANOI_CHINESE_NEW_YEAR':
      case 'NAT_SHARE_DOWNJON_STAR':

        return (
          <>
            <Grid item xs={6}>
              <TextField
                disabled={
                  this.state.buttonControl === ESTATUS.AUTOMATIC || this.state.status === ESTATUS.OPEN
                    ? true : false
                }
                onBlur={props.handleBlur}
                name="threeUp"
                label="3 ตัวบน"
                maxLength={3}
                fullWidth={true}
                onClick={() => {
                  if (this.state.status !== ESTATUS.OPEN) {
                    props.setFieldValue('threeUp', '')
                    props.setValues({
                      ...props.values,
                      threeUp: '',
                    })
                    props.setErrors({
                      ...props.errors,
                      threeUp: '',
                    })
                    props.setTouched({
                      ...props.touched,
                      threeUp: false,
                    })
                  }

                }
                }
                onChange={props.handleChange}
                placeholder={constants.placeholderInput(constants.threeUp)}
                value={props.values.threeUp}
                error={!!props.errors.threeUp && props.touched.threeUp}
                helperText={!!props.errors.threeUp && props.touched.threeUp ? `${props.errors.threeUp}` : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={
                  this.state.buttonControl === ESTATUS.AUTOMATIC || this.state.status === ESTATUS.OPEN
                    ? true : false
                }
                onBlur={props.handleBlur}
                name="twoDown"
                label="2 ตัวล่าง"
                maxLength={2}
                fullWidth={true}
                placeholder={constants.placeholderInput(constants.twoDown)}
                onClick={() => {
                  if (this.state.status !== ESTATUS.OPEN) {
                    props.setFieldValue('twoDown', '')
                    props.setValues({
                      ...props.values,
                      twoDown: '',
                    })
                    props.setErrors({
                      ...props.errors,
                      twoDown: '',
                    })
                    props.setTouched({
                      ...props.touched,
                      twoDown: false,
                    })
                  }
                }
                }
                // onChange={(e)=>( props.values.twoDown.length <= 2)
                //   ? props.setFieldValue('twoDown',e.target.value)
                //   : {noop}
                // }
                onChange={props.handleChange}
                value={props.values.twoDown}
                error={!!props.errors.twoDown && props.touched.twoDown}
                helperText={!!props.errors.twoDown && props.touched.twoDown ? `${props.errors.twoDown}` : ''}
              />
            </Grid>
          </>
        )
      case 'LAO_SUITE':
        return (
          <Grid item xs={12}>
            <TextField
              disabled={
                this.state.buttonControl === ESTATUS.AUTOMATIC || this.state.status === ESTATUS.OPEN
                  ? true : false
              }
              maxLength={4}
              onBlur={props.handleBlur}
              name="fourSuit"
              label="เลขชุด 4 ตัว"
              fullWidth={true}
              placeholder={constants.placeholderInput(constants.fourSuit)}
              onClick={() => {
                if (this.state.status !== ESTATUS.OPEN) {
                  props.setFieldValue('fourSuit', '')
                  props.setValues({
                    ...props.values,
                    fourSuit: '',
                  })
                  props.setErrors({
                    ...props.errors,
                    fourSuit: '',
                  })
                  props.setTouched({
                    ...props.touched,
                    fourSuit: false,
                  })
                }
              }
              }
              onChange={props.handleChange}
              value={props.values.fourSuit}
              error={!!props.errors.fourSuit && props.touched.fourSuit}
              helperText={!!props.errors.fourSuit && props.touched.fourSuit ? `${props.errors.fourSuit}` : ''}
            />
          </Grid>
        )
      default:
        return <></>
    }
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.lottoLabel, active: true },
    ]
    const RenderLottoList = this.renderLottoList().filter(
      (Lotto) => {
        return Lotto.props.children.props.title.toLowerCase().indexOf(
          this.state.search.toLowerCase()) !== -1
      }
    )
    return (
      <div className="lotto-list-container primary-bg">
        <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
        <TitlePage title="ควบคุมหวย" />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="m3-b">
              <TextField
                placeholder='ค้นหาหวย'
                label="ค้นหา"
                startElement={<SearchIcon />}
                fullWidth={true}
                value={this.state.search}
                onChange={(e) => this.updateSearch(e.target.value)}
              />
            </div>
            <div className="lotto-body">
              <SimpleBar style={{ height: '100%' }}>
                {RenderLottoList}
              </SimpleBar>
            </div>

          </Grid>
          <Grid item xs={8}>
            <Formik
              initialValues={this.state.initialFormValue}
              validationSchema={scheme}
              enableReinitialize
              onSubmit={this.onSubmit}
            >
              {
                (
                  formikProps: FormikProps<ILottoDigits>
                ) => {
                  return (
                    // @ts-ignore
                    <Form>
                      <Paper className="paper-container secondary-dark">
                        <div className="paper-title">
                          <div className="lotto-action-text-wrapper">
                            <Grid container>
                              <Grid item xs={10} className='d-flex align-items-center'>
                                <ResponsiveIcon
                                  icon={this.state.icon}
                                  alt="flag"
                                  className="lotto-action-card-flag m2-r"
                                />
                                <div className='title-h3'>{this.state.title}</div>
                              </Grid>
                              <Grid item xs={2}>
                                {this.handleButtonActionClear({ ...formikProps })}
                              </Grid>
                            </Grid>
                          </div>
                        </div>

                        <Grid container spacing={3}>
                          <Grid item xs={12} style={{paddingBottom: 0}}>
                            <div className="control-button">
                              <h6 className="primary-text">
                                ควบคุมมือ:
                              </h6>
                              <Switches
                                onChange={(e) => this.controlLotto(e.target.checked)}
                                color="default"
                                checked={this.state.checked}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6}>

                            <h6 className="primary-text">{constants.start}: {this.state.startTime}</h6>
                          </Grid>
                          <Grid item xs={6}>
                            <h6 className="primary-text">{constants.end}: {this.state.endTime} </h6>
                          </Grid>
                          <Grid item xs={6} >
                            {
                              this.handleActionButton({
                                icon: AddAlarm,
                                buttonControl: this.state.buttonControl,
                                text: 'เปิดรับแทง',
                                color: 'green',
                                status: this.state.status,
                                onSettingLotto: this.state.onSettingLotto,
                              })
                            }
                          </Grid>
                          <Grid item xs={6} >
                            {
                              this.handleActionButton({
                                icon: AlarmOff,
                                buttonControl: this.state.buttonControl,
                                text: 'ปิดรับแทง',
                                status: this.state.status,
                                onSettingLotto: this.state.onSettingLotto,
                              })
                            }
                          </Grid>
                          <Grid item xs={6}>
                            {
                              this.handleActionButtonRefund({
                                icon: PaymentIcon,
                                buttonControl: this.state.buttonControl,
                                text: 'คืนเงินลูกค้า',
                                color: 'yellow',
                                status: this.state.status,
                                onSettingLotto: this.state.onSettingLotto,
                              })
                            }
                          </Grid>
                          <Grid item xs={6}>
                            {
                              this.handleActionButtonCalculate({
                                icon: PaymentIcon,
                                buttonControl: this.state.buttonControl,
                                text: 'จ่ายผู้ชนะ', color: 'red',
                                status: this.state.status,
                                onSettingLotto: this.state.onSettingLotto,
                              }, { ...formikProps })
                            }
                          </Grid>
                          <Grid item xs={6} style={{ paddingTop: 0 }}>
                            <div className="m2-t">
                              <DateTime
                                // onClose={this.onChangeDateStart}
                                id={'date-start'}
                                position='end'
                                disabled={
                                  this.state.buttonControl === ESTATUS.AUTOMATIC || this.state.status === ESTATUS.OPEN
                                    ? true : false
                                }
                                disablePast={true}
                                label="เวลาเปิด"
                                value={this.state.dateStartWithMoment}
                                onChange={this.handleDateChangeStart}
                                onClick={(e) => {
                                  if (this.state.status !== ESTATUS.OPEN) {
                                    formikProps.setFieldValue('threeUp', '')
                                    formikProps.setFieldValue('twoDown', '')
                                  }
                                }}
                                fullWidth={true}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ paddingTop: 0 }}>
                            <div className="m2-t">
                              <DateTime
                              id={'date-end'}
                                disablePast={true}
                                position='end'
                                disabled={
                                  this.state.buttonControl === ESTATUS.AUTOMATIC || this.state.status === ESTATUS.OPEN
                                    ? true : false
                                }
                                label="เวลาปิด"
                                value={this.state.dateEndWithMoment}
                                onChange={this.handleDateChangeEnd}
                                onClick={(e) => {
                                  if (this.state.status !== ESTATUS.OPEN) {
                                    formikProps.setFieldValue('threeUp', '')
                                    formikProps.setFieldValue('twoDown', '')
                                  }
                                }}
                                fullWidth={true}
                              />
                            </div>
                          </Grid>
                          {this.createActionElement({ ...formikProps })}
                          <Grid item xs={12}>
                            <div className="paper-bottom">
                              {this.handleOnSubmitButton({
                                buttonControl: this.state.buttonControl,
                                text: 'ออกผล',
                                onSettingLotto: this.state.onSettingLotto,
                              },
                                { ...formikProps })}
                            </div>
                          </Grid>
                        </Grid>

                      </Paper>
                    </Form>
                  )
                }
              }
            </Formik>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default LottoListContainer