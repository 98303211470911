import React, { Component } from "react";
import { noop, isEqual } from "lodash";
import moment from "moment";
import "./depositTransaction.style.scss";
import { responseCode } from "constants/response";
import { RouteComponentProps } from "react-router-dom";
import { number, transformer, date } from "utils";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TablePagination,
} from "@material-ui/core";
import { get } from "lodash";
import {
  Chipper,
  Button,
  ModalDeposit,
  TextField,
  Select,
  DateTime,
  TitlePage,
  Breadcrumb,
} from "components";
import SearchIcon from "@material-ui/icons/Search";

const constants = {
  confirmDeposit: "ยืนยันอนุมัติรายการฝาก",
  notDeposit: "ไม่อนุมัติรายการฝาก",
  approveSureDeposit: "คุณแน่ใจใช่หรือไม่? อนุมัติรายการฝาก",
  disApprovalSure: "คุณแน่ใจใช่หรือไม่? ไม่อนุมัติรายการฝาก",
  approval: "อนุมัติ",
  disApproval: "ไม่อนุมัติ",
  reserve: "กำลังดำเนินการอยู่...",
  depositLabel: "จัดการรายการฝาก",
  systemLabel: "จัดการระบบ",
  check: 'ตรวจสอบ',
};

enum ESTATUS {
  SUCCESS = "SUCCESS",
  WAIT = "WAIT",
  REJECT = "REJECT",
  FAIL = "FAIL",
  RESERVE = "RESERVE",
}

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ITransactionProps & ITransactionActionProps = {
  connectFinanceDepositSocket() {
    noop();
  },
  getCreditInfoList() {
    noop();
  },
  connectFinanceWithdrawSocket() {
    noop();
  },
  unlistenTransactionAllSocket() {
    noop();
  },
  clearTransactionAll() {
    noop();
  },
  clearCreditInfoList() {
    noop();
  },
  postReserveTransaction() {
    noop();
  },
  cancelReserveTransaction() {
    noop();
  },
  forceCancelReserveTransaction() {
    noop();
  },
  getTransaction() {
    noop();
  },
  getTransactionCode: 0,
  getTransactionError: "",
  getTransactionIsFetching: false,
  getTransactionResult: [],
  getTransactionLimit: 10,
  getTransactionPage: 0,
  getTransactionTotal: 0,
  getTransactionPageTotal: 0,
  putTransaction() {
    noop();
  },
  putTransactionCode: 0,
  putTransactionError: "",
  putTransactionIsFetching: false,
  putTransactionResult: [],

  postReserveTransactionCode: 0,
  postReserveTransactionError: "",
  postReserveTransactionIsFetching: false,
  postReserveTransactionResult: [],

  getWebBankActive() {
    noop();
  },
  getWebBankActiveCode: 0,
  getWebBankActiveError: "",
  getWebBankActiveIsFetching: false,
  getWebBankActiveResult: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  getCreditInfoListCode: 0,
  getCreditInfoListError: "",
  getCreditInfoListIsFetching: false,
  creditInfo: [],
  creditInfoPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  loader() {
    noop();
  },
};

const columns: ITransactionColumn<
  | "id"
  | /*'type'*/ "money"
  | "userTxTime"
  | "status"
  /*'action' |*/
  | "userName"
  | "userBank"
  | "bankName"
  | "approval"
  | "walletBank"
>[] = [
    { id: "id", label: "เลขที่", minWidth: 50, align: "center" },
    { id: "userName", label: "ชื่อผู้ใช้", minWidth: 50, align: "left" },
    { id: "userBank", label: "ชื่อผู้โอน/เวลาโอน", minWidth: 50, align: "left" },
    {
      id: "approval",
      label: "ชื่อผู้อนุมัติ/เวลา",
      minWidth: 100,
      align: "left",
    },
    { id: "bankName", label: "บัญชีที่โอน", minWidth: 50, align: "left" },
    { id: "walletBank", label: "บัญชีระบบ", minWidth: 50, align: "left" },
    {
      id: "money",
      label: "จำนวนเงิน",
      minWidth: 50,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    { id: "status", label: "สถานะ", minWidth: 50, align: "left" },
  ];

class DepositTransactionContainer extends Component<
  ITransactionProps &
  ITransactionActionProps &
  DefaultProps &
  RouteComponentProps,
  ITransactionContainerState
> {
  static defaultProps = defaultProps;

  constructor(
    props: ITransactionProps &
      ITransactionActionProps &
      RouteComponentProps &
      DefaultProps
  ) {
    super(props);
    this.state = {
      currentPage: 0,
      tableRows: [],
      dataType: "DEPOSIT",
      dataStatus: "WAIT",
      limit: 10,
      page: 0,
      total: 0,
      search: "",
      date: "",
      selectedDate: null,
      queryTimeout: setTimeout(() => {
        noop();
      }, 0),
      dateTimeout: setTimeout(() => {
        noop();
      }, 0),
      isShowSnack: false,
      hasMore: true,
      isFirst: true,
      creditName: "",
      getCreditInfo: {
        ID: "",
        isFirst: true,
        money: "",
      },
    };
  }

  getDepositTxAndSubscribe() {
    this.props.loader(true);
    this.props.getTransaction({
      type: this.state.dataType,
      status: this.state.dataStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      date: this.state.date,
    });
    this.props.connectFinanceDepositSocket({
      type: this.state.dataType,
      status: this.state.dataStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      date: this.state.date,
    });
  }
  componentDidMount() {
    this.getDepositTxAndSubscribe();
  }

  componentDidUpdate(prevProps: ITransactionProps) {
    if (
      prevProps.getTransactionIsFetching !==
      this.props.getTransactionIsFetching &&
      !this.props.getTransactionIsFetching
    ) {
      if (this.props.getTransactionCode === responseCode.OK) {
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          page: this.props.getTransactionPage,
          total: this.props.getTransactionTotal,
        });
      } else {
        this.setState({ tableRows: [] });
      }
      this.props.loader(false);
    }

    if (
      !isEqual(this.props.getTransactionResult, prevProps.getTransactionResult)
    ) {
      if (
        this.props.getTransactionResult.length === 0 &&
        this.props.getTransactionPage > 0
      ) {
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          total: this.props.getTransactionTotal,
        });
        this.handleChangePage(null, this.props.getTransactionPage - 1);
      } else {
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          page: this.props.getTransactionPage,
          total: this.props.getTransactionTotal,
        });
      }
      this.props.loader(false);
    }

    if (
      prevProps.putTransactionIsFetching !==
      this.props.putTransactionIsFetching &&
      !this.props.putTransactionIsFetching
    ) {
      this.props.loader(false);
      this.props.loader(true);
      this.getDepositTxAndSubscribe();
    }
  }

  componentWillUnmount() {
    // this.props.clearTransactionAll()
    this.props.unlistenTransactionAllSocket();
  }

  createData(txData: IWalletTransactionPopuate): ITransactionRow {
    return {
      id: String(txData.tag),
      type: txData.type,
      money: `${transformer.typeFormat(txData.money)}`,
      userName: get(txData.userId, "username", ""),
      userBank: get(txData.userBankId, "name", ""),
      bankName: get(txData.userBankId, "type", ""),
      bankAccountNo: number.formatBankNumber(
        get(txData.userBankId, "number", ""),
        get(txData.userBankId, "type", "")
      ),
      walletBank:
        txData.webBankId === null || txData.webBankId === undefined
          ? "-"
          : txData.webBankId.name,
      walletBankNo:
        txData.webBankId === null || txData.webBankId === undefined
          ? "-"
          : number.formatBankNumber(
            get(txData.webBankId, "number", ""),
            get(txData.webBankId, "type", "")
          ),
      userTxTime:
        txData.userTxTime === null || txData.userTxTime === undefined
          ? "-"
          : `${date.FormatDate(txData.userTxTime, "Do MMM YY | k:mm น.")}`,
      status: this.createActionElement(txData),
      approveUserId:
        txData.approveUserId === null || txData.approveUserId === undefined
          ? "-"
          : txData.approveUserId.username,
      approval:
        txData.updatedAt === null || txData.updatedAt === undefined
          ? "-"
          : `${date.FormatDate(txData.updatedAt, "Do MMM YY | k:mm น.")}`,
    };
  }

  transformType2Th(type: string) {
    switch (type) {
      case "DEPOSIT":
        return "ฝากเงิน";
      case "WITHDRAW":
        return "ถอนเงิน";
      default:
        return "ผิดพลาด";
    }
  }

  createActionElement(txData: IWalletTransactionPopuate): JSX.Element {
    switch (txData.status) {
      case ESTATUS.FAIL:
        return (
          <div className="d-flex align-items-center">
            <Chipper label={constants.disApproval} color="red" />
            <label className="ml-4">{txData.description}</label>
          </div>
        );
      case ESTATUS.SUCCESS:
        return (
          <>
            <div className="d-flex align-items-center">
              <Chipper label={constants.approval} color="green" />
              <div className="ml-4">
                <div>{txData.webBankId.type}</div>
                <div>
                  {number.formatBankNumber(
                    get(txData.webBankId, "number", ""),
                    txData.webBankId.type
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case ESTATUS.RESERVE:
        return (
          <div className="button-container">
            <Button
              text={`${get(txData.approveUserId, "username", "")} ${constants.reserve
                }`}
              theme="outline"
              onClick={() => this.handleforceCancelReserveWithDraw(txData)}
            />
          </div>
        );
      case ESTATUS.WAIT:
        return (
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className="button-container">
                  <Button
                    type="submit"
                    text={constants.disApproval}
                    theme="outline"
                    color="red"
                    onClick={() => this.handleOnUpdateTx(txData, ESTATUS.FAIL)}
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="button-container">
                  <Button
                    type="submit"
                    text={constants.approval}
                    color="green"
                    onClick={() =>
                      this.handleOnUpdateTx(txData, ESTATUS.SUCCESS)
                    }
                  />
                </div>
              </Grid>
              {
                (this.props.userMeResult.permission === 'SUPER_ADMIN'
                  || this.props.userMeResult.permission === 'STAFF'
                  || this.props.userMeResult.permission === 'ADMIN')
                && (
                  <Grid item>
                    <div className="button-container">
                      <Button
                        text={constants.check}
                        onClick={() => this.props.history.push(`/verify/?phoneNo=${txData.userId.phoneNumber}`)}
                      />
                    </div>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  }

  handleApproveDeposit = (status: "SUCCESS" | "FAIL", txId: number) => {
    const reqData = {
      status: status,
      wallet_tx_id: txId,
      user_tx_time: new Date().toDateString(),
    };
    this.props.loader(true);
    this.props.putTransaction(reqData);
    ModalDeposit.success.hide();
  };

  handleCancelDeposit = (
    description: string,
    status: "SUCCESS" | "FAIL",
    txId: number
  ) => {
    const reqData = {
      status: status,
      wallet_tx_id: txId,
      user_tx_time: new Date().toDateString(),
      description: description,
      web_bank_id: null,
    };
    this.props.loader(true);
    this.props.putTransaction(reqData);
    ModalDeposit.error.hide();
  };

  handleReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.postReserveTransaction({ wallet_tx_id: walletTxData.id });
  };
  handleCancelReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.cancelReserveTransaction({ wallet_tx_id: walletTxData.id });
  };
  handleforceCancelReserveWithDraw = (
    walletTxData: IWalletTransactionPopuate
  ) => {
    this.props.forceCancelReserveTransaction({ wallet_tx_id: walletTxData.id });
  };

  handleOnUpdateTx(txData: IWalletTransactionPopuate, status: ESTATUS) {
    let reqData: ITransactionPutData;
    switch (txData.type) {
      case "WITHDRAW":
        this.props.loader(true);
        reqData = {
          status: status,
          wallet_tx_id: txData.id,
        };
        this.props.putTransaction(reqData);
        break;
      case "DEPOSIT":
        reqData = {
          status: status,
          wallet_tx_id: txData.id,
          web_bank_id: 1,
          pay_slip_image: "image",
          user_tx_time: new Date().toDateString(),
        };
        if (status === "SUCCESS") {
          this.handleReserveWithDraw(txData);
          ModalDeposit.success.show({
            action: this.handleApproveDeposit,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(txData);
              ModalDeposit.success.hide();
            },
            txId: txData.id,
            txStatus: status,
            title: constants.confirmDeposit,
            subTitle: constants.approveSureDeposit,
            name: txData.userId.username,
            bank: txData.userBankId.type,
            accountNo: txData.userBankId.number,
            money: txData.money,
          });
        } else {
          this.handleReserveWithDraw(txData);
          ModalDeposit.error.show({
            action: this.handleCancelDeposit,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(txData);
              ModalDeposit.error.hide();
            },
            txId: txData.id,
            txStatus: status,
            title: constants.notDeposit,
            subTitle: constants.disApprovalSure,
            name: txData.userId.username,
            bank: txData.userBankId.type,
            accountNo: txData.userBankId.number,
            money: txData.money,
          });
        }
        break;
    }
  }

  handleTextAlign = (align: any) => {
    let alignType;
    switch (align) {
      case "center":
        alignType = "MuiTableCell-alignCenter";
        break;
      case "right":
        alignType = "MuiTableCell-alignRight";
        break;
      case "left":
        alignType = "MuiTableCell-alignLeft";
        break;
    }
    return alignType;
  };

  handleFilterStatus = (status: IWalletTransactionStatus) => {
    this.setState({ dataStatus: status }, () =>
      this.getDepositTxAndSubscribe()
    );
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        page: 0,
        limit: Number(event.target.value),
      },
      () => {
        this.getDepositTxAndSubscribe();
      }
    );
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    this.setState(
      {
        page: newPage,
      },
      () => {
        this.getDepositTxAndSubscribe();
      }
    );
  };

  handleQuerySearch = (value: string) => {
    this.setState({ search: value, page: 0 }, () => {
      clearTimeout(this.state.queryTimeout);
      this.setState({
        queryTimeout: setTimeout(() => {
          this.getDepositTxAndSubscribe();
        }, 2000),
      });
    });
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ selectedDate: date }, () => {
      clearTimeout(this.state.dateTimeout);
      this.setState({
        dateTimeout: setTimeout(() => {
          if (
            date != null &&
            !(moment(date).isValid())
          ) {
            const dateMoment = moment(date);
            this.setState({ date: dateMoment.format("DDMMYYYY") });
            this.getDepositTxAndSubscribe();
          } else {
            this.setState({ date: "" });
            this.getDepositTxAndSubscribe();
          }
        }, 2000),
      });
    });
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.depositLabel, active: true },
    ];
    const { tableRows, page, limit, total } = this.state;
    return (
      <div className="textfield-container">
        <Breadcrumb
          items={navigates}
          handleOnClickItem={this.handleOnClickBreadcrumb}
        />
        <TitlePage title="จัดการรายการฝาก" />
        <Paper className="secondary-dark" elevation={3}>
          <TableContainer>
            <Grid container className="table-title">
              <Grid item lg={6} md={4} sm={2}>
                <div>
                  <h5>รายการฝาก</h5>
                </div>
              </Grid>

              <Grid item lg={6} md={8} sm={10} className="Xs4">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div className="th-container">
                      <TextField
                        placeholder="ค้นหา"
                        label={this.state.search ? "ค้นหา" : ""}
                        startElement={<SearchIcon />}
                        value={this.state.search}
                        onChange={(e) => this.handleQuerySearch(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="th-container">
                      <DateTime
                        id="date"
                        placeHolder="วันที่"
                        label={this.state.selectedDate ? "วันที่" : ""}
                        value={this.state.selectedDate}
                        onChange={this.handleDateChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="th-container">
                      <Select
                        label="สถานะ"
                        value={this.state.dataStatus}
                        onChange={(e) =>
                          this.handleFilterStatus(e.target.value)
                        }
                        itemList={[
                          {
                            value: "WAIT",
                            element: <h6>รอตรวจสอบ</h6>,
                          },
                          {
                            value: "SUCCESS",
                            element: <h6>{constants.approval}</h6>,
                          },
                          {
                            value: "FAIL",
                            element: <h6>{constants.disApproval}</h6>,
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={this.handleTextAlign(column.align)}
                          >
                            <div className="secondary-text">
                              {column.id === "approval"
                                ? row.approveUserId
                                : ""}
                            </div>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                            <div className="secondary-text">
                              {column.id === "userBank" ? row.userTxTime : ""}
                            </div>
                            <div className="secondary-text">
                              {column.id === "bankName"
                                ? row.bankAccountNo
                                : ""}
                            </div>
                            <div className="secondary-text">
                              {column.id === "walletBank"
                                ? row.walletBankNo
                                : ""}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {tableRows.length === 0 && (
                  <TableRow hover role="checkbox">
                    <TableCell key={"no data"}>ไม่มีข้อมูล...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="primary-text"
            component="div"
            count={total}
            page={page}
            onChangePage={this.handleChangePage}
            rowsPerPage={limit}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="รายการ/หน้า:"
            labelDisplayedRows={() =>
              `${this.state.currentPage + 1}-${this.props.getTransactionPageTotal
              } หน้า`
            }
          />
        </Paper>
      </div>
    );
  }
}

export default DepositTransactionContainer;
