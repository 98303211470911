import React from 'react'
import { FormikProps, Form } from 'formik'
import {
  Button
} from 'components'
import './loginForm.style.scss'
import { TextField } from 'components';

const constants = {
  alinkButtonLogin: 'เข้าสู่ระบบ',
  placeholderUsername: 'กรุณากรอกชื่อผู้ใช้...',
  placeholderPassword: 'กรุณากรอกรหัสผ่าน...',
}

function LoginForm(props: FormikProps<ILogin> & ILoginFormProps) {


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = props
  return (
    <div>
      <Form autoComplete="off" translate={undefined}>
        <div className="login-from-container pb-4">
          <div className="rows">
            <TextField
              id="outlined-helperText"
              name="username"
              label="ชื่อผู้ใช้"
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username.toLowerCase()}
              placeholder={constants.placeholderUsername}
              error={!!errors.username && touched.username}
              helperText={!!errors.username && touched.username ? `${constants.placeholderUsername}` : ''}
            />
          </div>
          <div className="rows">
            <TextField
              id="outlined-helperText"
              name="password"
              type="password"
              label="รหัสผ่าน"
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              placeholder={constants.placeholderPassword}
              error={!!errors.password && touched.password}
              helperText={!!errors.password && touched.password ? `${constants.placeholderPassword}` : ''}
            />
          </div>
          <div className="rows">
            <Button
              text={constants.alinkButtonLogin}
              type="submit"
              fullWidth={true}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default LoginForm