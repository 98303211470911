import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
    catchError,
    exhaustMap,
    takeUntil,
    filter,
    map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
    fetchGetAffilate,
    fetchPutAffilate,
} from './services'
import actions from './actions'

const affilateEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getAffilateAction)),
        exhaustMap(_ =>
            from(fetchGetAffilate())
                .pipe(
                    map(actions.getAffilateSuccessAction),
                    catchError(error => of(actions.getAffilateFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getAffilateCancelAction))))
                ),
        )
    )

const affilatePutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.putAffilateAction)),
        exhaustMap(action =>
            from(fetchPutAffilate(action.payload))
                .pipe(
                    map(actions.putAffilateSuccessAction),
                    catchError(error => of(actions.putAffilateFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.putAffilateCancelAction))))
                ),
        )
    )

export default [
    affilateEpic,
    affilatePutEpic,
]
