import React, { SFC, useState, useEffect } from "react";
import { isNaN } from "lodash";
import { ResponsiveIcon, Badge } from "components";
import colors from "constants/colors";
import moment from "moment";
import { number, date } from "utils";
import "./lottoActionCard.style.scss";

const constants = {
  day: "วัน",
  timeups: "รอเปิด",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ILottoActionCard = {
  id: "",
  title: "",
  subTitle: "",
  isCountingdown: false,
  expire: "",
  status: "UNKNOWN",
  openedStatusText: "",
  waitingStatusText: "",
  closedStatusText: "",
  description: "",
  backgroundColor: colors.SECONDARY_DARK,
  icon: "",
};

const LottoActionCard: SFC<ILottoActionCard & DefaultProps> = (props) => {
  const {
    id,
    title,
    subTitle,
    isCountingdown,
    expire,
    status,
    openedStatusText,
    waitingStatusText,
    closedStatusText,
    backgroundColor,
    description,
    icon,
    onClick,
  } = props;

  let intervalId: NodeJS.Timeout | null = null;

  const [remain, setRemain] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const clearLocalInterval = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  };

  const countingdown = () => {
    clearLocalInterval();

    if (isCountingdown) {
      const expireMoment = date.calibratingTime(expire);
      intervalId = setInterval(() => {
        const diiferentSecond = expireMoment.diff(moment().local(), "seconds");
        const days = Math.floor(diiferentSecond / 86400);
        const hours = Math.floor((diiferentSecond / 3600) % 24);
        const minutes = Math.floor((diiferentSecond / 60) % 60);
        const seconds = Math.floor(diiferentSecond % 60);

        if (
          (hours <= 0 && minutes <= 0 && seconds <= 0) ||
          isNaN(hours) ||
          isNaN(minutes) ||
          isNaN(seconds)
        ) {
          setRemain({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          clearLocalInterval();
        } else {
          setRemain({ days, hours, minutes, seconds });
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (isCountingdown) {
      countingdown();
    }
    return clearLocalInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountingdown]);

  const BadgeComponent = ({ text }: { text: string }) => {
    if (status === "OPEN") {
      return (
        <Badge
          text={text}
          backgroundColor={colors.TERTIARY_GREEN}
          color={colors.PRIMARY_TEXT}
        />
      );
    } else if (status === "CLOSE") {
      return (
        <Badge
          text={text}
          backgroundColor={colors.TERTIART_RED}
          color={colors.PRIMARY_TEXT}
        />
      );
    } else if (status === "WAIT") {
      return (
        <Badge
          text={text}
          backgroundColor={colors.SECONDARY_PURPLE}
          color={colors.PRIMARY_TEXT}
        />
      );
    } else if (status === "UNKNOWN") {
      return (
        <Badge
          text={text}
          backgroundColor={colors.PRIMARY_DARK}
          color={colors.PRIMARY_TEXT}
        />
      );
    }
    return <></>;
  };

  const handleOnClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  const statusText = (): string => {
    if (isCountingdown) {
      if (remain.days > 0) {
        return `${number.padNumber(String(remain.days), 2)} ${constants.day}`;
      } else if (
        remain.days <= 0 &&
        remain.hours <= 0 &&
        remain.minutes <= 0 &&
        remain.seconds <= 0
      ) {
        return constants.timeups;
      } else {
        return `${number.padNumber(String(remain.hours), 2)}:${number.padNumber(
          String(remain.minutes),
          2
        )}:${number.padNumber(String(remain.seconds), 2)}`;
      }
    } else if (status === "OPEN") {
      return openedStatusText || "";
    } else if (status === "WAIT") {
      return waitingStatusText || "";
    } else if (status === "CLOSE") {
      return closedStatusText || "";
    }
    return "-";
  };

  return (
    <div
      className={`lotto-action-card-container ${
        typeof onClick === "function" ? "" : "disabled"
      } ${status}`}
      style={{ backgroundColor }}
      onClick={handleOnClick}
      id={id}
    >
      <div className="sub-background" />
      <div className="lotto-action-text-wrapper">
        <ResponsiveIcon
          icon={icon!}
          alt="flag"
          className="lotto-action-card-flag m2-r"
        />
        <h3 className="d-flex flex">{title}</h3>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <h6
          className="sub-title-label"
          style={{
            color:
              backgroundColor === colors.SECONDARY_PURPLE
                ? colors.PRIMARY_TEXT
                : colors.TERTIARY_TEXT,
          }}
        >
          {subTitle}
          <span className="subtitle-1" style={{ color: colors.PRIMARY_TEXT }}>
            {description}
          </span>
        </h6>
        <BadgeComponent text={statusText()} />
      </div>
    </div>
  );
};

LottoActionCard.defaultProps = defaultProps;

export default LottoActionCard;
