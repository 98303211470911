import { createAction } from 'typesafe-actions'
import {
  GET_LOTTO_RATE_LEVEL_REQUEST,
  GET_LOTTO_RATE_LEVEL_SUCCESS,
  GET_LOTTO_RATE_LEVEL_FAILURE,
  GET_LOTTO_RATE_LEVEL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getLottoRateLevelAction = createAction(GET_LOTTO_RATE_LEVEL_REQUEST,
  reslove => (data: IGetLottoRateLevelParams) => reslove(data))

const getLottoRateLevelSuccessAction = createAction(
  GET_LOTTO_RATE_LEVEL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoRateLevel[]>>) => resolve(data))

const getLottoRateLevelFailureAction = createAction(
  GET_LOTTO_RATE_LEVEL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getLottoRateLevelCancelAction = createAction(GET_LOTTO_RATE_LEVEL_CANCEL)

export default {
  getLottoRateLevelAction,
  getLottoRateLevelSuccessAction,
  getLottoRateLevelFailureAction,
  getLottoRateLevelCancelAction,
}