import { createAction } from 'typesafe-actions'
import {
  GET_USER_CRIMINAL_VIEW_REQUEST,
  GET_USER_CRIMINAL_VIEW_SUCCESS,
  GET_USER_CRIMINAL_VIEW_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getCriminalViewAction = createAction(
  GET_USER_CRIMINAL_VIEW_REQUEST,
  reslove => (data: IQueryCriminalView) => reslove(data)
)
const getCriminalViewSuccessAction = createAction(
  GET_USER_CRIMINAL_VIEW_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IResCriminalView>>>) => resolve(data)
)
const getCriminalViewFailureAction = createAction(
  GET_USER_CRIMINAL_VIEW_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

export default {
  getCriminalViewAction,
  getCriminalViewSuccessAction,
  getCriminalViewFailureAction,
}