import { createAction } from 'typesafe-actions'
import {
    GET_AFFILATE_REQUEST,
    GET_AFFILATE_CANCEL,
    GET_AFFILATE_SUCCESS,
    GET_AFFILATE_FAILURE,

    PUT_AFFILATE_REQUEST,
    PUT_AFFILATE_CANCEL,
    PUT_AFFILATE_SUCCESS,
    PUT_AFFILATE_FAILURE,

} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getAffilateAction = createAction(GET_AFFILATE_REQUEST,
)

const getAffilateSuccessAction = createAction(
    GET_AFFILATE_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IAffilate>>) => resolve(data))

const getAffilateFailureAction = createAction(
    GET_AFFILATE_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const getAffilateCancelAction = createAction(GET_AFFILATE_CANCEL)

const putAffilateAction = createAction(PUT_AFFILATE_REQUEST,
    reslove => (data: IAffilatePutData) => reslove(data)
)

const putAffilateSuccessAction = createAction(
    PUT_AFFILATE_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IAffilate>>) => resolve(data))

const putAffilateFailureAction = createAction(
    PUT_AFFILATE_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const putAffilateCancelAction = createAction(PUT_AFFILATE_CANCEL)

export default {
    getAffilateAction,
    getAffilateSuccessAction,
    getAffilateFailureAction,
    getAffilateCancelAction,

    putAffilateAction,
    putAffilateSuccessAction,
    putAffilateFailureAction,
    putAffilateCancelAction,
}