import project from 'constants/project'

export const POST_LOTTO_RATE_LEVEL_REQUEST = 'POST_LOTTO_RATE_LEVEL_REQUEST'
export const POST_LOTTO_RATE_LEVEL_SUCCESS = 'POST_LOTTO_RATE_LEVEL_SUCCESS'
export const POST_LOTTO_RATE_LEVEL_FAILURE = 'POST_LOTTO_RATE_LEVEL_FAILURE'
export const POST_LOTTO_RATE_LEVEL_CANCEL = 'POST_LOTTO_RATE_LEVEL_CANCEL'

export const initialState: ReducerState<ILottoRateLevel[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postLottoRateLevel: `${project.environment[project.environmentName].api}/lotter/rate-level`,
}