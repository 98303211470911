import axios from 'axios'
import { MiddlewareAPI, Dispatch } from 'redux'
import project from 'constants/project'

const axiosMiddleware = ({ getState }: MiddlewareAPI<Dispatch, RootReducers>) => (next: Dispatch) => (action: any) => {
  const token = getState().mantra.user.token.accessToken
  axios.defaults.headers.common.token = token
  axios.defaults.headers.common.node = project.name
  return next(action)
}

export default axiosMiddleware