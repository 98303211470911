import React, { useEffect } from 'react';
import Emitter from 'configs/emiter'
import event from 'constants/event'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import WarningOutlined from '@material-ui/icons/WarningOutlined'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircle from '@material-ui/icons/CheckCircleOutline'
import {
    SnackbarProvider,
    IconVariant,
    useSnackbar,
} from 'notistack';

import './Snackbar.component.scss'

const snackBarIconVariant: IconVariant = {
    default: <></>,
    success: <CheckCircle />,
    error: <ErrorOutline />,
    info: <InfoOutlined />,
    warning: <WarningOutlined />,
}

function Snackbar() {

    useEffect(() => {
        Emitter.on(event.SNACKBAR, onEventSubscribed)
    })

    const { enqueueSnackbar } = useSnackbar();

    const onEventSubscribed = ({ variant, message }: ISnackbarSubScribeProps) => {
        enqueueSnackbar(
            <h6 className="subtitle-1 primary-text">{message}</h6>,
            {
                variant,
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                },
            });
    }

    return (
        <>
        </>
    );
}

function SnackbarContainer() {
    return (
        <SnackbarProvider
            maxSnack={3}
            iconVariant={snackBarIconVariant}
            className="snackbar-provider"
        >
            <Snackbar />
        </SnackbarProvider>
    )

}

export default SnackbarContainer