import { string, object, ObjectSchema } from 'yup'

const constants = {
  bank: 'กรุณาเลืิอกธนาคาร',
}

const scheme: ObjectSchema<IBank> = object().shape({
  bank: string()
  .required(constants.bank),
})
export default scheme