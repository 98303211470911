import React, { Component } from 'react'
import { noop } from 'lodash'
import './AppBar.component.scss'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Collapse,
} from '@material-ui/core'
import {
  Notifications,
  Help,
  Input,
} from '@material-ui/icons'
import { THEME_MODE } from 'constants/variables'
import MenuIcon from '@material-ui/icons/Menu'
import TuneIcon from '@material-ui/icons/Tune'
import GroupsIcon from '@material-ui/icons/Group'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ReceiptIcon from '@material-ui/icons/Receipt'
import SettingIcon from '@material-ui/icons/Settings'
import BarChartIcon from '@material-ui/icons/BarChart'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import logo from 'assets/images/flags/logothailandbet.png'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import AppSettingIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import InderminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingUsd, faUserSecret } from '@fortawesome/free-solid-svg-icons'

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IAppBarProps & IMenuActionProps = {
  menu: { activeControl: { menu: 'deposit', activeRoleManagement: false } },
  userMeResult: [],
  userMeCode: 0,
  userMeError: '',
  userMeIsFetching: false,
  logout() { noop() },
  onChangeMenu() { noop() },
}
class AppBarComponent extends Component<IAppBarComponentProps & DefaultProps, IAppBarStates> {

  static defaultProps = defaultProps
  state: IAppBarStates = {
    isExpand: false,
    themeMode: THEME_MODE.DARK,
    hideMenu: false,
    consoleClassName: `content-container close`,
    appBarClassName: `app-bar close`,
    menuBarClassName: `menus-bar close`,
    activeMenu: 'admin-management',
  }
  componentDidMount() {
    if (this.props.userMeResult.permission === 'SUPER_ADMIN') {
      this.setState({ activeMenu: 'admin-management' })
    }
    if (this.props.userMeResult.permission === 'ADMIN') {
      this.setState({ activeMenu: 'lotter-master-management' })
    }

    if (this.isMenuRoleManagement()) {
      this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: this.initialActiveMenu() })
    }
    else {
      this.props.onChangeMenu!({ menu: this.initialActiveMenu(), activeRoleManagement: false })
    }
  }
  componentDidUpdate(pre: IMenuBarProps) {
    if (pre.userMeResult.permission !== this.props.userMeResult.permission) {
      if (this.props.userMeResult.permission === 'SUPER_ADMIN') {
        this.setState({ activeMenu: 'admin-management' })
      }
      if (this.props.userMeResult.permission === 'ADMIN') {
        this.setState({ activeMenu: 'lotter-master-management' })
      }
    }
  }
  isMenuRoleManagement = () => {
    return (this.initialActiveMenu() === 'admin-management' ||
      this.initialActiveMenu() === 'lotter-master-management' ||
      this.initialActiveMenu() === 'staff-management' ||
      this.initialActiveMenu() === 'user-management' ||
      this.initialActiveMenu() === 'line-user-management')
  }
  initialActiveMenu = () => {
    const { pathname } = window.location
    if (pathname.startsWith('/transaction/withdraw/verify')) {
      return 'withdraw';
    }

    switch (window.location.pathname) {
      case '/webbank':
        return 'webbank'
      case '/transaction/withdraw':
        return 'withdraw'
      case '/news':
        return 'news'
      case '/web-config':
        return 'web-config'
      case '/summary-dashboard':
        return 'summary-dashboard'
      case '/user-management':
        return 'user-management'
      case '/admin-management':
        return 'admin-management'
      case '/staff-management':
        return 'staff-management'
      case '/lotto':
        return 'lotto'
      case '/lotto-rate':
        return 'lotto-rate'
      case '/lotter-master-management':
        return 'lotter-master-management'
      case '/line-user-management':
        return 'line-user-management'
      case '/Affilate':
        return 'members'
      case '/transaction/deposit':
        return 'deposit'
      case '/lotto-cancel':
        return 'lottoCancel'
      case '/number-rate':
        return 'numberRate'
      case '/report-benefit':
        return 'report-benefit'
      case '/criminal-view':
        return 'criminal-view'
      case '/daily-report':
        return 'daily-report'

      default:
        return 'deposit'
    }
  }
  onOpenMenu = () => {
    const status = 'open'
    this.setState({
      isExpand: true,
      hideMenu: false,
      consoleClassName: `content-container ${status}`,
      appBarClassName: `app-bar ${status}`,
      menuBarClassName: `menus-bar ${status}`,
    })
  }
  onCloseMenu = () => {
    const status = 'close'
    this.setState({
      hideMenu: false,
      consoleClassName: `content-container ${status}`,
      appBarClassName: `app-bar ${status}`,
      menuBarClassName: `menus-bar ${status}`,
    })
  }

  handleOnClick = () => {
    this.state.isExpand && (this.onCloseMenu())
    this.setState({ isExpand: !this.state.isExpand })
  }


  renderUserManagementMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link
          to="/user-management"
          onClick={() => this.props.onChangeMenu!({ menu: 'user-management', activeRoleManagement: false })}
        >
          <ListItem
            button
            key={'user-management'}
            className={this.props.menu!.activeControl.menu === 'user-management' ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <GroupsIcon
                className={
                  this.props.menu!.activeControl.menu === 'user-management' ? 'primary-purple-text' : ''
                }
              />
            </ListItemIcon>
            <ListItemText primary={'จัดการผู้ใช้'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderWebbankManagementMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/webbank" onClick={() => this.props.onChangeMenu!({ menu: 'webbank', activeRoleManagement: false })}>
          <ListItem
            button
            key={'webbank'}
            className={this.props.menu!.activeControl.menu === 'webbank'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AccountBalanceIcon
                className={this.props.menu!.activeControl.menu === 'webbank'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'บัญชีระบบ'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderSumDashboardMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link
          to="/summary-dashboard"
          onClick={() => this.props.onChangeMenu!({ menu: 'summary-dashboard', activeRoleManagement: false })}
        >
          <ListItem
            button
            key={'summary'}
            className={this.props.menu!.activeControl.menu === 'summary-dashboard'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <BarChartIcon
                className={this.props.menu!.activeControl.menu === 'summary-dashboard'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText className="subtitle-1" primary={'ภาพรวม'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderReportBenefit = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/report-benefit" onClick={() => this.props.onChangeMenu!({ menu: 'report-benefit', activeRoleManagement: false })}>
          <ListItem button
            key={'report-benefit'}
            className={this.props.menu!.activeControl.menu === 'report-benefit'
              ? 'primary-purple-text' : ''}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHandHoldingUsd} className={this.props.menu!.activeControl.menu === "report-benefit" ? 'primary-purple-text' : ''} />
            </ListItemIcon>
            <ListItemText className="subtitle-1" primary={"กำไร / ขาดทุน"} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderCriminalView = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/criminal-view" onClick={() => this.props.onChangeMenu!({ menu: 'criminal-view', activeRoleManagement: false })}>
          <ListItem button
            key={'criminal-view'}
            className={this.props.menu!.activeControl.menu === 'criminal-view'
              ? 'primary-purple-text' : ''}>
            <ListItemIcon>
              <FontAwesomeIcon style={{ width: 23, height: 23 }} icon={faUserSecret} className={this.props.menu!.activeControl.menu === 'criminal-view' ? 'primary-purple-text' : ''} />
            </ListItemIcon>
            <ListItemText className="subtitle-1" primary={"ตรวจจับคนโกง"} />
          </ListItem>
        </Link>
      </div>
    )
  }


  renderDailyReportMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link
          to="/daily-report"
          onClick={() => this.props.onChangeMenu!({ menu: 'daily-report', activeRoleManagement: false })}
        >
          <ListItem
            button
            key={'summary'}
            className={this.props.menu!.activeControl.menu === 'daily-report'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <ShowChartIcon
                className={this.props.menu!.activeControl.menu === 'daily-report'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText className="subtitle-1" primary={'รายงานรายวัน'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderAffilateMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/Affilate" onClick={() => this.props.onChangeMenu!({ menu: 'members', activeRoleManagement: false })}>
          <ListItem
            button
            key={'members'}
            className={this.props.menu!.activeControl.menu === 'members'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <PermIdentityIcon
                className={this.props.menu!.activeControl.menu === 'members'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'แนะนำสมาชิก'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderSettinsgMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/settings" onClick={() => this.props.onChangeMenu!({ menu: 'settings', activeRoleManagement: false })}>
          <ListItem
            button
            key={'settings'}
            className={this.props.menu!.activeControl.menu === 'settings'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <SettingIcon
                className={this.props.menu!.activeControl.menu === 'settings'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'ยี่กี'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderWebConfigMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/web-config" onClick={() => this.props.onChangeMenu!({ menu: 'web-config', activeRoleManagement: false })}>
          <ListItem
            button
            key={'web-config'}
            className={this.props.menu!.activeControl.menu === 'web-config'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AppSettingIcon
                className={this.props.menu!.activeControl.menu === 'web-config'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'เว็บ'} />
          </ListItem>
        </Link>
      </div>
    )
  }
  renderBroadcastMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/broadcast" onClick={() => this.props.onChangeMenu!({ menu: 'broadcast', activeRoleManagement: false })}>
          <ListItem
            button
            key={'broadcast'}
            className={this.props.menu!.activeControl.menu === 'broadcast'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AnnouncementIcon
                className={this.props.menu!.activeControl.menu === 'broadcast'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'โฆษณาไลน์'} />
          </ListItem>
        </Link>
      </div>
    )
  }
  renderDepositMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/transaction/deposit" onClick={() => this.props.onChangeMenu!({ menu: 'deposit', activeRoleManagement: false })}>
          <ListItem
            button
            key={'transaction-management'}
            className={this.props.menu!.activeControl.menu === 'deposit' ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AddBoxIcon
                className={this.props.menu!.activeControl.menu === 'deposit'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            {/* <div> */}
            <ListItemText className="subtitle-1" primary={'รายการฝาก'} id='deposit-menu' />
            {/* </div> */}
          </ListItem>
        </Link>
      </div>
    )
  }
  renderWithdrawMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/transaction/withdraw" onClick={() => this.props.onChangeMenu!({ menu: 'withdraw', activeRoleManagement: false })}>
          <ListItem
            button
            key={'withdraw'}
            className={this.props.menu!.activeControl.menu === 'withdraw'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <InderminateCheckBoxIcon
                className={this.props.menu!.activeControl.menu === 'withdraw'
                  ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText className="subtitle-1" primary={'รายการถอน'} />

          </ListItem>
        </Link>
      </div>
    )
  }


  renderManagementSale = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <div className="sub-menu-bar">
          <Link to="/user-management" onClick={() => this.props.onChangeMenu!({ menu: 'user-management', activeRoleManagement: false })}>
            <ListItem
              button
              key={'lottorate'}
              className={this.props.menu!.activeControl.menu === 'user-management'
                ? 'primary-purple-text' : ''}
            >
              <ListItemIcon>
                <SwapVertIcon
                  className={this.props.menu!.activeControl.menu === 'user-management'
                    ? 'primary-purple-text'
                    : ''}
                />
              </ListItemIcon>
              <ListItemText primary={'ผู้ใช้'} />
            </ListItem>
          </Link>
        </div>
      </div >
    )
  }
  
  renderNewsMenu = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/news" onClick={() => this.props.onChangeMenu!({ menu: 'news', activeRoleManagement: false })}>
          <ListItem
            button
            key={'news'}
            className={this.props.menu!.activeControl.menu === 'news'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <ReceiptIcon
                className={this.props.menu!.activeControl.menu === 'news' ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'ข่าวสาร'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderLottoList = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/lotto" onClick={() => this.props.onChangeMenu!({ menu: 'lotto', activeRoleManagement: false })}>
          <ListItem
            button
            key={'lotto'}
            className={this.props.menu!.activeControl.menu === 'lotto'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <TuneIcon
                className={this.props.menu!.activeControl.menu === 'lotto' ? 'primary-purple-text' : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'หวย'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderLottoRate = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/lotto-rate" onClick={() => this.props.onChangeMenu!({ menu: 'lotto-rate', activeRoleManagement: false })}>
          <ListItem
            button
            key={'lottorate'}
            className={this.props.menu!.activeControl.menu === 'lotto-rate'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <ReceiptIcon
                className={this.props.menu!.activeControl.menu === 'lotto-rate'
                  ? 'primary-purple-text'
                  : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'อัตราจ่ายหวย'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderContent = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/content" onClick={() => this.props.onChangeMenu!({ menu: 'content', activeRoleManagement: false })}>
          <ListItem
            button
            key={'content'}
            className={this.props.menu!.activeControl.menu === 'content'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AddPhotoAlternateIcon
                className={this.props.menu!.activeControl.menu === 'content'
                  ? 'primary-purple-text'
                  : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'จัดการโฆษณา'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderManagement = (): JSX.Element => {
    return (
      <ListSubheader>
        <div className="group-title subtitle-1 secondary-text m2-l"> จัดการระบบ </div>
      </ListSubheader>
    )
  }
  renderReportTitle = (): JSX.Element => {
    return (
      <ListSubheader>
        <div className="group-title subtitle-1 secondary-text m3-t m2-l"> รายงาน </div>
      </ListSubheader>
    )
  }
  renderSettingsTitle = (): JSX.Element => {
    return (
      <ListSubheader>
        <div className="group-title subtitle-1 secondary-text m2-l"> ตั้งค่าระบบ </div>
      </ListSubheader>
    )
  }

  renderManagementUser = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link
          to={() => window.location.pathname}
          onClick={() => this.props.onChangeMenu!(
            this.isMenuRoleManagement() ? {
              menu: 'role-management',
              activeRoleManagement: !this.props.menu?.activeControl.activeRoleManagement,
              childMenu: this.initialActiveMenu(),
            } : {
              menu: this.initialActiveMenu(),
              activeRoleManagement: !this.props.menu?.activeControl.activeRoleManagement
            }
          )}
        >
          <ListItem
            button
            className={this.props.menu!.activeControl.menu === 'role-management' ? 'primary-purple-text' : ''}
          >
            <ListItemIcon className={'a'}>
              <IconLibraryBooks />
            </ListItemIcon>
            <ListItemText primary="จัดการพนักงาน" />
            {this.props.menu!.activeControl.activeRoleManagement ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
        </Link>
        <div className="">
          <Collapse in={this.props.menu!.activeControl.activeRoleManagement} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              {this.props.userMeResult.permission === 'SUPER_ADMIN'
                ? (
                  <Link
                    to="/admin-management"
                    onClick={() => this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: 'admin-management' })}
                  >
                    <ListItem
                      className={`sub-menu ${this.props.menu!.activeControl.childMenu === 'admin-management' ? ' primary-purple-text' : ''}`}
                      button
                      key={'admin-management'}
                    >
                      <ListItemText
                        inset
                        primary="หัวหน้าพนักงาน"
                      />
                    </ListItem>
                  </Link>
                )
                : <></>
              }
              {
                this.props.userMeResult.permission === 'SUPER_ADMIN' ||
                  this.props.userMeResult.permission === 'ADMIN' ||
                  this.props.userMeResult.permission === 'LOTTER_MASTER'
                  ? (
                    <Link
                      to="/lotter-master-management"
                      onClick={() => this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: 'lotter-master-management' })}
                    >
                      <ListItem
                        className={`sub-menu ${this.props.menu!.activeControl.childMenu === 'lotter-master-management' ? ' primary-purple-text' : ''}`}
                        button
                        key={'lotter-master-management'}
                      >
                        <ListItemText
                          inset
                          primary="พนักงานควบคุมหวย"
                        />
                      </ListItem>
                    </Link>
                  )
                  : <></>
              }
              {!(this.props.userMeResult.permission === 'STAFF' || this.props.userMeResult.permission === 'LOTTER_MASTER')
                ? (
                  <Link
                    to="/staff-management"
                    onClick={() => this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: 'staff-management' })}
                  >
                    <ListItem
                      className={`sub-menu ${this.props.menu!.activeControl.childMenu === 'staff-management' ? ' primary-purple-text' : ''}`}
                      button
                      key={'staff-management'}
                    >
                      <ListItemText
                        inset
                        primary="พนักงาน"
                      />
                    </ListItem>
                  </Link>
                )
                : <></>
              }
              {this.props.userMeResult.permission !== 'LOTTER_MASTER' &&
                (
                  <>
                    <Link
                      to="/user-management"
                      onClick={() => this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: 'user-management' })}
                    >
                      <ListItem
                        className={`sub-menu ${this.props.menu!.activeControl.childMenu === 'user-management' ? ' primary-purple-text' : ''}`}
                        button
                        key={'user-management'}
                      >
                        <ListItemText
                          inset
                          primary="ผู้ใช้"
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/line-user-management"
                      onClick={() => this.props.onChangeMenu!({ menu: 'role-management', activeRoleManagement: true, childMenu: 'line-user-management' })}
                    >
                      <ListItem
                        className={`sub-menu ${this.props.menu!.activeControl.childMenu === 'line-user-management' ? ' primary-purple-text' : ''}`}
                        button
                        key={'line-user-management'}
                      >
                        <ListItemText
                          inset
                          primary="ไลน์ผู้ใช้"
                        />
                      </ListItem>
                    </Link>
                  </>
                )
              }
            </List>
          </Collapse>
        </div>
      </div >
    )
  }

  renderNumberRate = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/number-rate" onClick={() => this.props.onChangeMenu!({ menu: 'numberRate', activeRoleManagement: false })}>
          <ListItem
            button
            key={'lottorate'}
            className={this.props.menu!.activeControl.menu === 'numberRate'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <SwapVertIcon
                className={this.props.menu!.activeControl.menu === 'numberRate'
                  ? 'primary-purple-text'
                  : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'ปรับราคาเฉพาะเลข'} />
          </ListItem>
        </Link>
      </div>
    )
  }
  renderLottoCancel = (): JSX.Element => {
    return (
      <div className="sub-menu-bar">
        <Link to="/lotto-cancel" onClick={() => this.props.onChangeMenu!({ menu: 'lottoCancel', activeRoleManagement: false })}>
          <ListItem
            button
            key={'lottorate'}
            className={this.props.menu!.activeControl.menu === 'lottoCancel'
              ? 'primary-purple-text' : ''}
          >
            <ListItemIcon>
              <AutorenewIcon
                className={this.props.menu!.activeControl.menu === 'lottoCancel'
                  ? 'primary-purple-text'
                  : ''}
              />
            </ListItemIcon>
            <ListItemText primary={'คืนโพยเลข'} />
          </ListItem>
        </Link>
      </div>
    )
  }

  renderUserManagement = (): JSX.Element => {
    const RenderNewsMenu = this.renderNewsMenu
    const RenderLottoList = this.renderLottoList
    const RenderManagement = this.renderManagement
    const RenderDepositMenu = this.renderDepositMenu
    const RenderWithdrawMenu = this.renderWithdrawMenu
    const RenderManagementUser = this.renderManagementUser
    const RenderWebbankManagementMenu = this.renderWebbankManagementMenu
    const RenderManagementSale = this.renderManagementSale
    switch (this.props.userMeResult.permission) {
      case 'SUPER_ADMIN':
        return (
          <>
            <RenderManagement />
            <RenderDepositMenu />
            <RenderWithdrawMenu />
            <RenderManagementUser />
            <RenderWebbankManagementMenu />
            <RenderNewsMenu />
            <RenderLottoList />
          </>
        )
      case 'ADMIN':
        return (
          <>
            <RenderManagement />
            <RenderDepositMenu />
            <RenderWithdrawMenu />
            <RenderManagementUser />
            <RenderWebbankManagementMenu />
            <RenderNewsMenu />
            <RenderLottoList />
          </>
        )
      case 'SALE':
        return (
          <>
            <RenderManagement />
            <RenderManagementSale />
          </>
        )
      case 'STAFF':
        return (
          <>
            <RenderManagement />
            <RenderDepositMenu />
            <RenderWithdrawMenu />
            <RenderManagementUser />
            {/* <RenderUserManagementMenu /> */}
          </>
        )
      case 'LOTTER_MASTER':
        return (
          <>
            <RenderManagementUser />
            <RenderLottoList />
          </>
        )
      default:
        return <></>
    }
  }

  renderReportManagement = (): JSX.Element => {
    const RenderReportTitle = this.renderReportTitle
    const RenderSumDashboardMenu = this.renderSumDashboardMenu
    const RenderDailyReportMenu = this.renderDailyReportMenu
    const RenderReportBenefit = this.renderReportBenefit
    // const RenderCriminalView = this.renderCriminalView
    switch (this.props.userMeResult.permission) {
      case 'SUPER_ADMIN':
        return (
          <>
            <RenderReportTitle />
            <RenderSumDashboardMenu />
            <RenderDailyReportMenu />
            <RenderReportBenefit />
            {/* <RenderCriminalView /> */}
          </>
        )
      case 'ADMIN':
        return (
          <>
            <RenderReportTitle />
            <RenderDailyReportMenu />
            {/* <RenderCriminalView /> */}
          </>
        )
      case 'STAFF':
        return (
          <>
            <RenderReportTitle />
            {/* <RenderCriminalView /> */}
          </>
        )
      default:
        return <></>
    }
  }

  renderSettingsManagement = (): JSX.Element => {
    const RenderLottoRate = this.renderLottoRate
    const RenderAffilateMenu = this.renderAffilateMenu
    const RenderSettingsTitle = this.renderSettingsTitle
    const RenderWebConfigMenu = this.renderWebConfigMenu
    const RenderBroadcastMenu = this.renderBroadcastMenu
    const RenderLottoCancel = this.renderLottoCancel
    const RenderNumberRate = this.renderNumberRate
    switch (this.props.userMeResult.permission) {
      case 'SUPER_ADMIN':
        return (
          <>
            <RenderSettingsTitle />
            <RenderLottoCancel />
            <RenderNumberRate />
            <RenderLottoRate />
            <RenderAffilateMenu />
            <RenderWebConfigMenu />
          </>
        )
      case 'ADMIN':
        return (
          <>
            <RenderSettingsTitle />
            <RenderLottoRate />
            <RenderLottoCancel />
            <RenderNumberRate />
          </>
        )
      case 'MARKETING':
        return (
          <>
            <RenderBroadcastMenu />
          </>
        )
      case 'STAFF':
        return (
          <>
          </>
        )
      default:
        return <></>
    }
  }

  render() {
    const RenderReportManagement = this.renderReportManagement
    const RenderUserManagement = this.renderUserManagement
    const RenderSettingsManagement = this.renderSettingsManagement
    return (
      <AppBar
        position="fixed"
        className="app-bar-container secondary-dark"
      >
        <Toolbar>
          <div className="d-flex align-items-center">
            <img src={logo} className="app-logo" alt="app-logo" />
            <h5 className="text-thin pd-l">THAILAND<span>BET ADMIN</span> </h5>
          </div>
          <div className="user-navigate-container">
            <Tooltip title="แจ้งเตือน">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
              >
                <Notifications />
              </IconButton>
            </Tooltip>
            <Tooltip title="ข้อมูล">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
              >
                <Help />
              </IconButton>
            </Tooltip>
            <Tooltip title="ออกจากระบบ">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.props.logout}
                edge="start"
              >
                <Input />
              </IconButton>

            </Tooltip>
            <h6 className="primary-text">{this.props.userMeResult.username}</h6>
            <div className="m2-l menu-burger" id='menu-burger' key="menu-burger">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.onOpenMenu}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Drawer
              variant="permanent"
              className={this.state.menuBarClassName}
            >
              <div className="d-flex transaction-chevron-right-icon" onClick={this.handleOnClick}>
                <ChevronRightIcon className={`chevron-right-icon ${this.state.isExpand ? '' : 'expanded'}`} />
              </div>
              <List>
                <RenderReportManagement />
                <RenderUserManagement />
                <RenderSettingsManagement />
              </List>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

export default AppBarComponent