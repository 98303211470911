import React, { Component, createRef, RefObject } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  TitlePage,
  TextField,
  Select,
  LottoActionCard,
  ResponsiveIcon,
  Breadcrumb,
  Modal,
  ModalNumberRate,
  RollerLoading,
} from "components";

import moment from "moment";
import { noop, isEmpty, map, get, uniq, groupBy } from "lodash";
import colors from "constants/colors";
import {
  LOTTO_SLUG_NAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_TO_TYPE,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import "./NumberRate.container.scss";
import {
  Grid,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Form, Formik, FormikProps } from "formik";
import initialFormValue from "./models/initialValues";
import scheme from "./models/scheme";
import InfiniteScroll from "react-infinite-scroll-component";
import { responseCode, responseMessage } from "constants/response";
import { SPECIAL } from "constants/regex";
import { number, date } from "utils";
import { RenderModeComponent } from "./components";
import { fetchPuttLottoRateNumber } from "reduxs/config/numberRate/create/services";

const constants = {
  back: "กลับ",
  // lottoLabel: 'แทงหวย',
  closedLabel: "เวลาที่ปิดรับ: ",
  openedYeegeLabel: "เปิดรับ: ",
  closedStatusLabel: "ปิดรับแทง",
  placeholderInput: (type: string) => `กรอก${type}`,
  waitingStatusLabel: "รอเปิดรับแทง",
  openedYeegeStatusLabel: "24 ชม.",
  round: "รอบ",
  twoUp: "2 ตัวบน",
  twoDown: "2 ตัวล่าง",
  threeUp: "3 ตัวบน",
  fourSuit: "เลขชุด 4 ตัว",
  runUp: "วิ่งบน",
  runDown: "วิ่งล่าง",
  threeToast: "3 โต๊ด",
  level: "ระดับ",
  Rate: "อัตราจ่าย",
  number: "เลข",
  sumTotal: "ยอดรวมปรับระดับ",
  wrongUsernameTitle: "เพิ่มระดับอัตราจ่ายไม่ถูกต้อง",
  wrongUsernameDescription:
    "โปรดกรอกยอดปรับระดับมากกว่าระดับเดิม และอัตราจ่ายที่น้อยกว่าระดับเดิม",
  settingLabel: "ตั้งค่าระบบ",
  betRateLabel: "ปรับราคาเลข",
  fail: "ไม่สำเร็จ",
  description: "เนื่องจาก :",
  editRate: "การแก้ไขอัตราจ่าย",
  status: "สถานะ",
  confirmEditRate: "ยืนยันการแก้ไขอัตราจ่าย",
  confirmFailureText: "พบข้อผิดพลาด",
  confirmFailureRate: "ไม่สามารถปรับอัตราจ่ายได้",
  type: "ประเภท",
  edit: "แก้ไข",
  cancel: "ยกเลิก",
  changeRateSuccess: "ปรับราคาเฉพาะเลขสำเร็จ",
  changeRateUnSuccess: "ปรับราคาเฉพาะเลขไม่สำเร็จ",

  modeBet: [
    { key: "THREE_UP", label: "3 บน", rate: "threeUpRate", length: 3 },
    { key: "THREE_TOAST", label: "3 โต๊ด", rate: "threeToastRate", length: 3 },
    { key: "TWO_UP", label: "2 บน", rate: "twoUpRate", length: 2 },
    { key: "TWO_DOWN", label: "2 ล่าง", rate: "twoDownRate", length: 2 },
    { key: "RUN_UP", label: "วิ่งบน", rate: "runUpRate", length: 1 },
    { key: "RUN_DOWN", label: "วิ่งล่าง", rate: "runDownRate", length: 1 },
  ],
  modeButton: [
    { key: "SIXBACK", label: "6 กลับ" },
    { key: "19DOOR", label: "19 ประตู" },
    { key: "FLIPNUMBER", label: "กลับเลข" },
    { key: "allType", label: "เกี่ยวหมด" },
  ],
};

const lottoTypeList = [
  {
    value: "THREE_UP",
    element: <h6>{constants.threeUp}</h6>,
  },
  {
    value: "THREE_TOAST",
    element: <h6>{constants.threeToast}</h6>,
  },
  {
    value: "TWO_UP",
    element: <h6>{constants.twoUp}</h6>,
  },
  {
    value: "TWO_DOWN",
    element: <h6>{constants.twoDown}</h6>,
  },
  {
    value: "RUN_UP",
    element: <h6>{constants.runUp}</h6>,
  },
  {
    value: "RUN_DOWN",
    element: <h6>{constants.runDown}</h6>,
  },
];

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: INumberRateProps & INumberRateActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: "",
  lottoSchedule: [],

  getLottoRateNumberIsFetching: false,
  getLottoRateNumberCode: 0,
  getLottoRateNumberError: "",
  getLottoRateNumberResult: {
    isFetching: false,
    data: [],
    error: "",
    code: "",
    total: 0,
    page: 0,
    limit: 0,
  },

  putLottoRateNumberIsFetching: false,
  putLottoRateNumberCode: 0,
  putLottoRateNumberError: "",
  putLottoRateNumberResult: {},

  loader() {
    noop();
  },
  getLottoSchedule() {
    noop();
  },
  getLottoRateNumber() {
    noop();
  },
  clearLottoRateNumber() {
    noop();
  },
  putLottoRateNumber() {
    noop();
  },
};

type IColumnId = "number" | "rate" | "update";
const columns: ITransactionColumn<IColumnId>[] = [
  { id: "number", label: "เลข", minWidth: 100, align: "left" },
  { id: "rate", label: "อัตราจ่าย(บาทละ)", minWidth: 100, align: "right" },
  { id: "update", label: "แก้ไขล่าสุด", minWidth: 150, align: "center" },
];

class LottoRateContainer extends Component<
  INumberRateProps & INumberRateActionProps & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  FormikInstance: FormikProps<ILottoRateNumber> | null = null;

  state: ILottoRateNumberContainerState = {
    tableRows: [],
    selectedDate: null,
    search: "",
    onSettingLotto: "LOTTER_GOVN",
    title: "หวยรัฐบาล",
    startTime: "",
    endTime: "",
    lottoTypeSelect: "THREE_UP",
    lottoType: "THREE_UP",
    lottoSchedule: [],
    icon: LottoFlags.THA.Icon,
    RateNumberPage: 0,
    RateNumberHasMore: false,
    RateNumberIsFirst: true,

    listNumber: [],

    objectLottoRate: {
      code: "",
      rate: "",
      type: "",
      number: "",
    },
    is6BackActivated: false,
    isReverseNumActivated: false,
    is19DoorActivated: false,
    isAllRelevantActivated: false,
    isModalShow: false,
    isModalShowError: false,
    isModalSuccessShow: false,
    isSearchActivated: false,
  };
  inputRef: RefObject<HTMLInputElement> = createRef();
  rateRef: RefObject<HTMLInputElement> = createRef();

  componentDidMount() {
    this.props.loader(true);
    this.props.getLottoSchedule();
    this.props.getLottoRateNumber(
      {
        page: 0,
        limit: 10,
        code: this.state.onSettingLotto,
        type: this.state.lottoTypeSelect,
      },
      this.state.RateNumberIsFirst
    );
  }

  componentDidUpdate(prevProps: INumberRateProps) {
    if (
      prevProps.getLottoScheduleIsFetching !==
        this.props.getLottoScheduleIsFetching &&
      !this.props.getLottoScheduleIsFetching
    ) {
      this.setState({
        lottoSchedule: this.props.lottoSchedule.filter((member) => {
          return member.code !== "LOTTER_YEGEE";
        }),
      });
      this.props.loader(false);
    }
    if (
      prevProps.getLottoRateNumberIsFetching !==
        this.props.getLottoRateNumberIsFetching &&
      !this.props.getLottoRateNumberIsFetching
    ) {
      if (this.props.getLottoRateNumberCode === responseCode.OK) {
        if (this.state.RateNumberIsFirst) {
          this.setState({ RateNumberIsFirst: false });
        }
        this.setState({
          RateNumberPage: this.props.getLottoRateNumberResult.page,
          RateNumberHasMore:
            this.props.getLottoRateNumberResult.page! <
            this.props.getLottoRateNumberResult.total!,
        });
      }
      this.props.loader(false);
    }
    if (
      prevProps.putLottoRateNumberIsFetching !==
        this.props.putLottoRateNumberIsFetching &&
      !this.props.putLottoRateNumberIsFetching
    ) {
      if (this.props.putLottoRateNumberCode === responseCode.OK) {
        this.setState(
          {
            RateNumberPage: 0,
            RateNumberHasMore: false,
            RateNumberIsFirst: true,
          },
          () => {
            this.props.clearLottoRateNumber();
            this.getLottoRateNumber();
          }
        );
      } else {
        Modal.confirm.show({
          action: () => {
            Modal.error.hide();
          },
          typeIcon: "Alert",
          isCancelButton: false,
          child: (
            <>
              <h3 className={`primary-red-text`}>{constants.fail}</h3>
              <div className="d-flex flex align-items-end justify-content-center mb-2">
                <h5>{constants.description}</h5>
                <h5 className="ml-1">
                  {get(responseMessage, this.props.putLottoRateNumberCode, "")}
                </h5>
              </div>
            </>
          ),
          height: "330px",
          description: constants.editRate,
        });
      }
      this.props.loader(false);
    }
  }

  getLottoRateNumber = () => {
    this.props.getLottoRateNumber(
      {
        page: this.state.RateNumberIsFirst ? 0 : this.state.RateNumberPage,
        limit: 10,
        code: this.state.onSettingLotto,
        type: this.state.lottoTypeSelect,
      },
      this.state.RateNumberIsFirst
    );
  };

  renderLottoList = () =>
    map(this.state.lottoSchedule, (lotto, index) => {
      const subtitle =
        lotto.code === "LOTTER_YEGEE"
          ? constants.openedYeegeLabel
          : constants.closedLabel;
      const backgroundColor =
        lotto.code === this.state.onSettingLotto
          ? colors.SECONDARY_PURPLE
          : colors.SECONDARY_DARK;

      const description =
        lotto.code === "LOTTER_YEGEE"
          ? `88 ${constants.round}`
          : isEmpty(lotto.endTime)
          ? "-"
          : moment(lotto.endTime)
              .add(-7, "hour")
              .add(543, "years")
              .format("DD MMM YY HH:mm");
      const isCountingDown =
        lotto.code === "LOTTER_YEGEE" ? false : lotto.status === "OPEN";

      const FlagIcon =
        LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon;
      return (
        <div className="lotto-body-item" key={`lotto-${lotto.code}-${index}`}>
          <LottoActionCard
            id={`lotto-${lotto.code}`}
            onClick={() => {
              this.renderBetDetail(lotto);
              this.resetMode();
            }}
            title={LOTTO_SLUG_NAME[lotto.code]}
            subTitle={subtitle}
            icon={FlagIcon}
            backgroundColor={backgroundColor}
            status={lotto.status}
            isCountingdown={isCountingDown}
            closedStatusText={constants.closedStatusLabel}
            waitingStatusText={constants.waitingStatusLabel}
            openedStatusText={constants.openedYeegeStatusLabel}
            description={description}
            expire={lotto.endTime}
          />
        </div>
      );
    });

  renderBetDetail = (lotto: ILottoSchedule) => {
    this.setState(
      {
        RateNumberPage: 0,
        RateNumberHasMore: false,
        RateNumberIsFirst: true,
      },
      () => {
        this.props.clearLottoRateNumber();
      }
    );
    moment.locale("th");
    this.setState({ onSettingLotto: lotto.code }, () => {
      if (this.state.onSettingLotto !== "LOTTER_LAO_SUITE") {
        this.setState(
          { lottoTypeSelect: "THREE_UP", lottoType: "THREE_UP" },
          () => {
            this.getLottoRateNumber();
          }
        );
      } else {
        this.setState(
          { lottoTypeSelect: "FOUR_SUITE", lottoType: "FOUR_SUITE" },
          () => {
            this.getLottoRateNumber();
          }
        );
      }
    });
    this.props.loader(true);
    this.setState(
      {
        id: lotto.id,
        title: LOTTO_SLUG_NAME[lotto.code],
        startTime: lotto.startTime,
        endTime: moment(lotto.endTime).format("lll"),
        icon: LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon,
        // initialFormValue: { ...initialValue },
      },
      () => {
        this.FormikInstance?.resetForm();
      }
    );
  };

  updateSearch(value: string) {
    let newVal = value.replace(SPECIAL, "");
    this.setState({ search: newVal.substr(0, 20) });
  }

  handleFilterStatus = (lottoType: TLottoGameType) => {
    this.setState(
      {
        RateNumberPage: 0,
        RateNumberHasMore: false,
        RateNumberIsFirst: true,
      },
      () => {
        this.props.clearLottoRateNumber();
      }
    );
    this.setState({ lottoTypeSelect: lottoType }, () => {
      this.props.loader(true);
      this.getLottoRateNumber();
    });
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  resetData = (formikProps: FormikProps<ILottoRateNumber>) => {
    formikProps.setValues({
      ...formikProps.values,
      rate: "",
      number: "",
      threeUpRate: "",
      threeToastRate: "",
      twoUpRate: "",
      twoDownRate: "",
      runUpRate: "",
      runDownRate: "",
    });
    formikProps.setErrors({
      ...formikProps.errors,
      rate: "",
      number: "",
      threeUpRate: "",
      threeToastRate: "",
      twoUpRate: "",
      twoDownRate: "",
      runUpRate: "",
      runDownRate: "",
    });
    formikProps.setTouched({
      ...formikProps.touched,
      rate: false,
      number: false,
      threeUpRate: false,
      threeToastRate: false,
      twoUpRate: false,
      twoDownRate: false,
      runUpRate: false,
      runDownRate: false,
    });
  };

  resetMode = () => {
    this.setState({ is6BackActivated: false });
    this.setState({ is19DoorActivated: false });
    this.setState({ isReverseNumActivated: false });
    this.setState({ isAllRelevantActivated: false });
  };

  isCheckEmptyForm = (formikProps: FormikProps<ILottoRateNumber>) => {
    const { errors, values } = formikProps;

    return this.state.isAllRelevantActivated
      ? !isEmpty(errors) ||
          isEmpty(values.number) ||
          isEmpty(values.threeUpRate) ||
          isEmpty(values.threeToastRate) ||
          isEmpty(values.twoUpRate) ||
          isEmpty(values.twoDownRate) ||
          isEmpty(values.runUpRate) ||
          isEmpty(values.runDownRate)
      : !isEmpty(errors) || isEmpty(values.number) || isEmpty(values.rate);
  };

  setValuestoArray = (charlength: string[], rate: string, type: string) => {
    let result = [];
    for (var i = 0; i < charlength.length; i++) {
      result.push({
        ...this.state.objectLottoRate,
        code: this.state.onSettingLotto,
        rate: rate,
        type: type,
        number: charlength[i],
      });
    }
    return result;
  };
  setValuestoArrayAllRelevant = (
    uniqNum: string[],
    values: ILottoRateNumber
  ) => {
    let result: IObjectLottoRate[] = [];

    uniqNum!.map((item) => {
      return (
        item.length > 0 &&
        result.push(
          {
            code: this.state.onSettingLotto,
            rate:
              item.length === 3
                ? values.threeUpRate!
                : item.length === 2
                ? values.twoUpRate!
                : values.runUpRate!,
            type:
              item.length === 3
                ? "THREE_UP"
                : item.length === 2
                ? "TWO_UP"
                : "RUN_UP",
            number: item,
          },
          {
            code: this.state.onSettingLotto,
            rate:
              item.length === 3
                ? values.threeToastRate!
                : item.length === 2
                ? values.twoDownRate!
                : values.runDownRate!,
            type:
              item.length === 3
                ? "THREE_TOAST"
                : item.length === 2
                ? "TWO_DOWN"
                : "RUN_DOWN",
            number: item,
          }
        )
      );
    });

    return result;
  };
  sixBack = (data: string, values: ILottoRateNumber) => {
    var arrResult: IObjectLottoRate[] = [];
    var arrNum = data.split("");
    var uniqNum = uniq([
      `${arrNum[0]}${arrNum[1]}${arrNum[2]}`,
      `${arrNum[0]}${arrNum[2]}${arrNum[1]}`,
      `${arrNum[1]}${arrNum[0]}${arrNum[2]}`,
      `${arrNum[1]}${arrNum[2]}${arrNum[0]}`,
      `${arrNum[2]}${arrNum[0]}${arrNum[1]}`,
      `${arrNum[2]}${arrNum[1]}${arrNum[0]}`,
    ]);

    arrResult = this.setValuestoArray(
      uniqNum,
      values.rate!,
      this.state.lottoType
    );

    return arrResult;
  };

  reverseNum = (data: string, values: ILottoRateNumber) => {
    var dataReverse = data.split("").reverse().join("");

    if (data === dataReverse) {
      return [
        {
          ...this.state.objectLottoRate,
          number: dataReverse,
          code: this.state.onSettingLotto,
          rate: values.rate,
          type: this.state.lottoType,
        },
      ];
    }
    return [
      {
        ...this.state.objectLottoRate,
        code: this.state.onSettingLotto,
        rate: values.rate,
        type: this.state.lottoType,
        number: data,
      },
      {
        ...this.state.objectLottoRate,
        code: this.state.onSettingLotto,
        rate: values.rate,
        type: this.state.lottoType,
        number: dataReverse,
      },
    ];
  };

  nineteendoor = (data: string, values: ILottoRateNumber) => {
    var arrResult: IObjectLottoRate[] = [];
    var all19Num = [];
    var arrNum = data.split("");
    var firstDigit = arrNum[0];
    var secondDigit = arrNum[1];

    for (var i = 0; i < 10; i++) {
      var concatNum = firstDigit.concat(String(i));
      var concatNumReverse = String(i).concat(firstDigit);
      var concatNumSecond = secondDigit.concat(String(i));
      var concatNumSecondReverse = String(i).concat(secondDigit);
      all19Num.push(
        concatNum,
        concatNumSecond,
        concatNumReverse,
        concatNumSecondReverse
      );
    }

    var uniqNum = uniq(all19Num.sort());

    arrResult = this.setValuestoArray(
      uniqNum,
      values.rate!,
      this.state.lottoType
    );

    return arrResult;
  };

  all2Relevant = (data: string, values: ILottoRateNumber) => {
    var arrResult: IObjectLottoRate[] = [];
    var arrNum = data.split("");
    var arrNumfor = [];
    var reverseNum = data.split("").reverse().join("");

    for (var i = 0; i < 10; i++) {
      var concatNum = data.concat(String(i));
      var concatNumReverse = String(i).concat(data);
      var concatNumSecond = reverseNum.concat(String(i));
      var concatNumSecondReverse = String(i).concat(reverseNum);
      arrNumfor.push(
        concatNum,
        concatNumReverse,
        concatNumSecond,
        concatNumSecondReverse
      );
    }

    var uniqNum = [
      uniq(arrNumfor.sort()),
      uniq([
        `${arrNum[0]}${arrNum[1]}`,
        `${arrNum[1]}${arrNum[0]}`,
        `${arrNum[0]}`,
        `${arrNum[1]}`,
      ]),
    ].flat();

    arrResult = this.setValuestoArrayAllRelevant(uniqNum, values!);

    return arrResult;
  };

  all3Relevant = (data: string, values: ILottoRateNumber) => {
    var arrResult: IObjectLottoRate[] = [];
    var arrNum = data.split("");
    var uniqNum = uniq([
      `${arrNum[0]}${arrNum[1]}${arrNum[2]}`,
      `${arrNum[0]}${arrNum[2]}${arrNum[1]}`,
      `${arrNum[1]}${arrNum[0]}${arrNum[2]}`,
      `${arrNum[1]}${arrNum[2]}${arrNum[0]}`,
      `${arrNum[2]}${arrNum[0]}${arrNum[1]}`,
      `${arrNum[2]}${arrNum[1]}${arrNum[0]}`,
      `${arrNum[0]}${arrNum[1]}`,
      `${arrNum[0]}${arrNum[2]}`,
      `${arrNum[1]}${arrNum[0]}`,
      `${arrNum[1]}${arrNum[2]}`,
      `${arrNum[2]}${arrNum[0]}`,
      `${arrNum[2]}${arrNum[1]}`,
      `${arrNum[0]}`,
      `${arrNum[1]}`,
      `${arrNum[2]}`,
    ]);

    arrResult = this.setValuestoArrayAllRelevant(uniqNum, values!);
    return arrResult;
  };

  all1Relevant = (data: string, values: ILottoRateNumber) => {
    var arrResult: IObjectLottoRate[] = [];
    var arrNumFor = [];

    arrNumFor.push(data);
    for (var i = 0; i < 10; i++) {
      for (var j = 0; j < 10; j++) {
        arrNumFor.push(data.concat(String(i)).concat(String(j)));
        arrNumFor.push(String(i).concat(data).concat(String(j)));
        arrNumFor.push(String(i).concat(String(j)).concat(data));
      }
    }

    for (var ii = 0; ii < 10; ii++) {
      arrNumFor.push(data.concat(String(ii)));
      arrNumFor.push(String(ii).concat(data));
    }

    var uniqNum = uniq(arrNumFor.sort());

    arrResult = this.setValuestoArrayAllRelevant(uniqNum, values!);
    return arrResult;
  };

  handleChangeRate = (values: ILottoRateNumber) => {
    const { number } = values;
    if (this.state.isAllRelevantActivated) {
      if (number.length === 3) {
        return this.all3Relevant(number, values);
      } else if (number.length === 2) {
        return this.all2Relevant(number, values);
      } else {
        return this.all1Relevant(number, values);
      }
    } else {
      if (this.state.is6BackActivated && number.length === 3) {
        return this.sixBack(number, values);
      } else if (this.state.is19DoorActivated && number.length === 2) {
        return this.nineteendoor(number, values);
      } else if (this.state.isReverseNumActivated) {
        return this.reverseNum(number, values);
      } else {
        let arrResult = [];
        arrResult.push({
          ...this.state.objectLottoRate,
          code: this.state.onSettingLotto,
          type: this.state.lottoType,
          rate: values.rate,
          number: values.number,
        });
        return arrResult;
      }
    }
  };

  renderModeList = (formikProps: FormikProps<ILottoRateNumber>) => {
    const LottoRateButtonMode = {
      is6BackActivated: this.state.is6BackActivated,
      isReverseNumActivated: this.state.isReverseNumActivated,
      is19DoorActivated: this.state.is19DoorActivated,
      isAllRelevantActivated: this.state.isAllRelevantActivated,
    };
    const handleSetButtonStatus = (key: object) => {
      this.setState(key);
    };
    return (
      <RenderModeComponent
        formikProps={formikProps}
        lottoType={this.state.lottoType}
        LottoRateButtonMode={LottoRateButtonMode}
        handleSetButtonStatus={handleSetButtonStatus}
        isCheckEmptyForm={this.isCheckEmptyForm(formikProps)}
        resetData={this.resetData}
        resetMode={this.resetMode}
        inputRef={this.inputRef}
        rateRef={this.rateRef}
      />
    );
  };

  putLottoRate = (resetForm: Function) => {
    const result = groupBy(this.state.listNumber, "type");
    let dataListFail: ILottoRateNumberErrorObject[] = [];
    const promise = this.state.isAllRelevantActivated
      ? [
          !isEmpty(result.THREE_UP) &&
            fetchPuttLottoRateNumber(result.THREE_UP).catch(
              (err) => err.response
            ),
          !isEmpty(result.THREE_TOAST) &&
            fetchPuttLottoRateNumber(result.THREE_TOAST).catch(
              (err) => err.response
            ),
          !isEmpty(result.TWO_UP) &&
            fetchPuttLottoRateNumber(result.TWO_UP).catch(
              (err) => err.response
            ),
          !isEmpty(result.TWO_DOWN) &&
            fetchPuttLottoRateNumber(result.TWO_DOWN).catch(
              (err) => err.response
            ),
          !isEmpty(result.RUN_UP) &&
            fetchPuttLottoRateNumber(result.RUN_UP).catch(
              (err) => err.response
            ),
          !isEmpty(result.RUN_DOWN) &&
            fetchPuttLottoRateNumber(result.RUN_DOWN).catch(
              (err) => err.response
            ),
        ].filter((data) => data)
      : [
          !isEmpty(result[this.state.lottoType]) &&
            fetchPuttLottoRateNumber(result[this.state.lottoType]).catch(
              (err) => err.response
            ),
        ];

    Promise.all(promise)
      .then((responseArray) => {
        responseArray.forEach((item) => {
          if (item.data?.code === 400128) {
            let dataFail = {
              type: JSON.parse(item.config.data)[0].type,
              ...item.data,
            };
            dataListFail.push(dataFail);
          }
        });

        if (dataListFail.length > 0) {
          this.setState({ isModalShowError: true });
          ModalNumberRate.confirm.show({
            editAction: () => {
              this.cancelActionModal({
                isModalShowError: false,
              });
            },
            isResponseFailure: true,
            dataListFail: dataListFail,
            lottoList: this.state.listNumber,
            description: constants.confirmFailureText,
            description2: constants.confirmFailureRate,
          });
        } else {
          const checkCode = (data: APIResponse) => {
            return data.data?.code === 200;
          };

          if (responseArray.every(checkCode)) {
            this.setState({ isModalSuccessShow: true });
            Modal.success.show({
              action: () => {
                this.setState({ isModalSuccessShow: false });
                Modal.success.hide();
              },
              description: constants.changeRateSuccess,
            });
          } else {
            Modal.error.show({
              action: Modal.error.hide,
              description: constants.changeRateUnSuccess,
            });
          }
          resetForm({});
          this.resetMode();
        }
      })
      .catch((err) => {
        console.log(err);
        Modal.confirm.show({
          action: () => {
            Modal.error.hide();
          },
          typeIcon: "Alert",
          isCancelButton: false,
          child: (
            <>
              <h3 className={`primary-red-text`}>{constants.fail}</h3>
              <div className="d-flex flex align-items-end justify-content-center mb-2">
                <h5>{constants.description}</h5>
                <h5 className="ml-1">{constants.confirmFailureRate}</h5>
              </div>
            </>
          ),
          height: "330px",
          description: constants.confirmFailureText,
        });
      })
      .finally(() => {
        this.fetchLottoRate();
      });
  };
  fetchLottoRate = () => {
    this.setState(
      {
        RateNumberPage: 0,
        RateNumberHasMore: false,
        RateNumberIsFirst: true,
      },
      () => {
        this.props.clearLottoRateNumber();
        this.getLottoRateNumber();
      }
    );
  };

  cancelActionModal = (key: object) => {
    ModalNumberRate.confirm.hide();
    this.setState(key);
  };

  render() {
    const LottoRateNumberResult = get(
      this.props.getLottoRateNumberResult,
      "data",
      []
    );
    const RenderLottoList = this.renderLottoList().filter((Lotto) => {
      return (
        Lotto.props.children.props.title
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    const navigates: IBreadcrumbItem[] = [
      { label: constants.settingLabel, active: false },
      { label: constants.betRateLabel, active: true },
    ];

    const RenderMode = this.renderModeList;
    return (
      <div className="number-rate-container primary-bg">
        <Breadcrumb
          items={navigates}
          handleOnClickItem={this.handleOnClickBreadcrumb}
        />
        <TitlePage title={constants.betRateLabel} />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="m3-b">
              <TextField
                label="ค้นหา"
                startElement={<SearchIcon />}
                fullWidth={true}
                value={this.state.search}
                placeholder="ค้นหาหวย"
                onChange={(e) => this.updateSearch(e.target.value)}
                onFocus={() => this.setState({ isSearchActivated: true })}
                onBlur={() => this.setState({ isSearchActivated: false })}
              />
            </div>
            <div
              className={`lotto-body ${
                this.state.isAllRelevantActivated ? "allRelevant" : ""
              }`}
            >
              <SimpleBar style={{ height: "100%" }}>
                {RenderLottoList}
              </SimpleBar>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Paper className="paper-container secondary-dark">
              <div className="paper-title">
                <div className="lotto-action-text-wrapper">
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      className="d-flex flex align-items-center"
                    >
                      <div className="d-flex flex">
                        <ResponsiveIcon
                          icon={this.state.icon}
                          alt="flag"
                          className="lotto-action-card-flag m2-r"
                        />
                        <h3>{this.state.title}</h3>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      {this.state.onSettingLotto !== "LOTTER_LAO_SUITE" ? (
                        <Select
                          label={constants.status}
                          fullWidth={true}
                          value={this.state.lottoTypeSelect}
                          onChange={(e) => {
                            this.handleFilterStatus(e.target.value);
                          }}
                          itemList={lottoTypeList}
                        />
                      ) : (
                        <Select
                          label={constants.status}
                          fullWidth={true}
                          value={this.state.lottoTypeSelect}
                          onChange={(e) =>
                            this.handleFilterStatus(e.target.value)
                          }
                          itemList={[
                            {
                              value: "FOUR_SUITE",
                              element: <h6>{constants.fourSuit}</h6>,
                            },
                          ]}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Formik
                initialValues={initialFormValue}
                validationSchema={scheme(
                  this.state.lottoType
                  // this.state.isAllRelevantActivated!
                )}
                innerRef={(instance: FormikProps<ILottoRateNumber>) =>
                  (this.FormikInstance = instance)
                }
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                  this.setState({
                    isModalShow: true,
                    listNumber: this.handleChangeRate(values),
                  });

                  ModalNumberRate.confirm.show({
                    action: () => {
                      this.cancelActionModal({ isModalShow: false });
                      this.putLottoRate(resetForm);
                    },
                    cancelAction: () =>
                      this.cancelActionModal({ isModalShow: false }),

                    is19DoorOrAllRelevantActivated:
                      this.state.isAllRelevantActivated ||
                      this.state.is19DoorActivated
                        ? true
                        : false,
                    lottoList: this.state.listNumber,
                    handleCallback: (childData: IObjectLottoRate[]) => {
                      this.setState({ listNumber: childData });
                    },
                    description: constants.confirmEditRate,
                  });
                }}
              >
                {(formikProps: FormikProps<ILottoRateNumber>) => {
                  const { handleSubmit, resetForm } = formikProps;
                  const resetFormandSelectedMode = (key: string) => {
                    this.resetMode();
                    this.resetData(formikProps);
                    this.setState({ lottoType: key });
                  };
                  onkeydown = (keyDownEvent) => {
                    let focused = document.activeElement?.id;
                    !this.state.isSearchActivated &&
                      (() => {
                        switch (keyDownEvent.code) {
                          case "Tab":
                            switch (focused) {
                              case "number":
                                break;
                              case "rate":
                                keyDownEvent.preventDefault();
                                setTimeout(
                                  () => this.inputRef.current?.focus(),
                                  0
                                );
                                break;
                              case "rate1":
                                break;
                              case "rate2":
                                break;
                              case "rate3":
                                break;
                              case "rate4":
                                break;
                              case "rate5":
                                break;
                              case "rate6":
                                keyDownEvent.preventDefault();
                                setTimeout(
                                  () => this.inputRef.current?.focus(),
                                  0
                                );
                                break;
                              default:
                                setTimeout(
                                  () => this.inputRef.current?.focus(),
                                  0
                                );
                                break;
                            }

                            break;
                          case "Enter":
                          case "NumpadEnter":
                            keyDownEvent.preventDefault();
                            if (this.state.isModalSuccessShow) {
                              this.setState({ isModalSuccesShow: false });
                              Modal.success.hide();
                            }
                            if (this.state.isModalShow) {
                              keyDownEvent.preventDefault();
                              this.cancelActionModal({ isModalShow: false });
                              this.putLottoRate(resetForm);
                            } else if (this.state.isModalShowError) {
                              keyDownEvent.preventDefault();
                              this.cancelActionModal({
                                isModalShowError: false,
                              });
                            } else if (
                              !this.state.isModalShow &&
                              !this.state.isModalShowError
                            ) {
                              if (this.isCheckEmptyForm(formikProps)) {
                                break;
                              } else {
                                handleSubmit();
                              }
                            }
                            break;

                          case "Escape":
                            if (this.state.isModalShow) {
                              this.cancelActionModal({ isModalShow: false });
                            } else if (this.state.isModalShowError) {
                              this.cancelActionModal({
                                isModalShowError: false,
                              });
                            } else {
                              this.resetMode();
                              this.resetData(formikProps);
                            }
                            break;
                          case "KeyQ":
                            resetFormandSelectedMode("THREE_UP");
                            break;
                          case "KeyW":
                            resetFormandSelectedMode("THREE_TOAST");
                            break;
                          case "KeyE":
                            resetFormandSelectedMode("TWO_UP");
                            break;
                          case "KeyR":
                            resetFormandSelectedMode("TWO_DOWN");
                            break;
                          case "KeyT":
                            resetFormandSelectedMode("RUN_UP");
                            break;
                          case "KeyY":
                            resetFormandSelectedMode("RUN_DOWN");
                            break;

                          case "KeyA":
                            if (
                              this.state.lottoType === "TWO_UP" ||
                              this.state.lottoType === "TWO_DOWN"
                            ) {
                              this.setState({
                                is19DoorActivated:
                                  !this.state.is19DoorActivated,
                              });
                            } else if (
                              this.state.lottoType === "THREE_UP" ||
                              this.state.lottoType === "THREE_TOAST"
                            )
                              this.setState({
                                is6BackActivated: !this.state.is6BackActivated,
                              });

                            break;
                          case "KeyS":
                            if (
                              this.state.lottoType === "RUN_UP" ||
                              this.state.lottoType === "RUN_DOWN"
                            )
                              break;
                            else {
                              this.setState({
                                isReverseNumActivated:
                                  !this.state.isReverseNumActivated,
                              });
                            }

                            break;
                          case "KeyD":
                            this.setState({
                              isAllRelevantActivated:
                                !this.state.isAllRelevantActivated,
                            });
                            break;
                          default:
                        }
                      })();
                  };
                  return (
                    // @ts-ignore

                    <Form autoComplete="off">
                      <InfiniteScroll
                        dataLength={LottoRateNumberResult.length}
                        next={() => {
                          this.getLottoRateNumber();
                        }}
                        hasMore={this.state.RateNumberHasMore}
                        height={332}
                        style={{ maxHeight: "600px", minHeight: "150px" }}
                        loader={<RollerLoading />}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    paddingRight:
                                      column.id === "rate" ? "7%" : "16px",
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {isEmpty(LottoRateNumberResult) ? (
                              <TableRow hover role="checkbox">
                                <TableCell key={"no data"}>
                                  ไม่มีข้อมูล...
                                </TableCell>
                              </TableRow>
                            ) : (
                              LottoRateNumberResult.map(
                                (
                                  row: IResponseLotterRateNumberAll,
                                  index: number
                                ) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={row.number}
                                    >
                                      <TableCell
                                        key={`number${index}`}
                                        align="left"
                                      >
                                        {row.number}
                                      </TableCell>
                                      <TableCell
                                        key={`rate${index}`}
                                        align="right"
                                        style={{ paddingRight: "7%" }}
                                      >
                                        {number.decimalsFormatCommaWithOutZero(
                                          row.rate
                                        )}
                                      </TableCell>
                                      <TableCell
                                        key={`date${index}`}
                                        align="center"
                                      >
                                        {date.FormatDate(
                                          row.updatedAt,
                                          "Do MMM YY | k:mm น."
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )
                            )}
                          </TableBody>
                        </Table>
                      </InfiniteScroll>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="m3-t">
                            <Select
                              fullWidth={true}
                              value={this.state.lottoType}
                              onChange={(e) => {
                                this.setState(
                                  { lottoType: e.target.value },
                                  () => {
                                    resetForm({});
                                    this.resetMode();
                                  }
                                );
                              }}
                              itemList={
                                this.state.onSettingLotto !== "LOTTER_LAO_SUITE"
                                  ? lottoTypeList
                                  : [
                                      {
                                        value: "FOUR_SUITE",
                                        element: <h6>{constants.fourSuit}</h6>,
                                      },
                                    ]
                              }
                            />
                          </div>
                        </Grid>
                        <RenderMode {...formikProps} />
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LottoRateContainer;
