import axios, { AxiosResponse } from "axios"
import project from 'constants/project'
import { transformer } from 'utils'


//GET: api/v2/promo/condition/read
const fetchPromoCondition = (query: IV2ReadPromotionConditionRead): Promise<AxiosResponse<APIResponse<IV2RespReadPromotionConditionRead>>> => {
  const endpoint = `${project.environment[project.environmentName].api}/v2/promo/condition/read`
  return axios.get(`${endpoint}${transformer.urlSearchParamsSnakecase(query)}`)
}


//PT2.POST: api/v2/promo/condition/update
const postPromoUpdate = (data: IV2PromotionConditionUpdate): Promise<AxiosResponse<APIResponse<IV2PromotionConditionUpdate>>> => {
  const endpoint = `${project.environment[project.environmentName].api}/v2/promo/condition/update`
  const req = {
    user_id: data.userId,
    bonus_current:data.bonusCurrent,
    turn_current:data.turnCurrent
  }
  return axios.post(endpoint, req)
}

export {
  fetchPromoCondition,
  postPromoUpdate
}