import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const loginReducer = (state: ILoginState = initialState, action: RootAction):
  ITransactionAllState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.getTransactionAllAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getTransactionAllSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        // data: [...state.data,action.payload.data.data.dataList],
        code: action.payload.data.code,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.limit * action.payload.data.data.total),
        pageTotal: action.payload.data.data.total
      }

    case getType(actions.getTransactionAllFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    case getType(actions.updateTransactionAllAction):
      return {
        isFetching: true,
        ...state,
      }
    case getType(actions.updateTransactionAllActionSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.dataList,
        page: (action.payload.data.page - 1),
        limit: action.payload.data.limit,
        total: (action.payload.data.limit * action.payload.data.total),
      }
    case getType(actions.updateTransactionWithdrawAllAction):
      return {
        isFetching: true,
        ...state,
      }
    case getType(actions.updateTransactionWithdrawlActionSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.dataList,
        page: (action.payload.data.page - 1),
        limit: action.payload.data.limit,
        total: (action.payload.data.limit * action.payload.data.total),
      }
    case getType(actions.updateTransactionAllActionFailureAction):
      return {
        ...state,
      }
    case getType(actions.clearTransactionAll):
      return initialState
    default:
      return state
  }
}

export default loginReducer