import project from 'constants/project'

export const PUT_TRANSACTION_REQUEST = 'PUT_TRANSACTION_REQUEST'
export const PUT_TRANSACTION_SUCCESS = 'PUT_TRANSACTION_SUCCESS'
export const PUT_TRANSACTION_FAILURE = 'PUT_TRANSACTION_FAILURE'
export const PUT_TRANSACTION_CANCEL = 'PUT_TRANSACTION_CANCEL'

export const initialState: ITransactionPutState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  putTransaction: `${project.environment[project.environmentName].api}/finance/checked`,
}