import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Breadcrumb, TitlePage, Button, Tap } from 'components'
import React, { Component } from 'react'
import './overview.style.scss'
import { get, isEmpty, noop } from 'lodash'
import colors from 'constants/colors'
import bank from 'assets/images/global/bank'
// import SearchIcon from '@material-ui/icons/Search'
import BadgeComponent from 'components/Badge/Badge.component'
import account1 from 'assets/images/global/account/account1.png'
import account2 from 'assets/images/global/account/account2.png'
import account3 from 'assets/images/global/account/account3.png'
import moment, { Moment } from 'moment'

import { History } from 'history';
import { number, transformer } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ResponsiveLine } from '@nivo/line'

const constants = {
  depositLabel: 'รายงานยอดรายวัน',
  systemLabel: 'จัดการระบบ',
  titlePage: 'รายงานยอดรายวัน',
  startDateTime: 'เริ่มเก็บข้อมูล',
  tapDeposit:'บัญชีฝาก',
  tapWithdraw:'บัญชีถอน',
  search:'ค้นหา',
  dataEmpty:'ไม่มีข้อมูล...',
  summary:'สรุปยอด : ',
  bath:' บาท',
  dataSummary:'ข้อมูลสะสม',
  startDate:'เริ่ม ',
  titleBet:'เดิมพัน',
  summaryResult:'สรุป',
  win:'กำไร',
  lose:'ขาดทุน',
  DepositWithdraw:'ฝาก / ถอน',
  Deposit:'ฝาก',
  Withdraw:'ถอน',
  member:'สมาชิก',
  memberNew:'ลูกค้าสมัครใหม่ ',
  memberAff:'ลูกค้าสมัครแนะนำ ',
  memberTotal:'ลูกค้ารวม ',
  unitPeople:' คน'

}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IOverviewActionProps & IOverviewProps = {
  getWebBank() { noop() },
  getWebbankSummary() { noop() },
  loader() { noop() },
  getBetSummary() { noop() },
  getMemberSummary() { noop() },
  getFinanceSummary() { noop() },

  getBetChartSummary() { noop() },
  getMemberChartSummary() { noop() },
  getFinanceChartSummary() { noop() },

  changeDateStart() { noop() },

  getDateTime: '',

  getWebBankIsFetching: false,
  getWebBankError: '',
  getWebBankCode: 0,
  getWebBankResult: {
    isFetching: false,
    data: [],
    error: '',
    code: '',
    total: 0,
    page: 0,
    limit: 0,
  },

  getWebBankSummaryIsFetching: false,
  getWebBankSummaryData: {},
  getWebBankSummaryError: '',
  getWebBankSummaryCode: '',

  getBetSummaryIsFetching: false,
  getBetSummaryData: {},
  getBetSummaryError: '',
  getBetSummaryCode: '',

  getMemberSummaryIsFetching: false,
  getMemberSummaryData: {},
  getMemberSummaryError: '',
  getMemberSummaryCode: '',

  getFinanceSummaryIsFetching: false,
  getFinanceSummaryData: {},
  getFinanceSummaryError: '',
  getFinanceSummaryCode: '',

  getBetChartSummaryIsFetching: false,
  getBetChartSummaryData: [],
  getBetChartSummaryError: '',
  getBetChartSummaryCode: '',

  getMemberChartSummaryIsFetching: false,
  getMemberChartSummaryData: [],
  getMemberChartSummaryError: '',
  getMemberChartSummaryCode: '',

  getFinanceChartSummaryIsFetching: false,
  getFinanceChartSummaryData: [],
  getFinanceChartSummaryError: '',
  getFinanceChartSummaryCode: '',
}

const columns: IListBankColumn<'bank' | 'name' | 'number' | 'amount'>[] = [
    { id: 'bank', label: 'ธนาคาร', minWidth: 50, align: 'left' },
    { id: 'name', label: 'ชื่อ', minWidth: 50, align: 'center' },
    { id: 'number', label: 'เลขที่', minWidth: 50, align: 'center' },
    { id: 'amount', label: 'ยอด', minWidth: 50, align: 'right' },
  ];

class OverviewContainer extends Component<DefaultProps & IOverviewContainerState & IOverviewActionProps & IOverviewProps & { history: History }> {

    static defaultProps = defaultProps
    state: IOverviewContainerState = {
      currentTap: 0,
      search:'',
      isFirst:true,
      hasMore:false,
      page:0,
      queryTimerSearch: setTimeout(() => { noop() }, 0),
      dataChartBet: [],
      dataChartMember: [],
      dataChartFinance: [],

      dataPreviewChartBet: [],
      dataPreviewChartMember: [],
      dataPreviewChartFinance: [],
      hoverChart: ''
    }

    componentDidMount(){
      let dateStart = isEmpty(this.props.getDateTime)?moment():this.props.getDateTime
      this.getDataAll(dateStart)
    }

    getDataAll=(dateStart:string | Moment)=>{
      let dateQuery = {
        startDate:moment(isEmpty(dateStart)?moment():dateStart).format("YYYY-MM-DDTHH:mm:ss"),
        endDate:moment().format("YYYY-MM-DDTHH:mm:ss")
      }
      if (isEmpty(this.props.getDateTime)) {
        this.props.changeDateStart({datetime:moment().toString()})
      }
      this.getAllWebbank()
      this.props.getWebbankSummary()
      this.props.getBetSummary(dateQuery)
      this.props.getMemberSummary(dateQuery)
      this.props.getFinanceSummary(dateQuery)
      this.props.getBetChartSummary(dateQuery)
      this.props.getMemberChartSummary(dateQuery)
      this.props.getFinanceChartSummary(dateQuery)
    }
    
    componentDidUpdate(prevProps: IOverviewProps){
      if ( prevProps.getWebBankIsFetching !== this.props.getWebBankIsFetching
        && !this.props.getWebBankIsFetching ) {
          this.setState({
            page:this.props.getWebBankResult.page,
            isFirst: false,
            hasMore:this.props.getWebBankResult.page! < this.props.getWebBankResult.total!
          })
        this.props.loader(false)
      }
      
      if (prevProps.getWebBankSummaryIsFetching !== this.props.getWebBankSummaryIsFetching
        && !this.props.getWebBankSummaryIsFetching ) {
          this.props.loader(false)
      }

      if (prevProps.getBetSummaryIsFetching !== this.props.getBetSummaryIsFetching
        && !this.props.getBetSummaryIsFetching ) {
          this.props.loader(false)
      }

      if (prevProps.getMemberSummaryIsFetching !== this.props.getMemberSummaryIsFetching
        && !this.props.getMemberSummaryIsFetching ) {
          this.props.loader(false)
      }

      if (prevProps.getFinanceSummaryIsFetching !== this.props.getFinanceSummaryIsFetching
        && !this.props.getFinanceSummaryIsFetching ) {
          this.props.loader(false)
      }


      if (prevProps.getBetChartSummaryIsFetching !== this.props.getBetChartSummaryIsFetching
        && !this.props.getBetChartSummaryIsFetching ) {
          let data :IdataChart[] = [
            {id: constants.lose,label:constants.lose,"color": colors.PRIMARY_RED,"data": []},
            {id: constants.win,label:constants.win,"color": colors.PRIMARY_GREEN,"data": []}
          ]
          let dataPreview :IdataChart[] = [
            {id: constants.lose,label:constants.lose,"color": colors.PRIMARY_RED,"data": []},
            {id: constants.win,label:constants.win,"color": colors.PRIMARY_GREEN,"data": []}
          ]
          if(!isEmpty(this.props.getBetChartSummaryData)){
              this.props.getBetChartSummaryData.map((val,index)=>{
                const timeX = moment(get(val,'intervalHours','')).format("DDMMM HH:mm")
                data[0].data.push({x:timeX,y:get(val,'winnerTotal',0)})
                data[1].data.push({x:timeX,y:get(val,'loserTotal',0)})
                if (this.props.getBetChartSummaryData.length>5) {
                  if (index<5) {
                    dataPreview[0].data.push({x:timeX,y:get(val,'winnerTotal',0)})
                    dataPreview[1].data.push({x:timeX,y:get(val,'loserTotal',0)})
                  }
                }
                return {}
              })
          }else{
            const timeX = moment().format("DDMMM HH:mm")
            data[1].data.push({x:timeX,y:0})
            data[0].data.push({x:timeX,y:0})
          }
          this.setState({dataChartBet:data})
          this.setState({dataPreviewChartBet:dataPreview})
          this.props.loader(false)
      }

      if (prevProps.getMemberChartSummaryIsFetching !== this.props.getMemberChartSummaryIsFetching
        && !this.props.getMemberChartSummaryIsFetching ) {
          let data :IdataChart[] = [
            {id: "สมัครแนะนำ",label:"สมัครแนะนำ","color": colors.PRIMARY_GREEN,"data": []},
            {id: "สมัครใหม่",label:"สมัครใหม่","color": colors.PRIMARY_RED,"data": []}
          ]
          let dataPreview :IdataChart[] = [
            {id: "สมัครแนะนำ",label:"สมัครแนะนำ","color": colors.PRIMARY_RED,"data": []},
            {id: "สมัครใหม่",label:"สมัครใหม่","color": colors.PRIMARY_GREEN,"data": []}
          ]
          if(!isEmpty(this.props.getMemberChartSummaryData)){
            this.props.getMemberChartSummaryData.map((val,index)=>{
              const timeX = moment(get(val,'intervalHours','')).format("DDMMM HH:mm")
              data[0].data.push({x:timeX,y:get(val,'newMemberAff',0)})
              data[1].data.push({x:timeX,y:get(val,'newMemberNormal',0)})
              if (this.props.getMemberChartSummaryData.length>5) {
                if (index<5) {
                  dataPreview[0].data.push({x:timeX,y:get(val,'newMemberAff',0)})
                  dataPreview[1].data.push({x:timeX,y:get(val,'newMemberNormal',0)})
                }
              }
              return {}
            })
          }else{
            const timeX = moment().format("DDMMM HH:mm")
            data[1].data.push({x:timeX,y:0})
            data[0].data.push({x:timeX,y:0})
          }
          this.setState({dataChartMember:data})
          this.setState({dataPreviewChartMember:dataPreview})
          this.props.loader(false)
      }

      if (prevProps.getFinanceChartSummaryIsFetching !== this.props.getFinanceChartSummaryIsFetching
        && !this.props.getFinanceChartSummaryIsFetching ) {
          let data :IdataChart[] = [
            {id: constants.Withdraw,label:constants.Withdraw,"color": colors.PRIMARY_GREEN,"data": []},
            {id: constants.Deposit,label:constants.Deposit,"color": colors.PRIMARY_RED,"data": []}
          ]
          let dataPreview :IdataChart[] = [
            {id: constants.Withdraw,label:constants.Withdraw,"color": colors.PRIMARY_RED,"data": []},
            {id: constants.Deposit,label:constants.Deposit,"color": colors.PRIMARY_GREEN,"data": []}
          ]
          if(!isEmpty(this.props.getFinanceChartSummaryData)){
            this.props.getFinanceChartSummaryData.map((val,index)=>{
              const timeX = moment(get(val,'intervalHours','')).format("DDMMM HH:mm")
              data[1].data.push({x:timeX,y:get(val,'depositTotal',0)})
              data[0].data.push({x:timeX,y:get(val,'withdrawTotal',0)})
              if (this.props.getFinanceChartSummaryData.length>5) {
                if (index<5) {
                  dataPreview[1].data.push({x:timeX,y:get(val,'depositTotal',0)})
                  dataPreview[0].data.push({x:timeX,y:get(val,'withdrawTotal',0)})
                }
              }
              return {}
            })
          }else{
            const timeX = moment().format("DDMMM HH:mm")
            data[1].data.push({x:timeX,y:0})
            data[0].data.push({x:timeX,y:0})
          }
          this.setState({dataChartFinance:data})
          this.setState({dataPreviewChartFinance:dataPreview})
          this.props.loader(false)
      }
    }

    getAllWebbank=()=>{
      this.props.loader(true)
      this.props.getWebBank({
        queryStatus: '',
        page: this.state.isFirst?0:this.state.page,
        limit: 10,
        search: this.state.search,
        operate_type: this.state.currentTap===0?'DEPOSIT':'WITHDRAW'
      },this.state.isFirst)
    }
    
    handleOnClickBreadcrumb = (path: string) => {
      this.props.history.replace(path)
    }

    currentTap = (current: number)=>{
      this.setState({currentTap:current,isFirst:true},()=>{
        if (this.state.currentTap===0) {
          this.getAllWebbank()
          this.props.getWebbankSummary()
        }else if (this.state.currentTap===1) {
          this.getAllWebbank()
          this.props.getWebbankSummary()
        }
      })
    }

    updateSearch(value: any) {
      clearTimeout(this.state.queryTimerSearch)
      this.setState({ search: value.substr(0, 20),isFirst:true},()=>{
        this.setState({queryTimerSearch: setTimeout(() => {
          this.getAllWebbank()
        }, 2000)})
      })
    }

    comma(value:number){
      return Intl.NumberFormat('th-TH').format(value)
    }

    MyResponsiveLine = (data:IdataChart[] , tpye:string) => {
      let formatAxisLeftMember = (val: number | string | Date)=> Number(val)%1===0 ? number.addComma(Number(val)): ''
      let formatAxisLef = (val: number | string | Date)=> number.addComma(Number(val)).toString()

      return <ResponsiveLine
          data={data}
          margin={{ top: 10, right: 60, bottom: 50, left: tpye==='member'?50:80 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
          yFormat=" >-,.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -10,
              legendOffset: 45,
              legendPosition: 'middle'
          }}
          axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
              format: tpye==='member' ? formatAxisLeftMember : formatAxisLef
          }}
          colors={tpye==='member' ?[colors.PRIMARY_GREEN, colors.PRIMARY_TEXT]:[colors.PRIMARY_RED, colors.PRIMARY_GREEN]}
          enableGridX={false}
          enableGridY={false}
          pointSize={5}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          enableSlices='x'
          useMesh={true}
          sliceTooltip={({ slice }) => {
            return (
                <div
                    style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                    }}
                >
                    {slice.points.map(point => (
                      <div
                      key={point.id}
                      style={{
                        color: point.serieColor===colors.PRIMARY_TEXT?colors.SECONDARY_DARK:point.serieColor,
                        padding: '3px 0',
                      }}
                      >
                          <div>{point.data.xFormatted}</div>
                          <strong>{point.serieId}</strong> {point.data.yFormatted}
                        </div>
                    ))}
                </div>
            )
        }}
          legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 45,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
      />
    }

    render() {
      const WebBankResult = get(this.props.getWebBankResult,'data',[])
      const dateTime = get(this.props,'getDateTime',moment())
      const navigates: IBreadcrumbItem[] = [
        { label: constants.systemLabel, active: false },
        { label: constants.depositLabel, active: true },
      ]
        return (
          <div className="depositTransaction-container">
            <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
            <Grid container className="pr-4">
              <Grid item xs={10}>
                <TitlePage className="mb-0" title={constants.titlePage} />
              </Grid>
              <Grid item xs={2} >
                <Button
                  onClick={()=>{
                    this.props.changeDateStart({datetime:moment().toString()})
                    this.getDataAll(moment())
                  }}
                  text={constants.startDateTime}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container className="pr-4">
              <Grid item xs={6} className="pt-1 pr-2">
                <Tap
                  id = "string"
                  listTap = {[constants.tapDeposit,constants.tapWithdraw]}
                  onClick = {this.currentTap}
                >
                  <div className="primary-dark bg-tap px-2 pb-2 m-2 pt-2">
                    {/* <Grid container spacing={2} className="mb-2">
                      <Grid item xs={7}></Grid>
                      <Grid item xs={5}>
                        <TextField
                          label={constants.search}
                          startElement={<SearchIcon />}
                          fullWidth={true}
                          value={this.state.search}
                          onChange={(e) => this.updateSearch(e.target.value)}
                        />
                      </Grid>
                    </Grid> */}
                    <TableContainer component={Paper} className="custom-scrollbar">
                      <InfiniteScroll
                        dataLength={WebBankResult.length}
                        next={()=>{this.getAllWebbank()}}
                        hasMore={this.state.hasMore!}
                        height={580}
                        style={{maxHeight:"620px",minHeight:'150px'}}
                        loader={<div className="boxLoader"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
                      >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isEmpty(WebBankResult)?
                            <TableRow hover role="checkbox">
                              <TableCell key={'no data'} >{constants.dataEmpty}</TableCell>
                            </TableRow>:
                            WebBankResult.map((item:any,index:number)=>(
                            <TableRow
                              key={`row-${index}`}
                              className={`row-table ${item.status!=="INACTIVE"?item.status==="ACTIVE"?'primary-green-alpha':'primary-yellow-alpha':''}`}
                            >
                              <TableCell
                                key={`bank-${index}`}
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className="mr-2"
                                    style={{ width: '30px', height: '30px', marginLeft: '5px',display: 'inline'}}
                                    key={`bankList-${index}`}
                                  >
                                    <img
                                      src={bank[item.type].Icon}
                                      style={{
                                        width: 'inherit',
                                        height: 'inherit',
                                      }}
                                      alt="thailand-flat"
                                    />
                                  </div>
                                  <label>{bank[item.type].name}</label>
                                </div>
                              </TableCell>
                              <TableCell
                                key={`name-${index}`}
                                align={'center'}
                              >
                                {item.name}
                              </TableCell>
                              <TableCell
                                key={`number-${index}`}
                                align={'center'}
                              >
                                {number.formatBankNumber(item.number, item.type)}
                              </TableCell>
                              <TableCell
                                key={`monney-${index}`}
                                align='right'
                                className='primary-purple-text'
                              >
                                {transformer.typeFormat(String(item.currentMoney))}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      </InfiniteScroll>
                    </TableContainer>
                    <h5 className="p-3 text-right">{constants.summary}
                      <label className="primary-green-text">
                        {this.state.currentTap===0?
                        this.comma(get(this.props.getWebBankSummaryData,'depositWebBankTotal',0)):
                        this.comma(get(this.props.getWebBankSummaryData,'withdrawWebBankTotal',0))}
                      </label>
                      {constants.bath}
                    </h5>
                  </div>
                </Tap>
              </Grid>
              <Grid item xs={6} className="mt-5 p-3 secondary-dark">
                <Grid container className="mt-2 mb-4">
                  <Grid item xs={6} >
                    <h5 className="body-1">{constants.dataSummary}</h5>
                  </Grid>
                  <Grid item xs={6} >
                    <h5 className="body-1 text-right pr-4" style={{marginLeft: 'auto'}}>{constants.startDate}{moment(isEmpty(dateTime)?moment():dateTime).add(543,'years').format("lll")}</h5>
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item xs={6} >
                    <div className="box-chart">
                      <div id="chartWinLose"
                        onMouseOver={()=>{this.setState({hoverChart:'chartWinLose'})}}
                        onMouseLeave={()=>{this.setState({hoverChart:''})}}
                      >
                        {this.MyResponsiveLine(
                          this.state.hoverChart==='chartWinLose'?
                          this.state.dataChartBet:
                          this.props.getBetChartSummaryData.length>5?
                          this.state.dataPreviewChartBet:this.state.dataChartBet,'chartWinLose')}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div className="pt-2 pl-3">
                      <h4 className="body-1">{constants.titleBet}</h4>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.win} backgroundColor={colors.PRIMARY_GREEN}/> 
                        </Grid>
                        <Grid item xs={8} >
                          <h5 className="text-right pr-4"><label className="primary-green-text">{this.comma(get(this.props.getBetSummaryData ,'loserTotal',''))}</label>{constants.bath}</h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.lose} backgroundColor={colors.PRIMARY_RED} />
                        </Grid>
                        <Grid item xs={8}>
                          <h5 className="text-right pr-4"><label className="primary-red-text">{this.comma(get(this.props.getBetSummaryData ,'winnerTotal',''))}</label>{constants.bath}</h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.summaryResult} backgroundColor={colors.PRIMARY_PURPLE} />
                        </Grid>
                        <Grid item xs={8}>
                          <h5 className="text-right pr-4">
                            <label className={`${get(this.props.getBetSummaryData ,'profitLoss',0)>=0?"primary-green-text":"primary-red-text"}`}>
                              {this.comma(get(this.props.getBetSummaryData ,'profitLoss',''))}
                            </label>{constants.bath}
                          </h5>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item xs={6} >
                    <div className="box-chart">
                      <div id="chartDepositWithdraw"
                        onMouseOver={()=>{this.setState({hoverChart:'chartDepositWithdraw'})}}
                        onMouseLeave={()=>{this.setState({hoverChart:''})}}
                      >
                        {this.MyResponsiveLine(
                          this.state.hoverChart==='chartDepositWithdraw'?
                          this.state.dataChartFinance:
                          this.props.getFinanceChartSummaryData.length>5?
                          this.state.dataPreviewChartFinance:this.state.dataChartFinance,'chartDepositWithdraw')}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div className="pt-2 pl-3">
                      <h4 className="body-1">{constants.DepositWithdraw}</h4>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.Deposit} backgroundColor={colors.PRIMARY_GREEN}/> 
                        </Grid>
                        <Grid item xs={8} >
                          <h5 className="text-right pr-4">
                            <label className="primary-green-text">{this.comma(get(this.props.getFinanceSummaryData,'depositTotal',''))}</label>{constants.bath}
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.Withdraw} backgroundColor={colors.PRIMARY_RED} />
                        </Grid>
                        <Grid item xs={8}>
                          <h5 className="text-right pr-4">
                            <label className="primary-red-text">{this.comma(get(this.props.getFinanceSummaryData,'withdrawTotal',''))}</label>{constants.bath}
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={4} >
                          <BadgeComponent text={constants.summaryResult} backgroundColor={colors.PRIMARY_PURPLE} />
                        </Grid>
                        <Grid item xs={8}>
                          <h5 className="text-right pr-4">
                            <label className={`${get(this.props.getFinanceSummaryData,'financeBalance',0)>=0?"primary-green-text":"primary-red-text"}`}>{this.comma(get(this.props.getFinanceSummaryData,'financeBalance',''))}</label>{constants.bath}
                          </h5>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="">
                  <Grid item xs={6} >
                    <div className="box-chart">
                      <div id="chartMember"
                        onMouseOver={()=>{this.setState({hoverChart:'member'})}}
                        onMouseLeave={()=>{this.setState({hoverChart:''})}}
                      >
                        {this.MyResponsiveLine(
                          this.state.hoverChart==='member'?
                          this.state.dataChartMember:
                          this.props.getMemberChartSummaryData.length>5?
                          this.state.dataPreviewChartMember:this.state.dataChartMember,'member')}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div className="pt-2 pl-3">
                      <h4 className="body-1">{constants.member}</h4>
                      <Grid container className="mt-2">
                        <Grid item xs={3} className="d-flex align-items-center">
                          <img
                            src={account1}
                            style={{
                              width: '40px',
                              height: '40px',
                            }}
                            alt="thailand-flat"
                          />
                        </Grid>
                        <Grid item xs={9} className="d-flex align-items-center">
                          <h5>{constants.memberNew}<label className="primary-green-text">{this.comma(get(this.props.getMemberSummaryData,'newMemberNormal',''))}</label>{constants.unitPeople}</h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={3} className="d-flex align-items-center">
                          <img
                            src={account3}
                            style={{
                              width: '40px',
                              height: '40px',
                            }}
                            alt="thailand-flat"
                          />
                        </Grid>
                        <Grid item xs={9} className="d-flex align-items-center">
                          <h5>{constants.memberAff}<label className="primary-green-text">{this.comma(get(this.props.getMemberSummaryData,'newMemberAff',''))}</label>{constants.unitPeople}</h5>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-2">
                        <Grid item xs={3} className="d-flex align-items-center">
                        <img
                            src={account2}
                            style={{
                              width: '40px',
                              height: '40px',
                            }}
                            alt="thailand-flat"
                          />
                        </Grid>
                        <Grid item xs={9} className="d-flex align-items-center">
                          <h5>{constants.memberTotal}<label className="primary-green-text">{this.comma(get(this.props.getMemberSummaryData,'totalMember',''))}</label>{constants.unitPeople}</h5>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
    }
}
export default OverviewContainer