import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import userLineAction from 'reduxs/user/line/actions'
import LineManagement from './LineManagement.container'

const mapStateToProps = (state: RootReducers): IUserLineManagementProps => ({
    postUserLineIsFetching: state.mantra.user.userLine.all.isFetching!,
    postUserLineError: state.mantra.user.userLine.all.error!,
    postUserLineCode: state.mantra.user.userLine.all.code!,
    postUserLineResult: state.mantra.user.userLine.all.data!,
    postUserLineLimit: state.mantra.user.userLine.all.limit!,
    postUserLinePage: state.mantra.user.userLine.all.page!,
    postUserLineTotal: state.mantra.user.userLine.all.total!,
    deleteUserLineIsFetching: state.mantra.user.userLine.delete.isFetching!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IUserLineManagementActionProps => bindActionCreators({
    postUserLine: userLineAction.postUserLineAction,
    deleteUserLine: userLineAction.deleteUserLineAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LineManagement)