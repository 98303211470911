import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetUserAll = (data: IGetUserParams) => {
    const queryParams = new URLSearchParams({
        permission: get(data, 'permission', ''),
        search: get(data, 'search', ''),
        phone_number: get(data, 'phone_number', ''),
        page: String(get(data,'page',1) + 1),
        limit: String(get(data,'limit','10')),
    })
    return axios.get(`${endpoint.getUserAll}`, {
        params: queryParams,
    })
}