export default {
  PRIMARY_DARK: "#1C2025",
  SECONDARY_DARK: "#282C34",
  PRIMARY_TEXT: "#E6E6E8",
  SECONDARY_TEXT: "#ADB0BB",
  TERTIARY_TEXT: "#8d8d8d",
  PRIMARY_RED: "#F44336",
  SECONDARY_RED: "#d32f2f",
  TERTIART_RED: "#E46067",
  PRIMARY_PURPLE: "#8A85FF",
  SECONDARY_PURPLE: "#7671FF",
  PRIMARY_GREEN: "#4CAF50",
  SECONDARY_GREEN: "#27A02C",
  TERTIARY_GREEN: "#6fcf97",
  PRIMARY_YELLOW: "#ffb751",
  TRANSPARENT: "rgba(0, 0, 0, 0)",
  PRIMARY_GRAY: "#8D8D8D",
};
