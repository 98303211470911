import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Root } from 'pages/Root'
import configureStore from 'configs/store'
import interceptor from 'configs/interceptor'
import { PersistGate } from 'redux-persist/lib/integration/react'
import 'configs/global'
import * as serviceWorker from './serviceWorker'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'assets/stylesheets/global.scss'

const { store, persistor } = configureStore()
interceptor({},store)

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#002884',
      main: '#1171ec',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiThemeProvider theme={theme}>
            <Root />
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </StrictMode>
  ),
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
