import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const lottoCalculateReducer =(state: ILottoCalculateState = initialState, action: RootAction): ILottoCalculateState => {
    switch (action.type) {
      case getType(actions.postLottoCalculateAction):
        return {
          ...state,
          isFetching: true,
        }
      case getType(actions.postLottoCalculateSuccessAction):
        return {
          isFetching: false,
          data: action.payload.data.data,
          code: action.payload.status,
        }

      case getType(actions.postLottoCalculateFailureAction):
        return {
          isFetching: false,
          error: action.payload.response?.data.devMessage,
          code: action.payload.response?.status,
        }
      default:
        return state
    }
  }

export default lottoCalculateReducer