import { padStart } from "lodash";
import { DECIMAL_DITGITS, COMMA } from "constants/regex";

const castToInteger = (numberString: string = "") => {
  const result = numberString.replace(COMMA, "").replace(DECIMAL_DITGITS, "");
  return result;
};
const padNumber = (value: string, ditgit: number) => {
  const padString = "0";
  return padStart(value, ditgit, padString);
};
const numberFormat = (data: string) => {
  return Intl.NumberFormat().format(Number(data));
};
const castToMoney = (value: number, spacing?: boolean) => {
  const locals = "th-TH";
  const currency = "THB";
  const money = new Intl.NumberFormat(locals, {
    style: "currency",
    currency,
  }).format(value || 0);
  if (spacing) {
    return money.replace(/^(\D+)/, "$1 ");
  }
  return money;
};
const decimalsFormatComma = (data: string) => {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(data));
};

const addComma = (value: number) =>
  ` ${new Intl.NumberFormat("th-TH").format(value || 0)} `;

const decimalsFormatCommaWithOutZero = (data: string) => {
  const result = Number(data)
    .toFixed(2)
    .replace(/\.?0*$/, "")
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return result;
};

const formatBankNumber = (value: string, type: string) => {
  if ((type === "GSB" || type === "BAAC") && value.length === 12) {
    return (
      value.slice(0, 1) +
      "-" +
      value.slice(1, 4) +
      "-" +
      value.slice(4, 8) +
      "-" +
      value.slice(8, 12)
    );
  } else if (type !== "GSB" && type !== "BAAC" && value.length === 10) {
    return (
      value.slice(0, 3) + "-" + value.slice(3, 9) + "-" + value.slice(9, 10)
    );
  } else if (value.length === 12) {
    return (
      value.slice(0, 1) +
      "-" +
      value.slice(1, 4) +
      "-" +
      value.slice(4, 8) +
      "-" +
      value.slice(8, 12)
    );
  } else {
    return value;
  }
};

export default {
  padNumber,
  castToInteger,
  numberFormat,
  castToMoney,
  formatBankNumber,
  decimalsFormatComma,
  addComma,
  decimalsFormatCommaWithOutZero,
};
