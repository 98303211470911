import { camelCase, get, keys, map,snakeCase } from 'lodash'
import moment from 'moment'
const isArray = (array: any) => Array.isArray(array)

const isObject = (object: any) => object === Object(object) && !isArray(object) && typeof object !== 'function';

const camelcaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[camelCase(key)] = camelcaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => camelcaseTransform(i))
    return newArray
  }
  return data
}

const calPercentage = (data: ITransformObjectNum2Percentage) => {
  for (const value of Object.keys(data)) {
    /* tslint:disable:no-bitwise */
    data[value] = String(Number(data[value]) * 100 | 0)
  }
  return data
}
const time1DigitTo2Digit = (time: string) => {
  if (time.length === 1) return `0${time}`
  return time
}

const seperateTimeString = (time: string): { hour: string; minutes: string } => {
  const timeSplit = time.split(':')
  return { hour: timeSplit[0], minutes: timeSplit[1] }
}

const urlSearchParams = <T = any>(data: T): string => {
  const dataKeys = keys(data) || []
  const params = new URLSearchParams()
  map(dataKeys, (key) => {
    const queryValue = get(data, key, '')
    if (queryValue) {
      params.append(key, queryValue)
    }
  })
  if (params.toString()) {
    return `?${params.toString()}`
  }
  return ''
}

const urlSearchParamsSnakecase = <T = any>(data: T): string => {
  const transformedData = snakecaseTransform(data)

  const dataKeys = keys(transformedData) || []
  const params = new URLSearchParams()
  map(dataKeys, (key) => {
    let queryValue = get(transformedData, key, '')
    
    if (isArray(queryValue)) {
      queryValue = JSON.stringify(queryValue)
    }
    if (queryValue) {
      params.append(key, queryValue)
    }
  })

  if (params.toString()) {
    return `?${params.toString()}`
  }
  return ''
}

const snakecaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[snakeCase(key)] = snakecaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => snakecaseTransform(i))
    return newArray
  }
  return data
}

const typeFormat = (data: string, spacing?: boolean) => {
  const money = Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(Number(data))
  if (spacing) {
    return money.replace(/^(\D+)/, '$1 ')
  }
  return money
}

const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const transformTime = (time: string) =>{
  return moment(time).add(543, 'years').format('Do MMM YY - k:mm')
}

const queryToObject = (): any => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params
}

export default {
  camelcaseTransform,
  time1DigitTo2Digit,
  seperateTimeString,
  IsJsonString,
  typeFormat,
  calPercentage,
  urlSearchParams,
  transformTime,
  urlSearchParamsSnakecase,
  queryToObject
}