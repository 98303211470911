import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const userAllReducer = (state: IGetMeState = initialState, action: RootAction):
  ITransactionAllState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.getUserAllAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getUserAllSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.limit * action.payload.data.data.total),
        code: action.payload.data.code,
      }
    case getType(actions.getUserAllFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}
export default userAllReducer