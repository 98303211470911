import React, { ChangeEvent, Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core'
import './EditAdmin.scss'
import { History } from 'history';
import { responseCode, responseMessage } from 'constants/response'
import { noop, get, isEmpty } from 'lodash'
import bank from 'assets/images/global/bank'
import {
    Button,
    TextField,
    TitlePage,
    Breadcrumb,
    InputNumber,
    Select,
    Modal
} from 'components'
import { Formik, Form } from 'formik'
import { schemeEdit } from '../models/scheme'

const constants = {
    placeholderUsername: 'ชื่อผู้ใช้ *',
    placeholderPassword: 'รหัสผ่าน *',
    placeholderConfirmPassword: 'ยืนยันรหัสผ่าน *',
    placeholderAffilate: 'รหัสคนชวน ',
    placeholderPhoneNumber: 'ระบุหมายเลขโทรศัพท์ 10 หลัก',
    placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),
    selectBankText: 'เลือกธนาคาร *',
    placeholderBankNumber: 'หมายเลขบัญชีธนาคาร *',
    placeholderOwnerName: 'ชื่อ - นามสกุลบัญชีธนาคาร *',
    buttonConfirm: 'สมัครสมาชิก',
    systemLabel: 'จัดการระบบ',
    adminLabel: 'หัวหน้าพนักงาน',
    editAdminLabel: 'แก้ไขหัวหน้าพนักงาน',
}

type DefaultProps = Readonly<typeof defaultProps>


const defaultProps: IUserPutManagementProps & IPutManagementActionProps = {
    putUser() { noop() },
    loader() { noop() },
    getUserAll() { noop() },
    getUserAllCode: 0,
    getUserAllError: '',
    getUserAllIsFetching: false,
    getUserAllResult: [],
    putUserCode: 0,
    putUserError: '',
    putUserIsFetching: false,
    putUserResult: [{
        id: 0,
        username: '',
        password: '',
        phoneNumber: '',
        walletId: 0,
        userBankId: 0,
        affilateMeUuid: '',
        permission: '',
        isFake: false,
        createdAt: '',
        updatedAt: '',
        bank: {
            id: 0,
            type: '',
            name: '',
            number: '',
            isFake: false,
            createdAt: '',
            updatedAt: '',
        },
        wallet: '',
        action: '',
    }],
}
class EditAdmin
    extends Component<IPutManagementActionProps & IUserPutManagementProps & { history: History },
    & IUserManagementCreateContainerState> {

    static defaultProps = defaultProps
    constructor(props: IPutManagementActionProps & IUserPutManagementProps & { history: History }
        & DefaultProps) {
        super(props)
        this.state = {
            bank:""
        }
    }
    componentDidMount(){
        this.props.getUserAll(this.getQueryParams())
    }

    componentDidUpdate(prevProps: IUserPutManagementProps) {
        if ((prevProps.putUserIsFetching !== this.props.putUserIsFetching
            && !this.props.putUserIsFetching)) {
            if (this.props.putUserCode === responseCode.OK) {
                this.props.loader(false)
                this.props.history.push('/admin-management')
            }
            else {
                this.props.loader(false)
                Modal.error.show({
                    action:  Modal.error.hide,
                    description: get(
                      responseMessage,
                      this.props.putUserCode!,
                      responseMessage[0]
                    ),
                  })
              }
        }
    }

    getQueryParams = (): IGetUserParams => {
        return {
          permission: 'ADMIN',
          search: (get(this.props.history.location.state, 'phoneNumber', '')),
          page: 0,
          limit: 1,
        }
      }

    onSubmitLogin = (values: IEditUser) => {
        const data = {
            id: (get(this.props.history.location.state, 'deleteUser', 0)),
            password: values.password,
            phone_number: isEmpty(values.phoneNumber)?get(this.props.getUserAllResult,"[0].phoneNumber",""):values.phoneNumber,

            bank_type: isEmpty(values.number)?get(this.props.getUserAllResult,"[0].userBank.type",""):values.bankCode,
            user_bank_name: isEmpty(values.name)?get(this.props.getUserAllResult,"[0].userBank.name",""):values.name,
            user_bank_number: isEmpty(values.number)?get(this.props.getUserAllResult,"[0].userBank.number",""):values.number,
        }
        if (!(isEmpty(data.bank_type)&&isEmpty(data.password)&&isEmpty(data.phone_number)&&isEmpty(data.user_bank_name)&&isEmpty(data.user_bank_number))) {
            Modal.confirm.show({
                action: () => {
                    this.props.loader(true)
                    this.props.putUser(data)
                    Modal.confirm.hide()
                },
                cancelAction: () => {
                    Modal.confirm.hide()
                },
            })
        }
    }

    handleWebBankChange = (bankName: string) => {
        this.setState({ bank: bankName })
    }
    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
    }
    formatBankNumber = (type:string)=>{
        return type === 'GSB' || type ==='BAAC'
        ? '# ### #### ####'
        : '### ###### #'
    }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.adminLabel, path: '/admin-management',active: false},
            { label: constants.editAdminLabel, active: true },
          ]
        let initialValueEditUser = {
            phoneNumber: get(this.props.getUserAllResult,"[0].phoneNumber",""),
            password: '',
            bankCode: get(this.props.getUserAllResult,"[0].userBank.type",""),
            name: get(this.props.getUserAllResult,"[0].userBank.name",""),
            number: get(this.props.getUserAllResult,"[0].userBank.number",""),
        }
        return (
            <div>
                <div className="edit-admin-container">
                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title={constants.editAdminLabel}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Paper className="paper-container secondary-dark">
                                <Formik
                                    initialValues={initialValueEditUser}
                                    validationSchema={schemeEdit}
                                    enableReinitialize
                                    onSubmit={this.onSubmitLogin}
                                >
                                    {
                                        ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                                // @ts-ignore
                                                <Form>
                                                    <div className="paper-body">
                                                        <TextField
                                                            onBlur={handleBlur}
                                                            name="password"
                                                            label="รหัสผ่าน"
                                                            fullWidth={true}
                                                            placeholder={constants.placeholderInput(constants.placeholderPassword)}
                                                            onChange={handleChange}
                                                            value={values.password.toLowerCase()}
                                                            error={!!errors.password && touched.password}
                                                            helperText={!!errors.password && touched.password ? `${errors.password}` : ''}
                                                        />
                                                        <InputNumber
                                                            name="phoneNumber"
                                                            label="หมายเลขโทรศัพท์"
                                                            fullWidth={true}
                                                            placeholder={constants.placeholderPhoneNumber}
                                                            format="### ### ####"
                                                            onValueChange={({value}) =>{setFieldValue('phoneNumber', value)}}
                                                            onBlur={(event: ChangeEvent<HTMLInputElement>)=>{
                                                                handleBlur(event)
                                                                if (isEmpty(values.phoneNumber)) {
                                                                    setFieldValue("phoneNumber", get(this.props.getUserAllResult,"[0].phoneNumber",""))
                                                                }
                                                            }}
                                                            value={values.phoneNumber.toLowerCase()}
                                                            error={!!errors.phoneNumber && touched.phoneNumber}
                                                            helperText={!!errors.phoneNumber && touched.phoneNumber ? `${errors.phoneNumber}` : ''}
                                                        />
                                                        <Select
                                                            name="bankCode"
                                                            label="เลือกธนาคาร"
                                                            // maxWidth="700px"
                                                            fullWidth={true}
                                                            value={values.bankCode}
                                                            onChange={(event) => {
                                                                this.handleWebBankChange(event.target.value)
                                                                setFieldValue(event.target.name, event.target.value)
                                                            }}

                                                            error={!!errors.bankCode && touched.bankCode}
                                                            helperText={!!errors.bankCode && touched.bankCode ? `${errors.bankCode}` : ''}
                                                            itemList={Object.keys(bank).map((item) => {
                                                                return {
                                                                // @ts-ignore
                                                                value: bank[item].key,
                                                                element: (
                                                                    <div>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                                                                <img
                                                                                    src={bank[item].Icon}
                                                                                    style={{
                                                                                    width: 'inherit',
                                                                                    height: 'inherit',
                                                                                    }}
                                                                                    alt="thailand-flat"
                                                                                />
                                                                            </div>
                                                                            <div style={{ display: 'grid' }}>
                                                                                <h6>{bank[item].name}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                                }
                                                            })

                                                            }
                                                        />
                                                        <TextField
                                                            name="name"
                                                            label="ชื่อเจ้าของบัญชี"
                                                            fullWidth={true}
                                                            onChange={handleChange}
                                                            onBlur={(event)=>{
                                                                handleBlur(event)
                                                                if (isEmpty(values.name)) {
                                                                    setFieldValue("name", get(this.props.getUserAllResult,"[0].userBank.name",""))
                                                                }
                                                            }}
                                                            value={values.name.toLowerCase()}
                                                            error={!!errors.name && touched.name}
                                                            helperText={!!errors.name && touched.name ? `${errors.name}` : ''}
                                                        />
                                                        <InputNumber
                                                            onBlur={(event: ChangeEvent<HTMLInputElement>)=>{
                                                                handleBlur(event)
                                                                if (isEmpty(values.number)) {
                                                                    setFieldValue("number", get(this.props.getUserAllResult,"[0].userBank.number",""))
                                                                }
                                                            }}
                                                            disabled={values.bankCode === '' ? true : false}
                                                            name="number"
                                                            label="เลขบัญชี"
                                                            fullWidth={true}
                                                            onValueChange={({ value }) => setFieldValue('number', value)}
                                                            decimalScale={0}
                                                            format={this.formatBankNumber(values.bankCode)}
                                                            allowNegative={false}
                                                            value={values.number.toLowerCase()}
                                                            errorMessage={errors.number}
                                                            error={!!errors.number && touched.number}
                                                            helperText={!!errors.number && touched.number ? `${errors.number}` : ''}
                                                        />

                                                    </div>

                                                    <div className="paper-bottom">
                                                        <Button
                                                            type="submit"
                                                            text="บันทึก"
                                                            fullWidth={true}
                                                        />
                                                    </div>
                                                </Form>
                                            )
                                    }
                                </Formik>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <></>
                        </Grid>
                    </Grid>
                </div >
            </div >
        )
    }
}

export default EditAdmin