import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const deleteUserReducer = (state: IGetMeState = initialState, action: RootAction): IUserDeleteState => {
  switch (action.type) {
      case getType(actions.deleteUserAction):
          return {
              ...state,
              isFetching: true,
          }
      case getType(actions.deleteUserSuccessAction):
          return {
              isFetching: false,
              data: action.payload.data.data,
              code: action.payload.data.code,
          }

      case getType(actions.deleteUserFailureAction):
          return {
              isFetching: false,
              error: action.payload.message,
              code: action.payload.code,
          }
      default:
          return state
  }
}
export default deleteUserReducer