import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetCreditInfo = (data: IGetCreditParams) => {
    const queryParams = new URLSearchParams({
        id: get(data, 'id', ''),
        page: String(get(data,'page','')),
        limit: String(get(data,'limit','')),
        money: get(data,'money',''),
    })
    return axios.get(`${endpoint.getCreditInfo}`, {
        params: queryParams,
    })
}