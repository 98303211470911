const project: IProjectConstants = {
  name: 'mantra',
  environmentName: process.env.REACT_APP_MANTRA_ENV || 'dev',
  environment: {
    prod: {
      name: 'production',
      api: 'https://prodapi.tlbetmantra.com/api',
      socket: 'https://prodapi.tlbetmantra.com',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
      jwtSecretKey: process.env.REACT_APP_JWT_SECRET_KEY || '',
    },
    release: {
      name: 'release',
      api: 'https://stapi.tlbetmantra.com/api',
      socket: 'https://stapi.tlbetmantra.com',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
      jwtSecretKey: process.env.REACT_APP_JWT_SECRET_KEY || '',
    },
    dev: {
      name: 'development',
      api: 'https://devapi.tlbetmantra.com/api',
      socket: 'https://devapi.tlbetmantra.com',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
      jwtSecretKey: process.env.REACT_APP_JWT_SECRET_KEY || '',
    },
    bifrost: {
      name: 'bifrost',
      api: 'https://bifrostapi.tlbetmantra.com/api',
      socket: 'https://bifrostapi.tlbetmantra.com',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
      jwtSecretKey: process.env.REACT_APP_JWT_SECRET_KEY || '',
    },
  },
}

export default project