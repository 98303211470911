import { createAction } from 'typesafe-actions'
import {
  GET_LOTTO_RATE_NUMBER_REQUEST,
  GET_LOTTO_RATE_NUMBER_SUCCESS,
  GET_LOTTO_RATE_NUMBER_FAILURE,
  GET_LOTTO_RATE_NUMBER_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getLottoRateNumberAction = createAction(GET_LOTTO_RATE_NUMBER_REQUEST,
  reslove => (data: IGetLottoRateLevelParams, isFirst?: boolean) =>
    reslove({ ...data, isFirst }))

const getLottoRateNumberSuccessAction = createAction(
  GET_LOTTO_RATE_NUMBER_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IResponseLotterRateNumberAll>>>, isFirst?: boolean) =>
    resolve({ ...data, isFirst }))

const getLottoRateNumberFailureAction = createAction(
  GET_LOTTO_RATE_NUMBER_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getLottoRateNumberCancelAction = createAction(GET_LOTTO_RATE_NUMBER_CANCEL)

export default {
  getLottoRateNumberAction,
  getLottoRateNumberSuccessAction,
  getLottoRateNumberFailureAction,
  getLottoRateNumberCancelAction,
}