import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootAction } from "typings/reduxs/Actions";
import ReportBenefitContainer from "./ReportBenefit.container";
import reportAction from 'reduxs/report/actions'

const mapStateToProps = (state: RootReducers): IReportBenefitProps => {
  return {
    getReportBenefitIsFetching: state.mantra.report.reportBenefit.isFetching!,
    getReportBenefitCode: state.mantra.report.reportBenefit.code!,
    getReportBenefitError: state.mantra.report.reportBenefit.error!,
    getReportBenefitResult: state.mantra.report.reportBenefit.data!,

    getReportBenefitLotteryIsFetching: state.mantra.report.reportBenefitLottery.isFetching!,
    getReportBenefitLotteryCode: state.mantra.report.reportBenefitLottery.code!,
    getReportBenefitLotteryError: state.mantra.report.reportBenefitLottery.error!,
    getReportBenefitLotteryResult: state.mantra.report.reportBenefitLottery.data!,

    getReportBenefitExternalCasinoIsFetching: state.mantra.report.reportBenefitExternalCasino.isFetching!,
    getReportBenefitExternalCasinoError: state.mantra.report.reportBenefitExternalCasino.error!,
    getReportBenefitExternalCasinoCode: state.mantra.report.reportBenefitExternalCasino.code!,
    getReportBenefitExternalCasinoResult: state.mantra.report.reportBenefitExternalCasino.data!,

    getReportBenefitExternalSlotIsFetching: state.mantra.report.reportBenefitExternalSlot.isFetching!,
    getReportBenefitExternalSlotError: state.mantra.report.reportBenefitExternalSlot.error!,
    getReportBenefitExternalSlotCode: state.mantra.report.reportBenefitExternalSlot.code!,
    getReportBenefitExternalSlotResult: state.mantra.report.reportBenefitExternalSlot.data!,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<RootAction>
): IReportBenefitActionProps => bindActionCreators({
  getReportBenefit: reportAction.getReportBenefitAction,
  getReportBenefitLottery: reportAction.getReportBenefitLotteryAction,
  getReportExternalCasino: reportAction.getReportBenefitExternalCasinoAction,
  getReportExternalSlot: reportAction.getReportBenefitExternalSlotAction,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBenefitContainer);
