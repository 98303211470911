import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import userDeleteAction from 'reduxs/user/delete/actions'
import loaderAction from 'reduxs/loader/actions'
import DeleteAdmin from './DeleteAdmin'

const mapStateToProps = (state: RootReducers): IUserDeleteManagementProps => {
  return {
    deleteUserIsFetching: state.mantra.user.userDelete.isFetching!,
    deleteUserError: state.mantra.user.userDelete.error!,
    deleteUserCode: state.mantra.user.userDelete.code!,
    deleteUserResult: state.mantra.user.userDelete.data!,
  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IDeleteManagementActionProps => bindActionCreators({
  deleteUser: userDeleteAction.deleteUserAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(DeleteAdmin)