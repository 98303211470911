import project from 'constants/project'

export const POST_USER_LINE_REQUEST = 'POST_USER_LINE_REQUEST'
export const POST_USER_LINE_SUCCESS = 'POST_USER_LINE_SUCCESS'
export const POST_USER_LINE_FAILURE = 'POST_USER_LINE_FAILURE'

export const DELETE_USER_LINE_REQUEST = 'DELETE_USER_LINE_REQUEST'
export const DELETE_USER_LINE_SUCCESS = 'DELETE_USER_LINE_SUCCESS'
export const DELETE_USER_LINE_FAILURE = 'DELETE_USER_LINE_FAILURE'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postUserLine: `${project.environment[project.environmentName].api}/line/user/all`,
  deleteUserLine: `${project.environment[project.environmentName].api}/line/user/delete`,
}