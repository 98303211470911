import project from 'constants/project'

export const PUT_LOTTO_STATUS_REQUEST = 'PUT_LOTTO_STATUS_REQUEST'
export const PUT_LOTTO_STATUS_SUCCESS = 'PUT_LOTTO_STATUS_SUCCESS'
export const PUT_LOTTO_STATUS_FAILURE = 'PUT_LOTTO_STATUS_FAILURE'
export const PUT_LOTTO_STATUS_CANCEL = 'PUT_LOTTO_STATUS_CANCEL'

export const initialState: ReducerState<ILottoSchedule[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  putLottoStatus: `${project.environment[project.environmentName].api}/config/web/lottery`,
}

