import React, { FC } from 'react'
import { noop,get } from 'lodash'
import { Button } from 'components'
import bank from 'assets/images/global/bank'
import { number, transformer } from 'utils'
import './success.style.scss'
import { Formik, Form } from 'formik'
import initialValue from '../models/initialValues'
import scheme from '../models/scheme'
import {
  Grid,
} from '@material-ui/core'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const constants = {
  title: 'เรียบร้อย',
  description: 'กรุณารอการตรวจสอบสักครู่',
  approval: 'อนุมัติ',
  cancel: 'ยกเลิก',
  bank: 'ธนาคาร: ',
  accountNo: 'หมายเลขบัญชี: ',
  amount: 'จำนวนเงิน: ',
  baht: ' บาท',
  bankTransfer: 'ธนาคารที่โอน',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IWithDrawSuccessModal = {
  title: constants.title,
  description: constants.description,
  cancel: constants.cancel,
  approval: constants.approval,
  txStatus: 'SUCCESS',
  txId: 0,
  webBankList: {
    id: 0,
    type: 'KBANK',
    name: '',
    number: '',
    status: 'INACTIVE',
    created_at: '',
    updated_at: '',
    operate_type: 'WITHDRAW'
  },
  action(webBankId: number, status: 'SUCCESS' | 'FAIL', txId: number) { noop() },
  cancelAction() { noop() },
}

const SuccessModal: FC<IWithDrawSuccessModal & DefaultProps> = (props: IWithDrawSuccessModal) => {

  // const [webBank, setWebBank] = useState('0')
  const handleSubmit = () => {
    props.action!(
      Number(props.webBankList!.id),
      props.txStatus,
      props.txId,
    )
  }
  return (
    <div className="success-modal-withdraw-container">
      <div className="title-success-modal-withdraw">
        <h4>{props.title}</h4>
      </div>
      <div className="sub-title-success-modal-withdraw">
        <h6>{props.subTitle}</h6>
      </div>
      <div className="description-success-modal-withdraw body-2">
        <SimpleBar style={{ minHeight: '100%', maxHeight: '100vh' }}>
          <div className="margin-bottom">
            {props.name}
          </div>
          <div className="description-setings">
            <div className="secondary-text">{constants.bank}</div>{props.bank}
          </div>
          <div className="description-setings">
            <div className="secondary-text">{constants.accountNo}</div>{number.formatBankNumber(get(props,'accountNo',''),get(props,'bank',''))}
          </div>
          <div className="amount-settings">
            <div className="secondary-text">{constants.amount}</div>
            <div className="primary-green-text">
              {transformer.typeFormat(props.money!)}
            </div>
            <div>{constants.baht}</div>
          </div>
          <div className="margin-bottom">
            {constants.bankTransfer}
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '40px', height: '40px', marginRight: '16px' }}>
                <img
                  src={bank[get(props.webBankList,'type', 'SCB')].Icon}
                  style={{ width: 'inherit', height: 'inherit' }}
                  alt="thailand-flat"
                />
              </div>
              <div style={{}}>
                <h6 style={{ textAlign: 'left' }}>{props.webBankList!.type}</h6>
                <h6 style={{ textAlign: 'left' }}>{number.formatBankNumber(props.webBankList!.number,props.webBankList!.type)}</h6>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>

      <Formik
        initialValues={initialValue}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={values => {
          //  tslint:disable-next-line:no-empty
        }}
      >
        {
          (
            {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
              setErrors,
              setTouched,

            }
          ) => {
            return (
              // @ts-ignore
              <Form>
                <Grid container justify="center">
                  <Grid item xs={4}>
                    { }
                  </Grid>

                  <Grid item xs={8}>
                    <div className="buttton-container">
                      <div className="footer-wrapper-success-modal">
                        <Button
                          onClick={props.cancelAction}
                          text={props.cancel!}
                          theme="outline"
                        />
                      </div>
                      <div className="footer-wrapper-success-modal">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          text={props.approval!}
                          color="green"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {/* <Select
                  error={!!errors.bank && touched.bank}
                  helperText={!!errors.bank && touched.bank ? `${errors.bank}` : ''}
                  name="bank"
                  label={constants.bankTransfer}
                  // maxWidth="315px"
                  placeholder="กรุณาเลือกธนาคารที่โอน"
                  fullWidth={true}
                  value={webBank}
                  onChange={(e) => {
                    setWebBank(e.target.value)
                    setErrors({
                      bank: '',
                    })
                    setTouched({
                      bank: false,
                    })
                  }}
                  isMultiline={true}
                  itemList={
                    props.webBankList!.map((webBankObj) => {
                      return {
                        value: webBankObj.id,
                        element: (
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '40px', height: '40px', marginRight: '16px' }}>
                                <img
                                  src={bank[webBankObj.type].Icon}
                                  style={{ width: 'inherit', height: 'inherit' }}
                                  alt="thailand-flat"
                                />
                              </div>
                              <div style={{ display: 'grid' }}>
                                <h6 style={{ textAlign: 'left' }}>{webBankObj.type}</h6>
                                <h6 style={{ textAlign: 'left' }}>{webBankObj.number}</h6>
                              </div>
                            </div>
                          </div>
                        ),
                      }
                    })
                  }
                />

                <Grid container justify="center">
                  <Grid item xs={4}>
                    {}
                  </Grid>

                  <Grid item xs={8}>
                    <div className="buttton-container">
                      <div className="footer-wrapper-success-modal">
                        <Button
                          onClick={props.cancelAction}
                          text={props.cancel!}
                          theme="outline"
                        />
                      </div>
                      <div className="footer-wrapper-success-modal">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          text={props.approval!}
                          color="green"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>  */}
              </Form>
            )
          }}
      </Formik>
    </div>
  )
}

SuccessModal.defaultProps = defaultProps

export default SuccessModal