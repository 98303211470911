import { createAction } from 'typesafe-actions'
import {
  POST_WEBBANK_REQUEST,
  POST_WEBBANK_CANCEL,
  POST_WEBBANK_SUCCESS,
  POST_WEBBANK_FAILURE,

  POST_MAP_WEBBANK_REQUEST,
  POST_MAP_WEBBANK_CANCEL,
  POST_MAP_WEBBANK_SUCCESS,
  POST_MAP_WEBBANK_FAILURE,

  GET_WEBBANK_REQUEST,
  GET_WEBBANK_CANCEL,
  GET_WEBBANK_SUCCESS,
  GET_WEBBANK_FAILURE,

  GET_ACTIVE_WEBBANK_REQUEST,
  GET_ACTIVE_WEBBANK_CANCEL,
  GET_ACTIVE_WEBBANK_SUCCESS,
  GET_ACTIVE_WEBBANK_FAILURE,

  GET_INACTIVE_WEBBANK_REQUEST,
  GET_INACTIVE_WEBBANK_CANCEL,
  GET_INACTIVE_WEBBANK_SUCCESS,
  GET_INACTIVE_WEBBANK_FAILURE,

  GET_WEBBANK_ACTIVE_REQUEST,
  GET_WEBBANK_ACTIVE_CANCEL,
  GET_WEBBANK_ACTIVE_SUCCESS,
  GET_WEBBANK_ACTIVE_FAILURE,

  PUT_WEBBANK_REQUEST,
  PUT_WEBBANK_CANCEL,
  PUT_WEBBANK_SUCCESS,
  PUT_WEBBANK_FAILURE,

  DELETE_WEBBANK_REQUEST,
  DELETE_WEBBANK_CANCEL,
  DELETE_WEBBANK_SUCCESS,
  DELETE_WEBBANK_FAILURE,

  UPDATE_WEBBANK_REQUEST,
  UPDATE_WEBBANK_SUCCESS,
  UPDATE_WEBBANK_FAILURE,

  LISTEN_WEBBANK_SOCKET,
  UNLISTEN_WEBBANK_SOCKET,
  CLEAR_WEBBANK,

} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getWebbankAction = createAction(
  GET_WEBBANK_REQUEST,
  reslove => (data: IGetWebBankParams) => reslove(data)
)

const getWebbankSuccessAction = createAction(
  GET_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IWebBank>>>) => resolve(data))

const getWebbankFailureAction = createAction(
  GET_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getWebbankCancelAction = createAction(GET_WEBBANK_CANCEL)

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const getActiveWebbankAction = createAction(
  GET_ACTIVE_WEBBANK_REQUEST,
  reslove => (data: IGetWebBankParams) => reslove(data)
)

const getActiveWebbankSuccessAction = createAction(
  GET_ACTIVE_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IWebBank>>>) => resolve(data))

const getActiveWebbankFailureAction = createAction(
  GET_ACTIVE_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getActiveWebbankCancelAction = createAction(GET_ACTIVE_WEBBANK_CANCEL)
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const getInActiveWebbankAction = createAction(
  GET_INACTIVE_WEBBANK_REQUEST,
  reslove => (data: IGetWebBankParams) => reslove(data)
)

const getInActiveWebbankSuccessAction = createAction(
  GET_INACTIVE_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IWebBank>>>) => resolve(data))

const getInActiveWebbankFailureAction = createAction(
  GET_INACTIVE_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getInActiveWebbankCancelAction = createAction(GET_INACTIVE_WEBBANK_CANCEL)
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const getWebbankActiveAction = createAction(
  GET_WEBBANK_ACTIVE_REQUEST,
  reslove => (data: IGetWebBankActiveParams) => reslove(data)
)

const getWebbankActiveSuccessAction = createAction(
  GET_WEBBANK_ACTIVE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IWebBank>>>) => resolve(data))

const getWebbankActiveFailureAction = createAction(
  GET_WEBBANK_ACTIVE_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getWebbankActiveCancelAction = createAction(GET_WEBBANK_ACTIVE_CANCEL)


const putWebbankAction = createAction(PUT_WEBBANK_REQUEST,
  reslove => (data: IPutWebbankStoreState) => reslove(data)
)

const putWebbankSuccessAction = createAction(
  PUT_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWebBank>>) => resolve(data))

const putWebbankFailureAction = createAction(
  PUT_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const putWebbankCancelAction = createAction(PUT_WEBBANK_CANCEL)



const deleteWebbankAction = createAction(DELETE_WEBBANK_REQUEST,
  reslove => (data: IDeleteWebbankStoreState) => reslove(data)
)

const deleteWebbankSuccessAction = createAction(
  DELETE_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWebBank>>) => resolve(data))

const deleteWebbankFailureAction = createAction(
  DELETE_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const deleteWebbankCancelAction = createAction(DELETE_WEBBANK_CANCEL)



const postWebbankAction = createAction(POST_WEBBANK_REQUEST,
  reslove => (data: IPostWebbankStoreState) => reslove(data)
)

const postWebbankSuccessAction = createAction(
  POST_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IWebBank>>) => resolve(data))

const postWebbankFailureAction = createAction(
  POST_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const postWebbankCancelAction = createAction(POST_WEBBANK_CANCEL)

const postMapWebbankAction = createAction(POST_MAP_WEBBANK_REQUEST,
  reslove => (data: IPostMapWebbankStoreState) => reslove(data)
)

const postMapWebbankSuccessAction = createAction(
  POST_MAP_WEBBANK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IMapWebBankRes>>) => resolve(data))

const postMapWebbankFailureAction = createAction(
  POST_MAP_WEBBANK_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const postMapWebbankCancelAction = createAction(POST_MAP_WEBBANK_CANCEL)


const updateWebbankAction = createAction(
  UPDATE_WEBBANK_REQUEST,
  resolve => (data: APIResponse<PaginationResponse<IWebBank>>) => resolve(data))

const updateWebbankSuccessAction = createAction(
  UPDATE_WEBBANK_SUCCESS,
  resolve => (data: APIResponse<PaginationResponse<IWebBank>>) => resolve(data))

const updateWebbankFailureAction = createAction(
  UPDATE_WEBBANK_FAILURE,
  resolve => (error: any) => resolve(error))


const listenWebbankSocketAction = createAction(
  LISTEN_WEBBANK_SOCKET,
  resolve => (data: IGetWebBankParams) => resolve(data))
const unlistenWebbankSocketAction = createAction(
  UNLISTEN_WEBBANK_SOCKET,
  resolve => () => resolve())

const clearWebbank = createAction(CLEAR_WEBBANK)

export default {
  getWebbankAction,
  getWebbankSuccessAction,
  getWebbankFailureAction,
  getWebbankCancelAction,

  getActiveWebbankAction,
  getActiveWebbankSuccessAction,
  getActiveWebbankFailureAction,
  getActiveWebbankCancelAction,

  getInActiveWebbankAction,
  getInActiveWebbankSuccessAction,
  getInActiveWebbankFailureAction,
  getInActiveWebbankCancelAction,

  getWebbankActiveAction,
  getWebbankActiveSuccessAction,
  getWebbankActiveFailureAction,
  getWebbankActiveCancelAction,

  putWebbankAction,
  putWebbankSuccessAction,
  putWebbankFailureAction,
  putWebbankCancelAction,

  deleteWebbankAction,
  deleteWebbankSuccessAction,
  deleteWebbankFailureAction,
  deleteWebbankCancelAction,

  postWebbankAction,
  postWebbankSuccessAction,
  postWebbankFailureAction,
  postWebbankCancelAction,

  postMapWebbankAction,
  postMapWebbankSuccessAction,
  postMapWebbankFailureAction,
  postMapWebbankCancelAction,

  updateWebbankAction,
  updateWebbankSuccessAction,
  updateWebbankFailureAction,

  listenWebbankSocketAction,
  unlistenWebbankSocketAction,
  clearWebbank,
}