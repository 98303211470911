import { createAction } from 'typesafe-actions'
import {
  POST_LOTTO_REFUND_REQUEST,
  POST_LOTTO_REFUND_SUCCESS,
  POST_LOTTO_REFUND_FAILURE,
  POST_LOTTO_REFUND_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postLottoRefundAction = createAction(
  POST_LOTTO_REFUND_REQUEST,
  reslove => (data: ILottoRefundPostData) => reslove(data)
  )

const postLottoRefundSuccessAction = createAction(
  POST_LOTTO_REFUND_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<any>>) => resolve(data))

const postLottoRefundFailureAction = createAction(
  POST_LOTTO_REFUND_FAILURE,
  resolve => (error: AxiosError<APIResponse<any>>) => resolve(error))

const postLottoRefundCancelAction = createAction(POST_LOTTO_REFUND_CANCEL)

export default {
  postLottoRefundAction,
  postLottoRefundSuccessAction,
  postLottoRefundFailureAction,
  postLottoRefundCancelAction,
}