import { getType } from 'typesafe-actions'
import { combineReducers } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const userAllReducer = (state: IGetMeState = initialState, action: RootAction):
  ITransactionAllState & IPaginationStore => {
  switch (action.type) {
    case getType(actions.postUserLineAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postUserLineSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: action.payload.data.data.total,
        code: action.payload.data.code,
      }
    case getType(actions.postUserLineFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const deleteUserLineReducer = (state: IGetMeState = initialState, action: RootAction): IUserLineDeleteState => {
  switch (action.type) {
      case getType(actions.deleteUserLineAction):
          return {
              ...state,
              isFetching: true,
          }
      case getType(actions.deleteUserLineSuccessAction):
          return {
              isFetching: false,
              data: action.payload.data.data,
              code: action.payload.data.code,
          }

      case getType(actions.deleteUserLineFailureAction):
          return {
              isFetching: false,
              error: action.payload.message,
              code: action.payload.code,
          }
      default:
          return state
  }
}

export default combineReducers({
  all: userAllReducer,
  delete: deleteUserLineReducer,
})