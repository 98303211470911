import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const yegeeGameConfigReducer = (state: IYegeeConfigState = initialState, action: RootAction): IYegeeConfigState => {
  switch (action.type) {
    case getType(actions.getYegeeGameConfigAllAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getYegeeGameConfigAllSuccessAction):
      return {
        isFetching: false,
        data:{
          betweenMinutes: action.payload.data.data.betweenMinutes,
          createdAt: action.payload.data.data.createdAt,
          expectRate: String(Number(action.payload.data.data.expectRate)*100),
          id:action.payload.data.data.id,
          updatedAt:action.payload.data.data.updatedAt,
          round:action.payload.data.data.round,
          startAt:action.payload.data.data.startAt,
        },
        code: action.payload.data.code,
      }

    case getType(actions.getYegeeGameConfigAllFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const putYegeeGameConfigReducer = (state: IPutBetRatesState = initialState, action: RootAction): any => {
  switch (action.type) {
    case getType(actions.putYegeeGameConfigAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.putYegeeGameConfigSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.data.code,
      }

    case getType(actions.putYegeeGameConfigFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

export {
  yegeeGameConfigReducer,
  putYegeeGameConfigReducer,
}