import React, { SFC } from 'react'
import { Switch } from '@material-ui/core'
import './Switches.component.scss'
import { noop } from 'lodash'


type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ISwitches = {
    name: '',
    color: 'default',
    onChange() { noop() },
    checked: false,
}


const SwitchesComponent: SFC<ISwitches & DefaultProps> = (props) => {
    const {
        color,
        onChange,
        name,
        checked,
    } = props

    return (
        <div className="switches-container">
            {
                (
                    <Switch
                        color={color}
                        onChange={onChange}
                        name={name}
                        checked={checked}
                    />
                )
            }
        </div>
    )
}

export default SwitchesComponent