import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import menuAction from 'reduxs/menu/actions'
import MenuBarComponent from './AppBar.component'

const mapStateToProps = (state: RootReducers): IMenuBarProps => {
  return {
    menu: state.mantra.menu!,
    userMeIsFetching: state.mantra.user.userMe.isFetching!,
    userMeError: state.mantra.user.userMe.error!,
    userMeCode: state.mantra.user.userMe.code!,
    userMeResult: state.mantra.user.userMe.data!,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): IMenuActionProps => bindActionCreators({
  onChangeMenu: menuAction.onChangeMenuAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MenuBarComponent)
