import project from 'constants/project'

export const GET_USER_ME_REQUEST = 'GET_USER_ME_REQUEST'
export const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS'
export const GET_USER_ME_FAILURE = 'GET_USER_ME_FAILURE'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    username: '',
    password: '',
    phone_number: '',
    wallet_id: 0,
    user_bank_id: 0,
    affilate_me_uuid: '',
    permission: '',
    is_fake: false,
    created_at: new Date(),
    updated_at: new Date(),
    bank: '',
    wallet: '',
  },
  error: '',
}

export const endpoint = {
  getUserMe: `${project.environment[project.environmentName].api}/user/me`,
}