import AllbetIcon from './allbet.png';
import DreamGamingIcon from './dream-gaming.png';
import SAGamingIcon from './sa-gaming.png';
import AllIcon from './thailandbet.png'
import W88Icon from './w88.png'
import WMIcon from './wm.png';
import AESexyIcon from './ae-sexy.png'
import EvoIcon from './evolution-icon.png'
const externalCasinoImage: IImageExternalCasino = {
    AESEXY: {
        name: "AE Sexy",
        Icon: AESexyIcon,
    },
    ALL: {
        name: "All",
        Icon: AllIcon,
    },
    ALLBET: {
        name: "Allbet",
        Icon: AllbetIcon
    },
    DG: {
        name: "Dream Gaming",
        Icon: DreamGamingIcon
    },
    SAGAME: {
        name: "SA Gaming",
        Icon: SAGamingIcon,
    },
    W88: {
        name: "W88",
        Icon: W88Icon
    },
    WMGAME: {
        name: "WM",
        Icon: WMIcon
    },
    EVOLUTION:{
        name:"EVOLUTION",
        Icon: EvoIcon
    }
}

export default externalCasinoImage