import scheduleActions from './schedule/actions'
import modeAction from './mode/actions'
import status from './status/actions'
import refund from './refund/actions'
import result from './result/actions'
import list from './list/actions'
import calculate from './calculate/actions'
export default {
  ...scheduleActions,
  ...modeAction,
  ...status,
  ...refund,
  ...result,
  ...list,
  ...calculate,
}