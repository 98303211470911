import { createAction } from 'typesafe-actions'
import {
  POST_LOTTO_CALCULATE_REQUEST,
  POST_LOTTO_CALCULATE_SUCCESS,
  POST_LOTTO_CALCULATE_FAILURE,
  POST_LOTTO_CALCULATE_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postLottoCalculateAction = createAction(
  POST_LOTTO_CALCULATE_REQUEST,
  reslove => (data: ILottoCalculatePostData) => reslove(data)
  )

const postLottoCalculateSuccessAction = createAction(
  POST_LOTTO_CALCULATE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<any>>) => resolve(data))

const postLottoCalculateFailureAction = createAction(
  POST_LOTTO_CALCULATE_FAILURE,
  resolve => (error: AxiosError<APIResponse<any>>) => resolve(error))

const postLottoCalculateCancelAction = createAction(POST_LOTTO_CALCULATE_CANCEL)

export default {
  postLottoCalculateAction,
  postLottoCalculateSuccessAction,
  postLottoCalculateFailureAction,
  postLottoCalculateCancelAction,
}