import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import webbankAction from 'reduxs/webbank/webbanks/actions'
import loaderAction from 'reduxs/loader/actions'
import EditWebBankContainer from './EditWebBank'

const mapStateToProps = (state: RootReducers): any => {
  return {
    putWebBankIsFetching: state.mantra.webbank.webbanks.putWebbank.isFetching!,
    putWebBankError: state.mantra.webbank.webbanks.putWebbank.error!,
    putWebBankCode: state.mantra.webbank.webbanks.putWebbank.code!,
    putWebBankResult: state.mantra.webbank.webbanks.putWebbank.data!,

  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IPutWebBankActionProps => bindActionCreators({
  putWebBank: webbankAction.putWebbankAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(EditWebBankContainer)