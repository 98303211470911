import { createAction } from 'typesafe-actions'
import {
  DELETE_LOTTO_RATE_LEVEL_REQUEST,
  DELETE_LOTTO_RATE_LEVEL_SUCCESS,
  DELETE_LOTTO_RATE_LEVEL_FAILURE,
  DELETE_LOTTO_RATE_LEVEL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const deleteLottoRateLevelAction = createAction(
  DELETE_LOTTO_RATE_LEVEL_REQUEST,
  reslove => (data: ILottoDeleteRateLevelData) => reslove(data)
  )

const deleteLottoRateLevelSuccessAction = createAction(
  DELETE_LOTTO_RATE_LEVEL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<ILottoRateLevel[]>>) => resolve(data))

const deleteLottoRateLevelFailureAction = createAction(
  DELETE_LOTTO_RATE_LEVEL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const deleteLottoRateLevelCancelAction = createAction(DELETE_LOTTO_RATE_LEVEL_CANCEL)

export default {
  deleteLottoRateLevelAction,
  deleteLottoRateLevelSuccessAction,
  deleteLottoRateLevelFailureAction,
  deleteLottoRateLevelCancelAction,
}