import React, { SFC } from 'react'
import './transactionItemCollapsible.style.scss'
import {
  map,
  noop,
  keys,
  sortBy,
  isEmpty,
  reverse,
  groupBy,
  Dictionary,
} from 'lodash'
import moment from 'moment-timezone'
import {
  Grid
} from '@material-ui/core'
import { TransactionItemCollapsible, Button, /* RollerLoading */ } from 'components'
import { date } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'

const constants = {
  titleLabel: 'รายการเดินบัญชี',
  withdrawLabel: 'ถอน',
  acceptsLabel: 'ตกลง',
  cancel: 'ยกเลิก',
  twoDown: '2 ตัวล่าง',
  threeUp: '3 ตัวบน',
  fourSuit: 'เลขชุด 4 ตัว',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItemCollapsibleModal = {
  titleLabel: constants.titleLabel,
  acceptsLabel: constants.acceptsLabel,
  action() { noop() },
  cancelAction() { noop() },
  fourSuit: '',
  twoDown: '',
  threeUp: '',
  creditName: '',
  getCreditMedetail(createdAt: 'SUCCESS' | 'FAIL', slug: string | null) { noop() },
  dataStore: {}
}

const renderTransactionList = (creditInfo: ICredit[], props: ITransactionItemCollapsibleModal) => {
  const creditGroupList: Dictionary<ICredit[]> = groupBy<ICredit>(
    map(creditInfo, credit => ({ ...credit, groupTime: date.calibratingTime(credit.createdAt).format('YYYYMMDD') })),
    'groupTime')
  if (isEmpty(creditGroupList)) { return <></> }

  return reverse(keys(creditGroupList).sort()).map((key, index) => {
    const CreditPerDay =
      map(
        reverse(sortBy(creditGroupList[key], ['createdAt'])),
        (transaction, transactionIndex) => {
          return (
            <TransactionItemCollapsible
              dataStore={props.dataStore}
              key={`credit-info-items-${transactionIndex}`}
              credit={transaction}
              getCreditMedetail={props.getCreditMedetail}
            />
          )
        }
      )
    const dateMoment = moment(key, 'YYYYMMDD').add(543, 'years').local()
    const isToDay = dateMoment.clone().diff(moment().local(), 'day')
    const displayDayString = (isToDay === 0)
      ? constants.today
      : (isToDay === -1)
        ? constants.yesterday
        : dateMoment.format('DD MMM YYYY')

    return (
      <div className="mt-4" key={`${key}-${index}`}>
        <Grid container>
          <Grid item xs={12}>
            <div className="primary-text mb-2 p2-x body-1">{displayDayString}</div>
            {CreditPerDay}
          </Grid>
        </Grid>
      </div>
    )
  })
}

const TransactionItemCollapsibleModal:
  SFC<ITransactionItemCollapsibleModal & DefaultProps> = (props: ITransactionItemCollapsibleModal) => {
    const fetchMoreData = () => {
      props.action!(
      )
    }
    const containerClass = `transaction-modal-container p3-y`

    return (
      <div className={containerClass}>
        <Grid container className="p2-x">
          <Grid item xs={12}>
            <h4>{`${props.titleLabel} (${props.creditName})`}</h4>
            {/* <div className="d-flex credit-info-transaction-wrapper">
              <div className="transaction-leading-wrapper">
                <h6 className="secondary-text p1-y ">{constants.withdrawLabel}</h6>
              </div>
              <div className="d-flex primary-red-text align-items-center body-1">
                {transformer.typeFormat(props.money!)}
              </div>
            </div> */}
          </Grid>
        </Grid>
        <div className='secondary-dark'>
          <InfiniteScroll
            dataLength={props.creditInfo!.length}
            next={fetchMoreData}
            hasMore={props.hasMore!}
            height={615}
            loader={false}
          >
            <Grid container>
              <Grid item xs={12}>
                {renderTransactionList(props.creditInfo!, props)}
              </Grid>
            </Grid>

          </InfiniteScroll>
          <div className="p2-x  d-flex justify-content-end w-100 secondary-dark">
            <Button
              buttonClass='btn-confirm'
              onClick={props.cancelAction}
              text={props.acceptsLabel!}
              color="green"
            />
          </div>
        </div>
      </div>

    )
  }

TransactionItemCollapsibleModal.defaultProps = defaultProps

export default TransactionItemCollapsibleModal