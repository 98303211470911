import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const affilateReducer = (state: IAffilateAllState = initialState, action: RootAction): IAffilateAllState => {
    switch (action.type) {
        case getType(actions.getAffilateAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getAffilateSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getAffilateFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const putAffilateReducer = (state: IAffilateAllState = initialState, action: RootAction): IAffilateAllState => {
    switch (action.type) {
        case getType(actions.putAffilateAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.putAffilateSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.putAffilateFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}
export default combineReducers({
    all: affilateReducer,
    put: putAffilateReducer,
})