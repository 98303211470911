import { Grid } from "@material-ui/core";
import { Button, InputNumber } from "components";
import { isEmpty } from "lodash";
import React from "react";

const RenderMode = (props: IRenderMode) => {
  const constants = {
    back: "กลับ",
    // lottoLabel: 'แทงหวย',
    closedLabel: "เวลาที่ปิดรับ: ",
    openedYeegeLabel: "เปิดรับ: ",
    closedStatusLabel: "ปิดรับแทง",
    placeholderInput: (type: string) => `กรอก${type}`,
    waitingStatusLabel: "รอเปิดรับแทง",
    openedYeegeStatusLabel: "24 ชม.",
    round: "รอบ",
    twoUp: "2 ตัวบน",
    twoDown: "2 ตัวล่าง",
    threeUp: "3 ตัวบน",
    fourSuit: "เลขชุด 4 ตัว",
    runUp: "วิ่งบน",
    runDown: "วิ่งล่าง",
    threeToast: "3 โต๊ด",
    level: "ระดับ",
    Rate: "อัตราจ่าย",
    number: "เลข",
    sumTotal: "ยอดรวมปรับระดับ",
    wrongUsernameTitle: "เพิ่มระดับอัตราจ่ายไม่ถูกต้อง",
    wrongUsernameDescription:
      "โปรดกรอกยอดปรับระดับมากกว่าระดับเดิม และอัตราจ่ายที่น้อยกว่าระดับเดิม",
    settingLabel: "ตั้งค่าระบบ",
    betRateLabel: "ปรับราคาเลข",
    fail: "ไม่สำเร็จ",
    description: "เนื่องจาก :",
    editRate: "การแก้ไขอัตราจ่าย",
    status: "สถานะ",
    confirmEditRate: "ยืนยันการแก้ไขอัตราจ่าย",
    type: "ประเภท",
    edit: "แก้ไข",
    cancel: "ยกเลิก",
    modeBet: [
      { key: "THREE_UP", label: "3 บน", rate: "threeUpRate" },
      { key: "THREE_TOAST", label: "3 โต๊ด", rate: "threeToastRate" },
      { key: "TWO_UP", label: "2 บน", rate: "twoUpRate" },
      { key: "TWO_DOWN", label: "2 ล่าง", rate: "twoDownRate" },
      { key: "RUN_UP", label: "วิ่งบน", rate: "runUpRate" },
      { key: "RUN_DOWN", label: "วิ่งล่าง", rate: "runDownRate" },
    ],
    modeButton: [
      { key: "SIXBACK", label: "6 กลับ" },
      { key: "19DOOR", label: "19 ประตู" },
      { key: "FLIPNUMBER", label: "กลับเลข" },
      { key: "allType", label: "เกี่ยวหมด" },
    ],
    inputRef: "",
  };

  const { handleSubmit, values, setFieldValue, touched, handleBlur, errors } =
    props.formikProps;
  const {
    LottoRateButtonMode,
    lottoType,
    isCheckEmptyForm,
    resetData,
    resetMode,
    handleSetButtonStatus,
    inputRef,
  } = props;

  const maxLength = () => {
    if (lottoType === "FOUR_SUITE") {
      return 4;
    } else if (lottoType === "THREE_TOAST" || lottoType === "THREE_UP") {
      return 3;
    } else if (lottoType === "TWO_DOWN" || lottoType === "TWO_UP") {
      return 2;
    } else {
      return 1;
    }
  };
  const isCheckEmptyCancle = () => {
    return LottoRateButtonMode.isAllRelevantActivated
      ? false
      : LottoRateButtonMode.is6BackActivated === false &&
          LottoRateButtonMode.isReverseNumActivated === false &&
          LottoRateButtonMode.is19DoorActivated === false &&
          LottoRateButtonMode.isAllRelevantActivated === false &&
          isEmpty(values.number) &&
          isEmpty(values.rate);
  };
  return (
    <>
      {(() => {
        if (lottoType === "RUN_UP" || lottoType === "RUN_DOWN") {
          return (
            <Grid item xs={9}>
              <div
                className={`m3-t btn-mode ${
                  LottoRateButtonMode.isAllRelevantActivated ? "active" : ""
                }`}
              >
                <Button
                  text={constants.modeButton[3].label}
                  fullWidth={true}
                  onClick={() => {
                    handleSetButtonStatus({
                      isAllRelevantActivated:
                        !LottoRateButtonMode.isAllRelevantActivated,
                    });
                  }}
                />
              </div>
            </Grid>
          );
        } else {
          return (
            <>
              {(() => {
                if (lottoType === "THREE_UP" || lottoType === "THREE_TOAST") {
                  return (
                    <Grid item xs={3}>
                      <div
                        className={`m3-t btn-mode ${
                          LottoRateButtonMode.is6BackActivated ? "active" : ""
                        }`}
                      >
                        <Button
                          text={constants.modeButton[0].label}
                          fullWidth={true}
                          onClick={() => {
                            handleSetButtonStatus({
                              is6BackActivated:
                                !LottoRateButtonMode.is6BackActivated,
                            });
                          }}
                        />
                      </div>
                    </Grid>
                  );
                } else if (lottoType === "TWO_UP" || lottoType === "TWO_DOWN") {
                  return (
                    <Grid item xs={3}>
                      <div
                        className={`m3-t btn-mode ${
                          LottoRateButtonMode.is19DoorActivated ? "active" : ""
                        }`}
                      >
                        <Button
                          text={constants.modeButton[1].label}
                          fullWidth={true}
                          onClick={() => {
                            handleSetButtonStatus({
                              is19DoorActivated:
                                !LottoRateButtonMode.is19DoorActivated,
                            });
                          }}
                        />
                      </div>
                    </Grid>
                  );
                }
              })()}
              <Grid item xs={3}>
                <div
                  className={`m3-t btn-mode ${
                    LottoRateButtonMode.isReverseNumActivated ? "active" : ""
                  }`}
                >
                  <Button
                    text={constants.modeButton[2].label}
                    fullWidth={true}
                    onClick={() => {
                      handleSetButtonStatus({
                        isReverseNumActivated:
                          !LottoRateButtonMode.isReverseNumActivated,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className={`m3-t btn-mode ${
                    LottoRateButtonMode.isAllRelevantActivated ? "active" : ""
                  }`}
                >
                  <Button
                    text={constants.modeButton[3].label}
                    fullWidth={true}
                    onClick={() => {
                      handleSetButtonStatus({
                        isAllRelevantActivated:
                          !LottoRateButtonMode.isAllRelevantActivated,
                      });
                    }}
                  />
                </div>
              </Grid>
            </>
          );
        }
      })()}

      <Grid item xs={LottoRateButtonMode.isAllRelevantActivated ? 12 : 6}>
        <InputNumber
          id="number"
          inputRef={inputRef}
          name="number"
          onBlur={handleBlur}
          label={constants.number}
          fullWidth={true}
          onValueChange={({ value }) => {
            setFieldValue("number", value);
          }}
          allowNegative={false}
          allowLeadingZeros
          maxLength={maxLength()}
          decimalSeparator={false}
          placeholder={constants.placeholderInput(constants.number)}
          error={!!errors.number && touched.number}
          value={values.number}
          helperText={
            !!errors.number && touched.number ? `${errors.number}` : ""
          }
        />
      </Grid>
      {!LottoRateButtonMode.isAllRelevantActivated ? (
        <>
          <Grid item xs={6}>
            <InputNumber
              id="rate"
              name="rate"
              onBlur={handleBlur}
              label={constants.Rate}
              fullWidth={true}
              onValueChange={({ value }) => {
                setFieldValue("rate", value);
              }}
              allowNegative={false}
              thousandSeparator
              decimalScale={2}
              placeholder={constants.placeholderInput(constants.Rate)}
              error={!!errors.rate && touched.rate}
              value={values.rate}
              helperText={!!errors.rate && touched.rate ? `${errors.rate}` : ""}
              // getInputRef={this.inputReference}
            />
          </Grid>
        </>
      ) : (
        <>
          {constants.modeBet.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={2}>
                  <div className="items">
                    <h6>{item.label}</h6>
                  </div>
                </Grid>
                <Grid item xs={10} style={{ paddingLeft: "54px" }}>
                  <InputNumber
                    id={`rate${index + 1}`}
                    name={item.rate}
                    onBlur={handleBlur}
                    label={constants.Rate}
                    fullWidth={true}
                    onValueChange={({ value }) => {
                      setFieldValue(item.rate, value);
                    }}
                    allowNegative={false}
                    thousandSeparator
                    decimalScale={2}
                    placeholder={constants.placeholderInput(constants.Rate)}
                    value={values[item.rate as keyof ILottoRateNumber]}
                    error={
                      !!errors[item.rate as keyof ILottoRateNumber] &&
                      touched[item.rate as keyof ILottoRateNumber]
                    }
                    helperText={
                      !!errors[item.rate as keyof ILottoRateNumber] &&
                      touched[item.rate as keyof ILottoRateNumber]
                        ? `${errors[item.rate as keyof ILottoRateNumber]}`
                        : ""
                    }
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </>
      )}
      <Grid item xs={6}>
        <Button
          type="submit"
          text={constants.edit}
          fullWidth={true}
          disabled={isCheckEmptyForm}
          onClick={() => {
            handleSubmit();
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          text={constants.cancel}
          fullWidth={true}
          color="red"
          disabled={isCheckEmptyCancle()}
          onClick={() => {
            resetMode();
            resetData(props.formikProps);
          }}
        />
      </Grid>
    </>
  );
};

export default RenderMode;
