import { combineReducers } from 'redux'
import schedule from './schedule/reducers'
import mode from './mode/reducers'
import status from './status/reducers'
import refund from './refund/reducers'
import result from './result/reducers'
import list from './list/reducers'
import calculate from './calculate/reducers'
export default combineReducers({
  schedule,
  mode,
  status,
  refund,
  result,
  list,
  calculate,
})
