import tokenAction from './token/actions'
import userMeAction from './me/actions'
import userAllAction from './all/actions'
import userCreateAction from './create/actions'
import userEditAction from './edit/actions'
import userDeleteAction from './delete/actions'
import userLineAction from './line/actions'
import dateAction from './dateOverviwe/actions'
import criminalAction from './criminal/actions'
import criminalLogAction from './criminalLog/actions'
export default {
    ...tokenAction,
    ...userMeAction,
    ...userAllAction,
    ...userCreateAction,
    ...userEditAction,
    ...userDeleteAction,
    ...userLineAction,
    ...dateAction,
    ...criminalAction,
    ...criminalLogAction,
}