import { string, object } from 'yup'

const constants = {
  onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
  number: 'กรุณากรอกตัวเลข',
  twoLength: 'กรุณากรอกไม่เกิน 2 ตัว',
  twoMinLength: 'กรุณากรอกให้ครบ 2 ตัว',
  threeLength: 'กรุณากรอกไม่เกิน 3 ตัว',
  threeMinLength: 'กรุณากรอกให้ครบ 3 ตัว',
  fourLength: 'กรุณากรอกไม่เกิน 4 ตัว',
  fourMinLength: 'กรุณากรอกให้ครบ 4 ตัว',
  rate: 'กรุณากรอกอัตราจ่าย',
  numberReq: 'กรุณากรอกเลข'
}

const scheme = (codeLotto: TLottoSlug) => {
  return object().shape({
    number: string()
      .required(constants.number)
      .test(
        'check length LOTTER_LAO_SUITE',
        constants.fourMinLength,
        function (value: string) {
          if (codeLotto === 'LOTTER_LAO_SUITE') {
            return (value.length === 4)
          } else {
            return true
          }
        })
      .test(
        'check length LOTTER_LAO_SUITE',
        constants.threeLength,
        function (value: string) {
          if (codeLotto !== 'LOTTER_LAO_SUITE') {
            return (value.length <= 3)
          } else {
            return true
          }
        }),
  })
}
export default scheme