import { createAction } from 'typesafe-actions'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CANCEL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const loginAction = createAction(
  LOGIN_REQUEST,
  resolve => (data: ILoginRequest) => resolve(data))

const loginSuccessAction = createAction(
  LOGIN_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<any>>) => resolve(data))

const loginFailureAction = createAction(
  LOGIN_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const loginCancelAction = createAction(LOGIN_CANCEL)

const logoutAction = createAction(LOGOUT_REQUEST)
const logoutSuccessAction = createAction(LOGOUT_SUCCESS)


export default {
  loginAction,
  loginSuccessAction,
  loginFailureAction,
  loginCancelAction,
  logoutAction,
  logoutSuccessAction,
}