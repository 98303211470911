import schedule from './schedule/epics'
import mode from './mode/epics'
import status from './status/epics'
import refund from './refund/epics'
import result from './result/epics'
import list from './list/epics'
import calculate from './calculate/epics'
export default [
  ...schedule,
  ...mode,
  ...status,
  ...refund,
  ...result,
  ...list,
  ...calculate,
]
