import project from 'constants/project'

export const POST_CHECK_RATE_CLIENT_REQUEST = 'POST_CHECK_RATE_CLIENT_REQUEST'
export const POST_CHECK_RATE_CLIENT_SUCCESS = 'POST_CHECK_RATE_CLIENT_SUCCESS'
export const POST_CHECK_RATE_CLIENT_FAILURE = 'POST_CHECK_RATE_CLIENT_FAILURE'
export const POST_CHECK_RATE_CLIENT_CANCEL = 'POST_CHECK_RATE_CLIENT_CANCEL'

export const initialState: ICheckRateClientRedux = {
  isFetching: false,
  code: 0,
  data: '',
  error: '',
}

export const endpoint = {
  postCheckRateClient: `${project.environment[project.environmentName].api}/lotter/bet/client/check`,
}