import DateFnsUtils from '@date-io/date-fns'
import { format as fnsFormat, getYear, parse as fnsParse } from 'date-fns'
import { get } from 'lodash'

const dateIOFormats = {
    dayOfMonth: "d",
    fullDate: "PP",
    fullDateWithWeekday: "PPPP",
    fullDateTime: "PP p",
    fullDateTime12h: "PP hh:mm aaa",
    fullDateTime24h: "PP HH:mm",
    fullTime: "p",
    fullTime12h: "hh:mm aaa",
    fullTime24h: "HH:mm",
    hours12h: "hh",
    hours24h: "HH",
    keyboardDate: "P",
    keyboardDateTime: "P p",
    keyboardDateTime12h: "P hh:mm aaa",
    keyboardDateTime24h: "P HH:mm",
    minutes: "mm",
    month: "LLLL",
    monthAndDate: "MMMM d",
    monthAndYear: "LLLL yyyy",
    monthShort: "MMM",
    weekday: "EEEE",
    weekdayShort: "EEE",
    normalDate: "d MMMM",
    normalDateWithWeekday: "EEE, MMM d",
    seconds: "ss",
    shortDate: "MMM d",

    year: "yyyy",
    EEE: "EEE",
    MMM: 'MMM',
    yyyy: "yyyy",
    EEEEEE: 'EEEEEE',
    hh: 'hh',
    mm: 'mm',
    d: 'd'
}

const normalizeYearFormats = {
    fullDate: "MMM d, yyyy",
    fullDateWithWeekday: "EEEE, LLLL d, yyyy",
    fullDateTime: "MMM d, yyyy p",
    fullDateTime12h: "MMM d, yyyy hh:mm aaa",
    fullDateTime24h: "MMM d, yyyy HH:mm",
    keyboardDateTime: "MM/dd/yyyy p",
    keyboardDateTime12h: "MM/dd/yyyy hh:mm aaa",
    keyboardDateTime24h: "MM/dd/yyyy HH:mm",
    monthAndYear: "LLLL yyyy",
}

export default class OverwriteBEEra extends DateFnsUtils {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(d: { locale: any, formats: any, instance: any }) {
        super(d)
    }

    toBuddhistYear(date: Date, formats: string): string {
        const normalFormatKey = get(normalizeYearFormats, formats, '')
        const christianYear = fnsFormat(date, 'yyyy')
        const buddhishYear = (parseInt(christianYear) + 543).toString()
        const replacedYearFormats = normalFormatKey.replace('yyyy', buddhishYear).replace('yy', buddhishYear.substring(2, 4))
        const result = fnsFormat(date, replacedYearFormats, { locale: this.locale }).replace(christianYear, buddhishYear)
        return result
    }

    formatByString = (date: Date, formatString: string) => {
        const christianYear = `${getYear(date)}`;
        const buddhishYear = (parseInt(christianYear) + 543).toString();
        let result = fnsFormat(date, formatString, { locale: this.locale });
        return result.replace(christianYear, buddhishYear);
    }

    format = (date: Date, formatKey: string,): string => {
        const includedYearFormat = ['fullDate', 'fullDateWithWeekday', 'fullDateTime', 'fullDateTime12h', 'fullDateTime24h', 'keyboardDateTime', 'keyboardDateTime12h', 'keyboardDateTime24h', 'monthAndYear']
        if (includedYearFormat.includes(formatKey)) {
            return this.toBuddhistYear(date, formatKey)
        }
        let dateFnsFormatKey
        if (formatKey === 'EEE, MMM d') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'EEE, MMM d')
        }
        else if (formatKey === 'MMM d') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'MMM d')
        }
        else if (formatKey === 'MMMM yyyy') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'MMMM yyyy')
        }
        else if (formatKey === 'dd MMM yyyy HH:mm') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'dd MMM yyyy HH:mm')
        }
        else if (formatKey === 'MM/dd/yyyy HH:mm') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'MM/dd/yyyy HH:mm')
        }
        else if (formatKey === 'dd/MM/yyyy:HH:mm') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'dd/MM/yyyy:HH:mm')
        }
        else if (formatKey === 'dd MMM yyyy') {
            dateFnsFormatKey = get(dateIOFormats, formatKey, 'dd MMM yyyy')
        }
        else {
            dateFnsFormatKey = get(dateIOFormats, formatKey, '')
        }


        // *New
        const christianYear = `${getYear(date)}`;
        let buddhishYear
        if (new Date().getFullYear() === Number(christianYear) || Number(christianYear) !== new Date().getFullYear() + 543) {
            buddhishYear = (parseInt(christianYear) + 543).toString()
        }
        else {
            buddhishYear = (parseInt(christianYear)).toString();
        }
        let result = fnsFormat(date, dateFnsFormatKey, { locale: this.locale });
        return result.replace(christianYear, buddhishYear);
        // return fnsFormat(date, dateFnsFormatKey)
    }

    parse = (value: string, formatString: string): Date => {
        if (value === '') {
            return new Date()
        }
        if ((formatString === 'dd/MM/yyyy:HH:mm' || formatString === 'P') && value.length === 16) {
            let year = parseInt(value.substring(6, 10))
            if (year) {
                let newYear = year - 543;
                let res = value.replace(`${year}`, `${newYear}`);
                return fnsParse(res, 'dd/MM/yyyy:HH:mm', new Date(), { locale: this.locale });
            } else {
                return fnsParse(value, formatString, new Date(), { locale: this.locale });
            }
        }

        if ((formatString === 'dd MMM yyyy HH:mm' || formatString === 'P')) {
            let year = parseInt(value.substring(value.length === 19 ? 9 : 8, value.length === 19 ? 13 : 12))
            if (year) {
                let newYear = year - 543
                let res = value.replace(`${year}`, `${newYear}`)
                return fnsParse((value.length === 19 || value.length === 18) ? res : '', 'dd MMMM yyyy HH:mm', new Date(), { locale: this.locale });
            } else {
                return fnsParse(value, formatString, new Date(), { locale: this.locale });
            }
        }

        if ((formatString === 'dd/MM/yyyy' || formatString === 'P') && value.length === 10) {
            let year = parseInt(value.substring(6, 10));
            if (year > 543) {
                let newYear = year - 543;
                let res = value.replace(`${year}`, `${newYear}`);
                return fnsParse(res, formatString, new Date(), { locale: this.locale });
            } else {
                return fnsParse(value, formatString, new Date(), { locale: this.locale });
            }
        }

        if (formatString === 'MM/yyyy' && value.length === 7) {
            let year = parseInt(value.substring(3, 7));
            if (year > 543) {
                let newYear = year - 543;
                let res = value.replace(`${year}`, `${newYear}`);
                return fnsParse(res, formatString, new Date(), { locale: this.locale });
            } else {
                return fnsParse(value, formatString, new Date(), { locale: this.locale });
            }
        }
        if (formatString === 'yyyy' && value.length === 4) {
            let year = parseInt(value);
            if (year > 543) {
                let newYear = year - 543;

                let res = value.replace(`${year}`, `${newYear}`);
                return fnsParse(res, formatString, new Date(), { locale: this.locale });
            } else {
                return fnsParse(value, formatString, new Date(), { locale: this.locale });
            }
        }
        if (formatString === 'MM' && value.length === 2) {
            return fnsParse(value, formatString, new Date(), { locale: this.locale });
        }
        return new Date()
        // return dateFnsParse(value, formatString, new Date(), { locale: this.locale });
    }
}