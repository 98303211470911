import React, { SFC } from 'react'
import { noop, map } from 'lodash'
import './success.style.scss'
import {
  Grid
} from '@material-ui/core'
import LottoFlags from 'assets/images/global/flags'
import { LOTTO_SLUG_NAME, LOTTO_FLAG_ALPHA, LOTTO_SLUG_TO_TYPE } from 'constants/variables'
import { Button, ResponsiveIcon, InputNumber } from 'components'
import ErrorIcon from 'assets/images/modal/error/error.png'
import PayIcon from 'assets/images/modal/error/pay.png'
import Checkbox from '@material-ui/core/Checkbox'
const constants = {
  description: 'ยืนยันการทำรายการ',
  accepts: 'ยืนยัน',
  cancel: 'ยกเลิก',
  twoDown: '2 ตัวล่าง',
  threeUp: '  3 ตัวบน',
  fourSuit: 'เลขชุด 4 ตัว',
  confirm: 'ยืนยันอีกครั้ง',
  lottoResut: 'ผลหวยถูกต้อง',
  calculate: 'จ่ายผู้ชนะ',
  warningCheck: ' *กรุณาตรวจสอบผลหวยอีกครั้ง'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAcceptsModal = {
  description: constants.description,
  action() { noop() },
  cancelAction() { noop() },
  lottoCode: 'LOTTER_GOVN',
  fourSuit: '',
  twoDown: '',
  threeUp: '',
  isconfirm: false,
}


const AcceptsModal: SFC<IAcceptsModal & DefaultProps> = (props: IAcceptsModal) => {

  const [checkedOne, setCheckedOne] = React.useState(false);
  const [checkedTwo, setcheckedTwo] = React.useState(false);
  const [lastChecked, setlastChecked] = React.useState(false);
  const [numOne, setnumOne] = React.useState('');
  const [numTwo, setNumTwo] = React.useState('');
  const handleChange = (type: string, event: React.ChangeEvent<HTMLInputElement>) => {
    switch (type) {
      case 'checkedOne':
        setCheckedOne(event.target.checked);
        break
      case 'checkedTwo':
        setcheckedTwo(event.target.checked);
        break
      case 'lastChecked':
        setlastChecked(event.target.checked);
        break
    }
  };

  const handleCheckBox = (key: string, props: IAcceptsModal) => {
    if (key === 'threeUp' && props.threeUp === numTwo) {
      return false
    }
    else if (key === 'twoDown' && props.twoDown === numOne) {
      return false
    }
    return true
  }
  const lottoNumber = () => {
    return map(props, (value, key) => {
      if ((key === 'threeUp' || key === 'twoDown' || key === 'fourSuit')
        && (value !== 'undefined' && value !== '')) {
        return (

          <div className="lotto-container" key={`${key}`}>
            <Grid container className="d-flex" alignItems="center">
              <Grid item xs={5} className="d-flex" justify="center">
                {<p className="body-1">{`${constants[key]}:`}</p>}
                <div className={`primary-red-text lotto-number-text ${key} m1-l`}>
                  {`${value}`}
                </div>
              </Grid>
              <Grid item xs={4}>

                <InputNumber
                  placeholder={`${constants.accepts} ${key === 'threeUp' ? `3 ตัวบน` : key === 'twoDown' ? `2 ตัวล่าง` : 'เลขชุด'}`}
                  maxLength={key === 'threeUp' ? 3 : key === 'twoDown' ? 2 : 4}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  onValueChange={({ value }) => {
                    if(key === 'threeUp') {
                      setcheckedTwo(false)
                    }
                    else {
                      setCheckedOne(false)
                    }
                    key === 'threeUp'
                      ? setNumTwo(String(value))
                      : setnumOne(String(value)
                      )
                  }}
                  value={key === 'threeUp' ? numTwo : numOne}
                >
                </InputNumber>

              </Grid>
              <Grid item xs={3}>
                <div className='check-box-container'>
                  <div>
                    <Checkbox
                      disabled={handleCheckBox(key, props)}
                      color="secondary"
                      checked={key === 'threeUp' ? checkedTwo : checkedOne}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setlastChecked(false)
                        handleChange(key === 'threeUp' ? 'checkedTwo' : 'checkedOne', e)
                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                  <div className="check-box-text">
                    {constants.confirm}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )
      }
    });
  }

  const FlagIcon = LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[props.lottoCode!]]].Icon
  const lottoName = LOTTO_SLUG_NAME[props.lottoCode!]
  const iconModal = props.title?.includes(constants.calculate) ? PayIcon : ErrorIcon
  let containerClass = `warnning-modal-container border-rounded`
  containerClass = `${containerClass} ${props.fourSuit ? 'height-lotto-suit' : props.threeUp ? 'height-lotto' : ''}`
  return (
    <div className={containerClass}>
      <ResponsiveIcon
        alt="error-icon"
        className="error-checked-icon"
        icon={{ x1: iconModal, x2: iconModal, x3: iconModal }}
      />
      <p className="body-1 primary-text m3-b">{`${props.description} ${props.title}`}</p>
      {props.isconfirm && (<div className="lotto-action-text-wrapper">
        <ResponsiveIcon icon={FlagIcon} alt="flag" className="lotto-action-card-flag m2-r" />
        <h1 className="primary-text adjust-text">{lottoName}</h1>
      </div>)}


      <div className="">
        {lottoNumber()}
      </div>
      <div className="m3-b">
        <Grid container justify="center" spacing={2}>
          {props.isconfirm && (
            <Grid item xs={12}>
              <div className='confirm-checkbox'>
                <Checkbox
                  disabled={checkedOne && checkedTwo ? false : true}
                  color="secondary"
                  checked={lastChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange('lastChecked', e)
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <div className="title-text">
                  <span className="primary-text">{constants.lottoResut}</span>
                  <span className="primary-red-text" >{constants.warningCheck}</span>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={6}>
            <div className="footer-wrapper-success-modal">
              <Button
                onClick={props.cancelAction}
                text={constants.cancel}
                color="red"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="footer-wrapper-success-modal">
              <Button
                disabled={!lastChecked && props.isconfirm}
                onClick={props.action}
                text={constants.accepts}
                color="green"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

AcceptsModal.defaultProps = defaultProps

export default AcceptsModal