import project from 'constants/project'

export const SAVE_BROADCAST_LINE_REQUEST = 'SAVE_BROADCAST_LINE_REQUEST'
export const SAVE_BROADCAST_LINE_SUCCESS = 'SAVE_BROADCAST_LINE_SUCCESS'
export const SAVE_BROADCAST_LINE_FAILURE = 'SAVE_BROADCAST_LINE_FAILURE'
export const SAVE_BROADCAST_LINE_CANCEL = 'SAVE_BROADCAST_LINE_CANCEL'

export const POST_BROADCAST_LINE_REQUEST = 'POST_BROADCAST_LINE_REQUEST'
export const POST_BROADCAST_LINE_SUCCESS = 'POST_BROADCAST_LINE_SUCCESS'
export const POST_BROADCAST_LINE_FAILURE = 'POST_BROADCAST_LINE_FAILURE'
export const POST_BROADCAST_LINE_CANCEL = 'POST_BROADCAST_LINE_CANCEL'

export const GET_BROADCAST_LINE_LIST_REQUEST = 'GET_BROADCAST_LINE_LIST_REQUEST'
export const GET_BROADCAST_LINE_LIST_SUCCESS = 'GET_BROADCAST_LINE_LIST_SUCCESS'
export const GET_BROADCAST_LINE_LIST_FAILURE = 'GET_BROADCAST_LINE_LIST_FAILURE'
export const GET_BROADCAST_LINE_LIST_CANCEL = 'GET_BROADCAST_LINE_LIST_CANCEL'

export const DELETE_BROADCAST_LINE_LIST_REQUEST = 'DELETE_BROADCAST_LINE_LIST_REQUEST'
export const DELETE_BROADCAST_LINE_LIST_SUCCESS = 'DELETE_BROADCAST_LINE_LIST_SUCCESS'
export const DELETE_BROADCAST_LINE_LIST_FAILURE = 'DELETE_BROADCAST_LINE_LIST_FAILURE'
export const DELETE_BROADCAST_LINE_LIST_CANCEL = 'DELETE_BROADCAST_LINE_LIST_CANCEL'

export const SUMMARY_BROADCAST_LINE_LIST_REQUEST = 'SUMMARY_BROADCAST_LINE_LIST_REQUEST'
export const SUMMARY_BROADCAST_LINE_LIST_SUCCESS = 'SUMMARY_BROADCAST_LINE_LIST_SUCCESS'
export const SUMMARY_BROADCAST_LINE_LIST_FAILURE = 'SUMMARY_BROADCAST_LINE_LIST_FAILURE'
export const SUMMARY_BROADCAST_LINE_LIST_CANCEL = 'SUMMARY_BROADCAST_LINE_LIST_CANCEL'

export const LOG_BROADCAST_LINE_REQUEST = 'LOG_BROADCAST_LINE_REQUEST'
export const LOG_BROADCAST_LINE_SUCCESS = 'LOG_BROADCAST_LINE_SUCCESS'
export const LOG_BROADCAST_LINE_FAILURE = 'LOG_BROADCAST_LINE_FAILURE'
export const LOG_BROADCAST_LINE_CANCEL = 'LOG_BROADCAST_LINE_CANCEL'


export const initialState: IBroadcastAllState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  saveBroadcast: `${project.environment[project.environmentName].api}/line/broadcast/save`,
  listBroadcast: `${project.environment[project.environmentName].api}/line/broadcast`,
  deleteBroadcast: `${project.environment[project.environmentName].api}/line/broadcast/delete`,
  postBroadcast: `${project.environment[project.environmentName].api}/line/broadcast/send`,
  summaryBroadcast: `${project.environment[project.environmentName].api}/line/broadcast/summary`,
  logsBroadcast: `${project.environment[project.environmentName].api}/line/broadcast/log`,
}