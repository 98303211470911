import { combineReducers } from 'redux'
import project from 'constants/project'
import loader from './loader/reducers'
import auth from './auth/reducers'
import user from './user/reducers'
import transaction from './transaction/reducers'
import settings from './settings/reducers'
import menu from './menu/reducers'
import storage from 'redux-persist/lib/storage'
import webbank from './webbank/reducers'
import socket from './socket/reducers'
import news from './news/reducers'
import webConfig from './webConfig/reducers'
import affilate from './affilate/reducers'
import config from './config/reducers'
import lotto from './lotto/reducers'
import check from './check/reducers'
import report from './report/reducers'
import credit from './credit/reducers'
import overview from './overview/reducers'
import { persistReducer } from 'redux-persist'

const persistConfig = {
  key: project.name,
  storage,
  whitelist: ['auth', 'user'],
}

const userPersistConfig = {
  key: 'user',
  storage,
}

const authPersistConfig = {
  key: 'auth',
  storage,
}

const rootReducers = {
  user: persistReducer(userPersistConfig, user),
  loader,
  auth: persistReducer(authPersistConfig, auth),
  transaction,
  settings,
  webbank,
  menu,
  news,
  webConfig,
  socket,
  affilate,
  config,
  lotto,
  check,
  report,
  credit,
  overview
}

export default persistReducer(
  persistConfig,
  combineReducers({ [project.name]: combineReducers(rootReducers) })
)