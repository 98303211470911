import moment from "moment"
import "./withdraw.style.scss"
import { noop, isEqual, get } from "lodash"
import React, { Component } from "react"
import SearchIcon from "@material-ui/icons/Search"
import { responseCode, responseMessage } from "constants/response"
import { RouteComponentProps } from "react-router-dom"
import { number, date } from "utils"
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TablePagination,
} from "@material-ui/core"
import {
  Modal,
  Button,
  ModalWithdraw,
  Chipper,
  Select,
  TextField,
  DateTime,
  Breadcrumb,
  TitlePage,
} from "components"

const constants = {
  confirmWithDraw: "ยืนยันอนุมัติรายการถอน",
  notWithDraw: "ไม่อนุมัติรายการถอน",
  approveSureWithdraw: "คุณแน่ใจใช่หรือไม่? อนุมัติรายการถอน",
  disApprovalSureWithdraw: "คุณแน่ใจใช่หรือไม่? ไม่อนุมัติรายการถอน",
  approval: "อนุมัติ",
  disApproval: "ไม่อนุมัติ",
  reserve: "กำลังดำเนินการอยู่...",
  withDrawLabel: "รายการถอน",
  systemLabel: "จัดการระบบ",
  detailLabel: "รายละเอียด",
  check:'ตรวจสอบ',
  titleError: "เกิดข้อผิดพลาด",
};

enum ESTATUS {
  SUCCESS = "SUCCESS",
  WAIT = "WAIT",
  REJECT = "REJECT",
  FAIL = "FAIL",
  RESERVE = "RESERVE",
}

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ITransactionProps & ITransactionActionProps = {
  meDetailRequest() {
    noop();
  },
  userMeResult: [],
  storeCreditMetail: {},
  connectFinanceDepositSocket() {
    noop();
  },
  connectFinanceWithdrawSocket() {
    noop();
  },
  unlistenTransactionAllSocket() {
    noop();
  },
  clearTransactionAll() {
    noop();
  },
  clearCreditInfoList() {
    noop();
  },
  getTransaction() {
    noop();
  },
  postReserveTransaction() {
    noop();
  },
  cancelReserveTransaction() {
    noop();
  },
  forceCancelReserveTransaction() {
    noop();
  },
  getCreditInfoList() {
    noop();
  },
  getTransactionCode: 0,
  getTransactionError: "",
  getTransactionIsFetching: false,
  getTransactionResult: [],
  getTransactionLimit: 10,
  getTransactionPage: 0,
  getTransactionTotal: 0,
  getTransactionPageTotal: 0,
  putTransaction() {
    noop();
  },
  putTransactionCode: 0,
  putTransactionError: "",
  putTransactionIsFetching: false,
  putTransactionResult: [],

  postReserveTransactionCode: 0,
  postReserveTransactionError: "",
  postReserveTransactionIsFetching: false,
  postReserveTransactionResult: [],

  getWebBankActive() {
    noop();
  },
  getWebBankActiveCode: 0,
  getWebBankActiveError: "",
  getWebBankActiveIsFetching: false,
  getCreditInfoListCode: 0,
  getCreditInfoListError: "",
  getCreditInfoListIsFetching: false,
  getWebBankActiveResult: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },

  postMapWebBank() {
    noop();
  },
  postMapWebBankCode: 0,
  postMapWebBankError: "",
  postMapWebBankIsFetching: false,
  postMapWebBankResult: {
    id: 0,
    type: "",
    name: "",
    number: "",
    status: "INACTIVE",
    created_at: "",
    updated_at: "",
    operate_type: "WITHDRAW",
  },

  loader() {
    noop();
  },
  creditInfo: [],
  creditInfoPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
};

const columns: ITransactionColumn<
  | "id"
  | "money"
  | "userTxTime"
  | "status"
  | "userName"
  | "userBank"
  | "bankName"
  | "statement"
  | "approval"
>[] = [
    { id: "id", label: "เลขที่", minWidth: 50, align: "center" },
    { id: "userName", label: "ชื่อผู้ใช้", minWidth: 100, align: "left" },
    { id: "userBank", label: "ชื่อผู้โอน/เวลาถอน", minWidth: 100, align: "left" },
    {
      id: "approval",
      label: "ชื่อผู้อนุมัติ/เวลา",
      minWidth: 100,
      align: "left",
    },
    { id: "bankName", label: "บัญชีที่โอน", minWidth: 50, align: "left" },
    {
      id: "money",
      label: "จำนวนเงิน",
      minWidth: 50,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    { id: "statement", label: "รายการเดินบัญชี", minWidth: 50, align: "center" },
    { id: "status", label: "สถานะ", minWidth: 100, align: "left" },
  ];

class TransactionContainer extends Component<
  ITransactionProps &
  ITransactionActionProps &
  DefaultProps &
  RouteComponentProps,
  ITransactionContainerState
> {
  static defaultProps = defaultProps;

  constructor(
    props: ITransactionProps &
      ITransactionActionProps &
      RouteComponentProps &
      DefaultProps
  ) {
    super(props);
    this.state = {
      statusButton: "",
      currentPage: 0,
      tableRows: [],
      dataType: "WITHDRAW",
      dataStatus: "WAIT",
      limit: 10,
      page: 0,
      total: 0,
      search: "",
      date: "",
      selectedDate: null,
      queryTimeout: setTimeout(() => {
        noop();
      }, 0),
      dateTimeout: setTimeout(() => {
        noop();
      }, 0),
      isShowSnack: false,
      hasMore: true,
      isFirst: true,
      creditName: "",
      getCreditInfo: {
        ID: "",
        isFirst: true,
        money: "",
      },
      txData: {
        id: 0,
        tag: "",
        description: "",
        money: "",
        status: "",
        type: "",
        userTxTime: "",
        approveUserId: {
          username: "",
          id: "",
        },
        webBankId: {
          name: "",
          number: "",
          type: "",
        },
        userBankId: {
          name: "",
          type: "",
          number: "",
        },
        userId: {
          username: "",
          id: "",
          phoneNumber: ""
        },
        walletBank: {
          name: "",
          number: "",
          type: "",
        },
        bankAccountNo: {
          name: "",
          number: "",
          type: "",
        },
        walletId: {
          id: 0,
          money: "",
          created_at: "",
        },
        updatedAt: "",
      },
    };
  }

  getWithDrawTxAndSubscribe() {
    this.props.loader(true);
    this.props.getTransaction({
      type: this.state.dataType,
      status: this.state.dataStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      date: this.state.date,
    });
    this.props.connectFinanceWithdrawSocket({
      type: this.state.dataType,
      status: this.state.dataStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      date: this.state.date,
    });
  }

  GetCreditinfo = () => {
    const queryData: IGetCreditParams = {
      id: this.state.getCreditInfo.ID,
      money: this.state.getCreditInfo.money,
      limit: 10,
      page: this.state.getCreditInfo.isFirst
        ? 1
        : this.props.creditInfoPagination.page + 1,
    };
    this.props.getCreditInfoList(queryData, this.state.getCreditInfo.isFirst);
  };

  componentDidMount() {
    this.getWithDrawTxAndSubscribe();
  }

  componentDidUpdate(prevProps: ITransactionProps) {
    if (
      prevProps.getTransactionIsFetching !==
      this.props.getTransactionIsFetching &&
      !this.props.getTransactionIsFetching
    ) {
      if (this.props.getTransactionCode === responseCode.OK) {
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          page: this.props.getTransactionPage,
          total: this.props.getTransactionTotal,
        });
      } else {
        this.setState({ tableRows: [] });
      }
      this.props.loader(false);
      this.props.getWebBankActive(this.getQuerywebBankActive());
      this.props.loader(true);
    }

    if (
      !isEqual(this.props.getTransactionResult, prevProps.getTransactionResult)
    ) {
      if (
        this.props.getTransactionResult.length === 0 &&
        this.props.getTransactionPage > 0
      ) {
        this.props.loader(false);
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          total: this.props.getTransactionTotal,
        });
        this.handleChangePage(null, this.props.getTransactionPage - 1);
      } else {
        this.setState({
          tableRows: this.props.getTransactionResult.map((txResult) =>
            this.createData(txResult)
          ),
          limit: this.props.getTransactionLimit,
          page: this.props.getTransactionPage,
          total: this.props.getTransactionTotal,
        });
      }
      this.props.loader(false);
    }

    if (
      prevProps.postMapWebBankIsFetching !==
      this.props.postMapWebBankIsFetching &&
      !this.props.postMapWebBankIsFetching
    ) {
      this.props.loader(false);
      if (this.props.postMapWebBankCode === responseCode.OK) {
        if (this.state.statusButton === ESTATUS.SUCCESS) {
          ModalWithdraw.success.show({
            action: this.handleApproveWithDraw,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(this.state.txData!);
              ModalWithdraw.success.hide();
            },
            txId: this.state.txData!.id,
            txStatus: ESTATUS.SUCCESS,
            title: constants.confirmWithDraw,
            subTitle: constants.approveSureWithdraw,
            name: this.state.txData!.userId.username,
            bank: this.state.txData!.userBankId.type,
            accountNo: this.state.txData!.userBankId.number,
            money: this.state.txData!.money,
            webBankList: this.props.postMapWebBankResult,
          });
        }
      } else {
        Modal.error.show({
          action: () => {
            this.handleCancelReserveWithDraw(this.state.txData!);
            Modal.error.hide();
          },
          description: get(responseMessage, this.props.postMapWebBankCode!, ""),
          actionText: "ไม่สามารถอนุมัติได้",
        });
      }
    }
    if (
      prevProps.getWebBankActiveIsFetching !==
      this.props.getWebBankActiveIsFetching &&
      !this.props.getWebBankActiveIsFetching
    ) {
      this.props.loader(false);
    }

    if (
      prevProps.putTransactionIsFetching !==
      this.props.putTransactionIsFetching &&
      !this.props.putTransactionIsFetching
    ) {
      this.props.loader(false);
      this.props.loader(true);
      this.getWithDrawTxAndSubscribe();
    }

    if (
      prevProps.getCreditInfoListIsFetching !==
      this.props.getCreditInfoListIsFetching &&
      !this.props.getCreditInfoListIsFetching
    ) {
      this.props.loader(false);
      if (this.props.getCreditInfoListCode === responseCode.OK) {
        if (this.state.getCreditInfo.isFirst) {
          this.setState({
            getCreditInfo: { ...this.state.getCreditInfo, isFirst: false },
          });
          Modal.transactionItemCollapsible.show({
            dataStore: this.props.storeCreditMetail,
            action: this.GetCreditinfo,
            getCreditMedetail: this.onGetCreditMedetail,
            cancelAction: () => {
              this.setState({
                getCreditInfo: { ...this.state.getCreditInfo, isFirst: true },
              });
              this.setState({ hasMore: true });
              this.props.clearCreditInfoList();
              Modal.transactionItemCollapsible.hide();
            },
            hasMore: this.state.hasMore,
            creditInfo: this.props.creditInfo,
            money: this.state.getCreditInfo.money,
            creditName: this.state.creditName,
          });
        } else {
          Modal.transactionItemCollapsibleUpdate.show({
            dataStore: this.props.storeCreditMetail,
            action: this.GetCreditinfo,
            getCreditMedetail: this.onGetCreditMedetail,
            cancelAction: () => {
              this.setState({
                getCreditInfo: { ...this.state.getCreditInfo, isFirst: true },
              });
              this.setState({ hasMore: true });
              this.props.clearCreditInfoList();
              Modal.transactionItemCollapsible.hide();
            },
            creditName: this.state.creditName,
            hasMore: this.state.hasMore,
            creditInfo: this.props.creditInfo,
            money: this.state.getCreditInfo.money,
          });
        }
        if (
          this.props.creditInfoPagination.page >=
          this.props.creditInfoPagination.total
        ) {
          Modal.transactionItemCollapsibleUpdate.show({
            getCreditMedetail: this.onGetCreditMedetail,
            dataStore: this.props.storeCreditMetail,
            cancelAction: () => {
              this.setState({
                getCreditInfo: { ...this.state.getCreditInfo, isFirst: true },
              });
              this.setState({ hasMore: true });
              this.props.clearCreditInfoList();
              Modal.transactionItemCollapsible.hide();
            },
            creditName: this.state.creditName,
            creditInfo: this.props.creditInfo,
            money: this.state.getCreditInfo.money,
            hasMore: false,
          });
          this.setState({ hasMore: false });
        }
      }
    }

    if (prevProps.storeCreditMetail !== this.props.storeCreditMetail) {
      Modal.transactionItemCollapsibleUpdate.show({
        dataStore: this.props.storeCreditMetail,
        action: this.GetCreditinfo,
        getCreditMedetail: this.onGetCreditMedetail,
        // creditDetail: this.getStoreDataDetail,
        cancelAction: () => {
          this.setState({
            getCreditInfo: { ...this.state.getCreditInfo, isFirst: true },
          });
          this.setState({ hasMore: true });
          this.props.clearCreditInfoList();
          Modal.transactionItemCollapsible.hide();
        },
        creditName: this.state.creditName,
        hasMore: this.state.hasMore,
        creditInfo: this.props.creditInfo,
        money: this.state.getCreditInfo.money,
      });
    }
  }

  componentWillUnmount() {
    // this.props.clearTransactionAll()
    this.props.unlistenTransactionAllSocket();
  }

  onGetCreditMedetail = (createdAt: string, slug: string, status: string) => {
    this.props.meDetailRequest!(
      { created_at: createdAt, slug: slug, status: status },
      this.state.getCreditInfo.ID
    );
  };

  getQuerywebBankActive = (): IGetWebBankActiveParams => {
    return {
      operateType: "WITHDRAW",
    };
  };
  createData(txData: IWalletTransactionPopuate): ITransactionRow {
    return {
      id: String(txData.tag),
      type: txData.type,
      money: number.decimalsFormatCommaWithOutZero(txData.money),
      phoneNo: get(txData.userId, "phoneNumber", ""),
      userName: get(txData.userId, "username", ""),
      userBank: get(txData.userBankId, "name", ""),
      bankName: get(txData.userBankId, "type", ""),
      bankAccountNo: number.formatBankNumber(
        get(txData.userBankId, "number", ""),
        get(txData.userBankId, "type", "")
      ),
      userTxTime:
        txData.userTxTime === null || txData.userTxTime === undefined
          ? "-"
          : `${date.FormatDate(txData.userTxTime, "Do MMM YY | k:mm น.")}`,
      status: this.createActionElement(txData),
      approveUserId:
        txData.approveUserId === null || txData.approveUserId === undefined
          ? "-"
          : txData.approveUserId.username,
      approval:
        txData.updatedAt === null || txData.updatedAt === undefined
          ? "-"
          : `${date.FormatDate(txData.updatedAt, "Do MMM YY | k:mm น.")}`,
      statement:
        txData.status === "WAIT" ? (
          <Button
            text={constants.detailLabel}
            theme="outline"
            onClick={() => this.onGetCreditinfo(txData)}
          />
        ) : (
          <></>
        ),
    };
  }

  onGetCreditinfo(txData: IWalletTransactionPopuate) {
    this.setState({ creditName: get(txData.userId, "username", "") });
    let reqData: IGetCreditInfo;
    reqData = {
      isFirst: this.state.getCreditInfo.isFirst,
      ID: txData.userId.id,
      money: txData.money,
    };
    this.setState(
      { getCreditInfo: reqData },
      () => (
        // eslint-disable-next-line
        this.props.loader(true), this.GetCreditinfo()
      )
    );
  }

  transformType2Th(type: string) {
    switch (type) {
      case "DEPOSIT":
        return "ฝากเงิน";
      case "WITHDRAW":
        return "ถอนเงิน";
      default:
        return "ผิดพลาด";
    }
  }

  createActionElement(txData: IWalletTransactionPopuate): JSX.Element {
    switch (txData.status) {
      case ESTATUS.FAIL:
        return (
          <div className="d-flex align-items-center">
            <Chipper label={constants.disApproval} color="red" />
            <label className="ml-4">{txData.description}</label>
          </div>
        );
      case ESTATUS.SUCCESS:
        return (
          <>
            <div className="d-flex align-items-center">
              <Chipper label={constants.approval} color="green" />
              <div className="ml-4">
                <div>{get(txData.webBankId, "type", "")}</div>
                <div>
                  {number.formatBankNumber(
                    get(txData.webBankId, "number", ""),
                    get(txData.webBankId, "type", "")
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case ESTATUS.RESERVE:
        return (
          <div className="button-container">
            <Button
              text={`${txData.approveUserId.username} ${constants.reserve}`}
              theme="outline"
              onClick={() => this.handleforceCancelReserveWithDraw(txData)}
            />
          </div>
        );
      case ESTATUS.WAIT:
        return (
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className="button-container">
                  <Button
                    theme="outline"
                    color="red"
                    text={constants.disApproval}
                    onClick={() => this.handleOnUpdateTx(txData, ESTATUS.FAIL)}
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="button-container">
                  <Button
                    color="green"
                    text={constants.approval}
                    onClick={() => {
                      this.setState({
                        txData: txData,
                        statusButton: ESTATUS.SUCCESS,
                      });
                      this.handleOnUpdateTx(txData, ESTATUS.SUCCESS);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  }

  handleApproveWithDraw = (
    webBank: number,
    status: "SUCCESS" | "FAIL",
    txId: number
  ) => {
    if (webBank !== 0) {
      const reqData = {
        status: status,
        wallet_tx_id: txId,
        user_tx_time: moment().format(),
      };
      this.props.loader(true);
      this.props.putTransaction(reqData);
      ModalWithdraw.success.hide();
    }
  };

  handleCancelWithDraw = (
    description: string,
    status: "SUCCESS" | "FAIL",
    txId: number
  ) => {
    const reqData = {
      status: status,
      wallet_tx_id: txId,
      user_tx_time: moment().format(),
      description: description,
    };
    this.props.loader(true);
    this.props.putTransaction(reqData);
    ModalWithdraw.error.hide();
  };

  handleReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.postReserveTransaction({ wallet_tx_id: walletTxData.id });
  };
  handleCancelReserveWithDraw = (walletTxData: IWalletTransactionPopuate) => {
    this.props.cancelReserveTransaction({ wallet_tx_id: walletTxData.id });
  };
  handleforceCancelReserveWithDraw = (
    walletTxData: IWalletTransactionPopuate
  ) => {
    this.props.forceCancelReserveTransaction({ wallet_tx_id: walletTxData.id });
  };

  handleOnUpdateTx(txData: IWalletTransactionPopuate, status: ESTATUS) {
    let reqData: ITransactionPutData;
    switch (txData.type) {
      case "DEPOSIT":
        this.props.loader(true);
        reqData = {
          status: status,
          wallet_tx_id: txData.id,
        };
        this.props.putTransaction(reqData);
        break;
      case "WITHDRAW":
        if (status === "SUCCESS") {
          this.handleReserveWithDraw(txData);
          this.props.loader(true);
          this.props.postMapWebBank!({ id: txData.id });
        } else {
          this.handleReserveWithDraw(txData);
          ModalWithdraw.error.show({
            action: this.handleCancelWithDraw,
            cancelAction: () => {
              this.handleCancelReserveWithDraw(txData);
              ModalWithdraw.error.hide();
            },
            txId: txData.id,
            txStatus: status,
            title: constants.notWithDraw,
            subTitle: constants.disApprovalSureWithdraw,
            name: txData.userId.username,
            bank: txData.userBankId.type,
            accountNo: txData.userBankId.number,
            money: txData.money,
          });
        }
        break;
    }
  }

  handleTextAlign = (align: any) => {
    let alignType;
    switch (align) {
      case "center":
        alignType = "MuiTableCell-alignCenter";
        break;
      case "right":
        alignType = "MuiTableCell-alignRight";
        break;
      case "left":
        alignType = "MuiTableCell-alignLeft";
        break;
    }
    return alignType;
  };

  handleQuerySearch = (value: string) => {
    this.setState({ search: value, page: 0 }, () => {
      clearTimeout(this.state.queryTimeout);
      this.setState({
        queryTimeout: setTimeout(() => {
          this.getWithDrawTxAndSubscribe();
        }, 2000),
      });
    });
  };

  handleFilterStatus = (status: IWalletTransactionStatus) => {
    this.setState({ dataStatus: status }, () =>
      this.getWithDrawTxAndSubscribe()
    );
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        currentPage: 0,
        page: 0,
        limit: Number(event.target.value),
      },
      () => {
        this.getWithDrawTxAndSubscribe();
      }
    );
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    this.setState(
      {
        currentPage: newPage,
        page: newPage,
      },
      () => {
        this.getWithDrawTxAndSubscribe();
      }
    );
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ selectedDate: date }, () => {
      clearTimeout(this.state.dateTimeout);
      this.setState({
        dateTimeout: setTimeout(() => {
          if (
            date !== null &&
            !(moment(date).isValid())
          ) {
            const dateMoment = moment(date);
            this.setState({
              date: dateMoment.format("DDMMYYYY"),
              currentPage: 0,
            });
            // TODO
            this.getWithDrawTxAndSubscribe();
          } else {
            this.setState({ date: "" });
            this.getWithDrawTxAndSubscribe();
          }
        }, 2000),
      });
    });
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.withDrawLabel, active: true },
    ];
    const { tableRows, limit, total } = this.state;
    // TODO
    const columnsFilter = columns.filter((item) =>
      this.state.dataStatus === "WAIT" ? item : item.id !== "statement"
    );
    return (
      <div className="textfield-container">
        <Breadcrumb
          items={navigates}
          handleOnClickItem={this.handleOnClickBreadcrumb}
        />
        <TitlePage title="จัดการรายการถอน" />
        <Paper className="secondary-dark" elevation={3}>
          <TableContainer className="custom-scrollbar">
            <Grid container className="table-title">
              <Grid item lg={6} md={4} sm={2}>
                <div>
                  <h5>รายการถอน</h5>
                </div>
              </Grid>

              <Grid item lg={6} md={8} sm={10} className="Xs4">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div className="th-container">
                      <TextField
                        placeholder="ค้นหา"
                        label={this.state.search ? "ค้นหา" : ""}
                        startElement={<SearchIcon />}
                        value={this.state.search}
                        onChange={(e) => this.handleQuerySearch(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="th-container">
                      <DateTime
                        id="date"
                        placeHolder="วันที่"
                        label={this.state.selectedDate ? "วันที่" : ""}
                        value={this.state.selectedDate}
                        onChange={this.handleDateChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="th-container">
                      <Select
                        label="สถานะ"
                        value={this.state.dataStatus}
                        onChange={(e) =>
                          this.handleFilterStatus(e.target.value)
                        }
                        itemList={[
                          {
                            value: "WAIT",
                            element: <h6>รอตรวจสอบ</h6>,
                          },
                          {
                            value: "SUCCESS",
                            element: <h6>{constants.approval}</h6>,
                          },
                          {
                            value: "FAIL",
                            element: <h6>{constants.disApproval}</h6>,
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columnsFilter.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columnsFilter.map((column) => {
                        const value = row[column.id]
                        let secondaryText = ""

                        if (column.id === "approval") {
                          secondaryText = row.approveUserId
                        } else if (column.id === "userBank") {
                          secondaryText = row.userTxTime
                        } else if (column.id === "bankName") {
                          secondaryText = row.bankAccountNo
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={this.handleTextAlign(column.align)}
                          >
                            {secondaryText && <div className="secondary-text">{secondaryText}</div>}
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                            {(column.id === "statement") && 
                            (this.props.userMeResult.permission === 'SUPER_ADMIN' 
                            || this.props.userMeResult.permission === 'STAFF'
                            || this.props.userMeResult.permission === 'ADMIN')
                            &&  (
                              <Button
                                buttonClass={'btn-width'}
                                size="small"
                                text={constants.check}
                                onClick={() => this.props.history.push(`/verify/?phoneNo=${row.phoneNo}`)}
                              />
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                {tableRows.length === 0 && (
                  <TableRow hover role="checkbox">
                    <TableCell key={"no data"}>ไม่มีข้อมูล...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="primary-text"
            component="div"
            count={total}
            page={this.state.currentPage}
            onChangePage={this.handleChangePage}
            rowsPerPage={limit}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="รายการ/หน้า:"
            labelDisplayedRows={() =>
              `${this.state.currentPage + 1}-${this.props.getTransactionPageTotal
              } หน้า`
            }
          />
        </Paper>
      </div>
    );
  }
}

export default TransactionContainer;
