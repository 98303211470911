import { Paper } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import "./benefitCard.style.scss";
import rightArrow from "assets/images/reportBenefit/right-arrow.png";
import calendar from "assets/images/reportBenefit/calendar.png";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { th } from "date-fns/locale";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import colors from "constants/colors";
import { Select } from "components";
import { forOwn, noop } from "lodash";
import {
  LOTTO_FLAG_REPORT_BENEFIT,
  SLUG_EXTERNAL_CASINO,
  SLUG_EXTERNAL_SLOT,
  SLUG_LOTTO_NAME,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import ExternalCasinoImage from "assets/images/reportBenefit/externalCasino";
import ExternalSlotImage from "assets/images/reportBenefit/externalSlot";
import number from "utils/number";
import OverwriteBEERADate from 'utils/OverwriteBEEraDate'

const constants = {
  summary: "สรุปผล",
  lotto: "หวย",
  externalCasino: "คาสิโนนอก",
  externalSlot: "สล็อตนอก",
  profit: "กำไร",
  loss: "ขาดทุน",
  total: "รวม",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IBenefitCard = {
  title: "",
  handleReport() {
    noop();
  },
  profit: 0,
  loss: 0,
  total: 0,
};

const BenefitCard: FC<IBenefitCard & DefaultProps> = (props) => {
  const { title, handleReport, profit, loss, total } = props;
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [selectedEndDate, handleEndDateChange] =
    useState<MaterialUiPickersDate>();
  const [selectedStartDate, handleStartDateChange] =
    useState<MaterialUiPickersDate>();

  const [selectedGame, handleSelectedGameChange] = useState<string>("ALL");

  useEffect(() => {
    if (handleReport) {
      handleReport(selectedGame, selectedStartDate, selectedEndDate);
    }
  }, [selectedGame, handleReport, selectedStartDate, selectedEndDate]);

  const renderTitleImage = () => {
    if (title === constants.lotto) {
      const FlagIcon =
        LottoFlags[LOTTO_FLAG_REPORT_BENEFIT[selectedGame as TSlugLotto]];
      return FlagIcon;
    }

    if (title === constants.externalCasino) {
      const ExternalCasinoIcon =
        ExternalCasinoImage[selectedGame as TSlugExternalCasino];
      return ExternalCasinoIcon;
    }

    if (title === constants.externalSlot) {
      const ExternalSlotIcon =
        ExternalSlotImage[selectedGame as TSlugExternalSlot];
      return ExternalSlotIcon;
    }

    const FlagIcon =
      LottoFlags[LOTTO_FLAG_REPORT_BENEFIT[selectedGame as TSlugLotto]];
    return FlagIcon;
  };

  const handleSelectedGameDropdown = (selectedGame: string) => {
    handleSelectedGameChange(selectedGame);
  };

  const generateItemList = () => {
    if (title === constants.lotto) {
      let lottoItemList: { value: string; label: string }[] = [];
      forOwn(SLUG_LOTTO_NAME, (value, key) => {
        lottoItemList.push({ value: key, label: value });
      });
      return lottoItemList;
    }

    if (title === constants.externalCasino) {
      let externalCasinoItemList: { value: string; label: string }[] = [];
      forOwn(SLUG_EXTERNAL_CASINO, (value, key) => {
        externalCasinoItemList.push({ value: key, label: value });
      });
      return externalCasinoItemList;
    }

    if (title === constants.externalSlot) {
      let externalSlotItemList: { value: string; label: string }[] = [];
      forOwn(SLUG_EXTERNAL_SLOT, (value, key) => {
        externalSlotItemList.push({ value: key, label: value });
      });
      return externalSlotItemList;
    }

    let lottoItemList: { value: string; label: string }[] = [];
    forOwn(SLUG_LOTTO_NAME, (value, key) => {
      lottoItemList.push({ value: key, label: value });
    });
    return lottoItemList;
  };

  return (
    <Paper className="paper-container secondary-dark benefit-card-container">
      <div className="d-flex">
        <div className="benefit-card-title-container p1 p3-l w-50 d-flex align-items-center">
          <h3>{title}</h3>
        </div>
        <div className="benefit-card-date-title-wrapper d-flex align-items-center">
          <div className="benefit-card-date-title-container primary-purple d-flex align-items-center">
            <div className="start-date-container">
              {selectedStartDate === undefined && (
                <div
                  className="w-100 h-100 d-flex justify-content-center align-items-center"
                  onClick={() => setIsStartDateOpen(true)}
                >
                  <img
                    src={calendar}
                    alt="calendar-img"
                    className="calendar-icon"
                  />
                </div>
              )}

              <div
                style={
                  selectedStartDate === undefined
                    ? { width: "0%", visibility: "hidden" }
                    : {
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }
                }
              >
                <MuiPickersUtilsProvider utils={OverwriteBEERADate} locale={th}>
                  <DatePicker
                    variant="inline"
                    format="dd MMM yyyy"
                    value={selectedStartDate}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: { color: colors.PRIMARY_DARK, fontSize: 12 },
                    }}
                    onChange={(date) => {
                      handleStartDateChange(date);
                    }}
                    autoOk
                    open={isStartDateOpen}
                    onOpen={() => setIsStartDateOpen(true)}
                    onClose={() => setIsStartDateOpen(false)}
                    disableFuture
                    className={
                      "w-100 h-100 d-flex align-items-center justify-content-center"
                    }
                    shouldDisableDate={(date) => {
                      if (selectedEndDate) {
                        return (
                          selectedEndDate.setHours(0, 0, 0, 0) <
                          date!.setHours(0, 0, 0, 0)
                        );
                      }
                      return false;
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="right-arrow-container">
              <img
                src={rightArrow}
                alt="right-arrow-img"
                className="right-arrow-icon"
              />
            </div>
            <div className="end-date-container">
              {selectedEndDate === undefined && (
                <div
                  className="w-100 h-100 d-flex justify-content-center align-items-center"
                  onClick={() => setIsEndDateOpen(true)}
                >
                  <img
                    src={calendar}
                    alt="calendar-img"
                    className="calendar-icon"
                  />
                </div>
              )}

              <div
                style={
                  selectedEndDate === undefined
                    ? { width: "0%", visibility: "hidden" }
                    : {
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }
                }
              >
                <MuiPickersUtilsProvider utils={OverwriteBEERADate} locale={th}>
                  <DatePicker
                    variant="inline"
                    format="dd MMM yyyy"
                    value={selectedEndDate}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: { color: colors.PRIMARY_DARK, fontSize: 12 },
                    }}
                    onChange={(date) => handleEndDateChange(date)}
                    autoOk
                    open={isEndDateOpen}
                    onOpen={() => setIsEndDateOpen(true)}
                    onClose={() => setIsEndDateOpen(false)}
                    disableFuture
                    className={
                      "w-100 h-100 d-flex align-items-center justify-content-center"
                    }
                    shouldDisableDate={(date) => {
                      if (selectedStartDate) {
                        return (
                          selectedStartDate.setHours(0, 0, 0, 0) >
                          date!.setHours(0, 0, 0, 0)
                        );
                      }
                      return false;
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tertiary-purple-bg-container d-flex flex-row align-items-center"
        style={
          title === constants.summary
            ? { backgroundColor: colors.TRANSPARENT, visibility: "hidden" }
            : { backgroundColor: undefined }
        }
      >
        <div className="title-image-container d-flex align-items-center">
          <img
            src={renderTitleImage().Icon}
            alt={renderTitleImage().name}
            className="dropdown-icon"
          />
        </div>
        <div className="m2-l select-game-dropdown-container">
          <Select
            value={selectedGame}
            onChange={(e) => handleSelectedGameDropdown(e.target.value)}
            itemList={generateItemList()}
            fullWidth={true}
            size="small"
          />
        </div>
      </div>
      <div className="m2-t d-flex flex-row">
        <div className="w-25">
          <h6 className="primary-text">{constants.profit}</h6>
        </div>
        <div className="w-25 d-flex justify-content-end">
          <h6 className={Number(profit!) > 0 ? "primary-green-text" : "primary-text"}>
            {number.decimalsFormatComma(profit!.toString())}
          </h6>
        </div>
      </div>
      <div className="m2-t d-flex flex-row">
        <div className="w-25">
          <h6 className="primary-text">{constants.loss}</h6>
        </div>
        <div className="w-25 d-flex justify-content-end">
          <h6 className={Number(loss!) < 0 ? "primary-red-text" : "primary-text"}>
            {number.decimalsFormatComma(loss!.toString())}
          </h6>
        </div>
      </div>
      <div className="m2-t d-flex flex-row">
        <div className="w-25">
          <h6 className="primary-text">{constants.total}</h6>
        </div>
        <div className="w-25 d-flex justify-content-end">
          <h6
            className={
              total === 0
                ? "primary-text"
                : Number(total!) < 0
                ? "primary-red-text"
                : `primary-green-text`
            }
          >
            {number.decimalsFormatComma(total!.toString())}
          </h6>
        </div>
      </div>
    </Paper>
  );
};

BenefitCard.defaultProps = defaultProps;

export default BenefitCard;
