import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const criminalViewReducer = (
  state: ICriminalViewState = initialState,
  action: RootAction):
  ICriminalViewState => {
  switch (action.type) {
    case getType(actions.getCriminalViewAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getCriminalViewSuccessAction):
      const data = {
        dataList: action.payload.data.data.dataList,
        page: (action.payload.data.data.page - 1),
        limit: action.payload.data.data.limit,
        total: action.payload.data.data.limit * action.payload.data.data.total,
        pageTotal:action.payload.data.data.total,
      }
      return {
        isFetching: false,
        data: action.payload.data.data.dataList,
        pagination: data,
        code: action.payload.data.code
        ,
      }
    case getType(actions.getCriminalViewFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.response?.status,
      }
    default:
      return state
  }
}
export default criminalViewReducer