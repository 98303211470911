import { string, object, ObjectSchema } from 'yup'
import { NUMBER_NO } from 'constants/regex'

const constants = {
  onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
  number: 'กรุณากรอกตัวเลข',
  twoLength: 'กรุณากรอกไม่เกิน 2 ตัว',
  twoMinLength:'กรุณากรอกให้ครบ 2 ตัว',
  threeLength: 'กรุณากรอกไม่เกิน 3 ตัว',
  threeMinLength:'กรุณากรอกให้ครบ 3 ตัว',
  fourLength: 'กรุณากรอกไม่เกิน 4 ตัว',
  fourMinLength:'กรุณากรอกให้ครบ 4 ตัว',
}

const scheme: ObjectSchema<ILottoDigits> = object().shape({
  twoDown: string()
  .matches(NUMBER_NO, constants.onlyNumber)
  .min(2, constants.twoMinLength)
  .length(2,constants.twoLength),
  threeUp: string()
  .matches(NUMBER_NO, constants.onlyNumber)
  .min(3, constants.threeMinLength)
  .length(3,constants.threeLength),
  fourSuit: string()
  .matches(NUMBER_NO, constants.onlyNumber)
  .min(4, constants.fourLength)
  .length(4,constants.fourMinLength),
})

export default scheme