import React, { Component } from 'react'
import { get, isUndefined, isNull } from 'lodash'
import { Grid } from '@material-ui/core'
import { select, boolean } from '@storybook/addon-knobs'
import { date, number } from 'utils'
// @ts-ignore
import { generateDrinkStats } from '@nivo/generators'
import { ResponsiveLine, Datum } from '@nivo/line'
import './IncomeSummary.component.scss'

const constants = {
    incomeLabel: 'ได้รวม',
    moneyLoseLabel: 'เสียรวม',
    averageIncomeLabel: 'ได้/เสีย เฉลี่ย',
}

const data = generateDrinkStats(18)
const commonProperties = {
    margin: { top: 20, right: 20, bottom: 70, left: 80 },
    data,
    animate: true,
    enableSlices: 'x',
}
const curveOptions = ['linear', 'monotoneX', 'step', 'stepBefore', 'stepAfter']
// @ts-ignore
const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
        <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
)

class IncomeSummary extends Component<IResponseReportOverview, any> {
    state = {
        averagePerDay: '',
    }

    rangeOfData = (data1List: Datum[] = [], data2List: Datum[] = []): IIncomeSummaryRangeOfData => {
        const rangeVar = 1.1
        let maxValueData = 0
        let minValueData = 0
        for (const data1 of data1List) {
            if (isNull(data1.y) || isUndefined(data1.y)) continue
            if (maxValueData < data1.y)
                maxValueData = data1.y as number
            if (minValueData > data1.y)
                minValueData = data1.y as number
        }
        for (const data2 of data2List) {
            if (isNull(data2.y) || isUndefined(data2.y)) continue
            if (maxValueData < data2.y)
                maxValueData = data2.y as number
            if (minValueData > data2.y)
                minValueData = data2.y as number
        }
        maxValueData = Math.floor(maxValueData * rangeVar)
        minValueData = Math.floor(minValueData * rangeVar)
        return { max: maxValueData, min: minValueData }
    }

    averageLine = (incomeList: { x: string; y: number | null }[], moneyLoseList: { x: string; y: number | null }[])
        : {
            dataList: { x: string; y: number | null }[];
            averagePerDay: number;
        } => {
        const averageLine = []
        let maxStackResultIncome = 0
        let maxStackResultMoneyLost = 0
        for (let i = 0; i < incomeList.length; i++) {
            if (!isNull(incomeList[i].y) && incomeList[i].y !== 0) {
                maxStackResultIncome += incomeList[i].y!
                averageLine.push({
                    x: incomeList[i].x, y: ((maxStackResultIncome + maxStackResultMoneyLost) / 2),
                })
                continue
            }
            if (!isNull(moneyLoseList[i].y) && moneyLoseList[i].y !== 0) {
                maxStackResultMoneyLost += moneyLoseList[i].y!
                averageLine.push({
                    x: moneyLoseList[i].x, y: ((maxStackResultIncome + maxStackResultMoneyLost) / 2),
                })
                continue
            }
            averageLine.push({ x: moneyLoseList[i].x, y: 0 })
        }
        return {
            dataList: averageLine,
            averagePerDay: ((maxStackResultIncome + maxStackResultMoneyLost) / (incomeList.length * 2)),
        }
    }

    render() {
        const { dataList, averagePerDay } = this.averageLine(this.props.incomeLine, this.props.moneyLoseLine)
        const plotData = [
            {
                id: constants.averageIncomeLabel,
                data: dataList,
            },
            {
                id: constants.moneyLoseLabel,
                data: this.props.moneyLoseLine,
            },
            {
                id: constants.incomeLabel,
                data: this.props.incomeLine,
            },
        ]
        const { max: yMax, min: yMin } = this.rangeOfData(get(plotData, '[3].data', []), get(plotData, '[2].data', []))
        return (
            <div className="income-summary-container">
                <div className="graph-container">
                    <ResponsiveLine
                        {...commonProperties}
                        data={plotData}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            useUTC: false,
                            precision: 'day',
                        }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                            type: 'linear',
                            stacked: boolean('stacked', false),
                            min: yMin,
                            max: yMax,
                        }}
                        axisLeft={{
                        }}
                        axisBottom={{
                            // format: '%b %d',
                            format: (value)=>date.FormatDate(value, 'Do MMM'),
                            tickValues: 'every 2 days',
                        }}
                        // @ts-ignore
                        curve={select('curve', curveOptions, 'monotoneX')}
                        pointSymbol={CustomSymbol}
                        pointSize={4}
                        pointBorderWidth={1}
                        pointBorderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.3]],
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        useMesh={true}
                        enableArea={true}
                        colors={['#8A85FF', 'rgb(244, 117, 96)', 'rgb(97, 205, 187)']}
                        legends={[
                            {
                                anchor: 'bottom-left',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 50,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 200,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
                <div className="detail-container">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4>รายละเอียด</h4>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ยอดรวมตั้งแต่เริ่มระบบ</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${this.props.allIncome >= 0 ? 'green' : 'red'}-text`}>
                                {number.numberFormat(String(this.props.allIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ยอดรวมรายเดือน</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${this.props.allIncome >= 0 ? 'green' : 'red'}-text`}>
                                {number.numberFormat(String(this.props.monthIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ยอดเฉลี่ยต่อวัน</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className={`primary-${averagePerDay >= 0 ? 'green' : 'red'}-text`}>
                                {number.numberFormat(String(averagePerDay))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <h5>กำไรสูงสุดต่อวัน</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5 className="primary-green-text">
                                {number.numberFormat(String(this.props.dayBetTxMaxIncome))} บาท
                            </h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5>วันเดือนปี</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5>
                                {this.props.dayOfBetTxMaxIncome ?
                                date.FormatDate(this.props.dayOfBetTxMaxIncome,'LLLL')
                                    : 'ไม่พบข้อมูล'}
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <h5>ขาดทุนสูงสุดต่อวัน</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5 className="primary-red-text">
                                {number.numberFormat(String(this.props.dayBetTxMinIncome))} บาท
                            </h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5>วันเดือนปี</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <h5>
                                {this.props.dayOfBetTxMinIncome ?
                                date.FormatDate(this.props.dayOfBetTxMinIncome,'LLLL')
                                :  'ไม่พบข้อมูล'}
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>กำไรสูงสุดต่อบิล</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className="primary-green-text">
                                {number.numberFormat(String(this.props.monthBetTxMaxIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h5>ขาดทุนสูงสุดต่อบิล</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className="primary-red-text">
                                {number.numberFormat(String(this.props.monthBetTxMinIncome))} บาท
                            </h5>
                        </Grid>
                    </Grid>
                </div>
            </div >
        )
    }
}

export default IncomeSummary