import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import { get } from 'lodash'
import actions from './actions'

const checkRateClientReducer = (state: ICheckRateClientRedux = initialState, action: RootAction):
  ICheckRateClientRedux => {
  switch (action.type) {
    case getType(actions.postCheckRateClientAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postCheckRateClientSuccessAction):
      return {
        isFetching: false,
        data: get(action, 'payload.data.rate', ''),
        code: get(action, 'payload.data.code', ''),
        error: '',
      }

    case getType(actions.postCheckRateClientFailureAction):
      return {
        ...state,
        isFetching: false,
      }
    case getType(actions.postCheckRateClientCancelAction):
      return {
        ...state,
      }
    default:
      return state
  }
}

export default combineReducers({
  rateClient: checkRateClientReducer,
})