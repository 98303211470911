import project from 'constants/project'

export const GET_WEB_CONFIG_REQUEST = 'GET_WEB_CONFIG_REQUEST'
export const GET_WEB_CONFIG_SUCCESS = 'GET_WEB_CONFIG_SUCCESS'
export const GET_WEB_CONFIG_FAILURE = 'GET_WEB_CONFIG_FAILURE'
export const GET_WEB_CONFIG_CANCEL = 'GET_WEB_CONFIG_CANCEL'

export const PUT_WEB_CONFIG_REQUEST = 'PUT_WEB_CONFIG_REQUEST'
export const PUT_WEB_CONFIG_SUCCESS = 'PUT_WEB_CONFIG_SUCCESS'
export const PUT_WEB_CONFIG_FAILURE = 'PUT_WEB_CONFIG_FAILURE'
export const PUT_WEB_CONFIG_CANCEL = 'PUT_WEB_CONFIG_CANCEL'

export const initialState: INewsAllState = {
    isFetching: false,
    code: 0,
    data: {},
    error: '',
}

export const endpoint = {
    getWebConfig: `${project.environment[project.environmentName].api}/config/web/me`,
    putWebConfig: `${project.environment[project.environmentName].api}/config/web/me`,
}