import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  TitlePage,
  TextField,
  Select,
  Button,
  LottoActionCard,
  ResponsiveIcon,
  Modal,
  Breadcrumb,
  InputNumber,
} from "components";
import moment from "moment";
import { Formik, Form, FormikProps } from "formik";
import initialValue from "./models/initialValues";
import scheme from "./models/scheme";
import { noop, isEmpty, map } from "lodash";
import colors from "constants/colors";
import {
  LOTTO_SLUG_NAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_TO_TYPE,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import "./LottoRate.container.scss";
import { responseCode } from "constants/response";
import { COMMACASE } from "constants/regex";
import { transformer, number } from "utils";
import {
  Grid,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const constants = {
  back: "กลับ",
  // lottoLabel: 'แทงหวย',
  closedLabel: "เวลาที่ปิดรับ: ",
  openedYeegeLabel: "เปิดรับ: ",
  closedStatusLabel: "ปิดรับแทง",
  placeholderInput: (type: string) => `กรอก ${type}`,
  waitingStatusLabel: "รอเปิดรับแทง",
  openedYeegeStatusLabel: "24 ชม.",
  round: "รอบ",
  twoUp: "2 ตัวบน",
  twoDown: "2 ตัวล่าง",
  threeUp: "3 ตัวบน",
  fourSuit: "เลขชุด 4 ตัว",
  runUp: "วิ่งบน",
  runDown: "วิ่งล่าง",
  threeToast: "3 โต๊ด",
  level: "ระดับ",
  Rate: "อัตราจ่าย",
  sumTotal: "ยอดรวมปรับระดับ",
  wrongUsernameTitle: "เพิ่มระดับอัตราจ่ายไม่ถูกต้อง",
  wrongUsernameDescription:
    "โปรดกรอกยอดปรับระดับมากกว่าระดับเดิม และอัตราจ่ายที่น้อยกว่าระดับเดิม",
  settingLabel: "ตั้งค่าระบบ",
  betRateLabel: "อัตราจ่ายหวย",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ILottoRateProps & ILottoRateActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: "",
  lottoSchedule: [],
  loader() {
    noop();
  },
  getLottoSchedule() {
    noop();
  },

  getLottoRateLevelIsFetching: false,
  getLottoRateLevelError: "",
  getLottoRateLevelCode: 0,
  getLottoRateLevelResult: [],
  getLottoRateLevel() {
    noop();
  },

  postLottoRateLevelIsFetching: false,
  postLottoRateLevelError: "",
  postLottoRateLevelCode: 0,
  postLottoRateLevelResult: [],
  postLottoRateLevel() {
    noop();
  },

  deleteLottoRateLevelIsFetching: false,
  deleteLottoRateLevelError: "",
  deleteLottoRateLevelCode: 0,
  deleteLottoRateLevelResult: [],
  deleteLottoRateLevel() {
    noop();
  },

  postCheckRateClientIsFetching: false,
  postCheckRateClientError: "",
  postCheckRateClientCode: 0,
  postCheckRateClientResult: "",
  postCheckRateClient() {
    noop();
  },
};

type IColumnId = "level" | "volume_limit" | "rate";
const columns: ITransactionColumn<IColumnId>[] = [
  { id: "level", label: "ระดับ", minWidth: 100, align: "center" },
  {
    id: "volume_limit",
    label: "ยอดรวมปรับระดับ",
    minWidth: 50,
    align: "right",
  },
  { id: "rate", label: "อัตราจ่าย(บาทละ)", minWidth: 50, align: "right" },
];

class LottoRateContainer extends Component<
  ILottoRateProps & ILottoRateActionProps & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  state: ILottoRateContainerState = {
    id: 3,
    tableRows: [],
    selectedDate: null,
    search: "",
    onSettingLotto: "LOTTER_GOVN",
    title: "หวยรัฐบาล",
    startTime: "",
    endTime: "",
    lottoTypeSelect: "THREE_UP",
    rate: "",
    level: "",
    volume_limit: "",
    lottoSchedule: [],
    initialFormValue: initialValue,
    icon: LottoFlags.THA.Icon,
    textSubmit: "เพิ่ม",
    indexSelectRow: NaN,
  };

  componentDidMount() {
    this.props.loader(true);
    this.props.getLottoSchedule();
    this.props.getLottoRateLevel({ type: "THREE_UP", code: "LOTTER_GOVN" });
  }

  componentDidUpdate(prevProps: ILottoRateProps) {
    if (
      prevProps.getLottoScheduleIsFetching !==
        this.props.getLottoScheduleIsFetching &&
      !this.props.getLottoScheduleIsFetching
    ) {
      this.setState({
        lottoSchedule: this.props.lottoSchedule.filter((member) => {
          return member.code !== "LOTTER_YEGEE";
        }),
      });
      this.props.loader(false);
    }

    if (
      prevProps.getLottoRateLevelIsFetching !==
        this.props.getLottoRateLevelIsFetching &&
      !this.props.getLottoRateLevelIsFetching
    ) {
      this.setState({
        tableRows: this.props.getLottoRateLevelResult.map((txResult) =>
          this.createData(txResult)
        ),
      });
      this.props.loader(false);
    }

    if (
      prevProps.postLottoRateLevelIsFetching !==
        this.props.postLottoRateLevelIsFetching &&
      !this.props.postLottoRateLevelIsFetching
    ) {
      if (this.props.postLottoRateLevelCode === responseCode.OK) {
        //
      } else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongUsernameDescription,
          actionText: constants.wrongUsernameTitle,
        });
      }
      this.props.getLottoRateLevel({
        type: this.state.lottoTypeSelect,
        code: this.state.onSettingLotto,
      });
      this.props.loader(false);
    }

    if (
      prevProps.deleteLottoRateLevelIsFetching !==
        this.props.deleteLottoRateLevelIsFetching &&
      !this.props.deleteLottoRateLevelIsFetching
    ) {
      this.props.getLottoRateLevel({
        type: this.state.lottoTypeSelect,
        code: this.state.onSettingLotto,
      });
      this.props.loader(false);
    }
  }

  createData(txData: ILottoRateLevel): ILottoRateLevelRow {
    return {
      id: txData.id,
      level: String(txData.level),
      rate: transformer.typeFormat(txData.rate),
      volume_limit: transformer.typeFormat(String(txData.volumeLimit)),
    };
  }

  renderLottoList = () =>
    map(this.state.lottoSchedule, (lotto, index) => {
      const subtitle =
        lotto.code === "LOTTER_YEGEE"
          ? constants.openedYeegeLabel
          : constants.closedLabel;
      const backgroundColor =
        lotto.code === this.state.onSettingLotto
          ? colors.SECONDARY_PURPLE
          : colors.SECONDARY_DARK;
      const description =
        lotto.code === "LOTTER_YEGEE"
          ? `88 ${constants.round}`
          : isEmpty(lotto.endTime)
          ? "-"
          : moment(lotto.endTime)
              .add(-7, "hour")
              .add(543, "years")
              .format("DD MMM YY");
      const isCountingDown =
        lotto.code === "LOTTER_YEGEE" ? false : lotto.status === "OPEN";

      const FlagIcon =
        LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon;
      return (
        <div className="lotto-body-item" key={`lotto-${lotto.code}-${index}`}>
          <LottoActionCard
            id={`lotto-${lotto.code}`}
            onClick={() => this.renderBetDetail(lotto)}
            title={LOTTO_SLUG_NAME[lotto.code]}
            subTitle={subtitle}
            icon={FlagIcon}
            backgroundColor={backgroundColor}
            status={lotto.status}
            isCountingdown={isCountingDown}
            closedStatusText={constants.closedStatusLabel}
            waitingStatusText={constants.waitingStatusLabel}
            openedStatusText={constants.openedYeegeStatusLabel}
            description={description}
            expire={lotto.endTime}
          />
        </div>
      );
    });

  renderBetDetail = (lotto: ILottoSchedule) => {
    moment.locale("th");
    this.setState({ onSettingLotto: lotto.code }, () => {
      if (this.state.onSettingLotto !== "LOTTER_LAO_SUITE") {
        this.props.getLottoRateLevel({
          type: "THREE_UP",
          code: this.state.onSettingLotto,
        });
        this.setState({ lottoTypeSelect: "THREE_UP" });
      } else {
        this.setState({ lottoTypeSelect: "FOUR_SUITE" });
        this.props.getLottoRateLevel({
          type: "FOUR_SUITE",
          code: this.state.onSettingLotto,
        });
      }
    });
    this.props.loader(true);
    this.setState({
      id: lotto.id,
      title: LOTTO_SLUG_NAME[lotto.code],
      startTime: lotto.startTime,
      endTime: moment(lotto.endTime).format("lll"),
      icon: LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon,
      initialFormValue: { ...initialValue },
      textSubmit: "เพิ่ม",
      indexSelectRow: NaN,
    });
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ selectedDate: date });
  };

  updateSearch(value: any) {
    this.setState({ search: value.substr(0, 20) });
  }

  handleFilterStatus = (lottoType: any) => {
    this.setState({ lottoTypeSelect: lottoType });
    this.props.loader(true);
    this.props.getLottoRateLevel({
      type: lottoType,
      code: this.state.onSettingLotto,
    });
  };

  handleTextAlign = (align: any) => {
    let alignType;
    switch (align) {
      case "center":
        alignType = "MuiTableCell-body MuiTableCell-alignCenter";
        break;
      case "left":
        alignType = "MuiTableCell-body MuiTableCell-alignLeft";
        break;
    }
    return alignType;
  };

  handleTextAlignaa = (align: any) => {
    let alignType;
    switch (align) {
      case "center":
        alignType = "MuiTableCell-bodyaa MuiTableCell-alignCenter";
        break;
      case "left":
        alignType = "MuiTableCell-bodyaa MuiTableCell-alignLeft";
        break;
    }
    return alignType;
  };

  handleInputText = (data: any, index: any, props: FormikProps<ILottoRate>) => {
    props.setErrors({
      ...props.errors,
      level: "",
      rate: "",
      volumeLimit: "",
    });
    props.setTouched({
      ...props.touched,
      level: false,
      rate: false,
      volumeLimit: false,
    });
    const aa: ILottoRate = {
      level: data.level,
      rate: data.rate.substring(1, data.rate.lenght),
      volumeLimit: data.volume_limit.substring(1, data.volume_limit.lenght),
      timeStamp: moment().unix(),
    };
    this.setState({
      indexSelectRow: index,
      textSubmit: "บันทึก",
      id: data.id,
      level: data.level,
      rate: data.rate,
      volume_limit: data.volume_limit,
      initialFormValue: aa,
    });
    this.props.postCheckRateClient({
      rate: data.rate.replace(/฿/, "").replace(/,/g, ""),
      type: "lotter",
    });
  };

  onChangeText = (data: object) => {
    Object.entries(data).forEach(([key, value]) => {
      if (key === "level") {
        this.setState({ level: value });
      } else if (key === "rate") {
        this.setState({ rate: value });
      } else if (key === "volume_limit") {
        this.setState({ volume_limit: value });
      }
    });
  };

  createActionElement = (
    key: ILottoRateActionButton,
    props: FormikProps<ILottoRate>
  ) => {
    switch (key.name) {
      case "volumeLimit":
        return (
          <TextField
            name="volumeLimit"
            onBlur={props.handleBlur}
            label="ยอดรวมปรับระดับ"
            fullWidth={true}
            onChange={(e) => {
              if (COMMACASE.test(e.target.value) && e.target.value !== "") {
                props.setFieldValue(
                  "volumeLimit",
                  number.numberFormat(number.castToInteger(e.target.value))
                );
              } else {
                props.setFieldValue("volumeLimit", e.target.value);
              }
            }}
            placeholder={constants.placeholderInput(constants.sumTotal)}
            error={!!props.errors.volumeLimit && props.touched.volumeLimit}
            value={String(props.values.volumeLimit)}
            helperText={
              !!props.errors.volumeLimit && props.touched.volumeLimit
                ? `${props.errors.volumeLimit}`
                : ""
            }
          />
        );
      case "rate":
        return (
          <InputNumber
            name="rate"
            onBlur={props.handleBlur}
            label="อัตราจ่าย"
            fullWidth={true}
            onValueChange={({ value }) => {
              this.props.postCheckRateClient({ rate: value, type: "lotter" });
              props.setFieldValue("rate", value);
            }}
            thousandSeparator
            decimalScale={2}
            placeholder={constants.placeholderInput(constants.Rate)}
            error={!!props.errors.rate && props.touched.rate}
            value={props.values.rate}
            helperText={
              !!props.errors.rate && props.touched.rate
                ? `${props.errors.rate}`
                : ""
            }
            endElement={
              <div>อัตราจ่ายลูกค้า: {this.props.postCheckRateClientResult}</div>
            }
          />
        );
      default:
        return <></>;
    }
  };

  handleDeleteButton = (props: FormikProps<ILottoRate>): JSX.Element => {
    const isLastIndex =
      Number(props.values.level) !== this.state.tableRows.length;
    const isEmptyInputGroup =
      isEmpty(props.values.rate) && isEmpty(props.values.volumeLimit);
    return (
      <>
        <Grid item xs={4}>
          <Button
            type="submit"
            text={this.state.textSubmit}
            fullWidth={true}
            onClick={() => {
              props.setFieldValue("isSecondButton", true);
            }}
            color="green"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            text={"ยกเลิก"}
            fullWidth={true}
            onClick={() => {
              this.setState({ textSubmit: "เพิ่ม" });
              this.setState({ indexSelectRow: NaN });
              props.setValues({
                ...props.values,
                volumeLimit: "",
                rate: "",
              });
              props.setErrors({
                ...props.errors,
                level: "",
                rate: "",
                volumeLimit: "",
              });
              props.setTouched({
                ...props.touched,
                level: false,
                rate: false,
                volumeLimit: false,
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            text="ลบ"
            fullWidth={true}
            color="red"
            disabled={isLastIndex || isEmptyInputGroup}
          />
        </Grid>
      </>
    );
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };
  render() {
    const { tableRows } = this.state;
    const HandleDeleteButton = this.handleDeleteButton;
    const RenderLottoList = this.renderLottoList().filter((Lotto) => {
      return (
        Lotto.props.children.props.title
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    const navigates: IBreadcrumbItem[] = [
      { label: constants.settingLabel, active: false },
      { label: constants.betRateLabel, active: true },
    ];
    return (
      <div className="lotto-rate-container primary-bg">
        <Breadcrumb
          items={navigates}
          handleOnClickItem={this.handleOnClickBreadcrumb}
        />
        <TitlePage title={constants.betRateLabel} />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="m3-b">
              <TextField
                label="ค้นหา"
                startElement={<SearchIcon />}
                fullWidth={true}
                value={this.state.search}
                onChange={(e) => this.updateSearch(e.target.value)}
              />
            </div>
            <div className="lotto-body">
              <SimpleBar style={{ height: "100%" }}>
                {RenderLottoList}
              </SimpleBar>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Paper className="paper-container secondary-dark">
              <div className="paper-title">
                <div className="lotto-action-text-wrapper">
                  <Grid container>
                    <Grid item xs={9} className="d-flex align-items-center">
                      <div className="d-flex flex">
                        <ResponsiveIcon
                          icon={this.state.icon}
                          alt="flag"
                          className="lotto-action-card-flag m2-r"
                        />
                        <h3>{this.state.title}</h3>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      {this.state.onSettingLotto !== "LOTTER_LAO_SUITE" ? (
                        <Select
                          label="สถานะ"
                          fullWidth={true}
                          value={this.state.lottoTypeSelect}
                          onChange={(e) =>
                            this.handleFilterStatus(e.target.value)
                          }
                          itemList={[
                            {
                              value: "THREE_UP",
                              element: <h6>{constants.threeUp}</h6>,
                            },
                            {
                              value: "THREE_TOAST",
                              element: <h6>{constants.threeToast}</h6>,
                            },
                            {
                              value: "TWO_UP",
                              element: <h6>{constants.twoUp}</h6>,
                            },
                            {
                              value: "TWO_DOWN",
                              element: <h6>{constants.twoDown}</h6>,
                            },
                            {
                              value: "RUN_UP",
                              element: <h6>{constants.runUp}</h6>,
                            },
                            {
                              value: "RUN_DOWN",
                              element: <h6>{constants.runDown}</h6>,
                            },
                          ]}
                        />
                      ) : (
                        <Select
                          label="สถานะ"
                          fullWidth={true}
                          value={this.state.lottoTypeSelect}
                          onChange={(e) =>
                            this.handleFilterStatus(e.target.value)
                          }
                          itemList={[
                            {
                              value: "FOUR_SUITE",
                              element: <h6>{constants.fourSuit}</h6>,
                            },
                          ]}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Formik
                initialValues={this.state.initialFormValue}
                validationSchema={scheme}
                enableReinitialize
                onSubmit={(values) => {
                  if (values.isSecondButton) {
                    this.props.loader(true);
                    this.props.postLottoRateLevel({
                      level:
                        this.state.textSubmit === "บันทึก"
                          ? Number(values.level)
                          : this.state.tableRows.length + 1,
                      type: this.state.lottoTypeSelect,
                      code: this.state.onSettingLotto,
                      volume_limit: Number(
                        number.castToInteger(values.volumeLimit)
                      ),
                      rate: values.rate,
                    });
                  } else {
                    this.props.deleteLottoRateLevel({
                      id: this.state.id,
                      type: this.state.lottoTypeSelect,
                      code: this.state.onSettingLotto,
                    });
                  }
                }}
              >
                {(formikProps: FormikProps<ILottoRate>) => {
                  return (
                    // @ts-ignore
                    <Form>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableRows.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                selected={this.state.indexSelectRow === index}
                                role="checkbox"
                                tabIndex={-1}
                                key={`${row.level}  ${index}`}
                                onClick={() =>
                                  this.handleInputText(row, index, {
                                    ...formikProps,
                                  })
                                }
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      className={this.handleTextAlign(
                                        column.align
                                      )}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                          {tableRows.length === 0 && (
                            <TableRow hover role="checkbox">
                              <TableCell key={"no data"}>
                                ไม่มีข้อมูล...
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <div className="m3-t">
                            {this.createActionElement(
                              { name: "volumeLimit" },
                              { ...formikProps }
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="m3-t">
                            {this.createActionElement(
                              { name: "rate" },
                              { ...formikProps }
                            )}
                          </div>
                        </Grid>

                        <HandleDeleteButton {...formikProps} />
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LottoRateContainer;
