import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import configAction from 'reduxs/config/actions'
import checkRateClientAction from 'reduxs/check/actions'
import LottoRateContainer from './LottoRate.container'

const mapStateToProps = (state: RootReducers): ILottoRateProps => ({
    getLottoScheduleIsFetching: state.mantra.config.lotto.schedule.isFetching!,
    getLottoScheduleCode: state.mantra.config.lotto.schedule.code!,
    getLottoScheduleError: state.mantra.config.lotto.schedule.error!,
    lottoSchedule: state.mantra.config.lotto.schedule.data!,

    getLottoRateLevelIsFetching: state.mantra.config.lottoRate.list.isFetching!,
    getLottoRateLevelCode: state.mantra.config.lottoRate.list.code!,
    getLottoRateLevelError: state.mantra.config.lottoRate.list.error!,
    getLottoRateLevelResult: state.mantra.config.lottoRate.list.data!,

    postLottoRateLevelIsFetching: state.mantra.config.lottoRate.create.isFetching!,
    postLottoRateLevelCode: state.mantra.config.lottoRate.create.code!,
    postLottoRateLevelError: state.mantra.config.lottoRate.create.error!,
    postLottoRateLevelResult: state.mantra.config.lottoRate.create.data!,

    deleteLottoRateLevelIsFetching: state.mantra.config.lottoRate.remove.isFetching!,
    deleteLottoRateLevelCode: state.mantra.config.lottoRate.remove.code!,
    deleteLottoRateLevelError: state.mantra.config.lottoRate.remove.error!,
    deleteLottoRateLevelResult: state.mantra.config.lottoRate.remove.data!,

    postCheckRateClientIsFetching: state.mantra.check.rateClient.isFetching!,
    postCheckRateClientCode: state.mantra.check.rateClient.code!,
    postCheckRateClientError: state.mantra.check.rateClient.error!,
    postCheckRateClientResult: state.mantra.check.rateClient.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ILottoRateActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    getLottoSchedule: configAction.getLottoScheduleAction,
    getLottoRateLevel: configAction.getLottoRateLevelAction,
    postLottoRateLevel: configAction.postLottoRateLevelAction,
    deleteLottoRateLevel: configAction.deleteLottoRateLevelAction,
    postCheckRateClient: checkRateClientAction.postCheckRateClientAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoRateContainer)