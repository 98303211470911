import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchPostUserLine, fetchDeleteUserLine  } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const userAllEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postUserLineAction)),
    exhaustMap(action =>
      from(fetchPostUserLine(action.payload))
        .pipe(
          map(actions.postUserLineSuccessAction),
          catchError(error => of(actions.postUserLineFailureAction(error))),
        ),
    )
  )
  const userLineDeleteEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.deleteUserLineAction)),
    exhaustMap(action =>
      from(fetchDeleteUserLine(action.payload))
        .pipe(
          map(actions.deleteUserLineSuccessAction),
          catchError(error => of(actions.deleteUserLineFailureAction(error))),
        ),
    )
  )
export default [
  userAllEpic,
  userLineDeleteEpic,
]
