import { createAction } from "typesafe-actions";
import {
  GET_REPORT_OVERVIEW_REQUEST,
  GET_REPORT_OVERVIEW_CANCEL,
  GET_REPORT_OVERVIEW_SUCCESS,
  GET_REPORT_OVERVIEW_FAILURE,
  GET_REPORT_YEGEE_REQUEST,
  GET_REPORT_YEGEE_CANCEL,
  GET_REPORT_YEGEE_SUCCESS,
  GET_REPORT_YEGEE_FAILURE,
  GET_REPORT_LOTTER_NUMBER_SESSION_REQUEST,
  GET_REPORT_LOTTER_NUMBER_SESSION_CANCEL,
  GET_REPORT_LOTTER_NUMBER_SESSION_SUCCESS,
  GET_REPORT_LOTTER_NUMBER_SESSION_FAILURE,
  POST_REPORT_DEPOSIT_WITHDRAW_REQUEST,
  POST_REPORT_DEPOSIT_WITHDRAW_CANCEL,
  POST_REPORT_DEPOSIT_WITHDRAW_SUCCESS,
  POST_REPORT_DEPOSIT_WITHDRAW_FAILURE,
  GET_REPORT_BENEFIT_SUCCESS,
  GET_REPORT_BENEFIT_FAILURE,
  GET_REPORT_BENEFIT_CANCEL,
  GET_REPORT_BENEFIT_REQUEST,
  CLEAR_REPORT_BENEFIT,
  GET_REPORT_BENEFIT_LOTTERY_REQUEST,
  GET_REPORT_BENEFIT_LOTTERY_SUCCESS,
  GET_REPORT_BENEFIT_LOTTERY_FAILURE,
  GET_REPORT_BENEFIT_LOTTERY_CANCEL,
  CLEAR_REPORT_BENEFIT_LOTTERY,
  GET_REPORT_BENEFIT_EXTERNAL_CASINO_REQUEST,
  GET_REPORT_BENEFIT_EXTERNAL_CASINO_SUCCESS,
  GET_REPORT_BENEFIT_EXTERNAL_CASINO_FAILURE,
  GET_REPORT_BENEFIT_EXTERNAL_CASINO_CANCEL,
  CLEAR_REPORT_BENEFIT_EXTERNAL_CASINO,
  GET_REPORT_BENEFIT_EXTERNAL_SLOT_REQUEST,
  GET_REPORT_BENEFIT_EXTERNAL_SLOT_SUCCESS,
  GET_REPORT_BENEFIT_EXTERNAL_SLOT_FAILURE,
  GET_REPORT_BENEFIT_EXTERNAL_SLOT_CANCEL,
  CLEAR_REPORT_BENEFIT_EXTERNAL_SLOT,
} from "./constants";

import { AxiosResponse, AxiosError } from "axios";
import { Serie } from "@nivo/line";

const getReportOverviewAction = createAction(
  GET_REPORT_OVERVIEW_REQUEST,
  (reslove) => (data: IRequestReportOverview) => reslove(data)
);

const getReportOverviewSuccessAction = createAction(
  GET_REPORT_OVERVIEW_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IReportOverview<Serie>>>) =>
    resolve(data)
);

const getReportOverviewFailureAction = createAction(
  GET_REPORT_OVERVIEW_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
);

const getReportOverviewCancelAction = createAction(GET_REPORT_OVERVIEW_CANCEL);

const getReportYegeeAction = createAction(
  GET_REPORT_YEGEE_REQUEST,
  (reslove) => (data: IRequestReportYegee) => reslove(data)
);

const getReportYegeeSuccessAction = createAction(
  GET_REPORT_YEGEE_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IReportYegee>>) => resolve(data)
);

const getReportYegeeFailureAction = createAction(
  GET_REPORT_YEGEE_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
);

const getReportYegeeCancelAction = createAction(GET_REPORT_YEGEE_CANCEL);

const getReportLotterNumberSessionAction = createAction(
  GET_REPORT_LOTTER_NUMBER_SESSION_REQUEST,
  (reslove) => (data: IRequestReportLotterNumberSession) => reslove(data)
);

const getReportLotterNumberSessionSuccessAction = createAction(
  GET_REPORT_LOTTER_NUMBER_SESSION_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IReportLotterNumberSession>>) =>
    resolve(data)
);

const getReportLotterNumberSessionFailureAction = createAction(
  GET_REPORT_LOTTER_NUMBER_SESSION_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
);

const getReportLotterNumberSessionCancelAction = createAction(
  GET_REPORT_LOTTER_NUMBER_SESSION_CANCEL
);

const postReportDepositWithdrawAction = createAction(
  POST_REPORT_DEPOSIT_WITHDRAW_REQUEST,
  (reslove) => (data: IRequestReportDepositWithdraw) => reslove(data)
);

const postReportDepositWithdrawSuccessAction = createAction(
  POST_REPORT_DEPOSIT_WITHDRAW_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IReportDepositWithdraw>>) =>
    resolve(data)
);

const postReportDepositWithdrawFailureAction = createAction(
  POST_REPORT_DEPOSIT_WITHDRAW_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
);

const postReportDepositWithdrawCancelAction = createAction(
  POST_REPORT_DEPOSIT_WITHDRAW_CANCEL
);


const getReportBenefitAction = createAction(
  GET_REPORT_BENEFIT_REQUEST,
  (resolve) => (data: IQueryReportBenefit) => resolve(data)
);
const getReportBenefitSuccessAction = createAction(
  GET_REPORT_BENEFIT_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IResponseReportBenefit>>) =>
    resolve(data)
);
const getReportBenefitFailureAction = createAction(
  GET_REPORT_BENEFIT_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
);
const getReportBenefitCancelAction = createAction(GET_REPORT_BENEFIT_CANCEL);
const clearReportBenefitAction = createAction(CLEAR_REPORT_BENEFIT)


const getReportBenefitLotteryAction = createAction(GET_REPORT_BENEFIT_LOTTERY_REQUEST,
  (resolve) => (data: IQueryReportBenefitLottery) => resolve(data)
)
const getReportBenefitLotterySuccessAction = createAction(GET_REPORT_BENEFIT_LOTTERY_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IResponseReportBenefitLottery>>) =>
    resolve(data)
)
const getReportBenefitLotteryFailureAction = createAction(GET_REPORT_BENEFIT_LOTTERY_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
)
const getReportBenefitLotteryCancelAction = createAction(GET_REPORT_BENEFIT_LOTTERY_CANCEL)
const clearReportBenefitLotteryAction = createAction(CLEAR_REPORT_BENEFIT_LOTTERY)


const getReportBenefitExternalCasinoAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_CASINO_REQUEST,
  (resolve) => (data: IQueryReportBenefitExternalCasino) => resolve(data)
)
const getReportBenefitExternalCasinoSuccessAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_CASINO_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IResponseReportBenefitExternalCasino>>) => resolve(data)
)
const getReportBenefitExternalCasinoFailureAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_CASINO_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
)
const getReportBenefitExternalCasinoCancelAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_CASINO_CANCEL)
const clearReportBenefitExternalCasinoAction = createAction(CLEAR_REPORT_BENEFIT_EXTERNAL_CASINO)

const getReportBenefitExternalSlotAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_SLOT_REQUEST,
  (resolve) => (data: IQueryReportBenefitExternalSlot) => resolve(data)
)
const getReportBenefitExternalSlotSuccessAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_SLOT_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<IResponseReportBenefitExternalSlot>>) => resolve(data)
)
const getReportBenefitExternalSlotFailureAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_SLOT_FAILURE,
  (resolve) => (error: AxiosError) => resolve(error)
)
const getReportBenefitExternalSlotCancelAction = createAction(GET_REPORT_BENEFIT_EXTERNAL_SLOT_CANCEL
)
const clearReportBenefitExternalSlotAction = createAction(CLEAR_REPORT_BENEFIT_EXTERNAL_SLOT)

export default {
  getReportOverviewAction,
  getReportOverviewSuccessAction,
  getReportOverviewFailureAction,
  getReportOverviewCancelAction,

  getReportYegeeAction,
  getReportYegeeSuccessAction,
  getReportYegeeFailureAction,
  getReportYegeeCancelAction,

  getReportLotterNumberSessionAction,
  getReportLotterNumberSessionSuccessAction,
  getReportLotterNumberSessionFailureAction,
  getReportLotterNumberSessionCancelAction,

  postReportDepositWithdrawAction,
  postReportDepositWithdrawSuccessAction,
  postReportDepositWithdrawFailureAction,
  postReportDepositWithdrawCancelAction,

  getReportBenefitAction,
  getReportBenefitSuccessAction,
  getReportBenefitFailureAction,
  getReportBenefitCancelAction,
  clearReportBenefitAction,

  getReportBenefitLotteryAction,
  getReportBenefitLotterySuccessAction,
  getReportBenefitLotteryFailureAction,
  getReportBenefitLotteryCancelAction,
  clearReportBenefitLotteryAction,

  getReportBenefitExternalCasinoAction,
  getReportBenefitExternalCasinoSuccessAction,
  getReportBenefitExternalCasinoFailureAction,
  getReportBenefitExternalCasinoCancelAction,
  clearReportBenefitExternalCasinoAction,

  getReportBenefitExternalSlotAction,
  getReportBenefitExternalSlotSuccessAction,
  getReportBenefitExternalSlotFailureAction,
  getReportBenefitExternalSlotCancelAction,
  clearReportBenefitExternalSlotAction,
};
