import React, { Component } from 'react'
import {
    Paper,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    Grid,
    ListSubheader
} from '@material-ui/core'
import { get, isEmpty } from 'lodash'
import { number, transformer } from 'utils'
import './DeleteWebBank.scss'
import { History } from 'history';
import { Chipper, Button, Breadcrumb, TitlePage, Snackbar,Modal } from 'components'
import bank from 'assets/images/global/bank'
import { responseCode } from 'constants/response'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const constants = {
    detail: 'รายละเอียด',
    bank: 'ธนาคาร',
    accountNo: 'หมายเลขบัญชี',
    name: 'ชื่อบัญชี',
    income: 'จำนวนรายได้บัญชี',
    announce: 'การลบบัญชีธนาคารไม่ควรมีสถานะที่ใช้งานอยู่ และถ้าลบไปแล้วจะไม่สามารถกู้คืนกลับมาได้',

    systemLabel: 'จัดการระบบ',
    webBankLabel: 'บัญชีระบบ',

    wrongDeleteBank: 'ลบบัญชีระบบผิดพลาด',
    wrongDeleteBankDescription: 'ไม่สามารถลบบัญชีระบบ',

    type:'ประเภท',
    deleteBank:'ลบบัญชีธนาคาร',
    multibank:'ธนาคารร่วม',
    bankDeposit:'บัญชีฝาก',
    bankWithdraw:'บัญชีถอน',
    daleteBankSuccess:'ลบบัญชีธนาคารสำเร็จ!'
}


class EditWebBank extends Component<IDeleteWebBankActionProps & IWebBankProps & { history: History },
    & ICreateWebBankState> {

    constructor(props: IDeleteWebBankActionProps & IWebBankProps & { history: History }) {
        super(props)
        this.state = {
            bank: '',
            name: '',
            number:'',
            listBankCheck: [],
            isAuto: false,
            bankType: 'DEPOSIT'
        }
    }
    componentDidUpdate(prevProps: IWebBankProps) {
        if (prevProps.deleteWebBankIsFetching !== this.props.deleteWebBankIsFetching
            &&!this.props.deleteWebBankIsFetching) {
            if (this.props.postWebBankCode === responseCode.OK) {
              this.props.loader(false)
              Snackbar.success.show({
                message:constants.daleteBankSuccess
              })
              this.props.history.push('/webbank')
            }
            else {
              Modal.error.show({
                action: Modal.error.hide,
                actionText: constants.wrongDeleteBank,
                description: constants.wrongDeleteBankDescription,
              })
              this.props.loader(false)
            }
        }
    }

    onDeleteWebBank = () => {
        this.props.loader(true)
        this.props.deleteWebBank({
            id: Number(get(this.props.history.location.state, 'webBank.id', 0)),
        })
    }

    handleOnClickBreadcrumb = (path: string) => {
        if (!isEmpty(path)) {
            this.props.history.replace(path)
        }
    }

    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.webBankLabel, path: '/webbank', active: false },
            { label: constants.detail, active: true },
          ]
          let typeBank = get(this.props.history.location.state, 'webBank.type', '')
          let dataBank = get(this.props.history.location.state, 'webBank', '')
          let titleDeleteWebbank = get(bank,`[${typeBank}].name`,'')+' ('+ get(this.props.history.location.state, 'webBank.name', '')+')'
        
          return (
            <div>
                <div className="delete-webbank-container">
                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title={constants.detail}/>
                <h5 className="body-2 mb-4">{titleDeleteWebbank}</h5>
                <Grid container>
                    <Grid item xs={10}>
                        <Paper className="paper-container secondary-dark">
                        <div className="paper-body">
                            <Grid container>
                                <Grid item xs={6}>
                                    <div className="pd-title">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <h5>{constants.detail} </h5>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="flex">
                                                            <Chipper label={dataBank.status==='ACTIVE'?"เปิดใช้งาน":'ยังไม่ได้ใช้งาน'} color={dataBank.status==='ACTIVE'?"green":'yellow'} />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="line" />
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                {/* {rows.map((row) => ( */}
                                                <TableRow key="type">
                                                    <TableCell align="left">
                                                        <div className="primary-text subtitle-1">{constants.type}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className={`body-2 ${get(this.props.history.location.state, 'webBank.operateType', '')==="DEPOSIT"?"primary-green-text":"primary-red-text"}`}>
                                                            {get(this.props.history.location.state, 'webBank.operateType', '')==="DEPOSIT"?constants.bankDeposit:constants.bankWithdraw}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key="bank">
                                                    <TableCell align="left">
                                                        <div className="primary-text subtitle-1">{constants.bank}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="secondary-text body-2">
                                                            {bank[get(this.props.history.location.state, 'webBank.type', 'KBANK')].name}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key="number">
                                                    <TableCell align="left">
                                                        <div className="primary-text subtitle-1">{constants.accountNo}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="secondary-text body-2">
                                                            {number.formatBankNumber(get(this.props.history.location.state, 'webBank.number', ''), get(this.props.history.location.state, 'webBank.type', ''))}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key="name">
                                                    <TableCell align="left">
                                                        <div className="primary-text subtitle-1">{constants.name}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="secondary-text body-2">
                                                            {get(this.props.history.location.state, 'webBank.name', '')}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key="currentMoney">
                                                    <TableCell align="left">
                                                        <div className="primary-text subtitle-1">{constants.income}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="secondary-text body-2">
                                                            {transformer.typeFormat( get(this.props.history.location.state, 'webBank.currentMoney', ''))}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {/* ))} */}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                    <div className="secondary-text body-2  pd-announce">
                                        {constants.announce}
                                        <div className="mg-button">
                                            <Button
                                                text={constants.deleteBank}
                                                theme="outline"
                                                color="gray"
                                                disabled={true}
                                                onClick={this.onDeleteWebBank}
                                                fullWidth={true}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item  xs={6} className="list-bank">
                                    <div className="box-close d-flex align-items-center justify-content-center">
                                        <h6>ปิดปรับปรุง ชั่วคราว</h6>
                                    </div>
                                    <List className={`custom-scrollbar`} subheader={<ListSubheader><h5>{constants.multibank}</h5></ListSubheader>}>
                                    {Object.keys(bank).map((item) => {
                                        const labelId = `checkbox-list-label-${item}`;
                                        let checkIndex = this.state.listBankCheck.indexOf(item)
                                        return (
                                        <ListItem key={item} role={undefined} dense button 
                                            className={`${checkIndex!==-1?"checked":""}`}
                                        >
                                            <ListItemIcon>
                                            <Checkbox
                                                checked={checkIndex !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={(
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '25px', height: '25px', marginRight: '16px' }}>
                                                <img
                                                    src={bank[item].Icon}
                                                    style={{
                                                    width: 'inherit',
                                                    height: 'inherit',
                                                    }}
                                                    alt="thailand-flat"
                                                />
                                                </div>
                                                <div style={{ display: 'grid' }}>
                                                <h6>{bank[item].name}</h6>
                                                </div>
                                            </div>
                                            )}
                                            />
                                        </ListItem>
                                        );
                                    })}
                                    </List>
                                
                                </Grid>
                            </Grid>
                        </div>
                        </Paper>
                    </Grid>
                </Grid>
                </div >
            </div >
        )
    }
}

export default EditWebBank