import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import affilateAction from 'reduxs/affilate/actions'
import loaderAction from 'reduxs/loader/actions'
import AffilateContainer from './Affilate.container'

const mapStateToProps = (state: RootReducers): IAffilateContainerProps => ({
    getAffilateAllIsFetching: state.mantra.affilate.all.isFetching!,
    getAffilateAllError: state.mantra.affilate.all.error!,
    getAffilateAllCode: state.mantra.affilate.all.code!,
    getAffilateAllResult: state.mantra.affilate.all.data!,
    putAffilateCode: state.mantra.affilate.put.code!,
    putAffilateError: state.mantra.affilate.put.error!,
    putAffilateIsFetching: state.mantra.affilate.put.isFetching!,
    putAffilateResult: state.mantra.affilate.put.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IAffilateContainerActionProps => bindActionCreators({
    getAffilateAll: affilateAction.getAffilateAction,
    putAffilate: affilateAction.putAffilateAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(AffilateContainer)