import moment from 'moment'
import { History } from 'history'
import { transformer } from 'utils'
import { noop, isEqual } from 'lodash'
import momentTime from 'moment-timezone'
import './CriminalView.container.scss'
import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { responseCode, responseMessage } from 'constants/response'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb,
    DatePicker,
    Modal,
} from 'components'
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TablePagination,
} from '@material-ui/core'

const constants = {
    criminalLabel: 'ตรวจจับคนโกง',
    systemLabel: 'รายงาน',
    listCriminal: 'รายการผู้ใช้เข้าข่ายสงสัย',
    number: 'เลขที่',
    username: 'ชื่อผู้ใช้',
    riskLevels: 'ระดับความเสี่ยง',
    workingFoud: 'เงินหมุนเวียน',
    time: 'เวลาล่าสุด',
    management: 'จัดการ',
    search: 'ค้นหาชื่อผู้ใช้',
    wrong: 'ไม่สามารถดูประวัติได้',
}


enum ESTATUS {
    MIDDLE = 'MIDDLE',
    HIGH = 'HIGH',
    LOW = 'LOW',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ICriminalViewProps & ICriminalViewActionProps = {
    getCriminalView() { noop() },
    getCriminalViewLog() { noop() },
    clearCriminalViewLog() { noop() },
    getCriminalViewCode: 0,
    getCriminalViewError: '',
    getCriminalViewFetching: false,
    getCriminalViewResult: [],
    getCriminalViewPagination: {
        dataList: [],
        limit: 0,
        page: 0,
        total: 0,
    },
    getCriminalViewLogCode: 0,
    getCriminalViewLogError: '',
    getCriminalViewLogFetching: false,
    getCriminalViewLogResult: [],
    getCriminalViewLogPagination: {
        dataList: [],
        limit: 0,
        page: 0,
        total: 0,
    },
    loader() { noop() },
}
type IColumnId = 'id' | 'username' | 'riskLevels' | 'workingFoud' | 'updatedAt' | 'management'
const columns: ITransactionColumn<IColumnId>[] = [
    { id: 'id', label: constants.number, minWidth: 50, align: 'center' },
    { id: 'username', label: constants.username, minWidth: 100, align: 'center' },
    { id: 'riskLevels', label: constants.riskLevels, minWidth: 50, width: 150, align: 'center' },
    { id: 'workingFoud', label: constants.workingFoud, minWidth: 100, align: 'right' },
    { id: 'updatedAt', label: constants.time, minWidth: 50, align: 'center' },
    { id: 'management', label: constants.management, minWidth: 50, align: 'center' },
];

class
    CriminalViewContainer
    extends Component<IUserManagementProps & IUserManagementActionProps & DefaultProps
    & { history: History }, ICriminalViewContainerState> {

    static defaultProps = defaultProps
    state: ICriminalViewContainerState = {
        hasMore: true,
        isFirst: true,
        start_date: '',
        currentPage: 0,
        end_date: '',
        rowsPerPage: 10,
        limit: 10,
        page: 1,
        name: '',
        total: 0,
        search: '',
        date: '',
        queryTimer: setTimeout(() => { noop() }, 0),
        dateTimeout: setTimeout(() => { noop() }, 0),
        selectedDate: null,
        datalist: {},
    }

    componentDidMount() {
        this.props.loader(true)
        this.props.getCriminalView(this.getQueryParams())
    }
    componentWillUnmount(){
        
    }

    componentDidUpdate(prevProps: ICriminalViewProps) {
        if (prevProps.getCriminalViewFetching !== this.props.getCriminalViewFetching && !this.props.getCriminalViewFetching) {
            this.props.loader(false)
            this.setState({
                page: this.props.getCriminalViewPagination.page,
                total: this.props.getCriminalViewPagination.total,
                limit: this.props.getCriminalViewPagination.limit,
            })
        }
        if (!isEqual(this.props.getCriminalViewResult, prevProps.getCriminalViewResult)) {
            this.props.loader(false)
            if (this.props.getCriminalViewResult.length === 0 && (this.props.getCriminalViewPagination.page > 0)) {
                this.setState({
                    limit: 0,
                    total: this.props.getCriminalViewPagination.total,
                })
                this.handleChangePage(null, this.props.getCriminalViewPagination.page - 1)
            } else {
                this.setState({
                    limit: this.props.getCriminalViewPagination.limit,
                    page: this.props.getCriminalViewPagination.page,
                    total: this.props.getCriminalViewPagination.total,
                })
            }
        }

        if (prevProps.getCriminalViewLogFetching !== this.props.getCriminalViewLogFetching && !this.props.getCriminalViewLogFetching) {
            this.props.loader(false)
            if (this.props.getCriminalViewLogCode === responseCode.OK) {
                if (this.state.isFirst) {
                    this.setState({ isFirst: false })
                    Modal.logCriminal.show({
                        action: () => { this.getCriminalViewLog(this.state.datalist.id) },
                        cancelAction: () => {
                            this.setState({ isFirst: true, hasMore: true })
                            Modal.logCriminal.hide()
                            this.props.clearCriminalViewLog()
                        },
                        logData: this.props.getCriminalViewLogResult,
                        userData: this.state.datalist,
                        hasMore: this.state.hasMore,
                    })
                }
                else {
                    Modal.logCriminalUpdate.show({
                        action: () => { this.getCriminalViewLog(this.state.datalist.id) },
                        cancelAction: () => {
                            this.setState({ isFirst: true, hasMore: true })
                            this.props.clearCriminalViewLog()
                            Modal.logCriminal.hide()
                        },
                        hasMore: this.state.hasMore,
                        userData: this.state.datalist,
                        logData: this.props.getCriminalViewLogResult,
                    })
                }
                if (this.props.getCriminalViewLogPagination.page >= this.props.getCriminalViewLogPagination.total) {
                    Modal.logCriminalUpdate.show({
                        cancelAction: () => {
                            this.setState({ isFirst: true, hasMore: true })
                            this.props.clearCriminalViewLog()
                            Modal.logCriminal.hide()
                        },
                        userData: this.state.datalist,
                        logData: this.props.getCriminalViewLogResult,
                        hasMore: false,
                    })
                    this.setState({ hasMore: false })
                }
            }
            else {
                Modal.error.show({
                    action: Modal.error.hide,
                    description: responseMessage[this.props.getCriminalViewLogCode],
                    actionText: constants.wrong,
                })
                this.props.loader(false)
            }
        }
    }

    getQueryParams = (): IQueryCriminalView => {
        return {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            page: this.state.page,
            limit: this.state.limit,
            name: this.state.name
        }
    }

    handleQuerySearch = (value: string) => {
        this.setState({ search: value }, () => {
            clearTimeout(this.state.queryTimer)
            this.setState({
                queryTimer: setTimeout(() => {
                    this.setState({ name: value })
                    this.props.getCriminalView(this.getQueryParams())
                }, 2000),
            })
        })
    }

    getCriminalViewLog = (txId: number) => {
        const reqData = {
            user_id: txId,
            start_date: '',
            end_date: '',
            limit: 10,
            page: this.state.isFirst ? 1 : this.props.getCriminalViewLogPagination.page + 1,
        }
        this.props.loader(true)
        this.props.getCriminalViewLog(reqData, this.state.isFirst)
    }

    handleOnLogCriminal = (data: IResCriminalView) => {
        this.setState({ datalist: data })
        this.getCriminalViewLog(data.id)
    }

    handleDateChange = (date: Date | null) => {
        const fullYear = `${Number(date?.getFullYear())}/${Number(date?.getMonth()) + 1}`
        this.setState({ selectedDate: fullYear }, () => {
            clearTimeout(this.state.dateTimeout)
            this.setState({
                dateTimeout: setTimeout(() => {
                    if (date != null) {
                        const dateMoment = moment(date);
                        this.setState({
                            currentPage: 0,
                            start_date: dateMoment.set('date', 1).format('YYYY-MM-DD'),
                            end_date: dateMoment.set('date', dateMoment.daysInMonth()).format('YYYY-MM-DD'),
                            page: 0
                        })
                        this.props.getCriminalView(this.getQueryParams())
                    }
                }, 2000),
            })
        })
    };

    createData = (txDataList: IResCriminalView[]): ICriminalViewContainerTableRow[] => {
        return txDataList.map((txData) => {
            return {
                id: String(txData.id),
                username: txData.user,
                riskLevels: this.createActionElement(txData.riskLevel, txData.type),
                workingFoud: `${transformer.typeFormat(String(txData.total))}`,
                updatedAt: momentTime(moment(txData.updatedAt).format('YYYYMMDDHHmmss'), 'YYYYMMDDHHmmss').format('lll'),
                // updatedAt: transformer.transformTime(txData.updatedAt),
                management: <Button text="ประวัติ" onClick={() => this.handleOnLogCriminal(txData)} />,
            }
        })
    }


    createActionElement(riskLevel: string, type: string): JSX.Element {
        const typeText = type === 'DEPOSIT' ? 'ฝาก' : 'ถอน'
        switch (riskLevel) {
            case ESTATUS.LOW:
                return (
                    <div className={`risk-level-item ${riskLevel}`}>{typeText}</div>
                )
            case ESTATUS.MIDDLE:
                return (
                    <>
                        <div className={`risk-level-item ${riskLevel}`}>{typeText}</div>
                    </>
                )
            case ESTATUS.HIGH:
                return (
                    <div className={`risk-level-item ${riskLevel}`}>{typeText}</div>
                )
            default:
                return <></>
        }
    }

    transformTime = (time: string) => {
        return moment(time).add('years', 543).format('Do MMM YYYY - h:mm')
    }

    handleTextAlign = (align: any) => {
        let alignType
        switch (align) {
            case 'center':
                alignType = 'MuiTableCell-alignCenter'
                break
            case 'right':
                alignType = 'MuiTableCell-alignRight'
                break
            case 'left':
                alignType = 'MuiTableCell-alignLeft'
                break
        }
        return alignType
    }

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.setState({
            currentPage: newPage,
            page: newPage + 1,
        }, () => {
            this.props.getCriminalView(this.getQueryParams())
        })
    };

    handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        this.setState({
            currentPage: 0,
            rowsPerPage: Number(event.target.value),
            page: 1,
            limit: Number(event.target.value),
        }, () => {
            this.props.loader(true)
            this.props.getCriminalView(this.getQueryParams())
        })
    };

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
    }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.criminalLabel, active: true },
        ]
        const { total } = this.state
        return (
            <div className="admin-management-container">
                <Grid container spacing={3}>
                    <Grid item xs={12} className="title-webbank">
                        <Grid container>
                            <Grid item xs={12}>
                                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                                <TitlePage title={constants.criminalLabel} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Paper className="secondary-dark" elevation={3}>
                    <TableContainer >
                        <Grid container className="table-title">
                            <Grid item xs={8} >
                                <div >
                                    <h4>
                                        {constants.listCriminal}
                                    </h4>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="Xs4">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>

                                        <div className="th-container">
                                            <DatePicker
                                                label="ปี/เดือน"
                                                value={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="th-container">
                                            <TextField
                                                variant="outlined"
                                                label="ค้นหา"
                                                value={this.state.search}
                                                startElement={<SearchIcon />}
                                                onChange={(e) => this.handleQuerySearch(e.target.value)}
                                                placeholder={constants.search}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, width: column.width }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.createData(this.props.getCriminalViewResult).map((row, index) => {

                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={`${index} ${row.workingFoud}`}>
                                                {columns.map((column) => {
                                                    const value = row[column.id]
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            className={this.handleTextAlign(column.align)}
                                                        >
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value) : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    this.props.getCriminalViewResult.length === 0 && (
                                        <TableRow hover role="checkbox">
                                            <TableCell key={'no data'} >
                                                ไม่มีข้อมูล...
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        className="primary-text"
                        component="div"
                        count={total}
                        page={this.state.currentPage}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="รายการ/หน้า:"
                        labelDisplayedRows={() => `${this.state.currentPage +1}-${this.props.getCriminalViewPagination.pageTotal} หน้า`}
                    />
                </Paper>
            </div>
        )
    }
}

export default CriminalViewContainer