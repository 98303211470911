import React, { FC, useState, Fragment } from 'react'
import { date, transformer, number } from 'utils'
import { Badge } from 'components'
import { split, groupBy, Dictionary, isEmpty, map, keys, get, capitalize, upperCase, noop } from 'lodash'
import { LOTTO_GAME_TYPE_NAME, TRANSACTION_TYPE, LOTTO_SLUG_NAME, GAME_TYPE_NAME } from 'constants/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './transactionItemCollapsible.style.scss'
import colors from 'constants/colors'
import {
  Grid,
} from '@material-ui/core'
import BronzeIcon from 'assets/images/pmpg/bronze.svg'
import SilverIcon from 'assets/images/pmpg/diamond.svg'
import GoldIcon from 'assets/images/pmpg/chest.svg'
import TinIcon from 'assets/images/pmpg/tin.svg'

const constants = {
  round: 'รอบที่',
  makeLotto: 'แทง',
  waitResult: 'รอผลออก',
  actureResult: 'เลขที่ออก',
  game: 'เกม',
  fee: 'ค่าธรรมเนียม 2%',
  moneyRate: 'อัตราจ่าย',
}

const statusName: { [status in TBetStatus | TFinanceStatus]: { name: string; color: string; amountColor: string } } = {
  WAIT: { name: 'รอตรวจสอบ', color: colors.PRIMARY_YELLOW, amountColor: colors.PRIMARY_YELLOW },
  SUCCESS: { name: 'สำเร็จ', color: colors.SECONDARY_GREEN, amountColor: colors.SECONDARY_GREEN },
  FAIL: { name: 'ไม่สำเร็จ', color: colors.PRIMARY_RED, amountColor: colors.SECONDARY_TEXT },
  WINNER: { name: 'ได้', color: colors.SECONDARY_GREEN, amountColor: colors.SECONDARY_GREEN },
  LOSER: { name: 'เสีย', color: colors.PRIMARY_RED, amountColor: colors.PRIMARY_RED },
  CANCEL: { name: 'ยกเลิก', color: colors.SECONDARY_TEXT, amountColor: colors.SECONDARY_TEXT },
  DRAW: { name: 'เสมอ', color: colors.SECONDARY_TEXT, amountColor: colors.SECONDARY_TEXT },
  PMPG_BRONZE_AWARD: { name: 'โบนัส', color: colors.PRIMARY_YELLOW, amountColor: colors.PRIMARY_GREEN },
  PMPG_GOLD_AWARD: { name: 'โบนัส', color: colors.PRIMARY_YELLOW, amountColor: colors.PRIMARY_GREEN },
  PMPG_SILVER_AWARD: { name: 'โบนัส', color: colors.PRIMARY_YELLOW, amountColor: colors.PRIMARY_GREEN },
  PMPG_TIN_AWARD: { name: 'โบนัส', color: colors.PRIMARY_YELLOW, amountColor: colors.PRIMARY_GREEN },
  W_SUCCESS: { name: 'สำเร็จ', color: colors.SECONDARY_GREEN, amountColor: colors.PRIMARY_RED },
  WF_SUCCESS: { name: 'สำเร็จ', color: colors.SECONDARY_GREEN, amountColor: colors.PRIMARY_RED },
  RESERVE: { name: 'กำลังตรวจสอบ', color: colors.SECONDARY_TEXT, amountColor: colors.PRIMARY_TEXT },
}

declare interface ITransactionItemCollapsible {
  credit: ICredit,
  getCreditMedetail?(createdAt: string, slug: string | null, status: string): void,
  creditDetail?: (keyDetail: string) => ICreditDetail[]
  dataStore?: { [key in string]: ICreditDetail[] }
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItemCollapsible = {
  credit: {
    money: 0,
    status: '',
    createdAt: '',
    groupType: '',
    slug: '',
    list: [],
    isFee: false,
  },
  creditDetail: () => [],
  getCreditMedetail(createdAt: 'SUCCESS' | 'FAIL', slug: string | null) { noop() },
  dataStore: {}
}

const getDisplayTransactionName = (groupType: string) => {
  const lendingType = get(groupType.split('_', 1), '0', '')
  const trailingType = groupType.replace(`${lendingType}_`, '')
  const subLendingType = get(trailingType.split('_', 1), '0', '')

  if (lendingType === 'FINANCE') {
    return TRANSACTION_TYPE[trailingType as TTransactionType]
  } else if (lendingType === 'BET') {
    if (subLendingType === 'GAME') {
      const trailingBetType = trailingType.replace(`${subLendingType}_`, '')
      const gameNameUnderscore = trailingBetType.replace(/CASINO_JOKER_|CASINO_THBGAME_/, '')
      let gameName: string
      if (gameNameUnderscore.includes('W88')) {
        gameName = capitalize(upperCase(gameNameUnderscore).replace(/(?![W])\s(?=[88])/, '')).replace('w88', 'W88')
      } else {
        gameName = capitalize(upperCase(gameNameUnderscore))
      }

      return `เดิมพันเกม ${gameName}`
    }
    // LOTTO type
    return LOTTO_SLUG_NAME[trailingType as TLottoSlug]
  }
}


const TransactionItemCollapsible: FC<ITransactionItemCollapsible & DefaultProps> = (props) => {

  const [isExpand, setExpand] = useState<boolean>(false)
  const { credit } = props
  let status = credit.groupType.startsWith("BET_GAME_CASINO") ? '' : credit.status
  let generateStatus = credit.status
  const creditMeDataStore = get(props.dataStore, `${credit.slug + '_' + credit.createdAt + '_' + status + '_' + generateStatus}`, [])

  const handleOnExpandClick = () => {
    if (name !== 'FINANCE' && get(credit,'isDetail',false)) {
      setExpand(!isExpand)
      if (!isExpand) {
        props.getCreditMedetail!(
          credit.createdAt,
          credit.slug!,
          credit.status,
        )
      }
    }
  }

  const getGroupType = () => {
    const [nameSplited, typeSplited, subTypeSplited, endPoinTypeSplited] = split(credit.groupType, '_')
    return {
      name: nameSplited,
      type: typeSplited,
      subType: subTypeSplited || '',
      endPointType: endPoinTypeSplited || '',
    }
  }

  const getSlug = () => {
    const [slugName, slugType, slugRound] = split(credit.slug || '', '_')
    return {
      name: slugName,
      type: slugType,
      round: slugRound,
    }
  }

  const getStatus = () => {
    if (name === 'FINANCE') {
      if (type === 'DEPOSIT' && endPointType === 'FISHING' && credit.status === 'SUCCESS') {
        return statusName['WF_SUCCESS' as TBetStatus]
      }
      // withdraw
      else if (type === 'WITHDRAW' && endPointType !== 'FISHING' && credit.status === 'SUCCESS') {
        return statusName['W_SUCCESS' as TBetStatus]
      }
      // game
      else if (type === 'WITHDRAW' && endPointType === 'FISHING') {
        return statusName[credit.status as TFinanceStatus]
      }
      return statusName[credit.status as TFinanceStatus]
    } else if (name === 'BET') {
      if (credit.status === 'WAIT') {
        return statusName[credit.status as TFinanceStatus]
      }
      return statusName[credit.status as TBetStatus]
    }
    return { name: '', color: 'transparent' }
  }

  const { name, subType, type, endPointType } = getGroupType()
  const displayName = getDisplayTransactionName(credit.groupType)
  const isFee = credit.isFee
  const displayTime = date.FormatDate(credit.createdAt, 'Do MMM YYYY เวลา k:mm')
  // const round = (subType === 'YEGEE')
  //   ? ` (${constants.round} ${Number(getSlug().round)})`
  //   : ''
    const numRound = getSlug().round
    const round =
    subType === 'YEGEE'
      ? ` (${constants.round}  ${Number(numRound.substring(
        numRound.length - 3,
        numRound.length
      ))})`
      : ''


  const CreditInfoListComponent = () => {
    if (name === 'FINANCE') {
      return map(credit.list, (cred, creditIndex) => {
        const stColor = get(statusName, `${cred.status}.color`, '')
        const stName = get(statusName, `${cred.status}.name`, '')
        return (
          <div
            className="d-flex flex-row align-items-center transaction-description-row"
            key={`transaction-description-amount-${creditIndex}`}
          >
            <div className="transaction-description-name-text">
              {TRANSACTION_TYPE[cred.type as TTransactionType]}
              {' '}
              <span className="transaction-description-lotto-number">{cred.numbers}</span>
              {' '}
              (<span style={{ color: stColor }}>{stName}</span>)
            </div>
            <div className="transaction-description-amount">{transformer.typeFormat(String(cred.money))}</div>
          </div>
        )
      })
    } else {
      // BET
      const creditGroupList: Dictionary<ICreditDetail[]> = groupBy<ICreditDetail>(creditMeDataStore, 'type')
      if (isEmpty(creditGroupList)) { return <></> }
      return map(keys(creditGroupList), (key, keyIndex) => {
        const creditDetails = creditGroupList[key]
        const DetailComponents = map(creditDetails, (detail, detailIndex) => {
          const stName = get(statusName, `${detail.status}.name`, '')
          const stColor = get(statusName, `${detail.status}.color`, '')
          const subCreditStatus = (detail.status === 'WAIT') ? constants.waitResult : stName
          const gameNameUnderscore = (detail.slug || '').replace(/GAME_CASINO_JOKER_|GAME_CASINO_THBGAME_|GAME_CASINO_AESEXY_|GAME_CASINO_DT_|GAME_CASINO_PGGAME_|GAME_CASINO_WMGAME_|GAME_CASINO_PPGAME|GAME_CASINO_JILIGAME_|GAME_CASINO_EVOLUTIONGAME|GAME_CASINO_ALLBET_/, '')
          const removeRound = gameNameUnderscore.substring(0, gameNameUnderscore.lastIndexOf('_'))
          const gameName = capitalize(upperCase(removeRound)).replace('w 88', 'W88')
          const transactionName = (detail.numbers === '-') ?
            `${GAME_TYPE_NAME[detail.type as TGameType]} ${constants.game} ${gameName}`
            : detail.numbers
          return (
            <div
              className="d-flex flex-row align-items-center transaction-description-row"
              key={`transaction-description-detail-${detailIndex}`}
            >
              <div className="transaction-description-name-text">
                <h6 className="transaction-description-lotto-number p1-r">{transactionName}</h6>
                <span style={{ color: stColor }}>{subCreditStatus}</span>
                {' '}
                <br />
                <span className="secondary-text">
                  {(type === 'LOTTER' && detail.status === 'LOSER')
                    ? `${constants.actureResult} ${detail.numbersBetResult}`
                    : type === 'GAME' ? <></> :
                      (
                        <span style={{ color: stColor }} className="transaction-description-amount subtitle-1">
                          {`${constants.moneyRate} ${number.castToMoney(Number(detail.rate))}`}
                        </span>
                      )
                  }
                </span>
              </div>
              <div className="transaction-description-amount subtitle-1">
                {`${number.castToMoney(Number(detail.money))}`}</div>
            </div>
          )
        })

        return (
          <Fragment key={`transaction-description-amount-${keyIndex}`}>
            <div className="row mt-3">
              <div className="col">
                {LOTTO_GAME_TYPE_NAME[key as TLottoGameType]}
              </div>
            </div>
            {DetailComponents}
          </ Fragment>
        )
      })
    }
  }
  const trxColor = get(getStatus(), 'color', '')
  const trxName = get(getStatus(), 'name', '')
  const amountColor = get(getStatus(), 'amountColor', '')
  const strFee = endPointType.includes('THBGAME') && isFee ? constants.fee : ''
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="py-3 credit-info-item-container" onClick={handleOnExpandClick}>
          <div className="col d-flex credit-info-item-wrapper">
            <div className="d-flex flex-column transaction-leading-wrapper">
              <div className="transaction-name-text d-flex align-items-center">
                {displayName}
                {credit.status.includes('GOLD')
                  && (<img src={GoldIcon} className="pl-2 pr-1 bonus-badge" alt="BONUS" />)}
                {credit.status.includes('SILVER')
                  && (<img src={SilverIcon} className="pl-2 pr-1 bonus-badge" alt="BONUS" />)}
                {credit.status.includes('BRONZE')
                  && (<img src={BronzeIcon} className="pl-2 pr-1 bonus-badge" alt="BONUS" />)}
                {credit.status.includes('TIN')
                  && (<img src={TinIcon} className="pl-2 pr-1 bonus-badge" alt="BONUS" />)}
                <span className="transaction-badge-wrapper">
                  <Badge text={trxName} color="white" backgroundColor={trxColor} />
                </span>
              </div>
              <div className="transaction-time-text secondary-text py-2">{displayTime} {round}</div>
            </div>
            <div className="d-flex flex-column">
              <div className="transaction-amount-text" style={{ color: amountColor }}>
                {transformer.typeFormat(String(credit.money))}
              </div>
              <div className="transaction-fee-text pb-2 py-2" style={{ color: colors.SECONDARY_TEXT }}>
                {strFee}
              </div>
            </div>
            <div className="d-flex transaction-chevron-right-icon">
              {name !== 'FINANCE' && (<FontAwesomeIcon icon={faChevronRight} className={`chevron-right-icon ${isExpand ? 'expanded' : ''}`} />)}
            </div>
          </div>
          <div className={`transaction-description-container primary-dark custom-scrollbar ${isExpand ? 'expanded' : ''}`}>
            <div className="d-flex flex-column transaction-description-wrapper">
              {CreditInfoListComponent()}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

TransactionItemCollapsible.defaultProps = defaultProps

export default TransactionItemCollapsible