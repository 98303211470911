import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import userAllAction from 'reduxs/user/all/actions'
import userEditAction from 'reduxs/user/edit/actions'
import loaderAction from 'reduxs/loader/actions'
import EditAdmin from './EditAdmin'

const mapStateToProps = (state: RootReducers): IUserPutManagementProps => {
  return {
    putUserIsFetching: state.mantra.user.userEdit.isFetching!,
    putUserError: state.mantra.user.userEdit.error!,
    putUserCode: state.mantra.user.userEdit.code!,
    putUserResult: state.mantra.user.userEdit.data!,

    getUserAllIsFetching: state.mantra.user.userAll.isFetching!,
    getUserAllError: state.mantra.user.userAll.error!,
    getUserAllCode: state.mantra.user.userAll.code!,
    getUserAllResult: state.mantra.user.userAll.data!,
  }
}
const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IPutManagementActionProps => bindActionCreators({
  getUserAll: userAllAction.getUserAllAction,
  putUser: userEditAction.putUserAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(EditAdmin)