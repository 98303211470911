import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const dateReducer = (state: IDateTimeStart = initialState, action: RootAction): IDateTimeStart => {
  switch (action.type) {
    case getType(actions.persistedDateAction):
      console.log('persistedDateAction', action.payload);

      return {
        ...state,
        datetime: action.payload.datetime
      }
    default:
      return state
  }
}

export default dateReducer