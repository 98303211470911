import React, { Component } from 'react'
import { noop } from 'lodash'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb
} from 'components'
import moment from 'moment'
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TablePagination,
} from '@material-ui/core'
import './AdminManagement.container.scss'
import SearchIcon from '@material-ui/icons/Search'
import { History } from 'history'
import { date } from 'utils'


const constants = {
    adminLabel: 'จัดการหัวหน้าพนักงาน',
    systemLabel: 'จัดการระบบ',
  }

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IUserManagementProps & IUserManagementActionProps = {
    getUserAll() { noop() },
    getUserAllCode: 0,
    getUserAllError: '',
    getUserAllIsFetching: false,
    getUserAllResult: [],
    getUserAllLimit: 10,
    getUserAllPage: 0,
    getUserAllTotal: 0,
    loader() { noop() },
}
type IColumnId = 'id' | 'username' | 'phoneNumber' | 'userBank' | 'createdAt' | 'action' 
const columns: ITransactionColumn<IColumnId>[] = [
    { id: 'id', label: 'เลขที่', minWidth: 50, align: 'center' },
    { id: 'username', label: 'ชื่อหัวหน้าพนักงาน', minWidth: 50, align: 'left' },
    { id: 'phoneNumber', label: 'เบอร์โทร', minWidth: 50, align: 'left' },
    { id: 'userBank', label: 'บัญชี', minWidth: 50, align: 'left' },
    { id: 'createdAt', label: 'วันที่ลงทะเบียน', minWidth: 50, align: 'left' },
    { id: 'action', label: 'กิจกรรม', minWidth: 50, align: 'center' },
    // { id: 'delete', label: 'ลบ', minWidth: 50, align: 'center' },
];

class
    AdminManagementContainer
    extends Component<IUserManagementProps & IUserManagementActionProps & DefaultProps
    & { history: History }, IUserManagementContainerState> {

    static defaultProps = defaultProps
    state: IUserManagementContainerState = {
        limit: 10,
        page: 0,
        total: 0,
        search: '',
        permission: 'ADMIN',
        queryTimer: setTimeout(() => { noop() }, 0),
    }

    componentDidMount() {
        this.props.loader(true)
        this.props.getUserAll(this.getQueryParams())
    }

    componentDidUpdate(prevProps: IUserManagementProps) {
        if (prevProps.getUserAllIsFetching !== this.props.getUserAllIsFetching && !this.props.getUserAllIsFetching) {
            this.props.loader(false)
        }
    }


    handleRouteCreateUser = () => {
        this.props.history.push('/admin-management-create',{permission:this.state.permission})
    }

    handleRouteEditUser = (data: number, phoneNumber: string ) => {
        this.props.history.push('/admin-management-edit', { deleteUser: data , phoneNumber: phoneNumber })
      }

    handleRouteDeleteUser = (data: number, username: string  ) => {
        this.props.history.push('/admin-management-delete', { deleteUser: data, username:username})
    }

    getQueryParams = (): IGetUserParams => {
        return {
            permission: this.state.permission,
            search: this.state.search,
            page: this.state.page,
            limit: this.state.limit,
        }
    }

    handleQuerySearch = (value: string) => {
        this.setState({ search: value }, () => {
            clearTimeout(this.state.queryTimer)
            this.setState({
                queryTimer: setTimeout(() => {
                    this.props.getUserAll(this.getQueryParams())
                }, 2000),
            })
        })
    }

    createData = (txDataList: IUserPopulate[]): IUserManagementContainerTableRow[] => {
        return txDataList.map((txData) => {
            return {
                id: String(txData.id),
                username: txData.username,
                phoneNumber: txData.phoneNumber,
                userBank: (
                    <>
                        <div className="secondary-text">
                            {txData.userBank.type}
                        </div>
                        <div className="secondary-text">
                            {txData.userBank.name}
                        </div>
                        <div className="secondary-text">
                            {txData.userBank.number}
                        </div>
                    </>
                ),
                createdAt: date.FormatDate(txData.createdAt, 'Do MMM YY | k:mm น.'),
                action: <Button text="แก้ไข" onClick={() => this.handleRouteEditUser(txData.id,txData.phoneNumber)} />,
                // delete: <Button text="ลบ" onClick={() => this.handleRouteDeleteUser(txData.id, txData.username)} />,
            }
        })
    }

    transformTime = (time: string) => {
        return moment(time).add('years', 543).format('Do MMM YYYY - h:mm')
    }

    handleTextAlign = (align: any) => {
        let alignType
        switch (align) {
            case 'center':
                alignType = 'MuiTableCell-alignCenter'
                break
            case 'right':
                alignType = 'MuiTableCell-alignRight'
                break
            case 'left':
                alignType = 'MuiTableCell-alignLeft'
                break
        }
        return alignType
    }

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.setState({
            page: newPage,
        }, () => {
            this.props.getUserAll(this.getQueryParams())
        })
    };

    handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        this.setState({
            page: 0,
            limit: Number(event.target.value),
        }, () => {
            this.props.getUserAll(this.getQueryParams())
        })
    };

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
      }
    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.adminLabel, active: true },
          ]
        return (
            <div className="admin-management-container">
                <Grid container spacing={3}>
                    <Grid item xs={12} className="title-webbank">
                        <Grid container>
                            <Grid item xs={8}>
                            <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                                <TitlePage title="จัดการหัวหน้าพนักงาน" />
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        {}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <></>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div>
                                            <Button
                                                onClick={this.handleRouteCreateUser}
                                                text="เพิ่มหัวหน้าพนักงาน"
                                                fullWidth={true}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Paper className="secondary-dark" elevation={3}>
                    <TableContainer >
                        <Grid container className="table-title">
                            <Grid item xs={7} >
                                <div >
                                    <h5>
                                        รายการหัวหน้างาน
                                    </h5>
                                </div>
                            </Grid>

                            <Grid item xs={5} className="Xs4">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <></>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="th-container">
                                            <TextField
                                                label="ค้นหา"
                                                value={this.state.search}
                                                startElement={<SearchIcon />}
                                                onChange={(e) => this.handleQuerySearch(e.target.value)}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.createData(this.props.getUserAllResult).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            className={this.handleTextAlign(column.align)}
                                                        >
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value) : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    this.props.getUserAllResult.length === 0 && (
                                        <TableRow hover role="checkbox">
                                            <TableCell key={'no data'} >
                                                ไม่มีข้อมูล...
                                        </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="primary-text"
                        component="div"
                        count={this.props.getUserAllTotal}
                        page={this.props.getUserAllPage}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.props.getUserAllLimit}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="รายการ/หน้า:"
                        labelDisplayedRows={(
                            { from, to, count }) => `${from}-${to} ของ ${count !== -1 ? count : `more than ${to}`}`}
                    />
                </Paper>
            </div>
        )
    }
}

export default AdminManagementContainer