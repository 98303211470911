export const responseMessage: any = {
  0: 'กรุณาลองใหม่อีกครั้ง',
  200: 'สำเร็จ',
  400: 'คำร้องขอไม่สมบูรณ์',
  401: 'ปฏิเสธสิทธิการเข้าใช้',
  403: 'การเข้าถึง ถึงขีดจำกัด',
  404: 'ไม่พบข้อมูล',
  408: 'หมดเวลา กรุณาลองใหม่อีกครั้ง',
  500: 'เกิดข้อผิดพลาดบน Server',
  503: 'ไม่สามารถติดต่อกับผู้ให้บริการได้',
  200000: 'สำเร็จ',
  400000: 'คำร้องขอไม่สมบูรณ์',
  400001: 'ข้อมูลไม่สมบูรณ์',
  400100: 'ไม่สามารถสร้างผู้ใช้ได้เนื่องจากมีผู้ใช้อยู่ในระบบแล้ว',
  400101: 'จำนวนคำขอถึงขีดจำกัดแล้วโปรดลองใหม่อีกครั้ง',
  400102: 'เงินในกระเป๋าไม่พอ',
  400103: 'ไม่สามารถใช้หมายเลขโทรศัพท์ที่ซ้ำกันได้',
  400104: 'หมดเวลายืนยัน OTP โปรดขอ OTP ใหม่อีกครั้ง',
  400105: 'ไม่สามารถอัปเดตเวลาเปิดรับแทงหวยได้เนื่องจากเวลาเริ่มต้นเปิดรับแทงไม่เป็นอนาคต',
  400106: 'ไม่สามารถใช้หมายเลขบัญชีระบบซ้ำ',
  400107: 'ไม่สามารถอัปเดตบัญชีระบบที่ใช้งานอยู่ได้',
  400108: 'ต้องมีธุรกรรมการฝากเพียงหนึ่งรายการต่อหนึ่งผู้ใช้เท่านั้น',
  400109: 'ถอนได้ 100,000 ต่อธุรกรรมเท่านั้น',
  400110: 'ไม่สามารถจองธุรกรรมที่ถูกจองไว้แล้วได้',
  400111: 'ไม่สามารถยกเลิกการจองธุรกรรมได้',
  400112: 'เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด',
  400113: 'ผลหวยซ้ำ',
  400114: 'รหัสผ่านไม่ตรงกับยืนยันรหัสผ่าน',
  400115: 'ไม่สามารถแก้ไขข้อมูลได้เมื่อสถานะเป็นอัตโนมัติ',
  400116: 'เลขบัญชีธนาคารซ้ำ',
  400117: 'ไม่สามารถอัปเดตระดับอัตราจ่ายนี้ได้',
  400118: 'ไม่สามารถฝาก 0 หน่วยและน้อยกว่า 0 หน่วยได้',
  400124: 'ไม่มีบัญชีพร้อมให้บริการ โปรดติดต่อแอดมิน',
  400128: 'อัตราจ่ายต้องไม่เกินอัตราจ่ายสูงสุด',
  400225: 'ไม่พบรายการที่สามารถคืนเงินได้',
  401000: 'ไม่ผ่านการอนุมัติ',
  401001: 'ปฏิเสธสิทธิการเข้าใช้',
  401110: 'รหัสผิด',
  401111: 'OTP ไม่ถูกต้อง',
  401112: 'หมายเลขเบอร์โทรศัพท์ผิดรูปแบบต้องมี 10 หลักและเริ่มต้นด้วย 0',
  401113: 'uuid ผิดรูปแบบ',
  401114: 'token ไม่สามารถใช้งานได้',
  403000: 'การเข้าถึง ถึงขีดจำกัด',
  404000: 'ไม่พบข้อมูล',
  404001: 'ไม่พบบัญชีผู้ใช้',
  404002: 'ไม่พบการขอทำธุรกรรมกระเป๋าเงิน',
  404003: 'ไม่พบรายการธุรกรรมการเงิน',
  404004: 'ไม่พบบัญชีลูกค้า',
  404005: 'ไม่พบบัญชีระบบ',
  404006: 'ไม่พบการเปิดทำธุรกรรมการเงิน',
  404007: 'ไม่พบการร้องขอยืนยัน OTP',
  404008: 'ไม่พบอัตราจ่าย โปรดลองใหม่อีกครั้ง',
  404009: 'ไม่พบข้อมูลการยิงเลขยี่กี',
  404010: 'ไม่พบกระเป๋าเงิน',
  404011: 'ไม่พบประเภทอัตราจ่าย',
  404012: 'ไม่พบชุดโพย',
  404013: 'ไม่พบโพย',
  404014: 'ไม่พบสิทธิการเปลี่ยนรหัส',
  404015: 'ไม่พบผู้แนะนำสมัครสมาชิก',
  500000: 'เกิดข้อผิดพลาดบน Server',
  500001: 'เกิดข้อผิดพลาดการอัปเดต transaction',
  500002: 'เกิดข้อผิดพลาดการอัปเดต wallet transaction',
  500003: 'เกิดข้อผิดพลาดการอัปเดต wallet',
  500004: 'เกิดข้อผิดพลาดการอัปเดตการตั้งค่า affilate',
  500005: 'เกิดข้อผิดพลาดการอัปเดตชุดโพย',
  500006: 'เกิดข้อผิดพลาดการอัปเดตโพย',
  500007: 'เกิดข้อผิดพลาดการอัปเดทบัญชีระบบ',
  500008: 'เกิดข้อผิดพลาดในการสลับบัญชีระบบที่ใช้งานปัจจุบันเป็นบัญชีใหม่',
  500009: 'เกิดข้อผิดพลาดในการอัปเดตบัญชีระบบที่ใช้งานอยู่',
  500010: 'เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินผู้ใช้',
  500011: 'เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินระบบ',
  500012: 'เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินระบบแนะนำสมาชิก',
  500013: 'เกิดข้อผิดพลาดในการอัปเดตรหัสผ่าน',
  500100: 'เกิดข้อผิดพลาดการสร้าง Pre Wallet',
  500101: 'เกิดข้อผิดพลาดการสร้างรายการเดิมพัน',
  500102: 'เกิดข้อผิดพลาดการยิงเลขยี่กี',
  500103: 'เกิดข้อผิดพลาดการสร้างชุดโพย',
  500104: 'เกิดข้อผิดพลาดการสร้างโพย',
  500105: 'เกิดข้อผิดพลาดการสร้างบัญชีระบบ',
  500106: 'เกิดข้อผิดพลาดการสร้างรายการธุรกรรม',
  500107: 'เกิดข้อผิดพลาดการสร้างสิทธิในการแก้ไขรหัสผ่าน',
  500200: 'เกิดข้อผิดพลาดของข้อมูลภายในไม่สามารถขอร้องขอได้',
  500300: 'เกิดข้อผิดพลาดในการลบโพย',
  500301: 'เกิดข้อผิดพลาดในการลบบัญชีระบบ',
  500302: 'เกิดข้อผิดพลาดในการลบสิทธิเปลี่ยนรหัส',
  500303: 'เกิดข้อผิดพลาดในการลบผู้ใช้',
  500900: 'ไม่มีบัญชีระบบที่ใช้งานอยู่ โปรดติดต่อผู้ดูแลระบบ',
  503000: 'ไม่สามารถติดต่อกับผู้ให้บริการได้',
  503001: 'ไม่สามารถติดต่อกับผู้ให้บริการ OTP ได้',
}
export const responseCode = {
  OK: 200,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  ACCEPTED: 202,
}