import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetTransactionAll = (data: IGetTransactionParams) => {
    const queryParams = new URLSearchParams({
        type: get(data, 'type', ''),
        status: get(data, 'status', ''),
        page: String(get(data, 'page', 0) + 1),
        limit: String(get(data, 'limit', '10')),
        search: get(data, 'search', ''),
        date: get(data, 'date', ''),
    })
    return axios.get(endpoint.getTransactionAll, {
        params: queryParams,
    })
}