import React, { FC } from 'react'
import { isEmpty, noop } from 'lodash'
import './ButtonCountDown.component.scss'
import { Button } from '@material-ui/core'
import Countdown, { CountdownRenderProps } from "react-countdown"


type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IButtonCountDown = {
  id: '',
  text: '',
  onClick() { noop() },
  type: 'button',
  size: 'medium',
  disabled: false,
  theme: 'default',
  fullWidth: false,
  allowColor: false,
  fontColor: '',
  color: undefined,
  startIcon: undefined,
  iconStyles: undefined,
  time: 0,
  onCountdownComplete() { noop() },
}

const ButtonComponent: FC<IButtonCountDown & DefaultProps> = (props) => {

  const {
    id,
    text,
    onClick,
    type,
    size,
    disabled,
    theme,
    fullWidth,
    fontColor,
    color,
    allowColor,
    startIcon,
    iconStyles,
    time,
    onCountdownComplete
  } = props

  
  let containerClass = `button-container`
  const StatusImage = startIcon;
  containerClass = `${containerClass} ${type}`
  containerClass = `${containerClass} ${size}`
  containerClass = `${containerClass} ${disabled && !allowColor ? 'disabled' : ''}`
  containerClass = `${containerClass} ${theme}`
  containerClass = `${containerClass} ${fullWidth ? 'full-width' : ''}`
  containerClass = `${containerClass} ${color ? color : ''}`
  containerClass = `${containerClass} ${disabled ? 'no-drop': ''}`
  const textColor =  !isEmpty(fontColor) ?  fontColor : ''
  const remaining = (props: CountdownRenderProps) => {
    return (
     disabled && (<h6 style={{color: textColor}}>{`${props.formatted.minutes}:${props.formatted.seconds}`}</h6>)
    )
  }

  return (
    <div className={containerClass} id={"div-" + id}>
      <Button
        id={"button-" + id}
        type={type}
        className={containerClass}
        onClick={disabled ? noop : onClick}
        disabled={disabled}
      >
        {
          !isEmpty(StatusImage) && (
            <StatusImage style={isEmpty(iconStyles) ? { position: "absolute", bottom: 10, left: 9, color: textColor } : { iconStyles }} />
          )
        }
        <h6 className="link" style={{ padding: 10, color: textColor}}>{text}</h6>
        {
          time! > 0 &&(
            <Countdown
            onComplete={onCountdownComplete}
            intervalDelay={0}
            precision={3}
              date={time}
              renderer={(props: CountdownRenderProps) => (
                remaining(props)
              )}
               />
          )
        }
      
      </Button>
    </div>
  )
}

ButtonComponent.defaultProps = defaultProps

export default ButtonComponent