import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { noop, find } from 'lodash'
import {
  Paper,
  Grid,
} from '@material-ui/core'
import { transformer } from 'utils'
import { ZERO } from 'constants/regex'
import './Settings.style.scss'
import {
  Button,
  TitlePage,
  TextField,
  Select,
  Breadcrumb,
} from 'components'


const constants = {
  settingLabel: 'ตั้งค่าระบบ',
  yegeeLabel: 'ยี่กี',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ISettingsProps & ISettingsActionProps & IBetRatesProps = {
  loader() { noop() },
  getBetRatesAll() { noop() },
  putBetRates() { noop() },
  getYegeeGameConfigAll() { noop() },
  putYegeeGameConfig() { noop() },
  getBetRate() { noop() },

  getBetRatesAllIsFetching: false,
  getBetRatesAllError: '',
  getBetRatesAllCode: 0,
  getBetRatesAllResult: [],

  putBetRatesIsFetching: false,
  putBetRatesError: '',
  putBetRatesCode: 0,
  putBetRatesResult: [],

  getYegeeGameConfigAllIsFetching: false,
  getYegeeGameConfigAllError: '',
  getYegeeGameConfigAllCode: 0,
  getYegeeGameConfigAllResult: {
    id: 0,
    round: '0',
    betweenMinutes: '0',
    startAt: '',
    createdAt: '',
    updatedAt: '',
    expectRate: '0',
  },

  putYegeeGameConfigIsFetching: false,
  putYegeeGameConfigError: '',
  putYegeeGameConfigCode: 0,
  putYegeeGameConfigResult: {
    id: 0,
    round: '0',
    betweenMinutes: '0',
    startAt: '',
    createdAt: '',
    updatedAt: '',
    expectRate: '0',
  },

  getBetRatesIsFetching: false,
  getBetRatesError: '',
  getBetRatesCode: 0,
  getBetRatesResult: [],
}

enum EBETRATES_TYPE {
  LOTTER_YEGEE_RUN_DOWN = 'LOTTER_YEGEE_RUN_DOWN',
  LOTTER_YEGEE_THREE_UP = 'LOTTER_YEGEE_THREE_UP',
  LOTTER_YEGEE_THREE_TOAST = 'LOTTER_YEGEE_THREE_TOAST',
  LOTTER_YEGEE_TWO_DOWN = 'LOTTER_YEGEE_TWO_DOWN',
  LOTTER_YEGEE_TWO_UP = 'LOTTER_YEGEE_TWO_UP',
  LOTTER_YEGEE_RUN_UP = 'LOTTER_YEGEE_RUN_UP',
}

class Settings extends Component<ISettingsProps & RouteComponentProps & ISettingsActionProps
  & IBetRatesProps & DefaultProps, ISettingsState> {

  static defaultProps = defaultProps

  constructor(props: ISettingsProps & RouteComponentProps & ISettingsActionProps & IBetRatesProps & DefaultProps) {
    super(props)
    this.state = {
      idLotterYegeeRunDownRates: 0,
      idLotterYegeeThreeUpRates: 0,
      idLotterYegeeThreeToastRates: 0,
      idLotterYegeeTwoDownRates: 0,
      idLotterYegeeTwoUpRates: 0,
      idLotterYegeeRunUpRates: 0,

      lotterYegeeRunDownRates: 0,
      lotterYegeeThreeUpRates: 0,
      lotterYegeeThreeToastRates: 0,
      lotterYegeeTwoDownRates: 0,
      lotterYegeeTwoUpRates: 0,
      lotterYegeeRunUpRates: 0,
      hourList: [],
      minutesList: [],
      yegeeStartHour: '00',
      yegeeStartMinutes: '00',
      yegeeRound: '0',
      yegeeRoundTime: '0',
      expectRate: '0',
    }
  }

  componentDidMount() {
    this.props.getBetRate({ type: 'LOTTER_YEGEE' })
    this.props.loader(true)
    this.props.getBetRatesAll()
    this.props.getYegeeGameConfigAll()

    const buildHourList = []
    const buildMinutesList = []
    for (let i = 1; i <= 24; i++) {
      const time = transformer.time1DigitTo2Digit(String(i))
      buildHourList.push({ value: time, element: <h6>{time}</h6> })
    }
    for (let i = 0; i <= 60; i++) {
      const time = transformer.time1DigitTo2Digit(String(i))
      buildMinutesList.push({ value: time, element: <h6>{time}</h6> })
    }
    this.setState({
      hourList: buildHourList,
      minutesList: buildMinutesList,
    })
  }

  componentDidUpdate(prevProps: ISettingsProps & IBetRatesProps) {
    if (prevProps.getBetRatesAllIsFetching !== this.props.getBetRatesAllIsFetching
      && !this.props.getBetRatesAllIsFetching) {
      this.props.loader(false)
      this.seperateBetRates(this.props.getBetRatesAllResult)
    }

    if (prevProps.putBetRatesIsFetching !== this.props.putBetRatesIsFetching
      && !this.props.putBetRatesIsFetching) {
      this.props.getBetRate({ type: 'LOTTER_YEGEE' })
      this.props.loader(false)
    }

    if (prevProps.getYegeeGameConfigAllIsFetching !== this.props.getYegeeGameConfigAllIsFetching
      && !this.props.getYegeeGameConfigAllIsFetching) {
      const yegeeStartTime = transformer.seperateTimeString(this.props.getYegeeGameConfigAllResult.startAt)
      this.setState({
        yegeeRound: this.props.getYegeeGameConfigAllResult.round,
        yegeeRoundTime: this.props.getYegeeGameConfigAllResult.betweenMinutes,
        yegeeStartHour: yegeeStartTime.hour,
        yegeeStartMinutes: yegeeStartTime.minutes,
        expectRate: this.props.getYegeeGameConfigAllResult.expectRate,
      })
    }

    if (prevProps.putYegeeGameConfigIsFetching !== this.props.putYegeeGameConfigIsFetching
      && !this.props.putYegeeGameConfigIsFetching) {
      this.props.loader(false)
    }

    if (prevProps.getBetRatesIsFetching !== this.props.getBetRatesIsFetching
      && !this.props.getBetRatesIsFetching) {
      this.props.loader(false)
    }
  }

  seperateBetRates = (betRatesAll: IBetRatesAll[]) => {
    for (const betRate of betRatesAll) {
      switch (betRate.type) {
        case EBETRATES_TYPE.LOTTER_YEGEE_RUN_DOWN:
          this.setState({
            lotterYegeeRunDownRates: Number(betRate.rate),
            idLotterYegeeRunDownRates: betRate.id,
          })
          break
        case EBETRATES_TYPE.LOTTER_YEGEE_RUN_UP:
          this.setState({ lotterYegeeRunUpRates: Number(betRate.rate), idLotterYegeeRunUpRates: betRate.id })
          break
        case EBETRATES_TYPE.LOTTER_YEGEE_THREE_TOAST:
          this.setState({
            lotterYegeeThreeToastRates: Number(betRate.rate),
            idLotterYegeeThreeToastRates: betRate.id,
          })
          break
        case EBETRATES_TYPE.LOTTER_YEGEE_THREE_UP:
          this.setState({
            lotterYegeeThreeUpRates: Number(betRate.rate),
            idLotterYegeeThreeUpRates: betRate.id,
          })
          break
        case EBETRATES_TYPE.LOTTER_YEGEE_TWO_DOWN:
          this.setState({
            lotterYegeeTwoDownRates: Number(betRate.rate),
            idLotterYegeeTwoDownRates: betRate.id,
          })
          break
        case EBETRATES_TYPE.LOTTER_YEGEE_TWO_UP:
          this.setState({ lotterYegeeTwoUpRates: Number(betRate.rate), idLotterYegeeTwoUpRates: betRate.id })
          break
      }
    }
  }

  onUpdateYegeeGame = () => {
    this.props.loader(true)
    this.props.putYegeeGameConfig({
      round: this.state.yegeeRound,
      between_minutes: this.state.yegeeRoundTime,
      start_at: `${this.state.yegeeStartHour}:${this.state.yegeeStartMinutes}`,
      expect_rate: String(Number(this.state.expectRate) / 100),
    })
  }
  handleExpectRate = (expectRate: string) => {
    expectRate = expectRate.replace(/\+/g, '')
    if (Number(expectRate) > 100) {
      this.setState({ expectRate: '100' })
    }
    else if (Number(expectRate) < 0 || expectRate === ''|| !ZERO.test(expectRate)) {
      this.setState({ expectRate: '' })
    }
    else {
      this.setState({ expectRate: expectRate })
    }
  }



  onUpdateYegeeBetRates = () => {
    const {
      idLotterYegeeRunDownRates,
      idLotterYegeeThreeUpRates,
      idLotterYegeeThreeToastRates,
      idLotterYegeeTwoDownRates,
      idLotterYegeeTwoUpRates,
      idLotterYegeeRunUpRates,

      lotterYegeeRunDownRates,
      lotterYegeeThreeUpRates,
      lotterYegeeThreeToastRates,
      lotterYegeeTwoDownRates,
      lotterYegeeTwoUpRates,
      lotterYegeeRunUpRates,
    } = this.state
    const updateList = [
      {
        id_bet: idLotterYegeeRunDownRates,
        rate: String(lotterYegeeRunDownRates),
      },
      {
        id_bet: idLotterYegeeRunUpRates,
        rate: String(lotterYegeeRunUpRates),
      },
      {
        id_bet: idLotterYegeeThreeToastRates,
        rate: String(lotterYegeeThreeToastRates),
      },
      {
        id_bet: idLotterYegeeThreeUpRates,
        rate: String(lotterYegeeThreeUpRates),
      },
      {
        id_bet: idLotterYegeeTwoDownRates,
        rate: String(lotterYegeeTwoDownRates),
      },
      {
        id_bet: idLotterYegeeTwoUpRates,
        rate: String(lotterYegeeTwoUpRates),
      },
    ]
    this.props.loader(true)
    this.props.putBetRates(updateList)
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }
  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.settingLabel },
      { label: constants.yegeeLabel, active: true },
    ]
    const {
      lotterYegeeRunDownRates,
      lotterYegeeThreeUpRates,
      lotterYegeeThreeToastRates,
      lotterYegeeTwoDownRates,
      lotterYegeeTwoUpRates,
      lotterYegeeRunUpRates,
      yegeeStartHour,
      yegeeStartMinutes,
      yegeeRound,
      yegeeRoundTime,
      expectRate,
    } = this.state
    return (
      <div className="settings-container">
          <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
        <TitlePage title="ตั้งค่าระบบยี่กีเกมส์" />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className="row-yeege">
              <Paper className="paper-container secondary-dark">
                <div className="paper-title">ระบบกำไร</div>
                <div className="paper-body">
                  <div className="yegee-content-container">
                    <TextField
                      label="กำไรที่ต้องการต่อรอบ (ร้อยละ) *"
                      fullWidth={true}
                      type="number"
                      value={expectRate}
                      onChange={(e) => this.handleExpectRate(e.target.value)}
                      placeholder="กำไรที่ต้องการต่อรอบ (ร้อยละ) *"

                    />
                  </div>
                </div>
                <div className="paper-bottom">
                  <Button
                    text="บันทึก"
                    fullWidth={true}
                    onClick={this.onUpdateYegeeGame}
                  />
                </div>
              </Paper>
            </div>
            <Paper className="paper-container secondary-dark">
              <div className="paper-title">ระบบทั่วไป</div>
              <div className="paper-body">
                <div className="body-2 primary-text">
                  *ระบบจะเปลี่ยนแปลงต่อเมื่อถึงเวลาเริ่มเกมส์ของวันถัดไป
                                </div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Select
                      placeholder="เริ่มเกมส์ ชั่วโมงที่"
                      label="เริ่มเกมส์ ชั่วโมงที่"
                      fullWidth={true}
                      value={yegeeStartHour}
                      onChange={(e) => this.setState({ yegeeStartHour: e.target.value })}
                      itemList={this.state.hourList}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      placeholder="เริ่มเกมส์ นาทีที่ *"
                      label="เริ่มเกมส์ นาทีที่ *"
                      fullWidth={true}
                      value={yegeeStartMinutes}
                      onChange={(e) => this.setState({ yegeeStartMinutes: e.target.value })}
                      itemList={this.state.minutesList}
                    />
                  </Grid>
                </Grid>
                <TextField
                  placeholder="จำนวนรอบ *"
                  label="จำนวนรอบ *"
                  type="number"
                  fullWidth={true}
                  value={yegeeRound}
                  onChange={(e) => this.setState({ yegeeRound: e.target.value })}
                />
                <TextField
                  placeholder="ระยะเวลาต่อรอบ (นาที) *"
                  label="ระยะเวลาต่อรอบ (นาที) *"
                  type="number"
                  fullWidth={true}
                  value={yegeeRoundTime}
                  onChange={(e) => this.setState({ yegeeRoundTime: e.target.value })}
                />
              </div>
              <div className="paper-bottom">
                <Button
                  text="บันทึก"
                  fullWidth={true}
                  onClick={this.onUpdateYegeeGame}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="paper-container secondary-dark">
              <div className="paper-title">ระบบอัตราการจ่าย (บาทละ)</div>
              <div className="paper-body">
                <div className="yegee-content-container">
                  <Grid container spacing={3}>
                    <Grid item xs={6} >
                      <div className="yegee-rate-container">
                        <div className="yegee-row">
                          <TextField
                            label="วิ่งบน *"
                            placeholder="วิ่งบน *"
                            fullWidth={true}
                            value={String(lotterYegeeRunUpRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeRunUpRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_RUN_UP' })?.rate).toFixed(2)}
                          />
                          <TextField
                            label="สองตัวบน *"
                            placeholder="สองตัวบน *"
                            fullWidth={true}
                            value={String(lotterYegeeTwoUpRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeTwoUpRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_TWO_UP' })?.rate).toFixed(2)}
                          />
                          <TextField
                            label="สามตัวบน *"
                            placeholder="สามตัวบน *"
                            fullWidth={true}
                            value={String(lotterYegeeThreeUpRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeThreeUpRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_THREE_UP' })?.rate).toFixed(2)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="yegee-rate-container">
                        <div className="yegee-row">
                          <TextField
                            label="วิ่งล่าง *"
                            placeholder="วิ่งล่าง *"
                            fullWidth={true}
                            value={String(lotterYegeeRunDownRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeRunDownRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_RUN_DOWN' })?.rate).toFixed(2)}
                          />
                          <TextField
                            label="สองตัวล่าง *"
                            placeholder="สองตัวล่าง *"
                            fullWidth={true}
                            value={String(lotterYegeeTwoDownRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeTwoDownRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_TWO_DOWN' })?.rate).toFixed(2)}
                          />
                          <TextField
                            label="สามตัวโต๊ด *"
                            placeholder="สามตัวโต๊ด *"
                            fullWidth={true}
                            value={String(lotterYegeeThreeToastRates)}
                            onChange={(e) => this.setState(
                              { lotterYegeeThreeToastRates: Number(e.target.value) })}
                            type="number"
                            betRate={Number(find(this.props.getBetRatesResult, (lotto) => { return lotto.type === 'LOTTER_YEGEE_THREE_TOAST' })?.rate).toFixed(2)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="paper-bottom">
                <Button
                  text="บันทึก"
                  fullWidth={true}
                  onClick={this.onUpdateYegeeBetRates}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Settings