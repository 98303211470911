import './logCriminal.style.scss'
import moment from 'moment'
import momentTime from 'moment-timezone'
import React, { FC } from 'react'
import { noop, get, capitalize, upperCase } from 'lodash'
import { RollerLoading } from 'components'
// import SimpleBar from 'simplebar-react'
import ClearIcon from '@material-ui/icons/Clear'
import { IconButton } from '@material-ui/core'
import { transformer } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Grid
} from '@material-ui/core'

// declare interface ILogCriminalProps {
//   userData: IResCriminalView
//   logData: IResCriminalViewLog[]
//   cancelAction?(): void
//   hasMore?: boolean
//   action?(id:number): void
// }


const constants = {
  history: 'ประวัติ',
  number: 'เลขที่',
  approve: 'อนุมัติโดย',
  riskLevel: 'ระดับความเสี่ยง:',
  workingFoud: 'ยอดเงินหมุนเวียน',
  accountNo: 'หมายเลขบัญชี: ',
  description: 'กรุณารอการตรวจสอบสักครู่',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILogCriminalProps = {
  userData: {
    id: 0,
    user: '',
    adminApproveCount: 0,
    total: 0,
    type: '',
    adminList: [],
    riskLevel: '',
    updatedAt: '',
  },
  logData: [],
  cancelAction() { noop() },
}

const logUser = (dataList:IResCriminalViewLog[]): JSX.Element => {
  // eslint-disable-next-line
    const logList = dataList.map((data, index) => {
    const logTime = `${momentTime(moment(data.updatedAt).format('YYYYMMDDHHmmss'),'YYYYMMDDHHmmss').format('lll')} | ${ get(data.logging.split('|'), '1', '')}`
    const slug = get(data.logging.split('$'), '1', '')
    if (slug !== undefined) {
      const lendingType = get(slug.split('_', 1), '0', '')
      if (lendingType === 'GAME') {
        const trailingBetType = slug.replace(`${lendingType}_`, '')
        const casinoName = get(trailingBetType.split('_', 2), '1', '')
        const gameNameUnderscore = trailingBetType.replace(/CASINO_JOKER_|CASINO_THBGAME_|CASINO_AESEXY_|CASINO_DT_|CASINO_PGGAME_|CASINO_WMGAME_|CASINO_ALLBET_|CASINO_W88_/, '')
        const removeRound = gameNameUnderscore.substring(0, gameNameUnderscore.lastIndexOf('_'))
        const gameName = capitalize(upperCase(removeRound))
        return (
          <div className="m1-b" key={index} >
            {`${logTime} (ค่าย ${casinoName} ${gameName})`}
          </div>
        )
      }
      else {
        return (
          <div className="m1-b" key={index}>
            {`${logTime}`}
          </div>
        )
      }
    }
  })
  return (<div>{logList}</div>)
}

const LogCriminal: FC<ILogCriminalProps & DefaultProps> = (props: ILogCriminalProps) => {
  const approveUser = () => {
    let approveText = `${constants.approve} Admin: `
    props.userData.adminList.map((d, index) => {
      if (index !== props.userData.adminList.length - 1) {
        approveText += `${d}, `
      }
      else {
        approveText += `${d}`
      }
      return approveText
    })
    return approveText
  }

  const fetchMoreData = () => {
    props.action!(props.userData.id)
  }
  return (
    <div className="criminal-modal-container">
      <div className="m1-b title-criminal-modal">
        <h4>{constants.history}</h4>
        <IconButton onClick={props.cancelAction}>
          <ClearIcon />
        </IconButton>
      </div>
      <h6 className="m1-b primary-text">{`เลขที่ ${props.userData.id} user: ${props.userData.user} 
      ${approveUser()} ${constants.workingFoud} ${transformer.typeFormat(String(props.userData.total))}`}
      </h6>
      <Grid container>
        <Grid item xs={12}>
          <div className="criminal-risk">
            <h6>{constants.riskLevel}</h6>
            <div className={`m2-l risk-level-item ${props.userData.riskLevel}`} >
              {props.userData.type ===  'DEPOSIT' ? 'ฝาก' : 'ถอน'}
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="log-list body-2">
            <InfiniteScroll
              dataLength={props.logData!.length}
              next={fetchMoreData}
              hasMore={props.hasMore!}
              height={290}
              loader={<RollerLoading />}
            >
              <h6>{logUser(props.logData)}</h6>
            </InfiniteScroll>
      </div>
    </div>
  )
}

LogCriminal.defaultProps = defaultProps
export default LogCriminal
