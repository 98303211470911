import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const criminalViewLogReducer = (
  state: ICriminalViewLogState = initialState,
  action: RootAction):
  ICriminalViewLogState => {
  switch (action.type) {
    case getType(actions.getCriminalViewLogAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getCriminalViewLogSuccessAction):
      const data = (action.payload.isFirst)
        ? action.payload.data.data.dataList
        : [...(state.data || []), ...(action.payload.data.data.dataList || [])]
      return {
        isFetching: false,
        data: data,
        pagination: action.payload.data.data,
        code: action.payload.data.code
        ,
      }
    case getType(actions.getCriminalViewFailureLogAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.response?.status,
      }
    case getType(actions.getCriminalViewLogClear):
      return initialState
    default:
      return state
  }
}
export default criminalViewLogReducer