import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  TitlePage,
  TextField,
  Button,
  LottoActionCard,
  ResponsiveIcon,
  Breadcrumb,
  InputNumber,
  Modal,
} from "components";
import moment from "moment";
import { noop, isEmpty, map, get } from "lodash";
import colors from "constants/colors";
import {
  LOTTO_SLUG_NAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_TO_TYPE,
  LOTTO_CANCEL_TYPE_NAME,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import "./LottoCancel.container.scss";
import { Grid, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Form, Formik, FormikProps } from "formik";
import initialFormValue from "./models/initialValues";
import scheme from "./models/scheme";
import { responseCode, responseMessage } from "constants/response";
import { SPECIAL } from "constants/regex";

const constants = {
  back: "กลับ",
  closedLabel: "เวลาที่ปิดรับ: ",
  openedYeegeLabel: "เปิดรับ: ",
  closedStatusLabel: "ปิดรับแทง",
  placeholderInput: (type: string) => `กรอก${type}`,
  waitingStatusLabel: "รอเปิดรับแทง",
  openedYeegeStatusLabel: "24 ชม.",
  round: "รอบ",
  twoUp: "2 ตัวบน",
  twoDown: "2 ตัวล่าง",
  threeUp: "3 ตัวบน",
  fourSuit: "เลขชุด 4 ตัว",
  runUp: "วิ่งบน",
  runDown: "วิ่งล่าง",
  threeToast: "สามตัวโต๊ด",
  level: "ระดับ",
  Rate: "อัตราจ่าย",
  number: "เลข",
  sumTotal: "ยอดรวมปรับระดับ",
  wrongUsernameTitle: "เพิ่มระดับอัตราจ่ายไม่ถูกต้อง",
  wrongUsernameDescription:
    "โปรดกรอกยอดปรับระดับมากกว่าระดับเดิม และอัตราจ่ายที่น้อยกว่าระดับเดิม",
  settingLabel: "ตั้งค่าระบบ",
  betRateLabel: "คืนโพยเลข",
  cancelBet: "คืนโพย",
  success: "สำเร็จ",
  fail: "ไม่สำเร็จ",
  description: "เนื่องจาก :",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: INumberRateCancelProps & INumberRateCancelActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: "",
  lottoSchedule: [],

  postLottoCancelIsFetching: false,
  postLottoCancelCode: 0,
  postLottoCancelError: "",
  postLottoCancelResult: {},

  loader() {
    noop();
  },
  getLottoSchedule() {
    noop();
  },
  postLottoCancel() {
    noop();
  },
};

class LottoRateContainer extends Component<
  INumberRateCancelProps &
    INumberRateCancelActionProps &
    DefaultProps &
    RouteComponentProps
> {
  static defaultProps = defaultProps;

  FormikInstance: FormikProps<ILottoRateNumber> | null = null;

  state: INumberRateCancelContainerState = {
    selectedDate: null,
    search: "",
    onSettingLotto: "LOTTER_GOVN",
    title: "หวยรัฐบาล",
    startTime: "",
    endTime: "",
    lottoSchedule: [],
    icon: LottoFlags.THA.Icon,
    isFirst: true,
    number: "",
  };

  componentDidMount() {
    this.props.loader(true);
    this.props.getLottoSchedule();
  }

  componentDidUpdate(prevProps: INumberRateCancelProps) {
    if (
      prevProps.getLottoScheduleIsFetching !==
        this.props.getLottoScheduleIsFetching &&
      !this.props.getLottoScheduleIsFetching
    ) {
      this.setState({
        lottoSchedule: this.props.lottoSchedule.filter((member) => {
          return member.code !== "LOTTER_YEGEE";
        }),
      });
      this.props.loader(false);
    }

    if (
      prevProps.postLottoCancelIsFetching !==
        this.props.postLottoCancelIsFetching &&
      !this.props.postLottoCancelIsFetching
    ) {
      if (this.props.postLottoCancelCode === responseCode.OK) {
        Modal.confirm.show({
          action: () => {
            Modal.error.hide();
            this.checkForPostNext();
          },
          typeIcon: "Alert",
          isCancelButton: false,
          child: this.detailModal(
            this.state.number,
            this.selectType(this.state.number, this.state.isFirst),
            true,
            get(responseMessage, this.props.postLottoCancelCode, "")
          ),
          height: "340px",
          description: constants.cancelBet,
        });
      } else {
        Modal.confirm.show({
          action: () => {
            Modal.error.hide();
            this.checkForPostNext();
          },
          typeIcon: "Alert",
          isCancelButton: false,
          child: this.detailModal(
            this.state.number,
            this.selectType(this.state.number, this.state.isFirst),
            false,
            get(responseMessage, this.props.postLottoCancelCode, "")
          ),
          height: "365px",
          description: constants.cancelBet,
        });
      }
      this.props.loader(false);
    }
  }

  checkForPostNext = () => {
    if (
      this.state.isFirst &&
      this.selectType(this.state.number, this.state.isFirst) !== "FOUR_SUITE"
    ) {
      this.props.loader(true);
      this.setState({ isFirst: false }, () => {
        this.postLottocancel(
          this.state.number,
          this.selectType(this.state.number, false)
        );
      });
    } else {
      this.setState({ isFirst: true });
    }
  };

  selectType(number: string, isFirst: boolean) {
    let type: TLottoTypeCancel = "";
    if (number.length === 3) {
      type = isFirst ? "THREE_UP" : "THREE_TOAST";
    } else if (number.length === 2) {
      type = isFirst ? "TWO_UP" : "TWO_DOWN";
    } else if (number.length === 1) {
      type = isFirst ? "RUN_UP" : "RUN_DOWN";
    } else if (number.length === 4 && isFirst) {
      type = "FOUR_SUITE";
      // LOTTER_LAO_SUITE
    }
    return type;
  }

  postLottocancel(number: string, type: string) {
    if (!isEmpty(type)) {
      this.props.postLottoCancel({
        number: number,
        type: type,
        code: this.state.onSettingLotto,
      });
    }
  }

  renderLottoList = () =>
    map(this.state.lottoSchedule, (lotto, index) => {
      const subtitle =
        lotto.code === "LOTTER_YEGEE"
          ? constants.openedYeegeLabel
          : constants.closedLabel;
      const backgroundColor =
        lotto.code === this.state.onSettingLotto
          ? colors.SECONDARY_PURPLE
          : colors.SECONDARY_DARK;
      const description =
        lotto.code === "LOTTER_YEGEE"
          ? `88 ${constants.round}`
          : isEmpty(lotto.endTime)
          ? "-"
          : moment(lotto.endTime)
              .add(-7, "hour")
              .add(543, "years")
              .format("DD MMM YY HH:mm");
      const isCountingDown =
        lotto.code === "LOTTER_YEGEE" ? false : lotto.status === "OPEN";
      const FlagIcon =
        LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon;
      return (
        <div className="lotto-body-item" key={`lotto-${lotto.code}-${index}`}>
          <LottoActionCard
            id={`lotto-${lotto.code}`}
            onClick={() => this.renderBetDetail(lotto)}
            title={LOTTO_SLUG_NAME[lotto.code]}
            subTitle={subtitle}
            icon={FlagIcon}
            backgroundColor={backgroundColor}
            status={lotto.status}
            isCountingdown={isCountingDown}
            closedStatusText={constants.closedStatusLabel}
            waitingStatusText={constants.waitingStatusLabel}
            openedStatusText={constants.openedYeegeStatusLabel}
            description={description}
            expire={lotto.endTime}
          />
        </div>
      );
    });

  renderBetDetail = (lotto: ILottoSchedule) => {
    moment.locale("th");
    this.props.loader(true);
    this.setState({ onSettingLotto: lotto.code }, () => {
      this.FormikInstance?.resetForm();
      this.props.loader(false);
    });
    this.setState({
      id: lotto.id,
      title: LOTTO_SLUG_NAME[lotto.code],
      startTime: lotto.startTime,
      endTime: moment(lotto.endTime).format("lll"),
      icon: LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]].Icon,
      // initialFormValue: { ...initialValue },
    });
  };

  updateSearch(value: any) {
    let newVal = value.replace(SPECIAL, "");
    this.setState({ search: newVal.substr(0, 20) });
  }
  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };
  resetData = (formikProps: FormikProps<ILottoRateNumber>) => {
    formikProps.setValues({ number: "" });
    formikProps.setErrors({ number: "" });
    formikProps.setTouched({ number: false });
  };

  detailModal = (
    number?: string,
    typeLotto: TLottoTypeCancel = "",
    isSuccess: boolean = true,
    textDetail: string = ""
  ) => {
    const type = get(LOTTO_CANCEL_TYPE_NAME, typeLotto, "");
    return (
      <>
        <div className="d-flex flex justify-content-center">
          <ResponsiveIcon
            icon={this.state.icon}
            alt="flag"
            className="lotto-action-card-flag m1-r"
          />
          <h5>{this.state.title}</h5>
        </div>
        <div className="d-flex flex align-items-end justify-content-center my-2">
          {!isEmpty(typeLotto) ? (
            <>
              <h3>{type}</h3>
              <h3
                className={`ml-2 ${
                  isSuccess ? "primary-green-text" : "primary-red-text"
                }`}
              >
                {isSuccess ? constants.success : constants.fail}
              </h3>
            </>
          ) : (
            <>
              <h5 className="mb-1 pb-1">{constants.number}</h5>
              <h2 className="ml-3 primary-red-text fw-bolder">{number}</h2>
            </>
          )}
        </div>
        {!isSuccess ? (
          <>
            <div className="d-flex flex align-items-end justify-content-center mb-2">
              <h5>{constants.description}</h5>
              <h5 className="ml-1">{textDetail}</h5>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    const RenderLottoList = this.renderLottoList().filter((Lotto) => {
      return (
        Lotto.props.children.props.title
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    const navigates: IBreadcrumbItem[] = [
      { label: constants.settingLabel, active: false },
      { label: constants.betRateLabel, active: true },
    ];
    return (
      <div className="lotto-rate-container primary-bg">
        <Breadcrumb
          items={navigates}
          handleOnClickItem={this.handleOnClickBreadcrumb}
        />
        <TitlePage title={constants.betRateLabel} />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="m3-b">
              <TextField
                label="ค้นหา"
                startElement={<SearchIcon />}
                fullWidth={true}
                value={this.state.search}
                onChange={(e) => this.updateSearch(e.target.value)}
              />
            </div>
            <div className="lotto-body">
              <SimpleBar style={{ height: "100%" }}>
                {RenderLottoList}
              </SimpleBar>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Paper className="paper-container secondary-dark">
              <div className="paper-title">
                <div className="lotto-action-text-wrapper">
                  <Grid container>
                    <Grid item xs={10}>
                      <div className="d-flex flex">
                        <ResponsiveIcon
                          icon={this.state.icon}
                          alt="flag"
                          className="lotto-action-card-flag m2-r"
                        />
                        <h3>{this.state.title}</h3>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Formik
                initialValues={initialFormValue}
                validationSchema={scheme(this.state.onSettingLotto)}
                enableReinitialize
                innerRef={(instance: FormikProps<ILottoRateNumber>) =>
                  (this.FormikInstance = instance)
                }
                onSubmit={(values, { resetForm }) => {
                  Modal.confirm.show({
                    action: () => {
                      Modal.confirm.hide();
                      this.setState({ number: values.number }, () => {
                        this.props.loader(true);
                        this.postLottocancel(
                          this.state.number,
                          this.selectType(values.number, true)
                        );
                      });
                      // resetForm({})
                    },
                    cancelAction: () => {
                      Modal.confirm.hide();
                    },
                    child: this.detailModal(values.number),
                    height: "365px",
                    description: "ยืนยันการคืนโพย",
                  });
                }}
              >
                {(formikProps: FormikProps<ILottoRateNumber>) => {
                  const {
                    errors,
                    handleSubmit,
                    handleBlur,
                    touched,
                    values,
                    setFieldValue,
                  } = formikProps;
                  return (
                    // @ts-ignore
                    <Form>
                      <p className="primary-text ml-3">
                        &emsp;&emsp;การคืนโพยเป็นการยกเลิกโพยที่ลูกค้าได้วางเดิมพันเรียบร้อยแล้ว
                        ทางระบบจะคืนเงินที่เดิมพันเลขดังกล่าวทั้งหมดให้กับลูกค้าและ
                        บิลทั้งหมดจะเข้าสถานะ “ยกเลิก”{" "}
                      </p>
                      <h4 className="ml-5">ข้อควรระวัง</h4>
                      <p className="primary-text mt-0 ml-3">
                        &emsp;&emsp;หลังจากการยกเลิกโพยแล้วไม่สามารถย้อนกลับหรือแก้ไขในกรณีคืนโพยผิดพลาด
                        โปรดระมัดระวังในการใช้ระบบคืนโพย
                      </p>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <div className="m3-t">
                            <InputNumber
                              name="number"
                              onBlur={handleBlur}
                              label={constants.number}
                              fullWidth={true}
                              onValueChange={({ value }) => {
                                setFieldValue("number", value);
                              }}
                              allowNegative={false}
                              allowLeadingZeros
                              maxLength={
                                this.state.onSettingLotto === "LOTTER_LAO_SUITE"
                                  ? 4
                                  : 3
                              }
                              placeholder={constants.placeholderInput(
                                constants.number
                              )}
                              error={!!errors.number && touched.number}
                              value={values.number}
                              helperText={
                                !!errors.number && touched.number
                                  ? `${errors.number}`
                                  : ""
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        className="d-flex justify-content-end"
                      >
                        <Grid item xs={4}>
                          <Button
                            type="submit"
                            text={constants.cancelBet}
                            fullWidth={true}
                            disabled={
                              !isEmpty(errors) || isEmpty(values.number)
                            }
                            onClick={() => handleSubmit}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LottoRateContainer;
