import { createAction } from 'typesafe-actions'
import {
  GET_TRANSACTION_ALL_REQUEST,
  GET_TRANSACTION_ALL_SUCCESS,
  GET_TRANSACTION_ALL_FAILURE,
  GET_TRANSACTION_ALL_CANCEL,

  UPDATE_TRANSACTION_ALL_REQUEST,
  UPDATE_TRANSACTION_ALL_SUCCESS,
  UPDATE_TRANSACTION_ALL_FAILURE,

  UPDATE_TRANSACTION_WITHDRAW_ALL_REQUEST,
  UPDATE_TRANSACTION_WITHDRAW_ALL_SUCCESS,
  UPDATE_TRANSACTION_WITHDRAW_ALL_FAILURE,

  UNLISTEN_TRANSACTION_ALL_SOCKET,
  CLEAR_TRANSACTION_ALL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getTransactionAllAction = createAction(GET_TRANSACTION_ALL_REQUEST,
  reslove => (data: IGetTransactionParams) => reslove(data)
)
const getTransactionAllSuccessAction = createAction(
  GET_TRANSACTION_ALL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<ITransactionAll>>>) => resolve(data))

const getTransactionAllFailureAction = createAction(
  GET_TRANSACTION_ALL_FAILURE,
  resolve => (error: AxiosError) => resolve(error))
const getTransactionAllCancelAction = createAction(GET_TRANSACTION_ALL_CANCEL)


const updateTransactionAllAction = createAction(
  UPDATE_TRANSACTION_ALL_REQUEST,
  resolve => (data: APIResponse<PaginationResponse<ITransactionAll>>) => resolve(data))

const updateTransactionAllActionSuccessAction = createAction(
  UPDATE_TRANSACTION_ALL_SUCCESS,
  resolve => (data: APIResponse<PaginationResponse<ITransactionAll>>) => resolve(data))

const updateTransactionAllActionFailureAction = createAction(
  UPDATE_TRANSACTION_ALL_FAILURE,
  resolve => (error: any) => resolve(error))


const updateTransactionWithdrawAllAction = createAction(
  UPDATE_TRANSACTION_WITHDRAW_ALL_REQUEST,
  resolve => (data: APIResponse<PaginationResponse<ITransactionAll>>) => resolve(data))

const updateTransactionWithdrawlActionSuccessAction = createAction(
  UPDATE_TRANSACTION_WITHDRAW_ALL_SUCCESS,
  resolve => (data: APIResponse<PaginationResponse<ITransactionAll>>) => resolve(data))

const updateTransactionWithdrawActionFailureAction = createAction(
  UPDATE_TRANSACTION_WITHDRAW_ALL_FAILURE,
  resolve => (error: any) => resolve(error))



const unlistenTransactionAllSocket = createAction(
  UNLISTEN_TRANSACTION_ALL_SOCKET,
  resolve => () => resolve())

const clearTransactionAll = createAction(CLEAR_TRANSACTION_ALL)

export default {
  getTransactionAllAction,
  getTransactionAllSuccessAction,
  getTransactionAllFailureAction,
  getTransactionAllCancelAction,
  updateTransactionAllAction,
  updateTransactionAllActionSuccessAction,
  updateTransactionAllActionFailureAction,
  updateTransactionWithdrawAllAction,
  updateTransactionWithdrawlActionSuccessAction,
  updateTransactionWithdrawActionFailureAction,
  unlistenTransactionAllSocket,
  clearTransactionAll,
}