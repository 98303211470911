import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const webConfigReducer = (state: IWebConfigState = initialState, action: RootAction): IWebConfigState => {
    switch (action.type) {
        case getType(actions.getWebConfigAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getWebConfigSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.getWebConfigFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}

const putWebConfigReducer = (state: IWebConfigState = initialState, action: RootAction): IWebConfigState => {
    switch (action.type) {
        case getType(actions.putWebConfigAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.putWebConfigSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }

        case getType(actions.putWebConfigFailureAction):
            return {
                isFetching: false,
                error: action.payload.message,
                code: action.payload.code,
            }
        default:
            return state
    }
}


export default combineReducers({
    get: webConfigReducer,
    put: putWebConfigReducer,
})