import { createAction } from 'typesafe-actions'
import {
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
} from './constants'

import {
  AxiosResponse,
  AxiosError
} from 'axios'

const postUserAction = createAction(
  POST_USER_REQUEST,
  reslove => (data: IManagementPostData) => reslove(data)
)
const postUserSuccessAction = createAction(
  POST_USER_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IUser>>) => resolve(data)
)
const postUserFailureAction = createAction(
  POST_USER_FAILURE,
  resolve => (data: AxiosError) => resolve(data))

export default {
  postUserAction,
  postUserSuccessAction,
  postUserFailureAction,
}