import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import  responseConverter  from '../../../utils/responseConverter';
import actions from './actions'

const putUserReducer = (state: IGetMeState = initialState, action: RootAction): IUserPutState => {
  switch (action.type) {
      case getType(actions.putUserAction):
          return {
              ...state,
              isFetching: true,
          }
      case getType(actions.putUserSuccessAction):
          return {
              isFetching: false,
              data: action.payload.data.data,
              code: action.payload.data.code,
          }

      case getType(actions.putUserFailureAction):
        const convertedResponse = responseConverter.getMessage(action.payload);
          return {
              isFetching: false,
              error: convertedResponse.message,
              code: action.payload.response?.data.code,
          }
      default:
          return state
  }
}

export default putUserReducer