import project from 'constants/project'

export const GET_LOTTO_RATE_NUMBER_REQUEST = 'GET_LOTTO_RATE_NUMBER_REQUEST'
export const GET_LOTTO_RATE_NUMBER_SUCCESS = 'GET_LOTTO_RATE_NUMBER_SUCCESS'
export const GET_LOTTO_RATE_NUMBER_FAILURE = 'GET_LOTTO_RATE_NUMBER_FAILURE'
export const GET_LOTTO_RATE_NUMBER_CANCEL = 'GET_LOTTO_RATE_NUMBER_CANCEL'

export const initialState: IRateNumberListStoreState = {
  isFetching: false,
  data: [],
  code: 0,
  page: 0,
  limit: 0,
  total: 0
}

export const endpoint = {
  getAllLottoRateNumber: `${project.environment[project.environmentName].api}/lotter/rate-number/all`,
}