import { createAction } from 'typesafe-actions'
import {
  GET_BET_RATES_ALL_REQUEST,
  GET_BET_RATES_ALL_SUCCESS,
  GET_BET_RATES_ALL_FAILURE,
  GET_BET_RATES_ALL_CANCEL,

  PUT_BET_RATES_REQUEST,
  PUT_BET_RATES_SUCCESS,
  PUT_BET_RATES_FAILURE,
  PUT_BET_RATES_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getBetRatesAllAction = createAction(GET_BET_RATES_ALL_REQUEST)

const getBetRatesAllSuccessAction = createAction(
  GET_BET_RATES_ALL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetRatesAll>>) => resolve(data))

const getBetRatesAllFailureAction = createAction(
  GET_BET_RATES_ALL_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const getBetRatesAllCancelAction = createAction(GET_BET_RATES_ALL_CANCEL)

const putBetRatesAction = createAction(
  PUT_BET_RATES_REQUEST,
  reslove => (data: IBetRatesPutData[]) => reslove(data)
)

const putBetRatesSuccessAction = createAction(
  PUT_BET_RATES_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetRatesAll>>[]) => resolve(data))

const putBetRatesFailureAction = createAction(
  PUT_BET_RATES_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const putBetRatesCancelAction = createAction(PUT_BET_RATES_CANCEL)

export default {
  getBetRatesAllAction,
  getBetRatesAllSuccessAction,
  getBetRatesAllFailureAction,
  getBetRatesAllCancelAction,

  putBetRatesAction,
  putBetRatesSuccessAction,
  putBetRatesFailureAction,
  putBetRatesCancelAction,
}