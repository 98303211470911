import React from 'react'
import { noop, isEqual, isEmpty } from 'lodash';
import './Webbank.style.scss'
import Grid from '@material-ui/core/Grid'
import '../../components/InputRadioImage'
import bank from 'assets/images/global/bank'
import { date, number, transformer } from 'utils'
import {
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  TableHead,
} from '@material-ui/core'
import { History } from 'history';
import {
  Button,
  // TextField,
  Breadcrumb,
  TitlePage,
  BankCard,
  Tap,
  Select,
  Chipper,
} from 'components'
import moment from 'moment';
// import SearchIcon from '@material-ui/icons/Search'

const constants = {
  btnDetail: 'รายละเอียด',
  btnEdit: 'แก้ไข',
  systemLabel: 'จัดการระบบ',
  webBankLabel: 'บัญชีระบบ',
  addWebBankLabel: 'เพิ่มบัญชีระบบ',

  tapDeposit:'บัญชีฝาก',
  tapWithdraw:'บัญชีถอน',
  bankActive:'บัญชีที่ใช้งานอยู่',
  bankInActive:'รายการบัญชีระบบ',

  dataEmpty:'ไม่มีข้อมูล...',
  search:'ค้นหา',
  update:'อัพเดท',
  page:'รายการ/หน้า',
  of:'ของ',

  listBankAuto:'รายการบัญชีอัตโนมัติ',
  listBankAutoHistory:'ประวัติระบบบัญชีอัตโนมัติ',
}

const columnsInactive: IListInactiveColumn<'id' | 'money' | 'userTxTime' | 'status' |
  'userName' | 'userBank' | 'bankName' | 'approval' | '' |
  'walletBank'>[] = [
    { id: 'id', label: 'รายการ', minWidth: 50, align: 'center' },
    { id: 'userName', label: 'ชื่อบัญชี', minWidth: 50, align: 'center' },
    { id: 'userBank', label: 'เลขที่บัญชี', minWidth: 50, align: 'center' },
    { id: 'money', label: 'จำนวนเงิน', minWidth: 50, align: 'center' },
    { id: 'bankName', label: 'ธนาคารร่วม', minWidth: 50, align: 'center' },
    { id: '', label: '', minWidth: 100, align: 'center' },
    { id: '', label: '', minWidth: 100, align: 'center' },
  ];

const columnsListAuto: IListAutoColumn<'id' | 'money' | 'userTxTime' | 'status' |
  'userName' | 'userBank' | 'bankName' | 'approval' | '' |
  'walletBank'>[] = [
    { id: 'id', label: 'รายการ', minWidth: 50, align: 'center' },
    { id: 'userName', label: 'ชื่อบัญชี', minWidth: 50, align: 'center' },
    { id: 'userBank', label: 'เลขที่บัญชี', minWidth: 50, align: 'center' },
    { id: '', label: 'เวลาทำรายการ', minWidth: 50, align: 'center' },
    { id: 'bankName', label: 'จำนวนเงิน', minWidth: 50, align: 'center' },
    { id: 'approval', label: 'ยกเลิก', minWidth: 50, align: 'center' },
  ];

const columnsHistoryListAuto: IHistoryListAutoColumn<'id' | 'money' | 'userTxTime' | 'status' |
  'userName' | 'userBank' | 'bankName' | 'approval' | '' |
  'walletBank'>[] = [
    { id: 'id', label: 'รายการ', minWidth: 50, align: 'center' },
    { id: 'userName', label: 'ประเภท', minWidth: 50, align: 'center' },
    { id: 'userName', label: 'ชื่อบัญชี', minWidth: 50, align: 'center' },
    { id: 'userBank', label: 'เลขที่บัญชี', minWidth: 50, align: 'center' },
    { id: 'bankName', label: 'เวลาที่เปิดระบบ', minWidth: 50, align: 'center' },
    { id: 'approval', label: 'เวลาที่ปิดระบบ', minWidth: 50, align: 'center' },
    { id: '', label: 'จำนวนเงิน', minWidth: 50, align: 'center' },
  ];

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IWebBankProps & IWebBankActionProps = {
  getWebBank() { noop() },
  getActiveWebBank() { noop() },
  getInActiveWebBank() { noop() },
  getWebBankRate() { noop() },
  putWebBank() { noop() },
  deleteWebBank() { noop() },
  getWebBankActive() { noop() },
  loader() { noop() },
  listenWebbankSocket() { noop() },
  unlistenWebbankSocket() { noop() },
  postWebBankIsFetching: false,
  postWebBankError: '',
  postWebBankCode: 0,
  postWebBankResult: [],

  getWebBankActiveIsFetching: false,
  getWebBankActiveError: '',
  getWebBankActiveCode: 0,
  getWebBankActiveResult: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  getWebBankIsFetching: false,
  getWebBankError: '',
  getWebBankCode: 0,
  getWebBankResult: [],
  getWebBankLimit: 10,
  getWebBankPage: 0,
  getWebBankTotal: 0,

  getActiveWebBankIsFetching: false,
  getActiveWebBankError: '',
  getActiveWebBankCode: 0,
  getActiveWebBankResult: [],
  getActiveWebBankLimit: 10,
  getActiveWebBankPage: 0,
  getActiveWebBankTotal: 0,

  getInActiveWebBankIsFetching: false,
  getInActiveWebBankError: '',
  getInActiveWebBankCode: 0,
  getInActiveWebBankResult: [],
  getInActiveWebBankLimit: 10,
  getInActiveWebBankPage: 0,
  getInActiveWebBankTotal: 0,

  putWebBankIsFetching: false,
  putWebBankError: '',
  putWebBankCode: 0,
  putWebBankResult: [],

  deleteWebBankIsFetching: false,
  deleteWebBankError: '',
  deleteWebBankCode: 0,
  deleteWebBankResult: [],


  getWebBankRateIsFetching: false,
  getWebBankRateIsError: '',
  getWebBankRateCode: 0,
  getWebBankRateResult: {
    id: 0,
    limitMoney: 0,
  },
}

class Webbank extends React.Component<IWebBankActionProps & IWebBankProps & DefaultProps & { history: History },
  IWebBankContainerState> {

  static defaultProps = defaultProps

  state: IWebBankContainerState = {
    queryStatus: 'INACTIVE',
    webBankList: [],
    limit: 10,
    page: 0,
    total: 0,
    search: '',
    currentTap: 0
  }

  getWebBankSubscribe() {
    this.props.listenWebbankSocket({
      queryStatus: this.state.queryStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
    })
  }
  componentDidMount() {
    this.getWebBankSubscribe()
    this.getWebBankActiveAndInActive()
    this.props.getWebBankActive()
    this.props.getWebBankRate()
  }

  getWebBankActiveAndInActive (){
    let data:IGetWebBankParams = {
      queryStatus: this.state.queryStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      operate_type: this.state.currentTap===0?'DEPOSIT':'WITHDRAW'
    }
    this.props.getActiveWebBank(data)
    this.props.getInActiveWebBank(data)
  }
  getWebBankInActive (){
    let data:IGetWebBankParams = {
      queryStatus: this.state.queryStatus,
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.search,
      operate_type: this.state.currentTap===0?'DEPOSIT':'WITHDRAW'
    }
    this.props.getInActiveWebBank(data)
  }

  componentDidUpdate(prevProps: IWebBankProps) {
    if (prevProps.postWebBankIsFetching !== this.props.postWebBankIsFetching
      && !this.props.deleteWebBankIsFetching) {
      this.getWebBankActiveAndInActive()
    }
    if (prevProps.deleteWebBankIsFetching !== this.props.deleteWebBankIsFetching
      && !this.props.deleteWebBankIsFetching) {
      this.getWebBankActiveAndInActive()
    }
    if (prevProps.putWebBankIsFetching !== this.props.putWebBankIsFetching) {
      this.props.loader(false)
      this.getWebBankActiveAndInActive()
    }
    if (prevProps.getActiveWebBankIsFetching !== this.props.getActiveWebBankIsFetching) {
      this.props.loader(false)
    }
    if (prevProps.getInActiveWebBankIsFetching !== this.props.getInActiveWebBankIsFetching) {
      this.props.loader(false)
      this.setState({
        limit: this.props.getInActiveWebBankLimit,
        page: this.props.getInActiveWebBankPage,
        total: this.props.getInActiveWebBankTotal,
      })
    }
    if (!isEqual(this.props.getInActiveWebBankResult, prevProps.getInActiveWebBankResult)
      || !isEqual(this.props.getInActiveWebBankTotal, prevProps.getInActiveWebBankTotal)) {
      if (this.props.getInActiveWebBankResult.length === 0 && (this.props.getInActiveWebBankPage > 0)) {
        this.props.loader(false)
        this.setState({
          limit: this.props.getInActiveWebBankLimit,
          total: this.props.getInActiveWebBankTotal,
        })
        this.handleChangePage(null, this.props.getInActiveWebBankPage - 1)
      }
      else {
        this.props.loader(false)
        this.setState({
          limit: this.props.getInActiveWebBankLimit,
          page: this.props.getInActiveWebBankPage,
          total: this.props.getInActiveWebBankTotal,
        })
      }
    }
  }

  handleRouteCreateWebBank = () => {
    this.props.history.push('/add-account')
  }

  handleRouteEditWebBank = (data: IWebBank) => {
    this.props.history.push('/edit-webbank', { webBank: data })
  }

  handleRouteDeleteWebBank = (data: IWebBank) => {
    this.props.history.push('/delete-webbank', { webBank: data })
  }

  createData(txData: IWebBank): IWebBank {
    return {
      id: (txData.id),
      type: txData.type,
      name: txData.name,
      number: txData.number,
      currentMoney: txData.currentMoney ? Number(txData.currentMoney) : 0,
      status: txData.status,
      createdAt: txData.createdAt,
      updated_at: txData.updated_at,
      bankType: txData.bankType
    };
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({
      page: newPage,
    }, () => {
      this.getWebBankSubscribe()
      this.getWebBankInActive()
    })
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({
      page: 0,
      limit: Number(event.target.value),
    }, () => {
      this.getWebBankSubscribe()
      this.getWebBankInActive()
    })
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }

  currentTap = (current: number) => {
      this.setState({ currentTap: current, queryStatus:'INACTIVE' },()=>{
      this.getWebBankActiveAndInActive()
    })
  }
  onSelectedStatus = (status: IQueryWebBankStatus) => {
    this.props.loader(true)
    this.setState({ queryStatus: status, page:0 },()=>{
      this.getWebBankInActive()
    })
  }

  renderTitle = (): JSX.Element => {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.systemLabel, active: false },
      { label: constants.webBankLabel, active: true },
    ]
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8}>
            <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
            <TitlePage title={constants.webBankLabel} />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                { }
              </Grid>
              <Grid item xs={2}>
                { }
              </Grid>
              <Grid item xs={4}>
                <div>
                  <Button
                    onClick={this.handleRouteCreateWebBank}
                    text={constants.addWebBankLabel}
                    fullWidth={true}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderListBankCard = () => (
    !isEmpty(this.props.getActiveWebBankResult)
      ? this.props.getActiveWebBankResult.map((data, index) => {
          return (
            <div key={`BankCard-${index}`}>
              <BankCard
                id={`BankCard-${index}`}
                onClickDetail={() => { this.handleRouteDeleteWebBank(data) }}
                dataWebBank={data}
              />
            </div>
          )
        })
      : <h5>{constants.dataEmpty}</h5>
  )

  transformTime(time: string) {
    return moment(time).add('years', 543).format('Do MMM YYYY k:mm')
  }

  renderTableINACTIVE = () => (
    <TableContainer component={Paper}>
      <Table aria-label="sticky table">
        <TableHead>
          <TableRow>
            {
              columnsInactive.map((column, index) => (
                <TableCell
                  key={column.id + index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="primary-text"
                >
                  {column.label}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody className="list-inactive">
          {!isEmpty(this.props.getInActiveWebBankResult)
            ? this.props.getInActiveWebBankResult.map((row, index) => (
              <TableRow key={`table-row ${index}`}>
                <TableCell align="left">
                  <div className="ellipse-img">
                    <img
                      src={bank[row.type].Icon}
                      style={{ width: 'inherit', height: 'inherit' }}
                      alt="thailand-flat"
                    />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="primary-text">{row.name}</div></h6>
                  <div className=" body-2 secondary-text">{`${constants.update} ${date.FormatDate(row.createdAt, 'Do MMM YY k:mm น.')}`}</div>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="primary-text">{bank[row.type].name}</div></h6>
                  <div className=" body-2 secondary-text">{number.formatBankNumber(row.number, row.type)}</div>
                  <div>
                    {row.status === 'INACTIVE'
                      ? <Chipper label={`ยังไม่ได้ใช้งาน`} color="yellow" />
                      : ''}
                  </div>
                  <div>
                    {row.status === 'READY_TO_HARVEST'
                      ? <Chipper label={`เต็มวงเงิน`} color="green" />
                      : ''}
                  </div>
                </TableCell>
                <TableCell align="center" className="body-2">
                  <h5 className="primary-green-text">
                    {transformer.typeFormat(String(row.currentMoney))}
                  </h5>
                </TableCell>
                <TableCell align="center" className="list-bank">
                <div className="list-logo-bank d-flex justify-content-center">
                  {["SCB","KTB","TCD"].map((nameBank,index)=>
                    (<img
                        key={index}
                        src={bank[nameBank].Icon}
                        className={`logo-bank ${index===0?'first':''}`}
                        alt={`thailand-flat-${index}`}
                      />))
                  }
                  <div className="box-close d-flex justify-content-center align-self-center">
                    <h6 className="primary-text">ปิดปรับปรุง ชั่วคราว</h6>
                  </div>
                </div>
                </TableCell>
                <TableCell align="center">
                  <Button
                    text={constants.btnEdit}
                    theme="outline"
                    onClick={() => this.handleRouteEditWebBank(row)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    text={constants.btnDetail}
                    size="small"
                    onClick={() => this.handleRouteDeleteWebBank(row)}
                  />
                </TableCell>
              </TableRow>
            ))
            : (
                <TableRow hover role="checkbox">
                  <TableCell colSpan={7} key={'no data'} >
                    <h5>{constants.dataEmpty}</h5>
                  </TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )

  renderTableBankAuto = () => (
    <TableContainer component={Paper} className="list-bank-auto">
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columnsListAuto.map((column, index) => (
              <TableCell
                key={column.id + index}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                className="primary-text"
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            [].length === 0 ? (
              <TableRow hover role="checkbox">
                <TableCell colSpan={6} key={'no data'} >
                  <h5>{constants.dataEmpty}</h5>
                </TableCell>
              </TableRow>
            ) : (
              this.state.webBankList.map((row, index) => (
                <TableRow key={`table-row ${index}`}>
                  <TableCell align="left">
                    <div className="ellipse-img">
                      <img
                        src={bank[row.type].Icon}
                        style={{ width: 'inherit', height: 'inherit' }}
                        alt="thailand-flat"
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <h6><div className="body-2 primary-text">{row.name}</div></h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6><div className="body-2 primary-text">{bank[row.type].name}</div></h6>
                    <div className="subtitle-2 secondary-text">{number.formatBankNumber(row.number, row.type)}</div>
                  </TableCell>
                  <TableCell align="center">
                    <h6><div className="body-2 primary-text">{"18 พ.ค. 20 15:43 น."}</div></h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6><div className="body-2 primary-text">{"฿500,000.00"}</div></h6>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      text={"ยกเลิก"}
                      theme="outline"
                    // onClick={() => this.handleRouteEditWebBank(row)}
                    />
                  </TableCell>
                </TableRow>
              ))
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )

  renderTableBankAutoHistory = () => (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columnsHistoryListAuto.map((column, index) => (
              <TableCell
                key={column.id + index}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                className="primary-text"
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            [].length === 0 ? (
              <TableRow hover role="checkbox">
                <TableCell colSpan={7} key={'no data'} >
                  <h5>{constants.dataEmpty}</h5>
                </TableCell>
              </TableRow>
            ) : (this.state.webBankList.map((row, index) => (
              <TableRow key={`table-row ${index}`}>
                <TableCell align="left">
                  <div className="ellipse-img">
                    <img
                      src={bank[row.type].Icon}
                      style={{ width: 'inherit', height: 'inherit' }}
                      alt="thailand-flat"
                    />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="body-2 primary-text">{"ประเภท"}</div></h6>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="body-2 primary-text">{row.name}</div></h6>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="primary-text">{bank[row.type].name}</div></h6>
                  <div className="subtitle-2 secondary-text">{number.formatBankNumber(row.number, row.type)}</div>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="subtitle-2 primary-text">{"18 พ.ค. 20 15:43 น."}</div></h6>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="subtitle-2 primary-text">{"18 พ.ค. 20 15:43 น."}</div></h6>
                </TableCell>
                <TableCell align="center">
                  <h6><div className="body-2 primary-text">{"฿50,000,000.00"}</div></h6>
                </TableCell>
              </TableRow>
            ))
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )


  render() {
    const RenderTitle = this.renderTitle
    const RenderListBankCard = this.renderListBankCard()
    const RenderTableINACTIVE = this.renderTableINACTIVE()
    const RenderTableBankAuto = this.renderTableBankAuto()
    const RenderTableBankAutoHistory = this.renderTableBankAutoHistory()
    const { page, limit, total } = this.state

    return (
      <>
        <div className="webbank-container">
          <Grid container spacing={3}>
            <RenderTitle />
          </Grid>
          <div className="pb-4">
            <Tap
              id="string"
              listTap={[constants.tapDeposit, constants.tapWithdraw]}
              onClick={this.currentTap}
            >
              <Grid container>
                <Grid item xs={4} className="px-4 pb-4">
                  <h5 className="title-list primary-green-text p-2 mb-1">{constants.bankActive}</h5>
                  {RenderListBankCard}
                </Grid>
                <Grid item xs={8} className="px-4 pb-4">
                  <div className="head-inactive d-flex justify-content-between">
                    <h5 className="title-list primary-text p-2 mb-1">{constants.bankInActive}</h5>
                    <div>
                    <Select
                      label="สถานะ"
                      value={this.state.queryStatus}
                      onChange={(e) => this.onSelectedStatus(e.target.value)}
                      isMultiline={false}
                      itemList={[
                        {
                          value: 'INACTIVE',
                          element: <h6>ยังไม่ได้ใช้งาน</h6>,
                        },
                        {
                          value: 'READY_TO_HARVEST',
                          element: <h6>เต็มวงเงิน</h6>,
                        },
                      ]}
                    />
                    </div>
                  </div>
                    {RenderTableINACTIVE}
                  <TablePagination
                    className="primary-text"
                    component="div"
                    count={total}
                    page={page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage={`${constants.page}:`}
                    labelDisplayedRows={(
                      { from, to, count }) => `${from}-${to} ${constants.of} ${count !== -1 ? count : `more than ${to}`}`}
                  />
                </Grid>
              </Grid>
            </Tap>
          </div>

          <Grid container>
            <Grid item xs={6} className="pr-4">
              <div className="pb-5 tap-list-auto">
                <Tap
                  id="string"
                  listTap={[constants.listBankAuto]}
                  classChildren='limitTable'
                >
                  <div className="box-close d-flex align-items-center justify-content-center">
                    <h6 className="primary-text">ปิดปรับปรุง ชั่วคราว</h6>
                  </div>
                  {RenderTableBankAuto}
                </Tap>
              </div>
            </Grid>
            <Grid item xs={6} className="">
              <div className="pb-5 tap-list-history-auto">
                <Tap
                  id="string"
                  listTap={[constants.listBankAutoHistory]}
                  classChildren='limitTable'
                >
                  <div className="box-close d-flex align-items-center justify-content-center">
                    <h6 className="primary-text">ปิดปรับปรุง ชั่วคราว</h6>
                  </div>
                  {RenderTableBankAutoHistory}
                </Tap>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}
export default Webbank