import all from './all/epics'
import put from './check/epics'
import reserve from './reserve/epics'
import notifications from './notifications/epics'

export default [
  ...all,
  ...put,
  ...reserve,
  ...notifications,
]
