import React from 'react'
import emiter from 'configs/emiter'
import event from 'constants/event'
import { ModalProps } from 'react-bootstrap/Modal'
import Modal from './Modal.component'
import { Success } from './components/Success'
import { Error } from './components/Error'

const success = {
  show: (
    {
      action,
      description,
      title,
      subTitle,
      name,
      money,
      accountNo,
      bank,
      txId,
      txStatus,
      cancelAction,
    }: IDepositSuccessModal) => {
    const modalProps: ModalProps = {
      size: 'sm',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <Success
          title={title}
          subTitle={subTitle}
          description={description}
          action={action}
          cancelAction={cancelAction}
          name={name}
          bank={bank}
          money={money}
          accountNo={accountNo}
          txId={txId}
          txStatus={txStatus}
        />
      ),
    })
  },
  hide: () => emiter.emit(event.MODAL, { state: 'hide' }),
}

const error = {
  show: (
    {
      action,
      description,
      title,
      subTitle,
      name,
      money,
      accountNo,
      bank,
      cancelAction,
      txId,
      txStatus,
    }: IDepositErrorModal) => {
    const modalProps: ModalProps = {
      size: 'sm',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <Error
          title={title}
          subTitle={subTitle}
          description={description}
          action={action}
          cancelAction={cancelAction}
          name={name}
          bank={bank}
          money={money}
          accountNo={accountNo}
          txId={txId}
          txStatus={txStatus}
        />
      ),
    })
  },
  hide: () => emiter.emit(event.MODAL, { state: 'hide' }),
}

export default {
  Core: Modal,
  success,
  error,
}