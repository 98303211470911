import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialWebbankSummaryState, initialChartSummaryState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const webBankSummaryReducer = (state: IWebbankSummaryState = initialWebbankSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getWebbankSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getWebbankSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getWebbankSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const betSummaryReducer = (state: IWebbankSummaryState = initialWebbankSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getBetSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getBetSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getBetSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const memberSummaryReducer = (state: IWebbankSummaryState = initialWebbankSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getMemberSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getMemberSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getMemberSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const financeSummaryReducer = (state: IWebbankSummaryState = initialWebbankSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getFinanceSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getFinanceSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getFinanceSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const betChartSummaryReducer = (state: IWebbankSummaryState = initialChartSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getBetChartSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getBetChartSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getBetChartSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const memberChartSummaryReducer = (state: IWebbankSummaryState = initialChartSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getMemberChartSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getMemberChartSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getMemberChartSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

const financeChartSummaryReducer = (state: IWebbankSummaryState = initialChartSummaryState, action: RootAction):
  IWebbankSummaryState => {
  switch (action.type) {
    case getType(actions.getFinanceChartSummaryAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getFinanceChartSummarySuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        error: action.payload.data.devMessage,
        code: action.payload.data.code,
      }

    case getType(actions.getFinanceChartSummaryFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.code,
      }
    default:
      return state
  }
}

export default combineReducers({
  getWebbankSummary: webBankSummaryReducer,

  getBetSummary: betSummaryReducer,
  getMemberSummary: memberSummaryReducer,
  getFinanceSummary: financeSummaryReducer,
  betChartSummary: betChartSummaryReducer,
  memberChartSummary: memberChartSummaryReducer,
  financeChartSummary: financeChartSummaryReducer,
})