import { createAction } from 'typesafe-actions'
import {
  GET_USER_ALL_REQUEST,
  GET_USER_ALL_SUCCESS,
  GET_USER_ALL_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getUserAllAction = createAction(
  GET_USER_ALL_REQUEST,
  reslove => (data: IGetUserParams) => reslove(data)
)
const getUserAllSuccessAction = createAction(
  GET_USER_ALL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<PaginationResponse<IGetMeState>>>) => resolve(data)
)
const getUserAllFailureAction = createAction(
  GET_USER_ALL_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

export default {
  getUserAllAction,
  getUserAllSuccessAction,
  getUserAllFailureAction,
}