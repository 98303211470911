import React from 'react'
import emiter from 'configs/emiter'
import event from 'constants/event'
import { ModalProps } from 'react-bootstrap/Modal'
import Modal from './Modal.component'
import { Success } from './components/Success'
import { Error } from './components/Error'
import { Accepts } from './components/Accepts'
import { Confirm } from './components/Confirm'
import { TransactionItemCollapsible } from './components/TransactionItemCollapsible'
import { LogCriminal } from './components/LogCriminal'
const success = {
  show: ({ action, actionText, description, title }: ISuccessModal) => {
    const modalProps: ModalProps = {
      size: 'sm',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <Success
          title={title}
          description={description}
          action={action}
          actionText={actionText}
        />
      ),
    })
  },
  hide: () => emiter.emit(event.MODAL, { state: 'hide' }),
}

const error = {
  show: ({ action, actionText, description, title }: IErrorModal) => {
    const modalProps: ModalProps = {
      size: 'sm',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <Error
          title={title}
          description={description}
          action={action}
          actionText={actionText}
        />
      ),
    })
  },
  hide: () => emiter.emit(event.MODAL, { state: 'hide' }),
}

const accepts = {
  show: ({ action, cancelAction, title, twoDown, threeUp, fourSuit, lottoCode, isconfirm }: IAcceptsModal) => {
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: { size: 'md' },
      component: (
        <Accepts
          lottoCode={lottoCode}
          action={action}
          cancelAction={cancelAction}
          title={title}
          threeUp={threeUp}
          twoDown={twoDown}
          fourSuit={fourSuit}
          isconfirm={isconfirm}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}

const confirm = {
  show: ({ action, cancelAction, value, description, child, height, isCancelButton, typeIcon }: IconfirmModal) => {
    const modalProps: ModalProps = {
      size: 'sm',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <Confirm
          action={action}
          cancelAction={cancelAction}
          description={description}
          child={child}
          height={height}
          isCancelButton={isCancelButton}
          typeIcon={typeIcon}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}

const transactionItemCollapsible = {
  show: ({ action, cancelAction, getCreditMedetail, dataStore, title, twoDown, threeUp, fourSuit, ...props }: ITransactionItemCollapsibleModal) => {
    const modalProps: ModalProps = {
      size: 'lg',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <TransactionItemCollapsible
          dataStore={dataStore}
          getCreditMedetail={getCreditMedetail}
          hasMore={props.hasMore}
          creditInfo={props.creditInfo}
          action={action}
          cancelAction={cancelAction}
          money={props.money}
          title={title}
          twoDown={twoDown}
          threeUp={threeUp}
          fourSuit={fourSuit}
          creditName={props.creditName}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}

const transactionItemCollapsibleUpdate = {
  show: ({ action, cancelAction, dataStore, getCreditMedetail,title, twoDown, threeUp, fourSuit, ...props }: ITransactionItemCollapsibleModal) => {
    const modalProps: ModalProps = {
      size: 'lg',
    }
    return emiter.emit(event.MODAL, {
      state: 'update',
      extraProps: modalProps,
      component: (
        <TransactionItemCollapsible
        dataStore={dataStore}
        getCreditMedetail={getCreditMedetail}
          hasMore={props.hasMore}
          creditInfo={props.creditInfo}
          action={action}
          cancelAction={cancelAction}
          money={props.money}
          title={title}
          twoDown={twoDown}
          threeUp={threeUp}
          fourSuit={fourSuit}
          creditName={props.creditName}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}


const logCriminal = {
  show: (props: ILogCriminalProps) => {
    const modalProps: ModalProps = {
      size: 'xl',
    }
    return emiter.emit(event.MODAL, {
      state: 'show',
      extraProps: modalProps,
      component: (
        <LogCriminal
          {...props}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}

const logCriminalUpdate = {
  show: (props: ILogCriminalProps) => {
    const modalProps: ModalProps = {
      size: 'xl',
    }
    return emiter.emit(event.MODAL, {
      state: 'update',
      extraProps: modalProps,
      component: (
        <LogCriminal
          {...props}
        />
      ),
    })
  },
  hide: () => {
    emiter.emit(event.MODAL, { state: 'hide' })
  },
}
const events = (eventName: TEvent, modalProps: IModalStateProps) => {
  return emiter.emits<IModalStateProps>(eventName, modalProps)
}

export default {
  Core: Modal,
  events,
  success,
  error,
  accepts,
  confirm,
  transactionItemCollapsible,
  transactionItemCollapsibleUpdate,
  logCriminal,
  logCriminalUpdate,
}