import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import OverviewContainer from './Overview.container'
import summaryAction from 'reduxs/overview/summary/actions'
import webbankAction from 'reduxs/overview/allWebbank/actions'
import dateTimeStartAction from 'reduxs/user/dateOverviwe/actions'
import loaderAction from 'reduxs/loader/actions'

const mapStateToProps = (state: RootReducers): IOverviewProps => {
    return {
        getWebBankIsFetching: state.mantra.overview.allWebbank.isFetching!,
        getWebBankCode: state.mantra.overview.allWebbank.code!,
        getWebBankError: state.mantra.overview.allWebbank.error!,
        getWebBankResult: state.mantra.overview.allWebbank,

        getWebBankSummaryIsFetching: state.mantra.overview.summary.getWebbankSummary.isFetching!,
        getWebBankSummaryData: state.mantra.overview.summary.getWebbankSummary.data!,
        getWebBankSummaryError:state.mantra.overview.summary.getWebbankSummary.error!,
        getWebBankSummaryCode: state.mantra.overview.summary.getWebbankSummary.code!,

        getBetSummaryIsFetching: state.mantra.overview.summary.getBetSummary.isFetching!,
        getBetSummaryData: state.mantra.overview.summary.getBetSummary.data!,
        getBetSummaryError:state.mantra.overview.summary.getBetSummary.error!,
        getBetSummaryCode: state.mantra.overview.summary.getBetSummary.code!,

        getMemberSummaryIsFetching: state.mantra.overview.summary.getMemberSummary.isFetching!,
        getMemberSummaryData: state.mantra.overview.summary.getMemberSummary.data!,
        getMemberSummaryError:state.mantra.overview.summary.getMemberSummary.error!,
        getMemberSummaryCode: state.mantra.overview.summary.getMemberSummary.code!,

        getFinanceSummaryIsFetching: state.mantra.overview.summary.getFinanceSummary.isFetching!,
        getFinanceSummaryData: state.mantra.overview.summary.getFinanceSummary.data!,
        getFinanceSummaryError:state.mantra.overview.summary.getFinanceSummary.error!,
        getFinanceSummaryCode: state.mantra.overview.summary.getFinanceSummary.code!,

        getBetChartSummaryIsFetching: state.mantra.overview.summary.betChartSummary.isFetching!,
        getBetChartSummaryData: state.mantra.overview.summary.betChartSummary.data!,
        getBetChartSummaryError:state.mantra.overview.summary.betChartSummary.error!,
        getBetChartSummaryCode: state.mantra.overview.summary.betChartSummary.code!,

        getMemberChartSummaryIsFetching: state.mantra.overview.summary.memberChartSummary.isFetching!,
        getMemberChartSummaryData: state.mantra.overview.summary.memberChartSummary.data!,
        getMemberChartSummaryError:state.mantra.overview.summary.memberChartSummary.error!,
        getMemberChartSummaryCode: state.mantra.overview.summary.memberChartSummary.code!,

        getFinanceChartSummaryIsFetching: state.mantra.overview.summary.financeChartSummary.isFetching!,
        getFinanceChartSummaryData: state.mantra.overview.summary.financeChartSummary.data!,
        getFinanceChartSummaryError:state.mantra.overview.summary.financeChartSummary.error!,
        getFinanceChartSummaryCode: state.mantra.overview.summary.financeChartSummary.code!,

        getDateTime: state.mantra.user.dateOverviwe.datetime,
    }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IOverviewActionProps => bindActionCreators({
    getWebBank: (query, isFirst) => webbankAction.getAllWebbankAction(query, isFirst),
    getWebbankSummary : summaryAction.getWebbankSummaryAction,

    getBetSummary : summaryAction.getBetSummaryAction,
    getMemberSummary : summaryAction.getMemberSummaryAction,
    getFinanceSummary : summaryAction.getFinanceSummaryAction,
    getBetChartSummary : summaryAction.getBetChartSummaryAction,
    getMemberChartSummary : summaryAction.getMemberChartSummaryAction,
    getFinanceChartSummary : summaryAction.getFinanceChartSummaryAction,

    changeDateStart : dateTimeStartAction.persistedDateAction,
    
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(OverviewContainer)