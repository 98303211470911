import project from 'constants/project'

export const GET_USER_ALL_REQUEST = 'GET_USER_ALL_REQUEST'
export const GET_USER_ALL_SUCCESS = 'GET_USER_ALL_SUCCESS'
export const GET_USER_ALL_FAILURE = 'GET_USER_ALL_FAILURE'

export const initialState: IGetMeState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getUserAll: `${project.environment[project.environmentName].api}/user/all`,
}