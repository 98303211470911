import project from 'constants/project'

export const POST_RESERVE_TRANSACTION_REQUEST = 'POST_RESERVE_TRANSACTION_REQUEST'
export const POST_RESERVE_TRANSACTION_SUCCESS = 'POST_RESERVE_TRANSACTION_SUCCESS'
export const POST_RESERVE_TRANSACTION_FAILURE = 'POST_RESERVE_TRANSACTION_FAILURE'
export const POST_RESERVE_TRANSACTION_CANCEL = 'POST_RESERVE_TRANSACTION_CANCEL'

export const CANCEL_RESERVE_TRANSACTION_REQUEST = 'CANCEL_RESERVE_TRANSACTION_REQUEST'
export const CANCEL_RESERVE_TRANSACTION_SUCCESS = 'CANCEL_RESERVE_TRANSACTION_SUCCESS'
export const CANCEL_RESERVE_TRANSACTION_FAILURE = 'CANCEL_RESERVE_TRANSACTION_FAILURE'
export const CANCEL_RESERVE_TRANSACTION_CANCEL = 'CANCEL_RESERVE_TRANSACTION_CANCEL'

export const FORCE_CANCEL_RESERVE_TRANSACTION_REQUEST = 'FORCE_CANCEL_RESERVE_TRANSACTION_REQUEST'
export const FORCE_CANCEL_RESERVE_TRANSACTION_SUCCESS = 'FORCE_CANCEL_RESERVE_TRANSACTION_SUCCESS'
export const FORCE_CANCEL_RESERVE_TRANSACTION_FAILURE = 'FORCE_CANCEL_RESERVE_TRANSACTION_FAILURE'
export const FORCE_CANCEL_RESERVE_TRANSACTION_CANCEL = 'FORCE_CANCEL_RESERVE_TRANSACTION_CANCEL'


export const initialState: IReserveTransactionPostState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  postReserveTransaction: `${project.environment[project.environmentName].api}/finance/reserve`,
  deleteReserveTransaction: `${project.environment[project.environmentName].api}/finance/reserve`,
  deleteForceReserveTransaction: `${project.environment[project.environmentName].api}/finance/reserve/force`,
}