import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
  fetchGetWebBankSummary,
  fetchGetBetSummary,
  fetchGetFinanceSummary,
  fetchGetMemberSummary,
  fetchGetBetChartSummary,
  fetchGetFinanceChartSummary,
  fetchGetMemberChartSummary
} from './services'
import actions from './actions'

const webBankSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getWebbankSummaryAction)),
    exhaustMap(_ =>
      from(fetchGetWebBankSummary())
        .pipe(
          map(actions.getWebbankSummarySuccessAction),
          catchError(error => of(actions.getWebbankSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getWebbankSummaryCancelAction))))
        ),
    )
  )

const betSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getBetSummaryAction)),
    exhaustMap(action =>
      from(fetchGetBetSummary(action.payload))
        .pipe(
          map(actions.getBetSummarySuccessAction),
          catchError(error => of(actions.getBetSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getBetSummaryCancelAction))))
        ),
    )
  )

const memberSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getMemberSummaryAction)),
    exhaustMap(action =>
      from(fetchGetMemberSummary(action.payload))
        .pipe(
          map(actions.getMemberSummarySuccessAction),
          catchError(error => of(actions.getMemberSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getMemberSummaryCancelAction))))
        ),
    )
  )

const financeSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getFinanceSummaryAction)),
    exhaustMap(action =>
      from(fetchGetFinanceSummary(action.payload))
        .pipe(
          map(actions.getFinanceSummarySuccessAction),
          catchError(error => of(actions.getFinanceSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getFinanceSummaryCancelAction))))
        ),
    )
  )

const betChartSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getBetChartSummaryAction)),
    exhaustMap(action =>
      from(fetchGetBetChartSummary(action.payload))
        .pipe(
          map(actions.getBetChartSummarySuccessAction),
          catchError(error => of(actions.getBetChartSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getBetChartSummaryCancelAction))))
        ),
    )
  )

const memberChartSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getMemberChartSummaryAction)),
    exhaustMap(action =>
      from(fetchGetMemberChartSummary(action.payload))
        .pipe(
          map(actions.getMemberChartSummarySuccessAction),
          catchError(error => of(actions.getMemberChartSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getMemberChartSummaryCancelAction))))
        ),
    )
  )

const financeChartSummaryEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.getFinanceChartSummaryAction)),
    exhaustMap(action =>
      from(fetchGetFinanceChartSummary(action.payload))
        .pipe(
          map(actions.getFinanceChartSummarySuccessAction),
          catchError(error => of(actions.getFinanceChartSummaryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getFinanceChartSummaryCancelAction))))
        ),
    )
  )

export default [
  webBankSummaryEpic,
  memberSummaryEpic,
  betSummaryEpic,
  financeSummaryEpic,
  betChartSummaryEpic,
  memberChartSummaryEpic,
  financeChartSummaryEpic
]
