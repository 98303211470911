import pages from 'pages'

const routes: IRoutes[] = [
  {
    exact: true,
    name: 'login',
    path: '/',
    component: pages.Home,
  },
  {
    private: true,
    name: 'settings',
    path: '/settings',
    component: pages.Settings,
  },
  {
    private: true,
    name: 'webbank',
    path: '/webbank',
    component: pages.Webbank,
  },
  {
    private: true,
    name: 'add-account',
    path: '/add-account',
    component: pages.CreateWebBank,
  },
  {
    private: true,
    name: 'edit-webbank',
    path: '/edit-webbank',
    component: pages.EditWebBank,
  },
  {
    private: true,
    name: 'delete-webbank',
    path: '/delete-webbank',
    component: pages.DeleteWebBank,
  },
  {
    private: true,
    name: 'transaction/deposit',
    path: '/transaction/deposit',
    component: pages.DepositTransaction,
  },
  {
    exact:true,
    private: true,
    name: 'transaction/withdraw',
    path: '/transaction/withdraw',
    component: pages.Withdraw,
  },
  {
    private: true,
    name: 'verify',
    path: '/verify',
    component: pages.Verify,
  },
  {
    private: true,
    name: 'news',
    path: '/news',
    component: pages.News,
  },
  {
    private: true,
    name: 'web-config',
    path: '/web-config',
    component: pages.WebConfig,
  },
  {
    private: true,
    name: 'broadcast',
    path: '/broadcast',
    component: pages.Broadcast,
  },
  {
    private: true,
    name: 'affilate',
    path: '/affilate',
    component: pages.Affilate,
  },
  {
    private: true,
    name: 'summary-dashboard',
    path: '/summary-dashboard',
    component: pages.Dashboard,
  },
  {
    private: true,
    name: 'content',
    path: '/content',
    component: pages.Content,
  },
  {
    private: true,
    name: 'admin-management',
    path: '/admin-management',
    component: pages.AdminManagement,
  },
  {
    private: true,
    name: 'admin-management-create',
    path: '/admin-management-create',
    component: pages.CreateAdmin,
  },
  {
    private: true,
    name: 'admin-management-edit',
    path: '/admin-management-edit',
    component: pages.EditAdmin,
  },
  {
    private: true,
    name: 'admin-management-delete',
    path: '/admin-management-delete',
    component: pages.DeleteAdmin,
  },
  {
    private: true,
    name: 'staff-management',
    path: '/staff-management',
    component: pages.StaffManagement,
  },
  {
    private: true,
    name: 'staff-management-create',
    path: '/staff-management-create',
    component: pages.CreateStaff,
  },
  {
    private: true,
    name: 'staff-management-edit',
    path: '/staff-management-edit',
    component: pages.EditStaff,
  },
  {
    private: true,
    name: 'staff-management-delete',
    path: '/staff-management-delete',
    component: pages.DeleteStaff,
  },
  {
    private: true,
    name: 'user-management',
    path: '/user-management',
    component: pages.UserManagement,
  },
  {
    private: true,
    name: 'user-management-create',
    path: '/user-management-create',
    component: pages.CreateUser,
  },
  {
    private: true,
    name: 'user-management-edit',
    path: '/user-management-edit',
    component: pages.EditUser,
  },
  {
    private: true,
    name: 'user-management-delete',
    path: '/user-management-delete',
    component: pages.DeleteUser,
  },
  {
    private: true,
    name: 'lotter-master-management',
    path: '/lotter-master-management',
    component: pages.LottoMasterManagement,
  },
  {
    private: true,
    name: 'lotter-master-management-create',
    path: '/lotter-master-management-create',
    component: pages.CreateMaster,
  },
  {
    private: true,
    name: 'lotter-master-management-edit',
    path: '/lotter-master-management-edit',
    component: pages.EditMaster,
  },
  {
    private: true,
    name: 'lotter-master-management-delete',
    path: '/lotter-master-management-delete',
    component: pages.DeleteMaster,
  },
  {
    private: true,
    name: 'line-user-management',
    path: '/line-user-management',
    component: pages.LineManagement,
  },
  {
    private: true,
    name: 'report-yegee',
    path: '/report-yegee',
    component: pages.ReportSummary,
  },
  {
    private: true,
    name: 'lotto',
    path: '/lotto',
    component: pages.LottoList,
  },
  {
    private: true,
    name: 'lotto-rate',
    path: '/lotto-rate',
    component: pages.LottoRate,
  },
  {
    private: true,
    name: 'number-rate',
    path: '/number-rate',
    component: pages.NumberRate,
  },
  {
    private: true,
    name: 'lotto-cancel',
    path: '/lotto-cancel',
    component: pages.LottoCancel,
  },
  {
    private: true,
    name: 'report-benefit',
    path: "/report-benefit",
    component: pages.ReportBenefit,
  },
  {
    private: true,
    name: 'daily-report',
    path: '/daily-report',
    component: pages.DailyReport,
  },
  {
    private: true,
    name: 'criminal-view',
    path: '/criminal-view',
    component: pages.CriminalView,
  },
]

export default routes