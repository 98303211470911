import React, { Component } from 'react'
import { noop, trim } from 'lodash'
import { Formik, FormikProps } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { responseCode } from 'constants/response'
import { Modal } from 'components'
import { LoginForm } from './components'
import initialValues from './models/initialValues'
import scheme from './models/scheme'
import './home.style.scss'
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core'
import BankImageSet from 'assets/images/home/loginImage.jpg'
import jwt from 'jsonwebtoken'
import { JWT } from 'constants/jwt'
import { USER } from 'constants/user'
import project from 'constants/project'
import CryptoJS from 'crypto-js'

const constants = {
  ok: 'ตกลง',
  login: 'เข้าสู่ระบบ',
  sign: 'ลงชื่อเข้าใช้',
  wrongUsernameTitle: 'รหัสผ่านไม่ถูกต้อง',
  wrongUsernameDescription: 'โปรดกรอกรหัสผ่านให้ถูกต้อง',
  canNotAccessTitle: 'ไม่สามารถเข้าสู่ระบบได้',
  canNotAccessDescription: 'โปรดติดต่อเจ้าหน้าที่เพื่อรับสิทธิเข้าใช้งาน',
  management: 'ระบบจัดการ Thailand Bet',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IHomeProps & IHomeActionProps = {
  login() { noop() },
  loginResult: [],
  loginCode: 0,
  loginError: '',
  loginIsFetching: false,
  loader() { noop() },
}

class HomeContainer extends Component<IHomeProps & IHomeActionProps & DefaultProps & RouteComponentProps, {}> {

  static defaultProps = defaultProps

  componentDidUpdate(prevProps: IHomeProps) {
    if (prevProps.loginIsFetching !== this.props.loginIsFetching && !this.props.loginIsFetching) {
      this.props.loader(false)
      if (this.props.loginCode === responseCode.OK) {
        if (this.isCanAccess()) {
          this.props.history.replace('/summary-dashboard')
        }
      }
      else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.wrongUsernameDescription,
          actionText: constants.wrongUsernameTitle,
        })
      }
    }
  }

  isCanAccess = (): boolean => {
    let decodedResult: ITokenData
    try {
      decodedResult = jwt.verify(this.props.loginResult.token,
        project.environment[project.environmentName].jwtSecretKey) as ITokenData
   
      if (decodedResult.permission === USER.PERMISSON.STAFF
        || decodedResult.permission === USER.PERMISSON.ADMIN
        || decodedResult.permission === USER.PERMISSON.SUPER_ADMIN
      ) {
        return true
      } else {
        Modal.error.show({
          action: Modal.error.hide,
          description: constants.canNotAccessDescription,
          actionText: constants.canNotAccessTitle,
        })
      }
    } catch (err) {
      if (err.name === JWT.ERROR.JWT_ERROR) {
        switch (err.message) {
          case JWT.ERROR.TOKEN_EXPIRE:
          case JWT.ERROR.INVALID_SIGNATURE:
          case JWT.ERROR.JWT_NOT_PROVIDED:
          default:
            Modal.error.show({
              action: Modal.error.hide,
              description: constants.canNotAccessDescription,
              actionText: constants.canNotAccessTitle,
            })
        }
      }
    }
    return false
  }

  onSubmitLogin = (values: ILogin) => {
    this.props.loader(true)
    const encryptPassword =
      CryptoJS.AES.encrypt(
        values.password,
        project.environment[project.environmentName].aesSecretKey
      ).toString()
    const loginObject: ILogin = {
      username: trim(values.username),
      password: trim(encryptPassword),
      remember: values.remember,
    }
    this.props.login(loginObject)
  }
  onNavigateToForgotPassword = () => {
    // this.props.history.replace('/register')
  }

  renderLoginForm = () => {
    const LoginFormComponent = (formProps: FormikProps<ILogin>) => {
      return (
        <LoginForm
          onNavigateToForgotPassword={this.onNavigateToForgotPassword}
          {...formProps}
        />
      )
    }
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={this.onSubmitLogin}
      >
        {LoginFormComponent}
      </Formik>
    )
  }

  render() {
    const RenderLoginFormComponent = this.renderLoginForm
    return (
      <div className="home-container primary-dark">
        <Card className="secondary-dark">
          <Grid container key={'home-login'}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div className="login-container">
                <div className="container  padding">
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    <div> <h2>{constants.sign}</h2></div>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    <div className="management">{constants.management}</div>
                  </div>
                  <RenderLoginFormComponent />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div className="img">
                <img height="100%" width="100%" alt="Webbank" src={BankImageSet} />
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    )
  }
}

export default HomeContainer