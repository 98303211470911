import React from 'react'

import './TitlePage.component.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITitlePageProps = {
    title: '',
    className: '',
}

function TitlePageComponent(props: ITitlePageProps & DefaultProps) {
    const {
        title,
        className,
    } = props

    return (
        <div className={`title-page-container ${className}`}>
            <h3 className={className}>{title}</h3>
        </div>
    )
}

export default TitlePageComponent