import project from 'constants/project'

export const GET_REPORT_OVERVIEW_REQUEST = 'GET_REPORT_OVERVIEW_REQUEST'
export const GET_REPORT_OVERVIEW_SUCCESS = 'GET_REPORT_OVERVIEW_SUCCESS'
export const GET_REPORT_OVERVIEW_FAILURE = 'GET_REPORT_OVERVIEW_FAILURE'
export const GET_REPORT_OVERVIEW_CANCEL = 'GET_REPORT_OVERVIEW_CANCEL'

export const GET_REPORT_YEGEE_REQUEST = 'GET_REPORT_YEGEE_REQUEST'
export const GET_REPORT_YEGEE_SUCCESS = 'GET_REPORT_YEGEE_SUCCESS'
export const GET_REPORT_YEGEE_FAILURE = 'GET_REPORT_YEGEE_FAILURE'
export const GET_REPORT_YEGEE_CANCEL = 'GET_REPORT_YEGEE_CANCEL'

export const GET_REPORT_LOTTER_NUMBER_SESSION_REQUEST = 'GET_REPORT_LOTTER_NUMBER_SESSION_REQUEST'
export const GET_REPORT_LOTTER_NUMBER_SESSION_SUCCESS = 'GET_REPORT_LOTTER_NUMBER_SESSION_SUCCESS'
export const GET_REPORT_LOTTER_NUMBER_SESSION_FAILURE = 'GET_REPORT_LOTTER_NUMBER_SESSION_FAILURE'
export const GET_REPORT_LOTTER_NUMBER_SESSION_CANCEL = 'GET_REPORT_LOTTER_NUMBER_SESSION_CANCEL'

export const POST_REPORT_DEPOSIT_WITHDRAW_REQUEST = 'POST_REPORT_DEPOSIT_WITHDRAW_REQUEST'
export const POST_REPORT_DEPOSIT_WITHDRAW_SUCCESS = 'POST_REPORT_DEPOSIT_WITHDRAW_SUCCESS'
export const POST_REPORT_DEPOSIT_WITHDRAW_FAILURE = 'POST_REPORT_DEPOSIT_WITHDRAW_FAILURE'
export const POST_REPORT_DEPOSIT_WITHDRAW_CANCEL = 'POST_REPORT_DEPOSIT_WITHDRAW_CANCEL'

export const GET_REPORT_BENEFIT_REQUEST = 'GET_REPORT_BENEFIT_REQUEST'
export const GET_REPORT_BENEFIT_SUCCESS = 'GET_REPORT_BENEFIT_SUCCESS'
export const GET_REPORT_BENEFIT_FAILURE = 'GET_REPORT_BENEFIT_FAILURE'
export const GET_REPORT_BENEFIT_CANCEL = 'GET_REPORT_BENEFIT_CANCEL'
export const CLEAR_REPORT_BENEFIT = 'CLEAR_REPORT_BENEFIT'

export const GET_REPORT_BENEFIT_LOTTERY_REQUEST = 'GET_REPORT_BENEFIT_LOTTERY_REQUEST'
export const GET_REPORT_BENEFIT_LOTTERY_SUCCESS = 'GET_REPORT_BENEFIT_LOTTERY_SUCCESS'
export const GET_REPORT_BENEFIT_LOTTERY_FAILURE = 'GET_REPORT_BENEFIT_LOTTERY_FAILURE'
export const GET_REPORT_BENEFIT_LOTTERY_CANCEL = 'GET_REPORT_BENEFIT_LOTTERY_CANCEL'
export const CLEAR_REPORT_BENEFIT_LOTTERY = 'CLEAR_REPORT_BENEFIT_LOTTERY'

export const GET_REPORT_BENEFIT_EXTERNAL_CASINO_REQUEST = 'GET_REPORT_BENEFIT_EXTERNAL_CASINO_REQUEST'
export const GET_REPORT_BENEFIT_EXTERNAL_CASINO_SUCCESS = 'GET_REPORT_BENEFIT_EXTERNAL_CASINO_SUCCESS'
export const GET_REPORT_BENEFIT_EXTERNAL_CASINO_FAILURE = 'GET_REPORT_BENEFIT_EXTERNAL_CASINO_FAILURE'
export const GET_REPORT_BENEFIT_EXTERNAL_CASINO_CANCEL = 'GET_REPORT_BENEFIT_EXTERNAL_CASINO_CANCEL'
export const CLEAR_REPORT_BENEFIT_EXTERNAL_CASINO = 'CLEAR_REPORT_BENEFIT_EXTERNAL_CASINO'

export const GET_REPORT_BENEFIT_EXTERNAL_SLOT_REQUEST = 'GET_REPORT_BENEFIT_EXTERNAL_SLOT_REQUEST'
export const GET_REPORT_BENEFIT_EXTERNAL_SLOT_SUCCESS = 'GET_REPORT_BENEFIT_EXTERNAL_SLOT_SUCCESS'
export const GET_REPORT_BENEFIT_EXTERNAL_SLOT_FAILURE = 'GET_REPORT_BENEFIT_EXTERNAL_SLOT_FAILURE'
export const GET_REPORT_BENEFIT_EXTERNAL_SLOT_CANCEL = 'GET_REPORT_BENEFIT_EXTERNAL_SLOT_CANCEL'
export const CLEAR_REPORT_BENEFIT_EXTERNAL_SLOT = 'CLEAR_REPORT_BENEFIT_EXTERNAL_SLOT'

export const initialState: IReportYegeeReduxState = {
    isFetching: false,
    code: 0,
    data: {},
    error: '',
}

export const initialStateLotterNumberSession: IReportLotterNumberSessionReduxState = {
    isFetching: false,
    code: 0,
    data: [{
        number: '',
        totalBet: 0,
        result: 0,
        rateLevel: {
            id: 0,
            level: 0,
            volumeLimit: 0,
            rate: 0,
            code: '',
            type: '',
            createdAt: '',
            updatedAt: '',
        },
    }],
    error: '',
}

export const initialStateDepositWithdraw: IReportDepositWithdrawReduxState = {
    isFetching: false,
    code: 0,
    data: [{
        type: '',
        amount: 0,
    }],
    error: '',
}

export const initialStateReportBenefit: IReportBenefitReduxState<IResponseReportBenefit> = {
    isFetching: false,
    code: 0,
    data: {
        loss: 0,
        profit: 0,
        total: 0,
    },
    error: '',
}

export const initialStateReportBenefitLottery: IReportBenefitLotteryReduxState = {
    isFetching: false,
    code: 0,
    data: {
        loss: 0,
        profit: 0,
        total: 0,
    },
    error: '',
}

export const initialStateReportBenefitExternalCasino: IReportBenefitExternalCasinoReduxState = {
    isFetching: false,
    code: 0,
    data: {
        loss: 0,
        profit: 0,
        total: 0,
    },
    error: '',
}

export const initialStateReportBenefitExternalSlot: IReportBenefitExternalSlotReduxState = {
    isFetching: false,
    code: 0,
    data: {
        loss: 0,
        profit: 0,
        total: 0,
    },
    error: '',
}

export const endpoint = {
    getReportOverview: `${project.environment[project.environmentName].api}/report/overview`,
    getReportYegee: `${project.environment[project.environmentName].api}/report/yegee`,
    getReportLotterNumberSession: `${project.environment[project.environmentName].api}/report/lotter-session`,
    postReportDepositWithdraw: `${project.environment[project.environmentName].api}/report/deposit-withdraw`,
    getReportBenefit: `${project.environment[project.environmentName].api}/report/benefit`,
    getReportBenefitLottery: `${project.environment[project.environmentName].api}/report/benefit/lottery`,
    getReportBenefitExternalCasino: `${project.environment[project.environmentName].api}/report/benefit/external-casino`,
    getReportBenefitExternalSlot: `${project.environment[project.environmentName].api}/report/benefit/external-slot`,
}