import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import userAllAction from 'reduxs/user/all/actions'
import LottoMasterManagement from './LottoMasterManagement.container'

const mapStateToProps = (state: RootReducers): IUserManagementProps => ({
    getUserAllIsFetching: state.mantra.user.userAll.isFetching!,
    getUserAllError: state.mantra.user.userAll.error!,
    getUserAllCode: state.mantra.user.userAll.code!,
    getUserAllResult: state.mantra.user.userAll.data!,
    getUserAllLimit: state.mantra.user.userAll.limit!,
    getUserAllPage: state.mantra.user.userAll.page!,
    getUserAllTotal: state.mantra.user.userAll.total!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IUserManagementActionProps => bindActionCreators({
    getUserAll: userAllAction.getUserAllAction,
    loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoMasterManagement)