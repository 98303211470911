import KBANK from './KBANK.png'
import BAY from './BAY.png'
import KTB from './KTB.png'
import SCB from './SCB.png'
import BBL from './BBL.png'
import KKP from './KKP.png'
import CIMBT from './CIMBT.png'
import TISCO from './TISCO.png'
import UOBT from './UOBT.png'
import TMB from './TMB.png'
import TBANK from './TBANK.png'
import TCD from './TCD.png'
import ICBCT from './ICBCT.png'
import GSB from './GSB.png'
import ISBT from './ISBT.png'
import BAAC from './BAAC.png'
import GHB from './GHB.png'
import TTB from './TTB.png'
export const BANK_NAME = {
  BBL: 'ธนาคารกรุงเทพ',
  KBANK: 'ธนาคารกสิกร',
  KTB: 'ธนาคารกรุงไทย',
  TMB: 'ธนาคารทหารไทย',
  SCB: 'ธนาคารไทยพาณิชย์',
  BAY: 'ธนาคารกรุงศรีอยุธยา',
  KKP: 'ธนาคารเกียรตินาคิน',
  CIMBT: 'ธนาคารซีไอเอ็มบีไทย',
  TISCO: 'ธนาคารทิสโก้',
  TBANK: 'ธนาคารธนชาต',
  UOBT: 'ธนาคารยูโอบี',
  TCD: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
  ICBCT: 'ธนาคารไอซีบีซี (ไทย)',
  GSB: 'ธนาคารออมสิน',
  ISBT: 'ธนาคารอิสลามแห่งประเทศไทย',
  BAAC: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  GHB: 'ธนาคารอาคารสงเคราะห์',
  TTB: 'ทีเอ็มบีธนชาต',
}

const collectBank: IImageSet = {
  BAY: {
    Icon: BAY,
    key: 'BAY',
    name: BANK_NAME.BAY,
  },
  BBL: {
    Icon: BBL,
    key: 'BBL',
    name: BANK_NAME.BBL,
  },
  KBANK: {
    Icon: KBANK,
    key: 'KBANK',
    name: BANK_NAME.KBANK,
  },
  KTB: {
    Icon: KTB,
    key: 'KTB',
    name: BANK_NAME.KTB,
  },
  TMB: {
    Icon: TMB,
    key: 'TMB',
    name: BANK_NAME.TMB,
  },
  SCB: {
    Icon: SCB,
    key: 'SCB',
    name: BANK_NAME.SCB,
  },
  KKP: {
    Icon: KKP,
    key: 'KKP',
    name: BANK_NAME.KKP,
  },
  CIMBT: {
    Icon: CIMBT,
    key: 'CIMBT',
    name: BANK_NAME.CIMBT,
  },
  TISCO: {
    Icon: TISCO,
    key: 'TISCO',
    name: BANK_NAME.TISCO,
  },
  TBANK: {
    Icon: TBANK,
    key: 'TBANK',
    name: BANK_NAME.TBANK,
  },
  UOBT: {
    Icon: UOBT,
    key: 'UOBT',
    name: BANK_NAME.UOBT,
  },
  TCD: {
    Icon: TCD,
    key: 'TCD',
    name: BANK_NAME.TCD,
  },
  ICBCT: {
    Icon: ICBCT,
    key: 'ICBCT',
    name: BANK_NAME.ICBCT,
  },
  GSB: {
    Icon: GSB,
    key: 'GSB',
    name: BANK_NAME.GSB,
  },
  ISBT: {
    Icon: ISBT,
    key: 'ISBT',
    name: BANK_NAME.ISBT,
  },
  BAAC: {
    Icon: BAAC,
    key: 'BAAC',
    name: BANK_NAME.BAAC,
  },
  GHB: {
    Icon: GHB,
    key: 'GHB',
    name: BANK_NAME.GHB,
  },
  TTB: {
    Icon: TTB,
    key: 'TTB',
    name: BANK_NAME.TTB,
  },
}

export default collectBank