import { createAction } from 'typesafe-actions'
import {
    GET_NEWS_REQUEST,
    GET_NEWS_CANCEL,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILURE,

    POST_NEWS_REQUEST,
    POST_NEWS_CANCEL,
    POST_NEWS_SUCCESS,
    POST_NEWS_FAILURE,

    PUT_NEWS_REQUEST,
    PUT_NEWS_CANCEL,
    PUT_NEWS_SUCCESS,
    PUT_NEWS_FAILURE,

    DELETE_NEWS_REQUEST,
    DELETE_NEWS_CANCEL,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const getNewsAction = createAction(GET_NEWS_REQUEST,
)

const getNewsSuccessAction = createAction(
    GET_NEWS_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<INews>>) => resolve(data))

const getNewsFailureAction = createAction(
    GET_NEWS_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const getNewsCancelAction = createAction(GET_NEWS_CANCEL)

const postNewsAction = createAction(POST_NEWS_REQUEST,
    reslove => (data: INewsPostData) => reslove(data)
)

const postNewsSuccessAction = createAction(
    POST_NEWS_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<INews>>) => resolve(data))

const postNewsFailureAction = createAction(
    POST_NEWS_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const postNewsCancelAction = createAction(POST_NEWS_CANCEL)

const putNewsAction = createAction(PUT_NEWS_REQUEST,
    reslove => (data: INewsPutData) => reslove(data)
)

const putNewsSuccessAction = createAction(
    PUT_NEWS_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<INews>>) => resolve(data))

const putNewsFailureAction = createAction(
    PUT_NEWS_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const putNewsCancelAction = createAction(PUT_NEWS_CANCEL)

const deleteNewsAction = createAction(DELETE_NEWS_REQUEST,
    reslove => (data: INewsPutData) => reslove(data)
)

const deleteNewsSuccessAction = createAction(
    DELETE_NEWS_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<INews>>) => resolve(data))

const deleteNewsFailureAction = createAction(
    DELETE_NEWS_FAILURE,
    resolve => (error: AxiosError) => resolve(error))

const deleteNewsCancelAction = createAction(DELETE_NEWS_CANCEL)


export default {
    getNewsAction,
    getNewsSuccessAction,
    getNewsFailureAction,
    getNewsCancelAction,

    postNewsAction,
    postNewsSuccessAction,
    postNewsFailureAction,
    postNewsCancelAction,

    putNewsAction,
    putNewsSuccessAction,
    putNewsFailureAction,
    putNewsCancelAction,

    deleteNewsAction,
    deleteNewsSuccessAction,
    deleteNewsFailureAction,
    deleteNewsCancelAction,
}