import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchPostUser } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const userPostEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.postUserAction)),
    exhaustMap(action =>
      from(fetchPostUser(action.payload))
        .pipe(
          map(actions.postUserSuccessAction),
          catchError(error => of(actions.postUserFailureAction(error))),
        ),
    )
  )

export default [
  userPostEpic,
]
