import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    Grid,
    Paper,
} from '@material-ui/core'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb,
} from 'components'
import PhoneIcon from '@material-ui/icons/Phone'
import ContactsIcon from '@material-ui/icons/Contacts'
import PublicIcon from '@material-ui/icons/Public'
import { noop } from 'lodash'
import './WebConfig.container.scss'
import { responseCode } from 'constants/response'


const constants = {
    webLabel: 'หน้าเว็บ',
    settingLabel: 'ตั้งค่าระบบ',
  }

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IWebConfigContainerProps & IWebConfigContainerActionProps = {
    getWebConfig() { noop() },
    getWebConfigCode: 0,
    getWebConfigError: '',
    getWebConfigIsFetching: false,
    getWebConfigResult: {
        id: 0,
        textRunner: '',
        contactUrl: '',
        contactLine: '',
        contactPhoneNumber: '',
        updatedAt: '',
    },
    putWebConfig() { noop() },
    putWebConfigCode: 0,
    putWebConfigError: '',
    putWebConfigIsFetching: false,
    putWebConfigResult: {
        id: 0,
        textRunner: '',
        contactUrl: '',
        contactLine: '',
        contactPhoneNumber: '',
        updatedAt: '',
    },
    loader() { noop() },
}

class WebConfigContainer extends Component
<IWebConfigContainerProps & IWebConfigContainerActionProps & DefaultProps & RouteComponentProps, IWebConfigContainerState> {

    static defaultProps = defaultProps

    constructor(props: IWebConfigContainerProps & IWebConfigContainerActionProps & DefaultProps & RouteComponentProps) {
        super(props)
        this.state = {
            textRunner: '',
            contactUrl: '',
            contactLine: '',
            contactPhoneNumber: '',
            updatedAt: '',
        }
    }

    componentDidMount() {
        this.props.getWebConfig()
        this.props.loader(true)
    }

    componentDidUpdate(prevProps: IWebConfigContainerProps) {
        if (prevProps.getWebConfigIsFetching !== this.props.getWebConfigIsFetching
            && !this.props.getWebConfigIsFetching) {
            this.props.loader(false)
            if (this.props.getWebConfigCode === responseCode.OK) {
                this.setState({
                    textRunner: this.props.getWebConfigResult!.textRunner,
                    contactUrl: this.props.getWebConfigResult!.contactUrl,
                    contactLine: this.props.getWebConfigResult!.contactLine,
                    contactPhoneNumber: this.props.getWebConfigResult!.contactPhoneNumber,
                    updatedAt: this.props.getWebConfigResult!.updatedAt,
                })
            }
        }
        if (prevProps.putWebConfigIsFetching !== this.props.putWebConfigIsFetching
            && !this.props.putWebConfigIsFetching) {
            this.props.loader(false)
        }
    }

    onInsertContact = () => {
        this.props.loader(true)
        this.props.putWebConfig({
            text_runner: this.state.textRunner,
            contact_url: this.state.contactUrl,
            contact_line: this.state.contactLine,
            contact_phone_number: this.state.contactPhoneNumber,
        })
    }

    onInsertBoardcast = () => {
        this.props.loader(true)
        this.props.putWebConfig({
            text_runner: this.state.textRunner,
            contact_url: this.state.contactUrl,
            contact_line: this.state.contactLine,
            contact_phone_number: this.state.contactPhoneNumber,
        })
    }

    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
      }

    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.settingLabel },
            { label: constants.webLabel, active: true },
          ]
        return (
            <div className="web-config-container">
                  <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title="หน้าเว็บ" />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">จัดการประชาสัมพันธ์</div>
                            <div className="paper-body">
                                <TextField
                                    placeholder="กรุณากรอกตัวอักษรวิ่งหน้าเว็บ *"
                                    fullWidth={true}
                                    label="ตัวอักษรวิ่งหน้าเว็บ"
                                    multiline={true}
                                    rows={4}
                                    value={this.state.textRunner}
                                    onChange={(e) => this.setState({ textRunner: e.target.value })}
                                />
                            </div>
                            <div className="paper-bottom">
                                <Button
                                    text="บันทึกประชาสัมพันธ์"
                                    fullWidth={true}
                                    onClick={this.onInsertBoardcast}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">จัดการข้อมูลติดต่อ</div>
                            <div className="paper-body">
                                <TextField
                                    placeholder="กรุณากรอกเว็บไซต์ติดต่อ *"
                                    fullWidth={true}
                                    label="เว็บไซต์ติดต่อ"
                                    startElement={<PublicIcon />}
                                    value={this.state.contactUrl}
                                    onChange={(e) => this.setState({ contactUrl: e.target.value })}
                                />
                                <TextField
                                    placeholder="กรุณากรอกไลน์ติดต่อ *"
                                    fullWidth={true}
                                    label="ไลน์ติดต่อ"
                                    startElement={<ContactsIcon />}
                                    value={this.state.contactLine}
                                    onChange={(e) => this.setState({ contactLine: e.target.value })}
                                />
                                <TextField
                                    placeholder="กรุณากรอกเบอร์ติดต่อ *"
                                    fullWidth={true}
                                    label="เบอร์ติดต่อ"
                                    startElement={<PhoneIcon />}
                                    value={this.state.contactPhoneNumber}
                                    onChange={(e) => this.setState({ contactPhoneNumber: e.target.value })}
                                />
                            </div>
                            <div className="paper-bottom">
                                <Button
                                    text="บันทึกข้อมูลติดต่อ"
                                    fullWidth={true}
                                    onClick={this.onInsertContact}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default WebConfigContainer