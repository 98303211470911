import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import { combineReducers } from 'redux'
import actions from './actions'

const menuReducers = (state: IMenuActiveControl = initialState, action: RootAction): IMenuActiveControl => {
  switch (action.type) {
    case getType(actions.onChangeMenuAction):
      return {
        menu: action.payload.menu as IMenuList,
        activeRoleManagement: action.payload.activeRoleManagement,
        childMenu: action.payload.childMenu,
      }
    case getType(actions.clearMenu):
      return initialState
    default:
      return state
  }
}
export default combineReducers({
  activeControl: menuReducers,
})