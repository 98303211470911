const initialValue: IRegister = {
  phoneNumber: '',
  username: '',
  password: '',
  confirmPassword: '',
  affilateNumber: '',
  bankNumber: '',
  name: '',
  number: '',
}

const initialValueEditUser: IEditUser = {
  phoneNumber: '',
  password: '',
  bankCode: '',
  name: '',
  number: '',
}

export {
  initialValue,
  initialValueEditUser,
}