import { createAction } from 'typesafe-actions'
import {
  GET_WEBBANK_RATE_REQUEST,
  GET_WEBBANK_RATE_SUCCESS,
  GET_WEBBANK_RATE_FAILURE,
  GET_WEBBANK_RATE_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getWebbankRateAction = createAction(GET_WEBBANK_RATE_REQUEST)


const getWebbankRateSuccessAction = createAction(
  GET_WEBBANK_RATE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IRate>>) => resolve(data))

const getWebbankRateFailureAction = createAction(
  GET_WEBBANK_RATE_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getBetRateCancelAction = createAction(GET_WEBBANK_RATE_CANCEL)


export default {
  getWebbankRateAction,
  getWebbankRateSuccessAction,
  getWebbankRateFailureAction,
  getBetRateCancelAction,
}