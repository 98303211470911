import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    TitlePage,
    TextField,
    Button,
    Breadcrumb,
} from 'components'
import {
    Grid,
    Paper,
    IconButton,
} from '@material-ui/core'
import { responseCode } from 'constants/response'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/ArrowBackIos'
import { date } from 'utils'
import { noop } from 'lodash'

import './News.container.scss'

const constants = {
    newsLabel: 'ข่าวสาร',
    systemLabel: 'จัดการระบบ',
  }

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: INewsContainerProps & INewsContainerActionProps = {
    getNewsAll() { noop() },
    getNewsAllCode: 0,
    getNewsAllError: '',
    getNewsAllIsFetching: false,
    getNewsAllResult: [],
    postNews() { noop() },
    postNewsCode: 0,
    postNewsError: '',
    postNewsIsFetching: false,
    postNewsResult: [],
    putNews() { noop() },
    putNewsCode: 0,
    putNewsError: '',
    putNewsIsFetching: false,
    putNewsResult: [],
    deleteNews() { noop() },
    deleteNewsCode: 0,
    deleteNewsError: '',
    deleteNewsIsFetching: false,
    deleteNewsResult: [],
    loader() { noop() },
}

class NewsContainer extends Component
    <INewsContainerProps & INewsContainerActionProps & DefaultProps & RouteComponentProps, INewsContainerState> {

    static defaultProps = defaultProps

    constructor(props: INewsContainerProps & INewsContainerActionProps & DefaultProps & RouteComponentProps) {
        super(props)
        this.state = {
            newsList: [],
            onEdit: false,
            editTitle: '',
            editDescription: '',
            insertTitle: '',
            insertDescription: '',
        }
    }

    componentDidMount() {
        this.props.getNewsAll()
        this.props.loader(true)
    }

    componentDidUpdate(prevProps: INewsContainerProps) {
        if (prevProps.getNewsAllIsFetching !== this.props.getNewsAllIsFetching
            && !this.props.getNewsAllIsFetching) {
            this.props.loader(false)
            this.setState({ newsList: this.addUIParameter(this.props.getNewsAllResult) })
        }
        if (prevProps.postNewsIsFetching !== this.props.postNewsIsFetching
            && !this.props.postNewsIsFetching) {
            this.props.getNewsAll()
            if (this.props.postNewsCode === responseCode.OK) {
                this.setState({
                    insertTitle: '',
                    insertDescription: '',
                })
            } else {
                // TODO error
            }
        }
        if (prevProps.putNewsIsFetching !== this.props.putNewsIsFetching
            && !this.props.putNewsIsFetching) {
            if (this.props.postNewsCode === responseCode.OK) {
                this.setState({
                    onEdit: !this.state.onEdit,
                    editTitle: '',
                    editDescription: '',
                })
                this.props.getNewsAll()
            } else {
                this.props.loader(false)
                // TODO error
            }
        }
        if (prevProps.deleteNewsIsFetching !== this.props.deleteNewsIsFetching
            && !this.props.deleteNewsIsFetching) {
            if (this.props.deleteNewsCode === responseCode.OK) {
                this.props.getNewsAll()
            } else {
                this.props.loader(false)
                // TODO error
            }
        }
    }

    addUIParameter = (list: any[]) => {
        return list.map((item) => { return { ...item, isEdit: false } })
    }

    onInsertNews = () => {
        this.props.postNews({
            title: this.state.insertTitle,
            description: this.state.insertDescription,
        })
        this.props.loader(true)
    }

    onHandleDeleteNews = (id: number) => {
        this.props.deleteNews({ id })
    }

    onHandleEditNews = (id: number) => {
        const updateIndex: number = this.state.newsList.findIndex((news) => news.id === id)
        const cloneNewsList = this.state.newsList
        const updateNews = cloneNewsList[updateIndex]
        updateNews.isEdit = !updateNews.isEdit
        cloneNewsList[updateIndex] = updateNews
        if (updateNews.isEdit) {
            this.setState({
                newsList: cloneNewsList,
                onEdit: updateNews.isEdit,
                editTitle: updateNews.title,
                editDescription: updateNews.description,
            })
        } else {
            this.setState({
                newsList: cloneNewsList,
                onEdit: updateNews.isEdit,
                editTitle: '',
                editDescription: '',
            })
        }
    }

    handleEditNews = (id: number) => {
        this.props.putNews({
            id: id,
            title: this.state.editTitle,
            description: this.state.editDescription,
        })
        this.props.loader(true)
    }

    renderNewsList = (): JSX.Element => (
        <>
            {
                this.state.newsList
                    .map((news, index) => {
                        return (
                            <div
                                className="news-item-container"
                                key={`news${index}`}
                            >
                                <div className="title" >
                                    {
                                        news.isEdit ? (
                                            <TextField
                                                value={this.state.editTitle}
                                                placeholder="กรุณากรอกหัวข้อข่าว *"
                                                label="หัวข้อข่าว"
                                                fullWidth={true}
                                                onChange={(e) => this.setState({ editTitle: e.target.value })}
                                            />
                                        )
                                            :
                                            (
                                                <h6 className="primary-text">{news.title}</h6>
                                            )
                                    }
                                    <div className="manage-news-item-container">
                                        {
                                            this.state.onEdit  && news.isEdit? (
                                                <>
                                                    <IconButton
                                                        className="primary-purple-text"
                                                        onClick={() => this.onHandleEditNews(news.id)}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        className="primary-green-text"
                                                        onClick={() => this.handleEditNews(news.id)}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                </>
                                            )
                                                :
                                                (
                                                    <>
                                                        <IconButton
                                                            className="primary-purple-text"
                                                            onClick={() => this.onHandleEditNews(news.id)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            className="primary-purple-text"
                                                            onClick={() => this.onHandleDeleteNews(news.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                                <div className="subtitle">
                                    <h6 className="primary-text subtitle-2">
                                        {
                                            news.isEdit ? (<></>)
                                                :
                                                (
                                                    <>
                                                        {date.FormatDate(news.updatedAt, 'Do MMM YYYY เวลา k:mm')}
                                                    </>
                                                )
                                        }
                                    </h6>
                                    <h6 className="primary-text subtitle-2">
                                        {
                                            news.isEdit ? (<></>)
                                                :
                                                (
                                                    <>
                                                        Admin cat
                                                    <EditIcon className="editor-icon" />
                                                    </>
                                                )
                                        }
                                    </h6>
                                </div>
                                <h6 className="description secondary-text">
                                    {
                                        news.isEdit ? (
                                            <TextField
                                                value={this.state.editDescription}
                                                placeholder="กรุณาเนื้อหาข่าว *"
                                                label="เนื้อหาข่าว"
                                                fullWidth={true}
                                                multiline={true}
                                                rows={4}
                                                onChange={(e) => this.setState({ editDescription: e.target.value })}
                                            />
                                        )
                                            :
                                            (
                                                <>
                                                    {news.description}
                                                </>
                                            )
                                    }
                                </h6>
                            </div>
                        )
                    })
            }
        </>
    )
    handleOnClickBreadcrumb = (path: string) => {
        this.props.history.replace(path)
      }

    render() {
        const navigates: IBreadcrumbItem[] = [
            { label: constants.systemLabel, active: false },
            { label: constants.newsLabel, active: true },
          ]
        const RenderNewsList = this.renderNewsList
        return (
            <div className="news-container">
                <Breadcrumb items={navigates} handleOnClickItem={this.handleOnClickBreadcrumb} />
                <TitlePage title={constants.newsLabel} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">เขียนข่าวสาร</div>
                            <div className="paper-body">
                                <TextField
                                    placeholder="กรุณากรอกหัวข้อข่าว *"
                                    label="หัวข้อข่าว"
                                    fullWidth={true}
                                    value={this.state.insertTitle}
                                    onChange={(e) => this.setState({ insertTitle: e.target.value })}
                                />
                                <TextField
                                    placeholder="กรุณากรอกเนื้อหาข่าว *"
                                    label="เนื้อหาข่าว"
                                    fullWidth={true}
                                    multiline={true}
                                    rows={8}
                                    value={this.state.insertDescription}
                                    onChange={(e) => this.setState({ insertDescription: e.target.value })}
                                />
                            </div>
                            <div className="paper-bottom">
                                <Button
                                    text="สร้างข่าวสาร"
                                    fullWidth={true}
                                    onClick={this.onInsertNews}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="paper-container secondary-dark">
                            <div className="paper-title">รายการข่าวสาร</div>
                            <div className="paper-body">
                                <div className="news-list-container">
                                    <RenderNewsList />
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default NewsContainer