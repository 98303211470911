import project from 'constants/project'

export const GET_YEGEE_GAME_CONFIG_REQUEST = 'GET_YEGEE_GAME_CONFIG_REQUEST'
export const GET_YEGEE_GAME_CONFIG_SUCCESS = 'GET_YEGEE_GAME_CONFIG_SUCCESS'
export const GET_YEGEE_GAME_CONFIG_FAILURE = 'GET_YEGEE_GAME_CONFIG_FAILURE'
export const GET_YEGEE_GAME_CONFIG_CANCEL = 'GET_YEGEE_GAME_CONFIG_CANCEL'

export const PUT_YEGEE_GAME_CONFIG_REQUEST = 'PUT_YEGEE_GAME_CONFIG_REQUEST'
export const PUT_YEGEE_GAME_CONFIG_SUCCESS = 'PUT_YEGEE_GAME_CONFIG_SUCCESS'
export const PUT_YEGEE_GAME_CONFIG_FAILURE = 'PUT_YEGEE_GAME_CONFIG_FAILURE'
export const PUT_YEGEE_GAME_CONFIG_CANCEL = 'PUT_YEGEE_GAME_CONFIG_CANCEL'

export const initialState: IYegeeConfigState = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    round: '',
    betweenMinutes: '',
    startAt: '',
    createdAt: '',
    updatedAt: '',
    expectRate: '',
  },
  error: '',
}

export const endpoint = {
  getYegeeGameConfig: `${project.environment[project.environmentName].api}/config/yegee/game`,
  putYegeeGameConfig: `${project.environment[project.environmentName].api}/config/yegee/game`,
}