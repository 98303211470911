import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
    catchError,
    exhaustMap,
    takeUntil,
    filter,
    map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import {
    fetchGetWebConfig,
    fetchPutWebConfig,
} from './services'
import actions from './actions'

const webConfigEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getWebConfigAction)),
        exhaustMap(_ =>
            from(fetchGetWebConfig())
                .pipe(
                    map(actions.getWebConfigSuccessAction),
                    catchError(error => of(actions.getWebConfigFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getWebConfigCancelAction))))
                ),
        )
    )

const webConfigPutEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.putWebConfigAction)),
        exhaustMap(action =>
            from(fetchPutWebConfig(action.payload))
                .pipe(
                    map(actions.putWebConfigSuccessAction),
                    catchError(error => of(actions.putWebConfigFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.putWebConfigCancelAction))))
                ),
        )
    )

export default [
    webConfigEpic,
    webConfigPutEpic,
]
