import io from 'socket.io-client'
import { MiddlewareAPI, Dispatch } from 'redux'
import socketAction from 'reduxs/socket/actions'
import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import project from 'constants/project'
import transactionAction from 'reduxs/transaction/actions'
import webbankAction from 'reduxs/webbank/webbanks/actions'
import { transformer } from 'utils'

const onConnect = (handleStore: MiddlewareAPI<Dispatch, RootReducers>) => (event: any) => {
  handleStore.dispatch(socketAction.connectedSocketAction())
}

const onDisconnect = (handleStore: MiddlewareAPI<Dispatch, RootReducers>) => () => {
  handleStore.dispatch(socketAction.disconnectedSocketAction())
}

const onError = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) => (error: any) => {
  handlerStore.dispatch(socketAction.connectSocketErrorAction(error))
}

const TransactionDepositAllSuccess = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseTransactionAll: APIResponse<string> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer
      .camelcaseTransform(responseTransactionAll) as APIResponse<PaginationResponse<ITransactionAll>>
    handlerStore.dispatch(transactionAction.updateTransactionAllAction(transformed))
  }

const TransactionWithdrawAllSuccess = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseTransactionAll: APIResponse<string> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer
      .camelcaseTransform(responseTransactionAll) as APIResponse<PaginationResponse<ITransactionAll>>
    handlerStore.dispatch(transactionAction.updateTransactionWithdrawAllAction(transformed))
  }

const onupdateNotificationTransaction = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseTransactionAll: APIResponse<string> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer
      .camelcaseTransform(responseTransactionAll) as APIResponse<INotification>
    handlerStore.dispatch(transactionAction.updateNotificationTransactionAllAction(transformed))
  }

const onOffNotification = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  () => {
    handlerStore.dispatch(transactionAction.clearNotificationTransactionAll())
  }

const onOffTransactionAll = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  () => {
    handlerStore.dispatch(transactionAction.clearTransactionAll())
  }

const onupdateWebbank = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseTransactionAll: APIResponse<string> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer
      .camelcaseTransform(responseTransactionAll) as APIResponse<PaginationResponse<IWebBank>>
    handlerStore.dispatch(webbankAction.updateWebbankAction(transformed))
  }

const onOffWebbank = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  () => {
    handlerStore.dispatch(webbankAction.clearWebbank())
  }


let socket: SocketIOClient.Socket | null = null
let socketWithdraw: SocketIOClient.Socket | null = null
let socketDeposit: SocketIOClient.Socket | null = null
let socketNotification: SocketIOClient.Socket | null = null
let socketWebbank: SocketIOClient.Socket | null = null

const socketMiddleware = (store: MiddlewareAPI<Dispatch, RootReducers>) => (next: Dispatch) => (action: RootAction) => {
  if (store.getState().mantra.user.token.accessToken) {
    switch (action.type) {

      case getType(socketAction.connectSocketAction):
        if (socket?.connected) {
          socket.disconnect()
        }
        socket = io(project.environment[project.environmentName].socket, {
          query: { token: store.getState().mantra.user.token.accessToken },
        })

        socket.connect()
        socket.on('connect', onConnect(store))
        socket.on('disconnect', onDisconnect(store))
        socket.on('error', onError(store))
        break;
      case getType(socketAction.connectFinanceDepositSocketAction):
        if (socketDeposit?.connected) {
          socketDeposit.disconnect()
        }
        socketDeposit = io.connect(`${project.environment[project.environmentName].socket}/api/finance/all`, {
          query: {
            token: store.getState().mantra.user.token.accessToken,
            limit: action.payload.limit,
            page: String(Number(action.payload.page! + 1)),
            status: action.payload.status,
            type: action.payload.type,
          },
        });
        socketDeposit.connect()
        socketDeposit.on('api/finance/all', TransactionDepositAllSuccess(store))
        break;

      case getType(socketAction.connectFinanceWithDrawSocketAction):
        if (socketWithdraw?.connected) {
          socketWithdraw.disconnect()
        }
        socketWithdraw = io.connect(`${project.environment[project.environmentName].socket}/api/finance/all`, {
          query: {
            token: store.getState().mantra.user.token.accessToken,
            limit: action.payload.limit,
            page: String(Number(action.payload.page! + 1)),
            status: action.payload.status,
            type: action.payload.type,
          },
        });
        socketWithdraw.connect()
        socketWithdraw.on('api/finance/all', TransactionWithdrawAllSuccess(store))
        break;
      case getType(transactionAction.listenNotificationTransactionAllSocketAction):
        if (socketNotification?.connected) {
          socketNotification?.disconnect()
        }
        socketNotification = io.connect(`${project.environment[project.environmentName].socket}/api/finance/notifications`, {
          query: {
            token: store.getState().mantra.user.token.accessToken,
          },
        });
        socketNotification.connect()
        socketNotification.on('api/finance/notifications', onupdateNotificationTransaction(store))
        break;
      case getType(webbankAction.listenWebbankSocketAction):
        if (socketWebbank?.connected) {
          socketWebbank?.disconnect()
        }
        socketWebbank = io.connect(`${project.environment[project.environmentName].socket}/api/webbank/all`, {
          query: {
            token: store.getState().mantra.user.token.accessToken,
            page: String(Number(action.payload.page! + 1)),
            limit: action.payload.limit,
            status: action.payload.queryStatus,
          },
        });
        socketWebbank.connect()
        socketWebbank.on('api/webbank/all', onupdateWebbank(store))
        break;
      case getType(transactionAction.unlistenTransactionAllSocket):
        socketWithdraw?.off(`api/finance/all`, onOffTransactionAll(store))
        socketWithdraw?.disconnect()
        socketDeposit?.off(`api/finance/all`, onOffTransactionAll(store))
        socketDeposit?.disconnect()
        break;
      case getType(webbankAction.unlistenWebbankSocketAction):
        socketWebbank?.off(`api/webbank/all`, onOffWebbank(store))
        socketWebbank?.disconnect()
        break;
      case getType(transactionAction.unlistenNotificationTransactionAllSocketAction):
        socketNotification?.off(`api/finance/notifications`, onOffNotification(store))
        socketNotification?.disconnect()
        break;
      case getType(socketAction.disconnectSocketAction):
        if (socket) {
          if (socket.connected) {
            socket.disconnect()
          }
        }
        break;
      default:
        return next(action);
    }
  }

  return next(action)

}

export default socketMiddleware