import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetLottoRateNumber = (data: IGetNumberRateParams) => {
    const queryParams = new URLSearchParams({
        type: get(data, 'type', ''),
        code: get(data, 'code', ''),
        page: String(get(data, 'page', 0) + 1),
        limit: String(get(data, 'limit', '10')),
    })
    return axios.get(endpoint.getAllLottoRateNumber, {
        params: queryParams,
    })
}