import project from 'constants/project'

export const PUT_LOTTO_MODE_REQUEST = 'PUT_LOTTO_MODE_REQUEST'
export const PUT_LOTTO_MODE_SUCCESS = 'PUT_LOTTO_MODE_SUCCESS'
export const PUT_LOTTO_MODE_FAILURE = 'PUT_LOTTO_MODE_FAILURE'
export const PUT_LOTTO_MODE_CANCEL = 'PUT_LOTTO_MODE_CANCEL'

export const initialState: ReducerState<ILottoSchedule[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  putLottoMode: `${project.environment[project.environmentName].api}/config/web/lottery/mode`,
}

