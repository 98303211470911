import { createAction } from 'typesafe-actions'
import {
  SET_DATE,
  CLEAR_DATE,
  PERSISTED_DATE,
} from './constants'

const setDateAction = createAction(
  SET_DATE,
  action => (data: IDateTimeStart) => action(data))

const clearDateAction = createAction(
  CLEAR_DATE,
  action => () => action({ datetime: '' }))

const persistedDateAction = createAction(
  PERSISTED_DATE,
  resolve => (data: IDateTimeStart) => resolve(data))

export default {
  setDateAction,
  clearDateAction,
  persistedDateAction,
}