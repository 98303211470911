import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const postUserReducer = (state: IUserPostState = initialState, action: RootAction): IUserPostState => {
  switch (action.type) {
      case getType(actions.postUserAction):
          return {
              ...state,
              isFetching: true,
          }
      case getType(actions.postUserSuccessAction):
          return {
              isFetching: false,
              data: action.payload.data.data,
              code: action.payload.data.code,
              devMessage: action.payload.data.devMessage,
          }

      case getType(actions.postUserFailureAction):
          return {
              isFetching: false,
            //   error: action.payload.message,
              code: action.payload.response!.data.code,
              devMessage: action.payload.response!.data.devMessage,
          }
      default:
          return state
  }
}

export default postUserReducer