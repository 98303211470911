import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import authAction from 'reduxs/auth/actions'
import loaderAction from 'reduxs/loader/actions'
import HomeContainer from './Home.container'

const mapStateToProps = (state: RootReducers): IHomeProps => ({
  loginIsFetching: state.mantra.auth.login.isFetching!,
  loginError: state.mantra.auth.login.error!,
  loginCode: state.mantra.auth.login.code!,
  loginResult: state.mantra.auth.login.data!,
})

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IHomeActionProps => bindActionCreators({
  login: authAction.loginAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(HomeContainer)