import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getLottoRateNumberReducer = (state: IRateNumberListStoreState = initialState,
  action: RootAction): IRateNumberListStoreState => {
  switch (action.type) {
    case getType(actions.getLottoRateNumberAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getLottoRateNumberSuccessAction):
      const data = (action.payload.isFirst)
        ? action.payload.data.data.dataList
        : [...(state.data || []), ...(action.payload.data.data.dataList || [])]
      return {
        isFetching: false,
        data: data,
        code: action.payload.data.code,
        page: (action.payload.data.data.page),
        limit: action.payload.data.data.limit,
        total: (action.payload.data.data.total / action.payload.data.data.limit)
      }
    case getType(actions.getLottoRateNumberFailureAction):
      return {
        isFetching: false,
        error: action.payload.message,
        code: action.payload.response?.data.code,
      }
    case getType(actions.getLottoRateNumberCancelAction):
      return {
        isFetching: false,
        data: [],
        code: 0,
        page: 0,
        limit: 0,
        total: 0
      }
    default:
      return state
  }
}

export default getLottoRateNumberReducer