import { combineReducers } from 'redux'
import all from './all/reducers'
import put from './check/reducers'
import reserve from './reserve/reducers'
import notification from './notifications/reducers'

export default combineReducers({
  all,
  put,
  reserve,
  notification,
})