import { createAction } from 'typesafe-actions'
import {
  POST_CHECK_RATE_CLIENT_REQUEST,
  POST_CHECK_RATE_CLIENT_CANCEL,
  POST_CHECK_RATE_CLIENT_SUCCESS,
  POST_CHECK_RATE_CLIENT_FAILURE,
} from './constants'

import { AxiosResponse, AxiosError } from 'axios'

const postCheckRateClientAction = createAction(
  POST_CHECK_RATE_CLIENT_REQUEST,
  reslove => (data: IPostCheckRateClient) => reslove(data)
)

const postCheckRateClientSuccessAction = createAction(
  POST_CHECK_RATE_CLIENT_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IPostCheckRateClientResponse>>) => resolve(data.data))

const postCheckRateClientFailureAction = createAction(
  POST_CHECK_RATE_CLIENT_FAILURE,
  resolve => (error: AxiosError) => resolve(error))

const postCheckRateClientCancelAction = createAction(POST_CHECK_RATE_CLIENT_CANCEL)

export default {
  postCheckRateClientAction,
  postCheckRateClientSuccessAction,
  postCheckRateClientFailureAction,
  postCheckRateClientCancelAction,
}